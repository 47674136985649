import React, {useEffect} from 'react';
import {Box, CircularProgress, Grid, Paper, Typography} from '@material-ui/core';

import {useSelector} from 'react-redux';

import {BuildState, IBuild} from '@common/api/models/builds/IBuild';

import {usePartStoreActions, usePointCloudStoreActions} from '../../../../store/actions';
import {RootState} from '../../../../store/reducers';

import MultiViewer from '../../../../components/molecules/Viewport/MultiViewer';
import LazyLayerImage from '../../../../components/atoms/LazyLayerImage';
import {LiveBuildDataProps} from '..';
import waitingForSomethingPNG from '../../../../assets/img/waiting-for-something.png';
import {ILayerImage, LayerImageResolutionSize} from '@common/api/models/builds/data/ILayerImage';

export interface LayerImages {
  [layer: number]: LayerImage;
}

export interface LayerImage {
  [partName: string]: PartImages;
}

export interface PartImages {
  [layerType: string]: PartImage;
}

export interface PartImage {
  timestamp: Date;
  images: LazyImagesBySize;
}

export interface LazyImageWithSize {
  image: LazyLayerImage;
  width: number;
  height: number;
  imageSize: ILayerImage;
  resolutionSize: LayerImageResolutionSize;
}

export type LazyImagesBySize = LazyImageWithSize[];

function imagesOrderedBySize(imagesBySize: LazyImagesBySize, performanceMode: boolean) {
  // Ignore full res image in performance mode
  const fullRes = performanceMode
    ? undefined
    : imagesBySize.find((s) => s.resolutionSize === LayerImageResolutionSize.FULL_RES);
  const halfRes = imagesBySize.find((s) => s.resolutionSize === LayerImageResolutionSize.HALF_RES);
  const thumbnail = imagesBySize.find((s) => s.resolutionSize === LayerImageResolutionSize.THUMBNAIL);

  return [fullRes, halfRes, thumbnail].filter((image) => !!image) as LazyImageWithSize[];
}

export function chooseImageForViewing(imagesBySize: LazyImagesBySize, loadedOnly: boolean, performanceMode: boolean) {
  // Choose displayable first
  let result: LazyImageWithSize | undefined;
  const imagesOrdered = imagesOrderedBySize(imagesBySize, performanceMode);

  for (const img of imagesOrdered) {
    if (loadedOnly) {
      if (!img.image.imageBacked || !img.image.imageBacked.complete) {
        continue;
      }
    }

    result = img;
    break;
  }

  return result ? result.image : undefined;
}

export function getThumbnailImage(imagesBySize: LazyImagesBySize) {
  return imagesBySize.find((s) => s.resolutionSize === LayerImageResolutionSize.THUMBNAIL);
}

export function getSmallestImage(imagesBySize: LazyImagesBySize) {
  const imagesOrderedSmallestToLargest = imagesOrderedBySize(imagesBySize, false).reverse();
  const result = imagesOrderedSmallestToLargest[0];

  return result ? result.image : undefined;
}

export function getLargestImage(imagesBySize: LazyImagesBySize) {
  const imagesOrderedLargestToSmallest = imagesOrderedBySize(imagesBySize, false);
  const result = imagesOrderedLargestToSmallest[0];

  return result ? result.image : undefined;
}

export function ViewportPlaceholder({
  build,
  waitingForFirstFullLayer,
}: {
  build: IBuild;
  waitingForFirstFullLayer?: boolean;
}) {
  const placeholderText = waitingForFirstFullLayer
    ? 'Waiting for first full layer to print...'
    : build.lastPrintedLayerId
    ? `Loading ${build.lastPrintedLayerId} layers...`
    : build.state === BuildState.COMPLETED
    ? 'No layer images to view'
    : 'Waiting for first layer to print...';

  return (
    <Paper style={{width: '100%', height: '60vh'}}>
      <Box display="flex" flexDirection="column" height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h3">{placeholderText}</Typography>
        {build.lastPrintedLayerId && !waitingForFirstFullLayer ? (
          <CircularProgress />
        ) : (
          <img
            src={waitingForSomethingPNG}
            style={{height: '35vh', marginTop: '1rem'}}
            alt="bird chirping musically in a park"
          />
        )}
      </Box>
    </Paper>
  );
}

export default function ViewportsPage(props: LiveBuildDataProps) {
  const partStoreActions = usePartStoreActions();
  const partStore = useSelector((state: RootState) => state.partStore);

  useEffect(() => {
    partStoreActions.ensureConsistent({
      buildUuid: props.build.uuid,
      includeFirstLayer: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  const pointCloudStoreActions = usePointCloudStoreActions();
  const pointCloudStore = useSelector((state: RootState) => state.pointCloudStore);

  useEffect(() => {
    if (props.build.uuid) {
      pointCloudStoreActions.ensureConsistent({
        buildUuid: props.build.uuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  const anyBuildPointClouds =
    Object.values(pointCloudStore.byId).filter(
      (pointCloud) => partStore.byId[pointCloud.partUuid]?.buildUuid === props.build.uuid
    ).length > 0;

  return (
    <Grid container>
      <Grid item xs={12}>
        {props.totalLayers ? (
          <MultiViewer
            loadedLayers={props.layerImages!}
            cmLoadedImages={props.cmLoadedImages}
            setCmLoadedImage={props.setCmLoadedImage}
            totalLayers={props.totalLayers}
            mmPerPixel={props.calibrationScale}
            fetchLayerData={props.fetchLayerData}
            viewport3DAvailable={anyBuildPointClouds}
            build={props.build}
            partialLayerNumbers={props.partialLayerNumbers}
            missingLayerNumbers={props.missingLayerNumbers}
          />
        ) : (
          <ViewportPlaceholder build={props.build} />
        )}
      </Grid>
    </Grid>
  );
}
