import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {SvgIconComponent} from '@material-ui/icons';

export interface TimelineGraphNode {
  text: string;
  batch: IBatch;
  index: number;
  date: Date;
  icon: SvgIconComponent;
}

export enum TimeMode {
  FUTURE,
  PRESENT,
  HISTORY,
}

export interface TimelineGraphTableRow {
  nodes: TimelineGraphNode[];
  edges: string[];
  timeMode: TimeMode;
}
