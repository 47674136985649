import React, {useState} from 'react';
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import isMobilePhone from 'validator/lib/isMobilePhone';
import {validateEmail} from '@common/api/models/users/User.validation';
import {Close, Add} from '@material-ui/icons';
import {IUser} from '@common/api/models/users/IUser';
import {userPATCH} from '../../../../api/ajax/users';
import {Controller, useForm} from 'react-hook-form';
import envConfig from '../../../../envConfig';

import 'react-phone-input-2/lib/material.css';

type UserUpdateFormData = Pick<IUser, 'email' | 'mobile'>;
type EditNotificationUserModalProps = {
  user?: IUser;
  onClose: () => void;
  modalOpen: boolean;
  onEditSuccess?: () => void;
};

const EditNotificationUserModal = ({user, onClose, modalOpen, onEditSuccess}: EditNotificationUserModalProps) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={modalOpen} onClose={onClose}>
      <EditUserForm user={user!} onClose={onClose} onEditSuccess={onEditSuccess} />
    </Dialog>
  );
};

export default EditNotificationUserModal;

const EditUserForm = ({
  user,
  onClose,
  onEditSuccess,
}: {
  user: IUser;
  onClose: () => void;
  onEditSuccess?: () => void;
}) => {
  const [requestInProgress, setRequestInProgress] = useState(false);

  const {
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<UserUpdateFormData>({
    mode: 'all',
    defaultValues: {
      email: user?.email || '',
      mobile: user?.mobile || '',
    },
  });

  const handleSubmit = async (data: UserUpdateFormData) => {
    setRequestInProgress(true);
    const changedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (user[key as keyof UserUpdateFormData] !== value && !!value) {
        acc[key] = value;
      }
      return acc;
    }, {} as any);

    const res = await userPATCH(user.uuid, changedData);

    if (res.success) {
      if (onEditSuccess) {
        onEditSuccess();
      }
      toast('Successfully updated details!', {type: 'info'});
    }
    setRequestInProgress(false);
    onClose();
  };

  return (
    <form id="updateUserNotificationForm" onSubmit={onSubmit(handleSubmit)}>
      <DialogTitle disableTypography style={{padding: '24px'}}>
        <EditUserModalHeader onClose={onClose} />
      </DialogTitle>

      <StyledDiaglogContent dividers>
        <EditUserModalForm control={control} errors={errors} />
      </StyledDiaglogContent>
      <DialogActions style={{padding: '16px 24px'}}>
        <EditUserModalActions
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          onClose={onClose}
          requestInProgress={requestInProgress}
        />
      </DialogActions>
    </form>
  );
};

const EditUserModalHeader = ({onClose}: {onClose: () => void}) => {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Edit User</Typography>
        <IconButton aria-label="close" onClick={onClose} style={{padding: '0px'}}>
          <Close />
        </IconButton>
      </Grid>
    </>
  );
};

const EditUserModalForm = ({control, errors}: {control: any; errors: any}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Controller
          name="email"
          control={control}
          rules={{
            validate: (value) => {
              if (!value) return true;
              const validationResult = validateEmail(value, envConfig.isOnPremise);
              if (!validationResult.success) {
                return validationResult.errors[0];
              }
              return true;
            },
          }}
          render={({onChange, value, ref}) => {
            return (
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Email"
                inputRef={ref}
                value={value}
                error={!!errors.email}
                onChange={(event) => onChange(event.target.value)}
                inputProps={{
                  name: 'email',
                  type: 'email',
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={'mobile'}
          control={control}
          rules={{
            validate: (value) => (value ? isMobilePhone(`${value}`.replace('+', '')) : true),
          }}
          as={
            <PhoneInput
              country="au"
              disableDropdown
              inputProps={{name: 'mobile'}}
              masks={{au: '. .... ....'}}
              defaultErrorMessage="Invalid phone number"
              isValid={() => !errors.mobile}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

const EditUserModalActions = ({
  onSubmit,
  onClose,
  handleSubmit,
  requestInProgress,
}: {
  onSubmit: any;
  handleSubmit: (data: any) => void;
  onClose: () => void;
  requestInProgress: boolean;
}) => {
  return (
    <>
      <Button variant="outlined" color="primary" onClick={onClose} disabled={requestInProgress}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSubmit(handleSubmit)}
        endIcon={requestInProgress ? <CircularProgress size={20} /> : <Add />}
        disabled={requestInProgress}
      >
        Save
      </Button>
    </>
  );
};

const StyledDiaglogContent = styled(DialogContent)`
  padding: 24px 2px;
  margin: 0px 24px;
  overflow: hidden;

  .react-tel-input {
    input[type] {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 12px;
      font-family: Nunito;
    }
  }

  .react-tel-input .form-control + div:before {
    left: 8px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
    font-family: Nunito;
    font-weight: 400;
  }

  .invalid-number-message {
    left: 8px;
    font-size: 10px;
    font-family: Nunito;
    font-weight: 400;
  }
`;
