import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPointCloud} from '@common/api/models/builds/data/IPointCloud';
import {objMapValues} from '../../../../utils/objectFunctions';
import {BoundingBox} from './Base3DViewport';
import {Group, Object3D, Vector3} from 'three';
import {AnalysisTypeMap, PointCloudFilter, SinglePartPointCloudData} from './types/pointCloudTypes';

/**
 * Manages a THREE.Group containing all loaded point clouds.
 * The group contains a child group for every part, and each part group contains
 * a child group for each analysis type, which contain the individual point clouds.
 */
export class PartPointClouds {
  public readonly group: Group;
  public numParts: number;
  public numPointClouds: number;

  constructor() {
    this.group = new Group();
    this.numParts = 0;
    this.numPointClouds = 0;
  }

  addPart(uuid: string): Group {
    const existingPart = this.group.getObjectByName(uuid);
    if (existingPart) {
      // Part exists, make it visible and return
      existingPart.visible = true;
      return existingPart as Group;
    }

    // Part doesn't yet exist, so create it
    const partGroup = new Group();
    partGroup.name = uuid;
    partGroup.add(
      ...Object.values(AnalysisType3D).map((analysisType) => {
        const analysisTypeGroup = new Group();
        analysisTypeGroup.name = analysisType;
        analysisTypeGroup.visible = true;
        return analysisTypeGroup;
      })
    );
    this.group.add(partGroup);
    this.numParts++;
    return partGroup;
  }

  getPart(uuid: string): Group {
    const existingPart = this.group.getObjectByName(uuid);
    if (existingPart) {
      return existingPart as Group;
    }

    // Part doesn't yet exist, so create it and return
    return this.addPart(uuid);
  }

  /** Makes only the parts with the given uuids and the layerImagePlane visible */
  setVisibleParts(uuids: string[]) {
    this.group.children.forEach((partGroup) => {
      if (partGroup.name === 'layerImagePlane') return;
      partGroup.visible = uuids.includes(partGroup.name);
    });
  }

  addPointCloud(partUuid: string, analysisType: AnalysisType3D, pointCloud: Object3D) {
    pointCloud.userData.partUuid = partUuid;
    pointCloud.userData.analysisType = analysisType;

    const partGroup = this.getPart(partUuid);
    const analysisTypeGroup = partGroup.getObjectByName(analysisType) as Group;
    analysisTypeGroup.add(pointCloud);
    this.numPointClouds++;
  }

  getPointClouds(partUuid: string, analysisType: AnalysisType3D): Object3D[] {
    const partGroup = this.getPart(partUuid);
    const analysisTypeGroup = partGroup.getObjectByName(analysisType) as Group;
    return analysisTypeGroup.children;
  }

  filterPartGroups(groupList: Object3D[], filter: PointCloudFilter) {
    if (filter.partUuids) {
      return groupList.filter((group) => filter.partUuids?.includes(group.name));
    }

    return groupList;
  }

  filterAnalysisTypeGroups(groupList: Object3D[], filter: PointCloudFilter) {
    if (filter.analysisTypes) {
      return groupList.filter((group) => filter.analysisTypes?.includes(group.name as AnalysisType3D));
    }

    return groupList;
  }

  forEachPart(callback: (partGroup: Group) => void, filter: PointCloudFilter = {}) {
    this.filterPartGroups(this.group.children, filter).forEach((partGroup) => callback(partGroup as Group));
  }

  forEachAnalysisType(
    callback: (analysisTypeGroup: Group, analysisType: AnalysisType3D) => void,
    filter: PointCloudFilter = {}
  ) {
    this.forEachPart((partGroup) => {
      this.filterAnalysisTypeGroups(partGroup.children, filter).forEach((analysisTypeGroup) => {
        callback(analysisTypeGroup as Group, analysisTypeGroup.name as AnalysisType3D);
      });
    }, filter);
  }

  forEachPointCloud(callback: (pointCloud: Object3D) => void, filter: PointCloudFilter = {}) {
    this.forEachAnalysisType((analysisTypeGroup) => {
      analysisTypeGroup.children.forEach(callback);
    }, filter);
  }
}

function mergeBounds(boundsList: BoundingBox[]): BoundingBox {
  const min = new Vector3(
    Math.min(...boundsList.map((b) => b.min.x)),
    Math.min(...boundsList.map((b) => b.min.y)),
    Math.max(...boundsList.map((b) => b.min.z)) // Z axis has been inverted when loading point cloud
  );
  const max = new Vector3(
    Math.max(...boundsList.map((b) => b.min.x + b.dimensions.x)),
    Math.max(...boundsList.map((b) => b.min.y + b.dimensions.y)),
    Math.min(...boundsList.map((b) => b.min.z + b.dimensions.z))
  );
  const dimensions = max.clone().sub(min);
  return {min, dimensions};
}

/** Class to manage information relating to all parts' point clouds */
export class PointCloudData {
  readonly pointCloudMetadata: {
    [partUuid: string]: SinglePartPointCloudData;
  } = {};

  /** Add empty metadata for a part. Note getMetadata does this for you */
  addMetadata(partUuid: string) {
    this.pointCloudMetadata[partUuid] = {
      pointCloudsByType: objMapValues(AnalysisType3D, () => [] as IPointCloud[]) as AnalysisTypeMap<IPointCloud[]>,
      numModelPartitionsDownloaded: 0,
    };
    return this.pointCloudMetadata[partUuid];
  }

  /** Get all known metadata for a part */
  getMetadata(partUuid: string): SinglePartPointCloudData {
    const existingPart = this.pointCloudMetadata[partUuid];
    if (existingPart) {
      return existingPart;
    }

    // Part doesn't yet exist, so create it and return
    return this.addMetadata(partUuid);
  }

  /** Get all point cloud metadata for a given part and analysis type */
  getPointClouds(partUuid: string, analysisType: AnalysisType3D) {
    return this.getMetadata(partUuid).pointCloudsByType[analysisType];
  }

  /** Get the number of model partitions downloaded for a given part */
  getPartitionsDownloaded(partUuid: string) {
    return this.getMetadata(partUuid).numModelPartitionsDownloaded;
  }

  /** Bump the number of model partitions downloaded by 1 for a part */
  incDownloadedPartitions(partUuid: string) {
    this.getMetadata(partUuid).numModelPartitionsDownloaded++;
  }

  /**
   * Tell the class about some point cloud information that has been downloaded.
   * partUuid and analysis type info is inferred from the IPointCloud.
   */
  addPointCloud(pointCloud: IPointCloud) {
    const pointCloudList = this.getPointClouds(pointCloud.partUuid, pointCloud.type);
    if (!pointCloudList) return;

    const existing = pointCloudList.find((existing) => existing.uuid === pointCloud.uuid);
    if (!existing) {
      pointCloudList.push(pointCloud);
    }
  }

  /** Provide new/additional bounds for a part */
  setPartBounds(partUuid: string, bounds: BoundingBox) {
    const currentBounds = this.getMetadata(partUuid).partBounds;
    this.getMetadata(partUuid).partBounds = currentBounds ? mergeBounds([currentBounds, bounds]) : bounds;
  }

  /** Lists analysis point clouds available for a subset of all parts */
  availableAnalysisTypes(partUuids: string[]): AnalysisTypeMap<boolean> {
    const filteredParts = partUuids.map(this.getMetadata.bind(this));
    return objMapValues(AnalysisType3D, (_, analysisType) =>
      Object.values(filteredParts).some(
        (metadata) => metadata.pointCloudsByType[analysisType as AnalysisType3D].length > 0
      )
    ) as AnalysisTypeMap<boolean>;
  }

  /** Gets the combined bounds for a subset of all parts */
  getPartBounds(partUuids: string[]): BoundingBox | null {
    const filteredParts = partUuids.map(this.getMetadata.bind(this));
    const boundsList = filteredParts
      .map((metadata) => metadata.partBounds)
      .filter((bounds) => !!bounds) as BoundingBox[];

    if (boundsList.length === 0) return null;

    return mergeBounds(boundsList);
  }
}
