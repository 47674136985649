import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import {CheckCircle, Close, Error, KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import {sum} from 'lodash';
import {useLocation} from 'react-router-dom';
import {FileStates, FileUploadCandidate, FileUploadStatus} from './FileUploadContext';

const CustomMenu = styled.div`
  z-index: 1500;
  position: fixed;
  bottom: 85px !important;
  left: 10px !important;

  .MuiList-root {
    border-radius: 5px;
    padding: 0;
    width: 275px;
    min-width: 250px;
  }
`;

interface FileUploadUtilProps {
  files: {[key: string]: FileUploadCandidate};
  setState: (newState: FileStates) => void;
  cancelUpload: (filename: string) => void;
}

export default function FileUploadUtil(props: FileUploadUtilProps) {
  const [shrink, setShrink] = useState(false);
  const [close, setClose] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [filesCount, setFilesCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (isEmpty && !location.pathname.includes('/draft/')) {
      setClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toggleShrink = () => {
    setShrink(!shrink);
  };

  const toggleClose = () => {
    // Keep only non-completed files
    let updatedState: FileStates = {};
    Object.entries(props.files).forEach(([filename, file]) => {
      if (file.status !== FileUploadStatus.Completed) {
        updatedState[filename] = file;
      }
    });
    props.setState(updatedState);
    setClose(!close);
  };

  useEffect(() => {
    let files = Object.values(props.files);
    let numFiles = files.length;
    let numFilesUploading = files.filter((f) => f.status === FileUploadStatus.InProgress).length;

    // Update file count
    if (numFiles !== filesCount) {
      setFilesCount(numFiles);
    }
    // Reopen on adding new files
    if (numFiles > filesCount) {
      setShrink(false);
    }
    if (isEmpty && numFilesUploading > 0) {
      setClose(false);
    }
    setIsEmpty(numFilesUploading === 0);
    return () => {
      setIsEmpty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files]);

  return (
    <>
      {!close && (
        <CustomMenu>
          <Paper>
            <List
              style={{
                border: shrink ? 'none' : '1px rgba(0, 0, 0, 0.12) solid',
              }}
            >
              <ListItem
                style={{
                  backgroundColor: '#EBEBEB',
                  borderRadius: `${shrink ? '3px' : '0px'}`,
                  borderTopLeftRadius: `${!shrink ? '3px' : '0px'}`,
                  borderTopRightRadius: `${!shrink ? '3px' : '0px'}`,
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography style={{marginTop: '5px'}}>
                      {isEmpty ? 'All files uploaded!' : 'File upload in progress'}
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton size="small">
                      {shrink ? (
                        <KeyboardArrowUp onClick={toggleShrink} />
                      ) : (
                        <KeyboardArrowDown onClick={toggleShrink} />
                      )}
                    </IconButton>
                    <IconButton size="small" onClick={toggleClose} disabled={!isEmpty}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  maxHeight: '40vh',
                  overflowY: 'auto',
                }}
              >
                {!shrink && <Divider />}
                {!shrink &&
                  Object.values(props.files).map((file: FileUploadCandidate, index) => {
                    const loading = sum(file.loading) / file.loading.length;
                    return (
                      <ListItem
                        key={`${file.filename}-${index}-${file.uploadId}`}
                        style={{
                          padding: '5px 5px 5px 16px',
                        }}
                      >
                        <Grid container>
                          <Grid item xs={9} md={9} lg={9}>
                            <ListItemText
                              style={{textAlign: 'left', fontSize: '12px'}}
                              primary={`${file.name} - ${Math.round(loading * 100) / 100}%`}
                            />
                          </Grid>
                          <Grid item xs={1} md={1} lg={1} alignItems="center">
                            {file.status === FileUploadStatus.InProgress && (
                              <CircularProgress
                                variant="determinate"
                                size="20px"
                                value={loading}
                                style={{
                                  marginTop: '5px',
                                }}
                              />
                            )}
                            {file.status === FileUploadStatus.Completed && (
                              <CheckCircle fontSize="small" color="primary" style={{marginTop: '5px'}} />
                            )}
                            {file.status === FileUploadStatus.Error && (
                              <Error fontSize="small" htmlColor="#FF0000" style={{marginTop: '5px'}} />
                            )}
                          </Grid>
                          <Grid item xs={2} md={2} lg={2}>
                            <IconButton size="small" onClick={() => props.cancelUpload(file.filename)}>
                              <Close />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
              </div>
            </List>
          </Paper>
        </CustomMenu>
      )}
    </>
  );
}
