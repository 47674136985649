import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useSliceAttachmentStoreActions} from '../../../store/actions';
import SelectedDetailsTable, {TableRowType} from '../shared/SelectedDetailsTable';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {RootState} from '../../../store/reducers';
import {renderDateTimeString} from '../../../utils/string';

const SimilarityPartInfo = () => {
  const viewingPart = useSelector((state: RootState) => state.similarityReportStore.currentlyViewingPart);
  /* Get Slice Attachments data for the part that we are viewing */
  const sliceAttachmentStore = useSelector((state: RootState) => state.sliceAttachmentStore);
  const sliceAttachmentStoreActions = useSliceAttachmentStoreActions();

  useEffect(() => {
    if (viewingPart?.sliceAttachmentUuid) {
      sliceAttachmentStoreActions.ensureConsistent({
        uuid: viewingPart.sliceAttachmentUuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingPart]);

  const sliceAttachmentFilename = !!viewingPart
    ? sliceAttachmentStore.byId[viewingPart.sliceAttachmentUuid]?.filename
    : undefined;

  const rows: Array<TableRowType> | undefined = !!viewingPart
    ? [
        {field: 'Part Name', value: viewingPart.name},
        {
          field: 'Slice Filename',
          value: sliceAttachmentFilename || '',
          loading: sliceAttachmentStore.fetched === FetchingState.Fetching,
        },
        {field: 'Build Name', value: `${viewingPart.buildName}`},
        {
          field: 'Build Start Date',
          value: `${viewingPart.buildStartDate ? renderDateTimeString('short', viewingPart.buildStartDate) : ''}`,
        },
        {
          field: 'Build End Date',
          value: `${viewingPart.buildEndDate ? renderDateTimeString('short', viewingPart.buildEndDate) : ''}`,
        },
      ]
    : undefined;

  return <SelectedDetailsTable rows={rows} tableType="part" />;
};

export default SimilarityPartInfo;
