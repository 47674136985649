import React from 'react';
import {Grid, Table, Typography} from '@material-ui/core';
import styled from 'styled-components';
import ConfirmationTable from './ConfirmationStep/ConfirmationTable';
import {useSmallScreenSize} from '../../../../utils/utilHooks';

const ConfirmationStep = () => {
  const isSmallScreen = useSmallScreenSize();

  return (
    <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="space-between">
      <Grid item xs={12}>
        <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Step 7: Confirmation</Typography>
      </Grid>
      <TableContainer item xs={12}>
        <ConfirmationTable />
      </TableContainer>
    </Grid>
  );
};

export default ConfirmationStep;

const TableContainer = styled(Grid)`
  width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const CollapseTable = styled(Table)`
  width: 100%;
  table-layout: fixed;
  td,
  th {
    border: none;
  }
`;
