import axios from 'axios';
import * as qs from 'qs';
import {
  IBuildIntensityReportsGETRequest,
  IBuildIntensityReportGETResponse,
  IBuildIntensityReportPOSTRequest,
  IBuildIntensityReportUrlResponse,
} from '@common/api/models/builds/data/IBuildIntensityReport';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function buildIntensityReportAllGET(
  filter: QueryFilter<IBuildIntensityReportsGETRequest>
): AjaxApiResult<IBuildIntensityReportGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/reports/buildIntensity/' + (query && '?' + query));
  }, 'Could not fetch build intensity reports');
}

export async function buildIntensityReportPOST(
  buildIntensityReport: IBuildIntensityReportPOSTRequest
): AjaxApiResult<IBuildIntensityReportGETResponse> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/buildIntensity/', buildIntensityReport);
  }, 'Could not create build intensity report');
}

export async function buildIntensityReportDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/buildIntensity/${uuid}`);
  }, 'Could not delete build intensity report');
}

export async function buildIntensityReportDownloadUrlGET(
  uuid: string
): AjaxApiResult<IBuildIntensityReportUrlResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/buildIntensity/${uuid}/downloadUrl`);
  }, 'Could not get build intensity report download URL');
}
