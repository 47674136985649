import {combineValidation, ValidateFn, generateValidationResult, validateDateTime} from '../../../types';
import {IMachine} from './IMachine';
import {MACHINE_DETAILS} from './Machine.utils';

/**
 * Determine if input name is a valid machine name.
 * @param name {string} - Machine name to validate
 */
export const validateMachineName: ValidateFn<string, string> = function (name) {
  if (!name || name.length < 1) {
    const errorMessage = ['Please enter a machine name'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Determine if the manufacturer and model is valid.
 * @param manufacturer {string} - Machine manufacturer
 */
export const validateMachineManufacturer: ValidateFn<string, string> = function (manufacturer) {
  const validManufacturer = Object.keys(MACHINE_DETAILS).includes(manufacturer);
  if (!validManufacturer) {
    const errorMessage = ['Please enter a valid manufacturer'];
    return generateValidationResult(false, errorMessage);
  }

  return generateValidationResult(true);
};

/**
 * Determine if input model is a valid machine model.
 * @param manufacturer {string} - Machine manufacturer
 * @param model {string} - Machine model
 */
export const validateMachineModel: ValidateFn<[string, string], string> = function ([manufacturer, model]) {
  const manufacturerResult = validateMachineManufacturer(manufacturer);
  if (!manufacturerResult.success) return manufacturerResult;

  if (!model || model.length < 1) {
    const errorMessage = ['Please enter a machine model'];
    return generateValidationResult(false, errorMessage);
  }

  const validModel = Object.keys(MACHINE_DETAILS[manufacturer]).includes(model);

  if (!validModel) {
    const errorMessage = ['Please enter a valid model for the manufacturer'];
    return generateValidationResult(false, errorMessage);
  }

  return generateValidationResult(true);
};

/**
 * Determine if the commissioned date is valid.
 * @param date {Date} - Commissioned date of a machine
 */
export const validateCommissionedDate: ValidateFn<Date, string> = function (date) {
  const isValid = validateDateTime(date);
  if (!isValid) {
    const errorMessage = ['Please enter a valid commissioned date'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate all parameters of a machine.
 * @param machine {IMachine} - Contains information about a single machine
 */
export const validateMachine: ValidateFn<Partial<IMachine>, string> = function (machine) {
  return combineValidation(
    validateMachineName(machine.name),
    validateMachineManufacturer(machine.manufacturer),
    validateMachineModel([machine.manufacturer, machine.model]),
    validateCommissionedDate(machine.commissioned)
  );
};
