import React, {useEffect} from 'react';
import {Button, Card, CardContent, CardHeader, Grid, Table, TableBody, Typography} from '@material-ui/core';

import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Role} from '@common/api/models/users/IUser';
import {validateOrganizationName} from '@common/api/models/organization/Organization.validation';
import {validator} from '@common/api/types';

import {RootState} from '../../store/reducers';
import {useOrganizationStoreActions} from '../../store/actions';
import ViewEditFieldRow from '../../components/atoms/ViewEditFieldRow';
import {organizationByUuidPATCH} from '../../api/ajax/organizations';
import {userPATCH} from '../../api/ajax/users';
import Header from '../../components/organisms/Header';
import LoadingPage from '../../components/organisms/LoadingPage';

export default function OrganizationPage() {
  const organizationStoreActions = useOrganizationStoreActions();
  const auth = useSelector((state: RootState) => state.auth);

  const organizationStore = useSelector((state: RootState) => state.organizationStore);

  const {uuid} = useParams<{uuid: string}>();
  const organization = uuid ? organizationStore.byId[uuid as string] : undefined;

  useEffect(() => {
    if (uuid) {
      organizationStoreActions.ensureConsistent({uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  if (!organization) {
    return <LoadingPage loadingText="Loading Organization" />;
  }

  const handleSwitchToClick = async () => {
    const res = await userPATCH(auth.user!.uuid, {
      organizationUuid: uuid,
    });
    if (res.success) {
      // Force refresh to ensure user has swapped organization.
      window.location.reload();
    } else {
      toast('Could not switch Organization', {type: 'warning'});
    }
  };

  return (
    <React.Fragment>
      <Header
        helmet={`Organization ${organization.name}`}
        title={`Organization ${organization.name}`}
        breadcrumbs={[{title: 'Organizations', path: '/organizations'}, organization.name]}
      />

      <Grid container spacing={6} justifyContent={'center'}>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardHeader title={'Organization'}></CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                  <ViewEditFieldRow
                    type={'text'}
                    name={'Name'}
                    value={organization.name}
                    validateFn={(value) =>
                      validator(validateOrganizationName, value, (message) => toast(message, {type: 'error'}))
                    }
                    saveValue={(v) => organizationByUuidPATCH(organization.uuid, {name: v})}
                  />
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        {auth.user?.role === Role.SUPERUSER && (
          <Grid item xs={12} md={10} lg={8}>
            <Card>
              <CardHeader title={'Switch'}></CardHeader>
              <CardContent>
                {auth.user?.organizationUuid === organization.uuid ? (
                  <Typography>
                    <i>You are already in this organization</i>
                  </Typography>
                ) : (
                  <Button variant={'contained'} color={'primary'} onClick={handleSwitchToClick}>
                    Switch to this organization
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
