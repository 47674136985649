import React from 'react';
import {Link, Button} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from './../../../src/store/reducers';
import IndexPage from '../shared/IndexPage/IndexPage';

const NewUserButton = () => {
  return (
    <Link component={NavLink} to="/users/createUser">
      <Button variant="contained" color="primary">
        <Add />
        Add User
      </Button>
    </Link>
  );
};

export function UsersPage() {
  const useUsersData = () => {
    const usersList = useSelector((state: RootState) => state.userStore.list);

    usersList.filter((user) => !user.deletedAt);

    return usersList.map((user) => {
      return {
        name: `${user.firstName} ${user.lastName}`,
        onClickUrl: `/users/uuid/${user.uuid}`,
        ...user,
      };
    });
  };

  return <IndexPage resourceType="user" useData={useUsersData} CreateResourceComponent={NewUserButton} title="Users" />;
}
