import React from 'react';
import {Typography, TypographyProps, TooltipProps, Tooltip} from '@material-ui/core';

export interface IEllipsisTextProps extends TypographyProps {
  tooltip?: string | JSX.Element;
  tooltipProps?: Partial<TooltipProps>;
}

export default function EllipsisTextWithTooltip(props: IEllipsisTextProps) {
  const {children: text, tooltip, tooltipProps, ...rest} = props;

  return (
    <Tooltip title={<Typography>{tooltip || text || ''}</Typography>} arrow {...tooltipProps}>
      <Typography {...rest} noWrap>
        {text}
      </Typography>
    </Tooltip>
  );
}
