import React, {useContext} from 'react';
import {toast} from 'react-toastify';
import {MultiPartUploadResourceType} from './contexts/IMultiPartUploadContext';

export enum FileUploadStatus {
  InProgress,
  Completed,
  Error,
}

export interface FileUploadCandidate {
  uploadId: string;
  filename: string;
  name: string;
  signedUrls: string[];
  loading: Number[];
  resourceUuid: string;
  resourceType?: MultiPartUploadResourceType;
  orgUuid: string;
  status: FileUploadStatus;
  size: number;
  key: string;
}

export type FileStates = {[filename: string]: FileUploadCandidate};
export type StateType = {files: FileStates; toasts: {[id: string]: string[]}; currentToastId: React.ReactText | null};

interface IFileUploadContext {
  filesState: StateType;
  addFile: (filename: string, file: FileUploadCandidate) => void;
  setFilesState: (state: FileStates) => void;
  setFileProgress: (filename: string, progress: Number[]) => void;
  setFileStatus: (filename: string, status: FileUploadStatus) => void;
  completeUpload: (filename: string) => void;
}

const defaultVal: IFileUploadContext = {
  // default replaced below
  filesState: {files: {}, toasts: {}, currentToastId: null},
  addFile: (_filename: string, _file: FileUploadCandidate) => {},
  setFilesState: (_newState: {[filename: string]: FileUploadCandidate}) => {},
  setFileProgress: (_filename: string, _progress: Number[]) => {},
  setFileStatus: (_filename: string, _status: FileUploadStatus) => {},
  completeUpload: (_filename: string) => {},
};
export const FileContext = React.createContext<IFileUploadContext>(defaultVal);

export default class FileUploadProvider extends React.Component<{}, StateType> {
  state: StateType = {
    files: {},
    toasts: {},
    currentToastId: null,
  };

  setFilesState = (fileStates: FileStates) => {
    this.setState((state) => ({
      ...state,
      files: {
        ...fileStates,
      },
    }));
  };

  setNewState = (newState: StateType) => {
    // N.B. must be its own method as passing setState breaks
    this.setState(newState);
  };

  setFileProgress = (filename: string, progress: Number[]) => {
    const fileType = this.state.files[filename];
    if (fileType) {
      this.setFilesState({...this.state.files, [filename]: {...fileType, loading: progress}});
    }
  };

  setFileStatus = (filename: string, status: FileUploadStatus) => {
    const fileType = this.state.files[filename];
    if (fileType) {
      this.setFilesState({...this.state.files, [filename]: {...fileType, status}});
    }
  };

  addFile = (filename: string, file: FileUploadCandidate) => {
    this.setFilesState({...this.state.files, [filename]: file});
  };

  completeUpload = async (filename: string) => {
    const file: FileUploadCandidate = this.state.files[filename];
    if (file) {
      if (this.state.currentToastId && toast.isActive(this.state.currentToastId)) {
        const currentContent = this.state.toasts[`${this.state.currentToastId}`];

        toast.update(this.state.currentToastId, {
          render: `Successfully uploaded ${currentContent.length + 1} files`,
          autoClose: 4000,
        });

        this.setState({
          ...this.state,
          toasts: {[`${this.state.currentToastId}`]: [...currentContent, file.name]},
        });
      } else {
        const message = 'Successfully uploaded ' + file.name;
        const toastId = toast('Successfully uploaded ' + file.name, {
          type: 'success',
        });

        this.setState((state) => ({...state, currentToastId: toastId, toasts: {[`${toastId}`]: [message]}}));
      }
      this.setFileStatus(filename, FileUploadStatus.Completed);
    }
  };

  render() {
    return (
      <FileContext.Provider
        value={{
          addFile: this.addFile,
          filesState: this.state,
          setFilesState: this.setFilesState,
          setFileProgress: this.setFileProgress,
          setFileStatus: this.setFileStatus,
          completeUpload: this.completeUpload,
        }}
      >
        {this.props.children}
      </FileContext.Provider>
    );
  }
}

export const useFileUpload = () => {
  const fileUploadContext = useContext(FileContext);
  if (!fileUploadContext) {
    throw new Error('useFileUpload must be called within a FileUpload provider');
  }
  return fileUploadContext;
};
