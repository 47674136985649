import React, {useEffect, useState} from 'react';
import {
  Card,
  CardContent,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {FiberManualRecord} from '@material-ui/icons';

import {useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import {IMaterial} from '@common/api/models/materials/IMaterial';
import {
  AddEventData,
  BatchEventType,
  batchEventTypeTitle,
  BuildEventData,
  IBatch,
  InitialEventData,
} from '@common/api/models/materials/batches/IBatch';
import {IBuild} from '@common/api/models/builds/IBuild';
import {
  useBatchStoreActions,
  useBuildStoreActions,
  useMaterialAttachmentStoreActions,
  useMaterialStoreActions,
} from '../../../store/actions';
import {assertUnreachable} from '@common/utils/utils';
import {RootState} from '../../../store/reducers';
import {batchFutureGET, batchHistoryGET} from '../../../api/ajax/batches';
import LineTo from './LineTo';
import {grayColor, warningColor} from '../../../assets/jss/material-dashboard-react';
import 'react-virtualized/styles.css';
import BatchMaterialCompositionWidget from '../../../components/molecules/Dashboard/BatchMaterialCompositionWidget';
import {TimelineGraphNode, TimelineGraphTableRow, TimeMode} from './types';
import {batchesToFutureGraphTimelineRows} from './generateFutureNodes';
import {batchesToHistoryGraphTimelineRows} from './generateHistoryNodes';
import {renderDateString} from '../../../utils/string';
import {AttachmentDownloadTable} from '../../../components/molecules/Uploader/AttachmentDownloadTable';
import {uniq} from 'lodash';

export interface MaterialHistoryTargetBase {
  type: 'build' | 'batch' | 'material' | 'event';
}

export interface MaterialHistoryTargetMaterial extends MaterialHistoryTargetBase {
  type: 'material';
  material: IMaterial;
}

export interface MaterialHistoryTargetBatch extends MaterialHistoryTargetBase {
  type: 'batch';
  batch: IBatch;
}

export interface MaterialHistoryTargetBuild extends MaterialHistoryTargetBase {
  type: 'build';
  build: IBuild;
}

export type MaterialHistoryTarget =
  | MaterialHistoryTargetMaterial
  | MaterialHistoryTargetBatch
  | MaterialHistoryTargetBuild;

export type MaterialGraphViewerProps = MaterialHistoryTarget & {
  // That's it. Data is fetched in the component, not passed here.
};

function HistoryNodeIcon(props: {node: TimelineGraphNode; color: string}) {
  const {node} = props;
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const materialStoreActions = useMaterialStoreActions();
  const batchStoreActions = useBatchStoreActions();
  const attachmentStoreActions = useMaterialAttachmentStoreActions();
  const buildActions = useBuildStoreActions();

  const history = useHistory();

  const eventData = node.batch.creationEventData;

  const materialStore = useSelector((state: RootState) => state.materialStore);
  const buildStore = useSelector((state: RootState) => state.buildStore);
  const batchStore = useSelector((state: RootState) => state.batchStore);
  const attachmentStore = useSelector((state: RootState) => state.materialAttachmentStore);

  useEffect(() => {
    switch (eventData.type) {
      case BatchEventType.INITIAL:
      case BatchEventType.ADD:
        if (!materialStore.byId[eventData.materialUuid]) {
          materialStoreActions.ensureConsistent({uuid: eventData.materialUuid});
        }
        break;
      case BatchEventType.BUILD:
        if (!buildStore.byId[eventData.buildUuid]) {
          buildActions.ensureConsistent({uuid: eventData.buildUuid});
        }
        break;
      case BatchEventType.RETIRE:
      case BatchEventType.MEASUREMENT:
      case BatchEventType.MERGE: {
        const batchUuids = [node.batch.parentAUuid, node.batch.parentBUuid].filter((s) => s) as string[];
        if (!batchUuids.every((uuid) => !!batchStore.byId[uuid])) {
          batchStoreActions.ensureConsistent({uuid: batchUuids});
        }
        break;
      }
      case BatchEventType.SPLIT: {
        const parent = batchStore.byId[(node.batch.parentAUuid || node.batch.parentBUuid)!];
        const batchUuids = [
          parent?.successorAUuid!,
          parent?.successorBUuid!,
          node.batch.parentAUuid,
          node.batch.parentBUuid,
        ].filter((s) => s) as string[];
        if (!batchUuids.every((uuid) => !!batchStore.byId[uuid])) {
          batchStoreActions.ensureConsistent({uuid: batchUuids});
        }
        break;
      }
      case BatchEventType.TEST:
      case BatchEventType.CUSTOM:
        const attachmentUuids = eventData.attachmentUuids;
        if (!attachmentUuids.every((uuid) => !!attachmentStore.byId[uuid])) {
          attachmentStoreActions.ensureConsistent({uuid: attachmentUuids});
        }
        break;
      case BatchEventType.SIEVE:
        break;
      default:
        assertUnreachable(eventData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchStore.byId, eventData, node.batch.parentAUuid, node.batch.parentBUuid]);

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setClicked(false);
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const eventRows: JSX.Element[] = [];

  switch (eventData.type) {
    case BatchEventType.ADD:
    case BatchEventType.INITIAL:
      const material = materialStore.byId[eventData.materialUuid];
      eventRows.push(
        <Grid item xs={4}>
          <b>Material</b>
        </Grid>,
        <Grid item xs={8}>
          <Link to={'/materials/uuid/' + material?.uuid + '/'}>{material?.name}</Link>
        </Grid>,
        <Grid item xs={4}>
          <b>Quantity</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.quantity}kg
        </Grid>
      );
      break;
    case BatchEventType.SPLIT: {
      const parent = batchStore.byId[(node.batch.parentAUuid || node.batch.parentBUuid)!];
      const A = batchStore.byId[parent?.successorAUuid!];
      const B = batchStore.byId[parent?.successorBUuid!];
      eventRows.push(
        <Grid item xs={4}>
          <b>Batch A</b>
        </Grid>,
        <Grid item xs={8}>
          <Link to={'/batches/uuid/' + A?.uuid + '/timeline/'}>{A?.name}</Link>
        </Grid>,
        <Grid item xs={4}>
          <b>Batch B</b>
        </Grid>,
        <Grid item xs={8}>
          <Link to={'/batches/uuid/' + B?.uuid + '/timeline/'}>{B?.name}</Link>
        </Grid>,
        <Grid item xs={4}>
          <b>Batch A Quantity</b>
        </Grid>,
        <Grid item xs={8}>
          {A?.quantity}kg
        </Grid>,
        <Grid item xs={4}>
          <b>Batch B Quantity</b>
        </Grid>,
        <Grid item xs={8}>
          {B?.quantity}kg
        </Grid>
      );
      break;
    }
    case BatchEventType.BUILD:
      const build = buildStore.byId[eventData.buildUuid];
      eventRows.push(
        <Grid item xs={4}>
          <b>Build</b>
        </Grid>,
        <Grid item xs={8}>
          <Link to={'/builds/uuid/' + build?.uuid + '/'}>{build?.name}</Link>
        </Grid>,
        <Grid item xs={4}>
          <b>Quantity Used</b>
        </Grid>,
        <Grid item xs={8}>
          {Number(eventData.quantityConsumed).toFixed(4)} kg
        </Grid>
      );
      break;
    case BatchEventType.SIEVE:
      eventRows.push(
        <Grid item xs={4}>
          <b>Sieve Name</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.sieveName}
        </Grid>,
        <Grid item xs={4}>
          <b>Sieve Type</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.sieveType}
        </Grid>,
        <Grid item xs={4}>
          <b>Mesh Size</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.sieveUnits}
        </Grid>,
        <Grid item xs={4}>
          <b>Sieve Equipment S/N</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.sieveEquipmentSN}
        </Grid>,
        <Grid item xs={4}>
          <b>Lost Quantity</b>
        </Grid>,
        <Grid item xs={8}>
          {Number(eventData.lostQuantity).toFixed(4)} kg
        </Grid>
      );
      break;
    case BatchEventType.TEST:
      // TODO: attachments
      eventRows.push(
        <Grid item xs={4}>
          <b>Test Type</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.testType}
        </Grid>,
        <Grid item xs={4}>
          <b>Test Description</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.testDescription}
        </Grid>,
        <Grid item xs={4}>
          <b>Quantity Used</b>
        </Grid>,
        <Grid item xs={8}>
          {Number(eventData.quantityConsumed).toFixed(4)} kg
        </Grid>,
        <Grid item xs={4}>
          <b>Results</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.results}
        </Grid>
      );
      if (eventData.attachmentUuids.length) {
        eventRows.push(
          <Grid item xs={12}>
            <AttachmentDownloadTable
              attachments={eventData.attachmentUuids.map((auid) => attachmentStore.byId[auid]).filter((f) => f)}
            />
          </Grid>
        );
      }
      break;
    case BatchEventType.CUSTOM:
      eventRows.push(
        <Grid item xs={4}>
          <b>Description</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.description}
        </Grid>,
        <Grid item xs={4}>
          <b>Quantity Consumed</b>
        </Grid>,
        <Grid item xs={8}>
          {Number(eventData.quantityConsumed).toFixed(4)} kg
        </Grid>
      );
      if (eventData.attachmentUuids.length) {
        eventRows.push(
          <Grid item xs={12}>
            <AttachmentDownloadTable
              attachments={eventData.attachmentUuids.map((auid) => attachmentStore.byId[auid]).filter((f) => f)}
            />
          </Grid>
        );
      }
      break;
    case BatchEventType.MEASUREMENT:
      // TODO: Add more things to display
      eventRows.push(
        <Grid item xs={4}>
          <b>Measured Quantity</b>
        </Grid>,
        <Grid item xs={8}>
          {eventData.quantity}
        </Grid>
      );
      break;
    case BatchEventType.RETIRE:
    case BatchEventType.MERGE: {
      const A = batchStore.byId[node.batch?.parentAUuid!];
      const B = batchStore.byId[node.batch?.parentBUuid!];
      eventRows.push(
        <Grid item xs={4}>
          <b>Parent A</b>
        </Grid>,
        <Grid item xs={8}>
          <Link to={'/batches/uuid/' + A?.uuid + '/timeline/'}>{A?.name}</Link>
        </Grid>,
        <Grid item xs={4}>
          <b>Parent B</b>
        </Grid>,
        <Grid item xs={8}>
          <Link to={'/batches/uuid/' + B?.uuid + '/timeline/'}>{B?.name}</Link>
        </Grid>
      );
      break;
    }
    default:
      assertUnreachable(eventData);
  }

  const [popoverIn, setPopoverIn] = useState(false);

  return (
    <React.Fragment>
      <node.icon
        onDoubleClick={() => history.replace('/batches/uuid/' + node.batch.uuid + '/timeline/')}
        aria-owns={open ? 'mouse-over-popover-' + node.batch.uuid : undefined}
        aria-haspopup="true"
        className={node.batch.uuid}
        style={{
          color: '#FFFFFF',
          backgroundColor: props.color,
          borderRadius: 5,
          width: 24,
          height: 24,
          marginLeft: 10,
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />

      <Popover
        id={'mouse-over-popover-' + node.batch.uuid}
        open={open || popoverIn}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{pointerEvents: clicked ? 'inherit' : 'none'}}
        disableRestoreFocus
      >
        <div
          style={{pointerEvents: 'auto'}}
          onMouseEnter={() => setPopoverIn(true)}
          onMouseLeave={() => setPopoverIn(false)}
          onClick={() => {
            setPopoverIn(false);
            handlePopoverClose();
          }}
        >
          <Grid container spacing={2} style={{margin: 20, maxWidth: 500}}>
            <Grid item xs={12}>
              <Typography variant={'h4'}>
                Batch "<Link to={'/batches/uuid/' + node.batch.uuid + '/timeline/'}>{node.batch.name}</Link>"
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <b>Event Type</b>
            </Grid>
            <Grid item xs={8}>
              <b>{batchEventTypeTitle(node.batch.creationEventData.type)}</b>
            </Grid>
            {eventRows}
            <Grid item xs={12}>
              <BatchMaterialCompositionWidget batch={node.batch} elevation={0} size={'small'} />
            </Grid>
            <Grid item xs={4}>
              <b>Batch Name</b>
            </Grid>
            <Grid item xs={8}>
              {node.batch.name}
            </Grid>
            <Grid item xs={4}>
              <b>Description</b>
            </Grid>
            <Grid item xs={8}>
              {node.batch.description}
            </Grid>
            <Grid item xs={4}>
              <b>Quantity</b>
            </Grid>
            <Grid item xs={8}>
              {Number(node.batch.quantity).toFixed()} kg
            </Grid>
          </Grid>
        </div>
      </Popover>
    </React.Fragment>
  );
}

function timeModeColor(timeMode: TimeMode): string {
  switch (timeMode) {
    case TimeMode.FUTURE:
      return grayColor[3];
    case TimeMode.PRESENT:
      return warningColor[3];
    case TimeMode.HISTORY:
      return '#2196f3';
  }
  assertUnreachable(timeMode);
}

function GraphColumn(props: {rowData: TimelineGraphTableRow; maxNodesAcross: number; triggerRedraw: Date}) {
  const rowData: TimelineGraphTableRow = props.rowData;
  let color = timeModeColor(rowData.timeMode);

  const points = rowData.nodes.map((n) => n.index);
  const icons: JSX.Element[] = [];
  for (let i = 0; i <= props.maxNodesAcross; i++) {
    if (points.includes(i)) {
      const node = rowData.nodes.find((n) => n.index === i)!;

      icons.push(<HistoryNodeIcon node={node} color={color} />);
    } else {
      icons.push(
        <FiberManualRecord
          style={{
            color: '#ffffff',
            width: 24,
            height: 24,
            marginLeft: 10,
          }}
        />
      );
    }
  }
  const edgesElements = rowData.edges.map((e) => {
    const [from, to] = e.split('->');
    return (
      <LineTo
        from={from}
        to={to}
        delay={true}
        borderWidth={4}
        borderColor={color}
        triggerRedraw={props.triggerRedraw}
      />
    );
  });

  return (
    <TableCell style={{height: 48, minWidth: props.maxNodesAcross * 34 + 20}} component={'div'} variant={'body'}>
      {icons}
      {edgesElements}
    </TableCell>
  );
}

function getBatchDependencies(batchHistory: IBatch[], batchFuture: IBatch[]): string[] {
  const batches = [...batchHistory!, ...batchFuture!]
    .map((b) => {
      return [b.parentAUuid, b.parentBUuid, b.uuid, b.successorAUuid, b.successorBUuid];
    })
    .flat()
    .filter((s) => s) as string[];

  return uniq(batches);
}

export default function MaterialGraphViewer(props: MaterialGraphViewerProps) {
  // TODO: more granular queries.
  const batchStoreActions = useBatchStoreActions();
  const materialStoreActions = useMaterialStoreActions();
  const buildActions = useBuildStoreActions();

  const [batchHistory, setBatchHistory] = useState<IBatch[] | undefined>([]);
  const [batchFuture, setBatchFuture] = useState<IBatch[] | undefined>([]);

  const buildUuids = [...(batchHistory || []), ...(batchFuture || [])]
    .filter((batch) => batch.creationEventData.type === BatchEventType.BUILD)
    .map((batch) => (batch.creationEventData as BuildEventData).buildUuid);

  const batchUuids = getBatchDependencies(batchHistory || [], batchFuture || []);

  const materialUuids = [...(batchHistory || []), ...(batchFuture || [])]
    .filter(
      (batch) =>
        batch.creationEventData.type === BatchEventType.ADD || batch.creationEventData.type === BatchEventType.INITIAL
    )
    .map((batch) => {
      const eventData = batch.creationEventData as AddEventData | InitialEventData;
      return eventData.materialUuid;
    });

  useEffect(() => {
    if (batchHistory && batchFuture && batchUuids) {
      batchStoreActions.ensureConsistent({uuid: uniq(batchUuids)});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(batchUuids)]);

  useEffect(() => {
    if (batchHistory && batchFuture && materialUuids) {
      materialStoreActions.ensureConsistent({uuid: uniq(materialUuids)});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(materialUuids)]);

  useEffect(() => {
    if (batchHistory && batchFuture && buildUuids) {
      buildActions.ensureConsistent({uuid: uniq(buildUuids)});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(buildUuids)]);

  useEffect(() => {
    if (props.type === 'build') {
      if (props.build.batchUuid) {
        batchStoreActions.ensureConsistent({uuid: props.build.batchUuid});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.type === 'build' ? props.build.batchUuid : '']);

  const batchStore = useSelector((state: RootState) => state.batchStore);
  const materialStore = useSelector((state: RootState) => state.materialStore);
  const buildStore = useSelector((state: RootState) => state.buildStore);

  const [maxNodesAcross, setMaxNodesAcross] = useState(0);
  const [historyRowData, setHistoryRowData] = useState<TimelineGraphTableRow[]>([]);
  const [triggerRedraw, setTriggerRedraw] = useState(new Date());

  let targetBatch: IBatch | undefined = undefined;

  switch (props.type) {
    case 'material':
      // meh.
      break;
    case 'batch':
      targetBatch = props.batch;
      break;
    case 'build':
      targetBatch = batchStore.byId[props.build.batchUuid!];
      break;
    default:
      assertUnreachable(props);
  }

  useEffect(() => {
    if (targetBatch) {
      Promise.all([batchHistoryGET(targetBatch.uuid), batchFutureGET(targetBatch.uuid)]).then(([history, future]) => {
        if (!history.success) {
          // NOP, error handled by REST call
        } else if (!future.success) {
          // NOP, error handled by REST call
        } else {
          setBatchHistory(history.data);
          setBatchFuture(future.data);
        }
      });
    }
  }, [targetBatch]);

  useEffect(() => {
    if (batchHistory && batchFuture) {
      const dependencies = getBatchDependencies(batchHistory, batchFuture);
      for (const d of dependencies) {
        if (!batchStore.byId[d]) {
          // Batch dependency not loaded, and will cause errors
          return;
        }
      }
      const future = batchesToFutureGraphTimelineRows(batchFuture, materialStore, batchStore, buildStore);
      const past = batchesToHistoryGraphTimelineRows(batchHistory, materialStore, batchStore, buildStore);

      setHistoryRowData(
        future.futureTableRowData.slice(1, future.futureTableRowData.length).reverse().concat(past.historyTableRowData)
      );
      setMaxNodesAcross(Math.max(future.maxNodesAcross, past.maxNodesAcross));
    }
  }, [batchHistory, batchFuture, batchStore, buildStore, materialStore]);

  useEffect(() => {
    const handleResize = () => {
      setTriggerRedraw(new Date());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderQtyColumn = (args: {rowData: TimelineGraphTableRow}) => {
    const rowData: TimelineGraphTableRow = args.rowData;
    return (
      <TableCell style={{height: 48}} component={'div'} variant={'body'}>
        {rowData.nodes.map((n) => (
          <Typography key={n.index}>
            {rowData.timeMode === TimeMode.PRESENT ? (
              <b>{Number(n.batch.quantity).toFixed(3)} kg</b>
            ) : (
              `${Number(n.batch.quantity).toFixed(3)} kg`
            )}
          </Typography>
        ))}
      </TableCell>
    );
  };

  const renderQtyChangeColumn = (args: {rowData: TimelineGraphTableRow}) => {
    const rowData: TimelineGraphTableRow = args.rowData;

    return (
      <TableCell style={{height: 48}} component={'div'} variant={'body'}>
        {rowData.nodes.map((n) => {
          let quantityChange: string = '';

          switch (n.batch.creationEventData.type) {
            case BatchEventType.BUILD:
            case BatchEventType.TEST:
              if (n.batch.creationEventData.quantityConsumed) {
                quantityChange = `- ${n.batch.creationEventData.quantityConsumed?.toFixed(3)} kg`;
              }
              break;
            case BatchEventType.SIEVE:
              if (n.batch.creationEventData.lostQuantity) {
                quantityChange = `- ${n.batch.creationEventData.lostQuantity?.toFixed(3)} kg`;
              }
              break;
            case BatchEventType.ADD:
            case BatchEventType.INITIAL:
              if (n.batch.creationEventData.quantity) {
                quantityChange = `+ ${n.batch.creationEventData.quantity?.toFixed(3)} kg`;
              }
              break;
            default:
              break;
          }
          return (
            <Typography key={n.index}>
              {rowData.timeMode === TimeMode.PRESENT ? <b>{quantityChange}</b> : quantityChange}
            </Typography>
          );
        })}
      </TableCell>
    );
  };

  const renderDescriptionColumn = (args: {rowData: TimelineGraphTableRow}) => {
    const rowData: TimelineGraphTableRow = args.rowData;
    return (
      <TableCell style={{height: 48}}>
        {rowData.nodes.map((n) => (
          <Typography key={n.index}>{rowData.timeMode === TimeMode.PRESENT ? <b>{n.text}</b> : n.text}</Typography>
        ))}
      </TableCell>
    );
  };

  const renderDateColumn = (args: {rowData: TimelineGraphTableRow}) => {
    const rowData: TimelineGraphTableRow = args.rowData;
    return (
      <TableCell style={{height: 48}} component={'div'} variant={'body'}>
        {rowData.nodes.map((n) => (
          <Typography key={n.index}>
            {rowData.timeMode === TimeMode.PRESENT ? (
              <b>{renderDateString('short', n.date)}</b>
            ) : (
              renderDateString('short', n.date)
            )}
          </Typography>
        ))}
      </TableCell>
    );
  };

  const renderHeader = (props: {label: string}) => {
    return (
      <TableCell component="div" variant="head" style={{height: 48}}>
        <span>{props.label}</span>
      </TableCell>
    );
  };

  return (
    <Card className="rt-batch-timeline">
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              {renderHeader({label: 'Graph'})}
              {renderHeader({label: 'Current Qty'})}
              {renderHeader({label: 'Qty Change'})}
              {renderHeader({label: 'Event'})}
              {renderHeader({label: 'Date'})}
            </TableRow>
          </TableHead>
          <TableBody>
            {historyRowData.map((rowData) => {
              return (
                <TableRow>
                  <GraphColumn rowData={rowData} maxNodesAcross={maxNodesAcross} triggerRedraw={triggerRedraw} />
                  {renderQtyColumn({rowData})}
                  {renderQtyChangeColumn({rowData})}
                  {renderDescriptionColumn({rowData})}
                  {renderDateColumn({rowData})}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
