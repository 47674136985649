import React from 'react';
import {Tooltip} from '@material-ui/core';
import {Layers} from '@material-ui/icons';
import {ViewerButton, ViewerButtonActive, ActiveDiv} from '../../FadingComponents';
import {BUTTON_MARGIN, BUTTON_HEIGHT} from '../utils';

interface LinkLayerProps {
  handleLinkedLayers: () => void;
  mouseOn: boolean;
  linkedLayers: boolean | undefined;
}

export default function LinkLayer({handleLinkedLayers, mouseOn, linkedLayers}: LinkLayerProps) {
  return (
    <>
      {linkedLayers ? (
        <Tooltip title="Unlink layer slider" placement="left">
          <ViewerButtonActive
            onClick={handleLinkedLayers}
            style={{
              right: BUTTON_MARGIN,
              top: BUTTON_HEIGHT + BUTTON_MARGIN,
              position: 'absolute',
            }}
            className={(!mouseOn && 'fade') || undefined}
          >
            <ActiveDiv>
              <Layers />
            </ActiveDiv>
          </ViewerButtonActive>
        </Tooltip>
      ) : (
        <Tooltip title="Link layer slider" placement="left">
          <ViewerButton
            onClick={handleLinkedLayers}
            style={{
              right: BUTTON_MARGIN,
              top: BUTTON_HEIGHT + BUTTON_MARGIN,
              position: 'absolute',
            }}
            className={(!mouseOn && 'fade') || undefined}
          >
            <Layers />
          </ViewerButton>
        </Tooltip>
      )}
    </>
  );
}
