import {DeviceDetailedState, DeviceState} from '@common/api/models/devices/IDevice';
import * as React from 'react';
import {assertUnreachable} from '@common/utils/utils';
import {Grid} from '@material-ui/core';

export interface DeviceStatusProps {
  state?: DeviceState;
  stateDetailed?: DeviceDetailedState;
  extraText?: string;
  online: boolean;
  isStaging?: boolean;
}

function DeviceStatus(props: DeviceStatusProps) {
  let stateText;
  switch (props.state) {
    case DeviceState.IDLE:
    case undefined:
      stateText = props.isStaging ? 'Staging' : 'Idle';
      break;
    case DeviceState.STAGING:
      stateText = 'Staging';
      break;
    case DeviceState.MONITORING:
      stateText = 'Monitoring';
      break;
    case DeviceState.CALIBRATING:
      stateText = 'Calibrating';
      break;
    case DeviceState.PREVIEWING:
      stateText = 'Manual Focusing';
      break;
    case DeviceState.FOCUSING:
      stateText = 'Focusing';
      break;
    default:
      assertUnreachable(props?.state);
  }

  switch (props.stateDetailed) {
    case undefined:
    case DeviceDetailedState.Unknown:
    case DeviceDetailedState.Idling:
    case DeviceDetailedState.Staging:
    case DeviceDetailedState.Previewing:
    case DeviceDetailedState.Focusing:
    case DeviceDetailedState.Monitoring:
      break;
    case DeviceDetailedState.StagingStarting:
    case DeviceDetailedState.PreviewingStarting:
    case DeviceDetailedState.FocusingStarting:
    case DeviceDetailedState.CalibratingStarting:
    case DeviceDetailedState.MonitoringStarting:
      stateText += ' (starting)';
      break;
    case DeviceDetailedState.StagingStopping:
    case DeviceDetailedState.PreviewingStopping:
    case DeviceDetailedState.FocusingStopping:
    case DeviceDetailedState.CalibratingStopping:
    case DeviceDetailedState.MonitoringStopping:
      stateText += ' (stopping)';
      break;
    case DeviceDetailedState.CalibratingCapturing:
      stateText += ' (capturing)';
      break;
    case DeviceDetailedState.CalibratingCalculating:
      stateText += ' (calculating)';
      break;
    default:
      assertUnreachable(props.stateDetailed);
  }

  if (!props.online) {
    stateText += ', Offline';
  }

  return (
    <Grid container spacing={1}>
      <Grid item data-testid="device-status-text">
        {stateText}
        {props.extraText || ''}
      </Grid>
    </Grid>
  );
}

export default DeviceStatus;
