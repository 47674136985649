import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {buildsAllGET} from '../../../../api/ajax/builds';
import {FilterSchemaType} from './index';

export const similarityReportDefaultFilters = {
  sortBy: {createdAt: 'DESC'},
};

const similarityReportFilters: Array<FilterSchemaType> = [
  {field: 'name', type: 'string'},
  {
    field: 'partName',
    label: 'Source Part Name',
    type: 'string',
  },
  {
    field: 'buildName',
    label: 'Source Build Name',
    type: 'string',
  },
  {
    field: 'buildUuid',
    label: 'Source Build',
    type: 'resourceSelect',
    searchGET: buildsAllGET,
  },
  {
    field: 'status',
    type: 'enumMultiple',
    enumValues: Object.values(SimilarityStatus),
    enumLabels: {
      [SimilarityStatus.Success]: 'Completed',
      [SimilarityStatus.Failure]: 'Failed',
      [SimilarityStatus.Generating]: 'Generating',
      [SimilarityStatus.Submitted]: 'Submitted',
    },
  },
  {
    field: 'targetPartCount',
    label: '# Target Parts',
    type: 'int',
  },
  {
    field: 'buildEndDate',
    label: 'Source Build End Date',
    type: 'dateRange',
  },
  {field: 'createdAt', label: 'Created At', type: 'dateRange'},
];

export default similarityReportFilters;
