import {Pagination} from '@common/api/models/common';
export enum SensorProfileState {
  VALIDATING = 'validating',
  INVALID = 'invalid',
  VALID = 'valid',
}

export interface ISensorProfile {
  uuid: string;
  name: string;
  profileUrl: string;
  state: SensorProfileState;
  updatedAt: Date;
  createdAt: Date;
  deviceSerial: string;
}

export interface ISensorProfileGETResponse extends Omit<ISensorProfile, 'profileUrl'> {
  buildsCount: number;
}

export interface ISensorProfileGETRequest extends Pagination {
  name?: string | {like: string};
  deviceSerial?: string | {in: Array<string>} | {notIn: Array<string>};
  uuid?: string | {in: Array<string>} | {notIn: Array<string>};
}

export type ISensorProfilePUTRequest =
  | {
      name: string;
    }
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      profileJSON: {[key: string]: any};
    }
  | {
      profileJSON: string;
      name: string;
    };

export type ISensorProfileConfigDefaultsResponse = {configDefaults: string};
export type ISensorProfileYAMLResponse = {profileYAML: string};

export interface ISensorProfilePOSTRequest {
  name: string;
  deviceSerial: string;
  profileJSON: string;
}
