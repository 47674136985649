import {clamp} from 'lodash';

// Function to Transform Values Between Intervals
// Takes 2 Intervals as Arguments and returns a Function
// The Function returns the equivalent value from the original interval on the transformed interval
// Typical Use Cases: Temperature Conversion, Colormaps

// Eg: transformInterval([0,100],[-10,10])
export function transformInterval(initialInterval: number[], transformInterval: number[]) {
  return (t: number) =>
    clamp(
      transformInterval[0] +
        ((transformInterval[1] - transformInterval[0]) / (initialInterval[1] - initialInterval[0])) *
          (t - initialInterval[0]),
      transformInterval[0],
      transformInterval[1]
    );
}
