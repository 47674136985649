import React, {useEffect} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Theme,
  Grid,
} from '@material-ui/core';
import styled, {withTheme} from 'styled-components';

import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {useMaterialStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {BatchPageProps} from '../../../pages/materials/batchPages';

import PieChart from '../../atoms/Charts/PieChart';
import HorizontalBarChart from '../../atoms/Charts/HorizontalBarChart';

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ChartWrapper = styled.div`
  height: ${(props: {height: number}) => props.height}px;
`;

function BatchMaterialCompositionWidget(
  props: BatchPageProps & {
    elevation?: number;
    maxWidth?: number;
    size?: 'small' | 'medium';
    compact?: boolean;
  } & {theme: Theme}
) {
  const sortedMaterialsByWeight = Array.from(props.batch.materialComposition);
  sortedMaterialsByWeight.sort((a, b) => a.weight - b.weight).reverse();
  const {theme} = props;
  const history = useHistory();

  const materialStoreActions = useMaterialStoreActions();

  useEffect(() => {
    materialStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const materialStore = useSelector((state: RootState) => {
    return state.materialStore;
  });

  let height = 300;
  switch (props.size) {
    case 'small':
      height = 120;
      break;
    case 'medium':
      height = 168;
      break;
  }
  let margin: Partial<Plotly.Margin> = {t: 10, b: 20, r: 10};
  if (props.compact) {
    height = 120;
    margin = {t: 10, b: 0, r: 0};
  }

  const barChartData: Partial<Plotly.PlotData>[] = sortedMaterialsByWeight.map((material, index) => {
    return {
      name: materialStore.byId[material.materialUuid]?.name,
      x: [+(material.weight * props.batch.quantity).toFixed(3)],
      y: [materialStore.byId[material.materialUuid]?.name],
      marker: {
        // FIXME: Move CSS value out into a file
        color: index === 0 ? theme.palette.primary.main : 'rgb(191,215,234)',
      },
      hoverinfo: 'x+name',
    };
  });
  const barChartLayout: Partial<Plotly.Layout> = {
    xaxis: {
      tick0: 0,
      showticksuffix: 'all',
      ticksuffix: 'kg',
    },
  };

  return (
    <Card elevation={props.elevation} style={{maxWidth: props.maxWidth}} className="rt-batch-material-comp">
      <CardHeader title={`Material composition of "${props.batch.name}"`} style={props.compact ? {padding: 0} : {}} />
      <CardContent style={props.compact ? {padding: 0} : {}}>
        <Grid container spacing={2}>
          <Grid item xs={props.size === 'small' ? 4 : 12}>
            <ChartWrapper height={height}>
              {props.size === 'small' ? (
                <PieChart
                  labels={sortedMaterialsByWeight.map((mat) => materialStore.byId[mat.materialUuid]?.name)}
                  data={sortedMaterialsByWeight.map((mat) => mat.weight * props.batch.quantity)}
                  height={height}
                  width={height}
                />
              ) : (
                <HorizontalBarChart data={barChartData} layout={barChartLayout} margin={margin} height={height} />
              )}
            </ChartWrapper>
          </Grid>
          <Grid item xs={props.size === 'small' ? 8 : 12}>
            <Table size={props.size}>
              <TableHead>
                <TableRow>
                  <TableCell>Material</TableCell>
                  <TableCell>{props.size === 'small' ? 'kg' : 'Composition (kg)'}</TableCell>
                  <TableCell>%</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedMaterialsByWeight.map((m) => {
                  const mat = materialStore.byId[m.materialUuid];
                  return (
                    <TableRow
                      hover
                      onClick={() => history.push('/materials/uuid/' + m.materialUuid) + '/'}
                      style={{
                        cursor: 'pointer',
                        height: props.size === 'small' ? 'unset' : undefined,
                      }}
                      key={m.materialUuid}
                    >
                      <TableCell>{mat?.name}</TableCell>
                      <TableCell>{(m.weight * props.batch.quantity).toFixed(2)}</TableCell>
                      <TableCell>{(m.weight * 100).toFixed(1)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withTheme(BatchMaterialCompositionWidget);
