import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {ShowChart} from '@material-ui/icons';
import {Grid, Typography} from '@material-ui/core';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {PlotData} from 'plotly.js';
import HorizontalBarChart from '../../../components/atoms/Charts/HorizontalBarChart';
import LoadingPage from '../../../components/organisms/LoadingPage';
import {HoveredSelection, SetHoveredSelection} from '../BuildIntensityPage';
import {ChartDataType} from './ChartOptionsModel';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const NoPartsSelected = ({height}: {height?: number | undefined}) => {
  return (
    <Grid
      style={{
        height: height ? `${height}` : '100%',
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <ShowChart />
      <Typography>Select data to display</Typography>
    </Grid>
  );
};

const IntensitySummaryBar = ({
  hoveredSelection,
  setHoveredSelection,
  d3Color,
}: {
  d3Color: d3.scale.Ordinal<string, string>;
  hoveredSelection: HoveredSelection;
  setHoveredSelection: SetHoveredSelection;
}) => {
  const isSmallScreen = useSmallScreenSize();

  const selectedParts = useSelector((state: RootState) => state.buildIntensityReportStore.selectedParts);
  const selectedBuilds = useSelector((state: RootState) => state.buildIntensityReportStore.selectedBuilds);
  const summaryCSVs = useSelector((state: RootState) => state.buildIntensityReportStore.summaryCSVs);
  const chartDataType = useSelector((state: RootState) => state.buildIntensityReportStore.chartDataType);
  const buildNamesByBuildUuid = useSelector(
    (state: RootState) => state.buildIntensityReportStore.buildNamesByBuildUuid
  );

  let isLoading = true;
  const neededBuildCSVs = new Set([...selectedBuilds, ...Array.from(selectedParts).map((part) => part.buildUuid)]);
  const loadedBuildCSVs = Array.from(neededBuildCSVs).filter((buildUuid) => !!summaryCSVs[buildUuid]).length;

  if (neededBuildCSVs.size === 0) {
    isLoading = true;
  } else if (loadedBuildCSVs === neededBuildCSVs.size) {
    isLoading = false;
  } else {
    isLoading = true;
  }

  if (selectedParts.size === 0 && selectedBuilds.size === 0) return <NoPartsSelected />;
  if (isLoading) return <LoadingPage loadingText="Loading statistics..." />;

  const data: Partial<PlotData>[] = [];

  Array.from(selectedBuilds).forEach((buildUuid: string) => {
    const summaryData = summaryCSVs[buildUuid];
    const values = summaryData[chartDataType];
    const sum = values.reduce((acc, val) => acc + Number(val), 0);
    let value: number = 0;

    if (chartDataType === ChartDataType.MaxCount) {
      const maxValues = summaryData[ChartDataType.Max];
      const maxValue = Math.max(...maxValues);
      value = values.filter((value, index) => maxValues[index] === maxValue).reduce((acc, value) => acc + value, 0);
    } else if (chartDataType === ChartDataType.TotalCount) {
      value = sum;
    } else if (chartDataType === ChartDataType.Max) {
      value = Math.max(...values);
    } else if (chartDataType === ChartDataType.Min) {
      value = Math.min(...values);
    } else {
      value = sum / values.length;
    }
    const isHovered = hoveredSelection?.type === 'build' && hoveredSelection.uuid === buildUuid;
    const color = d3Color(buildUuid);

    data.push({
      y: [buildNamesByBuildUuid[buildUuid]],
      x: [value],
      customdata: ['build', buildUuid],
      marker: {
        color: !hoveredSelection || isHovered ? color : '#b0aeae55',
      },
      hovertemplate: `${buildNamesByBuildUuid[buildUuid]}: <b>%{x}</b><extra></extra>`,
    });
  });

  Array.from(selectedParts).forEach((part: IPartGETResponse) => {
    const summaryData = summaryCSVs[part.buildUuid];
    let partIndex: number = -1;

    for (let i = 0; i < summaryData.part_uuid.length; i++) {
      if (summaryData.part_uuid[i] === part.uuid) {
        partIndex = i;
        break;
      }
    }
    if (partIndex >= 0) {
      const color = d3Color(part.uuid);
      const isHovered = hoveredSelection?.type === 'part' && hoveredSelection.uuid === part.uuid;

      data.push({
        y: [part.name],
        x: [summaryData[chartDataType][partIndex]],
        customdata: ['part', part.uuid],
        marker: {
          color: !hoveredSelection || isHovered ? color : '#b0aeae55',
        },
        hovertemplate: `${part.name}: <b>%{x}</b><extra></extra>`,
      });
    }
  });

  const maxX = Math.max(...data.map((d) => d.x![0] as number));

  const labels = data.map((d) => d.y![0] as string);
  const truncatedLabels = data.map((d) => `${(d.y![0] as string).slice(0, 12)}...`);

  return (
    <div style={{width: '100%', height: '100%'}}>
      <HorizontalBarChart
        data={data}
        disableZoom={true}
        barmode={'group'}
        layout={{
          dragmode: false,
          autosize: true,
          margin: {l: 0, r: 0, b: 0, t: 0, pad: 0},
          showlegend: false,
          barmode: 'group',
          xaxis: {
            fixedrange: true,
            showticklabels: true,
            automargin: true,
            range: [0, [ChartDataType.MaxCount, ChartDataType.TotalCount].includes(chartDataType) ? maxX * 1.1 : 4096],
          },
          yaxis: {
            fixedrange: true,
            automargin: true,
            tickvals: labels,
            ticktext: isSmallScreen ? truncatedLabels : labels,
          },
          hovermode: 'closest',
        }}
        onHover={(eventData) => {
          if (eventData.points[0].customdata) {
            setHoveredSelection({
              type: eventData.points[0].data.customdata[0] as 'part' | 'build',
              uuid: eventData.points[0].data.customdata[1] as string,
            });
          }
        }}
        onUnhover={() => setHoveredSelection(null)}
      />
    </div>
  );
};

export default IntensitySummaryBar;
