import {Button} from '@material-ui/core';
import * as React from 'react';
import {useState} from 'react';
import {IMaterial} from '@common/api/models/materials/IMaterial';
import MaterialSelectorModal from './MaterialSelectorModal';

export interface MaterialSelectorButtonProps {
  selectedMaterial?: IMaterial;
  placeholder: string;
  onSelect: (material: IMaterial | undefined) => any;
  fullWidth?: boolean;
}

export function MaterialSelectorButton(props: MaterialSelectorButtonProps) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        fullWidth={Boolean(props.fullWidth)}
        color="primary"
        variant="outlined"
        onClick={() => setModalOpen(true)}
      >
        {props.selectedMaterial ? props.selectedMaterial.name : <i>{props.placeholder}</i>}
      </Button>

      <MaterialSelectorModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        initiallySelectedUuid={props.selectedMaterial?.uuid}
        onMaterialSelected={(m) => {
          props.onSelect(m);
          setModalOpen(false);
        }}
      />
    </React.Fragment>
  );
}
