import axios from 'axios';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {IUser} from '@common/api/models/users/IUser';

export async function loginPOST(email: string, password: string): AjaxApiResult<IUser> {
  return ajaxWrapper(() => {
    return axios.post('/api/auth/login', {email, password});
  }, 'Could not log in');
}

export async function loginGET(): AjaxApiResult<IUser> {
  return ajaxWrapper(
    () => {
      return axios.get('/api/auth/login', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
    },
    undefined,
    false
  );
}

export async function authenticateGET(): AjaxApiResult<IUser> {
  return ajaxWrapper(
    () => {
      return axios.get('/api/auth/authenticate', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
    },
    undefined,
    false
  );
}

export async function logoutPOST(): AjaxApiResult<{}> {
  return ajaxWrapper(() => {
    return axios.post('/api/auth/logout');
  }, 'Could not logout');
}

export async function logoutGET(): AjaxApiResult<{logoutURL: string}> {
  return ajaxWrapper(() => {
    return axios.get('/api/auth/logout');
  }, 'Could not logout');
}

export async function changeOwnPasswordPOST(oldPassword: string, newPassword: string): AjaxApiResult<IUser> {
  return ajaxWrapper(() => {
    return axios.post('/api/auth/changePassword/', {oldPassword, newPassword});
  }, 'Could not change password');
}

export async function resendEmailConfirmation() {
  return ajaxWrapper(() => {
    return axios.get('/api/auth/resendEmailConfirmation/');
  }, 'Could not resend email');
}
