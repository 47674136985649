import {FiltersState} from '../IndexPage';
import {FilterSchemaType} from './index';

export const materialDefaultFilters: Partial<FiltersState> = {
  sortBy: {
    dateReceived: 'DESC',
  },
};

export const materialFilterSchema: Array<FilterSchemaType> = [
  {
    field: 'name',
    type: 'string',
  },
  {
    field: 'type',
    type: 'string',
  },
  {
    field: 'grade',
    type: 'string',
  },
  {
    field: 'supplier',
    type: 'string',
  },
  {
    field: 'quantityRemaining',
    label: 'Quantity',
    type: 'float',
    min: 0,
  },
  {
    field: 'manufactured',
    type: 'dateRange',
  },
  {
    field: 'dateReceived',
    label: 'Received',
    type: 'dateRange',
  },
];
