export enum BuildDataArchiveActions {
  BUILD_DATA_ARCHIVE_DOWNLOAD_START = 'BUILD_DATA_ARCHIVE_DOWNLOAD_START',
  BUILD_DATA_ARCHIVE_DOWNLOAD_PROGRESS = 'BUILD_DATA_ARCHIVE_DOWNLOAD_PROGRESS',
  BUILD_DATA_ARCHIVE_DOWNLOAD_FINISH = 'BUILD_DATA_ARCHIVE_DOWNLOAD_FINISH',
  BUILD_DATA_ARCHIVE_DOWNLOAD_ERROR = 'BUILD_DATA_ARCHIVE_DOWNLOAD_ERROR',
  BUILD_DATA_ARCHIVE_SET_NUM_DOWNLOADS = 'BUILD_DATA_ARCHIVE_SET_NUM_DOWNLOADS',
}

export type SingleDownload = {
  filename: string;
  url: string;
  loaded: number;
  total: number;
};
export enum BuildDataDownloadStatus {
  IN_PROGRESS,
  COMPLETED,
  ERROR,
}

export type BuildDataArchive = {
  downloads: SingleDownload[];
  totalProgress: number;
  zipFilename: string;
  status: BuildDataDownloadStatus;
  errorMessage?: string;
  blob?: Blob;
};

export interface BuildDataArchiveStoreState {
  buildDataDownloads: BuildDataArchive[];
  numDownloads: number;
}

export const initialBuildDataArchiveState: BuildDataArchiveStoreState = {
  buildDataDownloads: [],
  numDownloads: 0,
};

interface BuildDataArchiveActionType<_T, P> {
  type: BuildDataArchiveActions;
  payload: P;
}

export type BuildDataArchiveStartAction = BuildDataArchiveActionType<
  BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_START,
  BuildDataArchive
>;

export type BuildDataArchiveProgressAction = BuildDataArchiveActionType<
  BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_PROGRESS,
  {index: number; filename: string; loaded: number; total: number}
>;

export type BuildDataArchiveFinishAction = BuildDataArchiveActionType<
  BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_FINISH,
  {index: number; blob: Blob}
>;

export type BuildDataArchiveErrorAction = BuildDataArchiveActionType<
  BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_FINISH,
  {index: number; errorMessage: string}
>;

export type BuildDataArchiveSetNumDownloads = BuildDataArchiveActionType<
  BuildDataArchiveActions.BUILD_DATA_ARCHIVE_SET_NUM_DOWNLOADS,
  {numDownloads: boolean}
>;
