import {Button, Card, Grid, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {inflect} from 'inflection';
import {BuildAttachmentType} from '@common/api/models/attachments/IAttachmentBase';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {BuildPhotosModal} from '../Modals/BuildPhotosModal';
import {RootState} from '../../../store/reducers';
import {useBuildAttachmentStoreActions} from '../../../store/actions';

interface BuildPhotosModalProps {
  buildUuid: string;
  orgUuid: string;
}

export const BuildPhotosSection = (props: BuildPhotosModalProps) => {
  const [isBuildPhotosOpen, setBuildPhotosOpen] = useState<boolean>(false);
  const buildPhotos = useSelector((state: RootState) => state.buildAttachmentStore.byId);
  const isSmall = useSmallScreenSize();
  const attachmentStoreActions = useBuildAttachmentStoreActions();

  const photosForBuildCount = Object.values(buildPhotos).filter(
    (att) =>
      props.buildUuid === att.buildUuid &&
      [BuildAttachmentType.BuildPhoto, BuildAttachmentType.BuildPhotoMain].includes(att.type)
  ).length;

  function setOpen(open: boolean) {
    setBuildPhotosOpen(open);
    if (!open) {
      attachmentStoreActions.ensureConsistent({buildUuid: props.buildUuid});
    }
  }

  return (
    <Card style={{padding: isSmall ? '15px' : '30px'}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={isSmall ? 12 : 4} style={{display: 'flex'}} alignItems="center">
          <Typography variant="h6" gutterBottom display="inline">
            Build Photos
          </Typography>
        </Grid>

        <Grid item xs={isSmall ? 12 : 5} style={{display: 'flex'}} alignItems="center">
          {photosForBuildCount} {inflect('Photo', photosForBuildCount)}
        </Grid>

        <Grid item xs={isSmall ? 12 : 3}>
          <Button
            style={{fontWeight: 400, float: 'right'}}
            fullWidth={isSmall}
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            View Gallery
          </Button>
        </Grid>
      </Grid>

      <BuildPhotosModal
        buildUuid={props.buildUuid}
        orgUuid={props.orgUuid}
        isOpen={isBuildPhotosOpen}
        setOpen={setOpen}
      />
    </Card>
  );
};
