import React from 'react';
import {Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers/index';
import {Alert} from '@material-ui/lab';

const SourceToSourceWarningMessage = () => {
  const sourceToSourceWarningMessage = useSelector(
    (state: RootState) => state.similarityReportStore.currentSimilarityReport!.sourceWarningMessage
  );

  if (!sourceToSourceWarningMessage) return <></>;

  return (
    <Alert severity="warning" style={{width: '100%', marginBottom: '12px'}}>
      <Typography>
        Source part model generation completed with warning: <b>{sourceToSourceWarningMessage}</b>
      </Typography>
    </Alert>
  );
};

export default SourceToSourceWarningMessage;
