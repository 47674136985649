import {cloneDeep} from 'lodash';
import {
  BuildDataArchiveActions,
  BuildDataArchiveErrorAction,
  BuildDataArchiveFinishAction,
  BuildDataArchiveSetNumDownloads,
  BuildDataArchiveStartAction,
  BuildDataArchiveStoreState,
  BuildDataDownloadStatus,
  initialBuildDataArchiveState,
} from '../model/buildDataArchive';
import createReducer from './createReducer';
import {BuildDataArchiveProgressAction} from '../model/buildDataArchive';

export const buildDataArchive = createReducer<BuildDataArchiveStoreState>(initialBuildDataArchiveState, {
  [BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_START](
    state: BuildDataArchiveStoreState,
    action: BuildDataArchiveStartAction
  ) {
    return {
      ...state,
      buildDataDownloads: [...state.buildDataDownloads, action.payload],
    };
  },

  [BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_PROGRESS](
    state: BuildDataArchiveStoreState,
    action: BuildDataArchiveProgressAction
  ) {
    const {index, filename, loaded, total} = action.payload;

    const buildDataDownload = state.buildDataDownloads[index];
    const thisDownload = buildDataDownload.downloads.find((download) => download.filename === filename)!;
    thisDownload.loaded = loaded;
    thisDownload.total = total;

    const allLoaded = buildDataDownload.downloads.reduce((sum, download) => sum + download.loaded, 0);
    const allTotal = buildDataDownload.downloads.reduce((sum, download) => sum + download.total, 0);

    buildDataDownload.totalProgress = (allLoaded / allTotal) * 0.99;

    const newBuildDataDownload = cloneDeep(state.buildDataDownloads);
    newBuildDataDownload.splice(action.payload.index, 1, buildDataDownload);

    return {
      ...state,
      buildDataDownloads: newBuildDataDownload,
    };
  },

  [BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_ERROR](
    state: BuildDataArchiveStoreState,
    action: BuildDataArchiveErrorAction
  ) {
    const buildDataDownload = state.buildDataDownloads[action.payload.index];
    buildDataDownload.status = BuildDataDownloadStatus.ERROR;
    buildDataDownload.errorMessage = action.payload.errorMessage;

    const newBuildDataDownload = cloneDeep(state.buildDataDownloads);
    newBuildDataDownload.splice(action.payload.index, 1, buildDataDownload);

    return {
      ...state,
      buildDataDownloads: newBuildDataDownload,
    };
  },

  [BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_FINISH](
    state: BuildDataArchiveStoreState,
    action: BuildDataArchiveFinishAction
  ) {
    const buildDataDownload = state.buildDataDownloads[action.payload.index];
    buildDataDownload.totalProgress = 1;
    buildDataDownload.status = BuildDataDownloadStatus.COMPLETED;
    buildDataDownload.blob = action.payload.blob;

    const newBuildDataDownload = cloneDeep(state.buildDataDownloads);
    newBuildDataDownload.splice(action.payload.index, 1, buildDataDownload);

    return {
      ...state,
      buildDataDownloads: newBuildDataDownload,
    };
  },

  [BuildDataArchiveActions.BUILD_DATA_ARCHIVE_SET_NUM_DOWNLOADS](
    state: BuildDataArchiveStoreState,
    action: BuildDataArchiveSetNumDownloads
  ) {
    return {
      ...state,
      numDownloads: action.payload.numDownloads,
    };
  },
});
