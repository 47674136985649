import {BuildState} from '@common/api/models/builds/IBuild';
import {machinesAllGET} from '../../../../api/ajax/machines';
import {batchesAllGET} from '../../../../api/ajax/batches';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {FilterSchemaType} from './index';

export const buildDefaultFilters = {
  sortBy: {createdAt: 'DESC'},
};

const buildFilters: Array<FilterSchemaType> = [
  {field: 'name', type: 'string'},
  {field: 'state', type: 'enumMultiple', enumValues: Object.values(BuildState)},
  {
    field: 'machineUuid',
    label: 'machine',
    type: 'resourceSelect',
    searchGET: machinesAllGET,
  },
  {
    field: 'batchUuid',
    label: 'batch',
    labelFn: (batch: IBatch) => `${batch.name} - ${batch.current ? 'current' : 'old'}`,
    type: 'resourceSelect',
    searchGET: batchesAllGET,
  },
  {field: 'start', label: 'Start date', type: 'dateRange'},
  {field: 'end', label: 'End date', type: 'dateRange'},
  {field: 'createdAt', label: 'Created date', type: 'dateRange'},
];

export default buildFilters;
