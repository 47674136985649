import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DeviceHosts} from '@common/api/models/devices/IDevice';
import {isPrivateIp} from '../../../../utils/ipChecks';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import {DarkAnchor} from '../../../../components/molecules/DarkAnchor';

function useWebcamDetails(build: IBuild, webcamHosts?: DeviceHosts) {
  const [webcamURL, setWebcamURL] = useState('');
  const {machinePermission} = usePermissionsForBuild(build);

  const tooltipTitle = !machinePermission
    ? "You don't have permission to access this build's machine."
    : !webcamURL
    ? 'This device does not have a webcam feed available'
    : '';
  const hostsWithoutLocal = webcamHosts?.filter((host) => !host.includes('.local'));

  // Show all hosts for on-prem, exclude hosts with .local for cloud based
  const hostsToShow = process.env.REACT_APP_IS_ON_PREMISE === 'true' ? webcamHosts : hostsWithoutLocal;
  const privateHosts = hostsToShow?.filter((host) => isPrivateIp(host));

  useEffect(() => {
    if (hostsToShow?.length) {
      if (privateHosts?.length) {
        setWebcamURL(privateHosts[privateHosts.length - 1]);
      } else {
        setWebcamURL(hostsToShow[hostsToShow.length - 1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostsToShow]);

  return {tooltipTitle, hostsToShow, webcamURL, setWebcamURL};
}

export default useWebcamDetails;

export const AllWebcamHosts = ({
  webcamURL,
  hostsToShow,
  setWebcamURL,
  isInTooltip,
}: {
  webcamURL: string;
  hostsToShow?: DeviceHosts;
  setWebcamURL: (url: string) => void;
  isInTooltip?: boolean;
}) => {
  const hostNotActive = hostsToShow?.filter((host) => host !== webcamURL) || [];

  const Anchor = isInTooltip ? DarkAnchor : 'a';

  return (
    <>
      <Typography>Viewing webcam on host:</Typography>
      <ul>
        <li style={{fontSize: '14px', fontWeight: 400}}>
          <Anchor href={webcamURL} target="_blank" rel="noopener noreferrer" onClick={() => setWebcamURL(webcamURL)}>
            {webcamURL}
          </Anchor>
        </li>
      </ul>
      {!!hostNotActive.length && (
        <>
          <Typography>Or, try another host:</Typography>
          <ul>
            {hostNotActive?.map((host) => (
              <li style={{fontSize: '14px', fontWeight: 400}}>
                <Anchor href={host} target="_blank" rel="noopener noreferrer" onClick={() => setWebcamURL(host)}>
                  {host}
                </Anchor>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};
