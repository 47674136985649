import axios from 'axios';
import * as qs from 'qs';
import {IMetricsResponse} from '@common/api/models/metrics';
import {IMetricsGETRequest} from '@common/api/models/metrics';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';

export async function metricsGET(filter: IMetricsGETRequest): AjaxApiResult<IMetricsResponse> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/metrics/' + (query && '?' + query));
  }, 'Could not fetch metrics');
}
