import React from 'react';
import moment from 'moment';
import inflection from 'inflection';
import {useWatch, Controller} from 'react-hook-form';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';
import {DateTimeRangeSelectorModal} from '../../../../components/molecules/Selector/DateTimeRangeSelectorModal';
import {FilterComponentProps} from '../FilterModal';
import {newDebouncer} from '../../../../api/ajax';

interface DateRange<T> {
  since?: T;
  until?: T;
}

interface MinMax {
  min?: ParsableDate;
  max?: ParsableDate;
}

const debounceSearch = newDebouncer();

const DateRangeFilter = ({
  field,
  control,
  label,
  dateType,
  onSubmit,
  filterListLocation,
}: FilterComponentProps & {dateType: 'date' | 'dateTime'}) => {
  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSearch(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  const timestamps: DateRange<string> | undefined =
    useWatch({
      name: field,
      control: control,
    }) ?? {};

  const transformValueIn = () => {
    return {
      min: !!timestamps.since ? moment(timestamps.since) : undefined,
      max: !!timestamps.until ? moment(timestamps.until) : undefined,
    };
  };

  const transformValueOut = (dates: MinMax) => {
    return {
      since: !!dates.min ? moment(dates.min).format() : undefined,
      until: !!dates.max ? moment(dates.max).format() : undefined,
    };
  };

  const handleSetSelectedDates = (dates: MinMax) => {
    control.setValue(field, transformValueOut(dates));
    submitField();
  };

  return (
    <Controller
      control={control}
      name={field}
      as={
        <DateTimeRangeSelectorModal
          selectedDates={transformValueIn()}
          showTimeSelection={dateType === 'dateTime'}
          setSelectedDates={handleSetSelectedDates}
          buildDateRange={{max: moment()}}
          isDataLive={true}
          label={label || inflection.humanize(inflection.underscore(field))}
        />
      }
    />
  );
};

export default DateRangeFilter;
