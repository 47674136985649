const cookieConfig = (logout: () => void): UserConfig => {
  return {
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,
    autorun: true,
    cookie_domain: 'addassure.com',
    languages: {
      en: {
        consent_modal: {
          title: 'Cookies',
          description: 'This website uses essential cookies to ensure its proper operation.',
          primary_btn: {
            text: 'Accept all',
            role: 'accept_all',
          },
          secondary_btn: {
            text: 'Reject all',
            role: 'accept_necessary',
          },
        },
        settings_modal: {
          title: 'Cookie Settings',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [],
          blocks: [
            {
              title: 'Authentication',
              description: 'We use cookies to Authenticate users across our platform',
              toggle: {
                value: 'auth',
                enabled: false,
                readonly: false,
              },
            },
          ],
        },
      },
    },
    delay: 1000,
    onFirstAction(userPreferences, _cookie) {
      if (userPreferences.accept_type !== 'all') {
        (window as any).CC.eraseCookies('cc_cookie');
        logout();
      }
    },
  };
};

export default cookieConfig;
