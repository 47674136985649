import React from 'react';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import SimilarityHeader from '../shared/SimilarityHeader';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {Box} from '@material-ui/core';
import ComparisonWeights from './ComparisonWeights';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import Header from '../../../components/organisms/Header';

const SimilarityCreateHeader = () => {
  const isSmallScreen = useSmallScreenSize();
  const reportName = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport.name);
  const backupName = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport.backupName);

  const {setDraftSimilarityReport} = useSimilarityReportStoreActions();

  const onNameUpdated = async (newName: string) => {
    setDraftSimilarityReport('name', newName);
    return true;
  };

  return (
    <Header
      helmet={`${reportName || backupName} Similarity Report`}
      title={
        <Box display="flex" alignItems="center" width="100%">
          <SimilarityHeader reportName={reportName || backupName} onChangeAccepted={onNameUpdated} />
        </Box>
      }
      breadcrumbs={[{title: 'Similarity Reports', path: '/reports/similarity/'}, reportName || backupName]}
      endAdornment={isSmallScreen ? undefined : <ComparisonWeights />}
    />
  );
};

export default SimilarityCreateHeader;
