import React, {useEffect, useMemo, useReducer, useState} from 'react';
import styled from 'styled-components';
import useImage from 'use-image';
import {CircularProgress, Box, Typography, Tooltip, makeStyles} from '@material-ui/core';
import {Help} from '@material-ui/icons';
import {SelfManagedSingleImageViewport} from '../../components/molecules/Viewport/2D/SelfManagedSingleImageViewport';
import locationBasedDefectsHelperPNG from '../../assets/img/location-based-defects-helper.png';
import {grayColor} from '../../assets/jss/material-dashboard-react';
import {HoveredMmAxisTransforms} from '../../components/molecules/Viewport/2D/SingleImageViewport';
import {BUTTON_HEIGHT, BUTTON_MARGIN, generateColourMaps} from '../../components/molecules/Viewport/2D/utils';
import CmSelector from '../../components/molecules/Viewport/2D/controls/CmSelector';
import {cmap} from '../../utils/colormap';
import CrosshairsToggle from '../../components/molecules/Viewport/2D/CrosshairsToggle';
import {LocationBasedDefectTypes} from './ViewportTabs';

const useStyles = makeStyles((_theme) => ({
  customWidth: {
    maxWidth: `min(90vw + 16px, ${256 + 16}px)`,
  },
}));

const AXIS_LABELS = {
  x: 'Side view (y,z)',
  y: 'Front view (x,z)',
  z: 'Top View (x,y)',
};

const AXIS_TRANSFORMS: {[axis: string]: HoveredMmAxisTransforms} = {
  z: {x: 'x', y: 'y'},
  y: {x: 'x', y: 'z'},
  x: {x: 'y', y: 'z'},
};

function HeatmapViewport({
  locationBasedDefectType,
  signedUrl,
  height,
  axis,
  hoveredPosition,
  setHoveredPosition,
  currentColourMap,
  setColourMap,
  crosshairsEnabled,
  setCrosshairsEnabled,
  maxValue,
}: {
  locationBasedDefectType: LocationBasedDefectTypes;
  signedUrl?: string;
  height: number;
  axis: 'x' | 'y' | 'z';
  hoveredPosition?: {x?: number; y?: number} | null;
  setHoveredPosition?: (hoveredPosition: {x?: number; y?: number; z?: number} | null) => void;
  currentColourMap: string;
  setColourMap: (colourMap: string) => void;
  crosshairsEnabled: boolean;
  setCrosshairsEnabled: (crosshairsEnabled: boolean) => void;
  maxValue: number | undefined;
}) {
  const [image, loaded] = useImage(signedUrl || '', 'anonymous');
  const [colouredImage, setColouredImage] = useState<HTMLImageElement | null>(null);
  const [forcedRender, forceRender] = useReducer((x) => x + 1, 0);

  const tooltipStyles = useStyles();

  const colorMappings = useMemo(() => {
    if (currentColourMap === 'raw') return [];
    return cmap({
      colormap: currentColourMap,
      nshades: 256,
    });
  }, [currentColourMap]);

  useEffect(() => {
    if (!signedUrl || ['loading', 'failed'].includes(loaded) || !image) return;

    if (currentColourMap === 'raw') {
      setColouredImage(image);
      return;
    }

    setColouredImage(generateColourMaps(image, [1, 255], colorMappings, forceRender, false));

    return () => {
      setColouredImage(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorMappings, image, loaded, signedUrl]);

  const imageContext = useMemo(() => {
    if (!image) return null;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set canvas dimensions equal to image size
    canvas.width = image.width;
    canvas.height = image.height;
    if (!context) return null;

    // Draw the image onto the canvas
    context.drawImage(image, 0, 0, image.width, image.height);

    return context;
  }, [image]);

  const dataPointValue = useMemo(() => {
    if (!maxValue) return null;
    if (!hoveredPosition?.x || !hoveredPosition?.y) return null;
    if (!imageContext || !image) return null;

    const {x, y} = hoveredPosition;

    // Get image data for the specified pixel
    const yHeightMm = image.height * 0.13;
    const pixelData = imageContext.getImageData(Math.round(x / 0.13), Math.round((yHeightMm - y) / 0.13), 1, 1).data;
    const value = (pixelData[0] / 255) * maxValue;

    if (value === 0) return null;

    if (locationBasedDefectType !== LocationBasedDefectTypes.OBJECT) {
      return `${(value * 100).toFixed(2)}%`;
    }

    return `${value.toFixed(2)}mm`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredPosition?.x, hoveredPosition?.y, imageContext, maxValue, image]);

  if (!signedUrl || !colouredImage || ['loading', 'failed'].includes(loaded) || !image) {
    return (
      <Box
        display="flex"
        width="100%"
        height={height}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {!signedUrl || loaded === 'failed' ? (
          <Typography variant="h6">No {`${AXIS_LABELS[axis].toLowerCase()} axis`} image available</Typography>
        ) : (
          <>
            <CircularProgress />
            <Typography variant="h6" style={{marginTop: '12px'}}>
              Loading...
            </Typography>
          </>
        )}
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h6" style={{marginBottom: '6px'}}>
        {AXIS_LABELS[axis]}{' '}
        <Tooltip
          classes={{tooltip: tooltipStyles.customWidth}}
          title={
            <img
              src={locationBasedDefectsHelperPNG}
              alt="Describing the axis view into the build plate"
              style={{width: `min(90vw, 256px)`}}
            />
          }
        >
          <Help style={{color: grayColor[3], marginBottom: '-4px'}} />
        </Tooltip>
      </Typography>
      <SelfManagedSingleImageViewport
        key={forcedRender}
        image={colouredImage}
        imageWidthPixels={colouredImage.width}
        imageHeightPixels={colouredImage.height}
        // 250mm x 250mm plate comes out to a 1924 x 1924 pixel image
        mmPerPixel={0.13}
        height={height}
        showHoveredMmPosition
        hoveredMmAxisTransforms={AXIS_TRANSFORMS[axis]}
        hoveredPosition={crosshairsEnabled ? hoveredPosition : undefined}
        setHoveredPosition={setHoveredPosition}
        dataPointValue={dataPointValue !== null ? dataPointValue : undefined}
        isLocationBasedDefectsViewport
      >
        <CmSelector
          mouseOn
          stageHeight={height - BUTTON_HEIGHT * 2}
          currentColourMap={currentColourMap}
          setColourMap={setColourMap}
          isLocationBasedDefects
        />
        <CrosshairsToggle
          mouseOn
          crosshairsEnabled={crosshairsEnabled}
          setCrosshairsEnabled={setCrosshairsEnabled}
          left={BUTTON_MARGIN * 2}
          top={height - BUTTON_HEIGHT * 3}
        />

        {maxValue && (
          <DataScale
            colorMappings={colorMappings}
            height={height}
            maxValue={locationBasedDefectType === LocationBasedDefectTypes.OBJECT ? maxValue : maxValue * 100}
            locationBasedDefectType={locationBasedDefectType}
          />
        )}
      </SelfManagedSingleImageViewport>
    </>
  );
}

export default HeatmapViewport;

const DataScale = React.memo(
  ({
    locationBasedDefectType,
    colorMappings,
    height,
    maxValue,
  }: {
    locationBasedDefectType: LocationBasedDefectTypes;
    colorMappings: [number, number, number][];
    height: number;
    maxValue: number;
  }) => {
    return (
      <Box
        position="absolute"
        left={BUTTON_MARGIN * 2}
        top={height - BUTTON_HEIGHT * 2}
        display="flex"
        alignItems="center"
      >
        <StyledTypography style={{marginRight: '10px'}}>
          0{locationBasedDefectType !== LocationBasedDefectTypes.OBJECT ? '%' : ' mm'}
        </StyledTypography>
        <Box display="flex" width="150px" height="10px">
          {ONE_HUNDRED_POINTS.map((_, index: number) => {
            const colour = colorMappings[Math.round((index + 1) * (colorMappings.length / 100))];
            if (!colour) return <React.Fragment key={`no-colour-${index}`}></React.Fragment>;

            return (
              <Box
                key={`${colour}-${index}`}
                width="1%"
                height="100%"
                style={{
                  backgroundColor: `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`,
                }}
              />
            );
          })}
        </Box>
        <StyledTypography style={{marginLeft: '10px'}}>
          {maxValue?.toFixed(2)}
          {locationBasedDefectType !== LocationBasedDefectTypes.OBJECT ? '%' : ' mm'}
        </StyledTypography>
      </Box>
    );
  }
);

const StyledTypography = styled(Typography)`
  color: white;
  font-weight: bold;
  display: inline;
`;

const ONE_HUNDRED_POINTS = Array(100).fill(0);
