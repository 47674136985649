import AWS, {Credentials} from 'aws-sdk';
import * as dotenv from 'dotenv';

dotenv.config();

// Override credentials for dev work
if (process.env.USE_ENV_CREDENTIALS === 'true' && !!(process.env.AWS_ACCESS && process.env.AWS_SECRET)) {
  AWS.config.update({
    credentials: new Credentials(process.env.AWS_ACCESS, process.env.AWS_SECRET),
  });
  console.log('overriding auto credentials method.');
}

// AWS endpoint overrides for on-premise deployments; http://<url>:<port>
const endpointUrl: string | undefined = process.env.AWS_ENDPOINT_URL;
const s3EndpointUrl: string | undefined = process.env.AWS_S3_ENDPOINT_URL;

export function s3Client(s3EndpointUrl: string): AWS.S3 {
  return new AWS.S3({
    endpoint: s3EndpointUrl,
    signatureVersion: 'v4',
    s3ForcePathStyle: true,
  });
}

// Instantiate AWS resources
export const s3 = s3Client(s3EndpointUrl);
export const sns = new AWS.SNS({endpoint: endpointUrl});
export const sqs = new AWS.SQS({endpoint: endpointUrl});
export const ses = new AWS.SES({endpoint: endpointUrl});
