import {Box, IconButton, Tooltip, TooltipProps, Typography, useTheme} from '@material-ui/core';
import React, {useState} from 'react';
import styled from 'styled-components';
import {successColor, grayColor} from '../../../../assets/jss/material-dashboard-react';

// TODO: This should come from midrig and be stored on the Device table
export const CAMERA_ORDER = [
  [3, 1],
  [4, 2],
  [7, 5],
  [8, 6],
];

function FieldOfViewStates({
  fieldOfViews,
  icon,
  globalStateTitle,
  heading,
  offColour = grayColor[3],
  globalState,
  helperText,
  placement,
}: {
  fieldOfViews?: (boolean | null)[][];
  icon: React.ReactNode;
  globalStateTitle?: React.ReactNode | string;
  heading: React.ReactNode | string;
  offColour: string;
  globalState: boolean;
  helperText?: string;
  placement?: TooltipProps['placement'];
}) {
  const [keepOpen, setKeepOpen] = useState(false);
  const theme = useTheme();

  if (!fieldOfViews) return <></>;

  return (
    <Tooltip
      open={keepOpen ? true : undefined}
      disableFocusListener={keepOpen}
      disableHoverListener={keepOpen}
      disableTouchListener={keepOpen}
      placement={placement}
      title={
        <Box display="flex" flexDirection="column" alignItems="center" margin="8px">
          {globalStateTitle && (
            <>
              {globalStateTitle}
              <br />
            </>
          )}
          <Typography>{heading}</Typography>
          <Box display="flex" justifyContent="center" width="100%" margin="12px 0px">
            {fieldOfViews.map((fov, i) => (
              <Box
                display="flex"
                flexDirection="column"
                key={i}
                height="50px"
                justifyContent="space-between"
                margin="0px 20px"
              >
                {fov.map((active, j) => {
                  const number = CAMERA_ORDER[i][j] || 0;

                  if (active === null) return <> </>;

                  return <Dot key={`${i}-${j}`} active={active} offColour={offColour} number={number} />;
                })}
              </Box>
            ))}
          </Box>
          {helperText && (
            <Typography variant="caption">
              <i>{helperText}</i>
            </Typography>
          )}
        </Box>
      }
    >
      <IconButton
        onClick={() => setKeepOpen(!keepOpen)}
        style={{
          position: 'relative',
          color: keepOpen ? theme.palette.primary.light : undefined,
          padding: '0px 0px 0px 12px',
        }}
      >
        {icon}
        <GlobalCameraStateDot active={globalState} offColour={offColour} />
      </IconButton>
    </Tooltip>
  );
}

export default FieldOfViewStates;

const Dot: React.FC<DotProps> = ({active, offColour, number}) => (
  <StyledDot active={active} offColour={offColour}>
    {number}
  </StyledDot>
);

interface DotProps {
  active: boolean;
  offColour: string;
  number: number;
}

const StyledDot = styled.div<{active: boolean; offColour: string}>`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({active, offColour}) => (active ? successColor[1] : offColour)};
  box-shadow: 0px 0px 3px 1px ${({active, offColour}) => (active ? successColor[1] : offColour)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
`;

const GlobalCameraStateDot = styled(StyledDot)`
  position: absolute;
  top: 0px;
  right: -5px;
  border: 2px solid white;
  height: 12px;
  width: 12px;
`;
