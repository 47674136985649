import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Tooltip, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {BurstMode, Delete} from '@material-ui/icons';
import {
  BuildDataArchiveFile,
  BuildDataArchiveStatus,
  IBuildDataArchiveGETResponse,
} from '@common/api/models/builds/data/IBuildDataArchive';
import CustomButton from '../../../atoms/CustomButton';
import {buildDataArchiveDELETE, buildDataArchiveGET, buildDataArchivePOST} from '../../../../api/ajax/buildDataArchive';
import {GenericDialog} from '../../DialogButton';
import {RootState} from '../../../../store/reducers';
import ConditionalTooltip from '../../../atoms/Texts/ConditionalTooltip';
import DownloadDataModal from './DownloadDataModal';
import {IBuild, isCompletedBuildState} from '@common/api/models/builds/IBuild';
import buildDataArchiveActions from '../../../../store/actions/buildDataArchiveActions';
import DownloadStartedModal from './DownloadStartedModal';
import {validateEmail} from '@common/api/models/users/User.validation';

interface BuildArchiveDownloadProps {
  build: IBuild;
}

const BuildDataArchiveDownload = ({build}: BuildArchiveDownloadProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiRequesting, setIsApiRequesting] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [hasDownloadStarted, setHasDownloadStarted] = useState(false);
  const [buildDataArchive, setBuildDataArchive] = useState<IBuildDataArchiveGETResponse>();
  const [selectedDownloadItems, setSelectedDownloadItems] = useState<Array<BuildDataArchiveFile>>([]);
  const [sendEmail, setSendEmail] = useState<boolean>(!!currentUser.email && !!currentUser.emailConfirmed);

  const archiveIsGenerating =
    !!buildDataArchive &&
    [BuildDataArchiveStatus.Submitted, BuildDataArchiveStatus.Generating].includes(buildDataArchive?.status);

  const fetchBuildDataArchive = async () => {
    const res = await buildDataArchiveGET(build.uuid);
    if (res.success) {
      setBuildDataArchive(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setBuildDataArchive(undefined);
    fetchBuildDataArchive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.uuid]);

  // Poll for status update every 10 seconds, if currently generating.
  useEffect(() => {
    let interval: null | ReturnType<typeof setInterval> = null;
    if (archiveIsGenerating) {
      interval = setInterval(fetchBuildDataArchive, 10_000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveIsGenerating]);

  async function handleGenerate() {
    setIsApiRequesting(true);
    const res = await buildDataArchivePOST(build.uuid, sendEmail);
    if (res.success) {
      setBuildDataArchive(res.data);
    }
    setIsApiRequesting(false);
  }

  async function handleDownload() {
    setIsApiRequesting(true);
    dispatch(
      buildDataArchiveActions.downloadData(build.uuid, selectedDownloadItems, `${build.name}-bulk-data-download.zip`)
    );
    setHasDownloadStarted(true);
  }

  async function handleDelete() {
    setIsApiRequesting(true);
    const res = await buildDataArchiveDELETE(build.uuid);
    if (res.success) {
      setBuildDataArchive(undefined);
    }
    setIsApiRequesting(false);
  }

  function closeDialog() {
    setIsApiRequesting(false);
    setSelectedDownloadItems([]);
    setIsDownloadModalOpen(false);
    setHasDownloadStarted(false);
  }

  if (isLoading) return <></>;

  const generating = isApiRequesting || archiveIsGenerating;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h6'}>Download Bulk Build Data</Typography>
      </Grid>
      {!buildDataArchive && (
        <Grid item xs={12}>
          <Typography>Data has yet to be compressed.</Typography>
        </Grid>
      )}
      {buildDataArchive?.status === BuildDataArchiveStatus.Failure && (
        <Grid item xs={12}>
          <Alert severity="error">Data compression failed. Please contact support.</Alert>
        </Grid>
      )}
      {archiveIsGenerating && (
        <Grid item xs={12}>
          <Alert severity="info">Data compression in progress...</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        {buildDataArchive?.status === BuildDataArchiveStatus.Success ? (
          <CustomButton
            text="Download data"
            onClick={() => setIsDownloadModalOpen(true)}
            disabled={isApiRequesting}
            variant={'outlined'}
            color={'primary'}
            startIcon={<BurstMode />}
          />
        ) : (
          <ConditionalTooltip
            hideTooltip={!(!buildDataArchive && !isCompletedBuildState(build.state))}
            tooltip={'Can only generate data archive for finished builds'}
          >
            <CustomButton
              text="Begin data compression"
              onClick={handleGenerate}
              variant={'outlined'}
              color={'primary'}
              disabled={
                generating ||
                buildDataArchive?.status === BuildDataArchiveStatus.Failure ||
                (!buildDataArchive && !isCompletedBuildState(build.state))
              }
              startIcon={<BurstMode />}
              loading={generating}
            />
          </ConditionalTooltip>
        )}
        {buildDataArchive &&
          [BuildDataArchiveStatus.Success, BuildDataArchiveStatus.Failure].includes(buildDataArchive.status) && (
            <Tooltip title={<Typography>Remove data archive</Typography>}>
              <IconButton aria-label="delete" onClick={handleDelete} disabled={isApiRequesting}>
                {isApiRequesting ? <CircularProgress size={20} /> : <Delete />}
              </IconButton>
            </Tooltip>
          )}
      </Grid>
      {buildDataArchive?.status !== BuildDataArchiveStatus.Success && (
        <Grid item xs={12}>
          <ConditionalTooltip
            tooltip={
              !validateEmail(currentUser.email).success
                ? 'Your email is invalid, please set your email in your user profile.'
                : 'Your email is unconfirmed'
            }
            hideTooltip={validateEmail(currentUser.email).success && !!currentUser.emailConfirmed}
          >
            <FormControlLabel
              label="Notify via email when compression is complete"
              disabled={
                generating ||
                buildDataArchive?.status === BuildDataArchiveStatus.Failure ||
                !currentUser.email ||
                !currentUser.emailConfirmed
              }
              control={<Checkbox checked={sendEmail} onChange={(_e, _v) => setSendEmail(!sendEmail)} />}
            />
          </ConditionalTooltip>
        </Grid>
      )}

      {!!buildDataArchive && (
        <GenericDialog
          title="Download data"
          content={
            hasDownloadStarted ? (
              <DownloadStartedModal />
            ) : (
              <DownloadDataModal
                files={buildDataArchive.files}
                createdAt={buildDataArchive.createdAt}
                selectedDownloadItems={selectedDownloadItems}
                setSelectedDownloadItems={setSelectedDownloadItems}
              />
            )
          }
          confirmText="Download data"
          closeText={hasDownloadStarted ? 'Close' : 'Cancel'}
          isOpen={isDownloadModalOpen}
          closeDialog={closeDialog}
          onSuccess={hasDownloadStarted ? undefined : handleDownload}
          confirmDisabled={isApiRequesting || !selectedDownloadItems.length}
          requestInProgress={hasDownloadStarted ? undefined : isApiRequesting}
          maxWidth="lg"
        />
      )}
    </>
  );
};

export default BuildDataArchiveDownload;
