import React from 'react';
import {Card, CardContent, CardHeader, Tooltip, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import RadialBarChart from '../../../components/atoms/ApexCharts/RadialBarChart';
import {Info} from '@material-ui/icons';
import {MachineIdleTimeType} from '@common/api/models/metrics';
import {CenteredDiv} from './SwitchableChart';
import waitingForSomethingPNG from '../../../assets/img/waiting-for-something.png';
import {useSmallScreenSize} from '../../../utils/utilHooks';

function MachineUtilization({machineIdleTime}: {machineIdleTime: MachineIdleTimeType}) {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();

  const trimmedNames = Object.values(machineIdleTime).map((machineIdleTime) =>
    machineIdleTime.name.length > 14 ? machineIdleTime.name.slice(0, 12) + '...' : machineIdleTime.name
  );

  function onDataPointSelection(dataPointIndex: number) {
    const machineUuid = Object.keys(machineIdleTime)[dataPointIndex];
    history.push(`/machines/uuid/${machineUuid}`);
  }

  if (Object.values(machineIdleTime).length === 0) {
    return (
      <Card style={{height: '100%'}}>
        <CardHeader title="Machine utilization" style={{paddingBottom: '0px'}} />
        <CenteredDiv>
          <Typography variant="h4">No machines utilized...</Typography>

          <img
            src={waitingForSomethingPNG}
            style={{height: '150px', margin: '1rem 0px 2rem'}}
            alt="bird chirping musically in a park"
          />
        </CenteredDiv>
      </Card>
    );
  }

  const totalAvg =
    Object.values(machineIdleTime).reduce(
      (sum, current) => sum + Number(((1 - current.idleTime) * 100).toFixed(0)),
      0
    ) / Object.values(machineIdleTime).length;

  return (
    <Card style={{height: '100%'}}>
      <CardHeader
        title={
          <>
            Machine utilization{' '}
            <Tooltip title={<Typography>Percentage of time your machine was actively building parts</Typography>}>
              <Info
                color="action"
                style={{
                  marginLeft: '6px',
                  marginBottom: '-4px',
                  width: '22px',
                  height: '22px',
                }}
              />
            </Tooltip>
          </>
        }
        style={{paddingBottom: '0px'}}
      />

      <CardContent>
        <RadialBarChart
          options={{
            labels: Object.values(machineIdleTime).length > 6 ? ['Total'] : trimmedNames,
            fill: {
              colors: undefined,
            },
            chart: {
              events: {
                dataPointSelection: (_event, _context, config) => onDataPointSelection(config.dataPointIndex),
              },
            },
            legend: {show: !isSmallScreen && Object.values(machineIdleTime).length <= 6},
          }}
          series={
            Object.values(machineIdleTime).length > 6
              ? [totalAvg]
              : Object.values(machineIdleTime).map((machineIdleTime) =>
                  Number(((1 - machineIdleTime.idleTime) * 100).toFixed(0))
                )
          }
          height={230}
          valueFormatter={(val) => {
            if (isNaN(val)) return val;
            return `${Number(val).toFixed(0)}%`;
          }}
        />
      </CardContent>
    </Card>
  );
}

export default MachineUtilization;
