import {IBuildGetRequest} from '@common/api/models/builds/IBuild';
import {BuildIntensityReportStatus} from '@common/api/models/builds/data/IBuildIntensityReport';
import {FilterSchemaType} from './index';
import {buildsAllGET} from '../../../../api/ajax/builds';
import {BuildState} from '@common/api/models/builds/IBuild';
import {QueryFilter} from '../../../../store/actions/liveUpdateStore';

export const buildIntensityReportDefaultFilters = {
  sortBy: {createdAt: 'DESC'},
};

export const COMPLETED_BUILD_FILTER = {
  state: {
    in: [BuildState.COMPLETED, BuildState.FAILED, BuildState.SUCCEEDED],
  },
};

const buildIntensityReportFilters: Array<FilterSchemaType> = [
  {
    field: 'buildName',
    label: 'Build Name',
    type: 'string',
  },
  {
    field: 'buildUuid',
    label: 'Build',
    type: 'resourceSelect',
    searchGET: (filters: QueryFilter<IBuildGetRequest>) =>
      buildsAllGET({
        ...filters,
        hasIntensityReport: true,
      }),
  },
  {
    field: 'status',
    type: 'enumMultiple',
    enumValues: Object.values(BuildIntensityReportStatus),
    enumLabels: {
      [BuildIntensityReportStatus.Success]: 'Completed',
      [BuildIntensityReportStatus.Failure]: 'Failed',
      [BuildIntensityReportStatus.Generating]: 'Generating',
      [BuildIntensityReportStatus.Submitted]: 'Submitted',
    },
  },
  {
    field: 'createdAt',
    label: 'Created at',
    type: 'dateRange',
  },
  {
    field: 'buildEndDate',
    label: 'Build end date',
    type: 'dateRange',
  },
];

export default buildIntensityReportFilters;
