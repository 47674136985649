import React from 'react';
import {IconButton} from '@material-ui/core';
import {Remove} from '@material-ui/icons';
import {Control, useWatch} from 'react-hook-form';
import {cloneDeep, set} from 'lodash';
import {
  IDefectNotificationConditions,
  ConditionGroup,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {dangerColor} from '../../../../../assets/jss/material-dashboard-react';

function RemoveCondition({
  path,
  control,
  reset,
  index,
  rootIndex,
}: {
  path: string;
  control: Control<IDefectNotificationConditions>;
  reset: (newForm: IDefectNotificationConditions) => void;
  rootIndex: number;
  index: number;
}) {
  const wholeForm: IDefectNotificationConditions | undefined = useWatch({control}) as IDefectNotificationConditions;
  const conditionGroup: ConditionGroup | undefined = useWatch({
    name: path,
    control: control,
  });

  const conditions = conditionGroup?.conditions;

  function onRemoveCondition() {
    if (!conditions) return;

    const newConditions = cloneDeep(conditions);
    newConditions.splice(index!, 1);

    const newForm = cloneDeep(wholeForm!);

    if (newConditions.length === 0) {
      const topLevelConditions = newForm.conditionGroups;
      topLevelConditions.splice(rootIndex!, 1);
      set(newForm, 'conditionGroups', topLevelConditions);
    } else {
      set(newForm, `${path}.conditions`, newConditions);
    }
    reset(newForm);
  }

  return (
    <IconButton style={{color: dangerColor[0]}} onClick={onRemoveCondition}>
      <Remove />
    </IconButton>
  );
}

export default RemoveCondition;
