import {IDevice} from '@common/api/models/devices/IDevice';

import {
  LiveStoreDeleteAction,
  LiveStoreFinishFetchAction,
  LiveStoreState,
  LiveStoreUpdateAction,
} from '../../model/liveUpdateStore';
import {LiveStoreReducers, ReducerFunctions} from '../liveUpdateStore';

export interface DeviceStoreState extends LiveStoreState<IDevice> {
  byMachineUuid: {[key: string]: IDevice};
}

export class DeviceLiveStoreReducer extends LiveStoreReducers<IDevice, DeviceStoreState> {
  getReducerFunctions(
    getStoreId: (payload: IDevice) => string,
    getStoreIndex: (payload: IDevice, store: LiveStoreState<IDevice>) => number
  ): ReducerFunctions<IDevice, DeviceStoreState> {
    const superReducers = super.getReducerFunctions(getStoreId, getStoreIndex);
    return {
      ...superReducers,
      update(state: DeviceStoreState, action: LiveStoreUpdateAction<IDevice>): DeviceStoreState {
        const storeId = getStoreId(action.payload);
        const byMachineUuid = Object.assign({}, state.byMachineUuid);

        // Delete old reference in byMachines
        const oldMachineUuid = state.byId[storeId]?.machineUuid;
        if (oldMachineUuid) {
          delete byMachineUuid[oldMachineUuid];
        }

        // Update byMachines with new reference
        const newMachineUuid = action.payload.machineUuid;
        if (newMachineUuid) {
          byMachineUuid[newMachineUuid] = action.payload;
        }

        return superReducers.update({...state, byMachineUuid}, action);
      },

      delete(state: DeviceStoreState, action: LiveStoreDeleteAction<IDevice>) {
        superReducers.delete(state, action);

        const storeId = getStoreId(action.payload);
        const byMachineUuid = Object.assign({}, state.byMachineUuid);

        // Delete old reference in byMachines
        const machineUuid = state.byId[storeId]?.machineUuid;
        if (machineUuid) {
          delete byMachineUuid[machineUuid];
        }

        return {...state, byMachineUuid};
      },

      finishFetch(state: DeviceStoreState, action: LiveStoreFinishFetchAction<IDevice>): DeviceStoreState {
        const byMachineUuid = Object.assign({}, state.byMachineUuid);

        const data = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data];
        for (const device of data) {
          const storeId = getStoreId(device);

          // Delete old reference in byMachines
          const oldMachineUuid = state.byId[storeId]?.machineUuid;
          if (oldMachineUuid) {
            delete byMachineUuid[oldMachineUuid];
          }

          // Update byMachines with new reference
          const newMachineUuid = device.machineUuid;
          if (newMachineUuid) {
            byMachineUuid[newMachineUuid] = device;
          }
        }

        return superReducers.finishFetch({...state, byMachineUuid}, action);
      },
    };
  }
}
