import {assertUnreachable} from '@common/utils/utils';

/**
 * @description
 * Device states implicitly defined by 'state' methods in AA_midrig's `remote_listener.py` process.
 */
export enum DeviceState {
  IDLE = 'idling',
  STAGING = 'staging',
  MONITORING = 'monitoring',
  CALIBRATING = 'calibrating',
  PREVIEWING = 'previewing',
  FOCUSING = 'focusing',
}

export enum DeviceMode { // todo consolidate with OperationMode in api/midrig/messages
  Focus = 'focus',
  Preview = 'preview',
  Calibration = 'calibration',
  Monitor = 'monitor',
}

export type DeviceSortOptions = 'deviceId' | 'model' | 'createdAt' | 'availability' | 'machineName';

/**
 * @description
 * Detailed device states explicitly defined by AA_midrig's `build_state.py`.
 */
export enum DeviceDetailedState {
  Unknown = 'Unknown',
  Idling = 'Idling',
  StagingStarting = 'StagingStarting',
  Staging = 'Staging',
  StagingStopping = 'StagingStopping',
  PreviewingStarting = 'PreviewingStarting',
  Previewing = 'Previewing',
  PreviewingStopping = 'PreviewingStopping',
  FocusingStarting = 'FocusingStarting',
  Focusing = 'Focusing',
  FocusingStopping = 'FocusingStopping',
  CalibratingStarting = 'CalibratingStarting',
  CalibratingCapturing = 'CalibratingCapturing',
  CalibratingCalculating = 'CalibratingCalculating',
  CalibratingStopping = 'CalibratingStopping',
  MonitoringStarting = 'MonitoringStarting',
  Monitoring = 'Monitoring',
  MonitoringStopping = 'MonitoringStopping',
}

export enum DeviceAvailability {
  Busy = 'Busy',
  Available = 'Available',
  Unavailable = 'Unavailable',
  BusyOffline = 'Busy (offline)',
  AvailableOffline = 'Available (offline)',
  UnavailableOffline = 'Unavailable (offline)',
}

export function isDeviceOnline(availability: DeviceAvailability) {
  switch (availability) {
    case DeviceAvailability.Busy:
    case DeviceAvailability.Available:
    case DeviceAvailability.Unavailable:
      return true;
    case DeviceAvailability.BusyOffline:
    case DeviceAvailability.AvailableOffline:
    case DeviceAvailability.UnavailableOffline:
      return false;
  }
  assertUnreachable(availability);
}

export interface IDevice {
  serial: string;
  uuid: string;

  machineUuid?: string;

  deviceId: string;
  organizationUuid: string;
  hwVer?: string;
  swVer?: string;
  model: string;
  createdAt: Date;
  active: boolean;
  lastActivityTime?: Date;
  online: boolean;
  previewHosts?: DeviceHosts;
  focusResult?: DeviceFocusingData;
  supportsAutofocus: boolean;
  webcamHosts?: DeviceHosts;

  state: DeviceState;
  stateDetailed: DeviceDetailedState;
  buildUuid?: string;

  currentConfigUrl?: string; // S3 URI
  defaultConfigUrl?: string; // S3 URI
  updatedAt?: Date;
  sensorProfileUuid?: string;

  laserStates?: CameraStateType;
  cameraStates?: CameraStateType;
}

export type CameraStateType = {[id: string]: boolean};
export type DeviceHosts = string[];

export interface CameraFocusingResultSuccess {
  success: true;
  result: number;
  timestamp: Date;
}

export interface CameraFocusingResultFailure {
  success: false;
  message: string;
  timestamp: Date;
}

export type CameraFocusingResult = CameraFocusingResultSuccess | CameraFocusingResultFailure;

export interface CameraFocusingResultPending {
  pending: true;
}

export interface CameraFocusingResultComplete {
  pending: false;
  result: CameraFocusingResult;
}

export type CameraFocusingState = CameraFocusingResultPending | CameraFocusingResultComplete;

export interface DeviceFocusingData {
  cameras: {[cameraNumber: number]: CameraFocusingState};
}

export function isStoppableDeviceState(state: DeviceState) {
  switch (state) {
    case DeviceState.IDLE:
    case DeviceState.STAGING:
      return false;
    case DeviceState.MONITORING:
    case DeviceState.CALIBRATING:
    case DeviceState.PREVIEWING:
    case DeviceState.FOCUSING:
      return true;
  }
  assertUnreachable(state);
}

export function isOccupiedDeviceState(state: DeviceState) {
  switch (state) {
    case DeviceState.IDLE:
      return false;
    case DeviceState.STAGING:
    case DeviceState.MONITORING:
    case DeviceState.CALIBRATING:
    case DeviceState.PREVIEWING:
    case DeviceState.FOCUSING:
      return true;
  }
  assertUnreachable(state);
}

export function isCalibrating(state: DeviceDetailedState) {
  switch (state) {
    case DeviceDetailedState.CalibratingStarting:
    case DeviceDetailedState.CalibratingCapturing:
    case DeviceDetailedState.CalibratingCalculating:
    case DeviceDetailedState.CalibratingStopping:
      return true;
    default:
      return false;
  }
}

export function isFocusing(state: DeviceDetailedState) {
  switch (state) {
    case DeviceDetailedState.FocusingStarting:
    case DeviceDetailedState.Focusing:
    case DeviceDetailedState.FocusingStopping:
    case DeviceDetailedState.PreviewingStarting:
    case DeviceDetailedState.Previewing:
    case DeviceDetailedState.PreviewingStopping:
      return true;
    default:
      return false;
  }
}

/**
Method to Convert SQL Availability Identifiers to Strings
  Available = 0
  Available (offline) = 1
  Busy = 2
  Busy (offline) = 3
  Unavailable = 4
  Unavailable (offline) = 5
**/
export function getAvailability(deviceState: DeviceState, online: boolean) {
  if (deviceState === null && online) {
    return DeviceAvailability.Unavailable;
  }
  if (deviceState === null && !online) {
    return DeviceAvailability.UnavailableOffline;
  }
  if (deviceState === DeviceState.IDLE && online) {
    return DeviceAvailability.Available;
  }
  if (deviceState === DeviceState.IDLE && !online) {
    return DeviceAvailability.AvailableOffline;
  }
  if (deviceState !== DeviceState.IDLE && online) {
    return DeviceAvailability.Busy;
  }
  if (deviceState !== DeviceState.IDLE && !online) {
    return DeviceAvailability.BusyOffline;
  }
}

export interface IDevicesGETRequest {
  serial: string | undefined;
  deviceId: string | undefined;
  machineUuid: string | undefined;
  active: boolean | undefined;
  state: DeviceState | undefined;
  availability: {in?: Array<string>};
}

export interface IDevicesTableGETResponse extends IDevice {
  availability?: DeviceAvailability;
  machineName?: string;
}
export interface IDevicesPOSTRequest {
  serial: string;
  deviceId: string;
  machineUuid: string;
  model: string;
  hwVer: string;
  swVer: string;
}

export interface DeviceGETResponse extends IDevice {}
