import {ISensorProfileGETResponse} from '@common/api/models/devices/ISensorProfile';
import {LiveStoreState, createInitialLiveStore} from './liveUpdateStore';

export const PROFILE_IGNORE_SECTIONS = ['device_id', 'type', 'version', 'loaded_from'];

export enum SensorProfileActions {
  SET_SENSOR_PROFILE_CONFIG_DEFAULTS = 'SET_SENSOR_PROFILE_CONFIG_DEFAULTS',
  LOAD_SENSOR_PROFILE = 'LOAD_SENSOR_PROFILE',
  LOAD_BUILD_SENSOR_PROFILE = 'LOAD_BUILD_SENSOR_PROFILE',
  SET_SENSOR_PROFILE = 'SET_SENSOR_PROFILE',
  SAVE_PROFILE_NAME = 'SAVE_PROFILE_NAME',
  SET_PROFILE_DEVICE = 'SET_PROFILE_DEVICE',
  CREATE_SENSOR_PROFILE = 'CREATE_SENSOR_PROFILE',
  REVERT_NEW_PROFILE = 'REVERT_NEW_PROFILE',
  CLONE_SENSOR_PROFILE = 'CLONE_SENSOR_PROFILE',
}

// Intentionally arbitrary object, structure is defined by a yaml file in S3.
export type ConfigDefaultsType = {[key: string]: any};
export type SensorProfileType = {[key: string]: any};

export interface SensorProfileStoreState extends LiveStoreState<ISensorProfileGETResponse> {
  configDefaults: {[deviceSerial: string]: ConfigDefaultsType};
  defaultProfiles: {[deviceSerial: string]: SensorProfileType};
  profiles: {[uuid: string]: SensorProfileType | undefined};
  originalProfiles: {[uuid: string]: SensorProfileType | undefined};
  originalBuildProfiles: {[uuid: string]: SensorProfileType | undefined};
  buildProfiles: {[uuid: string]: SensorProfileType | undefined};
}

export const initialSensorProfileState: SensorProfileStoreState = {
  ...createInitialLiveStore(),
  configDefaults: {},
  defaultProfiles: {},
  profiles: {},
  originalProfiles: {},
  buildProfiles: {},
  originalBuildProfiles: {},
};

interface SensorProfileActionType<_T, P> {
  type: SensorProfileActions;
  payload: P;
}

export type SetConfigDefaultsAction = SensorProfileActionType<
  SensorProfileActions.SET_SENSOR_PROFILE_CONFIG_DEFAULTS,
  {
    config: ConfigDefaultsType;
    defaultProfile: SensorProfileType;
    deviceSerial: string;
  }
>;

export type LoadSensorProfileAction = SensorProfileActionType<
  SensorProfileActions.LOAD_SENSOR_PROFILE,
  {uuid: string; profile: SensorProfileType}
>;

export type LoadBuildSensorProfileAction = SensorProfileActionType<
  SensorProfileActions.LOAD_BUILD_SENSOR_PROFILE,
  {uuid: string; profile: SensorProfileType}
>;

export type SetSensorProfileAction = SensorProfileActionType<
  SensorProfileActions.SET_SENSOR_PROFILE,
  {uuid: string; isBuild?: boolean; profile: SensorProfileType | undefined}
>;

export type SaveProfileNameAction = SensorProfileActionType<
  SensorProfileActions.SAVE_PROFILE_NAME,
  {uuid: string; name: string}
>;

export type SetProfileDeviceAction = SensorProfileActionType<
  SensorProfileActions.SET_PROFILE_DEVICE,
  {uuid: string; deviceSerial: string}
>;

export type CreateProfileAction = SensorProfileActionType<
  SensorProfileActions.CREATE_SENSOR_PROFILE,
  {profile: ISensorProfileGETResponse; profileJSON: SensorProfileType}
>;

export type RevertNewProfileAction = SensorProfileActionType<SensorProfileActions.REVERT_NEW_PROFILE, null>;

export type CloneProfileAction = SensorProfileActionType<
  SensorProfileActions.CLONE_SENSOR_PROFILE,
  {profile: ISensorProfileGETResponse; profileJSON: SensorProfileType}
>;
