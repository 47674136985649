import React from 'react';
import {Checkbox, Tooltip, Grid, IconButton, Box, Typography} from '@material-ui/core';
import {Delete, Edit, ErrorOutline} from '@material-ui/icons';
import {IRecipient} from '@common/api/models/builds/IRecipient';
import {IUser} from '@common/api/models/users/IUser';
import {validateEmail} from '@common/api/models/users/User.validation';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {recipientsByUuidDELETE, recipientsByUuidPATCH} from '../../../../api/ajax/recipients';
import {EllipsisTextColumnTooltip} from './sharedColumns';
import envConfig from '../../../../envConfig';
import {dangerColor} from '../../../../assets/jss/material-dashboard-react';

export interface RecipientRow extends IRecipient {
  user: IUser;
  isCurrentUser: boolean;
  onEdit: () => void;
  userAllowEmail: boolean;
  userAllowSms: boolean;
}

const recipientTableSchema = [
  {
    title: 'Name',
    field: 'user',
    width: 'auto',
    sorting: false,
    render: (row: RecipientRow) => {
      if (!row.user) return <></>;
      return <EllipsisTextColumnTooltip>{`${row.user.firstName} ${row.user.lastName}`}</EllipsisTextColumnTooltip>;
    },
  },
  ...(envConfig.isOnPremise
    ? []
    : [
        {
          title: 'Use Phone Number?',
          field: 'sms',
          width: 'auto',
          cellStyle: {paddingLeft: '8px'},
          sorting: false,
          render: (row: RecipientRow) => {
            if (!row.user) return <></>;

            const handleToggleSms = async (enabled: boolean) => {
              await recipientsByUuidPATCH(row.uuid, {notifySms: enabled});
            };
            if (!row.user.mobile) return <Typography>N/A</Typography>;

            return (
              <Grid container alignItems="center" style={{width: '100%'}} wrap="nowrap">
                <Grid item>
                  <Tooltip
                    title={row.userAllowSms ? 'Enable/disable SMS notification' : 'User has SMS notifications disabled'}
                  >
                    <span>
                      <Checkbox
                        checked={row.notifySms}
                        disabled={!row.userAllowSms}
                        onChange={(_e, checked) => handleToggleSms(checked)}
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item style={{width: 'calc(100% - 48px)'}}>
                  <EllipsisTextWithTooltip>{row.user.mobile}</EllipsisTextWithTooltip>
                </Grid>
              </Grid>
            );
          },
        },
      ]),
  {
    title: 'Use Email Address?',
    field: 'email',
    width: 'auto',
    cellStyle: {paddingLeft: '8px'},
    sorting: false,
    render: (row: RecipientRow) => {
      if (!row.user) return <></>;

      const handleToggleEmail = async (enabled: boolean) => {
        await recipientsByUuidPATCH(row.uuid, {notifyEmail: enabled});
      };

      if (!row.user.email) return <div>N/A</div>;
      if (!validateEmail(row.user.email).success)
        return (
          <Tooltip
            title={
              <Typography>
                Users email is invalid, they won't receive email notifications. Please ensure the user has set a valid
                email in their user profile.
              </Typography>
            }
          >
            <Box display="flex" alignItems="center">
              <ErrorOutline style={{color: dangerColor[0], margin: '0 9px'}} />
              <Typography variant="body2" style={{color: dangerColor[0]}}>
                <b>Invalid email</b>
              </Typography>
            </Box>
          </Tooltip>
        );

      return (
        <Grid container alignItems="center" style={{width: '100%'}} wrap="nowrap">
          <Grid item>
            <Tooltip
              title={row.userAllowEmail ? 'Enable/disable email notification' : 'User has email notifications disabled'}
            >
              <span>
                <Checkbox
                  checked={row.notifyEmail}
                  disabled={!row.userAllowEmail}
                  onChange={(e, checked) => handleToggleEmail(checked)}
                />
              </span>
            </Tooltip>
          </Grid>
          <Grid item style={{width: 'calc(100% - 48px)'}}>
            <EllipsisTextWithTooltip>{row.user.email}</EllipsisTextWithTooltip>
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Actions',
    sorting: false,
    width: '112px',
    render: (row: RecipientRow) => {
      return (
        <Box display="flex" justifyContent="flex-end">
          {row.isCurrentUser && (
            <Tooltip title="Edit">
              <IconButton aria-label="edit" onClick={() => row.onEdit()}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Remove">
            <IconButton
              aria-label="delete"
              onClick={() => {
                recipientsByUuidDELETE(row.uuid);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  },
];

export default recipientTableSchema;
