import * as React from 'react';
import {MaterialTableProps} from 'material-table';

import {BuildsRow, BuildsTable, BuildsTableProps} from './BuildsTable';
import {BuildState} from '@common/api/models/builds/IBuild';
import {assertUnreachable} from '@common/utils/utils';
import Status, {StatusVariant} from '../../atoms/Status/Status';
import {capitalise} from '../../../utils/string';

export default function BuildsTableWithStatus(props: BuildsTableProps & Partial<MaterialTableProps<BuildsRow>>) {
  const {builds, exclude, filters, errorMessage, ...otherProps} = props;
  return (
    <BuildsTable
      builds={builds}
      exclude={[...(exclude || [])]}
      customRender={{
        state: (row) => {
          let variant: StatusVariant = 'none';
          let stateText: string = row.build.state;
          switch (row.build.state) {
            case BuildState.DRAFT:
              variant = 'none';
              break;
            case BuildState.STAGING:
            case BuildState.PAUSED:
              variant = 'warning';
              break;
            case BuildState.ACTIVE:
              variant = 'info';
              break;
            case BuildState.SUCCEEDED:
              variant = 'success';
              break;
            case BuildState.COMPLETED:
              stateText = 'completed';
              variant = 'success';
              break;
            case BuildState.FAILED:
              variant = 'error';
              break;
            default:
              assertUnreachable(row.build.state);
          }

          return <Status variant={variant} message={capitalise(stateText)} />;
        },
      }}
      filters={filters}
      errorMessage={errorMessage}
      {...otherProps}
    />
  );
}
