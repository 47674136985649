import {FeatureFlagResponse, SupportVersionResponse} from '@common/api/models/support';
import axios from 'axios';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';

export async function versionGET(): AjaxApiResult<SupportVersionResponse> {
  return ajaxWrapper(() => {
    return axios.get('/api/support/version');
  }, 'Could not fetch webapp version');
}

export async function featureFlagsGET(): AjaxApiResult<FeatureFlagResponse> {
  return ajaxWrapper(() => {
    return axios.get('/api/support/featureFlags');
  });
}
