import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ArrowBack, Computer, Description, PersonAdd, Settings, Videocam} from '@material-ui/icons';
import {Box, Drawer, makeStyles, Typography} from '@material-ui/core';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {Role} from '@common/api/models/users/IUser';
import ResourcePermissionsTable from '../../../../components/molecules/Table/ResourcePermissionsTable';
import BottomToolbarContainer from '../../../../components/atoms/BottomToolbarContainer';
import VerticalButton from '../../../../components/atoms/VerticalButton';
import {useSliceAttachmentStoreActions} from '../../../../store/actions';
import {LiveStepState, getSettingsQueryString} from '../index';
import {SideBarTargetMachine} from './SideBarTargetMachine';
import {RootState} from '../../../../store/reducers/index';
import {SliceFilesCard} from '../../shared/SliceFilesCard';
import {SideBarWebcamFeed} from './SideBarWebcamFeed';

enum DrawerTypes {
  WEBCAM = 'WEBCAM',
  SLICE = 'SLICE',
  MACHINE = 'MACHINE',
  USER_ACCESS = 'USER_ACCESS',
}

const StagingBuildBottomToolbar = () => {
  const history = useHistory();
  const drawerStyles = useDrawerStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentDrawer, setCurrentDrawer] = useState<DrawerTypes | null>(null);
  const {uuid, liveStep} = useParams<{uuid: string; liveStep: string}>();
  const build = useSelector((state: RootState) => state.buildStore.byId[uuid]);
  const device = useSelector((state: RootState) => state.deviceStore.byMachineUuid[build.machineUuid!]);
  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const sliceAttachmentStoreActions = useSliceAttachmentStoreActions();

  const isSettingsPage = window.location.pathname.includes('/settings');
  const canViewPermissions = currentUser.role >= Role.MANAGER || build.provisionerUuid === currentUser.uuid;

  useEffect(() => {
    sliceAttachmentStoreActions.ensureConsistent({buildUuid: build.uuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.uuid]);

  const handleSettingsNavigation = () => {
    if (isSettingsPage) {
      history.push(`/builds/uuid/${uuid}`);

      return;
    }
    history.push(
      `/builds/uuid/${uuid}/settings/?defaultCameraMode=${getSettingsQueryString(
        liveStep as LiveStepState,
        device?.state
      )}`
    );
  };

  const toggleDrawer = (newDrawer: DrawerTypes) => {
    if (newDrawer === currentDrawer) {
      setIsDrawerOpen(false);
      setTimeout(() => setCurrentDrawer(null), 100);
    } else {
      setIsDrawerOpen(true);
      setCurrentDrawer(newDrawer);
    }
  };

  return (
    <BottomToolbarContainer
      columns={isSettingsPage ? '100%' : canViewPermissions ? '20% 20% 20% 20% 20%' : '25% 25% 25% 25%'}
    >
      {!isSettingsPage && (
        <>
          <VerticalButton onClick={() => toggleDrawer(DrawerTypes.WEBCAM)} color="secondary">
            <Videocam />
            <Typography variant="caption">Webcam Feed</Typography>
          </VerticalButton>
          {!!canViewPermissions && (
            <VerticalButton onClick={() => toggleDrawer(DrawerTypes.USER_ACCESS)} color="secondary">
              <PersonAdd />
              <Typography variant="caption">User Access</Typography>
            </VerticalButton>
          )}
          <VerticalButton onClick={() => toggleDrawer(DrawerTypes.SLICE)} color="secondary">
            <Description />
            <Typography variant="caption">Slice Files</Typography>
          </VerticalButton>
          <VerticalButton onClick={() => toggleDrawer(DrawerTypes.MACHINE)} color="secondary">
            <Computer />
            <Typography variant="caption">Device</Typography>
          </VerticalButton>
        </>
      )}

      {window.location.pathname.includes('/settings') ? (
        <VerticalButton onClick={handleSettingsNavigation} color="secondary">
          <ArrowBack />
          <Typography variant="caption">Back to staging page</Typography>
        </VerticalButton>
      ) : (
        <VerticalButton onClick={handleSettingsNavigation} color="secondary">
          <Settings />
          <Typography variant="caption">Settings</Typography>
        </VerticalButton>
      )}

      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => toggleDrawer(currentDrawer!)} classes={drawerStyles}>
        {currentDrawer === DrawerTypes.WEBCAM && <SideBarWebcamFeed webcamHosts={device?.webcamHosts} />}
        {currentDrawer === DrawerTypes.SLICE && <SliceFilesCard buildUuid={uuid} orgUuid={build.organizationUuid} />}
        {currentDrawer === DrawerTypes.MACHINE && <SideBarTargetMachine machineUuid={build.machineUuid!} />}
        {currentDrawer === DrawerTypes.USER_ACCESS && (
          <Box p={2}>
            <ResourcePermissionsTable resourceType={ResourcePermissionType.BUILD} resource={build} />
          </Box>
        )}
      </Drawer>
    </BottomToolbarContainer>
  );
};

export default StagingBuildBottomToolbar;

const useDrawerStyles = makeStyles({
  paperAnchorBottom: {
    bottom: '4rem !important',
  },
  root: {
    '& .MuiBackdrop-root': {
      bottom: '4rem !important',
    },
  },
  modal: {
    zIndex: '1298 !important' as any,
  },
});
