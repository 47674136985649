import React from 'react';
import Chart from 'react-apexcharts';

function LineBarChart({
  chartID,
  options,
  series,
  height,
  otherProps,
  valueFormatter = (val) => `${val}`,
  type,
}: {
  chartID: string;
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  options?: ApexCharts.ApexOptions;
  height?: number;
  valueFormatter?: (val: any) => string;
  otherProps?: React.ComponentProps<typeof Chart>;
  type?: 'numeric' | 'category' | 'datetime' | undefined;
}) {
  const {chart: chartOptions = {}, ...otherOptions} = options || {};

  return (
    <Chart
      type="line"
      series={series}
      height={height}
      options={{
        chart: {
          id: chartID,
          type: 'line',
          stacked: false,
          height: height,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: 'zoom',
          },
          ...chartOptions,
        },
        dataLabels: {enabled: false},
        markers: {size: 6},
        tooltip: {
          shared: true,
        },
        stroke: {width: [0, 4], curve: 'smooth'},
        xaxis: {
          type: type,
          labels: {
            hideOverlappingLabels: true,
          },
        },
        yaxis: {
          labels: {
            formatter: valueFormatter,
          },
        },
        ...otherOptions,
      }}
      {...otherProps}
    />
  );
}

export default LineBarChart;
