import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Grid, Typography} from '@material-ui/core';

import {DeviceHosts} from '@common/api/models/devices/IDevice';

interface WebcamButtonProps {
  webcamHosts?: DeviceHosts;
  disabled?: boolean;
}

export function WebcamButton(props: WebcamButtonProps) {
  const [webcamStatus, setWebCamStatus] = useState(false);
  const [webcamURL, setWebcamURL] = useState('');

  useEffect(() => {
    if (props.webcamHosts) {
      props.webcamHosts.forEach(async (url: string) => {
        setWebCamStatus(true);
        setWebcamURL(url);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.webcamHosts]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Webcam Feed</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          paddingTop: '10px',
        }}
      >
        {webcamStatus ? (
          <Button target="_blank" href={webcamURL} variant="outlined" size="small" disabled={props.disabled}>
            Open in a new tab
          </Button>
        ) : (
          <Typography>Webcam Feed Not Available</Typography>
        )}
      </Grid>
    </>
  );
}
