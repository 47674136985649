import {Avatar as MuiAvatar, Badge, Chip, ChipProps, Grid} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import * as React from 'react';
import styled from 'styled-components';

import {IDevice} from '@common/api/models/devices/IDevice';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {getMachineImage} from '@common/api/models/devices/machines/Machine.utils';

import defaultPrinterImage from '../../../../public/img/printer.png';
import {grayColor, infoColor, successColor} from '../../../assets/jss/material-dashboard-react';
import DeviceStatus from './DeviceStatus';

export interface MachineAvatarProps {
  machine: IMachine;
  device?: IDevice;
  isLoading?: boolean;
  hideBadge?: boolean;
  isStaging?: boolean;
}

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 100%;
  width: 128px;
`;

const StyledBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    background-color: ${(props: {rgbcolor: string}) => props.rgbcolor};
    border: 3px solid ${(props) => props.theme.palette.common.white};
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
`;

const getMachineStatusColor = (props: MachineAvatarProps) => {
  const machineActiveColor = props.machine.active ? infoColor[0] : successColor[0];
  return props.device?.online ? machineActiveColor : grayColor[0];
};

export function MachineStatusChip({isStaging, ...props}: MachineAvatarProps & ChipProps) {
  const badgeColour = props.device?.online ? getMachineStatusColor(props) : grayColor[4];

  return (
    <Chip
      style={{backgroundColor: badgeColour}}
      color={props.device?.online ? 'primary' : 'default'}
      label={
        <DeviceStatus
          state={props.device?.state}
          stateDetailed={props.device?.stateDetailed}
          online={!!props.device?.online}
          isStaging={isStaging}
        />
      }
      {...props}
    />
  );
}

export function MachineImage(props: MachineAvatarProps) {
  let printerImg = defaultPrinterImage;

  if (props.machine.manufacturer && props.machine.model) {
    const image = getMachineImage(props.machine);

    if (image) {
      printerImg = window.location.origin + '/img/Machine_Images/' + image;
    }
  }

  return <Avatar alt={props.machine.name} src={printerImg} variant={'rounded'} />;
}

export function MachineAvatar(props: MachineAvatarProps) {
  if (props.isLoading)
    return (
      <Grid container direction="column" alignContent="center">
        <Grid item>
          <Skeleton variant="rect" height={120} />
        </Grid>
        <Grid item>
          <Skeleton width={120} />
        </Grid>
      </Grid>
    );

  const badgeColour = getMachineStatusColor(props);

  return (
    <Grid container direction={'column'} justifyContent={'space-around'}>
      <Grid item>
        <Grid container justifyContent={'space-around'}>
          <Grid item>
            <StyledBadge
              overlap="rectangular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
              rgbcolor={badgeColour}
            >
              <MachineImage {...props} />
            </StyledBadge>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent={'space-around'}>
          <Grid item>
            <MachineStatusChip machine={props.machine} device={props.device} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
