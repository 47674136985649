import {LiveStoreState} from '../../store/model/liveUpdateStore';
import {IRecipient} from '@common/api/models/builds/IRecipient';
import {IUser} from '@common/api/models/users/IUser';

export function displayEmail(recipient: IRecipient, userStore: LiveStoreState<IUser>) {
  const user = userStore.byId[recipient.userUuid!];
  const email = user?.email;

  if (!email) {
    return '';
  }

  const domain = email.split('@')[1];
  const pre = email.split('@')[0];
  return `****${pre.substr(pre.length - 3, 3)}@${domain}`;
}

export function displayMobile(recipient: IRecipient, userStore: LiveStoreState<IUser>) {
  const user = userStore.byId[recipient.userUuid!];
  const mobile = user?.mobile;

  if (!mobile) {
    return '';
  }

  return `*******${mobile.substr(mobile.length - 3, 3)}`;
}

export function getRecipientName(recipient: IRecipient, userStore: LiveStoreState<IUser>) {
  const user = userStore.byId[recipient.userUuid!];

  if (!!user) {
    return `${user.firstName} ${user.lastName}`;
  } else {
    return null;
  }
}
