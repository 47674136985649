import YAML from 'yaml';
import {
  ConfigDefaultsType,
  PROFILE_IGNORE_SECTIONS,
  SensorProfileActions,
  SensorProfileType,
} from '../model/sensorProfile';
import {RootState} from '../reducers';
import {configDefaultsGET, sensorProfilesPOST, sensorProfilesPUT, profileYAMLGet} from '../../api/ajax/sensorProfile';
import {buildConfigCurrentGET} from '../../api/ajax/builds';
import {cloneDeep, isEqual, omit} from 'lodash';
import {toast} from 'react-toastify';

function profileConfigToDefaultProfile(profileConfig: ConfigDefaultsType) {
  const defaultProfile: SensorProfileType = {};

  function defaultsFromSection(section: ConfigDefaultsType) {
    const sectionConfig: SensorProfileType = {};

    Object.keys(section as {}).forEach((field) => {
      sectionConfig[field] = section[field].default;
    });

    return sectionConfig;
  }

  function cameraManagerDefaults() {
    const cameraProfiles: SensorProfileType = {};
    const cameraCount = profileConfig.camera_manager.cameras.count;
    const availableCameraIds = profileConfig.camera_manager.cameras.available_camera_ids;

    Object.entries(profileConfig.camera_manager.cameras)
      .filter(([key, _]) => !['available_camera_ids', 'count'].includes(key))
      .forEach(([mode, modeConfig]) => {
        cameraProfiles[mode] = {};

        Object.entries(modeConfig as any).forEach(([field, fieldConfig]: any) => {
          cameraProfiles[mode][field] = fieldConfig.default;
        });
      });

    return Array(cameraCount)
      .fill(cameraProfiles)
      .map((profile, index) => ({
        id: availableCameraIds ? availableCameraIds[index] : index + 1,
        ...cloneDeep(profile),
      }));
  }

  function outputFiletypesDefaults() {
    const cameraProfiles: SensorProfileType = {};

    Object.entries(profileConfig.output_filetypes.local).forEach(([mode, modeConfig]) => {
      cameraProfiles[mode] = {};

      Object.entries(modeConfig as any).forEach(([field, fieldConfig]: any) => {
        cameraProfiles[mode][field] = fieldConfig.default;
      });
    });

    return cameraProfiles;
  }

  Object.keys(profileConfig).forEach((section) => {
    switch (section) {
      case 'camera_manager':
        defaultProfile.camera_manager = {
          cameras: cameraManagerDefaults(),
          ...omit(defaultsFromSection(profileConfig.camera_manager), 'cameras'),
        };
        break;
      case 'output_filetypes':
        defaultProfile.output_filetypes = {
          local: outputFiletypesDefaults(),
        };
        break;
      case 'version':
      case 'device_id':
        defaultProfile[section] = profileConfig[section];
        break;
      case 'type':
        defaultProfile[section] = 'profile';
        break;
      case 'loaded_from':
        defaultProfile[section] = null;
        break;
      default:
        defaultProfile[section] = defaultsFromSection(profileConfig[section]);
    }
  });
  return defaultProfile;
}

async function fetchAndDispatchFetchConfigDefaults(dispatch: Function, deviceSerial: string) {
  const res = await configDefaultsGET(deviceSerial);

  if (!res.success) throw new Error();

  const parsedConfigDefaults = YAML.parse(res.data.configDefaults);

  const payload = {
    config: parsedConfigDefaults,
    defaultProfile: profileConfigToDefaultProfile(parsedConfigDefaults),
  };
  dispatch({
    type: SensorProfileActions.SET_SENSOR_PROFILE_CONFIG_DEFAULTS,
    payload: {
      ...payload,
      deviceSerial,
    },
  });

  return payload;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchConfigDefaults(deviceSerial: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      try {
        await fetchAndDispatchFetchConfigDefaults(dispatch, deviceSerial);
      } catch {
        console.error('Failed to fetch sensor profiles configuration');
      }
    };
  },

  loadSensorProfile(uuid: string) {
    return async (dispatch: Function, getState: () => RootState) => {
      const currentProfileExists = getState().sensorProfileStore.profiles[uuid];

      if (currentProfileExists) return;

      try {
        const res = await profileYAMLGet(uuid);

        if (!res.success) throw new Error();

        const parsedProfile = YAML.parse(res.data.profileYAML);

        dispatch({
          type: SensorProfileActions.LOAD_SENSOR_PROFILE,
          payload: {uuid, profile: parsedProfile},
        });
      } catch {
        console.error('Failed to load sensor profiles configuration');
      }
    };
  },

  loadBuildSensorProfile(uuid: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      try {
        const res = await buildConfigCurrentGET(uuid);

        if (!res.success) throw new Error();

        const parsedProfile = YAML.parse(res.data);

        dispatch({
          type: SensorProfileActions.LOAD_BUILD_SENSOR_PROFILE,
          payload: {uuid, profile: parsedProfile},
        });
      } catch {
        console.error('Failed to load build profile configuration');
      }
    };
  },

  setSensorProfile(uuid: string, profile?: SensorProfileType, isBuild?: {isBuild: boolean}) {
    return async (dispatch: Function, _getState: () => RootState) => {
      dispatch({
        type: SensorProfileActions.SET_SENSOR_PROFILE,
        payload: {uuid, isBuild: isBuild?.isBuild, profile},
      });
    };
  },

  saveProfileName(uuid: string, name: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      if (uuid !== 'new') {
        const res = await sensorProfilesPUT(uuid, {name});

        if (!res.success) return false;
      }

      dispatch({
        type: SensorProfileActions.SAVE_PROFILE_NAME,
        payload: {uuid, name},
      });

      return true;
    };
  },

  setDevice(uuid: string, deviceSerial: string | undefined) {
    return async (dispatch: Function, getState: () => RootState) => {
      try {
        if (uuid !== 'new') return;
        const profileStore = getState().sensorProfileStore;

        dispatch({
          type: SensorProfileActions.SET_PROFILE_DEVICE,
          payload: {uuid, deviceSerial},
        });

        if (!deviceSerial) {
          dispatch({
            type: SensorProfileActions.SET_SENSOR_PROFILE,
            payload: {uuid, profile: undefined},
          });
          return;
        }

        const currentProfile = profileStore.byId[uuid];
        const currentConfig = profileStore.configDefaults[currentProfile?.deviceSerial];

        let newConfig = profileStore.configDefaults[deviceSerial];
        let newDefaultProfile = profileStore.defaultProfiles[deviceSerial];

        if (!newConfig) {
          ({config: newConfig, defaultProfile: newDefaultProfile} = await fetchAndDispatchFetchConfigDefaults(
            dispatch,
            deviceSerial
          ));
        }

        const sameProfileStructure = isEqual(
          omit(newConfig, PROFILE_IGNORE_SECTIONS),
          omit(currentConfig || {}, PROFILE_IGNORE_SECTIONS)
        );
        if (sameProfileStructure) return;

        if (!!currentConfig) {
          toast("New device has different configuration, resetting profile to new device's defaults...", {
            type: 'info',
          });
        }

        dispatch({
          type: SensorProfileActions.SET_SENSOR_PROFILE,
          payload: {uuid, profile: newDefaultProfile},
        });

        return true;
      } catch (e) {
        console.log(e);
      }
    };
  },

  createProfile(name: string, deviceSerial: string, profileJSON: SensorProfileType) {
    return async (dispatch: Function, _getState: () => RootState) => {
      try {
        const res = await sensorProfilesPOST(name, deviceSerial, profileJSON);
        if (!res.success) return false;

        dispatch({
          type: SensorProfileActions.CREATE_SENSOR_PROFILE,
          payload: {profile: res.data, profileJSON},
        });

        return res.data.uuid;
      } catch {
        console.error('Failed to create sensor profile');
      }
    };
  },

  saveProfile(uuid: string, profileJSON: SensorProfileType) {
    return async (dispatch: Function, _getState: () => RootState) => {
      try {
        const res = await sensorProfilesPUT(uuid, {profileJSON});
        if (!res.success) return false;

        dispatch({
          type: SensorProfileActions.LOAD_SENSOR_PROFILE,
          payload: {uuid, profile: profileJSON},
        });

        return true;
      } catch {
        console.error('Failed to create sensor profile');
      }
    };
  },

  revertNewProfile() {
    return async (dispatch: Function, _getState: () => RootState) => {
      dispatch({
        type: SensorProfileActions.REVERT_NEW_PROFILE,
        payload: null,
      });

      return true;
    };
  },

  cloneSensorProfile(uuid: string) {
    return async (dispatch: Function, getState: () => RootState) => {
      try {
        const profileRes = await profileYAMLGet(uuid);
        const profile = getState().sensorProfileStore.byId[uuid];

        if (!profileRes.success) throw new Error();

        const parsedProfile = YAML.parse(profileRes.data.profileYAML);

        dispatch({
          type: SensorProfileActions.CLONE_SENSOR_PROFILE,
          payload: {
            profile: {
              ...cloneDeep(profile),
              uuid: 'new',
              name: `${profile.name} (copy)`,
            },
            profileJSON: parsedProfile,
          },
        });

        return true;
      } catch {
        console.error('Failed to clone sensor profile');
        return false;
      }
    };
  },
};
