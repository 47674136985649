import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MaterialTableProps} from 'material-table';
import {useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import {GetApp} from '@material-ui/icons';

import {getDefectAnalysisType, stringToDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {DefectStatisticGETResponse} from '@common/api/models/builds/data/defects/IDefectStatistic';
import {IBuild} from '@common/api/models/builds/IBuild';

import {DefectImageOverlays, ViewportDefect} from '../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {chooseImageForViewing, LayerImages} from '../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {defaultPart} from '../Viewport/2D/utils';
import {defaultOverlayParams} from '../Viewport/2D/analysisType';
import {RootState} from '../../../store/reducers';
import GenericTable from './GenericTable';
import {toast} from 'react-toastify';
import {downloadUrl} from '../../../utils/webtools';
import {defectStatisticCsvUrlGET} from '../../../api/ajax/defectStatistic';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';

const useDefectStatistics = () => {
  const defectStatistics = useSelector((state: RootState) => state.defectStatisticStore.list);

  return defectStatistics;
};

interface DefectsTableProps {
  currentDefectData: ViewportDefect | undefined;
  setDefectData: React.Dispatch<React.SetStateAction<ViewportDefect | undefined>>;
  setOverlay: React.Dispatch<React.SetStateAction<DefectImageOverlays[]>>;
  layerImages: LayerImages;
  fetchLayerData: (currentLayer: number) => void;
  startLoading: () => void;
  setWaitForStateChanges: React.Dispatch<React.SetStateAction<boolean>>;
  build: IBuild;
  tableOptions?: MaterialTableProps<DefectStatisticGETResponse>['options'];
}

function DefectsTable({
  currentDefectData,
  setDefectData,
  setOverlay,
  layerImages,
  fetchLayerData,
  startLoading,
  setWaitForStateChanges,
  build,
  tableOptions,
}: DefectsTableProps) {
  const isXsScreen = useExtraSmallScreenSize();
  // The defect type of the row selected by the user
  const [currentDefect, setCurrentDefect] = useState<DefectStatisticGETResponse>();
  const [csvRequesting, setCsvRequesting] = useState<boolean>(false);

  const defectStatisticBaseFilters = useMemo(() => ({buildUuid: build.uuid}), [build.uuid]);

  useEffect(() => {
    if (Object.keys(layerImages).length <= 0) return;

    if (currentDefect && layerImages[currentDefect.layerNum]) {
      setOverlayAndDefectData(currentDefect);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerImages]);

  const setOverlayAndDefectData = async (defect: DefectStatisticGETResponse) => {
    let width: number = 0;
    let height: number = 0;

    const defectType = getDefectAnalysisType(stringToDefectType(defect.defectType));
    const imagesForDefectType = layerImages[defect.layerNum][defaultPart][defectType]['images'];
    if (imagesForDefectType) {
      // TODO: performance mode
      const bestLayerImage = chooseImageForViewing(imagesForDefectType, false, false);

      if (bestLayerImage) {
        width = bestLayerImage.widthPx;
        height = bestLayerImage.heightPx;
      }
    }
    const params = defaultOverlayParams[defectType];

    const result: DefectImageOverlays = {
      filters: params.filters,
      filterParams: params.filterParams,
      image: undefined,
      layerNum: defect.layerNum,
      id: defectType,
    };
    // chooseBestImages would run twice without this. Most of the times following chooseBestImages's
    // dependencies are changing: selectedOverlays, currentLayer
    if (currentDefectData?.layerNum !== defect.layerNum) {
      setWaitForStateChanges(true);
    }
    setOverlay([result]);

    setDefectData({
      boundingBox: defect.boundingBox,
      layerNum: defect.layerNum,
      widthPx: width,
      heightPx: height,
      analysisType: defectType,
    });
  };

  const onRowClick = useCallback(
    (_e: any, clickedDefect: any) => {
      if (!clickedDefect || isEqual(currentDefect, clickedDefect)) return;

      startLoading();
      setCurrentDefect(clickedDefect);
      if (layerImages[clickedDefect.layerNum]) {
        setOverlayAndDefectData(clickedDefect);
      } else {
        fetchLayerData(clickedDefect.layerNum);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layerImages, currentDefect]
  );

  const handleCsvDownload = async () => {
    setCsvRequesting(true);
    const url = await defectStatisticCsvUrlGET(build.uuid);

    if (!url.success) {
      setCsvRequesting(false);
      return;
    }
    toast('Downloading CSV...', {type: 'success'});
    setCsvRequesting(false);
    downloadUrl(url.data, `${build.name}-defect-statistics.csv`);
  };

  return (
    <GenericTable
      filteringEnabled
      permanentFilters={defectStatisticBaseFilters}
      resourceType="defectStatistic"
      useData={useDefectStatistics}
      onRowClick={onRowClick}
      headerFlexDirection="row-reverse"
      tableOptions={{
        rowStyle: (rowData: any) => ({
          backgroundColor: rowData?.uuid === currentDefect?.uuid ? '#F5FaFF' : '',
        }),
        ...tableOptions,
        headerStyle: {
          backgroundColor: 'none',
        },
      }}
      tableTitle={
        <Button
          startIcon={csvRequesting ? <CircularProgress size={15} /> : <GetApp />}
          variant="outlined"
          color="primary"
          disabled={csvRequesting}
          onClick={handleCsvDownload}
          size={isXsScreen ? 'small' : 'medium'}
          fullWidth={isXsScreen}
        >
          <Typography noWrap style={{fontWeight: 600}}>
            Individual Defects CSV
          </Typography>
        </Button>
      }
    />
  );
}

export default DefectsTable;
