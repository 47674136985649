import {ApiFailureResponse} from '@common/api/apiResult';
import {isString, isEqual} from 'lodash';
import {QueryFilter} from './liveUpdateStore';
import {RootState} from '../reducers';
import {
  IBuildReportGETResponse,
  IBuildReportPOSTRequest,
  IBuildReportsGETRequest,
} from '@common/api/models/builds/data/IBuildReport';
import {
  ApiResponsePayload,
  LiveStoreActions,
  LiveStoreFetchErrorAction,
  LiveStoreFinishFetchAction,
  LiveStoreStartFetchAction,
} from '../model/liveUpdateStore';
import {buildReportAllGET, buildReportDELETE, buildReportPOST} from '../../api/ajax/buildReport';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createBuildReport(buildReport: IBuildReportPOSTRequest) {
    return async (dispatch: Function) => {
      const response = await buildReportPOST(buildReport);

      if (response.success) {
        dispatch(this.refreshList());
        return true;
      } else {
        return false;
      }
    };
  },

  deleteBuildReport(buildReport: IBuildReportGETResponse) {
    return async (dispatch: Function) => {
      const response = await buildReportDELETE(buildReport.uuid);

      if (response.success) {
        dispatch({
          type: LiveStoreActions.DELETE_LIST + 'BUILD_REPORT',
          payload: buildReport,
        });
        return true;
      } else {
        return false;
      }
    };
  },

  _finishListFetch(
    response: ApiResponsePayload<IBuildReportGETResponse>,
    filters: QueryFilter<IBuildReportsGETRequest>
  ): LiveStoreFinishFetchAction<IBuildReportGETResponse> {
    return {
      type: LiveStoreActions.FINISH_LIST_FETCH + 'BUILD_REPORT',
      payload: {...response, filters},
    };
  },

  _errorListFetch(message: string): LiveStoreFetchErrorAction<IBuildReportGETResponse> {
    return {
      type: LiveStoreActions.FETCH_LIST_ERROR + 'BUILD_REPORT',
      payload: message,
    };
  },

  _startListFetch(): LiveStoreStartFetchAction<IBuildReportGETResponse> {
    return {
      type: LiveStoreActions.START_LIST_FETCH + 'BUILD_REPORT',
      payload: {},
    };
  },

  refreshList(setLoading: boolean = true) {
    return async (dispatch: Function, getState: () => RootState) => {
      let response;
      const filters = getState().buildReportStore.listFilters;
      const currentList = getState().buildReportStore.list;

      if (!filters) return;

      if (setLoading) dispatch(this._startListFetch());
      response = await buildReportAllGET(filters);

      if (!response.success) {
        response = response as ApiFailureResponse;
        const message = isString(response.message) ? response.message : JSON.stringify(response.message);

        if (setLoading) dispatch(this._errorListFetch(message));
        return;
      }

      response = response as ApiResponsePayload<IBuildReportGETResponse>;

      if (!isEqual(currentList, response.data)) dispatch(this._finishListFetch(response, filters));
    };
  },
};
