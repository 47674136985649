import React, {useState} from 'react';
import {Button, Dialog, DialogContent, DialogActions} from '@material-ui/core';
import {IBuild} from '@common/api/models/builds/IBuild';
import NotificationRecipients from '../../../pages/builds/shared/DefectNotifications/NotificationRecipients';
import DefectNotificationFilters from '../../../pages/builds/shared/DefectNotifications/DefectNotificationFilters';

interface NotificationSettingsModalProps {
  build: IBuild;
  expanded?: Boolean;
}

export function NotificationSettingsModal(props: NotificationSettingsModalProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        style={{
          fontWeight: 400,
          marginRight: '15px',
          width: props.expanded ? '100%' : 'auto',
        }}
        variant="outlined"
        color={'primary'}
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Edit Notification Settings
      </Button>

      <Dialog
        maxWidth="xl"
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogContent style={{paddingTop: '15px'}}>
          <NotificationRecipients build={props.build} />
          <DefectNotificationFilters build={props.build} />
        </DialogContent>

        <DialogActions disableSpacing style={{padding: '0px 35px 35px 35px'}}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
