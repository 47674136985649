import React from 'react';
import {Box, ButtonGroup, useMediaQuery} from '@material-ui/core';
import {
  buildCalibrateGET,
  buildFocusGET,
  buildPreviewGET,
  buildStopGET,
  closeBuildGET,
  monitorBuildGET,
  provisionBuildGET,
} from '../../../api/ajax/builds';
import {LiveBuildProps} from '../../../pages/builds/liveBuild';
import {capitalise} from '../../../utils/string';
import {DialogButton} from '../DialogButton';
import {IBuild} from '@common/api/models/builds/IBuild';

function ManualBuildActionButtons(props: LiveBuildProps & {disabled?: boolean}) {
  const uuid = props.build.uuid;
  const buttonConfigs = [
    {action: 'provision', handler: () => provisionBuildGET(uuid, {skipStateCheck: true})},
    {action: 'manually focus', handler: () => buildPreviewGET(uuid, true)},
    {action: 'focus', handler: () => buildFocusGET(uuid, [], true)},
    {action: 'calibrate', handler: () => buildCalibrateGET(uuid, true)},
    {action: 'monitor', handler: () => monitorBuildGET(uuid, true)},
    {action: 'stop', handler: () => buildStopGET(uuid, true)},
    {action: 'close', handler: () => closeBuildGET(uuid, true)},
  ];

  const isXs = useMediaQuery('(max-width:600px)');

  if (isXs) {
    return (
      <Box display="flex" flexDirection="column">
        <ButtonGroup orientation="vertical" size="small" disabled={props.disabled}>
          <ButtonGroup orientation={'horizontal'} size="small" disabled={props.disabled}>
            {dialogButtons(props.build, buttonConfigs.slice(0, 3))}
          </ButtonGroup>
          <ButtonGroup orientation={'horizontal'} size="small" style={{marginTop: '12px'}} disabled={props.disabled}>
            {dialogButtons(props.build, buttonConfigs.slice(3, 7))}
          </ButtonGroup>
        </ButtonGroup>
      </Box>
    );
  }

  return (
    <ButtonGroup orientation="horizontal" size="small" disabled={props.disabled}>
      {dialogButtons(props.build, buttonConfigs)}
    </ButtonGroup>
  );
}

export default ManualBuildActionButtons;

const dialogButtons = (build: IBuild, buttonConfigs: {action: string; handler: () => void}[]) => {
  return buttonConfigs.map((button) => (
    <DialogButton
      key={`build-action-button-${button.action}`}
      dialog={{
        title: `${capitalise(button.action)} build`,
        content: `Are you sure you wish to ${button.action} the build "${build.name}"?`,
      }}
      handleConfirm={button.handler}
      text={capitalise(button.action)}
    />
  ));
};
