import React, {useState} from 'react';
import filesize from 'filesize';
import {Delete, CloudDownload} from '@material-ui/icons';
import {CircularProgress, Box, Tooltip, IconButton} from '@material-ui/core';
import {IBuildAttachment} from '@common/api/models/attachments/IAttachmentBase';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import {buildAttachmentByUuidDELETE} from '../../../../api/ajax/buildAttachments';
import {sliceAttachmentDownloadUrlGET} from '../../../../api/ajax/sliceAttachments';
import {downloadUrl} from '../../../../utils/webtools';

const buildAttachmentTableSchema = [
  {
    title: 'Name',
    field: 'filename',
    width: 'auto',
    cellStyle: {minWidth: '124px'},
    render: (row: IBuildAttachment) =>
      row.filename && <EllipsisTextColumnTooltip>{row.filename}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'Size',
    align: 'left',
    field: 'size',
    sortField: 'filesize',
    type: 'numeric',
    width: '140px',
    render: (row: IBuildAttachment) => row.filesize && filesize(row.filesize),
  },
  {
    title: 'Date Added',
    field: 'createdAt',
    align: 'left',
    type: 'date',
    width: '148px',
    emptyValue: '',
    render: (row: IBuildAttachment) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Actions',
    width: '112px',
    sorting: false,
    render: (rowData: IBuildAttachment) => <BuildAttachmentActions rowData={rowData} />,
  },
];

export default buildAttachmentTableSchema;

const BuildAttachmentActions = ({rowData}: {rowData: IBuildAttachment}) => {
  const [deleting, setDeleting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const onDownload = async () => {
    setDownloading(true);
    const url = await sliceAttachmentDownloadUrlGET(rowData.uuid);
    if (url.success) downloadUrl(url.data, rowData.filename);
    setDownloading(false);
  };
  const onDelete = async () => {
    setDeleting(true);
    await buildAttachmentByUuidDELETE(rowData.uuid);
    setDeleting(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title="Download">
        <IconButton aria-label="download" onClick={onDownload}>
          {downloading ? <CircularProgress size={20} /> : <CloudDownload />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove">
        <IconButton aria-label="delete" onClick={onDelete}>
          {deleting ? <CircularProgress size={20} /> : <Delete />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
