import React from 'react';
import {Button, Grid} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {Settings} from '@material-ui/icons';

export default function LiveBuildSettingsButton({buildUuid, queryString}: {buildUuid: string; queryString?: string}) {
  const history = useHistory();

  const handleSettingsClicked = () => {
    history.push(`/builds/uuid/${buildUuid}/settings/${queryString || ''}`);
  };

  return (
    <Grid item>
      <Button variant="contained" startIcon={<Settings />} color={'primary'} onClick={handleSettingsClicked}>
        Settings
      </Button>
    </Grid>
  );
}
