import axios from 'axios';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {IBuildNote} from '@common/api/models/builds/IBuildNote';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function buildNotesAllGET(filter: QueryFilter<IBuildNote>): AjaxApiResult<IBuildNote[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/buildNotes/', {params: filter});
  }, 'Could not fetch build notes');
}

export async function createBuildNotePOST(buildUuid: string, note: string): AjaxApiResult<IBuildNote> {
  return ajaxWrapper(() => {
    return axios.post(`/api/builds/${buildUuid}/notes`, {note});
  }, 'Unable to create build note');
}

export async function deleteBuildNoteDELETE(buildUuid: string, createdAt: Date): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/builds/${buildUuid}/notes`, {
      data: {createdAt},
    });
  }, 'Unable to delete build note');
}
