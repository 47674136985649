import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {IOrganization} from '@common/api/models/organization/IOrganization';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import qs from 'qs';

export async function organizationsAllGET(filter: QueryFilter<IOrganization>): AjaxApiResult<IOrganization[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/organizations/' + (query && '?' + query));
  }, 'Could not fetch organizations');
}

export async function organizationByUuidPATCH(
  uuid: string,
  update: Partial<IOrganization>
): AjaxApiResult<IOrganization> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/organizations/uuid/${uuid}/`, update);
  }, 'Could not patch organization');
}

export async function organizationCreatePOST(org: Partial<IOrganization>): AjaxApiResult<IOrganization> {
  return ajaxWrapper(() => {
    return axios.post('/api/organizations/', org);
  }, 'Could not create organization');
}
