import React, {useState} from 'react';
import {Button, Card, CardContent, Grid, Typography} from '@material-ui/core';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {IBuild} from '@common/api/models/builds/IBuild';
import ResourcePermissionsTable from '../../../../components/molecules/Table/ResourcePermissionsTable';
import {PersonAdd} from '@material-ui/icons';
import {GenericDialog} from '../../../../components/molecules/DialogButton';

function SideBarUserAccess({build}: {build: IBuild}) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Card>
          <CardContent style={{padding: '16px'}}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" align="center" style={{margin: 5}}>
                User Access
              </Typography>

              <Button
                variant="contained"
                color="primary"
                endIcon={<PersonAdd />}
                style={{margin: 5}}
                onClick={() => setModalOpen(true)}
              >
                View User Access
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <GenericDialog
        title="User Access"
        isOpen={modalOpen}
        closeDialog={() => setModalOpen(false)}
        closeText="Close"
        maxWidth="lg"
        content={<ResourcePermissionsTable resourceType={ResourcePermissionType.BUILD} resource={build} />}
      />
    </>
  );
}

export default SideBarUserAccess;
