import {IMachineLog} from '@common/api/models/devices/machines/machineLogs/IMachineLog';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function machineLogsAllGET(filter: QueryFilter<IMachineLog>): AjaxApiResult<IMachineLog[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/machineLogs/', {params: filter});
  }, 'Could not fetch machine logs');
}

export async function machineLogByUuidGET(uuid: string): AjaxApiResult<IMachineLog> {
  return ajaxWrapper(() => {
    return axios.get(`/api/machineLogs/uuid/${uuid}`);
  }, 'Could not fetch machine logs');
}

export async function machineLogCreatePOST(obj: Partial<IMachineLog>): AjaxApiResult<IMachineLog> {
  return ajaxWrapper(() => {
    return axios.post('/api/machineLogs/', obj);
  }, 'Could not create machine logs');
}

export async function machineLogDELETE(uuid: string): AjaxApiResult<IMachineLog> {
  return ajaxWrapper(() => {
    return axios.delete('/api/machineLogs/uuid/' + uuid);
  }, 'Could not delete machine logs');
}
