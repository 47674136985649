import React, {useState} from 'react';
import MachineSelectorModal from './MachineSelectorModal';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import CustomButton from '../../atoms/CustomButton';
import {MachineRow} from '../Table/MachinesTable';
import {useSmallScreenSize} from '../../../utils/utilHooks';

interface MachineSelectorButtonProps {
  machine: IMachine | null;
  onMachineSelected: (m: IMachine | null) => any;
  error?: boolean;
  machineFilter?: (machine: IMachine) => boolean;
  excludeColumns?: (keyof MachineRow)[];
  label?: string;
  fullWidthButton?: boolean;
  cantViewCurrentMachine?: boolean;
}

export function MachineSelectorButton(props: MachineSelectorButtonProps) {
  const isSmallScreen = useSmallScreenSize();
  const [machineModalOpen, setMachineModalOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <CustomButton
        color="primary"
        variant="outlined"
        onClick={() => setMachineModalOpen(true)}
        error={props.error}
        size={isSmallScreen ? 'small' : 'medium'}
        fullWidth={props.fullWidthButton}
        text={
          props.machine
            ? props.label || props.machine.name + ' (' + props.machine.uuid.substr(0, 7) + ')'
            : props.cantViewCurrentMachine
            ? props.label || 'Change Machine'
            : 'Select a Machine...'
        }
      />
      <MachineSelectorModal
        open={machineModalOpen}
        onClose={() => setMachineModalOpen(false)}
        onMachineSelected={(m) => {
          props.onMachineSelected(m);
          setMachineModalOpen(false);
        }}
        machineFilter={props.machineFilter}
        excludeColumns={props.excludeColumns}
        initiallySelectedUuid={props.machine?.uuid}
      />
    </React.Fragment>
  );
}
