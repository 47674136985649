import {MachinePageProps} from './index';
import React, {useEffect, useState} from 'react';
import {Card, CardContent, Grid, Table, TableBody} from '@material-ui/core';
import {toast} from 'react-toastify';

import {validator} from '@common/api/types';
import {
  validateMachineName,
  validateMachineModel,
  validateCommissionedDate,
  validateMachineManufacturer,
} from '@common/api/models/devices/machines/Machine.validation';
import {MACHINE_DETAILS} from '@common/api/models/devices/machines/Machine.utils';
import ViewEditFieldRow from '../../../components/atoms/ViewEditFieldRow';
import {machineByUuidPATCH} from '../../../api/ajax/machines';
import {sensorProfilesGET} from '../../../api/ajax/sensorProfile';
import {deviceProfilePUT} from '../../../api/ajax/devices';
import {DeviceState} from '@common/api/models/devices/IDevice';

function MachineEdit({machine, device}: MachinePageProps) {
  const [availableProfiles, setAvailableProfiles] = useState<Array<{value: string; text: string}>>([]);

  useEffect(() => {
    async function fetchAvailableProfiles() {
      const res = await sensorProfilesGET({deviceSerial: device.serial});

      if (res.success) {
        setAvailableProfiles(res.data.map((profile) => ({value: profile.uuid, text: profile.name})));
      }
    }

    if (device?.serial && !availableProfiles.length) {
      fetchAvailableProfiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.serial]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table>
              <TableBody>
                <ViewEditFieldRow
                  type="text"
                  name="Machine Name"
                  value={machine.name}
                  validateFn={(value) =>
                    validator(validateMachineName, value, (message) => toast(message, {type: 'error'}))
                  }
                  saveValue={async (name) => {
                    await machineByUuidPATCH(machine.uuid, {name});
                  }}
                />

                <ViewEditFieldRow
                  type="choice"
                  name="Manufacturer"
                  readOnlyTooltip={
                    !!device && device.state !== DeviceState.IDLE
                      ? 'Can only change machine manufacturer when device is idle'
                      : undefined
                  }
                  values={Object.keys(MACHINE_DETAILS).map((option) => ({
                    value: option,
                    text: option,
                  }))}
                  readOnly={!!device && device.state !== DeviceState.IDLE}
                  value={machine.manufacturer}
                  validateFn={(value) =>
                    validator(validateMachineManufacturer, value, (message) => toast(message, {type: 'error'}))
                  }
                  saveValue={async (manufacturer) => {
                    await machineByUuidPATCH(machine.uuid, {manufacturer});
                  }}
                />

                <ViewEditFieldRow
                  type="choice"
                  name="Model"
                  readOnlyTooltip={
                    !!device && device.state !== DeviceState.IDLE
                      ? 'Can only change machine model when device is idle'
                      : undefined
                  }
                  values={Object.keys(MACHINE_DETAILS[machine.manufacturer] || {}).map((option) => ({
                    value: option,
                    text: option,
                  }))}
                  value={machine.model}
                  readOnly={
                    (!!device && device.state !== DeviceState.IDLE) ||
                    !machine.manufacturer ||
                    !validateMachineManufacturer(machine.manufacturer).success
                  }
                  validateFn={(value) =>
                    validator(
                      (model) => validateMachineModel([machine.manufacturer, model]),
                      value,
                      (message) => toast(message, {type: 'error'})
                    )
                  }
                  saveValue={async (model) => {
                    await machineByUuidPATCH(machine.uuid, {model});
                  }}
                />

                {!!availableProfiles.length && (
                  <ViewEditFieldRow
                    type="choice"
                    name="Default Sensor Profile"
                    values={availableProfiles}
                    value={device.sensorProfileUuid}
                    valueText={availableProfiles.find((profile) => profile.value === device.sensorProfileUuid)?.text}
                    saveValue={async (profileUuid) => {
                      await deviceProfilePUT(device.serial, profileUuid);
                    }}
                  />
                )}
                <ViewEditFieldRow
                  type="text"
                  name="Location"
                  value={machine.location}
                  saveValue={async (location) => {
                    await machineByUuidPATCH(machine.uuid, {location});
                  }}
                />
                <ViewEditFieldRow
                  type="text"
                  name="Description"
                  value={machine.description}
                  saveValue={async (description) => {
                    await machineByUuidPATCH(machine.uuid, {description});
                  }}
                />
                <ViewEditFieldRow
                  type="date"
                  name="Commissioned"
                  value={machine.commissioned}
                  validateFn={(value) =>
                    validator(validateCommissionedDate, value, (message) => toast(message, {type: 'error'}))
                  }
                  saveValue={async (commissioned) => {
                    await machineByUuidPATCH(machine.uuid, {
                      commissioned,
                    });
                  }}
                />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function GeneralDetailsPage({machine, device}: MachinePageProps) {
  return <MachineEdit machine={machine} device={device} />;
}
