import React, {useMemo} from 'react';

import {Grid, Typography} from '@material-ui/core';
import {ShowChart} from '@material-ui/icons';
import {DefectType, getPrettyDefectType, stringToDefectType} from '@common/api/models/builds/data/defects/IDefect';

import ScatterChart from '../../../../components/atoms/Charts/ScatterChart';
import {GlobalGraphConfig, RollingMethod} from './DefectsPage';
import {
  calculateRollingAverages,
  generateGraph,
  generateMinMaxGraphs,
  generatePartGraph,
} from '../../../../utils/graphutils';
import {SelectedDefectSummary} from './useDefectSummaries';
import styled from 'styled-components';

interface DefectsGraphProps {
  selectedDefects: SelectedDefectSummary[];
  height?: number | undefined;
  globalGraphConfig: GlobalGraphConfig;
}

export type graphData = {
  name: string;
  x: number[];
  y: (number | null)[];
};

const generateGraphTitle = (selectedDefects: SelectedDefectSummary[], showCoverage: boolean) => {
  const selectedDefectKeys = Object.keys(selectedDefects).map((selectedDefectKey) => parseInt(selectedDefectKey));

  if (selectedDefectKeys.length > 1) {
    return `Defect ${showCoverage ? 'coverage' : 'area'} in parts`;
  } else if (selectedDefectKeys.length === 1) {
    const defectsString = selectedDefects[0].defectType === DefectType.Lsdd ? ' ' : ' defects ';
    return `${getPrettyDefectType(stringToDefectType(selectedDefects[0].defectType))}${defectsString}in part ${
      selectedDefects[0].partName
    }`;
  } else {
    return '';
  }
};

const NoPartsSelected = ({height}: {height?: number | undefined}) => {
  return (
    <Grid
      style={{
        height: height ? `${height}` : '100%',
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <ShowChart />
      <Typography>Select data to display</Typography>
    </Grid>
  );
};

export default function DefectsGraph({selectedDefects, height, globalGraphConfig}: DefectsGraphProps) {
  const calculateGraphData = () => {
    let finalGraphData: Partial<Plotly.PlotData>[] = [];
    const globalGraphPrefix = globalGraphConfig.rollingDataConfig.rollingMethod;

    selectedDefects.forEach((defect) => {
      const {rollingDataConfig, showOriginal, showTrendline, showPartArea} = defect.graphConfig.useOverrides
        ? defect.graphConfig
        : globalGraphConfig;

      if (showPartArea && !globalGraphConfig.useCoverage) {
        finalGraphData.push(generatePartGraph(defect));
      }
      if (showOriginal) {
        finalGraphData.push(generateGraph(defect, globalGraphPrefix, globalGraphConfig.useCoverage));
      }
      if (showTrendline) {
        if (rollingDataConfig.rollingMethod === RollingMethod.minMax) {
          generateMinMaxGraphs(
            defect,
            globalGraphPrefix,
            globalGraphConfig.useCoverage,
            rollingDataConfig.rollingPeriod
          ).forEach((graph) => finalGraphData.push(graph));
        } else {
          const data = calculateRollingAverages(
            globalGraphConfig.useCoverage ? defect.coverageGraph.y : defect.areaGraph.y,
            defect.areaGraph.x,
            rollingDataConfig.rollingPeriod,
            rollingDataConfig.rollingMethod
          );
          finalGraphData.push(generateGraph(defect, globalGraphPrefix, globalGraphConfig.useCoverage, data.x, data.y));
        }
      }
    });
    return finalGraphData;
  };

  const graphData: Partial<Plotly.PlotData>[] = useMemo(calculateGraphData, [selectedDefects, globalGraphConfig]);

  const graphTitle = useMemo(
    () => generateGraphTitle(selectedDefects, globalGraphConfig.useCoverage),
    [selectedDefects, globalGraphConfig.useCoverage]
  );

  const noSelectedDefects = selectedDefects.length === 0;

  if (noSelectedDefects) {
    return <NoPartsSelected height={height} />;
  }

  return (
    <StyledDiv onMouseDown={(event) => event.stopPropagation()}>
      <ScatterChart
        data={graphData}
        layout={{
          title: {
            text: graphTitle,
          },
          hovermode: 'x unified',
          xaxis: {title: 'Layer'},
          yaxis: {
            title: globalGraphConfig.useCoverage ? 'Coverage (%)' : 'Area (mm²)',
            tickfont: {color: '#1f77b4'},
            side: 'left',
          },
          margin: {r: 10, l: 50, t: 40, b: 70},
        }}
        height={height}
      />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  height: 100%;

  .hoverlayer {
    svg.main-svg,
    svg.main-svg * {
      overflow: visible !important;
      z-index: 9999;
    }
  }
`;
