import {isBoolean, isString} from 'lodash';
import {ComparisonFilters} from '../IndexPage';

export function parseCurrentFilters(currentFilters: ComparisonFilters, field: string): [string, any] {
  const currentFilter = currentFilters[field];

  if (isString(currentFilter)) {
    return ['in', currentFilter];
  } else if (isBoolean(currentFilter)) {
    return ['eq', currentFilter];
  } else {
    return Object.entries(currentFilter || {})[0] || [];
  }
}
