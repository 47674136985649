import React from 'react';
import Tour from 'reactour';

export const BaseTour = (props: any) => {
  return (
    <>
      <Tour
        steps={props.steps}
        isOpen={props.isTourOpen}
        rounded={5}
        onRequestClose={() => props.setIsTourOpen(false)}
      />
    </>
  );
};
