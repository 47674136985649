import React, {useState, useEffect} from 'react';
import {TextField, Grid} from '@material-ui/core';
import inflection from 'inflection';
import {omit} from 'lodash';
import {FilterComponentProps} from '../FilterModal';
import ComparisonsSelector from './ComparisonSelector';
import {newDebouncer} from '../../../../api/ajax';
import {parseCurrentFilters} from './utils';

const stringComparisons = {
  like: 'Like',
  notLike: 'Not like',
  equal: 'Equal',
  notEqual: 'Not equal',
};

type StringComparison = keyof typeof stringComparisons;

const debounceSearch = newDebouncer();

const StringFilter = ({
  field,
  control,
  onSubmit,
  label,
  placeholder,
  currentFilters,
  permanentComparison,
  excludeComparisons = [],
  filterListLocation,
}: FilterComponentProps) => {
  const initialFilter = parseCurrentFilters(currentFilters, field);
  const [internalValue, setInternalValue] = useState(initialFilter[1] || '');
  const [comparison, setComparison] = useState<StringComparison>(
    ((permanentComparison ?? initialFilter[0]) as StringComparison) || 'like'
  );

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSearch(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 400);
  };

  useEffect(() => {
    control.register(field);
  }, [control, field]);

  useEffect(() => {
    const filterValue = parseCurrentFilters(currentFilters, field);
    setInternalValue(filterValue[1] ? filterValue[1] : initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const setValue = (newValue: string) => {
    setInternalValue(newValue);
    control.setValue(field, newValue ? {[comparison]: newValue} : undefined);

    submitField();
  };

  const onComparisonChanged = (newComparison: StringComparison) => {
    setComparison(newComparison);
    setInternalValue(internalValue);
    if (internalValue) {
      control.setValue(field, {[newComparison]: internalValue});
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={!permanentComparison ? 6 : 12} sm={!permanentComparison ? 8 : 12}>
        <TextField
          size="small"
          fullWidth
          name={field}
          placeholder={placeholder || label || inflection.capitalize(field)}
          label={label || inflection.capitalize(field)}
          variant="outlined"
          onChange={(e) => setValue(e.target.value)}
          value={internalValue}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {!permanentComparison && (
        <Grid item xs={6} sm={4}>
          <ComparisonsSelector
            comparisons={
              omit(stringComparisons, excludeComparisons) as {
                [key: string]: string;
              }
            }
            currentComparison={comparison}
            setCurrentComparison={onComparisonChanged}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default StringFilter;
