import {useEffect, useMemo, useState} from 'react';
import Plotly, {PlotData} from 'plotly.js';
import {useSelector} from 'react-redux';
import {GraphDataType} from './GraphOptionsModal';
import {RootState} from '../../../store/reducers';
import {BuildNameByUuidType, GraphDataTogglesType, PartCSVsType} from '../../../store/model/buildIntensityReport';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

export const getColor = (uuid: string, d3Color: d3.scale.Ordinal<string, string>) => {
  const color = d3Color(uuid);
  const hslColor = Plotly.d3.hsl(color);

  const stdUpperColor = `hsl(${hslColor.h}, ${hslColor.s * 100 + 5}%, ${hslColor.l * 100 + 5}%)`;
  const stdLowerColor = `hsl(${hslColor.h}, ${hslColor.s * 100 - 5}%, ${hslColor.l * 100 - 5}%)`;

  return {color, stdLowerColor, stdUpperColor};
};

export const generateHoverTemplate = (name: string): string => {
  return `
  <b>${name}</b>: %{y}
  <extra></extra>`;
};

// Helper function to process and calculate rolling averages and bounds
export const calculateRollingValues = (data: Array<number | null>, rollingPeriod: number): Array<number | null> => {
  const rollingData: Array<number | null> = [];
  const firstEntry = data.findIndex((value) => value !== null);
  const bottomHalfPeriod = Math.floor(rollingPeriod / 2);
  const topHalfPeriod = Math.ceil(rollingPeriod / 2);

  for (let i = 0; i < bottomHalfPeriod + firstEntry; i++) {
    rollingData.push(null);
  }
  for (let i = firstEntry + bottomHalfPeriod; i < data.length - topHalfPeriod; i++) {
    const start = Math.max(firstEntry, i - bottomHalfPeriod);
    const end = i + topHalfPeriod;
    const window = data.slice(start, end).map((value) => value || 0);
    const average = window.reduce((a, b) => a + b, 0) / window.length;
    rollingData.push(average);
  }

  return rollingData;
};

export const graphScatter = (
  xData: number[],
  yData: Array<number | null>,
  name: string,
  uuid: string,
  type: 'build' | 'part',
  color: string
): Partial<PlotData> => {
  const scatterData: Partial<PlotData> = {
    x: xData,
    y: yData,
    type: 'scatter',
    mode: 'markers',
    name: name,
    marker: {
      color: color,
    },
    hovertemplate: generateHoverTemplate(name),
    customdata: [type, uuid, 'scatter'],
  };
  return scatterData;
};

export const graphTrendlines = (
  xData: number[],
  yData: Array<number | null>,
  name: string,
  uuid: string,
  type: 'build' | 'part',
  color: string,
  rollingPeriod: number
): Partial<PlotData> => {
  const smoothedYData = calculateRollingValues(yData, rollingPeriod);

  // Process trendline data for builds
  const trendline: Partial<PlotData> = {
    type: 'scatter',
    mode: 'lines',
    x: xData,
    y: smoothedYData,
    name: `${name} Trendline`,
    line: {
      color: color,
    },
    hovertemplate: generateHoverTemplate(name),
    customdata: [type, uuid, 'trendline', rollingPeriod],
  };
  return trendline;
};

export const graphSTDlines = (
  xData: number[],
  yData: Array<number | null>,
  stdData: Array<number | null>,
  name: string,
  uuid: string,
  type: 'build' | 'part',
  stdUpperColor: string,
  stdLowerColor: string,
  rollingPeriod: number
): Partial<PlotData>[] => {
  const upperBounds = yData.map((value, index) => (value ? value + (stdData[index] || 0) : null));
  const lowerBounds = yData.map((value, index) => (value ? value - (stdData[index] || 0) : null));
  const rollingUpperBound = calculateRollingValues(upperBounds, rollingPeriod);
  let rollingLowerBound = calculateRollingValues(lowerBounds, rollingPeriod);
  rollingLowerBound = rollingLowerBound.map((value) => (value && value < 0 ? 0 : value));

  const upperBoundLine: Partial<PlotData> = {
    type: 'scatter',
    mode: 'lines',
    x: xData,
    y: rollingUpperBound,
    name: `${name} Upper Bound`,
    line: {color: stdUpperColor},
    fill: 'tonexty',
    hovertemplate: generateHoverTemplate(`${name} Upper Bound`),
    customdata: [type, uuid, 'std', rollingPeriod],
  };

  const lowerBoundLine: Partial<PlotData> = {
    type: 'scatter',
    mode: 'lines',
    x: xData,
    y: rollingLowerBound,
    name: `${name} Lower Bound`,
    line: {color: stdLowerColor},
    fill: 'tonexty',
    hovertemplate: generateHoverTemplate(`${name} Lower Bound`),
    customdata: [type, uuid, 'std', rollingPeriod],
  };

  return [upperBoundLine, lowerBoundLine];
};

export const useIntensityGraphData = (d3Color: d3.scale.Ordinal<string, string>) => {
  const graphDataType = useSelector((state: RootState) => state.buildIntensityReportStore.graphDataType);
  const graphDataToggles = useSelector((state: RootState) => state.buildIntensityReportStore.graphDataToggles);
  const rollingPeriod = useSelector((state: RootState) => state.buildIntensityReportStore.rollingPeriod);
  const buildNamesByBuildUuid = useSelector(
    (state: RootState) => state.buildIntensityReportStore.buildNamesByBuildUuid
  );
  const selectedParts = useSelector((state: RootState) => state.buildIntensityReportStore.selectedParts);
  const selectedBuilds = useSelector((state: RootState) => state.buildIntensityReportStore.selectedBuilds);
  const partCSVs = useSelector((state: RootState) => state.buildIntensityReportStore.partCSVs);
  const summaryCSVs = useSelector((state: RootState) => state.buildIntensityReportStore.summaryCSVs);

  const allPartCSVs = useMemo(
    () => Object.values(partCSVs).reduce((acc, partCSVsByUuid) => ({...acc, ...partCSVsByUuid}), {}),
    [partCSVs]
  );

  const [graphData, setGraphData] = useState<Partial<PlotData>[]>([]);

  let isLoading = true;
  const loadedPartCSVs = Array.from(selectedParts).filter((part) => !!allPartCSVs[part.uuid]).length;
  const loadedBuildCSVs = Array.from(selectedBuilds).filter((buildUuid) => !!summaryCSVs[buildUuid]).length;

  if (selectedBuilds.size === 0 && selectedParts.size === 0) {
    isLoading = true;
  } else if (loadedBuildCSVs === selectedBuilds.size && loadedPartCSVs === selectedParts.size) {
    isLoading = false;
  } else {
    isLoading = true;
  }

  useEffect(() => {
    if (!isLoading) {
      setGraphData(
        generateGraphData(
          selectedBuilds,
          selectedParts,
          partCSVs,
          allPartCSVs,
          buildNamesByBuildUuid,
          d3Color,
          graphDataType,
          graphDataToggles,
          rollingPeriod
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedBuilds, selectedParts, partCSVs, graphDataType, graphDataToggles, rollingPeriod]);

  return {graphData, isLoading, noneSelected: selectedParts.size === 0 && selectedBuilds.size === 0};
};

const generateGraphData = (
  selectedBuilds: Set<string>,
  selectedParts: Set<IPartGETResponse>,
  partCSVs: PartCSVsType,
  allPartCSVs: PartCSVsType['buildUuid'],
  buildNamesByBuildUuid: BuildNameByUuidType,
  d3Color: d3.scale.Ordinal<string, string>,
  graphDataType: GraphDataType,
  graphDataToggles: GraphDataTogglesType,
  rollingPeriod: number
) => {
  const newGraphData: Partial<PlotData>[] = [];

  for (const buildUuid of Array.from(selectedBuilds)) {
    const xData: number[] = [];
    const yData: Array<number | null> = [];
    const stdData: Array<number | null> = [];
    const layerCount: {[layer: number]: number} = {};
    const layerDataTotal: {[layer: number]: number | null} = {};
    const layerSTDTotal: {[layer: number]: number | null} = {};
    const name = buildNamesByBuildUuid[buildUuid];
    let firstLayer: number = Number.MAX_SAFE_INTEGER;

    const {color, stdLowerColor, stdUpperColor} = getColor(buildUuid, d3Color);

    for (const partData of Object.values(partCSVs[buildUuid] || {})) {
      for (let i = 0; i < partData.layer.length; i++) {
        const layerNum = partData.layer[i];
        if (!layerCount[layerNum]) {
          layerDataTotal[layerNum] = partData[graphDataType][i];
          layerSTDTotal[layerNum] = partData.std[i];
          layerCount[layerNum] = 1;
        } else {
          if (graphDataType === GraphDataType.Max) {
            layerDataTotal[layerNum]! = Math.max(layerDataTotal[layerNum]!, partData[graphDataType][i]);
          } else if (graphDataType === GraphDataType.Min) {
            layerDataTotal[layerNum]! = Math.min(layerDataTotal[layerNum]!, partData[graphDataType][i]);
          } else {
            layerDataTotal[layerNum]! += partData[graphDataType][i];
            layerSTDTotal[layerNum]! += partData.std[i];
            layerCount[layerNum] += 1;
          }
        }

        if (layerNum < firstLayer!) {
          firstLayer = layerNum;
        }
      }
    }

    for (let i = 0; i < firstLayer!; i++) {
      layerCount[i] = 0;
      layerDataTotal[i] = null;
      layerSTDTotal[i] = null;
    }

    for (let layer in layerCount) {
      xData.push(parseInt(layer));
      if (
        [GraphDataType.MaxCount, GraphDataType.TotalCount, GraphDataType.Min, GraphDataType.Max].includes(graphDataType)
      ) {
        yData.push(!!layerDataTotal[layer] ? layerDataTotal[layer]! : null);
      } else {
        yData.push(!!layerDataTotal[layer] ? layerDataTotal[layer]! / layerCount[layer] : null);
        stdData.push(!!layerSTDTotal[layer] ? layerSTDTotal[layer]! / layerCount[layer] : null);
      }
    }

    // Process scatter data for builds
    if (graphDataToggles.showScatter) {
      const scatterData = graphScatter(xData, yData, name, buildUuid, 'build', color);
      newGraphData.push(scatterData);
    }

    if (graphDataToggles.showTrendline || (graphDataToggles.showSTD && graphDataType === 'mean')) {
      const trendline = graphTrendlines(xData, yData, name, buildUuid, 'build', color, rollingPeriod);

      if (graphDataToggles.showSTD && graphDataType === 'mean') {
        const stdlines = graphSTDlines(
          xData,
          yData,
          stdData,
          name,
          buildUuid,
          'build',
          stdUpperColor,
          stdLowerColor,
          rollingPeriod
        );
        const invisibleTrendline = graphTrendlines(xData, yData, name, buildUuid, 'build', color, rollingPeriod);
        invisibleTrendline.hoverinfo = 'none';
        invisibleTrendline.hovertemplate = undefined;
        newGraphData.push(trendline, stdlines[0], invisibleTrendline, stdlines[1]);
      } else {
        newGraphData.push(trendline);
      }
    }
  }

  for (const part of Array.from(selectedParts)) {
    const partData = allPartCSVs[part.uuid];
    const name = partData.part_name[0];
    const xData: Array<number> = [];
    const yData: Array<number | null> = [];
    const std: Array<number | null> = [];
    const layerCount: {[layer: number]: number} = {};
    const layerData: {[layer: number]: number | null} = {};
    const layerSTD: {[layer: number]: number | null} = {};
    let firstLayer: number = Number.MAX_SAFE_INTEGER;

    const {color, stdLowerColor, stdUpperColor} = getColor(part.uuid, d3Color);

    for (let i = 0; i < partData.layer.length; i++) {
      const layerNum = partData.layer[i];
      layerCount[layerNum] = 1;
      layerData[layerNum] = partData[graphDataType][i];
      layerSTD[layerNum] = partData.std[i];

      if (layerNum < firstLayer) {
        firstLayer = layerNum;
      }
    }

    for (let i = 0; i < firstLayer!; i++) {
      layerCount[i] = 0;
      layerData[i] = null;
      layerSTD[i] = null;
    }

    for (const layerNum in layerCount) {
      xData.push(parseInt(layerNum));
      yData.push(layerData[layerNum]);
      std.push(layerSTD[layerNum]);
    }

    if (graphDataToggles.showScatter) {
      const scatterData = graphScatter(xData, yData, name, part.uuid, 'part', color);
      newGraphData.push(scatterData);
    }

    if (graphDataToggles.showTrendline || (graphDataToggles.showSTD && graphDataType === 'mean')) {
      const trendline = graphTrendlines(xData, yData, name, part.uuid, 'part', color, rollingPeriod);

      if (graphDataToggles.showSTD && graphDataType === 'mean') {
        const invisibleTrendline = graphTrendlines(xData, yData, name, part.uuid, 'part', color, rollingPeriod);
        const stdlines = graphSTDlines(
          xData,
          yData,
          std,
          name,
          part.uuid,
          'part',
          stdUpperColor,
          stdLowerColor,
          rollingPeriod
        );
        invisibleTrendline.hoverinfo = 'none';
        invisibleTrendline.hovertemplate = undefined;
        newGraphData.push(trendline, stdlines[0], invisibleTrendline, stdlines[1]);
      } else {
        newGraphData.push(trendline);
      }
    }
  }

  return newGraphData;
};
