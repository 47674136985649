import {BuildAttachmentType} from '@common/api/models/attachments/IAttachmentBase';
import {FilterSchemaType} from '.';

export const buildAttachmentDefaultFilters = {
  type: {notIn: [BuildAttachmentType.BuildPhoto, BuildAttachmentType.BuildPhotoMain]},
};

export const buildAttachmentFilterSchema: Array<FilterSchemaType> = [
  {field: 'filename', type: 'string', label: 'Name'},
  {
    field: 'filesize',
    type: 'float',
  },
  {field: 'createdAt', type: 'dateTimeRange', label: 'Created at'},
];
