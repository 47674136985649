import React, {useCallback, useState} from 'react';
import {Button as MuiButton, Typography as MuiTypography, Grid, Box} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {IBuild} from '@common/api/models/builds/IBuild';
import {
  IMachineParameter,
  MachineParameterData,
} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';
import {RootState} from '../../../../store/reducers';
import {AuthState} from '../../../../store/model/auth';
import MachineParameterFormModal from '../../../../components/molecules/Forms/ParameterSetFormModal';
import {machineParameterByUuidDELETE, machineParameterCreatePOST} from '../../../../api/ajax/machineParameters';
import {useDraftBuild} from '../DraftBuildContext';
import GenericTable from '../../../../components/molecules/Table/GenericTable';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {GenericDialog} from '../../../../components/molecules/DialogButton';

const Typography = styled(MuiTypography)(spacing);
const Button = styled(MuiButton)(spacing);

export const initialMachineParameterState = (authState: AuthState) =>
  ({
    organizationUuid: authState.user!.organizationUuid,
    name: '',
    data: {} as MachineParameterData,
    genericNotes: '',
  } as IMachineParameter);

const MachineParametersStep = () => {
  const isSmallScreen = useSmallScreenSize();
  const {setDraftWithSave} = useDraftBuild();
  const authState = useSelector((state: RootState) => state.auth);
  const [newParametersModalOpen, setNewParametersModalOpen] = useState(false);

  const onMachineParameterAdded = async (machineParameters: IMachineParameter) => {
    const res = await machineParameterCreatePOST(machineParameters);
    if (res.success) {
      setDraftWithSave((draftBuild) => {
        return {
          ...draftBuild,
          machineParametersUuid: res.data.uuid,
        };
      });
      return true;
    }
    return false;
  };

  return (
    <div>
      <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="space-between">
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} style={{marginBottom: '1rem'}}>
            Step 3: Machine Parameters
          </Typography>

          <NewParameterSetButton onClick={() => setNewParametersModalOpen(true)} />
        </Box>
      </Grid>

      <MachineParametersTable />

      {newParametersModalOpen && (
        <MachineParameterFormModal
          isOpen={newParametersModalOpen}
          onClose={() => setNewParametersModalOpen(false)}
          mode="create"
          initialState={initialMachineParameterState(authState)}
          onSave={onMachineParameterAdded}
        />
      )}
    </div>
  );
};

export default MachineParametersStep;

const NewParameterSetButton = ({onClick}: {onClick: () => void}) => {
  const isSmallScreen = useSmallScreenSize();

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button color="primary" variant="contained" onClick={onClick} startIcon={<Add />} fullWidth={isSmallScreen}>
        Add Parameter Set
      </Button>
    </Box>
  );
};

const baseFilters = {};
const defaultFilters = {sortBy: {registered: 'DESC'}} as any;

export type MachineParameterRowData = IMachineParameter & {
  draftBuild: IBuild;
  onView: () => void;
  onClone: () => void;
  onDelete: () => void;
};

const MachineParametersTable = () => {
  const {setDraftWithSave, draftBuild} = useDraftBuild();
  const [paramsDeleting, setParamsDeleting] = useState<IMachineParameter | undefined>();
  const machineParametersList = useSelector((state: RootState) => state.machineParameterStore.list);
  const [copying, setCopying] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [initialState, setInitialState] = useState<IMachineParameter | undefined>();

  const onClose = () => {
    setCopying(false);
    setViewing(false);
    setInitialState(undefined);
  };

  const onClone = useCallback((rowData: IMachineParameter) => {
    setInitialState({...rowData, name: `${rowData.name} (Copy)`});
    setCopying(true);
  }, []);

  const onView = useCallback((rowData: IMachineParameter) => {
    setInitialState(rowData);
    setViewing(true);
  }, []);

  const onRowClick = (_event: any, row: any) => {
    setDraftWithSave((draftBuild) => {
      const newMachineParametersUuid = draftBuild.machineParametersUuid === row?.uuid ? null : row?.uuid;
      return {
        ...draftBuild,
        machineParametersUuid: newMachineParametersUuid,
      };
    });
  };

  const onMachineParameterAdded = async (machineParameters: IMachineParameter) => {
    const res = await machineParameterCreatePOST(machineParameters);
    if (res.success) {
      setDraftWithSave((draftBuild) => {
        return {
          ...draftBuild,
          machineParametersUuid: res.data.uuid,
        };
      });
      return true;
    }
    return false;
  };

  const useMachineParamsWithFunctions = useCallback(() => {
    return machineParametersList.map((machineParameters) => {
      return {
        ...machineParameters,
        onView: () => onView(machineParameters),
        onClone: () => onClone(machineParameters),
        onDelete: () => setParamsDeleting(machineParameters),
        draftBuild,
      };
    });
  }, [onView, onClone, draftBuild, machineParametersList]);

  return (
    <Grid item xs={12} style={{paddingTop: '32px'}}>
      <GenericTable
        filteringEnabled
        permanentFilters={baseFilters}
        defaultFilters={defaultFilters}
        tableTitle="Choose a Parameter Set (Optional)"
        resourceType="machineParameter"
        useData={useMachineParamsWithFunctions}
        onRowClick={onRowClick}
        minTableWidth="600px"
        tableOptions={{
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData?.uuid === draftBuild.machineParametersUuid ? '#F5FaFF' : '',
          }),
        }}
      />
      {(!!copying || !!viewing) && (
        <MachineParameterFormModal
          isOpen={!!copying || !!viewing}
          onClose={onClose}
          mode={!!copying ? 'copy' : 'view'}
          initialState={initialState!}
          onSave={onMachineParameterAdded}
        />
      )}
      <DeleteModal paramsDeleting={paramsDeleting} setParamsDeleting={setParamsDeleting} />
    </Grid>
  );
};

const DeleteModal = ({
  paramsDeleting,
  setParamsDeleting,
}: {
  paramsDeleting: IMachineParameter | undefined;
  setParamsDeleting: React.Dispatch<React.SetStateAction<IMachineParameter | undefined>>;
}) => {
  const [deleting, setDeleting] = useState(false);

  const {setDraftWithSave, draftBuild} = useDraftBuild();

  const onDelete = async () => {
    if (!paramsDeleting) return;

    setDeleting(true);
    const res = await machineParameterByUuidDELETE(paramsDeleting.uuid);
    setDeleting(false);

    if (res.success) {
      if (draftBuild.machineParametersUuid === paramsDeleting.uuid) {
        setDraftWithSave((draftBuild) => {
          return {
            ...draftBuild,
            machineParametersUuid: undefined,
          };
        });
      }
      setParamsDeleting(undefined);
    }
  };

  return (
    <GenericDialog
      title="Delete Parameter Set?"
      content={
        <>
          Are you sure you want to delete the Machine Parameter{' '}
          <b>
            <i>{paramsDeleting?.name}</i>
          </b>
          ?
        </>
      }
      isOpen={!!paramsDeleting}
      closeDialog={() => setParamsDeleting(undefined)}
      onSuccess={onDelete}
      requestInProgress={deleting}
      confirmButtonProps={{endIcon: <Delete />}}
    />
  );
};
