// https://konvajs.org/docs/react/DOM_Portal.html - to add dom elements inside konva
import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  node?: React.ReactNode;
  children?: React.ReactNode;
};

export default class Portal extends React.Component<PortalProps> {
  defaultNode: any;

  componentDidMount() {
    this.renderPortal();
  }

  componentDidUpdate() {
    this.renderPortal();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.defaultNode || this.props.node);
    if (this.defaultNode) {
      document.getElementsByClassName('konvajs-content')[0].removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  renderPortal() {
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div');
      this.defaultNode.style.height = '100%';
      this.defaultNode.style.display = 'flex';
      this.defaultNode.style.justifyContent = 'center';
      document.getElementsByClassName('konvajs-content')[0].appendChild(this.defaultNode);
    }

    let children: any = this.props.children;
    if (typeof children.type === 'function') {
      children = React.cloneElement(children);
    }

    ReactDOM.render(children, this.props.node || this.defaultNode);
  }

  render() {
    return null;
  }
}
