import React, {useState} from 'react';
import {Typography} from '@material-ui/core';
import {IBuild, isEngagedBuildState} from '@common/api/models/builds/IBuild';
import {deleteBuildDELETE} from '../../../../api/ajax/builds';
import {DialogButton} from '../../../../components/molecules/DialogButton';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import {toast} from 'react-toastify';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';

interface LiveBuildControlProps {
  build: IBuild;
}

export default function DeleteBuildButton({build}: LiveBuildControlProps) {
  const [requesting, setRequesting] = useState(false);
  const {machinePermission} = usePermissionsForBuild(build);

  const handleDeleteBuild = async () => {
    setRequesting(true);
    const res = await deleteBuildDELETE(build.uuid);
    setRequesting(false);

    if (!res.success) return;

    toast('Build successfully deleted', {type: 'success'});
    // Want to trigger a refresh of the page to refresh the loaded data
    window.location.href = '/builds';
  };

  const DIALOG_PROPS = {
    title: 'Delete Build',
    content: <Typography>Are you sure you want to delete this build?</Typography>,
    confirmText: 'Delete',
  };

  return (
    <ConditionalTooltip
      tooltip={
        !machinePermission
          ? "You don't have the required permissions to delete this build."
          : 'Please complete the build before deleting.'
      }
      hideTooltip={!!machinePermission && !isEngagedBuildState(build.state)}
    >
      <DialogButton
        danger
        text="Delete Build"
        dialog={DIALOG_PROPS}
        handleConfirm={handleDeleteBuild}
        disabled={!machinePermission || isEngagedBuildState(build.state)}
        loading={requesting}
      />
    </ConditionalTooltip>
  );
}
