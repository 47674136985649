import React from 'react';
import PartSelectorTreeView from './partsSelectorTree/PartsSelectorTreeView';
import {CardContent, Card as MuiCard} from '@material-ui/core';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {SetHoveredSelection} from './BuildIntensityPage';

const Card = styled(MuiCard)(spacing);

const BuildIntensityListView = ({setHoveredSelection}: {setHoveredSelection: SetHoveredSelection}) => {
  const isSmallScreen = useSmallScreenSize();

  return (
    <Card style={{height: isSmallScreen ? '384px' : '100%'}}>
      <CardContent style={{height: '100%'}}>
        <PartSelectorTreeView setHoveredSelection={setHoveredSelection} />
      </CardContent>
    </Card>
  );
};
export default BuildIntensityListView;
