import React, {useEffect, useRef, useState} from 'react';
import {Grid, Link, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {DeviceDetailedState, DeviceState, IDevice} from '@common/api/models/devices/IDevice';
import {isPrivateIp} from '../../../../../utils/ipChecks';

const ManualFocusingWidget = ({
  buildUuid,
  device,
  didStartManualFocusing,
  waitingForPreview,
  previewError,
  setPreviewError,
}: {
  buildUuid: string;
  device: IDevice;
  didStartManualFocusing: boolean;
  waitingForPreview: boolean;
  previewError: boolean;
  setPreviewError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const previewHosts = device.previewHosts;
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [internalState, setInternalState] = useState<DeviceDetailedState>(device.stateDetailed);
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const hostsWithoutLocal = previewHosts?.filter((host) => !host.includes('.local'));

  // Show all hosts for on-prem, exclude hosts with .local for cloud based
  const hostsToShow = process.env.REACT_APP_IS_ON_PREMISE === 'true' ? previewHosts : hostsWithoutLocal;
  const privateHosts = hostsToShow?.filter((host) => isPrivateIp(host));

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // !! IMPORTANT: In future do NOT try and test the connection of IP's     !!
  // !! from the web app. It is destined to fail. Unless the web servers    !!
  // !! on midrig are running HTTPS, the browser will not allow the         !!
  // !! connection to be made. This is a security feature of the browser.   !!
  // !! It will result in a mixed content error and fail. You have been     !!
  // !! warned...                                                           !!
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  useEffect(() => {
    if (hostsToShow?.length) {
      if (privateHosts?.length) {
        setPreviewUrl(privateHosts[privateHosts.length - 1]);
      } else {
        setPreviewUrl(hostsToShow[hostsToShow.length - 1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostsToShow]);

  useEffect(() => {
    const currentState = device.stateDetailed;

    if (currentState === internalState) return;
    if (currentState === DeviceDetailedState.Staging && internalState === DeviceDetailedState.PreviewingStarting) {
      setPreviewError(true);
    }

    setInternalState(device.stateDetailed);
  }, [device.stateDetailed, internalState, setPreviewError]);

  useEffect(() => {
    if (previewUrl) {
      setTimeout(() => {
        scrollToRef?.current?.scrollIntoView();
      });
      if (didStartManualFocusing) {
        window.open(previewUrl);
      }
    }
  }, [previewUrl, didStartManualFocusing]);

  if (previewError) {
    return (
      <Alert severity="error" onClose={() => setPreviewError(false)}>
        Unable to start manual focus mode. <Link href={`/builds/uuid/${buildUuid}/live/alerts/`}>View device Logs</Link>{' '}
        for more information.
      </Alert>
    );
  }

  if (
    device.state !== DeviceState.PREVIEWING &&
    device.stateDetailed !== DeviceDetailedState.PreviewingStarting &&
    (!waitingForPreview || !didStartManualFocusing)
  )
    return <></>;

  if (!previewHosts || waitingForPreview || device.stateDetailed === DeviceDetailedState.PreviewingStarting) {
    return <Alert severity="info">Waiting for device to start up and configure</Alert>;
  }

  if (!hostsToShow || hostsToShow?.length === 0) {
    return <Alert severity="warning">Unable to manually focus device.</Alert>;
  }

  if (!previewUrl) {
    return <Alert severity="info">Finding working URL...</Alert>;
  }

  return (
    <Grid container spacing={2} ref={scrollToRef}>
      <Alert severity="info" style={{width: '100%'}}>
        <Typography>Manually focusing device on: {previewUrl}</Typography>
        <br />
        <Typography>Or, try another manual focusing host:</Typography>
        <ul>
          {hostsToShow.map((host) => {
            return (
              <li key={host}>
                <a
                  href={host}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    setPreviewUrl(host);
                    if (!didStartManualFocusing) {
                      window.open(previewUrl);
                    }
                  }}
                >
                  <i>{host}</i>
                </a>
              </li>
            );
          })}
        </ul>
      </Alert>
    </Grid>
  );
};

export default ManualFocusingWidget;
