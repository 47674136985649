import React from 'react';
import {DateWithTimeTooltip} from './sharedColumns';

export interface MaterialRow {
  name: string;
  type: string;
  grade: string;
  supplier: string;
  description: string;
  quantityRemaining: number;
  manufactured: Date;
  recieved: Date;
}

export const materialTableSchema = [
  {title: 'Name', field: 'name'},
  {title: 'Type', field: 'type', width: '128px'},
  {title: 'Grade', field: 'grade', width: '96px'},
  {title: 'Supplier', field: 'supplier'},
  {title: 'Description', field: 'description', sorting: false},
  {title: 'Quantity Remaining', field: 'quantityRemaining'},
  {
    title: 'Manufactured on',
    field: 'manufactured',
    width: '148px',
    render: (row: MaterialRow) => <DateWithTimeTooltip timestamp={row.manufactured} />,
  },
  {
    title: 'Recieved on',
    field: 'recieved',
    sortField: 'dateReceived',
    width: '148px',
    render: (row: MaterialRow) => <DateWithTimeTooltip timestamp={row.recieved} />,
  },
];

export default materialTableSchema;
