import React from 'react';
import {Info} from '@material-ui/icons';
import {Box, Grid, GridProps, TextField, Tooltip, Typography} from '@material-ui/core';
import {ComparisonWeightsType, SimilarityComparisonType} from '@common/api/models/builds/data/ISimilarity';
import {WeightTypes} from './utils';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';

function WeightsColumn({
  comparisonWeights,
  onChange,
  weightType,
  index,
  xs,
}: {
  comparisonWeights: ComparisonWeightsType;
  onChange: (defectType: SimilarityComparisonType, value: number | null, index: number) => void;
  weightType: WeightTypes;
  index: number;
  xs: GridProps['xs'];
}) {
  const usingLogWeights = Object.values(comparisonWeights).every((value) => !value[0]);

  function onValueChanged(defectType: SimilarityComparisonType, newValue: string, index: number) {
    if (!newValue) {
      onChange(defectType, null, index);
      return;
    }

    if (weightType === WeightTypes.Weight) {
      const value = Math.max(Number(newValue), 0);
      onChange(defectType, value, index);
      return;
    }

    const value = Math.max(Math.min(Number(newValue), weightType === WeightTypes.Slope ? 5 : 1), 0);
    onChange(defectType, value, index);
  }

  return (
    <Grid item container spacing={2} xs={xs} style={{margin: '0px'}}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography>
            <b>{weightType}</b>
          </Typography>
          <Tooltip title={<Typography>{Tooltips[weightType]}</Typography>} interactive>
            <Info color="action" style={{marginLeft: '6px'}} />
          </Tooltip>
        </Box>
      </Grid>

      {(Object.keys(comparisonWeights!) as Array<SimilarityComparisonType>).map((defectType) => (
        <ConditionalTooltip
          tooltip="Cannot manually specify log weights when dynamic log weights is enabled"
          hideTooltip={!usingLogWeights || weightType !== WeightTypes.Weight}
        >
          <Grid item xs={12} key={`${weightType}-${defectType}-weight-input`}>
            <TextField
              size="small"
              type="number"
              value={comparisonWeights![defectType][index] ?? ''}
              onChange={(event) => onValueChanged(defectType, event.target.value, index)}
              variant="outlined"
              inputProps={{
                step: 0.1,
              }}
              label={usingLogWeights && weightType === WeightTypes.Weight ? 'Log weights' : undefined}
              disabled={usingLogWeights && weightType === WeightTypes.Weight}
              fullWidth
            />
          </Grid>
        </ConditionalTooltip>
      ))}
    </Grid>
  );
}

export default WeightsColumn;

const Tooltips = {
  [WeightTypes.Weight]: (
    <>Final multiplier given to the weighted score when calculating the overall similarity score.</>
  ),
  [WeightTypes.MidPoint]: (
    <>
      The 'Mid point' value represents what value will equal 0.5 after weighting. For example, with a 'Mid point' value
      of 0.1, A target part which has 10% more/less severe defect (score of 0.1) would result in a weighted score of 0.5
      (50%) * weight.
    </>
  ),
  [WeightTypes.Slope]: (
    <>
      The 'slope' value represents how steep the slope is on the inverse S-shape. A higher value represents a steeper
      slope.
    </>
  ),
};
