import React, {useEffect, useMemo, useState, useCallback} from 'react';
import {Button, CircularProgress, Grid, Typography, Box} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import {useSelector} from 'react-redux';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IUser} from '@common/api/models/users/IUser';
import {RootState} from '../../../../store/reducers';
import {recipientsCreatePOST} from '../../../../api/ajax/recipients';
import SearchAndSelectUser, {SelectedUserType} from '../../../../components/molecules/Selector/SearchAndSelectUser';
import GenericTable from '../../../../components/molecules/Table/GenericTable';
import EditUserNotificationModal from './EditUserNotificationModal';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {userSearchGET} from '../../../../api/ajax/users';
import envConfig from '../../../../envConfig';

const NotificationRecipients = ({build}: {build: IBuild}) => {
  const isSmallScreen = useSmallScreenSize();
  const [modalOpen, setModalOpen] = useState(false);
  const [editUser, setEditUser] = useState<IUser | undefined>();
  const [userDetails, setUserDetails] = useState<IUser[]>();

  const recipientList = useSelector((s: RootState) => s.recipientStore.list);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const buildUuidFilter = useMemo(() => ({buildUuid: build.uuid}), [build.uuid]);

  const onEdit = (user: IUser) => {
    setEditUser(user);
    setModalOpen(true);
  };

  const onClose = () => {
    setEditUser(undefined);
    setModalOpen(false);
  };

  const getUserInfo = useCallback(async () => {
    if (recipientList.length === 0) return;

    const userInfoResponse = await userSearchGET('', {
      uuid: recipientList.map((r) => r.userUuid!),
    });

    if (userInfoResponse.success) {
      setUserDetails(userInfoResponse.data);
    }
  }, [recipientList]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  const useNotifiedUsersWithFunc = useCallback(() => {
    if (!userDetails) return [];

    return recipientList
      .map((recipient) => {
        const user = userDetails.find((user) => user.uuid === recipient.userUuid)!;

        return {
          user: user,
          onEdit: () => onEdit(user),
          notifyEmail: recipient.notifyEmail,
          isCurrentUser: currentUser!.uuid === user?.uuid,
          notifySms: recipient.notifySms,
          uuid: recipient.uuid,
          userAllowEmail: user?.sendEmail,
          userAllowSms: user?.sendMessage,
        };
      })
      .filter((recipient) => !!recipient.user);
  }, [recipientList, currentUser, userDetails]);

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid container item xs={12} justifyContent="space-between" alignItems="center">
          <Box pt={2} pr={2}>
            <Typography variant={isSmallScreen ? 'subtitle2' : 'h5'}>Notified Users</Typography>
          </Box>
          <AddRecipient build={build} />
        </Grid>
        <Alert severity="info" style={{marginTop: '12px', width: '100%'}}>
          The following users will receive an alert via phone or email if any of the below defect notification
          conditions are met.
        </Alert>
      </Grid>
      <GenericTable
        filteringEnabled={false}
        resourceType="recipient"
        useData={useNotifiedUsersWithFunc}
        permanentFilters={buildUuidFilter}
      />
      <EditUserNotificationModal user={editUser} modalOpen={modalOpen} onClose={onClose} onEditSuccess={getUserInfo} />
    </Grid>
  );
};

export default NotificationRecipients;

const AddRecipient = ({build}: {build: IBuild}) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SelectedUserType>();
  const recipientList = useSelector((s: RootState) => s.recipientStore.list);

  const onAddUser = async () => {
    setRequestInProgress(true);
    await recipientsCreatePOST({
      buildUuid: build.uuid,
      userUuid: selectedUser!.uuid,
      notifyEmail: !!selectedUser!.email && selectedUser!.sendEmail,
      // On premise users wont receive text notifications
      notifySms: !envConfig.isOnPremise && !selectedUser!.email && !!selectedUser!.mobile && selectedUser!.sendMessage,
    });
    setRequestInProgress(false);
    setSelectedUser(undefined);
  };

  return (
    <Box display="flex" flex="1" pt={2} justifyContent="flex-end">
      <Box pr={2} flex={1} maxWidth="300px">
        <SearchAndSelectUser
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          filterOutUuids={recipientList.map((recipient) => recipient.userUuid!)}
        />
      </Box>

      <Box minWidth="115px">
        <Button
          variant="contained"
          color="primary"
          onClick={onAddUser}
          startIcon={requestInProgress ? <CircularProgress size={20} /> : <Add />}
          disabled={!selectedUser || requestInProgress}
        >
          Add User
        </Button>
      </Box>
    </Box>
  );
};
