import React, {useState} from 'react';
import {Box, IconButton, Tooltip, CircularProgress} from '@material-ui/core';
import {Delete, CloudDownload} from '@material-ui/icons';

import {
  IBuildIntensityReportGETResponse,
  BuildIntensityReportStatus,
} from '@common/api/models/builds/data/IBuildIntensityReport';

import {ChipStatus} from '../../../../components/atoms/Status/Status';
import {downloadUrl} from '../../../../utils/webtools';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import {buildIntensityReportDownloadUrlGET} from '../../../../api/ajax/buildIntensityReport';

interface BuildIntensityReportRow extends IBuildIntensityReportGETResponse {
  onDelete: () => void;
}

const buildIntensityReportTableSchema = [
  {
    title: 'Build Name',
    field: 'buildName',
    width: 'auto',
    render: (row: BuildIntensityReportRow) => {
      return <EllipsisTextColumnTooltip>{row.buildName}</EllipsisTextColumnTooltip>;
    },
  },
  {
    title: 'Status',
    field: 'status',
    width: 'auto',
    render: (row: BuildIntensityReportRow) => {
      switch (row.status) {
        case BuildIntensityReportStatus.Submitted:
          return <ChipStatus message="Submitted" variant="info" />;
        case BuildIntensityReportStatus.Generating:
          return <ChipStatus message="Generating" variant="info" />;
        case BuildIntensityReportStatus.Success:
          return <ChipStatus message="Complete" variant="success" />;
        case BuildIntensityReportStatus.Failure:
          return <ChipStatus message="Failed" variant="error" />;
        default:
          return <></>;
      }
    },
  },
  {
    title: 'Created at',
    field: 'createdAt',
    width: '132px',
    render: (row: BuildIntensityReportRow) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Build end',
    field: 'buildEndDate',
    width: '132px',
    render: (row: BuildIntensityReportRow) => <DateWithTimeTooltip timestamp={row.buildEndDate} />,
  },
  {
    title: 'Actions',
    sorting: false,
    width: '112px',
    render: (row: BuildIntensityReportRow) => <ReportActions row={row} />,
  },
];

export default buildIntensityReportTableSchema;

const ReportActions = ({row}: {row: BuildIntensityReportRow}) => {
  const [requesting, setRequesting] = useState(false);

  const handleCsvDownload = async () => {
    if (requesting) return;

    setRequesting(true);
    const url = await buildIntensityReportDownloadUrlGET(row.uuid);

    if (!url.success) {
      setRequesting(false);
      return;
    }

    downloadUrl(url.data.url, `${row.buildName}-build-intensity-report.zip`);
    setRequesting(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      {row.status === BuildIntensityReportStatus.Success && (
        <Tooltip title="Download report">
          <IconButton
            aria-label="download"
            onClick={(e) => {
              e.stopPropagation();
              handleCsvDownload();
            }}
          >
            {requesting ? <CircularProgress size={20} /> : <CloudDownload />}
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Remove">
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            row.onDelete();
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
