import {
  AgeComposition,
  BatchEventType,
  BatchEventData,
  IBatch,
  MaterialComposition,
  BATCH_NAME_LIMIT,
} from '@common/api/models/materials/batches/IBatch';
import * as uuid from 'uuid';
import {assertUnreachable, EPS} from '@common/utils/utils';

export function mergeAgeComposition(
  a: AgeComposition[],
  weightA: number,
  b: AgeComposition[],
  weightB: number
): AgeComposition[] {
  const byUsage = new Map<number, number>();
  const totalWeight = weightA + weightB;
  const result: AgeComposition[] = [];
  for (const {usedCount, weight} of a) {
    byUsage.set(usedCount, (byUsage.get(usedCount) || 0) + weight * weightA);
  }
  for (const {usedCount, weight} of b) {
    byUsage.set(usedCount, (byUsage.get(usedCount) || 0) + weight * weightB);
  }

  for (const usage of Array.from(byUsage.keys()).sort()) {
    result.push({
      usedCount: Number(usage),
      weight: byUsage.get(Number(usage))! / totalWeight,
    });
  }
  return result;
}

export function mergeMaterialComposition(
  a: MaterialComposition[],
  weightA: number,
  b: MaterialComposition[],
  weightB: number
): MaterialComposition[] {
  const byMaterial = new Map<string, number>();
  const totalWeight = weightA + weightB;
  const result: MaterialComposition[] = [];
  for (const {materialUuid, weight} of a) {
    byMaterial.set(materialUuid, (byMaterial.get(materialUuid) || 0) + weight * weightA);
  }
  for (const {materialUuid, weight} of b) {
    byMaterial.set(materialUuid, (byMaterial.get(materialUuid) || 0) + weight * weightB);
  }

  // Sort by decreasing weight - so most significant material first.
  for (const [material, weight] of Array.from(byMaterial.entries()).sort((a, b) => b[1] - a[1])) {
    result.push({
      materialUuid: material,
      weight: weight / totalWeight,
    });
  }
  return result;
}

export function mergeBatches(batchA: IBatch, batchB: IBatch): IBatch {
  let batchName = batchA.name + ' + ' + batchB.name;
  if (batchName.length > BATCH_NAME_LIMIT) {
    const batchAName = batchA.name.slice(0, BATCH_NAME_LIMIT / 2 - 2).slice(0, -3) + '...';
    const batchBName = batchB.name.slice(0, BATCH_NAME_LIMIT / 2 - 2).slice(0, -3) + '...';
    batchName = batchAName + ' + ' + batchBName;
  }

  const newBatch: IBatch = {
    ageComposition: mergeAgeComposition(batchA.ageComposition, batchA.quantity, batchB.ageComposition, batchB.quantity),
    materialComposition: mergeMaterialComposition(
      batchA.materialComposition,
      batchA.quantity,
      batchB.materialComposition,
      batchB.quantity
    ),
    current: true,

    description: 'Merged ' + batchA.name + ' and ' + batchB.name,
    name: batchName,
    organizationUuid: batchA.organizationUuid,

    quantity: batchA.quantity + batchB.quantity,
    registered: new Date(),
    retired: false,

    creationEventData: {type: BatchEventType.MERGE},
    creationEventType: BatchEventType.MERGE,
    parentAUuid: batchA.uuid,
    parentBUuid: batchB.uuid,

    successionEventType: null,
    successorAUuid: null,
    successorBUuid: null,
    uuid: uuid.v4(),
  };

  batchA.successionEventType = BatchEventType.MERGE;
  batchA.successorAUuid = newBatch.uuid;
  batchA.current = false;

  batchB.successionEventType = BatchEventType.MERGE;
  batchB.successorBUuid = newBatch.uuid;
  batchB.current = false;

  return newBatch;
}

// Quantity B is inferred.
export function splitBatches(batch: IBatch, quantityA: number): [IBatch, IBatch] {
  // Trim batch name to fit within name constraints
  let batchName = batch.name.slice(0, BATCH_NAME_LIMIT - 10);
  if (batchName.length === BATCH_NAME_LIMIT - 10) {
    batchName = batchName.slice(0, -3) + '...';
  }

  const batchA: IBatch = {
    ageComposition: batch.ageComposition,
    materialComposition: batch.materialComposition,
    current: true,
    description: batch.description,
    name: batchName + ' (split A)',
    organizationUuid: batch.organizationUuid,

    quantity: quantityA,
    registered: new Date(),
    retired: false,

    creationEventData: {type: BatchEventType.SPLIT},
    creationEventType: BatchEventType.SPLIT,
    parentAUuid: batch.uuid,
    parentBUuid: undefined,

    successionEventType: undefined,
    successorAUuid: undefined,
    successorBUuid: undefined,
    uuid: uuid.v4(),
  };

  const batchB: IBatch = {
    ageComposition: batch.ageComposition,
    materialComposition: batch.materialComposition,
    current: true,
    description: batch.description,
    name: batchName + ' (split B)',
    organizationUuid: batch.organizationUuid,

    quantity: batch.quantity - quantityA,
    registered: new Date(),
    retired: false,

    creationEventData: {type: BatchEventType.SPLIT},
    creationEventType: BatchEventType.SPLIT,
    parentAUuid: undefined,
    parentBUuid: batch.uuid,

    successionEventType: undefined,
    successorAUuid: undefined,
    successorBUuid: undefined,
    uuid: uuid.v4(),
  };

  batch.current = false;
  batch.successionEventType = BatchEventType.SPLIT;
  batch.successorAUuid = batchA.uuid;
  batch.successorBUuid = batchB.uuid;

  return [batchA, batchB];
}

export function applyBatchEvent(batch: IBatch, event: BatchEventData): IBatch {
  const newBatch: IBatch = Object.assign({}, batch);
  newBatch.uuid = uuid.v4();
  newBatch.creationEventType = event.type;
  newBatch.creationEventData = event;
  newBatch.parentAUuid = batch.uuid;
  newBatch.parentBUuid = null;
  newBatch.successionEventType = null;
  newBatch.successorAUuid = null;
  newBatch.successorBUuid = null;

  batch.successorAUuid = newBatch.uuid;
  batch.successionEventType = event.type;
  batch.current = false;
  newBatch.registered = new Date();

  switch (event.type) {
    case BatchEventType.RETIRE:
      break;
    case BatchEventType.ADD:
      const totalQuantity = batch.quantity + event.quantity;

      if (totalQuantity > 0) {
        const matMap = new Map<string, number>();
        for (const {materialUuid, weight} of batch.materialComposition) {
          matMap.set(materialUuid, (matMap.get(materialUuid) || 0) + weight);
        }
        matMap.set(event.materialUuid, (matMap.get(event.materialUuid) || 0) + event.quantity / batch.quantity);
        newBatch.materialComposition = Array.from(matMap.entries())
          .map(([key, value]) => ({
            materialUuid: key,
            weight: (value * batch.quantity) / totalQuantity,
          }))
          .sort((a, b) => b.weight - a.weight);

        const ageMap = new Map<number, number>();
        for (const {weight, usedCount} of batch.ageComposition) {
          ageMap.set(usedCount, (weight * batch.quantity) / totalQuantity);
        }
        ageMap.set(0, (ageMap.get(0) || 0) + event.quantity / totalQuantity);

        newBatch.ageComposition = Array.from(ageMap.entries())
          .map(([usedCount, weight]) => ({usedCount, weight}))
          .sort((a, b) => a.usedCount - b.usedCount);
      }

      newBatch.quantity = totalQuantity;
      break;
    case BatchEventType.BUILD:
      newBatch.quantity -= event.quantityConsumed;
      newBatch.ageComposition = newBatch.ageComposition.map(({usedCount, weight}) => ({
        usedCount: usedCount + 1,
        weight,
      }));
      break;
    case BatchEventType.SIEVE:
      newBatch.quantity -= event.lostQuantity;
      break;
    case BatchEventType.TEST:
      newBatch.quantity -= event.quantityConsumed;
      break;
    case BatchEventType.CUSTOM:
      newBatch.quantity -= event.quantityConsumed;
      break;
    case BatchEventType.MEASUREMENT:
      newBatch.quantity = event.quantity;
      break;
    case BatchEventType.MERGE:
    case BatchEventType.SPLIT:
    case BatchEventType.INITIAL:
      throw new Error("applyBatchEvent doesn't support this event");
    default:
      assertUnreachable(event);
  }

  if (Math.abs(newBatch.quantity) <= EPS) {
    newBatch.materialComposition = [];
    newBatch.ageComposition = [];
  }

  return newBatch;
}

// Assumes that weights in the array are normalized to 1
export function averageAge(ages: AgeComposition[]): number {
  let average = 0;
  for (const {weight, usedCount} of ages) {
    average += usedCount * weight;
  }
  return average;
}

// Assumes that weights in the array are normalized to 1
export function maxAge(ages: AgeComposition[]): {
  max: number;
  percentageAt: number;
} {
  let max = 0;
  for (const {weight, usedCount} of ages) {
    if (weight > 0) {
      max = Math.max(max, usedCount);
    }
  }
  let percentageAt = 0;
  for (const {weight, usedCount} of ages) {
    if (usedCount === max) {
      percentageAt += weight * 100;
    }
  }
  return {max, percentageAt};
}

export function ageCompositionToMap(ages: AgeComposition[]): Map<number, number> {
  const res = new Map<number, number>();
  for (const {weight, usedCount} of ages) {
    res.set(usedCount, (res.get(usedCount) || 0) + weight);
  }
  return res;
}

export function materialCompositionToMap(materials: MaterialComposition[]): Map<string, number> {
  const res = new Map<string, number>();
  for (const {weight, materialUuid} of materials) {
    res.set(materialUuid, (res.get(materialUuid) || 0) + weight);
  }
  return res;
}
