import React from 'react';

import styled from 'styled-components';
import {FormControl, TextField, MenuItem} from '@material-ui/core';

import {dangerColor, blackColor} from '../../assets/jss/material-dashboard-react';

interface CustomSelectProps {
  id?: string;
  label: string | JSX.Element;
  data: (string | number)[];
  computeData?: (item: any) => string;
  error?: boolean;
  helperText?: string;
  inputProps?: object;
}

interface HelperTextProps {
  readonly isError: boolean;
}

const HelperText = styled.p<HelperTextProps>`
  color: ${(props) => (props.isError ? dangerColor[0] : blackColor)};
`;

function CustomSelect(props: CustomSelectProps) {
  const {id, label, data, computeData, error, helperText, inputProps} = props;

  return (
    <FormControl fullWidth>
      <TextField id={id} select label={label} disabled={data.length < 1} defaultValue="" {...inputProps}>
        {data.map((item) => (
          <MenuItem key={item} value={item}>
            {computeData ? computeData(item) : item}
          </MenuItem>
        ))}
      </TextField>
      {helperText ? <HelperText isError={!!error}>{helperText}</HelperText> : null}
    </FormControl>
  );
}

export default CustomSelect;
