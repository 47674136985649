import React from 'react';
import styled from 'styled-components';
import {Button, ButtonProps} from '@material-ui/core';

const VerticalButton = ({children, ...buttonProps}: ButtonProps) => {
  return (
    <Button {...buttonProps} style={{maxWidth: '100%', ...(buttonProps.style ?? {})}}>
      <InnerButton>{children}</InnerButton>
    </Button>
  );
};

export default VerticalButton;

const InnerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  max-width: 100%;
`;
