export enum LayerImageResolutionSize {
  BIT_12 = 'bit_12',
  FULL_RES = 'full_res',
  HALF_RES = 'half_res',
  THUMBNAIL = 'thumbnail',
}

export interface IImageSize {
  bucket: string;
  key: string;
  width: number;
  height: number;

  isDisplayable?: boolean; // TODO: Deprecated, remove after release v1.18.0
  isThumbnail?: boolean; // TODO: Deprecated, remove after release v1.18.0

  resolutionSize: LayerImageResolutionSize;
  isPartialLayer: boolean;
}

export interface ILayerImage extends IImageSize {
  uuid: string;
  organizationUuid: string;
  buildUuid: string;

  // DO NOT reference ILayer because ILayer is archive-able.
  layerId: number;
  type: string;

  timestamp: Date;
}
