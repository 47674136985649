import React from 'react';
import {Box, CircularProgress, Grid, Typography} from '@material-ui/core';
import {Block, Widgets} from '@material-ui/icons';
import {View3DState} from './types/pointCloudTypes';

interface FullscreenMessageProps {
  icon: JSX.Element;
  title: string;
  body: string;
}

function FullscreenMessage(props: FullscreenMessageProps) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      style={{backgroundColor: '#222222'}}
      textAlign="center"
    >
      <Grid item>{props.icon}</Grid>
      <Grid item>
        <Typography style={{fontSize: 30}}>{props.title}</Typography>
      </Grid>
      <Grid item>
        <Typography>{props.body}</Typography>
      </Grid>
    </Box>
  );
}

interface ViewportMessageProps {
  viewportState: View3DState;
  noSelectionTitle: string;
  noSelectionMessage: string;
}

export const showOverlayMessage = (viewportState: View3DState) => {
  return ['loading', 'noselection', 'unavailable', 'failed'].includes(viewportState);
};

export function ViewportMessage(props: ViewportMessageProps) {
  switch (props.viewportState) {
    case 'loading':
      return <CircularProgress color="secondary" size={30} style={{color: 'rgba(25, 118, 210, 0.8)'}} />;
    case 'noselection':
      return <FullscreenMessage icon={<Widgets />} title={props.noSelectionTitle} body={props.noSelectionMessage} />;
    case 'unavailable':
      return <FullscreenMessage icon={<Block />} title="3D model unavailable" body="Please try again later." />;
    case 'failed':
      return (
        <FullscreenMessage
          icon={<Block />}
          title="Failed to load"
          body="An error occurred while loading the 3D model"
        />
      );
    default:
      return <></>;
  }
}
