import React, {useState, useEffect} from 'react';
import {TextField, Grid} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import inflection from 'inflection';
import ComparisonsSelector from './ComparisonSelector';
import {FilterComponentProps} from '../FilterModal';
import {flatten, omit} from 'lodash';
import styled from 'styled-components';
import {newDebouncer} from '../../../../api/ajax';
import {parseCurrentFilters} from './utils';

const enumMultipleComparisons = {
  in: 'Any of',
  notIn: 'None of',
};

type EnumMultipleComparison = keyof typeof enumMultipleComparisons;

const debounceSubmit = newDebouncer();

const EnumMultipleFilter = ({
  field,
  label,
  enumValues,
  control,
  onSubmit,
  currentFilters,
  enumLabels,
  permanentComparison,
  excludeComparisons = [],
  filterListLocation,
  tagFn,
}: FilterComponentProps) => {
  const initialFilter = parseCurrentFilters(currentFilters, field);
  const [internalValue, setInternalValue] = useState<Array<string>>(
    initialFilter[1] ? (flatten([initialFilter[1]]) as Array<string>) : []
  );
  const [comparison, setComparison] = useState<EnumMultipleComparison>(
    ((permanentComparison ?? initialFilter[0]) as EnumMultipleComparison) || 'in'
  );

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSubmit(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  useEffect(() => {
    control.register(field);
  }, [control, field]);

  useEffect(() => {
    const filterValue = parseCurrentFilters(currentFilters, field);
    setInternalValue(filterValue[1] ? filterValue[1] : initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const setValue = (newValue: any) => {
    setInternalValue(newValue);
    if (newValue.length) {
      control.setValue(field, {[comparison]: newValue});
    } else {
      control.setValue(field, undefined);
    }
    submitField();
  };

  const onComparisonChanged = (newComparison: EnumMultipleComparison) => {
    setComparison(newComparison);
    control.setValue(field, {[newComparison]: internalValue});
  };

  return (
    <Grid container spacing={4}>
      <StyledGrid
        item
        xs={!permanentComparison ? 6 : 12}
        sm={!permanentComparison ? 8 : 12}
        hasPopupIcon={internalValue.length === 0}
      >
        <Autocomplete
          multiple
          disableClearable
          filterSelectedOptions
          size="small"
          id={`filter-enum-${field}`}
          options={enumValues || []}
          getOptionLabel={(option) => `${enumLabels ? enumLabels[option] : option}`}
          value={internalValue}
          onChange={(_event: any, options: any) => setValue(options)}
          renderTags={tagFn}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={label || inflection.capitalize(field)}
              label={label || inflection.capitalize(field)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{internalValue.length ? null : params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </StyledGrid>
      {!permanentComparison && (
        <Grid item xs={6} sm={4}>
          <ComparisonsSelector
            comparisons={
              omit(enumMultipleComparisons, excludeComparisons) as {
                [key: string]: string;
              }
            }
            currentComparison={comparison}
            setCurrentComparison={onComparisonChanged}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EnumMultipleFilter;

const StyledGrid = styled(Grid)<{hasPopupIcon: boolean}>`
  .MuiAutocomplete-hasPopupIcon {
    .MuiInputBase-root {
      ${({hasPopupIcon}) => !hasPopupIcon && 'padding-right: 12px;'}
    }
  }
`;
