import React from 'react';
import {Typography, Box, LinearProgress, withStyles, IconButton, Tooltip, CircularProgress} from '@material-ui/core';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {Settings, Visibility} from '@material-ui/icons';
import {warningColor} from '../../../../assets/jss/material-dashboard-react';
import {useHistory} from 'react-router-dom';

const StyledLinearProgress = withStyles({
  colorPrimary: {
    background: `${warningColor[0]}66`,
  },
  barColorPrimary: {
    backgroundColor: warningColor[0],
  },
})(LinearProgress);

function BuildProgressButtons({build}: {build: IBuild}) {
  const history = useHistory();

  const {numLayers, state, uuid, lastPrintedLayerId} = build;

  const navigateToSettings = () => history.push(`/builds/uuid/${uuid}/settings`);
  const navigateToViewport = () => history.push(`/builds/uuid/${uuid}/live/viewports`);

  const ProgressBar = build.state === BuildState.PAUSED ? StyledLinearProgress : LinearProgress;

  return (
    <>
      <Box width="100%" display="flex" justifyContent="space-between" marginTop="6px">
        <Box display="flex" alignItems="center">
          <Typography
            color="primary"
            style={state === BuildState.PAUSED ? {color: warningColor[0], marginRight: '6px'} : {marginRight: '6px'}}
          >
            <b>Layer {lastPrintedLayerId && numLayers ? `${lastPrintedLayerId} / ${numLayers}` : 'N/A'}</b>
          </Typography>
          <BuildNavButton name="Settings" Icon={Settings} onClick={navigateToSettings} />
          <BuildNavButton name="Viewport" Icon={Visibility} onClick={navigateToViewport} />
        </Box>
        <Typography color="primary" style={state === BuildState.PAUSED ? {color: warningColor[0]} : {}}>
          <b>{lastPrintedLayerId && numLayers ? `${((lastPrintedLayerId / numLayers) * 100).toFixed(0)}%` : 0}</b>
        </Typography>
      </Box>
      <ProgressBar
        value={lastPrintedLayerId && numLayers ? (lastPrintedLayerId / numLayers) * 100 : 0}
        variant="determinate"
        style={{borderRadius: '4px'}}
      />
    </>
  );
}

export default BuildProgressButtons;

function BuildNavButton({
  name,
  onClick,
  Icon,
  requesting,
}: {
  name: string;
  onClick: () => void;
  Icon: React.ComponentType<any>;
  requesting?: boolean;
}) {
  return (
    <Tooltip title={<Typography>{name}</Typography>}>
      <IconButton onClick={onClick} size="small" style={{marginBottom: '2px'}}>
        {requesting ? <CircularProgress size={12} /> : <Icon style={{width: '20px', height: '20px'}} />}
      </IconButton>
    </Tooltip>
  );
}
