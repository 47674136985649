import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {inflect} from 'inflection';
import {Card, Typography, Box, CardHeader, CardContent, Collapse} from '@material-ui/core';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {DefectType} from '@common/api/models/builds/data/defects/IDefect';
import {
  useBatchStoreActions,
  useBuildStoreActions,
  useDefectSummaryStoreActions,
  useDeviceStoreActions,
  useMachineStoreActions,
} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import BuildProgressButtons from './ActiveBuildsWidet/BuildProgressButtons';
import BuildDefectsChart from './ActiveBuildsWidet/BuildDefectChart';
import {BatchField, FieldValue, MachineField} from './ActiveBuildsWidet/Fields';
import {CenteredDiv} from './SwitchableChart';
import waitingForSomethingPNG from '../../../assets/img/waiting-for-something.png';

function ActiveBuildsWidget() {
  const buildStoreActions = useBuildStoreActions();
  const defectSummaryStoreActions = useDefectSummaryStoreActions();
  const batchStoreActions = useBatchStoreActions();
  const machineStoreActions = useMachineStoreActions();
  const deviceStoreActions = useDeviceStoreActions();

  const activeBuilds = useSelector((state: RootState) =>
    Object.values(state.buildStore.byId).filter((build) => [BuildState.ACTIVE, BuildState.PAUSED].includes(build.state))
  );

  const activeBuildUuids = activeBuilds.map((build) => build.uuid);
  const batchUuids = activeBuilds.filter((build) => !!build.batchUuid).map((build) => build.batchUuid!);

  const machineUuids = activeBuilds.filter((build) => !!build.machineUuid).map((build) => build.machineUuid!);
  const deviceSerials = activeBuilds.filter((build) => !!build.deviceSerial).map((build) => build.deviceSerial!);

  const activeBuildUuidsTimestamps = activeBuildUuids.join('|');
  const machineUuidsStringified = machineUuids.join('|');
  const batchUuidsStringified = batchUuids.join('|');
  const deviceSerialStringified = deviceSerials.join('|');

  useEffect(() => {
    buildStoreActions.ensureConsistent({
      state: [BuildState.ACTIVE, BuildState.PAUSED],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeBuilds.length) {
      defectSummaryStoreActions.ensureConsistent({
        defectType: DefectType.Lsdd,
        buildUuid: activeBuilds.map((build) => build.uuid),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBuildUuidsTimestamps]);

  useEffect(() => {
    if (activeBuilds.length) {
      batchStoreActions.ensureConsistent({
        uuid: batchUuids,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchUuidsStringified]);

  useEffect(() => {
    if (activeBuilds.length) {
      machineStoreActions.ensureConsistent({
        uuid: machineUuids,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineUuidsStringified]);

  useEffect(() => {
    if (activeBuilds.length) {
      deviceStoreActions.ensureConsistent({
        serial: deviceSerials,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSerialStringified]);

  return (
    <Card style={{height: '100%'}}>
      <CardHeader title="Active Builds" style={{paddingBottom: '0px'}} />
      <CardContent>
        <Box display="flex" flexDirection="column">
          {!!activeBuilds.length ? (
            activeBuilds.map((build, index) => (
              <ActiveBuild
                key={`active-build-card-${build.uuid}`}
                build={build}
                isOnlyBuild={activeBuilds.length === 1}
                defaultExpanded={index === 0}
              />
            ))
          ) : (
            <CenteredDiv>
              <Typography variant="h4">No active builds...</Typography>
              <img
                src={waitingForSomethingPNG}
                style={{maxHeight: '25vh', marginTop: '1rem'}}
                alt="bird chirping musically in a park"
              />
            </CenteredDiv>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default ActiveBuildsWidget;

function ActiveBuild({
  build,
  defaultExpanded = false,
  isOnlyBuild,
}: {
  build: IBuild;
  defaultExpanded?: boolean;
  isOnlyBuild: boolean;
}) {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const hoursAgoBuildStarted = moment().diff(moment(build.start), 'hours');

  return (
    <Box display="flex" flexDirection="column" mb={6}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        onClick={isOnlyBuild ? undefined : () => setExpanded(!expanded)}
        style={isOnlyBuild ? {} : {cursor: 'pointer'}}
      >
        <Typography variant="h6">
          {build.name}
          {build.state === BuildState.PAUSED ? (
            <Typography style={{display: 'inline'}}>
              {' '}
              <i>(Paused)</i>
            </Typography>
          ) : (
            ''
          )}
        </Typography>
        {!isOnlyBuild && <>{expanded ? <ExpandLess /> : <ExpandMore />}</>}
      </Box>
      <BuildProgressButtons build={build} />
      <Collapse in={expanded}>
        <>
          <MachineField machineUuid={build.machineUuid!} />
          <BatchField batchUuid={build.batchUuid!} />
          <FieldValue
            field="Started"
            value={`${hoursAgoBuildStarted === 0 ? 'Less than 1' : hoursAgoBuildStarted} 
      ${inflect('hour', hoursAgoBuildStarted || 1)} ago`}
          />

          <FieldValue field="Defect Coverage" />
          <BuildDefectsChart buildUuid={build.uuid} />
        </>
      </Collapse>
    </Box>
  );
}
