import {IPv4} from 'ipaddr.js';

export const isPrivateIp = (host: string) => {
  try {
    const ip = IPv4.parse(new URL(host).hostname);
    return ip.range() === 'private';
  } catch (e) {
    try {
      const ip = IPv4.parse(host);
      return ip.range() === 'private';
    } catch {
      return false;
    }
  }
};
