import React, {useEffect, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {Trash} from 'react-feather';
import {Alert} from '@material-ui/lab';
import LightBox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import {BuildAttachmentType, IBuildAttachment} from '@common/api/models/attachments/IAttachmentBase';
import {Box, Card, CircularProgress, IconButton, TablePagination} from '@material-ui/core';
import {Close, Delete, Star, StarBorder} from '@material-ui/icons';
import {OrderDirection} from '@common/utils/ordering/ordering';
import {IBuildAttachmentsGETResponse} from '@common/api/models/attachments/IBuildAttachment';
import SliceAttachmentMultipartUpload from '../../../pages/builds/shared/SliceAttachmentMultipartUpload';
import {useBuildAttachmentStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {ImageGrid, ImageGridItem} from '../ImageGrid';
import {sliceAttachmentDownloadUrlGET} from '../../../api/ajax/sliceAttachments';
import {downloadUrl} from '../../../utils/webtools';
import {buildAttachmentByUuidDELETE, buildMasterPhotoPATCH} from '../../../api/ajax/buildAttachments';
import {GenericDialog} from '../DialogButton';
import {FetchingState} from '../../../store/model/liveUpdateStore';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import LoadingPage from '../../organisms/LoadingPage';

interface BuildPhotosModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((open: boolean) => void);
  buildUuid: string;
  orgUuid: string;
}

const sortBuildPhotos = (a: IBuildAttachmentsGETResponse, b: IBuildAttachmentsGETResponse): number => {
  if (a.type === BuildAttachmentType.BuildPhoto) return 1;
  if (b.type === BuildAttachmentType.BuildPhoto) return -1;
  return 0;
};

export function BuildPhotosModal(props: BuildPhotosModalProps) {
  return (
    <GenericDialog
      maxWidth="lg"
      fullWidth
      title="Build Photos"
      isOpen={props.isOpen}
      closeDialog={() => props.setOpen(false)}
      closeText="Close"
      content={
        <BuildGallery buildUuid={props.buildUuid} orgUuid={props.orgUuid} onLightBoxClose={() => props.setOpen(true)} />
      }
    />
  );
}

const BuildGallery = ({
  buildUuid,
  orgUuid,
  onLightBoxClose,
}: {
  buildUuid: string;
  orgUuid: string;
  onLightBoxClose?: () => void;
}) => {
  const buildAttachmentActions = useBuildAttachmentStoreActions();
  const [currentPage, setCurrentPage] = useState(0);
  const [photosPerPage, setPhotosPerPage] = useState(5);
  const [starring, setStarring] = useState<string | undefined>();
  const [downloading, setDownloading] = useState<string | undefined>();
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [lightBoxToggle, setLightBoxToggle] = useState(false);
  const [numFilesUploading, setNumFilesUploading] = useState(0);
  const [deletingAttachment, setDeletingAttachmentModal] = useState<IBuildAttachment>();
  const [photos, setPhotos] = useState<HTMLImageElement[]>([]);
  const [loading, setLoading] = useState(true);
  const buildPhotosLoading = useSelector((state: RootState) => state.buildAttachmentStore.listFetched);
  const imageAttachments = useSelector((state: RootState) => state.buildAttachmentStore.list);
  const imageCount = useSelector((state: RootState) => state.buildAttachmentStore.listTotal);
  const [forcedRefresh, forceRefresh] = useReducer((x) => x + 1, 0);

  const masterPhoto = imageAttachments.find(
    (attachment) => attachment.type === BuildAttachmentType.BuildPhotoMain
  )?.url;

  useEffect(() => {
    return () => {
      buildAttachmentActions.invalidateListHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedRefresh]);

  function fetchBuildAttachments() {
    buildAttachmentActions.fetchAndSubscribeToList(
      {
        buildUuid: buildUuid,
        take: photosPerPage,
        skip: photosPerPage * currentPage,
        type: [BuildAttachmentType.BuildPhoto, BuildAttachmentType.BuildPhotoMain],
        sortBy: {
          type: OrderDirection.DESC,
          createdAt: OrderDirection.ASC,
        },
      },
      {
        buildUuid: buildUuid,
        type: [BuildAttachmentType.BuildPhoto, BuildAttachmentType.BuildPhotoMain],
      }
    );
  }

  useEffect(() => {
    setLoading(true);
    fetchBuildAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildUuid, photosPerPage, currentPage]);

  useEffect(() => {
    // Fetch build attachment without setting loading state
    if (forcedRefresh > 0) {
      fetchBuildAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedRefresh]);

  useEffect(() => {
    if ([FetchingState.Error, FetchingState.Fetched].includes(buildPhotosLoading)) setLoading(false);
  }, [buildPhotosLoading]);

  useEffect(() => {
    setPhotos(
      imageAttachments.sort(!lightBoxToggle ? sortBuildPhotos : undefined).map((attachment) => {
        const img = new Image();
        img.alt = attachment.filename;
        img.src = attachment.signedUrl ?? '';
        return img;
      })
    );
  }, [imageAttachments, lightBoxToggle]);

  const downloadAttachment = async (photoUuid: string, fileName: string) => {
    if (photoUuid) {
      setDownloading(photoUuid);
      const url = await sliceAttachmentDownloadUrlGET(photoUuid);
      if (url.success) downloadUrl(url.data, fileName ?? 'photo');
      setDownloading(undefined);
    }
  };

  const starPhoto = async (idx: number, image: IBuildAttachmentsGETResponse) => {
    if (!isStarred(idx)) {
      setStarring(image.uuid);
      await buildMasterPhotoPATCH(image.uuid);
      setStarring(undefined);
    }
  };

  const isStarred = (idx: number) => imageAttachments[idx]?.url === masterPhoto;

  return (
    <>
      <PhotosLightBox
        lightBoxToggle={lightBoxToggle}
        setLightBoxToggle={setLightBoxToggle}
        photos={photos}
        currentImageIndex={currentImageIndex}
        setCurrentIndex={setCurrentIndex}
        onLightBoxClose={onLightBoxClose}
        setDeletingAttachmentModal={setDeletingAttachmentModal}
        imageAttachments={imageAttachments}
        isStarred={isStarred}
        starPhoto={starPhoto}
        starring={starring}
      />
      <Alert severity="info">
        Photos associated with the build can be uploaded here for reference. One Photo can be selected to be included in
        the Build Report by clicking the Star Icon.
      </Alert>
      <Box height="65vh" display="flex" flexDirection="column" justifyContent="space-between">
        {loading ? (
          <LoadingPage loadingText="Loading Photos..." />
        ) : imageAttachments.length === 0 ? (
          <Card style={{width: '100%', margin: '20px 0px', padding: '4px'}} elevation={2}>
            <SliceAttachmentMultipartUpload
              isBuildPhoto
              acceptImage
              buildUuid={buildUuid}
              orgUuid={orgUuid}
              numFilesUploading={numFilesUploading}
              setNumFilesUploading={setNumFilesUploading}
              postUploadCallback={forceRefresh}
            />
          </Card>
        ) : (
          <ImageGrid>
            <Card style={{height: '100%', width: '100%'}} elevation={0}>
              <SliceAttachmentMultipartUpload
                acceptImage
                isBuildPhoto
                buildUuid={buildUuid}
                orgUuid={orgUuid}
                numFilesUploading={numFilesUploading}
                setNumFilesUploading={setNumFilesUploading}
                postUploadCallback={forceRefresh}
              />
            </Card>
            {imageAttachments.map((image, idx) => (
              <ImageGridItem
                imageUrl={image.signedUrl!}
                alt={image.filename}
                onClick={() => {
                  setCurrentIndex(idx);
                  setLightBoxToggle(true);
                }}
                isStared={isStarred(idx)}
                isStarring={starring === image.uuid}
                onDelete={() => setDeletingAttachmentModal(image)}
                onDownload={() => downloadAttachment(image.uuid, image.filename)}
                downloading={downloading === image.uuid}
                onStar={() => starPhoto(idx, image)}
                key={image.url}
              />
            ))}
          </ImageGrid>
        )}
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <TablePagination
            count={imageCount || 0}
            rowsPerPageOptions={[1, 5, 10, 15, 25, 50, 100]}
            page={currentPage}
            component="div"
            onPageChange={(event, newPage) => setCurrentPage(newPage)}
            rowsPerPage={photosPerPage}
            labelRowsPerPage="Photos per Page"
            onRowsPerPageChange={(event) => {
              setPhotosPerPage(parseInt(event.target.value));
              setCurrentPage(0);
            }}
          />
        </Box>
        <DeleteAttachmentModal
          deletingAttachment={deletingAttachment}
          setDeletingAttachmentModal={setDeletingAttachmentModal}
          onDeleteCallback={() => {
            // Last photo on the page, set page to previous page
            if (!!imageCount && (imageCount - 1) % photosPerPage === 0) setCurrentPage(Math.max(0, currentPage - 1));
            forceRefresh();
          }}
        />
      </Box>
    </>
  );
};

interface IPhotosLightBoxProps {
  lightBoxToggle: boolean;
  setLightBoxToggle: React.Dispatch<React.SetStateAction<boolean>>;
  photos: HTMLImageElement[];
  currentImageIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  onLightBoxClose: (() => void) | undefined;
  setDeletingAttachmentModal: React.Dispatch<React.SetStateAction<IBuildAttachment | undefined>>;
  imageAttachments: IBuildAttachmentsGETResponse[];
  isStarred: (idx: number) => boolean;
  starPhoto: (idx: number, image: IBuildAttachmentsGETResponse) => void;
  starring: string | undefined;
}
function PhotosLightBox({
  lightBoxToggle,
  setLightBoxToggle,
  photos,
  currentImageIndex,
  setCurrentIndex,
  onLightBoxClose,
  setDeletingAttachmentModal,
  imageAttachments,
  isStarred,
  starPhoto,
  starring,
}: IPhotosLightBoxProps) {
  return (
    <LightBox
      open={lightBoxToggle}
      close={() => setLightBoxToggle(false)}
      slides={photos.map((img) => ({src: img.src ?? '', alt: img.alt}))}
      plugins={[Download, Counter, Fullscreen, Thumbnails, Zoom]}
      index={currentImageIndex}
      on={{
        view(props) {
          setCurrentIndex(props.index);
        },
        exiting() {
          if (onLightBoxClose) onLightBoxClose();
        },
      }}
      toolbar={{
        buttons: [
          <IconButton
            onClick={() => {
              setLightBoxToggle(false);
              setDeletingAttachmentModal(imageAttachments[currentImageIndex]);
            }}
          >
            <Trash style={{color: 'white'}} />
          </IconButton>,
          <IconButton onClick={() => starPhoto(currentImageIndex, imageAttachments[currentImageIndex])}>
            {starring === imageAttachments[currentImageIndex]?.uuid ? (
              <CircularProgress size={20} style={{margin: '2px'}} />
            ) : isStarred(currentImageIndex) ? (
              <Star style={{color: 'white'}} />
            ) : (
              <StarBorder style={{color: 'white'}} />
            )}
          </IconButton>,
          <IconButton onClick={() => setLightBoxToggle(false)}>
            <Close style={{color: 'white'}} />
          </IconButton>,
        ],
      }}
      thumbnails={{
        borderRadius: 5,
        position: 'bottom',
        border: 1,
        vignette: true,
      }}
      zoom={{
        scrollToZoom: true,
        zoomInMultiplier: 1.5,
        maxZoomPixelRatio: 5,
      }}
    />
  );
}

const DeleteAttachmentModal = ({
  deletingAttachment,
  setDeletingAttachmentModal,
  onDeleteCallback,
}: {
  deletingAttachment: IBuildAttachment | undefined;
  setDeletingAttachmentModal: React.Dispatch<React.SetStateAction<IBuildAttachment | undefined>>;
  onDeleteCallback?: () => void;
}) => {
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if (!deletingAttachment) return;

    setDeleting(true);
    const res = await buildAttachmentByUuidDELETE(deletingAttachment.uuid);
    setDeleting(false);

    if (res.success) {
      setDeletingAttachmentModal(undefined);
      if (onDeleteCallback) onDeleteCallback();
    }
  };

  return (
    <GenericDialog
      title="Delete Photo?"
      content={
        <>
          Are you sure you want to delete the photo{' '}
          <b>
            <i>{deletingAttachment?.filename}</i>
          </b>
          ?
        </>
      }
      isOpen={!!deletingAttachment}
      closeDialog={() => setDeletingAttachmentModal(undefined)}
      onSuccess={onDelete}
      requestInProgress={deleting}
      confirmButtonProps={{endIcon: <Delete />}}
    />
  );
};
