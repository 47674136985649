import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {Card, Collapse, Divider, Grid, IconButton, Typography} from '@material-ui/core';
import EllipsisTextWithTooltip from '../../atoms/Texts/EllipsisTextWithTooltip';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {useRecipientStoreActions} from '../../../store/actions';
import {displayEmail, displayMobile, getRecipientName} from '../../../api/helpers/recipientUtils';

import {IBuild} from '@common/api/models/builds/IBuild';
import {RootState} from '../../../store/reducers';
import {NotificationSettingsModal} from '../Modals/NotificationSettingsModal';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {inflect} from 'inflection';

interface NotificationRecipientCardProps {
  build: IBuild;
}

export function NotificationRecipientsCard(props: NotificationRecipientCardProps) {
  const [notifRecipientsExpanded, setNotifRecipientsExpanded] = useState(false);
  const userStore = useSelector((state: RootState) => state.userStore);

  /* Recipients */
  const recipientStoreActions = useRecipientStoreActions();
  const recipientStore = useSelector((state: RootState) => state.recipientStore);
  const recipients = Object.values(recipientStore.byId).filter((recipient) => recipient.buildUuid === props.build.uuid);

  useEffect(() => {
    recipientStoreActions.ensureConsistent({buildUuid: props.build.uuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  const isSmall = useSmallScreenSize();

  return (
    <Card style={{padding: isSmall ? '15px' : '30px'}}>
      <Grid container spacing={2} style={{margin: '0'}} justifyContent="space-between">
        <Grid item xs={isSmall ? 12 : 4} style={{display: 'flex'}} alignItems="center">
          <Typography variant="h6" gutterBottom display="inline">
            Notifications Recipients
          </Typography>
        </Grid>
        {isSmall ? (
          <>
            <Grid container spacing={2} style={{margin: '0'}} justifyContent="space-between">
              <Grid item xs={10} style={{display: 'flex'}} alignItems="center">
                {recipients.length} {inflect('Recipient', recipients.length)}
              </Grid>
              <Grid item xs={2} container direction="row-reverse">
                <IconButton
                  style={{padding: '7px'}}
                  onClick={() => setNotifRecipientsExpanded(!notifRecipientsExpanded)}
                  aria-expanded={notifRecipientsExpanded}
                  aria-label="show more"
                >
                  {notifRecipientsExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
              <NotificationSettingsModal build={props.build} expanded />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3} style={{display: 'flex'}} alignItems="center">
              {recipients.length} {inflect('Recipient', recipients.length)}
            </Grid>
            <Grid item xs={5} container direction="row-reverse">
              <IconButton
                style={{padding: '7px'}}
                onClick={() => setNotifRecipientsExpanded(!notifRecipientsExpanded)}
                aria-expanded={notifRecipientsExpanded}
                aria-label="show more"
              >
                {notifRecipientsExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <NotificationSettingsModal build={props.build} />
            </Grid>
          </>
        )}
      </Grid>

      <Collapse in={notifRecipientsExpanded} timeout="auto">
        <Divider style={{marginTop: '15px'}} />
        {recipients.map((recipient) => {
          const name = getRecipientName(recipient, userStore) ?? 'Loading...';
          const mobile = displayMobile(recipient, userStore);
          const email = displayEmail(recipient, userStore);

          return (
            <React.Fragment key={recipient.uuid}>
              <Grid container spacing={2} style={{margin: '0', padding: '10px 0'}}>
                <Grid item xs={mobile == null ? 5 : 4}>
                  {name}
                </Grid>

                {mobile != null ? (
                  <Grid item xs={3}>
                    <EllipsisTextWithTooltip tooltip={mobile || ''}>{mobile || ''}</EllipsisTextWithTooltip>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={mobile == null ? 9 : 5}>
                  <EllipsisTextWithTooltip tooltip={email || ''}>{email || ''}</EllipsisTextWithTooltip>
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })}
      </Collapse>
    </Card>
  );
}
