import React from 'react';

import {DeviceAvailability} from '@common/api/models/devices/IDevice';
import {assertUnreachable} from '@common/utils/utils';

import Status, {StatusVariant} from './Status';

export interface DeviceAvailabilityStatusProps {
  availability: DeviceAvailability;
}

export default function DeviceAvailabilityStatus(props: DeviceAvailabilityStatusProps) {
  let variant: StatusVariant = 'none';
  switch (props.availability) {
    case DeviceAvailability.Available:
      variant = 'success';
      break;
    case DeviceAvailability.Busy:
      variant = 'warning';
      break;
    case DeviceAvailability.Unavailable:
    case DeviceAvailability.AvailableOffline:
    case DeviceAvailability.BusyOffline:
    case DeviceAvailability.UnavailableOffline:
      variant = 'none';
      break;
    default:
      assertUnreachable(props.availability);
  }

  return <Status message={props.availability} variant={variant} />;
}
