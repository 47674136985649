import React, {useState} from 'react';
import {ISimilarityComparisonGETResponse, SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {Box, IconButton, Tooltip, CircularProgress, Typography} from '@material-ui/core';
import {Delete, CloudDownload, WarningRounded} from '@material-ui/icons';
import {ChipStatus, StatusVariant} from '../../../../components/atoms/Status/Status';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {similarityComparisonResultUrlGET} from '../../../../api/ajax/similarityReport';
import {downloadUrl} from '../../../../utils/webtools';
import SimilarityStatusChip from '../../../similarityReport/shared/SimilarityStatusChip';
import {warningColor} from '../../../../assets/jss/material-dashboard-react';
import {EllipsisTextColumnTooltip} from './sharedColumns';

interface SimilarityComparisonRow extends ISimilarityComparisonGETResponse {
  onDelete: () => void;
  reportUuid: string;
}

function allowHoverOnDisabledStyle(row: SimilarityComparisonRow): React.CSSProperties {
  return {
    pointerEvents: 'all',
    cursor: row.status === SimilarityStatus.Success ? 'inherit' : 'default',
  };
}

const similarityComparisonsTableSchema = [
  {
    title: 'Part Name',
    field: 'targetPartName',
    width: 'auto',
    render: (row: SimilarityComparisonRow) => {
      if (!!row.warningMessage)
        return (
          <Box display="flex" alignItems="center" flexWrap="nowrap" style={allowHoverOnDisabledStyle(row)}>
            <Box style={{overflow: 'hidden', marginRight: '6px'}}>
              <EllipsisTextWithTooltip>{row.targetPartName}</EllipsisTextWithTooltip>
            </Box>
            <Box style={{width: '24px', height: '24px'}}>
              <Tooltip title={<Typography>{row.warningMessage}</Typography>}>
                <WarningRounded style={{color: warningColor[1]}} />
              </Tooltip>
            </Box>
          </Box>
        );

      return (
        <Box style={allowHoverOnDisabledStyle(row)}>
          <EllipsisTextColumnTooltip>{row.targetPartName}</EllipsisTextColumnTooltip>
        </Box>
      );
    },
  },
  {
    title: 'Build Name',
    field: 'buildName',
    sorting: false,
    width: 'auto',
    render: (row: SimilarityComparisonRow) => (
      <Box style={allowHoverOnDisabledStyle(row)}>
        <EllipsisTextColumnTooltip>{row.buildName}</EllipsisTextColumnTooltip>
      </Box>
    ),
  },
  {
    title: 'Similarity',
    field: 'similarityScore',
    width: '112px',
    render: (row: SimilarityComparisonRow) => {
      let variant: StatusVariant | undefined = undefined;

      if (!row.similarityScore && row.similarityScore !== 0) return <></>;
      if (row.similarityScore < 0.33) variant = 'error';
      else if (row.similarityScore < 0.66) variant = 'warning';
      else if (row.similarityScore <= 1) variant = 'success';
      if (!variant) return <></>;

      return <ChipStatus message={`${(row.similarityScore * 100).toFixed(2)}%`} variant={variant} />;
    },
  },
  {
    title: 'Status',
    field: 'status',
    width: '124px',
    render: (row: SimilarityComparisonRow) => {
      return <SimilarityStatusChip similarityStatus={row.status} />;
    },
  },
  {
    title: 'Actions',
    sorting: false,
    width: '112px',
    render: (row: SimilarityComparisonRow) => <ComparisonActions row={row} />,
  },
];

export default similarityComparisonsTableSchema;

const ComparisonActions = ({row}: {row: SimilarityComparisonRow}) => {
  const [requesting, setRequesting] = useState(false);

  const handleCsvDownload = async () => {
    if (requesting) return;

    setRequesting(true);
    const url = await similarityComparisonResultUrlGET(row.reportUuid, row.uuid);

    if (!url.success) {
      setRequesting(false);
      return;
    }

    downloadUrl(url.data.url, `${row.reportUuid}-${row.targetPartName}-similarity-statistics.csv`);
    setRequesting(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      {row.statisticsUrl && (
        <Tooltip title="Download full results">
          <IconButton
            aria-label="download"
            onClick={(e) => {
              e.stopPropagation();
              handleCsvDownload();
            }}
            style={{pointerEvents: 'all'}}
          >
            {requesting ? <CircularProgress size={20} /> : <CloudDownload />}
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Remove">
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            row.onDelete();
          }}
          style={{pointerEvents: 'all'}}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
