import React from 'react';
import {Typography} from '@material-ui/core';
import AntSwitch from '../../../atoms/Switches/AntSwitch';
import {FadingBox} from '../FadingComponents';

function CrosshairsToggle({
  mouseOn,
  crosshairsEnabled,
  setCrosshairsEnabled,
  left,
  top,
}: {
  mouseOn: boolean;
  crosshairsEnabled: boolean;
  setCrosshairsEnabled: (crosshairsEnabled: boolean) => void;
  left: number;
  top: number;
}) {
  return (
    <FadingBox
      className={(mouseOn === false && 'fade') || undefined}
      key={`crosshair-toggle-${left}-${top}`}
      position="absolute"
      left={left}
      top={top}
      display="flex"
      alignItems="center"
    >
      <Typography noWrap style={{marginRight: '8px', color: 'white'}}>
        Crosshairs:
      </Typography>

      <AntSwitch size="small" checked={crosshairsEnabled} onClick={() => setCrosshairsEnabled(!crosshairsEnabled)} />
    </FadingBox>
  );
}

export default CrosshairsToggle;
