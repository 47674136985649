import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {isEqual} from 'lodash';
import {Controller, useForm} from 'react-hook-form';
import {IconButton, Grid, CircularProgress, Tooltip, TextField} from '@material-ui/core';
import {Edit, Save, Clear} from '@material-ui/icons';
import {SensorProfileState} from '@common/api/models/devices/ISensorProfile';
import {RootState} from '../../../../store/reducers/index';
import {useAsyncDispatch} from '../../../../ReduxRoot';
import sensorProfileActions from '../../../../store/actions/sensorProfileActions';
import ValidationStatusChip from './ValidationStatusChip';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';

function ProfileName() {
  const dispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.sensorProfileStore.byId[selectedUuid]);

  const [profileName, setProfileName] = useState<string>('');
  const [profileNameEditing, setProfileNameEditing] = useState(false);
  const [requestingNameUpdate, setRequestingNameUpdate] = useState(false);

  useEffect(() => {
    if (profile?.name) {
      setProfileName(profile.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.name]);

  async function saveProfileName(name: string) {
    setRequestingNameUpdate(true);
    const success = await dispatch(sensorProfileActions.saveProfileName(selectedUuid, name!));

    if (success) {
      setProfileName(name);
      setProfileNameEditing(false);
    }

    setRequestingNameUpdate(false);
  }

  return (
    <Grid container style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
      {profileNameEditing ? (
        <EditMode
          name={profileName}
          onSave={({name}) => saveProfileName(name)}
          onCancel={() => setProfileNameEditing(false)}
          requestingNameUpdate={requestingNameUpdate}
        />
      ) : (
        <ViewMode
          name={profileName}
          validationState={profile.state}
          uuid={selectedUuid}
          setProfileNameEditing={() => setProfileNameEditing(true)}
        />
      )}
    </Grid>
  );
}

export default ProfileName;

interface ViewModeProps {
  name: string;
  uuid: string;
  validationState: SensorProfileState;
  setProfileNameEditing: () => void;
}

const ViewMode = ({name, uuid, validationState, setProfileNameEditing}: ViewModeProps) => {
  const hasUnsavedChanges = useSelector(
    (state: RootState) =>
      !isEqual(state.sensorProfileStore.originalProfiles[uuid], state.sensorProfileStore.profiles[uuid])
  );

  return (
    <>
      <Grid item style={{maxWidth: 'calc(100% - 124px)'}}>
        <EllipsisTextWithTooltip variant="h4">{name}</EllipsisTextWithTooltip>
      </Grid>
      <Grid item style={{margin: '-6px 12px 0px 12px'}}>
        <Tooltip title="Edit">
          <IconButton size="small" onClick={setProfileNameEditing}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <ValidationStatusChip state={validationState} hasUnsavedChanges={hasUnsavedChanges} isNew={uuid === 'new'} />
      </Grid>
    </>
  );
};

interface EditModeProps {
  name: string;
  onSave: (data: {name: string}) => void;
  onCancel: () => void;
  requestingNameUpdate: boolean;
}

const EditMode = ({name, onSave, onCancel, requestingNameUpdate}: EditModeProps) => {
  const {
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<{name: string}>({
    mode: 'all',
    defaultValues: {name},
  });

  return (
    <>
      <Grid
        item
        style={{
          width: 'calc(100% - 76px)',
          maxWidth: '360px',
          marginRight: '12px',
        }}
      >
        <form id="updateParamProfileName" onSubmit={onSubmit(onSave)}>
          <Controller
            name="name"
            control={control}
            render={({onChange, value, ref}) => {
              return (
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="small"
                  label="Profile Name"
                  onKeyDown={(event) => event.key === 'Escape' && onCancel()}
                  inputRef={ref}
                  value={value}
                  error={!!errors.name}
                  onChange={(event) => onChange(event.target.value)}
                />
              );
            }}
          />
        </form>
      </Grid>
      <Grid item>
        {requestingNameUpdate ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Tooltip title="Save">
              <IconButton size="small" onClick={onSubmit(onSave)}>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton size="small" onClick={onCancel}>
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Grid>
    </>
  );
};
