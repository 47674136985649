import React, {useEffect} from 'react';
import {Box, Grid, Slider, Typography} from '@material-ui/core';
import {CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {CTFiltersMap} from '../../../components/molecules/Viewport/3D/types/pointCloudTypes';
import {newDebouncer} from '../../../api/ajax';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const debounceSetFilters = newDebouncer();

const defectTypeUnitInfo = (defectType: CTDefectType3D): [string, string, number] => {
  if (defectType === CTDefectType3D.Volume) return ['Volume', 'mm³', 0];
  if (defectType === CTDefectType3D.SurfaceArea) return ['Surface Area', 'mm²', 0];
  if (defectType === CTDefectType3D.SurfacePerVolume) return ['Surface Area/Volume', 'mm⁻¹', 0];
  if (defectType === CTDefectType3D.Solidity) return ['Solidity', '%', 2];
  if (defectType === CTDefectType3D.Extent) return ['Extent', '%', 2];

  return ['', '', 0];
};

const CTDefectFilters = ({
  ctFilters,
  ctFilterRanges,
  setCtFilters,
}: {
  ctFilters: CTFiltersMap;
  ctFilterRanges: CTFiltersMap;
  setCtFilters: (filters: CTFiltersMap) => void;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const [internalFilters, setInternalFilters] = React.useState<CTFiltersMap>(ctFilters);

  useEffect(() => {
    debounceSetFilters(() => {
      setCtFilters(internalFilters);
    }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalFilters]);

  if (!ctFilters || !ctFilterRanges) return <></>;

  return (
    <Box display="grid" gridTemplateColumns={isSmallScreen ? '1fr' : '1fr 1fr 1fr 1fr 1fr'} gridColumnGap="8px">
      {Object.values(CTDefectType3D).map((defectType) => {
        const {min: filterMin, max: filterMax} = ctFilterRanges[defectType] || {};
        const {min: currentMin, max: currentMax} = internalFilters[defectType] || {};

        if ((!filterMin && filterMin !== 0) || (!filterMax && filterMax !== 0)) return <></>;

        const step = (filterMax - filterMin) / 100;
        const [label, unit, precision] = defectTypeUnitInfo(defectType);

        let labelMin = currentMin || filterMin || 0;
        labelMin = unit === '%' ? labelMin * 100 : labelMin;
        let labelMax = currentMax || filterMax || 0;
        labelMax = unit === '%' ? labelMax * 100 : labelMax;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>{label}</Typography>
            <Typography variant="caption">
              {labelMin.toFixed(precision)}
              {unit} to {labelMax.toFixed(precision)}
              {unit}
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs style={{padding: '8px 12px'}}>
                <Slider
                  value={[currentMin || filterMin, currentMax || filterMax]}
                  onChange={(_, newValue) => {
                    const [newMin, newMax] = newValue as [number, number];
                    setInternalFilters({
                      ...internalFilters,
                      [defectType]: {
                        min: newMin > filterMin ? newMin : undefined,
                        max: newMax < filterMax ? newMax : undefined,
                      },
                    });
                  }}
                  min={filterMin}
                  max={filterMax}
                  step={step}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

export default CTDefectFilters;
