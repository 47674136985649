import React, {useState} from 'react';
import styled from 'styled-components';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
  Divider as MuiDivider,
} from '@material-ui/core';
import {Add, Close} from '@material-ui/icons';
import {spacing} from '@material-ui/system';
import {useForm, DeepMap, FieldError} from 'react-hook-form';
import inflection from 'inflection';

import {
  IMachineParameter,
  MACHINE_PARAMETER_DEFAULT_FIELDS,
  MachineParameterDefaultFields,
  MachineParameterDefaultKeys,
} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';
import {objFilter} from '../../../utils/objectFunctions';
import EllipsisTextWithTooltip from '../../atoms/Texts/EllipsisTextWithTooltip';

type MachineParameterFormFields = MachineParameterDefaultFields & {
  name: string;
  notes: string;
  [customField: string]: string;
};

type FormModes = 'create' | 'copy' | 'view';

const Divider = styled(MuiDivider)(spacing);

const MachineParameterFormModal = ({
  mode,
  initialState,
  onClose,
  isOpen,
  onSave,
}: {
  mode: FormModes;
  initialState: IMachineParameter;
  onClose: () => void;
  isOpen: boolean;
  onSave: (data: IMachineParameter) => Promise<boolean>;
}) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const {
    register,
    errors,
    handleSubmit: onSubmit,
    setValue: setFormValue,
    unregister,
  } = useForm<MachineParameterFormFields>({
    mode: 'all',
    defaultValues: {
      name: initialState.name || '',
      notes: initialState.genericNotes || '',
      ...initialState.data,
    },
  });

  const handleSubmit = async (formData: MachineParameterFormFields) => {
    setRequestInProgress(true);
    const machineParameter = {
      ...initialState,
      name: formData.name,
      genericNotes: formData.notes,
      data: {
        // Get all data fields from the form data
        ...objFilter(formData, (key) => key !== 'name' && key !== 'notes'),
        // Insert pending custom fields if the user has entered something there.
        ...(newKey.length > 0 && newValue.length > 0 ? {[newKey]: newValue} : {}),
      },
    };
    const success = await onSave(machineParameter);
    setRequestInProgress(false);
    if (success !== false) {
      onClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <form id="createMachineParameterModal" onSubmit={onSubmit(handleSubmit)}>
        <DialogTitle disableTypography style={{padding: '24px'}}>
          <MachineParametersModalHeader onClose={onClose} mode={mode} />
        </DialogTitle>

        <StyledDiaglogContent dividers>
          <MachineParametersModalContent
            isReadOnly={mode === 'view'}
            errors={errors}
            initialState={initialState}
            register={register}
            unregister={unregister}
            setFormValue={setFormValue}
            newKey={newKey}
            newValue={newValue}
            setNewKey={setNewKey}
            setNewValue={setNewValue}
          />
        </StyledDiaglogContent>
        <DialogActions style={{padding: '16px 24px'}}>
          <MachineParameterModalActions
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            onClose={onClose}
            requestInProgress={requestInProgress}
            isReadOnly={mode === 'view'}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

const MachineParametersModalHeader = ({onClose, mode}: {onClose: () => void; mode: FormModes}) => {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        <EllipsisTextWithTooltip variant="h4">{inflection.capitalize(mode)} Machine Parameter</EllipsisTextWithTooltip>
        <IconButton aria-label="close" onClick={onClose} style={{paddingRight: '12px'}}>
          <Close />
        </IconButton>
      </Grid>
    </>
  );
};

const MachineParametersModalContent = ({
  isReadOnly,
  errors,
  initialState,
  register,
  unregister,
  setFormValue,
  newKey,
  newValue,
  setNewKey,
  setNewValue,
}: {
  isReadOnly: boolean;
  initialState: Partial<IMachineParameter>;
  errors: DeepMap<MachineParameterFormFields, FieldError>;
  register: any;
  unregister: any;
  setFormValue: any;
  newKey: string;
  setNewKey: React.Dispatch<React.SetStateAction<string>>;
  newValue: string;
  setNewValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [customFields, setCustomFields] = useState<string[]>(
    Object.keys(initialState?.data || {}).filter(
      (key) => !Object.values(MachineParameterDefaultKeys).includes(key as any)
    )
  );

  const deleteDataField = (key: string) => {
    setCustomFields(customFields.filter((field) => field !== key));
    unregister(key);
  };

  const addDataField = () => {
    // Need to call this twice because the first call only seems to create the
    // field but not apply the value
    setFormValue(newKey, newValue);
    setTimeout(() => setFormValue(newKey, newValue));

    setCustomFields([...customFields, newKey]);
    setNewKey('');
    setNewValue('');
  };

  const [layerThicknessKey, layerThicknessName, layerThicknessUnits] = MACHINE_PARAMETER_DEFAULT_FIELDS.find(
    (item) => item[0] === MachineParameterDefaultKeys.LayerThickness
  )!;
  const machineParamsNoThickness = MACHINE_PARAMETER_DEFAULT_FIELDS.filter((item) => item[0] !== layerThicknessKey);

  return (
    <>
      <GridDiv columns="1fr">
        <Tooltip title={initialState.name || ''} open={isReadOnly ? undefined : false}>
          <TextField
            variant="outlined"
            size="small"
            error={!!errors.name}
            helperText={errors.name?.message}
            type="text"
            placeholder="Name*"
            label="Name*"
            disabled={isReadOnly}
            name="name"
            inputProps={{
              'aria-required': 'true',
            }}
            inputRef={register({
              required: 'Please input name',
            })}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Tooltip>

        <Tooltip
          title={(initialState.data && initialState.data.layerThickness) || ''}
          open={isReadOnly ? undefined : false}
        >
          <TextField
            variant="outlined"
            size="small"
            error={!!errors.layerThickness}
            helperText={errors.layerThickness?.message}
            type="number"
            label={`${layerThicknessName}*`}
            disabled={isReadOnly}
            name={layerThicknessKey}
            placeholder={layerThicknessUnits || `${layerThicknessName}*`}
            inputProps={{
              'aria-required': 'true',
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              required: 'Please enter a number',
              validate: (v: any) => {
                if (isNaN(v)) {
                  return 'Please enter a number';
                } else if (v < 0) {
                  return 'Please enter a positive number';
                }
                return true;
              },
            })}
          />
        </Tooltip>

        <TextField
          variant="outlined"
          size="small"
          rows={4}
          multiline
          error={!!errors.notes}
          helperText={errors.notes?.message}
          type="text"
          label="Notes"
          placeholder="Notes"
          disabled={isReadOnly}
          name="notes"
          inputProps={{
            'aria-required': 'true',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register()}
        />

        {machineParamsNoThickness.map(([key, name, units]) => (
          <>
            <Tooltip title={(initialState.data && initialState.data[key]) || ''} open={isReadOnly ? undefined : false}>
              <TextField
                variant="outlined"
                size="small"
                error={!!errors[key]}
                helperText={errors[key]?.message}
                type="number"
                label={name}
                disabled={isReadOnly}
                name={key}
                placeholder={units || name}
                inputProps={{
                  'aria-required': 'true',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={register({
                  validate: (v: any) => {
                    if (isNaN(v)) {
                      return 'Please enter a number';
                    } else if (v < 0) {
                      return 'Please enter a positive number';
                    }
                    return true;
                  },
                })}
              />
            </Tooltip>
          </>
        ))}
      </GridDiv>
      {!!Object.values(customFields).length && (
        <>
          <Divider my={4} />
          <GridDiv columns="1fr 32px">
            {Object.values(customFields).map((key) => (
              <>
                <Tooltip
                  title={(initialState?.data && initialState?.data[key]) || ''}
                  open={isReadOnly ? undefined : false}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    error={!!errors[key]}
                    helperText={errors[key]?.message}
                    type="text"
                    placeholder={`Parameter ${key}`}
                    label={key}
                    disabled={isReadOnly}
                    name={key}
                    inputProps={{
                      'aria-required': 'true',
                    }}
                    inputRef={register({
                      required: 'Please enter a number or text',
                    })}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Tooltip>

                <Tooltip title="Remove Property">
                  <IconButton onClick={() => deleteDataField(key)} disabled={isReadOnly}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </>
            ))}
          </GridDiv>
        </>
      )}
      {!isReadOnly && (
        <>
          <Divider my={4} />
          <GridDiv columns="1fr 1fr 32px">
            <TextField
              variant="outlined"
              size="small"
              error={customFields.includes(newKey)}
              label={customFields.includes(newKey) ? `${newKey} has already been set` : ''}
              value={newKey}
              placeholder="Custom property name"
              onChange={(e) => setNewKey(e.target.value)}
            />
            <TextField
              variant="outlined"
              size="small"
              value={newValue}
              placeholder="Value"
              onChange={(e) => setNewValue(e.target.value)}
            />
            <Tooltip title="Add Property">
              <IconButton
                onClick={() => addDataField()}
                disabled={!newKey || customFields.includes(newKey)}
                color="primary"
              >
                <Add />
              </IconButton>
            </Tooltip>
          </GridDiv>
        </>
      )}
    </>
  );
};

const MachineParameterModalActions = ({
  onSubmit,
  onClose,
  handleSubmit,
  requestInProgress,
  isReadOnly,
}: {
  onSubmit: any;
  handleSubmit: (data: any) => void;
  onClose: () => void;
  requestInProgress: boolean;
  isReadOnly: boolean;
}) => {
  return (
    <>
      <Button variant="outlined" color="primary" onClick={onClose} disabled={requestInProgress}>
        Cancel
      </Button>
      {!isReadOnly && (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSubmit(handleSubmit)}
          endIcon={requestInProgress ? <CircularProgress size={20} /> : <></>}
          disabled={requestInProgress}
        >
          Create
        </Button>
      )}
    </>
  );
};

export default MachineParameterFormModal;

const StyledDiaglogContent = styled(DialogContent)`
  padding: 24px 2px;
  margin: 0px 24px;
  overflow: hidden;
`;

const GridDiv = styled.div<{columns: string}>`
  display: grid;
  grid-template-columns: ${({columns}) => columns};
  grid-row-gap: 12px;
  grid-column-gap: 18px;
  .MuiIconButton-root {
    padding: 0px;
  }
`;
