import React, {useState, useEffect, useRef, useMemo} from 'react';
import {Box, Checkbox, CircularProgress, IconButton, Typography, Tooltip} from '@material-ui/core';
import {TreeView, TreeItem} from '@material-ui/lab';
import {ChevronRight, ExpandMore, CloudDownload, Delete} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {LoadMoreSensor, useIsInViewport} from '../../../utils/LoadMoreSensor';
import {useBuildIntensityReportStoreActions} from '../../../../src/store/actions';
import {
  IBuildIntensityReportGETResponse,
  BuildIntensityReportStatus,
} from '@common/api/models/builds/data/IBuildIntensityReport';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {downloadResource} from '../../../utils/webtools';
import {buildIntensityReportDownloadUrlGET} from '../../../api/ajax/buildIntensityReport';
import {ChipStatus, StatusVariant} from '../../../components/atoms/Status/Status';
import {useAsyncDispatch} from '../../../ReduxRoot';
import buildIntensityReportActions from '../../../store/actions/buildIntensityReportActions';
import EllipsisTextWithTooltip from '../../../components/atoms/Texts/EllipsisTextWithTooltip';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {SetHoveredSelection} from '../BuildIntensityPage';

const BuildsPartsTree = ({
  buildIntensityReports,
  setHoveredSelection,
}: {
  buildIntensityReports: IBuildIntensityReportGETResponse[];
  setHoveredSelection: SetHoveredSelection;
}) => {
  return (
    <>
      {buildIntensityReports.map((intensityReport) => (
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          key={`build-tree-view-${intensityReport.buildUuid}`}
        >
          <TreeItem
            nodeId={'0'}
            label={
              <BuildLabel
                buildName={intensityReport.buildName}
                buildUuid={intensityReport.buildUuid}
                reportUuid={intensityReport.uuid}
                setHoveredSelection={setHoveredSelection}
              />
            }
            key={`build-tree-item-${intensityReport.uuid}`}
          >
            {intensityReport.status === BuildIntensityReportStatus.Success ? (
              <PartsForBuild
                reportUuid={intensityReport.uuid}
                buildUuid={intensityReport.buildUuid}
                setHoveredSelection={setHoveredSelection}
              />
            ) : (
              <></>
            )}
          </TreeItem>
        </TreeView>
      ))}
    </>
  );
};

export default BuildsPartsTree;

const PartsForBuild = React.memo(
  ({
    reportUuid,
    buildUuid,
    setHoveredSelection,
  }: {
    reportUuid: string;
    buildUuid: string;
    setHoveredSelection: SetHoveredSelection;
  }) => {
    const [skipParts, setSkipParts] = useState(0);
    const loadMoreRef: React.RefObject<HTMLDivElement> = useRef(null);
    const shouldLoadMore = useIsInViewport(loadMoreRef);

    const summaryCSVs = useSelector((state: RootState) => state.buildIntensityReportStore.summaryCSVs);
    const parts = useSelector(
      (state: RootState) => state.buildIntensityReportStore.partsByBuildUuid[buildUuid]?.parts || []
    );
    const totalParts = useSelector(
      (state: RootState) => state.buildIntensityReportStore.partsByBuildUuid[buildUuid]?.total
    );
    const allPartsLoaded = useMemo(
      () => totalParts !== undefined && parts.length >= totalParts,
      [totalParts, parts.length]
    );

    const {fetchPartsForBuild, addIntensityCSVs} = useBuildIntensityReportStoreActions();

    useEffect(() => {
      if (!summaryCSVs.hasOwnProperty(buildUuid)) {
        addIntensityCSVs(reportUuid, buildUuid);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (shouldLoadMore && !allPartsLoaded) {
        fetchPartsForBuild(skipParts, buildUuid);
        setSkipParts((skip) => skip + 25);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoadMore, parts.length]);

    return (
      <>
        {parts?.map((part, nodeId) => (
          <TreeItem
            nodeId={`${nodeId + 1}`}
            label={
              <PartLabel
                buildUuid={buildUuid}
                reportUuid={reportUuid}
                part={part}
                setHoveredSelection={setHoveredSelection}
              />
            }
            key={`part-tree-item-${part.uuid}`}
          />
        ))}
        {totalParts === 0 && (
          <Typography>
            <b>No Parts found for this build</b>
          </Typography>
        )}
        {!allPartsLoaded && (
          <LoadMoreSensor ref={loadMoreRef}>
            <CircularProgress size={14} />
            <Typography>Loading more parts...</Typography>
          </LoadMoreSensor>
        )}
      </>
    );
  }
);

const CHIP_STATUS_MAP = {
  [BuildIntensityReportStatus.Submitted]: {variant: 'info', message: 'Submitted'},
  [BuildIntensityReportStatus.Generating]: {variant: 'info', message: 'Generating'},
  [BuildIntensityReportStatus.Success]: {variant: 'success', message: 'Complete'},
  [BuildIntensityReportStatus.Failure]: {variant: 'error', message: 'Failed'},
};

const BuildLabel = ({
  reportUuid,
  buildName,
  buildUuid,
  setHoveredSelection,
}: {
  reportUuid: string;
  buildName: string;
  buildUuid: string;
  setHoveredSelection: SetHoveredSelection;
}) => {
  const dispatch = useAsyncDispatch();
  const selectedBuilds = useSelector((state: RootState) => state.buildIntensityReportStore.selectedBuilds);
  const summaryCSVs = useSelector((state: RootState) => state.buildIntensityReportStore.summaryCSVs);
  const reportStatus = useSelector((state: RootState) => state.buildIntensityReportStore.byId[reportUuid].status);
  const {addSelectedBuild, removeSelectedBuild, addIntensityCSVs} = useBuildIntensityReportStoreActions();
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const isSelected = selectedBuilds.has(buildUuid);

  async function handleCsvDownload() {
    if (downloading) return;

    setDownloading(true);
    const url = await buildIntensityReportDownloadUrlGET(reportUuid);

    if (!url.success) {
      setDownloading(false);
      return;
    }

    downloadResource(url.data.url, `${buildName}-build-intensity-report.zip`);
    setDownloading(false);
  }

  async function deleteReport() {
    if (deleting) return;

    setDeleting(true);
    await dispatch(buildIntensityReportActions.deleteBuildIntensityReport(reportUuid));
    setDeleting(false);
  }

  function handleToggle() {
    if (isSelected) {
      removeSelectedBuild(buildUuid);
    } else {
      addSelectedBuild(buildUuid, buildName);
      if (!summaryCSVs.hasOwnProperty(buildUuid)) {
        addIntensityCSVs(reportUuid, buildUuid);
      }
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{background: isSelected ? 'rgb(230 230 227 / 70%)' : ''}}
      onMouseEnter={() => isSelected && setHoveredSelection({type: 'build', uuid: buildUuid})}
      onMouseLeave={() => setHoveredSelection(null)}
    >
      <Checkbox
        checked={isSelected}
        size="small"
        disabled={reportStatus !== BuildIntensityReportStatus.Success}
        onClick={(e) => {
          handleToggle();
          e.stopPropagation();
        }}
      />
      <EllipsisTextWithTooltip>{buildName}</EllipsisTextWithTooltip>
      <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
        <ChipStatus
          message={CHIP_STATUS_MAP[reportStatus].message}
          variant={CHIP_STATUS_MAP[reportStatus].variant as StatusVariant}
          chipSize="small"
        />

        <Tooltip title="Download report">
          <IconButton
            aria-label="download"
            onClick={(e) => {
              e.stopPropagation();
              handleCsvDownload();
            }}
            style={{padding: '8px'}}
            disabled={reportStatus !== BuildIntensityReportStatus.Success || downloading}
          >
            {downloading ? <CircularProgress size={24} /> : <CloudDownload />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete report">
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              e.stopPropagation();
              deleteReport();
            }}
            style={{padding: '8px'}}
            disabled={deleting}
          >
            {deleting ? <CircularProgress size={24} /> : <Delete />}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const PartLabel = ({
  buildUuid,
  reportUuid,
  part,
  setHoveredSelection,
}: {
  buildUuid: string;
  reportUuid: string;
  part: IPartGETResponse;
  setHoveredSelection: SetHoveredSelection;
}) => {
  const selectedParts = useSelector((state: RootState) => state.buildIntensityReportStore.selectedParts);
  const partCSVs = useSelector((state: RootState) => state.buildIntensityReportStore.partCSVs);

  const reportStatus = useSelector((state: RootState) => state.buildIntensityReportStore.byId[reportUuid]?.status);

  const {addSelectedPart, removeSelectedPart} = useBuildIntensityReportStoreActions();
  const isSelected = selectedParts.has(part);

  const handleToggle = () => {
    if (isSelected) {
      removeSelectedPart(part);
    } else {
      addSelectedPart(part);
    }
  };

  return (
    <ConditionalTooltip
      tooltip={
        'Intensity for this part is not available, this is most likely due to there not being enough ' +
        'data for this part, or it recorded 0 intensity across the build.'
      }
      hideTooltip={!!partCSVs[buildUuid]?.[part.uuid]}
    >
      <Box
        display="flex"
        alignItems="center"
        style={{background: isSelected ? 'rgb(230 230 227 / 70%)' : '', marginLeft: '10px'}}
        onMouseEnter={() => isSelected && setHoveredSelection({type: 'part', uuid: part.uuid})}
        onMouseLeave={() => setHoveredSelection(null)}
      >
        <Checkbox
          checked={isSelected}
          size="small"
          disabled={reportStatus !== BuildIntensityReportStatus.Success || !partCSVs[buildUuid]?.[part.uuid]}
          onClick={handleToggle}
        />
        <EllipsisTextWithTooltip>{part.name}</EllipsisTextWithTooltip>
      </Box>
    </ConditionalTooltip>
  );
};
