import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {useDraftBuild} from '../DraftBuildContext';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import ResourcePermissionsTable from '../../../../components/molecules/Table/ResourcePermissionsTable';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/reducers/index';
import {Role} from '@common/api/models/users/IUser';
import {Alert} from '@material-ui/lab';

const UserAccessStep = () => {
  const isSmallScreen = useSmallScreenSize();
  const {draftBuild} = useDraftBuild();
  const currentUser = useSelector((state: RootState) => state.auth.user!);

  return (
    <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="space-between">
      <Grid item xs={12}>
        <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Step 5: User Access</Typography>
      </Grid>
      {currentUser.role > Role.TECHNICIAN || currentUser.uuid === draftBuild.provisionerUuid ? (
        <ResourcePermissionsTable resourceType={ResourcePermissionType.BUILD} resource={draftBuild} />
      ) : (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Alert severity="info" style={{width: '100%'}}>
              Only the provisioner of the build or managers and above may manage user access
            </Alert>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserAccessStep;
