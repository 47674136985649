import React, {useState} from 'react';
import MomentUtils from '@date-io/moment';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import {shortDateFormat, shortNoSecondsDateTimeFormat} from '../../../utils/config';
import {ParsableDate} from '@material-ui/pickers/constants/prop-types';
import moment from 'moment';
import {MinMax} from '../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {Grid} from '@material-ui/core';

export interface DateTimeRangeSelectorModalProps<_T> {
  buildDateRange?: MinMax<ParsableDate>;
  selectedDates: MinMax<moment.Moment>;
  setSelectedDates: (newDates: MinMax<ParsableDate>) => void;
  isDataLive?: boolean;
  label?: string;
  showTimeSelection?: boolean;
}

export interface DateTimeFilterParams<_T> {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export function DateTimeRangeSelectorModal<T>({
  buildDateRange,
  setSelectedDates,
  isDataLive,
  selectedDates,
  label = 'Time',
  showTimeSelection = true,
}: DateTimeRangeSelectorModalProps<T>) {
  const [selectedStartDate, setSelectedStartDate] = useState<MaterialUiPickersDate | ParsableDate>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<MaterialUiPickersDate | ParsableDate>(null);

  const fromValue = isDataLive && !!selectedDates.min ? selectedDates.min : selectedStartDate;
  const toValue = isDataLive && !!selectedDates.max ? selectedDates.max : selectedEndDate;

  function onChangeHandler(date: MaterialUiPickersDate, startOrEnd: 'start' | 'end') {
    let startDate = null;
    let endDate = null;
    const minDate = buildDateRange?.min && moment.utc(buildDateRange.min);
    const maxDate = buildDateRange?.max && moment.utc(buildDateRange.max);

    const setWithinBounds = (date: MaterialUiPickersDate) => {
      let momentDate = moment.utc(date);
      // Set date to start/end of day for min/max
      if (!showTimeSelection) {
        momentDate = startOrEnd === 'start' ? momentDate.startOf('day') : momentDate.endOf('day');
      }

      if (!buildDateRange) return momentDate;

      if (minDate && momentDate.isBefore(minDate)) {
        return minDate;
      } else if (maxDate && momentDate.isAfter(maxDate)) {
        return maxDate;
      } else {
        return momentDate;
      }
    };

    if (startOrEnd === 'start' && date !== null) {
      startDate = setWithinBounds(date);
      if (toValue) {
        endDate = moment.utc(toValue);
        if (startDate.isAfter(endDate)) endDate = maxDate || null;
      }
    }

    if (startOrEnd === 'end' && date !== null) {
      endDate = setWithinBounds(date);
      if (fromValue) {
        startDate = moment.utc(fromValue);
        if (endDate.isBefore(startDate)) startDate = null;
      }
    }

    setSelectedStartDate(startDate?.format() || null);
    setSelectedEndDate(endDate?.format() || null);
    setSelectedDates({min: startDate, max: endDate});
  }

  const formatDate = (date: moment.Moment) => {
    if (showTimeSelection) return date.local().format();
    return date.format();
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DateTimePicker
            ampm={false}
            views={showTimeSelection ? undefined : ['year', 'month', 'date']}
            autoOk
            clearable
            format={showTimeSelection ? shortNoSecondsDateTimeFormat : shortDateFormat}
            fullWidth
            hideTabs
            showTodayButton
            inputVariant="outlined"
            label={`${label} from`}
            placeholder={showTimeSelection ? 'yyyy-mm-dd, hh:mm' : 'yyyy-mm-dd'}
            InputLabelProps={{shrink: true}}
            minDate={buildDateRange?.min}
            maxDate={buildDateRange?.max}
            onChange={(date) => onChangeHandler(date, 'start')}
            size="small"
            value={fromValue ? formatDate(moment(fromValue)) : null}
          />
        </Grid>

        <Grid item xs={6}>
          <DateTimePicker
            ampm={false}
            views={showTimeSelection ? undefined : ['year', 'month', 'date']}
            autoOk
            clearable
            format={showTimeSelection ? shortNoSecondsDateTimeFormat : shortDateFormat}
            fullWidth
            hideTabs
            inputVariant="outlined"
            label={`${label} to`}
            placeholder={showTimeSelection ? 'yyyy-mm-dd, hh:mm' : 'yyyy-mm-dd'}
            InputLabelProps={{shrink: true}}
            minDate={buildDateRange?.min}
            maxDate={buildDateRange?.max}
            onChange={(date) => onChangeHandler(date, 'end')}
            showTodayButton
            size="small"
            value={toValue ? formatDate(moment(toValue)) : null}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
