import {IDevice} from '@common/api/models/devices/IDevice';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function devicesAllGET(filter: QueryFilter<IDevice>): AjaxApiResult<IDevice[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});

    return axios.get('/api/devices/' + (query && '?' + query));
  }, 'Could not fetch devices');
}

export async function deviceCreatePOST(device: Partial<IDevice>): AjaxApiResult<IDevice> {
  return ajaxWrapper(() => {
    return axios.post('/api/devices/', device);
  }, 'Could not create device');
}

export async function deviceProfilePUT(deviceSerial: string, sensorProfileUuid: string) {
  return ajaxWrapper(() => {
    return axios.put(`/api/devices/${deviceSerial}`, {sensorProfileUuid});
  }, 'Could not update device');
}
// State API

export async function deviceStatePUT(deviceSerial: string, state: string): AjaxApiResult<IDevice> {
  return ajaxWrapper(() => {
    return axios.put(`/api/devices/${deviceSerial}/control/${state}`);
  }, 'Could not update device state');
}
