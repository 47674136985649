import {IUser, IUsersGETResponse, IUsersPATCHRequest, IUsersPOSTRequest} from '@common/api/models/users/IUser';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function usersAllGET(filter: QueryFilter<IUser>): AjaxApiResult<IUser[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/users/' + (query && '?' + query));
  }, 'Could not fetch users');
}

export async function userDELETE(uuid: string): AjaxApiResult<IUser> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/users/${uuid}`);
  }, 'Could not delete user');
}

export async function userPOST(user: Partial<IUsersPOSTRequest>, password: string): AjaxApiResult<IUsersGETResponse> {
  return ajaxWrapper(() => {
    return axios.post('/api/users/', {
      ...user,
      password,
    });
  }, 'Could not create user');
}

export async function userPATCH(uuid: string, partial: Partial<IUsersPATCHRequest>): AjaxApiResult<IUser> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/users/${uuid}`, partial);
  }, 'Could not update user');
}

export async function userSearchGET(query: string, filter?: QueryFilter<IUser>): AjaxApiResult<IUser[]> {
  return ajaxWrapper(() => {
    const filterQuery = !!query ? {...filter, query} : filter;
    const params = qs.stringify(filterQuery, {arrayFormat: 'brackets'});
    return axios.get('/api/users/search/?' + params);
  }, 'Could not search user');
}

export async function userResetPasswordPOST(uuid: string, newPassword?: string): AjaxApiResult<IUser> {
  return ajaxWrapper(() => {
    return axios.post(`/api/users/${uuid}/resetPassword/`, {
      newPassword,
    });
  }, "Could not reset user's password");
}
