import {createBrowserHistory} from 'history';
import {applyMiddleware, createStore} from 'redux';
import {composeWithDevToolsDevelopmentOnly} from '@redux-devtools/extension';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './store/reducers';

// Unused local redux storage (may add in later)
// import * as localforage from 'localforage';
// import {
//   PersistConfig,
//   persistReducer
// } from 'redux-persist';

// const persistConfig: PersistConfig<any> = {
//   key: 'root',
//   version: 1,
//   storage: localforage,
//   blacklist: [],
// };

const logger = (createLogger as any)();
const history = createBrowserHistory();

const loggerEnabled = process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true';

let middleware = loggerEnabled ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

// const persistedReducer = persistReducer(persistConfig, rootReducer(history));

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  /* don't persist right now.
	const store = createStore(persistedReducer, {}, middleware) as any;
	const persistor = persistStore(store);
  */

  const store = createStore(rootReducer(), {}, composeWithDevToolsDevelopmentOnly(middleware));

  return {store};
};

export {history};
