import React, {useState} from 'react';
import {Box, Button} from '@material-ui/core';
import styled from 'styled-components';
import {CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {CommonCTStepProps} from '../steps/CTReportStep';
import CTResultsViewport from '../CTResultsViewport';
import {useContainerHeight, useSmallScreenSize} from '../../../utils/utilHooks';
import CTDefectsTable from './CTDefectsTable';
import CTDefectFilters from './CTDefectFilters';
import {CTFiltersMap} from '../../../components/molecules/Viewport/3D/types/pointCloudTypes';
import {objMapValues} from '../../../utils/objectFunctions';
import CTResultsBottomToolbar from './CTResultsBottomToolbar';

function CTResultsPane({currentCTReport}: CommonCTStepProps) {
  const isSmallScreen = useSmallScreenSize();
  const [activeDefectId, setActiveDefectId] = useState<number | null>(null);
  const [filterRanges, setFilterRanges] = useState<CTFiltersMap>(
    objMapValues(CTDefectType3D, () => ({})) as CTFiltersMap
  );
  const [ctFilters, setCtFilters] = useState<CTFiltersMap>(objMapValues(CTDefectType3D, () => ({})) as CTFiltersMap);
  const {height, setContainerRef} = useContainerHeight();

  return (
    <Box height="100%" minHeight="700px">
      <Container ref={setContainerRef} isSmallScreen={isSmallScreen}>
        <CTResultsViewport
          ctReport={currentCTReport}
          stageHeight={height}
          activeDefectId={activeDefectId}
          ctFilters={ctFilters}
        />
      </Container>
      {activeDefectId && (
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button variant="contained" color="primary" onClick={() => setActiveDefectId(null)}>
            Clear Active Defect
          </Button>
        </Box>
      )}
      {!isSmallScreen && (
        <CTDefectFilters ctFilterRanges={filterRanges} ctFilters={ctFilters} setCtFilters={setCtFilters} />
      )}
      {isSmallScreen && (
        <CTResultsBottomToolbar ctFilterRanges={filterRanges} ctFilters={ctFilters} setCtFilters={setCtFilters} />
      )}
      <CTDefectsTable
        currentCTReport={currentCTReport}
        setActiveDefectId={setActiveDefectId}
        setFilterRanges={setFilterRanges}
        ctFilters={ctFilters}
        activeDefectId={activeDefectId}
      />
    </Box>
  );
}

export default CTResultsPane;

const Container = styled.div<{isSmallScreen: boolean}>`
  /* Don't ask how this works, just don't change it or the screen grows infinitely */
  height: calc(100% - 14px);
  min-height: 400px;
  max-height: ${({isSmallScreen}) => (isSmallScreen ? '450px' : '800px')};
  margin-bottom: 32px;
`;
