import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  Box,
  Icon,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {ChevronUp, ChevronRight} from 'react-feather';
import {humanize, inflect} from 'inflection';

import {
  ILocationBasedDefectsReportGETResponse,
  LocationBasedDefectsStatus,
} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {RootState} from '../../store/reducers/index';
import {FetchingState} from '../../store/model/liveUpdateStore';
import NewReportButton from './NewReportButton';
import {ChipStatus, StatusVariant} from '../../components/atoms/Status/Status';
import {locationBasedDefectsReportDELETE} from '../../api/ajax/locationBasedDefectsReport';

function LocationBasedDefectsList({setIsDrawerOpen}: {setIsDrawerOpen?: (open: boolean) => void}) {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const reports = useSelector((state: RootState) => Object.values(state.locationBasedDefectsReportStore.byId));

  return (
    <Card style={{height: '100%', overflow: 'auto'}}>
      {reports.length === 0 ? (
        <NoReports />
      ) : (
        <List style={{padding: 0}}>
          {reports.map((report) => (
            <LocationBasedDefectsListItem
              key={report.uuid}
              selected={report.uuid === selectedUuid}
              setIsDrawerOpen={setIsDrawerOpen}
              {...report}
            />
          ))}
        </List>
      )}
    </Card>
  );
}

export default LocationBasedDefectsList;

function NoReports() {
  const failedToFetch = useSelector(
    (state: RootState) => state.locationBasedDefectsReportStore.fetched === FetchingState.Error
  );

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h3" style={{marginBottom: '32px'}}>
        {failedToFetch ? 'Failed to fetch reports.' : 'No reports have been created.'}
      </Typography>
      {!failedToFetch && <NewReportButton />}
    </Box>
  );
}

interface ILocationBasedDefectsListItem extends ILocationBasedDefectsReportGETResponse {
  selected?: boolean;
}

function LocationBasedDefectsListItem({
  uuid,
  name,
  selected,
  builds,
  status,
  setIsDrawerOpen,
}: ILocationBasedDefectsListItem & {setIsDrawerOpen?: (open: boolean) => void}) {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const [deleting, setDeleting] = useState(false);

  const secondaryText = secondaryListItemText(builds);

  function onListItemClicked() {
    history.replace(`/reports/locationBasedDefects/${uuid}/`);
    setIsDrawerOpen && setIsDrawerOpen(false);
  }

  async function onDeleteClick() {
    setDeleting(true);
    await locationBasedDefectsReportDELETE(uuid);
    setDeleting(false);
  }

  return (
    <ListItem divider selected={selected} onClick={onListItemClicked} style={{cursor: 'pointer'}}>
      <ListItemText
        primary={
          <Box display="flex" justifyContent="space-between">
            <Typography style={{paddingRight: '12px', wordBreak: 'break-word'}}>{name}</Typography>
            <ChipStatus chipSize="small" message={humanize(status)} variant={statusChipColor(status)} />
          </Box>
        }
        secondary={secondaryText}
        style={{paddingRight: '48px'}}
      />
      <ListItemSecondaryAction style={{display: 'flex'}}>
        <Tooltip title={<Typography>Delete report</Typography>}>
          <span>
            <IconButton edge="end" onClick={onDeleteClick} disabled={deleting}>
              {deleting ? <CircularProgress size={20} /> : <Delete />}
            </IconButton>
          </span>
        </Tooltip>
        <Icon
          component="div"
          color={selected ? 'secondary' : undefined}
          style={{padding: '12px 0px 12px 12px', height: '36px', width: '36px'}}
        >
          {isSmallScreen ? <ChevronUp /> : <ChevronRight />}
        </Icon>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function secondaryListItemText(builds: ILocationBasedDefectsReportGETResponse['builds']) {
  return `${builds.length} ${inflect('build', builds.length)} included`;
}

export function statusChipColor(status: ILocationBasedDefectsListItem['status']): StatusVariant {
  switch (status) {
    case LocationBasedDefectsStatus.Submitted:
    case LocationBasedDefectsStatus.Generating:
      return 'info';
    case LocationBasedDefectsStatus.Success:
      return 'success';
    case LocationBasedDefectsStatus.Failure:
      return 'error';
  }
}
