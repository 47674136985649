import React, {useEffect} from 'react';
import {Card, CardContent, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {MachinePageProps} from './index';
import {RootState} from '../../../store/reducers';
import {IBuild, isCompletedBuildState} from '@common/api/models/builds/IBuild';
import BuildsTableWithStatus from '../../../components/molecules/Table/BuildsTableWithStatus';
import {useMaterialStoreActions} from '../../../store/actions';

export function MachineParameterMiniTable(props: {build: IBuild}) {
  const machineParameterActions = useMaterialStoreActions();

  useEffect(() => {
    if (props.build.machineParametersUuid) {
      machineParameterActions.ensureConsistent({
        uuid: props.build.machineParametersUuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.machineParametersUuid]);

  const machineParameterStore = useSelector((state: RootState) => state.machineParameterStore);
  const machineParameters = machineParameterStore.byId[props.build.machineParametersUuid!];

  return (
    <React.Fragment>
      <Table size="small">
        <TableBody>
          {machineParameters ? (
            [
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>{machineParameters.name}</TableCell>
              </TableRow>,
              <TableRow>
                <TableCell>
                  <strong>Notes</strong>
                </TableCell>
                <TableCell>{machineParameters.genericNotes}</TableCell>
              </TableRow>,
              <TableRow>
                <TableCell>
                  <strong>Registered on</strong>
                </TableCell>
                <TableCell>{machineParameters.registered}</TableCell>
              </TableRow>,

              ...Object.entries(machineParameters.data).map(([key, val]) => {
                return (
                  <TableRow>
                    <TableCell>
                      <strong>{'' + key}</strong>
                    </TableCell>
                    <TableCell>{'' + val}</TableCell>
                  </TableRow>
                );
              }),
            ]
          ) : (
            <Typography>
              <i>No machine parameters set</i>
            </Typography>
          )}
        </TableBody>
      </Table>
      <br />
    </React.Fragment>
  );
}

export function BuildHistoryPage({machine}: MachinePageProps) {
  const myCompletedBuilds = useSelector(
    (state: RootState) =>
      state.buildStore.byMachineUuid[machine.uuid]?.filter((b) => isCompletedBuildState(b.state)) || []
  );

  myCompletedBuilds.sort((a, b) => new Date(a.end!).getTime() - new Date(b.end!)!.getTime());

  return (
    <Card>
      <CardContent>
        <BuildsTableWithStatus
          builds={myCompletedBuilds}
          exclude={['machine']}
          detailPanel={[
            {
              render: (rowData) => <MachineParameterMiniTable build={rowData.build} />,
              tooltip: 'Machine Parameters',
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
