import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CloudDownload, KeyboardArrowDown, Settings} from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {toast} from 'react-toastify';

import {AnalysisType2D, getPrettyAnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';

import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../utils';
import {downloadUrl} from '../../../../../utils/webtools';
import {getLargestImage, PartImages} from '../../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {AnalysisOverlayParams} from '../analysisType';
import ColorPicker from '../../../Picker/ColorPicker';
import {RGBColor} from 'react-color';

const muiSliderTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: '#1976D2',
      },
      track: {
        color: '#1976D2',
      },
      rail: {
        color: '#ADC8ED',
      },
      valueLabel: {
        transform: 'scale(1) translateY(8px) !important',
        left: 'auto',
        '& > span': {
          width: '20px',
          height: '20px',
        },
        '& > span > span': {
          fontSize: '9px',
        },
      },
    },
  },
});

const customKeyDownButton = styled(KeyboardArrowDown)`
  color: #fff;
`;

const AnalysisWrapper = styled.div`
  padding-top: ${BUTTON_HEIGHT + BUTTON_MARGIN * 2}px;
  padding-left: ${BUTTON_MARGIN}px;
  .MuiMenuItem-root {
    width: 300px;
    @media (max-width: 768px) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
`;

interface LayerSelectionOverlayProps {
  partImages: PartImages;
  onSelect: (analysisType: AnalysisType2D) => void;
  selectedIds: {[analysisType in AnalysisType2D]?: boolean};
  selectKey: AnalysisType2D;
  analysisOverlayParams: AnalysisOverlayParams;
  setAnalysisOverlayParams: (data: AnalysisOverlayParams) => void;
  overlayColors: {
    [AnalysisType2D.LayerMask]: RGBColor;
  };
  setOverlayColours: (analysisType: AnalysisType2D, val: RGBColor) => void;
  forceUpdate: () => void;
}

interface LayerSelectionButtonProps {
  partImages: PartImages;
  analysisType: AnalysisType2D;
  onSelect: (id: AnalysisType2D) => any;
  selectedIds: {[analysisType in AnalysisType2D]?: boolean};
  isSelect: boolean;
  setDialogOpen: (val: AnalysisType2D) => void;
}

const CustomSlider = (props: any) => {
  return (
    <ThemeProvider theme={muiSliderTheme}>
      <Slider {...props} />
    </ThemeProvider>
  );
};

export default function LayerSelectorOverlay(props: LayerSelectionOverlayProps) {
  const availableAnalysisTypes = props.partImages ? [...Object.keys(props.partImages), 'colourMap'] : [];

  const [currentAnalysisType, setAnalysisType] = useState(AnalysisType2D.Layer);
  const [dialogState, setDialogState] = useState(false);
  const isXs = useMediaQuery('(max-width:600px)');
  const selectedIdLen = (Object.keys(props.selectedIds) as AnalysisType2D[]).reduce(
    (acc, val) => (props.selectedIds[val] ? acc + 1 : acc),
    0
  );

  const closeDialog = useCallback((value: AnalysisType2D) => {
    setAnalysisType(value);
    setDialogState(true);
  }, []);

  return (
    <div id="select-wrapper">
      {availableAnalysisTypes.length > 4 || isXs ? (
        <Select
          labelId="demo-mutiple-name-label"
          id="demo-mutiple-name"
          IconComponent={customKeyDownButton}
          value={props.selectKey}
          style={{
            color: '#fff',
            fontWeight: 600,
            minWidth: '80px',
            position: 'absolute',
            left: BUTTON_MARGIN * 2,
            top: BUTTON_HEIGHT + BUTTON_MARGIN * 3,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            container: () => document.fullscreenElement ?? null,
          }}
          renderValue={(value) =>
            `${getPrettyAnalysisType2D(value as AnalysisType2D)} ${selectedIdLen > 1 ? ` +${selectedIdLen - 1}` : ''}`
          }
        >
          {Object.values(AnalysisType2D)
            .filter((type) => availableAnalysisTypes.includes(type))
            .map((type, index) => {
              return (
                <MenuItem
                  value={type}
                  key={type}
                  style={{
                    padding: 0,
                    minWidth: '190px',
                  }}
                >
                  <LayerSelectorButton
                    key={`layerSelectorBtn-${index}`}
                    partImages={props.partImages}
                    analysisType={type}
                    onSelect={props.onSelect}
                    selectedIds={props.selectedIds}
                    isSelect={true}
                    setDialogOpen={closeDialog}
                  />
                </MenuItem>
              );
            })}
        </Select>
      ) : (
        <AnalysisWrapper>
          {Object.values(AnalysisType2D)
            .filter((type) => availableAnalysisTypes.includes(type))
            .map((type, index) => {
              return (
                <MenuItem
                  value={type}
                  key={type}
                  style={{
                    padding: 0,
                    width: '220px',
                  }}
                >
                  <LayerSelectorButton
                    key={`layerSelectorBtn-${index}`}
                    partImages={props.partImages}
                    analysisType={type}
                    onSelect={props.onSelect}
                    selectedIds={props.selectedIds}
                    isSelect={false}
                    setDialogOpen={closeDialog}
                  />
                </MenuItem>
              );
            })}
        </AnalysisWrapper>
      )}
      <AnalysisSettingsDialog
        open={dialogState}
        setOpenState={setDialogState}
        analysisType={currentAnalysisType}
        analysisTypeState={props.analysisOverlayParams}
        setAnalysisTypes={props.setAnalysisOverlayParams}
        overlayColors={props.overlayColors}
        setOverlayColours={props.setOverlayColours}
        forceUpdate={props.forceUpdate}
      />
    </div>
  );
}

export function LayerSelectorButton(props: LayerSelectionButtonProps) {
  const canDownload = props.analysisType !== AnalysisType2D.ColourMap;

  const handleDownload = async () => {
    if (!canDownload) {
      // Extra check, this shouldn't occur
      toast('Colour map layer cannot be downloaded', {type: 'info'});
      return;
    }

    const typeKey = props.analysisType;
    const prettyAnalysisType = getPrettyAnalysisType2D(props.analysisType);

    const largest = getLargestImage(props.partImages?.[typeKey]?.images || []);

    if (largest) {
      const src = await largest.src;

      // Get download's filename
      const url = new URL(largest.signedUrl!);
      const basename = url.pathname.split('/').pop()!;
      const ext = basename.split('.').pop()!;
      const filename = basename.slice(0, -ext.length - 1);
      const newFilename = `${filename}-${prettyAnalysisType}.${ext}`; // TODO prettyAnalysisType turn to snake_case

      downloadUrl(src, newFilename);
    } else {
      toast(`Layer type "${prettyAnalysisType}" not present on this layer`, {
        type: 'info',
      });
    }
  };

  return (
    <Grid container justifyContent="space-between" onClick={() => props.onSelect(props.analysisType)}>
      <Grid item xs={8}>
        <p
          style={{
            fontWeight: 600,
            margin: 0,
            padding: '6px 0px 6px 10px',
            color: props.selectedIds[props.analysisType] ? '#ff9900' : props.isSelect ? '#333' : '#fff',
          }}
        >
          {getPrettyAnalysisType2D(props.analysisType)}
        </p>
      </Grid>
      <Grid item xs={2}>
        {canDownload && (
          <Tooltip title="Download Full Resolution Images" placement="right">
            <IconButton
              size="small"
              onClick={(event) => {
                handleDownload();
                event.stopPropagation();
              }}
            >
              <CloudDownload
                style={{
                  fontSize: props.isSelect ? '1.2rem' : '1.3rem',
                  color: props.isSelect ? '#333' : '#fff',
                  paddingTop: '3px',
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Change analysis layer settings" placement="right">
          <IconButton
            size="small"
            onClick={(event) => {
              props.setDialogOpen(props.analysisType);
              event.stopPropagation();
            }}
          >
            <Settings
              style={{
                fontSize: props.isSelect ? '1.2rem' : '1.3rem',
                color: props.isSelect ? '#333' : '#fff',
                paddingTop: '3px',
              }}
            />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

function AnalysisSettingsDialog(props: {
  setOpenState: (val: boolean) => void;
  open: boolean;
  analysisType: AnalysisType2D;
  analysisTypeState: AnalysisOverlayParams;
  setAnalysisTypes: (data: AnalysisOverlayParams) => void;
  overlayColors: {
    [AnalysisType2D.LayerMask]: RGBColor;
  };
  setOverlayColours: (analysisType: AnalysisType2D, val: RGBColor) => void;
  forceUpdate: () => void;
}) {
  const {setOpenState, open} = props;
  const field = 'opacity';
  const filterParams = props.analysisTypeState[props.analysisType]['filterParams'];
  const [transparency, setTransparency] = useState(filterParams && filterParams[field] ? filterParams[field] : 1);
  const [colour, setColour] = useState((props.overlayColors as any)[props.analysisType]);

  const handleClose = () => setOpenState(false);

  const handleSave = () => {
    if (Object.keys(props.overlayColors).includes(props.analysisType)) {
      props.setOverlayColours(props.analysisType, colour);

      props.forceUpdate();
    } else {
      props.setAnalysisTypes({
        ...props.analysisTypeState,
        [props.analysisType]: {
          ...props.analysisTypeState[props.analysisType],
          filterParams: {
            ...props.analysisTypeState[props.analysisType]['filterParams'],
            [field]: transparency,
          },
        },
      });
    }

    setOpenState(false);
  };

  useEffect(
    () => {
      setColour((props.overlayColors as any)[props.analysisType]);
      if (Object.keys(props.overlayColors).includes(props.analysisType)) {
        setTransparency((props.overlayColors as any)[props.analysisType].a);
      } else {
        setTransparency(filterParams?.[field] ? filterParams[field] : 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="analysis-dialog-title"
      open={open}
      PaperProps={{
        style: {
          minWidth: '500px',
          overflow: 'unset',
        },
      }}
    >
      <DialogTitle id="analysis-dialog-title" style={{paddingBottom: '0px'}}>
        Analysis type settings
      </DialogTitle>
      <DialogContent style={{paddingTop: '30px'}}>
        <Grid container>
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={3}>
                <Typography id="continuous-slider" gutterBottom align="left" style={{marginTop: '5px'}}>
                  Transparency
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomSlider
                  value={transparency}
                  min={0}
                  step={0.01}
                  max={1}
                  onChange={(_: any, newVal: number | number[]) => {
                    if (typeof newVal === 'number') {
                      if (Object.keys(props.overlayColors).includes(props.analysisType)) {
                        setColour({...colour, a: newVal});
                      }
                      setTransparency(newVal);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {
            /* if selected Severe Defect */
            Object.keys(props.overlayColors).includes(props.analysisType) ? (
              <Grid
                style={{
                  marginLeft: '12px',
                }}
              >
                <ColorPicker
                  color={colour || {r: 0, g: 0, b: 0}}
                  setColor={(color) => {
                    setColour(color);
                    setTransparency(color.a === undefined ? 1 : color.a);
                  }}
                />
              </Grid>
            ) : (
              <></>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
