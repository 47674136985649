import React, {useState} from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';

import {useForm, Controller} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import {IMachine} from '@common/api/models/devices/machines/IMachine';

import {machineCreatePOST} from '../../api/ajax/machines';
import CustomInput from '../../components/atoms/CustomInput';
import CustomButton from '../../components/atoms/CustomButton';
import CustomSelect from '../../components/atoms/CustomSelect';
import RequiredAsterisk from '../../components/atoms/Texts/RequiredAsterisk';
import {MACHINE_DETAILS} from '@common/api/models/devices/machines/Machine.utils';
import Header from '../../components/organisms/Header';

export default function CreateMachinePage() {
  const {
    register,
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<IMachine>({
    mode: 'all',
  });
  const history = useHistory();
  const [selectedManufacturer, setSelectedManufacturer] = useState<string>('');

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async (data: IMachine) => {
    const res = await machineCreatePOST(data);
    if (res.success) {
      history.replace('/machines/uuid/' + res.data.uuid + '/');
    }
  };

  return (
    <React.Fragment>
      <Header
        helmet="Create Machine"
        title="Create Machine"
        breadcrumbs={[{title: 'Machines', path: '/machines/'}, 'Create Machine']}
      />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardHeader title={'Machine'}></CardHeader>
            <CardContent>
              <form id="createMachineForm">
                <Table style={{borderBottom: 'none'}} size={'small'}>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        Machine Name <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.name?.message}
                          error={!!errors.name}
                          inputProps={{
                            name: 'name',
                            inputRef: register({
                              required: 'Please input name',
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Manufacturer <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <Controller
                          render={({...renderProps}) => (
                            <CustomSelect
                              label={
                                <>
                                  Manufacturer <RequiredAsterisk />
                                </>
                              }
                              data={Object.keys(MACHINE_DETAILS)}
                              error={!!errors.manufacturer}
                              inputProps={{
                                ...renderProps,
                                onChange: (...event: any[]) => {
                                  renderProps.onChange(...event);
                                  setSelectedManufacturer(event[0].target.value);
                                },
                                'aria-required': 'true',
                              }}
                            />
                          )}
                          name="manufacturer"
                          control={control}
                          defaultValue=""
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Model <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <Controller
                          render={({...renderProps}) => (
                            <CustomSelect
                              label={
                                <>
                                  Model <RequiredAsterisk />
                                </>
                              }
                              data={Object.keys(MACHINE_DETAILS[selectedManufacturer] || {})}
                              error={!!errors.model}
                              inputProps={{
                                ...renderProps,
                                'aria-required': 'true',
                              }}
                            />
                          )}
                          name="model"
                          control={control}
                          defaultValue=""
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Location
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          inputProps={{
                            name: 'location',
                            inputRef: register,
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Description
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          inputProps={{
                            name: 'description',
                            inputRef: register,
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" variant="head">
                        Commissioned <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <Controller
                          render={({onChange}) => (
                            <TextField
                              type={'date'}
                              defaultValue={moment().format('YYYY-MM-DD')}
                              onChange={(e) => onChange(moment(e.target.value, 'YYYY-MM-DD').toDate())}
                              inputProps={{
                                'aria-required': 'true',
                              }}
                            />
                          )}
                          name="commissioned"
                          control={control}
                          rules={{required: 'Please select a date'}}
                          defaultValue={moment().format('YYYY-MM-DD')}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardContent
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant={'outlined'} onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <CustomButton
                    variant={'contained'}
                    type="submit"
                    form="createMachineForm"
                    color={'primary'}
                    onClick={onSubmit(handleSubmit)}
                  >
                    Create Machine
                  </CustomButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
