import {Matrix4} from 'three';
import {CTReportActions} from '../model/ctReport';
import {ctReportDELETE} from '../../api/ajax/ctReport';
import {ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {LiveStoreActions} from '../model/liveUpdateStore';

const ctReportActions = {
  updateViewportTransform(tf: Matrix4) {
    return async (dispatch: Function) => {
      dispatch({
        type: CTReportActions.UPDATE_VIEWPORT_TRANSFORMS,
        payload: {viewportTransform: tf},
      });
    };
  },

  deleteCtReport(report: ICTReportGETResponse) {
    return async (dispatch: Function) => {
      const response = await ctReportDELETE(report.uuid);

      if (response.success) {
        dispatch({
          type: LiveStoreActions.DELETE_LIST + 'CT_REPORT',
          payload: report,
        });
        return true;
      } else {
        return false;
      }
    };
  },
};

export default ctReportActions;
