import * as React from 'react';
import {Switch, Route} from 'react-router-dom';
import {OrganizationsPage} from '../pages/organization/OrganizationsPage';
import CreateOrganizationPage from '../pages/organization/CreateOrganizationPage';
import {UsersPage} from '../pages/organization/UsersPage';

export function OrganizationRoute() {
  return (
    <Switch>
      <Route path="/users/" exact={true}>
        <UsersPage />
      </Route>
      <Route path="/organizations/" exact={true}>
        <OrganizationsPage />
      </Route>
      <Route path="/organizations/createOrganization/">
        <CreateOrganizationPage />
      </Route>
    </Switch>
  );
}
