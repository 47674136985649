import axios from 'axios';

import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {
  IPartModelAttachmentsGETRequest,
  IPartModelAttachmentGETResponse,
} from '@common/api/models/attachments/IPartModelAttachment';
import {uploadParamType} from '../../pages/builds/shared/SliceAttachmentMultipartUpload';

export async function partModelAttachmentsAllGET(
  filter: QueryFilter<IPartModelAttachmentsGETRequest>
): AjaxApiResult<IPartModelAttachmentGETResponse[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/partModelAttachments/', {params: filter});
  }, 'Could not fetch Part Models');
}

export async function partModelAttachmentByUuidPATCH(uuid: string, rotation: number): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/partModelAttachments/${uuid}`, {rotation});
  }, 'Could not update part model attachment');
}

export async function partModelAttachmentByUuidDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete('/api/partModelAttachments/' + uuid);
  }, 'Could not delete part model attachment');
}

export async function partModelAttachmentDownloadUrlGET(uuid: string): AjaxApiResult<string> {
  return ajaxWrapper(() => {
    return axios.get('/api/partModelAttachments/downloadUrl/', {params: {uuid}});
  }, 'Could not download file');
}

export async function partModelAttachmentMultipartUploadInitiatePOST(
  partUuid: string,
  filename: string,
  filesize: number // bytes
): AjaxApiResult<uploadParamType> {
  return ajaxWrapper(() => {
    return axios.post('/api/sliceAttachments/multipart/initiate', {
      partUuid,
      filename,
      filesize,
    });
  }, 'Could not upload file');
}

export async function partModelAttachmentMultipartUploadCompletePOST(
  uploadId: string,
  partUuid: string,
  key: string,
  parts: {ETag: string; PartNumber: number}[]
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/sliceAttachments/multipart/complete/', {
      uploadId,
      partUuid,
      key,
      parts,
    });
  });
}

export async function partModelAttachmentMultipartUploadAbortPOST(uploadId: string, key: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/sliceAttachments/multipart/abort/', {
      uploadId,
      key,
    });
  }, 'Could not abort upload file');
}
