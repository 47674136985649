import * as React from 'react';
import {Typography} from '@material-ui/core';
import {num4svg} from '../utils';

export interface DistortionScaleProps {
  stageWidth: number;
  mmPerPixel: number;
  // Min/max pixels of distortion present in image
  minDistortion: number;
  maxDistortion: number;
}

const barThickness = 10;

// Maximum possible number of pixels of distortion
const distortionLevels = 20;

export function DistortionScale(props: DistortionScaleProps) {
  const scaleWidthPx = props.stageWidth / 3;
  const scaleLeft = (props.stageWidth - scaleWidthPx) / 2;

  const pathPoints = (scaleWidthPx: number) => {
    return `m 0 0 
            v ${barThickness} 
            h ${num4svg(scaleWidthPx)} 
            v -${barThickness} 
            h -${num4svg(scaleWidthPx)} `;
  };

  const svgViewbox = (scaleWidthPx: number) => {
    return `0 0 ${num4svg(scaleWidthPx)} ${barThickness}`;
  };

  const minDistortionUm = Math.round(props.minDistortion * props.mmPerPixel * 1000);
  const maxDistortionUm = Math.round(props.maxDistortion * props.mmPerPixel * 1000);
  const zeroPosition = props.minDistortion / (props.minDistortion + props.maxDistortion);

  const scaleLabels = [
    {label: `${-minDistortionUm} μm`, offset: 0},
    {
      label: `0 μm`,
      offset: zeroPosition * scaleWidthPx,
    },
    {label: `${maxDistortionUm} μm`, offset: scaleWidthPx},
  ];

  return (
    <div style={{pointerEvents: 'none'}}>
      <svg
        viewBox={svgViewbox(scaleWidthPx)}
        style={{
          width: scaleWidthPx,
          position: 'absolute',
          left: scaleLeft,
          top: barThickness + 15,
        }}
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{
                stopColor: `rgb(0,0,${255 * (props.minDistortion / distortionLevels)})`,
                stopOpacity: 1,
              }}
            />
            <stop
              offset={`${zeroPosition * 100}%`}
              style={{
                stopColor: 'black',
                stopOpacity: 1,
              }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: `rgb(${255 * (props.maxDistortion / distortionLevels)},0,0)`,
                stopOpacity: 1,
              }}
            />
          </linearGradient>
        </defs>
        <path d={pathPoints(scaleWidthPx)} fill="url(#gradient)" stroke="none" />
      </svg>
      {scaleLabels.map((scaleLabel) => (
        <Typography
          style={{
            left: scaleLeft - 100 + scaleLabel.offset,
            width: 200,
            top: barThickness + 30,
            position: 'absolute',
            color: '#FFFFFF',
          }}
          align="center"
        >
          {scaleLabel.label}
        </Typography>
      ))}
    </div>
  );
}
