import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Alert} from '@material-ui/lab';

import {IBuild} from '@common/api/models/builds/IBuild';
import {ICalibration} from '@common/api/models/devices/ICalibration';

import {useBuildStoreActions} from '../../../../../store/actions';
import {RootState} from '../../../../../store/reducers';
import {renderDateTimeString} from '../../../../../utils/string';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

export interface CalibrationDescriptionProps {
  calibration?: ICalibration;
  currentBuild: IBuild;
  isCurrentlyCalibrating: boolean;
  calibrationError?: boolean;
}

export default function CalibrationDescription({
  calibration,
  currentBuild,
  isCurrentlyCalibrating,
  calibrationError,
}: CalibrationDescriptionProps) {
  const buildActions = useBuildStoreActions();
  const calibrationBuild = useSelector((state: RootState) =>
    calibration ? state.buildStore.byId[calibration.buildUuid] : undefined
  );

  useEffect(() => {
    if (!calibration?.buildUuid) return;
    if (!!calibrationBuild) return;

    if (calibration.buildUuid !== currentBuild.uuid) {
      buildActions.ensureConsistent({uuid: calibration.buildUuid}, {uuid: [calibration.buildUuid, currentBuild.uuid]});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calibration, currentBuild!.uuid]);

  const calibrationTime = calibration ? renderDateTimeString('descriptive', calibration.registered) : undefined;

  const failedCalibration = (currentBuild.hasCalibrated && !currentBuild.calibrationUuid) || calibrationError;

  if (failedCalibration && !isCurrentlyCalibrating) {
    return (
      <Alert severity="error" style={{marginBottom: '12px'}}>
        <Typography>
          The most recent calibration failed. Please view{' '}
          <Link to={`/builds/uuid/${currentBuild.uuid}/live/alerts/`}>device logs</Link> for more information.
          <br />
          <br />
          Please re-run calibration or use the last successful calibration via the button below.
        </Typography>
      </Alert>
    );
  }

  if (isCurrentlyCalibrating)
    return (
      <Alert severity="warning" style={{marginBottom: '12px'}}>
        <Typography>Device calibration is currently in progress. Please wait for calibration to finish.</Typography>
      </Alert>
    );

  if (!calibration) {
    return (
      <Alert severity="warning" style={{marginBottom: '12px'}}>
        <Typography>This build has not been calibrated. Please calibrate this build before continuing.</Typography>
      </Alert>
    );
  }

  const scale = (calibration.scale * 1000).toFixed(2);

  let calibrationText = <></>;
  if (!calibrationBuild)
    calibrationText = (
      <>
        Last successful calibration was recorded on <b>{calibrationTime}</b>. This calibration was recorded on a deleted
        build. You may continue, but it is recommended that you recalibrate.
      </>
    );
  else if (calibrationBuild.uuid === currentBuild?.uuid) calibrationText = <>Successfully calibrated for this build!</>;
  else
    calibrationText = (
      <>
        Last successful calibration was recorded on <b>{calibrationTime}</b>. This calibration was recorded on the build{' '}
        <b>{calibrationBuild.name}</b>. You may continue, but it is recommended that you recalibrate.
      </>
    );

  return (
    <Alert
      severity={calibrationBuild && calibrationBuild.uuid === currentBuild?.uuid ? 'success' : 'info'}
      style={{marginBottom: '12px'}}
    >
      {calibrationText}
      <br />
      <br />
      The recorded scale is <b>{scale} μm/pixel</b>.
    </Alert>
  );
}
