import React from 'react';
import {useSelector} from 'react-redux';
import {Grid, Typography, Slider, Box} from '@material-ui/core';
import {RootState} from '../../../store/reducers';
import {CustomToggleButtonGroup} from '../../../components/atoms/Switches/CustomToggle';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {useBuildIntensityReportStoreActions} from '../../../store/actions';
import AntSwitch from '../../../components/atoms/Switches/AntSwitch';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';

export enum GraphDataType {
  Mean = 'mean',
  Median = 'median',
  Min = 'min',
  Max = 'max',
  MaxCount = 'max_count',
  TotalCount = 'total_count',
}

export const GRAPH_DATA_TYPE_TOOLTIPS = {
  Mean: 'Mean intensity values for each layer',
  Median: 'Median intensity values for each layer',
  Min: 'Mininumum intensity value for each layer',
  Max: 'Maximum intensity value for each layer',
  MaxCount: 'The total number of pixels that are equal to the max intensity recorded for each layer',
  TotalCount: 'The total number of pixels which recorded an intensity value in each layer',
  STD: 'Standard deviation of intensity values for each layer',
};

export function IntensityGraphOptionsModal({open, onClose}: {open: boolean; onClose: () => void}) {
  const isXsScreenSize = useExtraSmallScreenSize();
  const currentGraphDataType = useSelector((state: RootState) => state.buildIntensityReportStore.graphDataType);
  const currentGraphDataToggles = useSelector((state: RootState) => state.buildIntensityReportStore.graphDataToggles);
  const rollingPeriod = useSelector((state: RootState) => state.buildIntensityReportStore.rollingPeriod);
  const {setGraphDataType, setGraphDataToggles, setRollingPeriod} = useBuildIntensityReportStoreActions();

  const toggleScatter = () => {
    setGraphDataToggles('showScatter', !currentGraphDataToggles.showScatter);
  };

  const toggleTrendline = () => {
    setGraphDataToggles('showTrendline', !currentGraphDataToggles.showTrendline);
  };

  const toggleSTD = () => {
    setGraphDataToggles('showSTD', !currentGraphDataToggles.showSTD);
  };

  return (
    <GenericDialog
      title="Graph Options"
      isOpen={open}
      closeDialog={onClose}
      maxWidth="sm"
      closeText="Close"
      topAlign
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item style={{display: 'flex', justifyContent: 'center'}}>
            <CustomToggleButtonGroup<GraphDataType>
              values={GraphDataType}
              currentValue={currentGraphDataType}
              updateValue={setGraphDataType}
              orientation={isXsScreenSize ? 'vertical' : 'horizontal'}
              tooltipOverrides={GRAPH_DATA_TYPE_TOOLTIPS}
            />
          </Grid>
          <Grid item style={{marginTop: '1rem'}}>
            <Grid container direction="row" justifyContent="flex-start">
              <Box width="184px">
                <Typography noWrap style={{fontWeight: 'bold'}}>
                  Display Raw Values:
                </Typography>
              </Box>
              <Box width="calc(100% - 184px)">
                <AntSwitch size="small" checked={currentGraphDataToggles.showScatter} onClick={toggleScatter} />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start">
              <Box width="184px">
                <Typography noWrap style={{fontWeight: 'bold'}}>
                  Display Trendline:
                </Typography>
              </Box>
              <ConditionalTooltip
                tooltip="Trendline is always shown when standard deviation is shown."
                hideTooltip={!currentGraphDataToggles.showSTD}
              >
                <Box width="calc(100% - 184px)">
                  <AntSwitch
                    size="small"
                    checked={currentGraphDataToggles.showTrendline}
                    onClick={toggleTrendline}
                    disabled={currentGraphDataToggles.showSTD}
                  />
                </Box>
              </ConditionalTooltip>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start">
              <Box width="184px">
                <Typography noWrap style={{fontWeight: 'bold'}}>
                  Display Standard Deviation:
                </Typography>
              </Box>
              <Box width="calc(100% - 184px)">
                <ConditionalTooltip
                  hideTooltip={currentGraphDataType === GraphDataType.Mean}
                  tooltip="Standard deviation can only be shown when the graph is displaying mean intensity values."
                >
                  <AntSwitch
                    size="small"
                    checked={currentGraphDataToggles.showSTD && currentGraphDataType === GraphDataType.Mean}
                    onClick={toggleSTD}
                    disabled={!(currentGraphDataType === GraphDataType.Mean)}
                  />
                </ConditionalTooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Box width="184px">
                <Typography style={{fontWeight: 'bold'}}>Rolling Period:</Typography>
              </Box>
              <Box width="calc(100% - 184px)">
                <Typography>{rollingPeriod} layers</Typography>
              </Box>
            </Grid>
            <Slider
              value={rollingPeriod}
              step={1}
              min={1}
              max={200}
              onChange={(event: React.ChangeEvent<{}>, value: number | number[]) => {
                setRollingPeriod(value as number);
              }}
              disabled={!(currentGraphDataToggles.showTrendline || currentGraphDataToggles.showSTD)}
            />
          </Grid>
        </Grid>
      }
    ></GenericDialog>
  );
}
