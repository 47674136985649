import {combineValidation, ValidateFn, generateValidationResult} from '../../types';
import {IOrganization} from './IOrganization';

/**
 * Validate the input name for an organization.
 * @param name {string} - Name of the organization to validate
 */
export const validateOrganizationName: ValidateFn<string, string> = function (name) {
  if (name.length < 1) {
    const errorMessage = ['Please enter an organization name'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate all parameters of an organization.
 * @param organization {IOrganization} - Contains information about an organization
 */
export const validateOrganization: ValidateFn<IOrganization, string> = function (organization) {
  return combineValidation(validateOrganizationName(organization.name));
};
