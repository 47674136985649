import React from 'react';
import {Typography} from '@material-ui/core';

import {MaterialTableProps} from 'material-table';

import {CoolLinkedTable} from './CoolTable';
import {IMaterial} from '@common/api/models/materials/IMaterial';
import {renderDateString} from '../../../utils/string';
import {generateColumns} from '../../../utils/table';
import EllipsisTextWithTooltip from '../../atoms/Texts/EllipsisTextWithTooltip';

export interface MaterialsRow {
  name: string;
  type?: string;
  grade: string;
  supplier?: string;
  description: string;
  quantityRemaining: number;
  manufactured: Date | string;
  received?: Date | string;
  uuid: string;
  url: string;
}
interface MaterialsTableProps {
  materials: IMaterial[];
  errorMessage?: string;
  exclude?: (keyof MaterialsRow)[];
  customRender?: {
    [key in keyof MaterialsRow]?: (data: MaterialsRow, type: 'row' | 'group') => any;
  };
  filters?: Partial<MaterialsRow>;
}

export function MaterialsTable(props: MaterialsTableProps & Partial<MaterialTableProps<MaterialsRow>>) {
  const {materials, errorMessage, filters, isLoading, ...rest} = props;
  return (
    <div style={{maxWidth: '100%'}}>
      <CoolLinkedTable
        columns={generateColumns([
          {title: 'Name', field: 'name'},
          {title: 'Type', field: 'type'},
          {title: 'Grade', field: 'grade'},
          {title: 'Supplier', field: 'supplier'},
          {
            title: 'Description',
            field: 'description',
            render: (row: MaterialsRow) => <EllipsisTextWithTooltip>{row.description}</EllipsisTextWithTooltip>,
          },
          {
            title: 'Quantity Remaining',
            field: 'quantityRemaining',
            type: 'numeric',
            render: (row: MaterialsRow) => `${row.quantityRemaining} kg`,
          },
          {
            title: 'Manufactured on',
            field: 'manufactured',
            type: 'date',
            emptyValue: '',
            render: (row: MaterialsRow) => <Typography>{renderDateString('short', row.manufactured)}</Typography>,
          },
          {
            title: 'Received on',
            field: 'received',
            type: 'date',
            defaultSort: 'desc',
            emptyValue: '',
            render: (row: MaterialsRow) => <Typography>{renderDateString('short', row.received)}</Typography>,
          },
        ])}
        data={materials.map(
          (material): MaterialsRow => ({
            name: material.name,
            type: material.type,
            grade: material.grade,
            supplier: material.supplier,

            // TODO: truncate long descriptions.
            description: material.description,
            quantityRemaining: material.quantityRemaining,
            manufactured: material.manufactured,
            received: material.dateReceived,
            uuid: material.uuid,
            url: `/materials/uuid/${material.uuid}`,
          })
        )}
        filters={filters}
        errorMessage={errorMessage}
        isLoading={isLoading}
        {...rest}
      />
    </div>
  );
}
