import React from 'react';
import {Chip, Tooltip, Typography} from '@material-ui/core';

export default function SourcePartChips({sources}: {sources?: string[]}) {
  if (!sources) return <></>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '8px',
      }}
    >
      {sources.map((source) => {
        return (
          <Tooltip title={<Typography>{source}</Typography>}>
            <Chip label={source} color="primary" size="small" style={{maxWidth: '100%'}} />
          </Tooltip>
        );
      })}
    </div>
  );
}
