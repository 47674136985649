import * as React from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';

import {IMaterial} from '@common/api/models/materials/IMaterial';
import Widget from '../../atoms/Widgets/Widget';
import BarChart from '../../atoms/Charts/BarChart';

interface MaterialSupplyWidgetProps {
  material: IMaterial;
  raised?: boolean;
  elevation?: number;
}

const ChartWrapper = styled.div`
  height: 150px;
  position: relative;
  width: ;
`;

export function MaterialSupplyWidget(props: MaterialSupplyWidgetProps) {
  const quantityRemainingAmount = props.material.quantityRemaining.toFixed(2);
  const quantityReceivedAmount = props.material.quantityReceived.toFixed(2);

  const barChartData: Partial<Plotly.PlotData>[] = [
    {
      name: 'Remaining',
      x: [''],
      y: [props.material.quantityRemaining],
      marker: {color: ['rgba(76,175,80,0.5)']},
      hoverinfo: 'y+name',
    },
    {
      name: 'Used',
      x: [''],
      y: [props.material.quantityReceived - props.material.quantityRemaining],
      marker: {color: ['rgba(153,153,153,0.5)']},
      hoverinfo: 'y+name',
    },
  ];

  const barChartLayout: Partial<Plotly.Layout> = {
    yaxis: {
      tick0: 0,
      showticksuffix: 'all',
      ticksuffix: ' kg',
      automargin: true,
    },
  };

  return (
    <Widget
      title={`Supply for "${props.material.name}"`}
      hideSettings
      cardProps={{raised: props.raised, elevation: props.elevation}}
    >
      <ChartWrapper>
        <BarChart
          data={barChartData}
          height={150}
          barmode={'stack'}
          margin={{t: 0, b: 20, l: 40, r: 40}}
          layout={barChartLayout}
        />
      </ChartWrapper>
      <Typography>{`${quantityRemainingAmount} of ${quantityReceivedAmount} kg remaining`}</Typography>
    </Widget>
  );
}
