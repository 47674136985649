import {
  ILocationBasedDefectsReportGETResponse,
  ILocationBasedDefectsReportUrlsResponse,
} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {LiveStoreState, createInitialLiveStore} from './liveUpdateStore';

export enum LocationBasedDefectsReportActions {
  LOCATION_BASED_DEFECTS_REPORT_FETCH_IMAGES_SUCCESS = 'LOCATION_BASED_DEFECTS_REPORT_FETCH_IMAGES_SUCCESS',
}

export type LocationBasedDefectsMetadata = {
  [type: string]: {
    z: {
      'non-normalised': number;
      normalised: number;
    };
    x: {
      'non-normalised': number;
      normalised: number;
    };
    y: {
      'non-normalised': number;
      normalised: number;
    };
  };
};

export interface LocationBasedDefectsReportStoreState extends LiveStoreState<ILocationBasedDefectsReportGETResponse> {
  images: {[uuid: string]: ILocationBasedDefectsReportUrlsResponse[]};
  metadata: {[uuid: string]: LocationBasedDefectsMetadata | undefined};
}

export const initialLocationBasedDefectsReportStore: LocationBasedDefectsReportStoreState = {
  ...createInitialLiveStore(),
  images: {},
  metadata: {},
};

interface LocationBasedDefectsReportActionType<_T, P> {
  type: LocationBasedDefectsReportActions;
  payload: P;
}

export type LocationBasedDefectsReportFetchImagesAction = LocationBasedDefectsReportActionType<
  LocationBasedDefectsReportActions.LOCATION_BASED_DEFECTS_REPORT_FETCH_IMAGES_SUCCESS,
  {images: ILocationBasedDefectsReportUrlsResponse[]; uuid: string; metadata: undefined | LocationBasedDefectsMetadata}
>;
