import axios from 'axios';
import * as qs from 'qs';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {IDefectSummaryGETResponse} from '@common/api/models/builds/data/defects/IDefectSummary';

export async function defectSummaryGET(
  filter: QueryFilter<IDefectSummaryGETResponse>
): AjaxApiResult<IDefectSummaryGETResponse[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/defectSummary/' + (query && '?' + query));
  }, 'Could not get defect summary data');
}
