import React, {useState} from 'react';
import styled from 'styled-components';
import {KeyboardArrowDown} from '@material-ui/icons';
import {Tooltip} from '@material-ui/core';
import {CustomSelect} from '../../FadingComponents';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../utils';

export const colourMapOptions: string[] = ['turbo', 'jet', 'hsv', 'hot', 'cool', 'rainbow', 'spring', 'inferno'];
export const colourMapOptionsWithRaw: string[] = ['raw', ...colourMapOptions];

const customKeyboardArrowDown = styled(KeyboardArrowDown)`
  color: #fff;
`;

interface CmSelectorProps {
  mouseOn: boolean;
  stageHeight: number;
  currentColourMap: string;
  setColourMap: React.Dispatch<React.SetStateAction<string>> | ((colourMap: string) => void);
  isLocationBasedDefects?: boolean;
}

export default function CmSelector({
  mouseOn,
  stageHeight,
  currentColourMap,
  setColourMap,
  isLocationBasedDefects,
}: CmSelectorProps) {
  const [tooltipStatus, setTooltipStatus] = useState<boolean>(false);

  return (
    <Tooltip title="Select Colormap Type" placement="left" open={tooltipStatus}>
      <CustomSelect
        className={(!mouseOn && 'fade') || undefined}
        style={{
          left: BUTTON_MARGIN * 2,
          top: stageHeight - (isLocationBasedDefects ? BUTTON_HEIGHT * 1.8 : BUTTON_HEIGHT * 2.3),
          position: 'absolute',
          color: '#fff',
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          container: () => document.fullscreenElement ?? null,
        }}
        IconComponent={customKeyboardArrowDown}
        value={currentColourMap}
        onMouseOver={() => {
          setTooltipStatus(true);
        }}
        onMouseLeave={() => {
          setTooltipStatus(false);
        }}
        onChange={(e: React.ChangeEvent<{name?: string | undefined; value: unknown}>) => {
          setColourMap(e.target.value as string);
          setTooltipStatus(false);
        }}
        onClose={() => {
          setTooltipStatus(false);
        }}
      >
        {(isLocationBasedDefects ? colourMapOptionsWithRaw : colourMapOptions).map((val) => {
          const displayText = val.charAt(0).toUpperCase() + val.slice(1);
          return (
            <option value={val} style={{padding: '5px 10px', cursor: 'pointer'}} key={val}>
              {displayText}
            </option>
          );
        })}
      </CustomSelect>
    </Tooltip>
  );
}
