import React from 'react';
import {Button} from '@material-ui/core';
import {Add} from '@material-ui/icons';

import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {RootState} from '../../store/reducers';
import IndexPage from '../shared/IndexPage/IndexPage';

const AddMaterialButton = () => {
  const history = useHistory();
  return (
    <Button variant="contained" color="primary" onClick={() => history.push('/materials/create/')}>
      <Add />
      Add Material
    </Button>
  );
};

export function MaterialsPage() {
  const useMaterialData = () => {
    const materialStore = useSelector((state: RootState) => state.materialStore.list);
    return materialStore.map((material) => ({
      onClickUrl: `/materials/uuid/${material.uuid}`,
      ...material,
    }));
  };

  return (
    <IndexPage
      useData={useMaterialData}
      CreateResourceComponent={AddMaterialButton}
      resourceType="material"
      title="Materials"
    />
  );
}
