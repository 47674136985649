import React, {useMemo} from 'react';
import filesize from 'filesize';
import inflection, {inflect} from 'inflection';
import {Box, Checkbox, Typography} from '@material-ui/core';
import {TreeItem, TreeView} from '@material-ui/lab';
import {ChevronRight, ExpandMore} from '@material-ui/icons';
import {BuildDataArchiveFile} from '@common/api/models/builds/data/IBuildDataArchive';
import {renderDateString} from '../../../../utils/string';
import EllipsisTextWithTooltip from '../../../atoms/Texts/EllipsisTextWithTooltip';
import styled from 'styled-components';

type ArchiveTypes =
  | 'reduced'
  | 'raw'
  | 'spatter'
  | 'hot_cold_spots'
  | 'severe_defect'
  | 'recoater_streaks'
  | 'scanlines'
  | 'distortion';

interface DownloadDataModalProps {
  files: Array<BuildDataArchiveFile>;
  createdAt: Date;
  selectedDownloadItems: Array<BuildDataArchiveFile>;
  setSelectedDownloadItems: (downloadItems: Array<BuildDataArchiveFile>) => void;
}

const Container = styled(Box)`
  .MuiTreeItem-label {
    overflow: hidden;
  }
`;

const transformFilenameKey: (filename: string) => ArchiveTypes = (filename: string) => {
  const transformedName = filename.toLowerCase().replace(/-/g, '_');
  if (transformedName.includes('layers')) {
    if (transformedName.includes('8bit')) {
      return 'reduced';
    } else {
      return 'raw';
    }
  }

  return transformedName as ArchiveTypes;
};

const getFilesize = (files: BuildDataArchiveFile[]) => {
  return files.reduce((sum, file) => sum + file.size, 0);
};

const DownloadDataModal = ({
  files,
  createdAt,
  selectedDownloadItems,
  setSelectedDownloadItems,
}: DownloadDataModalProps) => {
  const groupedFiles = useMemo(() => {
    const tmpDict: {
      [key in ArchiveTypes]: BuildDataArchiveFile[];
    } = {
      raw: [],
      reduced: [],
      severe_defect: [],
      spatter: [],
      hot_cold_spots: [],
      recoater_streaks: [],
      scanlines: [],
      distortion: [],
    };

    return files
      .sort((a, b) => a.filename.localeCompare(b.filename, 'en', {numeric: true}))
      .reduce((dict, file) => {
        const name = file.filename.split('_')[0];
        if (name) {
          const key = transformFilenameKey(name.replace('/', ''));
          if (key in dict) {
            dict[key].push(file);
          }
        }
        return dict;
      }, tmpDict);
  }, [files]);

  const toggleChecked = (checked: boolean, files: BuildDataArchiveFile[]) => {
    const filenames = files.map((file) => file.filename);
    const currentlySelectedWithoutToggled = selectedDownloadItems.filter(
      (selected) => !filenames.includes(selected.filename)
    );
    if (!checked) {
      setSelectedDownloadItems(currentlySelectedWithoutToggled);
    } else {
      currentlySelectedWithoutToggled.push(...files);
      setSelectedDownloadItems(currentlySelectedWithoutToggled);
    }
  };

  const layers = {raw: groupedFiles.raw, reduced: groupedFiles.reduced};
  const analysis = {
    severe_defect: groupedFiles.severe_defect,
    spatter: groupedFiles.spatter,
    hot_cold_spots: groupedFiles.hot_cold_spots,
    // Not yet implemented on analysis
    recoater_streaks: groupedFiles.recoater_streaks,
    scanlines: groupedFiles.scanlines,
    distortion: groupedFiles.distortion,
  };
  const allLayers = [...layers.raw, ...layers.reduced];
  const allAnalysis = [
    ...analysis.severe_defect,
    ...analysis.spatter,
    ...analysis.hot_cold_spots,
    ...analysis.recoater_streaks,
    ...analysis.scanlines,
    ...analysis.distortion,
  ];

  const noData = !allLayers.length && !allAnalysis.length;

  const selectedDownloadSize = [...allLayers, ...allAnalysis].reduce((sum, file) => {
    if (selectedDownloadItems.find((selected) => selected.filename === file.filename)) {
      sum += file.size;
    }

    return sum;
  }, 0);

  return (
    <Container display="flex" flexDirection="column" style={{width: 'min(calc(100vw - 116px), 450px)'}}>
      {!!noData && <Typography>No data available to download</Typography>}
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        key={`build-data-download-files`}
      >
        {!!allLayers.length && (
          <RootDirectory
            rootName="Layers"
            filesDict={layers}
            allFiles={allLayers}
            selectedDownloadItems={selectedDownloadItems}
            toggleChecked={toggleChecked}
          />
        )}
        {!!allAnalysis.length && (
          <RootDirectory
            rootName="Analysis"
            filesDict={analysis}
            allFiles={allAnalysis}
            selectedDownloadItems={selectedDownloadItems}
            toggleChecked={toggleChecked}
          />
        )}
      </TreeView>

      <Typography style={{paddingTop: '12px'}}>
        <b>Compression date: {renderDateString('short', createdAt)}</b>
        {!!selectedDownloadItems.length && (
          <>
            <br />
            <br />
            <b>
              Selected downloads: {selectedDownloadItems.length} {inflect('zip file', selectedDownloadItems.length)} (
              {filesize(selectedDownloadSize)})
            </b>
          </>
        )}
      </Typography>
    </Container>
  );
};

export default DownloadDataModal;

const RootDirectory = ({
  rootName,
  filesDict,
  allFiles,
  selectedDownloadItems,
  toggleChecked,
}: {
  rootName: string;
  filesDict: {[key: string]: BuildDataArchiveFile[]};
  allFiles: BuildDataArchiveFile[];
  selectedDownloadItems: BuildDataArchiveFile[];
  toggleChecked: (checked: boolean, files: BuildDataArchiveFile[]) => void;
}) => {
  return (
    <TreeItem
      nodeId={rootName}
      key={rootName}
      label={
        <RowLabelCheckbox
          filename={rootName}
          size={getFilesize(allFiles)}
          checked={
            !!allFiles.length &&
            allFiles.every((layer) => selectedDownloadItems.find((selected) => selected.filename === layer.filename))
          }
          onToggle={(checked) => toggleChecked(checked, allFiles)}
        />
      }
    >
      {Object.entries(filesDict).map(([directoryName, files]) => {
        if (!files.length) return <React.Fragment key={`child-dir-${directoryName}`} />;

        return (
          <ChildDirectory
            key={`child-dir-${directoryName}`}
            rootName={rootName}
            directoryName={inflection.humanize(directoryName)}
            files={files}
            toggleChecked={toggleChecked}
            selectedDownloadItems={selectedDownloadItems}
          />
        );
      })}
    </TreeItem>
  );
};

const ChildDirectory = ({
  rootName,
  files,
  directoryName,
  selectedDownloadItems,
  toggleChecked,
}: {
  rootName: string;
  files: BuildDataArchiveFile[];
  directoryName: string;
  selectedDownloadItems: BuildDataArchiveFile[];
  toggleChecked: (checked: boolean, files: BuildDataArchiveFile[]) => void;
}) => {
  return (
    <TreeItem
      nodeId={`${rootName}-${directoryName}`}
      key={`${rootName}-${directoryName}`}
      label={
        <RowLabelCheckbox
          filename={directoryName}
          size={getFilesize(files)}
          checked={
            !!files.length &&
            !!files.every((layer) => selectedDownloadItems.find((selected) => selected.filename === layer.filename))
          }
          onToggle={(checked) => toggleChecked(checked, files)}
        />
      }
    >
      {files.map((file) => (
        <TreeItem
          nodeId={`${rootName}-leaf-${file.filename}`}
          key={`${rootName}-leaf-${file.filename}`}
          label={
            <RowLabelCheckbox
              filename={file.filename}
              size={getFilesize([file])}
              checked={!!selectedDownloadItems.find((selected) => selected.filename === file.filename)}
              onToggle={(checked) => toggleChecked(checked, [file])}
            />
          }
        />
      ))}
    </TreeItem>
  );
};

const RowLabelCheckbox = ({
  filename,
  size,
  checked,
  onToggle,
}: {
  filename: string;
  size: number;
  checked: boolean;
  onToggle: (checked: boolean) => void;
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center" style={{overflow: 'hidden'}}>
        <Checkbox
          checked={checked}
          onClick={(e) => {
            e.stopPropagation();
            onToggle(!checked);
          }}
        />
        <EllipsisTextWithTooltip style={{paddingLeft: '8px', direction: 'rtl'}}>
          {filename.replace('/', '')}
        </EllipsisTextWithTooltip>
      </Box>

      <Typography style={{paddingLeft: '12px', whiteSpace: 'nowrap'}}>{filesize(size)}</Typography>
    </Box>
  );
};
