import React, {useEffect, useState} from 'react';
import {sortBy} from 'lodash';
import {Box, Grid, Tabs as MuiTabs, Tab, AppBar, CircularProgress} from '@material-ui/core';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {IBuild} from '@common/api/models/builds/IBuild';
import {Role} from '@common/api/models/users/IUser';
import {isCalibrating, DeviceState, isFocusing} from '@common/api/models/devices/IDevice';
import {OrderDirection} from '@common/utils/ordering/ordering';

import OfflineDeviceAlert from '../../../components/molecules/OfflineDeviceAlert';
import {RootState} from '../../../store/reducers';
import {useCalibrationStoreActions, useDeviceStoreActions, useMachineStoreActions} from '../../../store/actions';
import LiveBuildSettingsButton from './shared/LiveBuildSettingsButton';
import RevertToDraftButton from './stagingBuildSteps/RevertToDraftButton';
import {SideBarWebcamFeed} from './stagingBuildSteps/SideBarWebcamFeed';
import {SliceFilesCard} from '../shared/SliceFilesCard';
import {SideBarTargetMachine} from './stagingBuildSteps/SideBarTargetMachine';
import {ActiveStepFOCUS} from './stagingBuildSteps/ActiveStepFOCUS';
import {ActiveStepCALIBRATE} from './stagingBuildSteps/ActiveStepCALIBRATE';
import {ActiveStepOVERVIEW} from './stagingBuildSteps/ActiveStepOVERVIEW';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {getSettingsQueryString, StagingStepComponent, LiveStepState} from './index';
import StagingBuildBottomToolbar from './stagingBuildSteps/StagingBuildBottomToolbar';
import {useSmallScreenSize, usePermissionsForBuild} from '../../../utils/utilHooks';
import SideBarUserAccess from './stagingBuildSteps/SideBarUserAccess';
import NoPermissionForDevice from '../../../components/molecules/NoPermissionForDevice';
import LoadingPage from '../../../components/organisms/LoadingPage';
import {warningColor} from '../../../assets/jss/material-dashboard-react';
import Header from '../../../components/organisms/Header';
import BuildHeader from '../shared/BuildHeader';
import {DeviceAlerts} from './shared/DeviceAlerts';

export interface StagingBuildProps {
  build: IBuild;
}

interface LiveStep {
  id: LiveStepState;
  title: string;
  mobileTitle: string;
  description: string;
  component: StagingStepComponent;
}

//! Material Operations Step was deleted in this PR: https://github.com/addassure/webapp/pull/848
const liveSteps: LiveStep[] = [
  {
    id: LiveStepState.OVERVIEW,
    title: 'Overview',
    mobileTitle: 'Overview',
    description: 'Review and monitor the build',
    component: ActiveStepOVERVIEW,
  },
  {
    id: LiveStepState.FOCUS,
    title: 'Device Focusing',
    mobileTitle: 'Focusing',
    description: 'Focus the device',
    component: ActiveStepFOCUS,
  },
  {
    id: LiveStepState.CALIBRATE,
    title: 'Device Calibration',
    mobileTitle: 'Calibration',
    description: 'Calibrate the device',
    component: ActiveStepCALIBRATE,
  },
  {
    id: LiveStepState.ALERTS,
    title: 'Device Alerts',
    mobileTitle: 'Alerts',
    description: 'View device alerts',
    component: DeviceAlerts,
  },
];

export default function StagingBuild(props: StagingBuildProps) {
  const {build} = props;
  const {liveStep} = useParams<{liveStep: LiveStepState}>();
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();
  const deviceActions = useDeviceStoreActions();
  const machineStoreActions = useMachineStoreActions();
  const calibrationActions = useCalibrationStoreActions();
  const {machinePermission} = usePermissionsForBuild(props.build);
  const [previewError, setPreviewError] = useState(false);
  const [focusingError, setFocusingError] = useState(false);
  const [calibrationError, setCalibrationError] = useState<boolean | undefined>(false);
  const [waitingForCalibration, setWaitingForCalibration] = useState(false);
  const [waitingForCalibrationToStop, setWaitingForCalibrationToStop] = useState(false);
  const [waitingForFocus, setWaitingForFocus] = useState(false);
  const [waitingForPreview, setWaitingForPreview] = useState(false);

  const currentUser = useSelector((s: RootState) => s.auth.user!);
  const deviceStore = useSelector((s: RootState) => s.deviceStore);
  const device = deviceStore.byMachineUuid[build.machineUuid!];
  const latestCalibration = useSelector(
    (state: RootState) =>
      sortBy(
        Object.values(state.calibrationStore.byId).filter((calib) => calib.deviceSerial === build.deviceSerial),
        ['registered']
      ).reverse()[0]
  );

  const hasCalibrated = build.hasCalibrated && !!latestCalibration && build.calibrationUuid === latestCalibration.uuid;

  useEffect(() => {
    if (build.deviceSerial && !hasCalibrated) {
      calibrationActions.ensureConsistent(
        {
          deviceSerial: build.deviceSerial,
          sortBy: {registered: OrderDirection.DESC},
          take: 1,
        },
        {
          deviceSerial: build.deviceSerial,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.deviceSerial, hasCalibrated]);

  useEffect(() => {
    if (build.deviceSerial) {
      deviceActions.ensureConsistent({serial: build.deviceSerial});
    }
    if (build.machineUuid) {
      machineStoreActions.ensureConsistent({uuid: build.machineUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.machineUuid, build.deviceSerial]);

  useEffect(() => {
    setWaitingForCalibration(false);
    setWaitingForCalibrationToStop(false);
  }, [device?.state]);

  if (!liveStep || !Object.values(LiveStepState).includes(liveStep)) {
    history.replace(`/builds/uuid/${build.uuid}/live/${LiveStepState.OVERVIEW}/`);
    return <LoadingPage loadingText="Loading Build..." />;
  }
  if (!device) return <LoadingPage loadingText="Loading Build..." />;

  const isCurrentlyCalibrating =
    waitingForCalibration || isCalibrating(device.stateDetailed) || device.state === DeviceState.CALIBRATING;

  const isCurrentlyFocusing =
    waitingForFocus ||
    waitingForPreview ||
    isFocusing(device.stateDetailed) ||
    device.state === DeviceState.FOCUSING ||
    device.state === DeviceState.PREVIEWING;

  const activeStep = liveSteps.find((step) => step.id === liveStep)!;

  return (
    <>
      <Header
        helmet={`Staging Build - ${props.build.name}`}
        title={<BuildHeader build={props.build} />}
        breadcrumbs={[
          {title: 'Builds', path: '/builds'},
          {title: props.build.name, path: `/builds/uuid/${props.build.uuid}`},
          activeStep.title,
        ]}
        endAdornment={
          isSmallScreen ? undefined : (
            <Grid container spacing={2} wrap="nowrap">
              <RevertToDraftButton build={build} />
              <LiveBuildSettingsButton
                buildUuid={build.uuid}
                queryString={`?defaultCameraMode=${getSettingsQueryString(activeStep.id, device?.state)}`}
              />
            </Grid>
          )
        }
      />

      {isSmallScreen && (
        <Box my={3}>
          <RevertToDraftButton build={build} fullWidthButton />
        </Box>
      )}

      {!machinePermission && (
        <NoPermissionForDevice initiallyExpanded>
          Device cannot be focused, calibrated or put into monitoring without permission to access this build's machine.
        </NoPermissionForDevice>
      )}
      {!!machinePermission &&
        ((device && !device.online) || (deviceStore.fetched === FetchingState.Fetched && !device)) && (
          <OfflineDeviceAlert lastActivityTime={device?.lastActivityTime} mb={2}>
            Device cannot be focused, calibrated or put into monitoring until it comes online.
          </OfflineDeviceAlert>
        )}

      <Grid container spacing={isSmallScreen ? 3 : 6}>
        <Grid item xs={12} sm={12} md={8}>
          <AppBar position="static" color="secondary">
            <Tabs
              value={liveSteps.findIndex((step) => step.id === liveStep)!}
              onChange={(_e, tab) => {
                history.replace(`/builds/uuid/${build.uuid}/live/${liveSteps[tab].id}/`);
              }}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons="auto"
            >
              {liveSteps.map((step) => {
                const showSpinner =
                  (step.id === LiveStepState.FOCUS && isCurrentlyFocusing) ||
                  (step.id === LiveStepState.CALIBRATE && isCurrentlyCalibrating);

                return (
                  <Tab
                    id={'tab-' + step.id}
                    label={
                      <Box display="flex">
                        {isSmallScreen ? step.mobileTitle : step.title}
                        {showSpinner ? (
                          <CircularProgress size={20} style={{marginLeft: '8px', color: warningColor[0]}} />
                        ) : (
                          <></>
                        )}
                      </Box>
                    }
                  />
                );
              })}
            </Tabs>
          </AppBar>
          <activeStep.component
            build={build}
            hasCalibrated={hasCalibrated}
            latestCalibration={latestCalibration}
            waitingForCalibration={waitingForCalibration}
            waitingForCalibrationToStop={waitingForCalibrationToStop}
            isCurrentlyCalibrating={isCurrentlyCalibrating}
            setWaitingForCalibration={setWaitingForCalibration}
            setWaitingForCalibrationToStop={setWaitingForCalibrationToStop}
            waitingForFocus={waitingForFocus}
            setWaitingForFocus={setWaitingForFocus}
            waitingForPreview={waitingForPreview}
            setWaitingForPreview={setWaitingForPreview}
            isCurrentlyFocusing={isCurrentlyFocusing}
            previewError={previewError}
            setPreviewError={setPreviewError}
            focusingError={focusingError}
            setFocusingError={setFocusingError}
            calibrationError={calibrationError}
            setCalibrationError={setCalibrationError}
          />
        </Grid>

        {!isSmallScreen && (
          <Grid item sm={12} md={4}>
            <Grid container spacing={6}>
              <SideBarWebcamFeed webcamHosts={device?.webcamHosts} />
              {(currentUser.role >= Role.MANAGER || build.provisionerUuid === currentUser.uuid) && (
                <SideBarUserAccess build={build} />
              )}
              <SliceFilesCard buildUuid={build.uuid} orgUuid={build.organizationUuid} />
              <SideBarTargetMachine machineUuid={build.machineUuid!} />
            </Grid>
          </Grid>
        )}
      </Grid>
      {isSmallScreen && <StagingBuildBottomToolbar />}
    </>
  );
}

const Tabs = styled(MuiTabs)`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: #000000;
`;
