import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {IMaterial, IMaterialPatch, IMaterialPost} from '@common/api/models/materials/IMaterial';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function materialsAllGET(filter: QueryFilter<IMaterial>): AjaxApiResult<IMaterial[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/materials/' + (query && '?' + query));
  }, 'Could not fetch materials');
}

export async function materialCreatePOST(material: Partial<IMaterialPost>): AjaxApiResult<IMaterial> {
  return ajaxWrapper(() => {
    return axios.post('/api/materials/', material);
  }, 'Could not create material');
}

export async function materialsByUuidGET(uuid: string): AjaxApiResult<IMaterial> {
  return ajaxWrapper(() => {
    return axios.get(`/api/materials/${uuid}`);
  }, 'Could not fetch material');
}

export async function materialsByUuidPATCH(uuid: string, updates: IMaterialPatch): AjaxApiResult<IMaterial> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/materials/${uuid}`, updates);
  }, 'Could not update material');
}
