import React, {useCallback, useState, Dispatch, SetStateAction} from 'react';
import {CircularProgress, TextField, Typography, Box} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {debounce} from 'lodash';

type SetSingle<T> = ((resource: T | undefined) => void) | Dispatch<SetStateAction<T | undefined>>;

type SetMulti<T> = ((resource: T[] | undefined) => void) | Dispatch<SetStateAction<T[] | undefined>>;

function SearchAndSelect<T>({
  selected,
  setSelected,
  fetchFunction,
  getSuggestionValue,
  isSelected,
  label,
  disableClearable,
  disabled,
  overrideLabel,
  renderOptionSecondary,
  disableCloseOnSelect = false,
  fullWidth,
}: {
  selected: T | T[] | undefined;
  setSelected: SetSingle<T> | SetMulti<T>;
  fetchFunction: ((search: string) => Promise<T[]>) | ((search: string) => Promise<T>);
  getSuggestionValue: (resource: T, isRender?: boolean) => string;
  isSelected: (option: T) => boolean;
  label: string;
  disableClearable?: boolean;
  disabled?: boolean;
  overrideLabel?: boolean;
  renderOptionSecondary?: (resource: T) => JSX.Element;
  disableCloseOnSelect?: boolean;
  fullWidth?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<T>>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(async (inputValue) => {
      const val = inputValue.trim();
      const newOptions = await fetchFunction(val);
      setOptions(newOptions as Array<any>);
      setLoading(false);
    }, 500),
    [fetchFunction]
  );

  const renderOption = (resource: T, {inputValue}: any) => {
    const label = getSuggestionValue(resource, false);
    const matches = match(label, inputValue);
    const parts = parse(label, matches);

    return (
      <Box display="flex" alignItems="center" width="100%">
        <Typography>
          {parts.map((part, index) => {
            const fontWeightValue = part.highlight ? 700 : 300;
            return (
              <span key={part.text + String(index)} style={{fontWeight: fontWeightValue}}>
                {part.text}
              </span>
            );
          })}
        </Typography>
        {renderOptionSecondary && renderOptionSecondary(resource)}
      </Box>
    );
  };

  return (
    <Autocomplete
      fullWidth={fullWidth}
      id={`search-and-select-${label}`}
      open={open}
      multiple={Array.isArray(selected)}
      size="small"
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      getOptionSelected={(option) => isSelected(option)}
      getOptionLabel={getSuggestionValue}
      options={options}
      loading={loading}
      value={selected || null}
      onChange={(_event: any, option: any) => setSelected(option)}
      renderOption={renderOption}
      onOpen={() => {
        setOpen(true);
        handleSearch('');
        setLoading(true);
      }}
      onClose={(_e, reason) => {
        if (reason === 'blur') return;
        setOpen(false);
        setLoading(false);
        setOptions([]);
      }}
      onInputChange={(_e, value) => {
        handleSearch(value);
        setLoading(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={overrideLabel ? label : `Search & Select ${label}...`}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchAndSelect;
