import React, {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button as MuiButton,
} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {Close, Delete} from '@material-ui/icons';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {IBuild} from '@common/api/models/builds/IBuild';
import {deleteBuildDELETE} from '../../../api/ajax/builds';
import CustomButton from '../../../components/atoms/CustomButton';
import {useDraftBuild} from './DraftBuildContext';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {useBuildStoreActions} from '../../../store/actions/index';
import {DangerButton} from '../../../components/molecules/DialogButton';

const Button = styled(MuiButton)(spacing);

const DeleteBuildButton = () => {
  const history = useHistory();
  const {draftBuild} = useDraftBuild();
  const isSmallScreen = useSmallScreenSize();
  const [deleting, setDeleting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const buildActions = useBuildStoreActions();

  const setDeleteModalOpen = () => setIsDeleteModalOpen(true);
  const handleCancel = () => setIsDeleteModalOpen(false);

  const handleDeleteBuildConfirmed = async () => {
    setIsDeleteModalOpen(false);

    setDeleting(true);
    const res = await deleteBuildDELETE(draftBuild.uuid);
    if (res.success) {
      buildActions.unsubscribeFromList();
      history.push('/builds/');
    } else {
      setDeleting(false);
    }
  };

  return (
    <Grid item>
      <DangerButton
        text="Delete Build"
        loading={deleting}
        onClick={setDeleteModalOpen}
        variant="contained"
        startIcon={<Delete />}
        fullWidth={isSmallScreen}
      />
      <DeleteDraftBuildDialog
        isOpen={isDeleteModalOpen}
        build={draftBuild}
        closeDialog={handleCancel}
        onSuccess={handleDeleteBuildConfirmed}
      />
    </Grid>
  );
};

export default DeleteBuildButton;

interface DeleteDraftBuildDialogProps {
  isOpen: boolean;
  build: IBuild;
  closeDialog: () => void;
  onSuccess: () => void;
}

function DeleteDraftBuildDialog({isOpen, closeDialog, onSuccess, build}: DeleteDraftBuildDialogProps) {
  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={closeDialog}>
      <DialogTitle id="confirmation-dialog-title">Delete Build</DialogTitle>
      <DialogContent dividers>
        <form id="deleteDraftBuild">
          <Typography>Are you sure you want to delete the build "{build.name}"?</Typography>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDialog} color="primary">
          Cancel
        </Button>
        <CustomButton
          onClick={onSuccess}
          type="submit"
          form="deleteDraftBuild"
          variant="contained"
          color="primary"
          startIcon={<Close />}
          text="Delete"
        />
      </DialogActions>
    </Dialog>
  );
}
