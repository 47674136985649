import * as React from 'react';
import {Typography, Card, CardContent, CardHeader} from '@material-ui/core';
import styled from 'styled-components';
import useMachine from '../hooks/useMachine';

const Centered = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  width: 140px;

  p:first-child {
    width: 70px;
    text-align: right;
  }
  p:last-child {
    width: 58px;
    text-align: left;
    margin-left: 12px;
  }
`;

export default function AttachedDevice() {
  const {device} = useMachine();

  return (
    <Card>
      <CardHeader title={'Attached device'} />
      <CardContent>
        <Centered>
          {device ? (
            <>
              <Typography variant="subtitle2">{device.deviceId}</Typography>
              <Row>
                <Typography variant="body1" style={{width: '70px'}}>
                  Software:
                </Typography>
                <Typography variant="body1">{device.swVer ? `v${device.swVer}` : 'Unavailable'}</Typography>
              </Row>
              <Row>
                <Typography variant="body1" style={{width: '70px'}}>
                  Hardware:
                </Typography>
                <Typography variant="body1">{device.hwVer ? `v${device.hwVer}` : 'Unavailable'}</Typography>
              </Row>
            </>
          ) : (
            <i>No device attached</i>
          )}
        </Centered>
      </CardContent>
    </Card>
  );
}
