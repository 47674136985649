import React from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {TimeFilter} from '@common/utils/timeFilter';
import {IMetricsResponse} from '@common/api/models/metrics';
import {Card, CardContent, CardHeader, Typography} from '@material-ui/core';
import PieChart from '../../../components/atoms/ApexCharts/PieChart';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {
  dangerColor,
  grayColor,
  infoColor,
  lowWarningColor,
  successColor,
  warningColor,
} from '../../../assets/jss/material-dashboard-react';
import waitingForSomethingPNG from '../../../assets/img/waiting-for-something.png';
import {CenteredDiv} from './SwitchableChart';

function BuildStatus({
  buildStateCount,
  timeFilter,
}: {
  buildStateCount: IMetricsResponse['buildStateCount'];
  timeFilter: TimeFilter;
}) {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();

  function onDataPointSelection(dataPointIndex: number) {
    const fromDate = moment.utc(timeFilter.from).format();

    switch (dataPointIndex) {
      case 0:
        history.push(
          `/builds/?state[in]=draft&sortBy[createdAt]=DESC${timeFilter.from ? `&createdAt[since]=${fromDate}` : ''}`
        );
        break;
      case 1:
        history.push(
          `/builds/?state[in]=staging&sortBy[createdAt]=DESC${timeFilter.from ? `&createdAt[since]=${fromDate}` : ''}`
        );
        break;
      case 2:
        history.push(
          `/builds/?state[in]=active&sortBy[start]=DESC${timeFilter.from ? `&start[since]=${fromDate}` : ''}`
        );
        break;
      case 3:
        history.push(
          `/builds/?state[in]=paused&sortBy[start]=DESC${timeFilter.from ? `&start[since]=${fromDate}` : ''}`
        );
        break;
      case 4:
        history.push(
          `/builds/?state[in]=completed&state[in]=succeeded&sortBy[end]=DESC${
            timeFilter.from ? `&start[since]=${fromDate}` : ''
          }`
        );
        break;
      case 5:
        history.push(`/builds/?state[in]=failed&sortBy[end]=DESC${timeFilter.from ? `&start[since]=${fromDate}` : ''}`);
        break;
    }
  }

  if (Object.values(buildStateCount).every((stateCount) => stateCount === 0)) {
    return (
      <Card style={{height: '100%'}}>
        <CardHeader title="Build state" style={{paddingBottom: '0px'}} />
        <CenteredDiv>
          <Typography variant="h4">No recent builds...</Typography>

          <img
            src={waitingForSomethingPNG}
            style={{height: '150px', margin: '1rem 0px 2rem'}}
            alt="bird chirping musically in a park"
          />
        </CenteredDiv>
      </Card>
    );
  }

  return (
    <Card style={{height: '100%'}}>
      <CardHeader title="Build state" style={{paddingBottom: '0px'}} />

      <CardContent>
        <PieChart
          options={{
            chart: {
              events: {
                dataPointSelection: (_event, _context, config) => onDataPointSelection(config.dataPointIndex),
              },
            },
            dataLabels: {
              formatter: (_num, {seriesIndex, w: options}) => {
                return `${options.config.series[seriesIndex]}`;
              },
            },
            labels: ['Draft', 'Staging', 'Active', 'Paused', 'Completed', 'Failed'],
            colors: [grayColor[3], lowWarningColor[0], infoColor[3], warningColor[0], successColor[0], dangerColor[0]],
            legend: {show: !isSmallScreen},
          }}
          series={[
            buildStateCount.draft,
            buildStateCount.staging,
            buildStateCount.active,
            buildStateCount.paused,
            buildStateCount.completed,
            buildStateCount.failed,
          ]}
          height={200}
        />
      </CardContent>
    </Card>
  );
}

export default BuildStatus;
