interface ColorMapData {
  [name: string]: {
    interpolate: boolean;
    colors: number[][];
  };
}

const data: ColorMapData = {
  RdBu: {
    interpolate: true,
    colors: [
      [0.4039, 0.0, 0.1216],
      [0.4155, 0.0037, 0.1234],
      [0.427, 0.0074, 0.1253],
      [0.4385, 0.0111, 0.1271],
      [0.4501, 0.0148, 0.129],
      [0.4616, 0.0185, 0.1308],
      [0.4731, 0.0221, 0.1326],
      [0.4847, 0.0258, 0.1345],
      [0.4962, 0.0295, 0.1363],
      [0.5077, 0.0332, 0.1382],
      [0.5193, 0.0369, 0.14],
      [0.5308, 0.0406, 0.1419],
      [0.5423, 0.0443, 0.1437],
      [0.5539, 0.048, 0.1456],
      [0.5654, 0.0517, 0.1474],
      [0.5769, 0.0554, 0.1493],
      [0.5885, 0.0591, 0.1511],
      [0.6, 0.0627, 0.1529],
      [0.6115, 0.0664, 0.1548],
      [0.6231, 0.0701, 0.1566],
      [0.6346, 0.0738, 0.1585],
      [0.6461, 0.0775, 0.1603],
      [0.6577, 0.0812, 0.1622],
      [0.6692, 0.0849, 0.164],
      [0.6807, 0.0886, 0.1659],
      [0.6923, 0.0923, 0.1677],
      [0.7008, 0.0997, 0.1712],
      [0.7063, 0.1107, 0.1765],
      [0.7119, 0.1218, 0.1817],
      [0.7174, 0.1329, 0.1869],
      [0.723, 0.1439, 0.1922],
      [0.7285, 0.155, 0.1974],
      [0.734, 0.1661, 0.2026],
      [0.7396, 0.1772, 0.2078],
      [0.7451, 0.1882, 0.2131],
      [0.7506, 0.1993, 0.2183],
      [0.7562, 0.2104, 0.2235],
      [0.7617, 0.2215, 0.2288],
      [0.7672, 0.2325, 0.234],
      [0.7728, 0.2436, 0.2392],
      [0.7783, 0.2547, 0.2444],
      [0.7839, 0.2657, 0.2497],
      [0.7894, 0.2768, 0.2549],
      [0.7949, 0.2879, 0.2601],
      [0.8005, 0.299, 0.2654],
      [0.806, 0.31, 0.2706],
      [0.8115, 0.3211, 0.2758],
      [0.8171, 0.3322, 0.281],
      [0.8226, 0.3433, 0.2863],
      [0.8281, 0.3543, 0.2915],
      [0.8337, 0.3654, 0.2967],
      [0.8392, 0.3765, 0.302],
      [0.8438, 0.3871, 0.3101],
      [0.8484, 0.3977, 0.3183],
      [0.8531, 0.4083, 0.3264],
      [0.8577, 0.4189, 0.3346],
      [0.8623, 0.4295, 0.3427],
      [0.8669, 0.4401, 0.3509],
      [0.8715, 0.4507, 0.359],
      [0.8761, 0.4614, 0.3672],
      [0.8807, 0.472, 0.3753],
      [0.8854, 0.4826, 0.3835],
      [0.89, 0.4932, 0.3916],
      [0.8946, 0.5038, 0.3998],
      [0.8992, 0.5144, 0.4079],
      [0.9038, 0.525, 0.4161],
      [0.9084, 0.5356, 0.4242],
      [0.913, 0.5463, 0.4324],
      [0.9176, 0.5569, 0.4405],
      [0.9223, 0.5675, 0.4487],
      [0.9269, 0.5781, 0.4568],
      [0.9315, 0.5887, 0.465],
      [0.9361, 0.5993, 0.4731],
      [0.9407, 0.6099, 0.4813],
      [0.9453, 0.6205, 0.4894],
      [0.9499, 0.6311, 0.4976],
      [0.9546, 0.6418, 0.5057],
      [0.9576, 0.6512, 0.5151],
      [0.9589, 0.6595, 0.5257],
      [0.9603, 0.6678, 0.5363],
      [0.9617, 0.6761, 0.5469],
      [0.9631, 0.6844, 0.5576],
      [0.9645, 0.6927, 0.5682],
      [0.9659, 0.701, 0.5788],
      [0.9672, 0.7093, 0.5894],
      [0.9686, 0.7176, 0.6],
      [0.97, 0.726, 0.6106],
      [0.9714, 0.7343, 0.6212],
      [0.9728, 0.7426, 0.6318],
      [0.9742, 0.7509, 0.6424],
      [0.9755, 0.7592, 0.6531],
      [0.9769, 0.7675, 0.6637],
      [0.9783, 0.7758, 0.6743],
      [0.9797, 0.7841, 0.6849],
      [0.9811, 0.7924, 0.6955],
      [0.9825, 0.8007, 0.7061],
      [0.9839, 0.809, 0.7167],
      [0.9852, 0.8173, 0.7273],
      [0.9866, 0.8256, 0.7379],
      [0.988, 0.8339, 0.7486],
      [0.9894, 0.8422, 0.7592],
      [0.9908, 0.8505, 0.7698],
      [0.9922, 0.8588, 0.7804],
      [0.9912, 0.8631, 0.7878],
      [0.9903, 0.8674, 0.7952],
      [0.9894, 0.8717, 0.8025],
      [0.9885, 0.876, 0.8099],
      [0.9875, 0.8804, 0.8173],
      [0.9866, 0.8847, 0.8247],
      [0.9857, 0.889, 0.8321],
      [0.9848, 0.8933, 0.8394],
      [0.9839, 0.8976, 0.8468],
      [0.9829, 0.9019, 0.8542],
      [0.982, 0.9062, 0.8616],
      [0.9811, 0.9105, 0.869],
      [0.9802, 0.9148, 0.8764],
      [0.9792, 0.9191, 0.8837],
      [0.9783, 0.9234, 0.8911],
      [0.9774, 0.9277, 0.8985],
      [0.9765, 0.932, 0.9059],
      [0.9755, 0.9363, 0.9133],
      [0.9746, 0.9406, 0.9206],
      [0.9737, 0.9449, 0.928],
      [0.9728, 0.9493, 0.9354],
      [0.9719, 0.9536, 0.9428],
      [0.9709, 0.9579, 0.9502],
      [0.97, 0.9622, 0.9576],
      [0.9691, 0.9665, 0.9649],
      [0.9657, 0.9672, 0.9681],
      [0.9599, 0.9645, 0.967],
      [0.954, 0.9617, 0.9659],
      [0.9482, 0.9589, 0.9649],
      [0.9423, 0.9562, 0.9638],
      [0.9365, 0.9534, 0.9627],
      [0.9306, 0.9506, 0.9616],
      [0.9248, 0.9479, 0.9606],
      [0.919, 0.9451, 0.9595],
      [0.9131, 0.9423, 0.9584],
      [0.9073, 0.9396, 0.9573],
      [0.9014, 0.9368, 0.9562],
      [0.8956, 0.934, 0.9552],
      [0.8897, 0.9313, 0.9541],
      [0.8839, 0.9285, 0.953],
      [0.878, 0.9257, 0.9519],
      [0.8722, 0.923, 0.9509],
      [0.8664, 0.9202, 0.9498],
      [0.8605, 0.9174, 0.9487],
      [0.8547, 0.9146, 0.9476],
      [0.8488, 0.9119, 0.9466],
      [0.843, 0.9091, 0.9455],
      [0.8371, 0.9063, 0.9444],
      [0.8313, 0.9036, 0.9433],
      [0.8255, 0.9008, 0.9423],
      [0.8196, 0.898, 0.9412],
      [0.8099, 0.8931, 0.9384],
      [0.8002, 0.8882, 0.9356],
      [0.7905, 0.8833, 0.9329],
      [0.7809, 0.8784, 0.9301],
      [0.7712, 0.8734, 0.9273],
      [0.7615, 0.8685, 0.9246],
      [0.7518, 0.8636, 0.9218],
      [0.7421, 0.8587, 0.919],
      [0.7324, 0.8537, 0.9163],
      [0.7227, 0.8488, 0.9135],
      [0.713, 0.8439, 0.9107],
      [0.7033, 0.839, 0.908],
      [0.6937, 0.8341, 0.9052],
      [0.684, 0.8291, 0.9024],
      [0.6743, 0.8242, 0.8997],
      [0.6646, 0.8193, 0.8969],
      [0.6549, 0.8144, 0.8941],
      [0.6452, 0.8095, 0.8913],
      [0.6355, 0.8045, 0.8886],
      [0.6258, 0.7996, 0.8858],
      [0.6161, 0.7947, 0.883],
      [0.6065, 0.7898, 0.8803],
      [0.5968, 0.7849, 0.8775],
      [0.5871, 0.7799, 0.8747],
      [0.5774, 0.775, 0.872],
      [0.5665, 0.7687, 0.8685],
      [0.5543, 0.761, 0.8644],
      [0.5422, 0.7533, 0.8602],
      [0.53, 0.7456, 0.8561],
      [0.5179, 0.7379, 0.8519],
      [0.5057, 0.7303, 0.8478],
      [0.4936, 0.7226, 0.8436],
      [0.4814, 0.7149, 0.8394],
      [0.4693, 0.7072, 0.8353],
      [0.4571, 0.6995, 0.8311],
      [0.445, 0.6918, 0.827],
      [0.4328, 0.6841, 0.8228],
      [0.4207, 0.6764, 0.8187],
      [0.4085, 0.6687, 0.8145],
      [0.3964, 0.6611, 0.8104],
      [0.3842, 0.6534, 0.8062],
      [0.3721, 0.6457, 0.8021],
      [0.3599, 0.638, 0.7979],
      [0.3478, 0.6303, 0.7938],
      [0.3356, 0.6226, 0.7896],
      [0.3235, 0.6149, 0.7855],
      [0.3113, 0.6072, 0.7813],
      [0.2992, 0.5995, 0.7772],
      [0.287, 0.5918, 0.773],
      [0.2749, 0.5842, 0.7689],
      [0.2627, 0.5765, 0.7647],
      [0.2575, 0.5696, 0.7612],
      [0.2523, 0.5626, 0.7576],
      [0.2471, 0.5557, 0.7541],
      [0.2418, 0.5488, 0.7506],
      [0.2366, 0.5419, 0.747],
      [0.2314, 0.5349, 0.7435],
      [0.2261, 0.528, 0.7399],
      [0.2209, 0.5211, 0.7364],
      [0.2157, 0.5142, 0.7329],
      [0.2105, 0.5073, 0.7293],
      [0.2052, 0.5003, 0.7258],
      [0.2, 0.4934, 0.7223],
      [0.1948, 0.4865, 0.7187],
      [0.1895, 0.4796, 0.7152],
      [0.1843, 0.4727, 0.7116],
      [0.1791, 0.4657, 0.7081],
      [0.1739, 0.4588, 0.7046],
      [0.1686, 0.4519, 0.701],
      [0.1634, 0.445, 0.6975],
      [0.1582, 0.4381, 0.694],
      [0.1529, 0.4311, 0.6904],
      [0.1477, 0.4242, 0.6869],
      [0.1425, 0.4173, 0.6834],
      [0.1373, 0.4104, 0.6798],
      [0.132, 0.4035, 0.6763],
      [0.1273, 0.3958, 0.6687],
      [0.123, 0.3875, 0.6572],
      [0.1186, 0.3792, 0.6457],
      [0.1143, 0.3709, 0.6341],
      [0.11, 0.3626, 0.6226],
      [0.1057, 0.3543, 0.6111],
      [0.1014, 0.346, 0.5995],
      [0.0971, 0.3377, 0.588],
      [0.0928, 0.3294, 0.5765],
      [0.0885, 0.3211, 0.5649],
      [0.0842, 0.3128, 0.5534],
      [0.0799, 0.3045, 0.5419],
      [0.0756, 0.2962, 0.5303],
      [0.0713, 0.2879, 0.5188],
      [0.067, 0.2796, 0.5073],
      [0.0627, 0.2713, 0.4957],
      [0.0584, 0.263, 0.4842],
      [0.0541, 0.2547, 0.4727],
      [0.0498, 0.2464, 0.4611],
      [0.0454, 0.2381, 0.4496],
      [0.0411, 0.2298, 0.4381],
      [0.0368, 0.2215, 0.4265],
      [0.0325, 0.2131, 0.415],
      [0.0282, 0.2048, 0.4035],
      [0.0239, 0.1965, 0.3919],
      [0.0196, 0.1882, 0.3804],
    ],
  },
  cool: {
    interpolate: true,
    colors: [
      [0.0, 1.0, 1.0],
      [0.0039, 0.9961, 1.0],
      [0.0078, 0.9922, 1.0],
      [0.0118, 0.9882, 1.0],
      [0.0157, 0.9843, 1.0],
      [0.0196, 0.9804, 1.0],
      [0.0235, 0.9765, 1.0],
      [0.0275, 0.9725, 1.0],
      [0.0314, 0.9686, 1.0],
      [0.0353, 0.9647, 1.0],
      [0.0392, 0.9608, 1.0],
      [0.0431, 0.9569, 1.0],
      [0.0471, 0.9529, 1.0],
      [0.051, 0.949, 1.0],
      [0.0549, 0.9451, 1.0],
      [0.0588, 0.9412, 1.0],
      [0.0627, 0.9373, 1.0],
      [0.0667, 0.9333, 1.0],
      [0.0706, 0.9294, 1.0],
      [0.0745, 0.9255, 1.0],
      [0.0784, 0.9216, 1.0],
      [0.0824, 0.9176, 1.0],
      [0.0863, 0.9137, 1.0],
      [0.0902, 0.9098, 1.0],
      [0.0941, 0.9059, 1.0],
      [0.098, 0.902, 1.0],
      [0.102, 0.898, 1.0],
      [0.1059, 0.8941, 1.0],
      [0.1098, 0.8902, 1.0],
      [0.1137, 0.8863, 1.0],
      [0.1176, 0.8824, 1.0],
      [0.1216, 0.8784, 1.0],
      [0.1255, 0.8745, 1.0],
      [0.1294, 0.8706, 1.0],
      [0.1333, 0.8667, 1.0],
      [0.1373, 0.8627, 1.0],
      [0.1412, 0.8588, 1.0],
      [0.1451, 0.8549, 1.0],
      [0.149, 0.851, 1.0],
      [0.1529, 0.8471, 1.0],
      [0.1569, 0.8431, 1.0],
      [0.1608, 0.8392, 1.0],
      [0.1647, 0.8353, 1.0],
      [0.1686, 0.8314, 1.0],
      [0.1725, 0.8275, 1.0],
      [0.1765, 0.8235, 1.0],
      [0.1804, 0.8196, 1.0],
      [0.1843, 0.8157, 1.0],
      [0.1882, 0.8118, 1.0],
      [0.1922, 0.8078, 1.0],
      [0.1961, 0.8039, 1.0],
      [0.2, 0.8, 1.0],
      [0.2039, 0.7961, 1.0],
      [0.2078, 0.7922, 1.0],
      [0.2118, 0.7882, 1.0],
      [0.2157, 0.7843, 1.0],
      [0.2196, 0.7804, 1.0],
      [0.2235, 0.7765, 1.0],
      [0.2275, 0.7725, 1.0],
      [0.2314, 0.7686, 1.0],
      [0.2353, 0.7647, 1.0],
      [0.2392, 0.7608, 1.0],
      [0.2431, 0.7569, 1.0],
      [0.2471, 0.7529, 1.0],
      [0.251, 0.749, 1.0],
      [0.2549, 0.7451, 1.0],
      [0.2588, 0.7412, 1.0],
      [0.2627, 0.7373, 1.0],
      [0.2667, 0.7333, 1.0],
      [0.2706, 0.7294, 1.0],
      [0.2745, 0.7255, 1.0],
      [0.2784, 0.7216, 1.0],
      [0.2824, 0.7176, 1.0],
      [0.2863, 0.7137, 1.0],
      [0.2902, 0.7098, 1.0],
      [0.2941, 0.7059, 1.0],
      [0.298, 0.702, 1.0],
      [0.302, 0.698, 1.0],
      [0.3059, 0.6941, 1.0],
      [0.3098, 0.6902, 1.0],
      [0.3137, 0.6863, 1.0],
      [0.3176, 0.6824, 1.0],
      [0.3216, 0.6784, 1.0],
      [0.3255, 0.6745, 1.0],
      [0.3294, 0.6706, 1.0],
      [0.3333, 0.6667, 1.0],
      [0.3373, 0.6627, 1.0],
      [0.3412, 0.6588, 1.0],
      [0.3451, 0.6549, 1.0],
      [0.349, 0.651, 1.0],
      [0.3529, 0.6471, 1.0],
      [0.3569, 0.6431, 1.0],
      [0.3608, 0.6392, 1.0],
      [0.3647, 0.6353, 1.0],
      [0.3686, 0.6314, 1.0],
      [0.3725, 0.6275, 1.0],
      [0.3765, 0.6235, 1.0],
      [0.3804, 0.6196, 1.0],
      [0.3843, 0.6157, 1.0],
      [0.3882, 0.6118, 1.0],
      [0.3922, 0.6078, 1.0],
      [0.3961, 0.6039, 1.0],
      [0.4, 0.6, 1.0],
      [0.4039, 0.5961, 1.0],
      [0.4078, 0.5922, 1.0],
      [0.4118, 0.5882, 1.0],
      [0.4157, 0.5843, 1.0],
      [0.4196, 0.5804, 1.0],
      [0.4235, 0.5765, 1.0],
      [0.4275, 0.5725, 1.0],
      [0.4314, 0.5686, 1.0],
      [0.4353, 0.5647, 1.0],
      [0.4392, 0.5608, 1.0],
      [0.4431, 0.5569, 1.0],
      [0.4471, 0.5529, 1.0],
      [0.451, 0.549, 1.0],
      [0.4549, 0.5451, 1.0],
      [0.4588, 0.5412, 1.0],
      [0.4627, 0.5373, 1.0],
      [0.4667, 0.5333, 1.0],
      [0.4706, 0.5294, 1.0],
      [0.4745, 0.5255, 1.0],
      [0.4784, 0.5216, 1.0],
      [0.4824, 0.5176, 1.0],
      [0.4863, 0.5137, 1.0],
      [0.4902, 0.5098, 1.0],
      [0.4941, 0.5059, 1.0],
      [0.498, 0.502, 1.0],
      [0.502, 0.498, 1.0],
      [0.5059, 0.4941, 1.0],
      [0.5098, 0.4902, 1.0],
      [0.5137, 0.4863, 1.0],
      [0.5176, 0.4824, 1.0],
      [0.5216, 0.4784, 1.0],
      [0.5255, 0.4745, 1.0],
      [0.5294, 0.4706, 1.0],
      [0.5333, 0.4667, 1.0],
      [0.5373, 0.4627, 1.0],
      [0.5412, 0.4588, 1.0],
      [0.5451, 0.4549, 1.0],
      [0.549, 0.451, 1.0],
      [0.5529, 0.4471, 1.0],
      [0.5569, 0.4431, 1.0],
      [0.5608, 0.4392, 1.0],
      [0.5647, 0.4353, 1.0],
      [0.5686, 0.4314, 1.0],
      [0.5725, 0.4275, 1.0],
      [0.5765, 0.4235, 1.0],
      [0.5804, 0.4196, 1.0],
      [0.5843, 0.4157, 1.0],
      [0.5882, 0.4118, 1.0],
      [0.5922, 0.4078, 1.0],
      [0.5961, 0.4039, 1.0],
      [0.6, 0.4, 1.0],
      [0.6039, 0.3961, 1.0],
      [0.6078, 0.3922, 1.0],
      [0.6118, 0.3882, 1.0],
      [0.6157, 0.3843, 1.0],
      [0.6196, 0.3804, 1.0],
      [0.6235, 0.3765, 1.0],
      [0.6275, 0.3725, 1.0],
      [0.6314, 0.3686, 1.0],
      [0.6353, 0.3647, 1.0],
      [0.6392, 0.3608, 1.0],
      [0.6431, 0.3569, 1.0],
      [0.6471, 0.3529, 1.0],
      [0.651, 0.349, 1.0],
      [0.6549, 0.3451, 1.0],
      [0.6588, 0.3412, 1.0],
      [0.6627, 0.3373, 1.0],
      [0.6667, 0.3333, 1.0],
      [0.6706, 0.3294, 1.0],
      [0.6745, 0.3255, 1.0],
      [0.6784, 0.3216, 1.0],
      [0.6824, 0.3176, 1.0],
      [0.6863, 0.3137, 1.0],
      [0.6902, 0.3098, 1.0],
      [0.6941, 0.3059, 1.0],
      [0.698, 0.302, 1.0],
      [0.702, 0.298, 1.0],
      [0.7059, 0.2941, 1.0],
      [0.7098, 0.2902, 1.0],
      [0.7137, 0.2863, 1.0],
      [0.7176, 0.2824, 1.0],
      [0.7216, 0.2784, 1.0],
      [0.7255, 0.2745, 1.0],
      [0.7294, 0.2706, 1.0],
      [0.7333, 0.2667, 1.0],
      [0.7373, 0.2627, 1.0],
      [0.7412, 0.2588, 1.0],
      [0.7451, 0.2549, 1.0],
      [0.749, 0.251, 1.0],
      [0.7529, 0.2471, 1.0],
      [0.7569, 0.2431, 1.0],
      [0.7608, 0.2392, 1.0],
      [0.7647, 0.2353, 1.0],
      [0.7686, 0.2314, 1.0],
      [0.7725, 0.2275, 1.0],
      [0.7765, 0.2235, 1.0],
      [0.7804, 0.2196, 1.0],
      [0.7843, 0.2157, 1.0],
      [0.7882, 0.2118, 1.0],
      [0.7922, 0.2078, 1.0],
      [0.7961, 0.2039, 1.0],
      [0.8, 0.2, 1.0],
      [0.8039, 0.1961, 1.0],
      [0.8078, 0.1922, 1.0],
      [0.8118, 0.1882, 1.0],
      [0.8157, 0.1843, 1.0],
      [0.8196, 0.1804, 1.0],
      [0.8235, 0.1765, 1.0],
      [0.8275, 0.1725, 1.0],
      [0.8314, 0.1686, 1.0],
      [0.8353, 0.1647, 1.0],
      [0.8392, 0.1608, 1.0],
      [0.8431, 0.1569, 1.0],
      [0.8471, 0.1529, 1.0],
      [0.851, 0.149, 1.0],
      [0.8549, 0.1451, 1.0],
      [0.8588, 0.1412, 1.0],
      [0.8627, 0.1373, 1.0],
      [0.8667, 0.1333, 1.0],
      [0.8706, 0.1294, 1.0],
      [0.8745, 0.1255, 1.0],
      [0.8784, 0.1216, 1.0],
      [0.8824, 0.1176, 1.0],
      [0.8863, 0.1137, 1.0],
      [0.8902, 0.1098, 1.0],
      [0.8941, 0.1059, 1.0],
      [0.898, 0.102, 1.0],
      [0.902, 0.098, 1.0],
      [0.9059, 0.0941, 1.0],
      [0.9098, 0.0902, 1.0],
      [0.9137, 0.0863, 1.0],
      [0.9176, 0.0824, 1.0],
      [0.9216, 0.0784, 1.0],
      [0.9255, 0.0745, 1.0],
      [0.9294, 0.0706, 1.0],
      [0.9333, 0.0667, 1.0],
      [0.9373, 0.0627, 1.0],
      [0.9412, 0.0588, 1.0],
      [0.9451, 0.0549, 1.0],
      [0.949, 0.051, 1.0],
      [0.9529, 0.0471, 1.0],
      [0.9569, 0.0431, 1.0],
      [0.9608, 0.0392, 1.0],
      [0.9647, 0.0353, 1.0],
      [0.9686, 0.0314, 1.0],
      [0.9725, 0.0275, 1.0],
      [0.9765, 0.0235, 1.0],
      [0.9804, 0.0196, 1.0],
      [0.9843, 0.0157, 1.0],
      [0.9882, 0.0118, 1.0],
      [0.9922, 0.0078, 1.0],
      [0.9961, 0.0039, 1.0],
      [1.0, 0.0, 1.0],
    ],
  },
  hot: {
    interpolate: true,
    colors: [
      [0.0416, 0.0, 0.0],
      [0.0519, 0.0, 0.0],
      [0.0622, 0.0, 0.0],
      [0.0725, 0.0, 0.0],
      [0.0828, 0.0, 0.0],
      [0.0931, 0.0, 0.0],
      [0.1034, 0.0, 0.0],
      [0.1137, 0.0, 0.0],
      [0.124, 0.0, 0.0],
      [0.1343, 0.0, 0.0],
      [0.1445, 0.0, 0.0],
      [0.1548, 0.0, 0.0],
      [0.1651, 0.0, 0.0],
      [0.1754, 0.0, 0.0],
      [0.1857, 0.0, 0.0],
      [0.196, 0.0, 0.0],
      [0.2063, 0.0, 0.0],
      [0.2166, 0.0, 0.0],
      [0.2269, 0.0, 0.0],
      [0.2372, 0.0, 0.0],
      [0.2475, 0.0, 0.0],
      [0.2578, 0.0, 0.0],
      [0.2681, 0.0, 0.0],
      [0.2784, 0.0, 0.0],
      [0.2887, 0.0, 0.0],
      [0.299, 0.0, 0.0],
      [0.3093, 0.0, 0.0],
      [0.3196, 0.0, 0.0],
      [0.3299, 0.0, 0.0],
      [0.3402, 0.0, 0.0],
      [0.3504, 0.0, 0.0],
      [0.3607, 0.0, 0.0],
      [0.371, 0.0, 0.0],
      [0.3813, 0.0, 0.0],
      [0.3916, 0.0, 0.0],
      [0.4019, 0.0, 0.0],
      [0.4122, 0.0, 0.0],
      [0.4225, 0.0, 0.0],
      [0.4328, 0.0, 0.0],
      [0.4431, 0.0, 0.0],
      [0.4534, 0.0, 0.0],
      [0.4637, 0.0, 0.0],
      [0.474, 0.0, 0.0],
      [0.4843, 0.0, 0.0],
      [0.4946, 0.0, 0.0],
      [0.5049, 0.0, 0.0],
      [0.5152, 0.0, 0.0],
      [0.5255, 0.0, 0.0],
      [0.5358, 0.0, 0.0],
      [0.546, 0.0, 0.0],
      [0.5563, 0.0, 0.0],
      [0.5666, 0.0, 0.0],
      [0.5769, 0.0, 0.0],
      [0.5872, 0.0, 0.0],
      [0.5975, 0.0, 0.0],
      [0.6078, 0.0, 0.0],
      [0.6181, 0.0, 0.0],
      [0.6284, 0.0, 0.0],
      [0.6387, 0.0, 0.0],
      [0.649, 0.0, 0.0],
      [0.6593, 0.0, 0.0],
      [0.6696, 0.0, 0.0],
      [0.6799, 0.0, 0.0],
      [0.6902, 0.0, 0.0],
      [0.7005, 0.0, 0.0],
      [0.7108, 0.0, 0.0],
      [0.7211, 0.0, 0.0],
      [0.7314, 0.0, 0.0],
      [0.7416, 0.0, 0.0],
      [0.7519, 0.0, 0.0],
      [0.7622, 0.0, 0.0],
      [0.7725, 0.0, 0.0],
      [0.7828, 0.0, 0.0],
      [0.7931, 0.0, 0.0],
      [0.8034, 0.0, 0.0],
      [0.8137, 0.0, 0.0],
      [0.824, 0.0, 0.0],
      [0.8343, 0.0, 0.0],
      [0.8446, 0.0, 0.0],
      [0.8549, 0.0, 0.0],
      [0.8652, 0.0, 0.0],
      [0.8755, 0.0, 0.0],
      [0.8858, 0.0, 0.0],
      [0.8961, 0.0, 0.0],
      [0.9064, 0.0, 0.0],
      [0.9167, 0.0, 0.0],
      [0.927, 0.0, 0.0],
      [0.9373, 0.0, 0.0],
      [0.9475, 0.0, 0.0],
      [0.9578, 0.0, 0.0],
      [0.9681, 0.0, 0.0],
      [0.9784, 0.0, 0.0],
      [0.9887, 0.0, 0.0],
      [0.999, 0.0, 0.0],
      [1.0, 0.0093, 0.0],
      [1.0, 0.0196, 0.0],
      [1.0, 0.0299, 0.0],
      [1.0, 0.0402, 0.0],
      [1.0, 0.0505, 0.0],
      [1.0, 0.0608, 0.0],
      [1.0, 0.0711, 0.0],
      [1.0, 0.0814, 0.0],
      [1.0, 0.0917, 0.0],
      [1.0, 0.102, 0.0],
      [1.0, 0.1123, 0.0],
      [1.0, 0.1225, 0.0],
      [1.0, 0.1328, 0.0],
      [1.0, 0.1431, 0.0],
      [1.0, 0.1534, 0.0],
      [1.0, 0.1637, 0.0],
      [1.0, 0.174, 0.0],
      [1.0, 0.1843, 0.0],
      [1.0, 0.1946, 0.0],
      [1.0, 0.2049, 0.0],
      [1.0, 0.2152, 0.0],
      [1.0, 0.2255, 0.0],
      [1.0, 0.2358, 0.0],
      [1.0, 0.2461, 0.0],
      [1.0, 0.2564, 0.0],
      [1.0, 0.2667, 0.0],
      [1.0, 0.277, 0.0],
      [1.0, 0.2873, 0.0],
      [1.0, 0.2975, 0.0],
      [1.0, 0.3078, 0.0],
      [1.0, 0.3181, 0.0],
      [1.0, 0.3284, 0.0],
      [1.0, 0.3387, 0.0],
      [1.0, 0.349, 0.0],
      [1.0, 0.3593, 0.0],
      [1.0, 0.3696, 0.0],
      [1.0, 0.3799, 0.0],
      [1.0, 0.3902, 0.0],
      [1.0, 0.4005, 0.0],
      [1.0, 0.4108, 0.0],
      [1.0, 0.4211, 0.0],
      [1.0, 0.4314, 0.0],
      [1.0, 0.4417, 0.0],
      [1.0, 0.452, 0.0],
      [1.0, 0.4623, 0.0],
      [1.0, 0.4725, 0.0],
      [1.0, 0.4828, 0.0],
      [1.0, 0.4931, 0.0],
      [1.0, 0.5034, 0.0],
      [1.0, 0.5137, 0.0],
      [1.0, 0.524, 0.0],
      [1.0, 0.5343, 0.0],
      [1.0, 0.5446, 0.0],
      [1.0, 0.5549, 0.0],
      [1.0, 0.5652, 0.0],
      [1.0, 0.5755, 0.0],
      [1.0, 0.5858, 0.0],
      [1.0, 0.5961, 0.0],
      [1.0, 0.6064, 0.0],
      [1.0, 0.6167, 0.0],
      [1.0, 0.627, 0.0],
      [1.0, 0.6373, 0.0],
      [1.0, 0.6475, 0.0],
      [1.0, 0.6578, 0.0],
      [1.0, 0.6681, 0.0],
      [1.0, 0.6784, 0.0],
      [1.0, 0.6887, 0.0],
      [1.0, 0.699, 0.0],
      [1.0, 0.7093, 0.0],
      [1.0, 0.7196, 0.0],
      [1.0, 0.7299, 0.0],
      [1.0, 0.7402, 0.0],
      [1.0, 0.7505, 0.0],
      [1.0, 0.7608, 0.0],
      [1.0, 0.7711, 0.0],
      [1.0, 0.7814, 0.0],
      [1.0, 0.7917, 0.0],
      [1.0, 0.802, 0.0],
      [1.0, 0.8123, 0.0],
      [1.0, 0.8225, 0.0],
      [1.0, 0.8328, 0.0],
      [1.0, 0.8431, 0.0],
      [1.0, 0.8534, 0.0],
      [1.0, 0.8637, 0.0],
      [1.0, 0.874, 0.0],
      [1.0, 0.8843, 0.0],
      [1.0, 0.8946, 0.0],
      [1.0, 0.9049, 0.0],
      [1.0, 0.9152, 0.0],
      [1.0, 0.9255, 0.0],
      [1.0, 0.9358, 0.0],
      [1.0, 0.9461, 0.0],
      [1.0, 0.9564, 0.0],
      [1.0, 0.9667, 0.0],
      [1.0, 0.977, 0.0],
      [1.0, 0.9873, 0.0],
      [1.0, 0.9975, 0.0],
      [1.0, 1.0, 0.0118],
      [1.0, 1.0, 0.0272],
      [1.0, 1.0, 0.0426],
      [1.0, 1.0, 0.0581],
      [1.0, 1.0, 0.0735],
      [1.0, 1.0, 0.089],
      [1.0, 1.0, 0.1044],
      [1.0, 1.0, 0.1199],
      [1.0, 1.0, 0.1353],
      [1.0, 1.0, 0.1507],
      [1.0, 1.0, 0.1662],
      [1.0, 1.0, 0.1816],
      [1.0, 1.0, 0.1971],
      [1.0, 1.0, 0.2125],
      [1.0, 1.0, 0.2279],
      [1.0, 1.0, 0.2434],
      [1.0, 1.0, 0.2588],
      [1.0, 1.0, 0.2743],
      [1.0, 1.0, 0.2897],
      [1.0, 1.0, 0.3051],
      [1.0, 1.0, 0.3206],
      [1.0, 1.0, 0.336],
      [1.0, 1.0, 0.3515],
      [1.0, 1.0, 0.3669],
      [1.0, 1.0, 0.3824],
      [1.0, 1.0, 0.3978],
      [1.0, 1.0, 0.4132],
      [1.0, 1.0, 0.4287],
      [1.0, 1.0, 0.4441],
      [1.0, 1.0, 0.4596],
      [1.0, 1.0, 0.475],
      [1.0, 1.0, 0.4904],
      [1.0, 1.0, 0.5059],
      [1.0, 1.0, 0.5213],
      [1.0, 1.0, 0.5368],
      [1.0, 1.0, 0.5522],
      [1.0, 1.0, 0.5676],
      [1.0, 1.0, 0.5831],
      [1.0, 1.0, 0.5985],
      [1.0, 1.0, 0.614],
      [1.0, 1.0, 0.6294],
      [1.0, 1.0, 0.6449],
      [1.0, 1.0, 0.6603],
      [1.0, 1.0, 0.6757],
      [1.0, 1.0, 0.6912],
      [1.0, 1.0, 0.7066],
      [1.0, 1.0, 0.7221],
      [1.0, 1.0, 0.7375],
      [1.0, 1.0, 0.7529],
      [1.0, 1.0, 0.7684],
      [1.0, 1.0, 0.7838],
      [1.0, 1.0, 0.7993],
      [1.0, 1.0, 0.8147],
      [1.0, 1.0, 0.8301],
      [1.0, 1.0, 0.8456],
      [1.0, 1.0, 0.861],
      [1.0, 1.0, 0.8765],
      [1.0, 1.0, 0.8919],
      [1.0, 1.0, 0.9074],
      [1.0, 1.0, 0.9228],
      [1.0, 1.0, 0.9382],
      [1.0, 1.0, 0.9537],
      [1.0, 1.0, 0.9691],
      [1.0, 1.0, 0.9846],
      [1.0, 1.0, 1.0],
    ],
  },
  hsv: {
    interpolate: true,
    colors: [
      [1.0, 0.0, 0.0],
      [1.0, 0.0232, 0.0],
      [1.0, 0.0463, 0.0],
      [1.0, 0.0695, 0.0],
      [1.0, 0.0926, 0.0],
      [1.0, 0.1158, 0.0],
      [1.0, 0.139, 0.0],
      [1.0, 0.1621, 0.0],
      [1.0, 0.1853, 0.0],
      [1.0, 0.2085, 0.0],
      [1.0, 0.2316, 0.0],
      [1.0, 0.2548, 0.0],
      [1.0, 0.2779, 0.0],
      [1.0, 0.3011, 0.0],
      [1.0, 0.3243, 0.0],
      [1.0, 0.3474, 0.0],
      [1.0, 0.3706, 0.0],
      [1.0, 0.3938, 0.0],
      [1.0, 0.4169, 0.0],
      [1.0, 0.4401, 0.0],
      [1.0, 0.4632, 0.0],
      [1.0, 0.4864, 0.0],
      [1.0, 0.5096, 0.0],
      [1.0, 0.5327, 0.0],
      [1.0, 0.5559, 0.0],
      [1.0, 0.579, 0.0],
      [1.0, 0.6022, 0.0],
      [1.0, 0.6254, 0.0],
      [1.0, 0.6485, 0.0],
      [1.0, 0.6717, 0.0],
      [1.0, 0.6949, 0.0],
      [1.0, 0.718, 0.0],
      [1.0, 0.7412, 0.0],
      [1.0, 0.7643, 0.0],
      [1.0, 0.7875, 0.0],
      [1.0, 0.8107, 0.0],
      [1.0, 0.8338, 0.0],
      [1.0, 0.857, 0.0],
      [1.0, 0.8801, 0.0],
      [1.0, 0.9033, 0.0],
      [1.0, 0.9265, 0.0],
      [0.996, 0.9456, 0.0],
      [0.9882, 0.961, 0.0],
      [0.9805, 0.9765, 0.0],
      [0.9728, 0.9919, 0.0],
      [0.9577, 1.0, 0.0],
      [0.9346, 1.0, 0.0],
      [0.9114, 1.0, 0.0],
      [0.8882, 1.0, 0.0],
      [0.8651, 1.0, 0.0],
      [0.8419, 1.0, 0.0],
      [0.8187, 1.0, 0.0],
      [0.7956, 1.0, 0.0],
      [0.7724, 1.0, 0.0],
      [0.7493, 1.0, 0.0],
      [0.7261, 1.0, 0.0],
      [0.7029, 1.0, 0.0],
      [0.6798, 1.0, 0.0],
      [0.6566, 1.0, 0.0],
      [0.6335, 1.0, 0.0],
      [0.6103, 1.0, 0.0],
      [0.5871, 1.0, 0.0],
      [0.564, 1.0, 0.0],
      [0.5408, 1.0, 0.0],
      [0.5176, 1.0, 0.0],
      [0.4945, 1.0, 0.0],
      [0.4713, 1.0, 0.0],
      [0.4482, 1.0, 0.0],
      [0.425, 1.0, 0.0],
      [0.4018, 1.0, 0.0],
      [0.3787, 1.0, 0.0],
      [0.3555, 1.0, 0.0],
      [0.3324, 1.0, 0.0],
      [0.3092, 1.0, 0.0],
      [0.286, 1.0, 0.0],
      [0.2629, 1.0, 0.0],
      [0.2397, 1.0, 0.0],
      [0.2165, 1.0, 0.0],
      [0.1934, 1.0, 0.0],
      [0.1702, 1.0, 0.0],
      [0.1471, 1.0, 0.0],
      [0.1239, 1.0, 0.0],
      [0.1007, 1.0, 0.0],
      [0.0776, 1.0, 0.0],
      [0.0544, 1.0, 0.0],
      [0.0312, 1.0, 0.0],
      [0.0235, 1.0, 0.0154],
      [0.0158, 1.0, 0.0309],
      [0.0081, 1.0, 0.0463],
      [0.0004, 1.0, 0.0618],
      [0.0, 1.0, 0.0846],
      [0.0, 1.0, 0.1077],
      [0.0, 1.0, 0.1309],
      [0.0, 1.0, 0.154],
      [0.0, 1.0, 0.1772],
      [0.0, 1.0, 0.2004],
      [0.0, 1.0, 0.2235],
      [0.0, 1.0, 0.2467],
      [0.0, 1.0, 0.2699],
      [0.0, 1.0, 0.293],
      [0.0, 1.0, 0.3162],
      [0.0, 1.0, 0.3393],
      [0.0, 1.0, 0.3625],
      [0.0, 1.0, 0.3857],
      [0.0, 1.0, 0.4088],
      [0.0, 1.0, 0.432],
      [0.0, 1.0, 0.4551],
      [0.0, 1.0, 0.4783],
      [0.0, 1.0, 0.5015],
      [0.0, 1.0, 0.5246],
      [0.0, 1.0, 0.5478],
      [0.0, 1.0, 0.571],
      [0.0, 1.0, 0.5941],
      [0.0, 1.0, 0.6173],
      [0.0, 1.0, 0.6404],
      [0.0, 1.0, 0.6636],
      [0.0, 1.0, 0.6868],
      [0.0, 1.0, 0.7099],
      [0.0, 1.0, 0.7331],
      [0.0, 1.0, 0.7562],
      [0.0, 1.0, 0.7794],
      [0.0, 1.0, 0.8026],
      [0.0, 1.0, 0.8257],
      [0.0, 1.0, 0.8489],
      [0.0, 1.0, 0.8721],
      [0.0, 1.0, 0.8952],
      [0.0, 1.0, 0.9184],
      [0.0, 1.0, 0.9415],
      [0.0, 1.0, 0.9647],
      [0.0, 1.0, 0.9879],
      [0.0, 0.989, 1.0],
      [0.0, 0.9658, 1.0],
      [0.0, 0.9426, 1.0],
      [0.0, 0.9195, 1.0],
      [0.0, 0.8963, 1.0],
      [0.0, 0.8732, 1.0],
      [0.0, 0.85, 1.0],
      [0.0, 0.8268, 1.0],
      [0.0, 0.8037, 1.0],
      [0.0, 0.7805, 1.0],
      [0.0, 0.7574, 1.0],
      [0.0, 0.7342, 1.0],
      [0.0, 0.711, 1.0],
      [0.0, 0.6879, 1.0],
      [0.0, 0.6647, 1.0],
      [0.0, 0.6415, 1.0],
      [0.0, 0.6184, 1.0],
      [0.0, 0.5952, 1.0],
      [0.0, 0.5721, 1.0],
      [0.0, 0.5489, 1.0],
      [0.0, 0.5257, 1.0],
      [0.0, 0.5026, 1.0],
      [0.0, 0.4794, 1.0],
      [0.0, 0.4563, 1.0],
      [0.0, 0.4331, 1.0],
      [0.0, 0.4099, 1.0],
      [0.0, 0.3868, 1.0],
      [0.0, 0.3636, 1.0],
      [0.0, 0.3404, 1.0],
      [0.0, 0.3173, 1.0],
      [0.0, 0.2941, 1.0],
      [0.0, 0.271, 1.0],
      [0.0, 0.2478, 1.0],
      [0.0, 0.2246, 1.0],
      [0.0, 0.2015, 1.0],
      [0.0, 0.1783, 1.0],
      [0.0, 0.1551, 1.0],
      [0.0, 0.132, 1.0],
      [0.0, 0.1088, 1.0],
      [0.0, 0.0857, 1.0],
      [0.0, 0.0625, 1.0],
      [0.0077, 0.0471, 1.0],
      [0.0154, 0.0316, 1.0],
      [0.0232, 0.0162, 1.0],
      [0.0309, 0.0007, 1.0],
      [0.0533, 0.0, 1.0],
      [0.0765, 0.0, 1.0],
      [0.0996, 0.0, 1.0],
      [0.1228, 0.0, 1.0],
      [0.146, 0.0, 1.0],
      [0.1691, 0.0, 1.0],
      [0.1923, 0.0, 1.0],
      [0.2154, 0.0, 1.0],
      [0.2386, 0.0, 1.0],
      [0.2618, 0.0, 1.0],
      [0.2849, 0.0, 1.0],
      [0.3081, 0.0, 1.0],
      [0.3312, 0.0, 1.0],
      [0.3544, 0.0, 1.0],
      [0.3776, 0.0, 1.0],
      [0.4007, 0.0, 1.0],
      [0.4239, 0.0, 1.0],
      [0.4471, 0.0, 1.0],
      [0.4702, 0.0, 1.0],
      [0.4934, 0.0, 1.0],
      [0.5165, 0.0, 1.0],
      [0.5397, 0.0, 1.0],
      [0.5629, 0.0, 1.0],
      [0.586, 0.0, 1.0],
      [0.6092, 0.0, 1.0],
      [0.6324, 0.0, 1.0],
      [0.6555, 0.0, 1.0],
      [0.6787, 0.0, 1.0],
      [0.7018, 0.0, 1.0],
      [0.725, 0.0, 1.0],
      [0.7482, 0.0, 1.0],
      [0.7713, 0.0, 1.0],
      [0.7945, 0.0, 1.0],
      [0.8176, 0.0, 1.0],
      [0.8408, 0.0, 1.0],
      [0.864, 0.0, 1.0],
      [0.8871, 0.0, 1.0],
      [0.9103, 0.0, 1.0],
      [0.9335, 0.0, 1.0],
      [0.9566, 0.0, 1.0],
      [0.9724, 0.0, 0.9926],
      [0.9801, 0.0, 0.9772],
      [0.9879, 0.0, 0.9618],
      [0.9956, 0.0, 0.9463],
      [1.0, 0.0, 0.9276],
      [1.0, 0.0, 0.9044],
      [1.0, 0.0, 0.8813],
      [1.0, 0.0, 0.8581],
      [1.0, 0.0, 0.8349],
      [1.0, 0.0, 0.8118],
      [1.0, 0.0, 0.7886],
      [1.0, 0.0, 0.7654],
      [1.0, 0.0, 0.7423],
      [1.0, 0.0, 0.7191],
      [1.0, 0.0, 0.696],
      [1.0, 0.0, 0.6728],
      [1.0, 0.0, 0.6496],
      [1.0, 0.0, 0.6265],
      [1.0, 0.0, 0.6033],
      [1.0, 0.0, 0.5801],
      [1.0, 0.0, 0.557],
      [1.0, 0.0, 0.5338],
      [1.0, 0.0, 0.5107],
      [1.0, 0.0, 0.4875],
      [1.0, 0.0, 0.4643],
      [1.0, 0.0, 0.4412],
      [1.0, 0.0, 0.418],
      [1.0, 0.0, 0.3949],
      [1.0, 0.0, 0.3717],
      [1.0, 0.0, 0.3485],
      [1.0, 0.0, 0.3254],
      [1.0, 0.0, 0.3022],
      [1.0, 0.0, 0.279],
      [1.0, 0.0, 0.2559],
      [1.0, 0.0, 0.2327],
      [1.0, 0.0, 0.2096],
      [1.0, 0.0, 0.1864],
      [1.0, 0.0, 0.1632],
      [1.0, 0.0, 0.1401],
      [1.0, 0.0, 0.1169],
      [1.0, 0.0, 0.0938],
    ],
  },
  inferno: {
    interpolate: true,
    colors: [
      [0.0015, 0.0005, 0.0139],
      [0.0023, 0.0013, 0.0186],
      [0.0033, 0.0022, 0.0242],
      [0.0045, 0.0034, 0.0309],
      [0.006, 0.0047, 0.0386],
      [0.0077, 0.0061, 0.0468],
      [0.0096, 0.0077, 0.0551],
      [0.0117, 0.0094, 0.0635],
      [0.014, 0.0112, 0.0719],
      [0.0166, 0.0131, 0.0803],
      [0.0194, 0.0151, 0.0888],
      [0.0224, 0.0172, 0.0973],
      [0.0258, 0.0193, 0.1059],
      [0.0294, 0.0215, 0.1146],
      [0.0334, 0.0237, 0.1234],
      [0.0377, 0.0259, 0.1322],
      [0.0423, 0.0281, 0.1411],
      [0.0469, 0.0303, 0.1502],
      [0.0516, 0.0325, 0.1593],
      [0.0564, 0.0346, 0.1684],
      [0.0613, 0.0366, 0.1776],
      [0.0663, 0.0385, 0.187],
      [0.0714, 0.0403, 0.1964],
      [0.0766, 0.0419, 0.2058],
      [0.082, 0.0433, 0.2153],
      [0.0874, 0.0446, 0.2248],
      [0.093, 0.0456, 0.2344],
      [0.0987, 0.0464, 0.2439],
      [0.1046, 0.047, 0.2534],
      [0.1105, 0.0474, 0.2629],
      [0.1167, 0.0476, 0.2723],
      [0.1229, 0.0475, 0.2816],
      [0.1293, 0.0473, 0.2908],
      [0.1358, 0.0469, 0.2998],
      [0.1424, 0.0462, 0.3086],
      [0.1491, 0.0455, 0.3171],
      [0.1558, 0.0446, 0.3253],
      [0.1627, 0.0436, 0.3333],
      [0.1696, 0.0425, 0.3409],
      [0.1765, 0.0414, 0.3481],
      [0.1834, 0.0403, 0.355],
      [0.1904, 0.0393, 0.3614],
      [0.1973, 0.0384, 0.3675],
      [0.2042, 0.0376, 0.3732],
      [0.2111, 0.037, 0.3786],
      [0.2179, 0.0366, 0.3835],
      [0.2248, 0.0364, 0.3881],
      [0.2315, 0.0364, 0.3924],
      [0.2383, 0.0366, 0.3964],
      [0.245, 0.0371, 0.4],
      [0.2516, 0.0377, 0.4034],
      [0.2582, 0.0386, 0.4065],
      [0.2648, 0.0396, 0.4093],
      [0.2713, 0.0409, 0.412],
      [0.2778, 0.0424, 0.4144],
      [0.2843, 0.0439, 0.4166],
      [0.2908, 0.0456, 0.4186],
      [0.2972, 0.0475, 0.4205],
      [0.3036, 0.0494, 0.4222],
      [0.3099, 0.0514, 0.4237],
      [0.3163, 0.0535, 0.4251],
      [0.3226, 0.0556, 0.4264],
      [0.3289, 0.0578, 0.4275],
      [0.3352, 0.0601, 0.4285],
      [0.3415, 0.0623, 0.4294],
      [0.3478, 0.0646, 0.4302],
      [0.354, 0.0669, 0.4309],
      [0.3603, 0.0692, 0.4315],
      [0.3665, 0.0716, 0.432],
      [0.3728, 0.0739, 0.4324],
      [0.379, 0.0763, 0.4327],
      [0.3852, 0.0786, 0.433],
      [0.3915, 0.0809, 0.4331],
      [0.3977, 0.0833, 0.4332],
      [0.4039, 0.0856, 0.4332],
      [0.4101, 0.0879, 0.4331],
      [0.4163, 0.0902, 0.4329],
      [0.4225, 0.0925, 0.4327],
      [0.4288, 0.0948, 0.4324],
      [0.435, 0.0971, 0.432],
      [0.4412, 0.0993, 0.4316],
      [0.4474, 0.1016, 0.4311],
      [0.4537, 0.1038, 0.4305],
      [0.4599, 0.1061, 0.4298],
      [0.4661, 0.1083, 0.4291],
      [0.4723, 0.1105, 0.4283],
      [0.4786, 0.1128, 0.4275],
      [0.4848, 0.115, 0.4265],
      [0.491, 0.1172, 0.4256],
      [0.4973, 0.1194, 0.4245],
      [0.5035, 0.1216, 0.4234],
      [0.5097, 0.1238, 0.4222],
      [0.516, 0.126, 0.4209],
      [0.5222, 0.1281, 0.4195],
      [0.5284, 0.1303, 0.4181],
      [0.5347, 0.1325, 0.4167],
      [0.5409, 0.1347, 0.4151],
      [0.5472, 0.1369, 0.4135],
      [0.5534, 0.1391, 0.4118],
      [0.5596, 0.1413, 0.4101],
      [0.5659, 0.1436, 0.4083],
      [0.5721, 0.1458, 0.4064],
      [0.5783, 0.148, 0.4044],
      [0.5845, 0.1503, 0.4024],
      [0.5907, 0.1526, 0.4003],
      [0.5969, 0.1548, 0.3981],
      [0.6031, 0.1572, 0.3959],
      [0.6093, 0.1595, 0.3936],
      [0.6155, 0.1618, 0.3912],
      [0.6217, 0.1642, 0.3888],
      [0.6278, 0.1666, 0.3863],
      [0.634, 0.169, 0.3837],
      [0.6401, 0.1714, 0.3811],
      [0.6463, 0.1739, 0.3784],
      [0.6524, 0.1764, 0.3756],
      [0.6585, 0.179, 0.3727],
      [0.6645, 0.1815, 0.3698],
      [0.6706, 0.1842, 0.3669],
      [0.6766, 0.1868, 0.3638],
      [0.6827, 0.1895, 0.3608],
      [0.6887, 0.1922, 0.3576],
      [0.6946, 0.195, 0.3544],
      [0.7006, 0.1979, 0.3511],
      [0.7065, 0.2007, 0.3478],
      [0.7124, 0.2037, 0.3444],
      [0.7183, 0.2066, 0.3409],
      [0.7241, 0.2097, 0.3374],
      [0.7299, 0.2128, 0.3339],
      [0.7357, 0.2159, 0.3302],
      [0.7414, 0.2191, 0.3266],
      [0.7471, 0.2224, 0.3229],
      [0.7528, 0.2257, 0.3191],
      [0.7584, 0.2291, 0.3153],
      [0.764, 0.2326, 0.3114],
      [0.7696, 0.2361, 0.3075],
      [0.7751, 0.2397, 0.3035],
      [0.7805, 0.2433, 0.2995],
      [0.7859, 0.2471, 0.2955],
      [0.7913, 0.2509, 0.2914],
      [0.7966, 0.2547, 0.2873],
      [0.8019, 0.2587, 0.2831],
      [0.8071, 0.2627, 0.2789],
      [0.8122, 0.2668, 0.2747],
      [0.8173, 0.271, 0.2704],
      [0.8224, 0.2752, 0.2661],
      [0.8274, 0.2795, 0.2618],
      [0.8323, 0.2839, 0.2574],
      [0.8372, 0.2884, 0.253],
      [0.842, 0.2929, 0.2486],
      [0.8467, 0.2976, 0.2441],
      [0.8514, 0.3023, 0.2396],
      [0.856, 0.307, 0.2351],
      [0.8605, 0.3119, 0.2306],
      [0.865, 0.3168, 0.2261],
      [0.8694, 0.3218, 0.2215],
      [0.8737, 0.3269, 0.2169],
      [0.878, 0.3321, 0.2123],
      [0.8822, 0.3373, 0.2076],
      [0.8863, 0.3426, 0.203],
      [0.8903, 0.348, 0.1983],
      [0.8943, 0.3534, 0.1936],
      [0.8982, 0.3589, 0.1889],
      [0.902, 0.3645, 0.1841],
      [0.9057, 0.3701, 0.1794],
      [0.9094, 0.3759, 0.1746],
      [0.913, 0.3816, 0.1698],
      [0.9165, 0.3875, 0.1649],
      [0.9199, 0.3934, 0.1601],
      [0.9232, 0.3994, 0.1552],
      [0.9265, 0.4054, 0.1503],
      [0.9296, 0.4115, 0.1454],
      [0.9327, 0.4176, 0.1404],
      [0.9357, 0.4238, 0.1354],
      [0.9387, 0.4301, 0.1304],
      [0.9415, 0.4364, 0.1254],
      [0.9443, 0.4428, 0.1204],
      [0.947, 0.4492, 0.1153],
      [0.9496, 0.4557, 0.1102],
      [0.9521, 0.4622, 0.105],
      [0.9545, 0.4687, 0.0999],
      [0.9569, 0.4754, 0.0947],
      [0.9591, 0.482, 0.0895],
      [0.9613, 0.4887, 0.0843],
      [0.9634, 0.4955, 0.0791],
      [0.9654, 0.5022, 0.0739],
      [0.9673, 0.5091, 0.0687],
      [0.9692, 0.5159, 0.0635],
      [0.9709, 0.5229, 0.0584],
      [0.9726, 0.5298, 0.0533],
      [0.9742, 0.5368, 0.0484],
      [0.9757, 0.5438, 0.0436],
      [0.9771, 0.5508, 0.039],
      [0.9784, 0.5579, 0.0349],
      [0.9797, 0.5651, 0.0314],
      [0.9808, 0.5722, 0.0285],
      [0.9819, 0.5794, 0.0262],
      [0.9829, 0.5866, 0.0247],
      [0.9838, 0.5938, 0.0238],
      [0.9846, 0.6011, 0.0236],
      [0.9853, 0.6084, 0.0242],
      [0.986, 0.6158, 0.0256],
      [0.9865, 0.6231, 0.0278],
      [0.987, 0.6305, 0.0309],
      [0.9873, 0.6379, 0.0349],
      [0.9876, 0.6453, 0.0399],
      [0.9878, 0.6528, 0.0456],
      [0.9879, 0.6602, 0.0518],
      [0.9879, 0.6677, 0.0583],
      [0.9879, 0.6753, 0.0653],
      [0.9877, 0.6828, 0.0725],
      [0.9875, 0.6904, 0.08],
      [0.9871, 0.6979, 0.0877],
      [0.9867, 0.7055, 0.0957],
      [0.9862, 0.7132, 0.1039],
      [0.9856, 0.7208, 0.1122],
      [0.9849, 0.7284, 0.1208],
      [0.9841, 0.7361, 0.1295],
      [0.9832, 0.7438, 0.1385],
      [0.9822, 0.7514, 0.1476],
      [0.9812, 0.7591, 0.1569],
      [0.98, 0.7668, 0.1664],
      [0.9788, 0.7745, 0.176],
      [0.9775, 0.7823, 0.1859],
      [0.9761, 0.79, 0.196],
      [0.9746, 0.7977, 0.2063],
      [0.9731, 0.8054, 0.2169],
      [0.9715, 0.8131, 0.2277],
      [0.9698, 0.8208, 0.2387],
      [0.968, 0.8285, 0.25],
      [0.9662, 0.8362, 0.2615],
      [0.9644, 0.8438, 0.2734],
      [0.9625, 0.8515, 0.2855],
      [0.9606, 0.8591, 0.298],
      [0.9587, 0.8666, 0.3108],
      [0.9568, 0.8741, 0.324],
      [0.955, 0.8816, 0.3375],
      [0.9532, 0.8889, 0.3514],
      [0.9515, 0.8962, 0.3656],
      [0.95, 0.9034, 0.3803],
      [0.9487, 0.9105, 0.3953],
      [0.9476, 0.9174, 0.4107],
      [0.9468, 0.9242, 0.4264],
      [0.9464, 0.9308, 0.4424],
      [0.9464, 0.9372, 0.4586],
      [0.9469, 0.9433, 0.475],
      [0.9479, 0.9493, 0.4914],
      [0.9495, 0.9551, 0.5079],
      [0.9517, 0.9606, 0.5242],
      [0.9545, 0.9659, 0.5404],
      [0.9579, 0.971, 0.5563],
      [0.9618, 0.9759, 0.5719],
      [0.9662, 0.9807, 0.5872],
      [0.9712, 0.9853, 0.6022],
      [0.9765, 0.9898, 0.6168],
      [0.9823, 0.9941, 0.631],
      [0.9884, 0.9984, 0.6449],
    ],
  },
  jet: {
    interpolate: true,
    colors: [
      [0.0, 0.0, 0.5],
      [0.0, 0.0, 0.5178],
      [0.0, 0.0, 0.5357],
      [0.0, 0.0, 0.5535],
      [0.0, 0.0, 0.5713],
      [0.0, 0.0, 0.5891],
      [0.0, 0.0, 0.607],
      [0.0, 0.0, 0.6248],
      [0.0, 0.0, 0.6426],
      [0.0, 0.0, 0.6604],
      [0.0, 0.0, 0.6783],
      [0.0, 0.0, 0.6961],
      [0.0, 0.0, 0.7139],
      [0.0, 0.0, 0.7317],
      [0.0, 0.0, 0.7496],
      [0.0, 0.0, 0.7674],
      [0.0, 0.0, 0.7852],
      [0.0, 0.0, 0.803],
      [0.0, 0.0, 0.8209],
      [0.0, 0.0, 0.8387],
      [0.0, 0.0, 0.8565],
      [0.0, 0.0, 0.8743],
      [0.0, 0.0, 0.8922],
      [0.0, 0.0, 0.91],
      [0.0, 0.0, 0.9278],
      [0.0, 0.0, 0.9456],
      [0.0, 0.0, 0.9635],
      [0.0, 0.0, 0.9813],
      [0.0, 0.0, 0.9991],
      [0.0, 0.0, 1.0],
      [0.0, 0.0, 1.0],
      [0.0, 0.0, 1.0],
      [0.0, 0.002, 1.0],
      [0.0, 0.0176, 1.0],
      [0.0, 0.0333, 1.0],
      [0.0, 0.049, 1.0],
      [0.0, 0.0647, 1.0],
      [0.0, 0.0804, 1.0],
      [0.0, 0.0961, 1.0],
      [0.0, 0.1118, 1.0],
      [0.0, 0.1275, 1.0],
      [0.0, 0.1431, 1.0],
      [0.0, 0.1588, 1.0],
      [0.0, 0.1745, 1.0],
      [0.0, 0.1902, 1.0],
      [0.0, 0.2059, 1.0],
      [0.0, 0.2216, 1.0],
      [0.0, 0.2373, 1.0],
      [0.0, 0.2529, 1.0],
      [0.0, 0.2686, 1.0],
      [0.0, 0.2843, 1.0],
      [0.0, 0.3, 1.0],
      [0.0, 0.3157, 1.0],
      [0.0, 0.3314, 1.0],
      [0.0, 0.3471, 1.0],
      [0.0, 0.3627, 1.0],
      [0.0, 0.3784, 1.0],
      [0.0, 0.3941, 1.0],
      [0.0, 0.4098, 1.0],
      [0.0, 0.4255, 1.0],
      [0.0, 0.4412, 1.0],
      [0.0, 0.4569, 1.0],
      [0.0, 0.4725, 1.0],
      [0.0, 0.4882, 1.0],
      [0.0, 0.5039, 1.0],
      [0.0, 0.5196, 1.0],
      [0.0, 0.5353, 1.0],
      [0.0, 0.551, 1.0],
      [0.0, 0.5667, 1.0],
      [0.0, 0.5824, 1.0],
      [0.0, 0.598, 1.0],
      [0.0, 0.6137, 1.0],
      [0.0, 0.6294, 1.0],
      [0.0, 0.6451, 1.0],
      [0.0, 0.6608, 1.0],
      [0.0, 0.6765, 1.0],
      [0.0, 0.6922, 1.0],
      [0.0, 0.7078, 1.0],
      [0.0, 0.7235, 1.0],
      [0.0, 0.7392, 1.0],
      [0.0, 0.7549, 1.0],
      [0.0, 0.7706, 1.0],
      [0.0, 0.7863, 1.0],
      [0.0, 0.802, 1.0],
      [0.0, 0.8176, 1.0],
      [0.0, 0.8333, 1.0],
      [0.0, 0.849, 1.0],
      [0.0, 0.8647, 0.9962],
      [0.0, 0.8804, 0.9836],
      [0.0, 0.8961, 0.9709],
      [0.0095, 0.9118, 0.9583],
      [0.0221, 0.9275, 0.9456],
      [0.0348, 0.9431, 0.933],
      [0.0474, 0.9588, 0.9203],
      [0.0601, 0.9745, 0.9077],
      [0.0727, 0.9902, 0.895],
      [0.0854, 1.0, 0.8824],
      [0.098, 1.0, 0.8697],
      [0.1107, 1.0, 0.8571],
      [0.1233, 1.0, 0.8444],
      [0.136, 1.0, 0.8318],
      [0.1486, 1.0, 0.8191],
      [0.1613, 1.0, 0.8065],
      [0.1739, 1.0, 0.7938],
      [0.1866, 1.0, 0.7812],
      [0.1992, 1.0, 0.7685],
      [0.2119, 1.0, 0.7559],
      [0.2245, 1.0, 0.7432],
      [0.2372, 1.0, 0.7306],
      [0.2498, 1.0, 0.7179],
      [0.2625, 1.0, 0.7052],
      [0.2751, 1.0, 0.6926],
      [0.2878, 1.0, 0.6799],
      [0.3004, 1.0, 0.6673],
      [0.3131, 1.0, 0.6546],
      [0.3257, 1.0, 0.642],
      [0.3384, 1.0, 0.6293],
      [0.351, 1.0, 0.6167],
      [0.3637, 1.0, 0.604],
      [0.3763, 1.0, 0.5914],
      [0.389, 1.0, 0.5787],
      [0.4016, 1.0, 0.5661],
      [0.4143, 1.0, 0.5534],
      [0.4269, 1.0, 0.5408],
      [0.4396, 1.0, 0.5281],
      [0.4522, 1.0, 0.5155],
      [0.4649, 1.0, 0.5028],
      [0.4775, 1.0, 0.4902],
      [0.4902, 1.0, 0.4775],
      [0.5028, 1.0, 0.4649],
      [0.5155, 1.0, 0.4522],
      [0.5281, 1.0, 0.4396],
      [0.5408, 1.0, 0.4269],
      [0.5534, 1.0, 0.4143],
      [0.5661, 1.0, 0.4016],
      [0.5787, 1.0, 0.389],
      [0.5914, 1.0, 0.3763],
      [0.604, 1.0, 0.3637],
      [0.6167, 1.0, 0.351],
      [0.6293, 1.0, 0.3384],
      [0.642, 1.0, 0.3257],
      [0.6546, 1.0, 0.3131],
      [0.6673, 1.0, 0.3004],
      [0.6799, 1.0, 0.2878],
      [0.6926, 1.0, 0.2751],
      [0.7052, 1.0, 0.2625],
      [0.7179, 1.0, 0.2498],
      [0.7306, 1.0, 0.2372],
      [0.7432, 1.0, 0.2245],
      [0.7559, 1.0, 0.2119],
      [0.7685, 1.0, 0.1992],
      [0.7812, 1.0, 0.1866],
      [0.7938, 1.0, 0.1739],
      [0.8065, 1.0, 0.1613],
      [0.8191, 1.0, 0.1486],
      [0.8318, 1.0, 0.136],
      [0.8444, 1.0, 0.1233],
      [0.8571, 1.0, 0.1107],
      [0.8697, 1.0, 0.098],
      [0.8824, 1.0, 0.0854],
      [0.895, 1.0, 0.0727],
      [0.9077, 1.0, 0.0601],
      [0.9203, 1.0, 0.0474],
      [0.933, 1.0, 0.0348],
      [0.9456, 0.9884, 0.0221],
      [0.9583, 0.9739, 0.0095],
      [0.9709, 0.9593, 0.0],
      [0.9836, 0.9448, 0.0],
      [0.9962, 0.9303, 0.0],
      [1.0, 0.9158, 0.0],
      [1.0, 0.9012, 0.0],
      [1.0, 0.8867, 0.0],
      [1.0, 0.8722, 0.0],
      [1.0, 0.8577, 0.0],
      [1.0, 0.8431, 0.0],
      [1.0, 0.8286, 0.0],
      [1.0, 0.8141, 0.0],
      [1.0, 0.7996, 0.0],
      [1.0, 0.785, 0.0],
      [1.0, 0.7705, 0.0],
      [1.0, 0.756, 0.0],
      [1.0, 0.7415, 0.0],
      [1.0, 0.7269, 0.0],
      [1.0, 0.7124, 0.0],
      [1.0, 0.6979, 0.0],
      [1.0, 0.6834, 0.0],
      [1.0, 0.6688, 0.0],
      [1.0, 0.6543, 0.0],
      [1.0, 0.6398, 0.0],
      [1.0, 0.6253, 0.0],
      [1.0, 0.6107, 0.0],
      [1.0, 0.5962, 0.0],
      [1.0, 0.5817, 0.0],
      [1.0, 0.5672, 0.0],
      [1.0, 0.5527, 0.0],
      [1.0, 0.5381, 0.0],
      [1.0, 0.5236, 0.0],
      [1.0, 0.5091, 0.0],
      [1.0, 0.4946, 0.0],
      [1.0, 0.48, 0.0],
      [1.0, 0.4655, 0.0],
      [1.0, 0.451, 0.0],
      [1.0, 0.4365, 0.0],
      [1.0, 0.4219, 0.0],
      [1.0, 0.4074, 0.0],
      [1.0, 0.3929, 0.0],
      [1.0, 0.3784, 0.0],
      [1.0, 0.3638, 0.0],
      [1.0, 0.3493, 0.0],
      [1.0, 0.3348, 0.0],
      [1.0, 0.3203, 0.0],
      [1.0, 0.3057, 0.0],
      [1.0, 0.2912, 0.0],
      [1.0, 0.2767, 0.0],
      [1.0, 0.2622, 0.0],
      [1.0, 0.2476, 0.0],
      [1.0, 0.2331, 0.0],
      [1.0, 0.2186, 0.0],
      [1.0, 0.2041, 0.0],
      [1.0, 0.1895, 0.0],
      [1.0, 0.175, 0.0],
      [1.0, 0.1605, 0.0],
      [1.0, 0.146, 0.0],
      [1.0, 0.1314, 0.0],
      [1.0, 0.1169, 0.0],
      [1.0, 0.1024, 0.0],
      [1.0, 0.0879, 0.0],
      [0.9991, 0.0733, 0.0],
      [0.9813, 0.0588, 0.0],
      [0.9635, 0.0443, 0.0],
      [0.9456, 0.0298, 0.0],
      [0.9278, 0.0153, 0.0],
      [0.91, 0.0007, 0.0],
      [0.8922, 0.0, 0.0],
      [0.8743, 0.0, 0.0],
      [0.8565, 0.0, 0.0],
      [0.8387, 0.0, 0.0],
      [0.8209, 0.0, 0.0],
      [0.803, 0.0, 0.0],
      [0.7852, 0.0, 0.0],
      [0.7674, 0.0, 0.0],
      [0.7496, 0.0, 0.0],
      [0.7317, 0.0, 0.0],
      [0.7139, 0.0, 0.0],
      [0.6961, 0.0, 0.0],
      [0.6783, 0.0, 0.0],
      [0.6604, 0.0, 0.0],
      [0.6426, 0.0, 0.0],
      [0.6248, 0.0, 0.0],
      [0.607, 0.0, 0.0],
      [0.5891, 0.0, 0.0],
      [0.5713, 0.0, 0.0],
      [0.5535, 0.0, 0.0],
      [0.5357, 0.0, 0.0],
      [0.5178, 0.0, 0.0],
      [0.5, 0.0, 0.0],
    ],
  },
  rainbow: {
    interpolate: true,
    colors: [
      [0.5, 0.0, 1.0],
      [0.4922, 0.0123, 1.0],
      [0.4843, 0.0246, 0.9999],
      [0.4765, 0.037, 0.9998],
      [0.4686, 0.0493, 0.9997],
      [0.4608, 0.0616, 0.9995],
      [0.4529, 0.0739, 0.9993],
      [0.4451, 0.0861, 0.9991],
      [0.4373, 0.0984, 0.9988],
      [0.4294, 0.1107, 0.9985],
      [0.4216, 0.1229, 0.9981],
      [0.4137, 0.1351, 0.9977],
      [0.4059, 0.1473, 0.9973],
      [0.398, 0.1595, 0.9968],
      [0.3902, 0.1716, 0.9963],
      [0.3824, 0.1837, 0.9957],
      [0.3745, 0.1958, 0.9951],
      [0.3667, 0.2079, 0.9945],
      [0.3588, 0.2199, 0.9939],
      [0.351, 0.2319, 0.9932],
      [0.3431, 0.2439, 0.9924],
      [0.3353, 0.2558, 0.9916],
      [0.3275, 0.2677, 0.9908],
      [0.3196, 0.2796, 0.99],
      [0.3118, 0.2914, 0.9891],
      [0.3039, 0.3032, 0.9882],
      [0.2961, 0.3149, 0.9872],
      [0.2882, 0.3265, 0.9862],
      [0.2804, 0.3382, 0.9852],
      [0.2725, 0.3497, 0.9841],
      [0.2647, 0.3612, 0.983],
      [0.2569, 0.3727, 0.9818],
      [0.249, 0.3841, 0.9806],
      [0.2412, 0.3955, 0.9794],
      [0.2333, 0.4067, 0.9781],
      [0.2255, 0.418, 0.9768],
      [0.2176, 0.4291, 0.9755],
      [0.2098, 0.4402, 0.9741],
      [0.202, 0.4512, 0.9727],
      [0.1941, 0.4622, 0.9713],
      [0.1863, 0.4731, 0.9698],
      [0.1784, 0.4839, 0.9683],
      [0.1706, 0.4947, 0.9667],
      [0.1627, 0.5053, 0.9651],
      [0.1549, 0.5159, 0.9635],
      [0.1471, 0.5264, 0.9618],
      [0.1392, 0.5369, 0.9601],
      [0.1314, 0.5472, 0.9584],
      [0.1235, 0.5575, 0.9566],
      [0.1157, 0.5677, 0.9548],
      [0.1078, 0.5778, 0.9529],
      [0.1, 0.5878, 0.9511],
      [0.0922, 0.5977, 0.9491],
      [0.0843, 0.6075, 0.9472],
      [0.0765, 0.6173, 0.9452],
      [0.0686, 0.6269, 0.9432],
      [0.0608, 0.6365, 0.9411],
      [0.0529, 0.6459, 0.939],
      [0.0451, 0.6553, 0.9369],
      [0.0373, 0.6645, 0.9347],
      [0.0294, 0.6737, 0.9325],
      [0.0216, 0.6827, 0.9302],
      [0.0137, 0.6917, 0.928],
      [0.0059, 0.7005, 0.9256],
      [0.002, 0.7093, 0.9233],
      [0.0098, 0.7179, 0.9209],
      [0.0176, 0.7264, 0.9185],
      [0.0255, 0.7348, 0.916],
      [0.0333, 0.7431, 0.9135],
      [0.0412, 0.7513, 0.911],
      [0.049, 0.7594, 0.9085],
      [0.0569, 0.7674, 0.9059],
      [0.0647, 0.7752, 0.9032],
      [0.0725, 0.7829, 0.9006],
      [0.0804, 0.7905, 0.8979],
      [0.0882, 0.798, 0.8952],
      [0.0961, 0.8054, 0.8924],
      [0.1039, 0.8126, 0.8896],
      [0.1118, 0.8197, 0.8868],
      [0.1196, 0.8267, 0.8839],
      [0.1275, 0.8336, 0.881],
      [0.1353, 0.8403, 0.8781],
      [0.1431, 0.847, 0.8751],
      [0.151, 0.8534, 0.8721],
      [0.1588, 0.8598, 0.8691],
      [0.1667, 0.866, 0.866],
      [0.1745, 0.8721, 0.8629],
      [0.1824, 0.8781, 0.8598],
      [0.1902, 0.8839, 0.8566],
      [0.198, 0.8896, 0.8534],
      [0.2059, 0.8952, 0.8502],
      [0.2137, 0.9006, 0.847],
      [0.2216, 0.9059, 0.8437],
      [0.2294, 0.911, 0.8403],
      [0.2373, 0.916, 0.837],
      [0.2451, 0.9209, 0.8336],
      [0.2529, 0.9256, 0.8302],
      [0.2608, 0.9302, 0.8267],
      [0.2686, 0.9347, 0.8233],
      [0.2765, 0.939, 0.8197],
      [0.2843, 0.9432, 0.8162],
      [0.2922, 0.9472, 0.8126],
      [0.3, 0.9511, 0.809],
      [0.3078, 0.9548, 0.8054],
      [0.3157, 0.9584, 0.8017],
      [0.3235, 0.9618, 0.798],
      [0.3314, 0.9651, 0.7943],
      [0.3392, 0.9683, 0.7905],
      [0.3471, 0.9713, 0.7867],
      [0.3549, 0.9741, 0.7829],
      [0.3627, 0.9768, 0.7791],
      [0.3706, 0.9794, 0.7752],
      [0.3784, 0.9818, 0.7713],
      [0.3863, 0.9841, 0.7674],
      [0.3941, 0.9862, 0.7634],
      [0.402, 0.9882, 0.7594],
      [0.4098, 0.99, 0.7554],
      [0.4176, 0.9916, 0.7513],
      [0.4255, 0.9932, 0.7473],
      [0.4333, 0.9945, 0.7431],
      [0.4412, 0.9957, 0.739],
      [0.449, 0.9968, 0.7348],
      [0.4569, 0.9977, 0.7307],
      [0.4647, 0.9985, 0.7264],
      [0.4725, 0.9991, 0.7222],
      [0.4804, 0.9995, 0.7179],
      [0.4882, 0.9998, 0.7136],
      [0.4961, 1.0, 0.7093],
      [0.5039, 1.0, 0.7049],
      [0.5118, 0.9998, 0.7005],
      [0.5196, 0.9995, 0.6961],
      [0.5275, 0.9991, 0.6917],
      [0.5353, 0.9985, 0.6872],
      [0.5431, 0.9977, 0.6827],
      [0.551, 0.9968, 0.6782],
      [0.5588, 0.9957, 0.6737],
      [0.5667, 0.9945, 0.6691],
      [0.5745, 0.9932, 0.6645],
      [0.5824, 0.9916, 0.6599],
      [0.5902, 0.99, 0.6553],
      [0.598, 0.9882, 0.6506],
      [0.6059, 0.9862, 0.6459],
      [0.6137, 0.9841, 0.6412],
      [0.6216, 0.9818, 0.6365],
      [0.6294, 0.9794, 0.6317],
      [0.6373, 0.9768, 0.6269],
      [0.6451, 0.9741, 0.6221],
      [0.6529, 0.9713, 0.6173],
      [0.6608, 0.9683, 0.6124],
      [0.6686, 0.9651, 0.6075],
      [0.6765, 0.9618, 0.6026],
      [0.6843, 0.9584, 0.5977],
      [0.6922, 0.9548, 0.5928],
      [0.7, 0.9511, 0.5878],
      [0.7078, 0.9472, 0.5828],
      [0.7157, 0.9432, 0.5778],
      [0.7235, 0.939, 0.5727],
      [0.7314, 0.9347, 0.5677],
      [0.7392, 0.9302, 0.5626],
      [0.7471, 0.9256, 0.5575],
      [0.7549, 0.9209, 0.5524],
      [0.7627, 0.916, 0.5472],
      [0.7706, 0.911, 0.5421],
      [0.7784, 0.9059, 0.5369],
      [0.7863, 0.9006, 0.5317],
      [0.7941, 0.8952, 0.5264],
      [0.802, 0.8896, 0.5212],
      [0.8098, 0.8839, 0.5159],
      [0.8176, 0.8781, 0.5106],
      [0.8255, 0.8721, 0.5053],
      [0.8333, 0.866, 0.5],
      [0.8412, 0.8598, 0.4947],
      [0.849, 0.8534, 0.4893],
      [0.8569, 0.847, 0.4839],
      [0.8647, 0.8403, 0.4785],
      [0.8725, 0.8336, 0.4731],
      [0.8804, 0.8267, 0.4677],
      [0.8882, 0.8197, 0.4622],
      [0.8961, 0.8126, 0.4567],
      [0.9039, 0.8054, 0.4512],
      [0.9118, 0.798, 0.4457],
      [0.9196, 0.7905, 0.4402],
      [0.9275, 0.7829, 0.4347],
      [0.9353, 0.7752, 0.4291],
      [0.9431, 0.7674, 0.4235],
      [0.951, 0.7594, 0.418],
      [0.9588, 0.7513, 0.4124],
      [0.9667, 0.7431, 0.4067],
      [0.9745, 0.7348, 0.4011],
      [0.9824, 0.7264, 0.3955],
      [0.9902, 0.7179, 0.3898],
      [0.998, 0.7093, 0.3841],
      [1.0, 0.7005, 0.3784],
      [1.0, 0.6917, 0.3727],
      [1.0, 0.6827, 0.367],
      [1.0, 0.6737, 0.3612],
      [1.0, 0.6645, 0.3555],
      [1.0, 0.6553, 0.3497],
      [1.0, 0.6459, 0.3439],
      [1.0, 0.6365, 0.3382],
      [1.0, 0.6269, 0.3324],
      [1.0, 0.6173, 0.3265],
      [1.0, 0.6075, 0.3207],
      [1.0, 0.5977, 0.3149],
      [1.0, 0.5878, 0.309],
      [1.0, 0.5778, 0.3032],
      [1.0, 0.5677, 0.2973],
      [1.0, 0.5575, 0.2914],
      [1.0, 0.5472, 0.2855],
      [1.0, 0.5369, 0.2796],
      [1.0, 0.5264, 0.2737],
      [1.0, 0.5159, 0.2677],
      [1.0, 0.5053, 0.2618],
      [1.0, 0.4947, 0.2558],
      [1.0, 0.4839, 0.2499],
      [1.0, 0.4731, 0.2439],
      [1.0, 0.4622, 0.2379],
      [1.0, 0.4512, 0.2319],
      [1.0, 0.4402, 0.226],
      [1.0, 0.4291, 0.2199],
      [1.0, 0.418, 0.2139],
      [1.0, 0.4067, 0.2079],
      [1.0, 0.3955, 0.2019],
      [1.0, 0.3841, 0.1958],
      [1.0, 0.3727, 0.1898],
      [1.0, 0.3612, 0.1837],
      [1.0, 0.3497, 0.1777],
      [1.0, 0.3382, 0.1716],
      [1.0, 0.3265, 0.1656],
      [1.0, 0.3149, 0.1595],
      [1.0, 0.3032, 0.1534],
      [1.0, 0.2914, 0.1473],
      [1.0, 0.2796, 0.1412],
      [1.0, 0.2677, 0.1351],
      [1.0, 0.2558, 0.129],
      [1.0, 0.2439, 0.1229],
      [1.0, 0.2319, 0.1168],
      [1.0, 0.2199, 0.1107],
      [1.0, 0.2079, 0.1045],
      [1.0, 0.1958, 0.0984],
      [1.0, 0.1837, 0.0923],
      [1.0, 0.1716, 0.0861],
      [1.0, 0.1595, 0.08],
      [1.0, 0.1473, 0.0739],
      [1.0, 0.1351, 0.0677],
      [1.0, 0.1229, 0.0616],
      [1.0, 0.1107, 0.0554],
      [1.0, 0.0984, 0.0493],
      [1.0, 0.0861, 0.0431],
      [1.0, 0.0739, 0.037],
      [1.0, 0.0616, 0.0308],
      [1.0, 0.0493, 0.0246],
      [1.0, 0.037, 0.0185],
      [1.0, 0.0246, 0.0123],
      [1.0, 0.0123, 0.0062],
      [1.0, 0.0, 0.0],
    ],
  },
  spring: {
    interpolate: true,
    colors: [
      [1.0, 0.0, 1.0],
      [1.0, 0.0039, 0.9961],
      [1.0, 0.0078, 0.9922],
      [1.0, 0.0118, 0.9882],
      [1.0, 0.0157, 0.9843],
      [1.0, 0.0196, 0.9804],
      [1.0, 0.0235, 0.9765],
      [1.0, 0.0275, 0.9725],
      [1.0, 0.0314, 0.9686],
      [1.0, 0.0353, 0.9647],
      [1.0, 0.0392, 0.9608],
      [1.0, 0.0431, 0.9569],
      [1.0, 0.0471, 0.9529],
      [1.0, 0.051, 0.949],
      [1.0, 0.0549, 0.9451],
      [1.0, 0.0588, 0.9412],
      [1.0, 0.0627, 0.9373],
      [1.0, 0.0667, 0.9333],
      [1.0, 0.0706, 0.9294],
      [1.0, 0.0745, 0.9255],
      [1.0, 0.0784, 0.9216],
      [1.0, 0.0824, 0.9176],
      [1.0, 0.0863, 0.9137],
      [1.0, 0.0902, 0.9098],
      [1.0, 0.0941, 0.9059],
      [1.0, 0.098, 0.902],
      [1.0, 0.102, 0.898],
      [1.0, 0.1059, 0.8941],
      [1.0, 0.1098, 0.8902],
      [1.0, 0.1137, 0.8863],
      [1.0, 0.1176, 0.8824],
      [1.0, 0.1216, 0.8784],
      [1.0, 0.1255, 0.8745],
      [1.0, 0.1294, 0.8706],
      [1.0, 0.1333, 0.8667],
      [1.0, 0.1373, 0.8627],
      [1.0, 0.1412, 0.8588],
      [1.0, 0.1451, 0.8549],
      [1.0, 0.149, 0.851],
      [1.0, 0.1529, 0.8471],
      [1.0, 0.1569, 0.8431],
      [1.0, 0.1608, 0.8392],
      [1.0, 0.1647, 0.8353],
      [1.0, 0.1686, 0.8314],
      [1.0, 0.1725, 0.8275],
      [1.0, 0.1765, 0.8235],
      [1.0, 0.1804, 0.8196],
      [1.0, 0.1843, 0.8157],
      [1.0, 0.1882, 0.8118],
      [1.0, 0.1922, 0.8078],
      [1.0, 0.1961, 0.8039],
      [1.0, 0.2, 0.8],
      [1.0, 0.2039, 0.7961],
      [1.0, 0.2078, 0.7922],
      [1.0, 0.2118, 0.7882],
      [1.0, 0.2157, 0.7843],
      [1.0, 0.2196, 0.7804],
      [1.0, 0.2235, 0.7765],
      [1.0, 0.2275, 0.7725],
      [1.0, 0.2314, 0.7686],
      [1.0, 0.2353, 0.7647],
      [1.0, 0.2392, 0.7608],
      [1.0, 0.2431, 0.7569],
      [1.0, 0.2471, 0.7529],
      [1.0, 0.251, 0.749],
      [1.0, 0.2549, 0.7451],
      [1.0, 0.2588, 0.7412],
      [1.0, 0.2627, 0.7373],
      [1.0, 0.2667, 0.7333],
      [1.0, 0.2706, 0.7294],
      [1.0, 0.2745, 0.7255],
      [1.0, 0.2784, 0.7216],
      [1.0, 0.2824, 0.7176],
      [1.0, 0.2863, 0.7137],
      [1.0, 0.2902, 0.7098],
      [1.0, 0.2941, 0.7059],
      [1.0, 0.298, 0.702],
      [1.0, 0.302, 0.698],
      [1.0, 0.3059, 0.6941],
      [1.0, 0.3098, 0.6902],
      [1.0, 0.3137, 0.6863],
      [1.0, 0.3176, 0.6824],
      [1.0, 0.3216, 0.6784],
      [1.0, 0.3255, 0.6745],
      [1.0, 0.3294, 0.6706],
      [1.0, 0.3333, 0.6667],
      [1.0, 0.3373, 0.6627],
      [1.0, 0.3412, 0.6588],
      [1.0, 0.3451, 0.6549],
      [1.0, 0.349, 0.651],
      [1.0, 0.3529, 0.6471],
      [1.0, 0.3569, 0.6431],
      [1.0, 0.3608, 0.6392],
      [1.0, 0.3647, 0.6353],
      [1.0, 0.3686, 0.6314],
      [1.0, 0.3725, 0.6275],
      [1.0, 0.3765, 0.6235],
      [1.0, 0.3804, 0.6196],
      [1.0, 0.3843, 0.6157],
      [1.0, 0.3882, 0.6118],
      [1.0, 0.3922, 0.6078],
      [1.0, 0.3961, 0.6039],
      [1.0, 0.4, 0.6],
      [1.0, 0.4039, 0.5961],
      [1.0, 0.4078, 0.5922],
      [1.0, 0.4118, 0.5882],
      [1.0, 0.4157, 0.5843],
      [1.0, 0.4196, 0.5804],
      [1.0, 0.4235, 0.5765],
      [1.0, 0.4275, 0.5725],
      [1.0, 0.4314, 0.5686],
      [1.0, 0.4353, 0.5647],
      [1.0, 0.4392, 0.5608],
      [1.0, 0.4431, 0.5569],
      [1.0, 0.4471, 0.5529],
      [1.0, 0.451, 0.549],
      [1.0, 0.4549, 0.5451],
      [1.0, 0.4588, 0.5412],
      [1.0, 0.4627, 0.5373],
      [1.0, 0.4667, 0.5333],
      [1.0, 0.4706, 0.5294],
      [1.0, 0.4745, 0.5255],
      [1.0, 0.4784, 0.5216],
      [1.0, 0.4824, 0.5176],
      [1.0, 0.4863, 0.5137],
      [1.0, 0.4902, 0.5098],
      [1.0, 0.4941, 0.5059],
      [1.0, 0.498, 0.502],
      [1.0, 0.502, 0.498],
      [1.0, 0.5059, 0.4941],
      [1.0, 0.5098, 0.4902],
      [1.0, 0.5137, 0.4863],
      [1.0, 0.5176, 0.4824],
      [1.0, 0.5216, 0.4784],
      [1.0, 0.5255, 0.4745],
      [1.0, 0.5294, 0.4706],
      [1.0, 0.5333, 0.4667],
      [1.0, 0.5373, 0.4627],
      [1.0, 0.5412, 0.4588],
      [1.0, 0.5451, 0.4549],
      [1.0, 0.549, 0.451],
      [1.0, 0.5529, 0.4471],
      [1.0, 0.5569, 0.4431],
      [1.0, 0.5608, 0.4392],
      [1.0, 0.5647, 0.4353],
      [1.0, 0.5686, 0.4314],
      [1.0, 0.5725, 0.4275],
      [1.0, 0.5765, 0.4235],
      [1.0, 0.5804, 0.4196],
      [1.0, 0.5843, 0.4157],
      [1.0, 0.5882, 0.4118],
      [1.0, 0.5922, 0.4078],
      [1.0, 0.5961, 0.4039],
      [1.0, 0.6, 0.4],
      [1.0, 0.6039, 0.3961],
      [1.0, 0.6078, 0.3922],
      [1.0, 0.6118, 0.3882],
      [1.0, 0.6157, 0.3843],
      [1.0, 0.6196, 0.3804],
      [1.0, 0.6235, 0.3765],
      [1.0, 0.6275, 0.3725],
      [1.0, 0.6314, 0.3686],
      [1.0, 0.6353, 0.3647],
      [1.0, 0.6392, 0.3608],
      [1.0, 0.6431, 0.3569],
      [1.0, 0.6471, 0.3529],
      [1.0, 0.651, 0.349],
      [1.0, 0.6549, 0.3451],
      [1.0, 0.6588, 0.3412],
      [1.0, 0.6627, 0.3373],
      [1.0, 0.6667, 0.3333],
      [1.0, 0.6706, 0.3294],
      [1.0, 0.6745, 0.3255],
      [1.0, 0.6784, 0.3216],
      [1.0, 0.6824, 0.3176],
      [1.0, 0.6863, 0.3137],
      [1.0, 0.6902, 0.3098],
      [1.0, 0.6941, 0.3059],
      [1.0, 0.698, 0.302],
      [1.0, 0.702, 0.298],
      [1.0, 0.7059, 0.2941],
      [1.0, 0.7098, 0.2902],
      [1.0, 0.7137, 0.2863],
      [1.0, 0.7176, 0.2824],
      [1.0, 0.7216, 0.2784],
      [1.0, 0.7255, 0.2745],
      [1.0, 0.7294, 0.2706],
      [1.0, 0.7333, 0.2667],
      [1.0, 0.7373, 0.2627],
      [1.0, 0.7412, 0.2588],
      [1.0, 0.7451, 0.2549],
      [1.0, 0.749, 0.251],
      [1.0, 0.7529, 0.2471],
      [1.0, 0.7569, 0.2431],
      [1.0, 0.7608, 0.2392],
      [1.0, 0.7647, 0.2353],
      [1.0, 0.7686, 0.2314],
      [1.0, 0.7725, 0.2275],
      [1.0, 0.7765, 0.2235],
      [1.0, 0.7804, 0.2196],
      [1.0, 0.7843, 0.2157],
      [1.0, 0.7882, 0.2118],
      [1.0, 0.7922, 0.2078],
      [1.0, 0.7961, 0.2039],
      [1.0, 0.8, 0.2],
      [1.0, 0.8039, 0.1961],
      [1.0, 0.8078, 0.1922],
      [1.0, 0.8118, 0.1882],
      [1.0, 0.8157, 0.1843],
      [1.0, 0.8196, 0.1804],
      [1.0, 0.8235, 0.1765],
      [1.0, 0.8275, 0.1725],
      [1.0, 0.8314, 0.1686],
      [1.0, 0.8353, 0.1647],
      [1.0, 0.8392, 0.1608],
      [1.0, 0.8431, 0.1569],
      [1.0, 0.8471, 0.1529],
      [1.0, 0.851, 0.149],
      [1.0, 0.8549, 0.1451],
      [1.0, 0.8588, 0.1412],
      [1.0, 0.8627, 0.1373],
      [1.0, 0.8667, 0.1333],
      [1.0, 0.8706, 0.1294],
      [1.0, 0.8745, 0.1255],
      [1.0, 0.8784, 0.1216],
      [1.0, 0.8824, 0.1176],
      [1.0, 0.8863, 0.1137],
      [1.0, 0.8902, 0.1098],
      [1.0, 0.8941, 0.1059],
      [1.0, 0.898, 0.102],
      [1.0, 0.902, 0.098],
      [1.0, 0.9059, 0.0941],
      [1.0, 0.9098, 0.0902],
      [1.0, 0.9137, 0.0863],
      [1.0, 0.9176, 0.0824],
      [1.0, 0.9216, 0.0784],
      [1.0, 0.9255, 0.0745],
      [1.0, 0.9294, 0.0706],
      [1.0, 0.9333, 0.0667],
      [1.0, 0.9373, 0.0627],
      [1.0, 0.9412, 0.0588],
      [1.0, 0.9451, 0.0549],
      [1.0, 0.949, 0.051],
      [1.0, 0.9529, 0.0471],
      [1.0, 0.9569, 0.0431],
      [1.0, 0.9608, 0.0392],
      [1.0, 0.9647, 0.0353],
      [1.0, 0.9686, 0.0314],
      [1.0, 0.9725, 0.0275],
      [1.0, 0.9765, 0.0235],
      [1.0, 0.9804, 0.0196],
      [1.0, 0.9843, 0.0157],
      [1.0, 0.9882, 0.0118],
      [1.0, 0.9922, 0.0078],
      [1.0, 0.9961, 0.0039],
      [1.0, 1.0, 0.0],
    ],
  },
  turbo: {
    interpolate: true,
    colors: [
      [0.19, 0.0718, 0.2322],
      [0.1948, 0.0834, 0.2615],
      [0.1996, 0.095, 0.2902],
      [0.2042, 0.1065, 0.3184],
      [0.2086, 0.118, 0.3461],
      [0.2129, 0.1295, 0.3731],
      [0.2171, 0.1409, 0.3996],
      [0.2211, 0.1522, 0.4256],
      [0.225, 0.1635, 0.451],
      [0.2288, 0.1748, 0.4758],
      [0.2324, 0.186, 0.5],
      [0.2358, 0.1972, 0.5237],
      [0.2392, 0.2083, 0.5469],
      [0.2423, 0.2194, 0.5694],
      [0.2454, 0.2304, 0.5914],
      [0.2483, 0.2414, 0.6129],
      [0.2511, 0.2524, 0.6337],
      [0.2537, 0.2633, 0.6541],
      [0.2562, 0.2741, 0.6738],
      [0.2585, 0.2849, 0.693],
      [0.2607, 0.2957, 0.7116],
      [0.2628, 0.3064, 0.7297],
      [0.2647, 0.3171, 0.7472],
      [0.2665, 0.3277, 0.7641],
      [0.2682, 0.3382, 0.7805],
      [0.2697, 0.3488, 0.7963],
      [0.271, 0.3593, 0.8116],
      [0.2723, 0.3697, 0.8262],
      [0.2733, 0.3801, 0.8404],
      [0.2743, 0.3904, 0.8539],
      [0.2751, 0.4007, 0.8669],
      [0.2758, 0.411, 0.8794],
      [0.2763, 0.4212, 0.8912],
      [0.2767, 0.4313, 0.9025],
      [0.2769, 0.4414, 0.9133],
      [0.277, 0.4515, 0.9235],
      [0.277, 0.4615, 0.9331],
      [0.2768, 0.4715, 0.9421],
      [0.2765, 0.4814, 0.9506],
      [0.276, 0.4913, 0.9586],
      [0.2754, 0.5012, 0.9659],
      [0.2747, 0.5109, 0.9728],
      [0.2738, 0.5207, 0.979],
      [0.2727, 0.5304, 0.9846],
      [0.2711, 0.5402, 0.9893],
      [0.2688, 0.55, 0.993],
      [0.2659, 0.5598, 0.9958],
      [0.2625, 0.5697, 0.9977],
      [0.2586, 0.5796, 0.9988],
      [0.2542, 0.5895, 0.999],
      [0.2495, 0.5994, 0.9984],
      [0.2443, 0.6094, 0.997],
      [0.2387, 0.6193, 0.9948],
      [0.2329, 0.6292, 0.992],
      [0.2268, 0.6391, 0.9885],
      [0.2204, 0.649, 0.9844],
      [0.2138, 0.6589, 0.9796],
      [0.2071, 0.6687, 0.9742],
      [0.2002, 0.6784, 0.9683],
      [0.1933, 0.6881, 0.9619],
      [0.1862, 0.6978, 0.955],
      [0.1792, 0.7073, 0.9476],
      [0.1722, 0.7168, 0.9398],
      [0.1653, 0.7262, 0.9316],
      [0.1584, 0.7355, 0.923],
      [0.1517, 0.7447, 0.9142],
      [0.1452, 0.7538, 0.905],
      [0.1389, 0.7628, 0.8955],
      [0.1328, 0.7716, 0.8858],
      [0.127, 0.7804, 0.8759],
      [0.1215, 0.789, 0.8658],
      [0.1164, 0.7974, 0.8556],
      [0.1117, 0.8057, 0.8452],
      [0.1074, 0.8138, 0.8348],
      [0.1036, 0.8218, 0.8244],
      [0.1003, 0.8296, 0.8139],
      [0.0975, 0.8371, 0.8034],
      [0.0953, 0.8446, 0.793],
      [0.0938, 0.8518, 0.7826],
      [0.0929, 0.8588, 0.7724],
      [0.0927, 0.8655, 0.7623],
      [0.0932, 0.8721, 0.7524],
      [0.0945, 0.8784, 0.7426],
      [0.0966, 0.8845, 0.7332],
      [0.0996, 0.8904, 0.7239],
      [0.1034, 0.896, 0.715],
      [0.1082, 0.9014, 0.706],
      [0.1137, 0.9067, 0.6965],
      [0.1201, 0.9119, 0.6866],
      [0.1273, 0.917, 0.6763],
      [0.1353, 0.922, 0.6656],
      [0.1439, 0.9268, 0.6545],
      [0.1532, 0.9315, 0.6431],
      [0.1632, 0.9361, 0.6314],
      [0.1738, 0.9405, 0.6194],
      [0.1849, 0.9448, 0.6071],
      [0.1966, 0.949, 0.5947],
      [0.2088, 0.953, 0.582],
      [0.2214, 0.9569, 0.5691],
      [0.2345, 0.9606, 0.5561],
      [0.248, 0.9642, 0.543],
      [0.2618, 0.9676, 0.5298],
      [0.276, 0.9709, 0.5165],
      [0.2904, 0.974, 0.5032],
      [0.3051, 0.977, 0.4899],
      [0.3201, 0.9797, 0.4765],
      [0.3352, 0.9823, 0.4632],
      [0.3504, 0.9848, 0.45],
      [0.3658, 0.987, 0.4369],
      [0.3813, 0.9891, 0.4239],
      [0.3968, 0.991, 0.411],
      [0.4123, 0.9927, 0.3983],
      [0.4278, 0.9942, 0.3858],
      [0.4432, 0.9955, 0.3734],
      [0.4585, 0.9966, 0.3614],
      [0.4738, 0.9976, 0.3496],
      [0.4888, 0.9983, 0.3382],
      [0.5036, 0.9988, 0.327],
      [0.5182, 0.9991, 0.3162],
      [0.5326, 0.9992, 0.3058],
      [0.5466, 0.9991, 0.2958],
      [0.5603, 0.9987, 0.2862],
      [0.5736, 0.9982, 0.2771],
      [0.5865, 0.9974, 0.2685],
      [0.5989, 0.9964, 0.2604],
      [0.6109, 0.9951, 0.2528],
      [0.6223, 0.9937, 0.2458],
      [0.6332, 0.992, 0.2394],
      [0.6436, 0.99, 0.2336],
      [0.6539, 0.9878, 0.2284],
      [0.6643, 0.9852, 0.2237],
      [0.6746, 0.9825, 0.2196],
      [0.6849, 0.9794, 0.216],
      [0.6952, 0.9761, 0.2129],
      [0.7055, 0.9726, 0.2103],
      [0.7158, 0.9688, 0.2082],
      [0.726, 0.9647, 0.2064],
      [0.7361, 0.9604, 0.205],
      [0.7462, 0.9559, 0.2041],
      [0.7562, 0.9512, 0.2034],
      [0.7661, 0.9463, 0.2031],
      [0.7759, 0.9411, 0.2031],
      [0.7856, 0.9358, 0.2034],
      [0.7952, 0.9302, 0.2039],
      [0.8047, 0.9245, 0.2046],
      [0.8141, 0.9186, 0.2055],
      [0.8233, 0.9125, 0.2066],
      [0.8324, 0.9063, 0.2079],
      [0.8413, 0.8999, 0.2093],
      [0.8501, 0.8933, 0.2107],
      [0.8587, 0.8866, 0.2123],
      [0.8671, 0.8797, 0.2139],
      [0.8753, 0.8727, 0.2156],
      [0.8833, 0.8655, 0.2172],
      [0.8911, 0.8583, 0.2188],
      [0.8987, 0.8509, 0.2204],
      [0.906, 0.8434, 0.2219],
      [0.9132, 0.8358, 0.2233],
      [0.92, 0.8281, 0.2246],
      [0.9267, 0.8202, 0.2257],
      [0.933, 0.8124, 0.2267],
      [0.9391, 0.8044, 0.2274],
      [0.9449, 0.7963, 0.228],
      [0.9504, 0.7882, 0.2283],
      [0.9556, 0.78, 0.2284],
      [0.9605, 0.7718, 0.2281],
      [0.9651, 0.7635, 0.2275],
      [0.9693, 0.7552, 0.2266],
      [0.9732, 0.7468, 0.2254],
      [0.9768, 0.7384, 0.2237],
      [0.98, 0.73, 0.2216],
      [0.9829, 0.7214, 0.2192],
      [0.9855, 0.7125, 0.2165],
      [0.9878, 0.7033, 0.2136],
      [0.9899, 0.6938, 0.2104],
      [0.9916, 0.6841, 0.2071],
      [0.9931, 0.6741, 0.2035],
      [0.9944, 0.6639, 0.1997],
      [0.9954, 0.6534, 0.1958],
      [0.9961, 0.6428, 0.1916],
      [0.9965, 0.6319, 0.1874],
      [0.9968, 0.6209, 0.183],
      [0.9967, 0.6098, 0.1784],
      [0.9964, 0.5985, 0.1738],
      [0.9959, 0.587, 0.169],
      [0.9952, 0.5755, 0.1641],
      [0.9942, 0.5639, 0.1592],
      [0.993, 0.5521, 0.1542],
      [0.9915, 0.5404, 0.1491],
      [0.9899, 0.5285, 0.144],
      [0.988, 0.5167, 0.1388],
      [0.9859, 0.5048, 0.1337],
      [0.9836, 0.4929, 0.1285],
      [0.9811, 0.481, 0.1233],
      [0.9784, 0.4692, 0.1182],
      [0.9754, 0.4574, 0.113],
      [0.9723, 0.4456, 0.108],
      [0.969, 0.434, 0.1029],
      [0.9656, 0.4224, 0.098],
      [0.9619, 0.4109, 0.0931],
      [0.958, 0.3996, 0.0883],
      [0.954, 0.3884, 0.0836],
      [0.9498, 0.3773, 0.079],
      [0.9454, 0.3664, 0.0746],
      [0.9408, 0.3557, 0.0703],
      [0.9361, 0.3451, 0.0662],
      [0.9312, 0.3348, 0.0622],
      [0.9262, 0.3247, 0.0584],
      [0.921, 0.3149, 0.0548],
      [0.9157, 0.3053, 0.0513],
      [0.9102, 0.296, 0.0481],
      [0.9046, 0.287, 0.0452],
      [0.8989, 0.2782, 0.0424],
      [0.893, 0.2698, 0.0399],
      [0.8869, 0.2615, 0.0375],
      [0.8807, 0.2533, 0.0352],
      [0.8742, 0.2453, 0.033],
      [0.8676, 0.2373, 0.0308],
      [0.8608, 0.2294, 0.0288],
      [0.8538, 0.2217, 0.0268],
      [0.8466, 0.2141, 0.0249],
      [0.8393, 0.2065, 0.023],
      [0.8317, 0.1991, 0.0213],
      [0.824, 0.1918, 0.0197],
      [0.8161, 0.1846, 0.0181],
      [0.808, 0.1775, 0.0166],
      [0.7997, 0.1706, 0.0152],
      [0.7912, 0.1637, 0.0139],
      [0.7826, 0.1569, 0.0126],
      [0.7738, 0.1503, 0.0115],
      [0.7648, 0.1437, 0.0104],
      [0.7556, 0.1373, 0.0094],
      [0.7462, 0.131, 0.0085],
      [0.7366, 0.1248, 0.0077],
      [0.7269, 0.1187, 0.007],
      [0.7169, 0.1127, 0.0063],
      [0.7068, 0.1068, 0.0057],
      [0.6965, 0.101, 0.0052],
      [0.686, 0.0954, 0.0048],
      [0.6754, 0.0898, 0.0045],
      [0.6645, 0.0844, 0.0042],
      [0.6534, 0.079, 0.0041],
      [0.6422, 0.0738, 0.004],
      [0.6308, 0.0687, 0.004],
      [0.6192, 0.0637, 0.0041],
      [0.6075, 0.0588, 0.0043],
      [0.5955, 0.054, 0.0045],
      [0.5834, 0.0493, 0.0049],
      [0.571, 0.0447, 0.0053],
      [0.5585, 0.0403, 0.0058],
      [0.5458, 0.0359, 0.0064],
      [0.533, 0.0317, 0.007],
      [0.5199, 0.0276, 0.0078],
      [0.5066, 0.0235, 0.0086],
      [0.4932, 0.0196, 0.0096],
      [0.4796, 0.0158, 0.0106],
    ],
  },
};

/*
Define auxiliary functions for evaluating colormaps
 */

export function evaluate_cmap(x: number, name: string, reverse: boolean): [number, number, number] {
  /**
   * Evaluate colormap `name` at some value `x`.
   * @param {number} x - The value (between 0 and 1) at which to evaluate the colormap.
   * @param {string} name - The name of the colormap (see matplotlib documentation).
   * @reverse {boolean} reverse - Whether or not to reverse the colormap.
   * @return {list} - A 3-tuple (R, G, B) containing the color assigned to `x`.
   */

  // We can get the reverse colormap by evaluating colormap(1-x)
  if (reverse === true) {
    x = 1 - x;
  }

  // Get the colors and whether or not we need to interpolate
  let colors = data[name]['colors'];
  let interpolate = data[name]['interpolate'];

  if (interpolate === true) {
    return interpolated(x, colors);
  } else {
    return qualitative(x, colors);
  }
}

function interpolated(x: number, colors: number[][]): [number, number, number] {
  let lo = Math.floor(x * (colors.length - 1));
  let hi = Math.ceil(x * (colors.length - 1));
  let r = Math.round(((colors[lo][0] + colors[hi][0]) / 2) * 255);
  let g = Math.round(((colors[lo][1] + colors[hi][1]) / 2) * 255);
  let b = Math.round(((colors[lo][2] + colors[hi][2]) / 2) * 255);
  return [r, g, b];
}

function qualitative(x: number, colors: number[][]): [number, number, number] {
  let idx = 0;
  while (x > (idx + 1) / (colors.length - 0)) {
    idx++;
  }
  let r = Math.round(colors[idx][0] * 255);
  let g = Math.round(colors[idx][1] * 255);
  let b = Math.round(colors[idx][2] * 255);
  return [r, g, b];
}

// Local Implementation of Linspace for Generating Linearly Spaced Points in the interval 0 to 1

export function linspace(n: number): number[] {
  const step = 1 / n;
  let arr: number[] = [0];
  for (let i = 0; i < n; i++) {
    if (arr[i] + step > 1) {
      arr.push(1);
      continue;
    }
    arr.push(arr[i] + step);
  }
  return arr;
}

export function cmap({colormap, nshades}: {colormap: string; nshades: number}): [number, number, number][] {
  const points = linspace(nshades - 1);

  return points.map((e) => evaluate_cmap(e, colormap, false));
}
