import React, {useState} from 'react';
import styled from 'styled-components';
import {Box, Button, Typography} from '@material-ui/core';
import {Divider as MuiDivider, CardContent, Card as MuiCard} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {useSmallScreenSize} from '../../utils/utilHooks';
import IntensitySummaryBar from './IntensitySummaryBar/IntensitySummaryBar';
import {IntensityChartOptionsModal} from './IntensitySummaryBar/ChartOptionsModel';
import {HoveredSelection, SetHoveredSelection} from './BuildIntensityPage';

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const BuildIntensityBarGraphView = ({
  d3Color,
  hoveredSelection,
  setHoveredSelection,
}: {
  d3Color: d3.scale.Ordinal<string, string>;
  hoveredSelection: HoveredSelection;
  setHoveredSelection: SetHoveredSelection;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const [isChartModalOpen, setChartModalOpen] = useState(false);

  return (
    <>
      <Card style={{height: isSmallScreen ? '384px' : '33%'}}>
        <CardContent style={{height: '100%'}}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Intensity Summary</Typography>
            <Button variant="outlined" onClick={() => setChartModalOpen(true)} color="primary" size="small">
              Graph Options
            </Button>
          </Box>
          <Divider my={isSmallScreen ? 3 : 6} />

          <Box height={`calc(100% - ${isSmallScreen ? '54px' : '72px'})`}>
            <IntensitySummaryBar
              d3Color={d3Color}
              hoveredSelection={hoveredSelection}
              setHoveredSelection={setHoveredSelection}
            />
          </Box>
        </CardContent>
      </Card>

      <IntensityChartOptionsModal open={isChartModalOpen} onClose={() => setChartModalOpen(false)} />
    </>
  );
};
export default BuildIntensityBarGraphView;
