import React from 'react';
import styled from 'styled-components';
import {Grid, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import moment from 'moment';

import {FetchingState} from '../../../store/model/liveUpdateStore';
import {RootState} from '../../../store/reducers';
import {TimeFilter} from '@common/utils/timeFilter';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {assertUnreachable} from '@common/utils/utils';

export const BuildNumTypography = styled(Typography)`
  // font-weight: 600;
  color: grey;
  font-size: 1.75rem;
`;

export const BuildNameTypography = styled(Typography)`
  color: #4049cc;
`;

export const BuildStatusGrid = styled(Grid)`
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
`;

interface BuildStatusWidgetProps {
  builds: Array<IBuild>;
  machineFilter?: Array<string>;
  timeFilter?: TimeFilter;
}

export const BuildStatusWidgetStandalone = ({builds, machineFilter, timeFilter}: BuildStatusWidgetProps) => {
  const history = useHistory();

  const isLoading = useSelector((state: RootState) => state.buildStore.fetched === FetchingState.Fetching);

  const draft: IBuild[] = [];
  const staging: IBuild[] = [];
  const active: IBuild[] = [];
  const concluded: IBuild[] = [];

  // Filter builds by time of creation
  const filteredBuilds = Object.values(builds)
    .filter((build) => {
      if (!timeFilter?.from) return true;

      return moment(build.createdAt).isAfter(timeFilter?.from);
    })
    .filter((build) => {
      if (!timeFilter?.to) return true;
      return moment(build.createdAt).isSameOrBefore(timeFilter?.to);
    });

  for (const build of filteredBuilds) {
    switch (build.state) {
      case BuildState.DRAFT:
        draft.push(build);
        break;
      case BuildState.STAGING:
        staging.push(build);
        break;
      case BuildState.ACTIVE:
      case BuildState.PAUSED:
        active.push(build);
        break;
      case BuildState.SUCCEEDED:
      case BuildState.COMPLETED:
      case BuildState.FAILED:
        concluded.push(build);
        break;
      default:
        assertUnreachable(build.state);
    }
  }

  const OnClickHandler = (label: string) => {
    const machineQueryString = machineFilter ? machineFilter.map((query) => `&machineUuid[in]=${query}`).join('') : '';

    switch (label) {
      case 'Draft':
        history.push(`/builds/?state[in]=${BuildState.DRAFT}${machineQueryString}`);
        break;
      case 'Staging':
        history.push(`/builds/?state[in]=${BuildState.STAGING}${machineQueryString}`);
        break;
      case 'Active':
        history.push(`/builds/?state[in]=${BuildState.ACTIVE}&state[in]=${BuildState.PAUSED}${machineQueryString}`);
        break;
      case 'Concluded':
        history.push(
          `/builds/?state[in]=${BuildState.SUCCEEDED}&state[in]=${BuildState.COMPLETED}&state[in]=${BuildState.FAILED}${machineQueryString}`
        );
        break;
      default:
        // error
        break;
    }
  };

  return (
    <Grid container>
      <BuildStatusGrid xs={6} onClick={() => OnClickHandler('Draft')}>
        <BuildNumTypography align="center">{isLoading ? <Skeleton /> : draft.length}</BuildNumTypography>
        <BuildNameTypography align="center">{isLoading ? <Skeleton /> : 'Draft'}</BuildNameTypography>
      </BuildStatusGrid>
      <BuildStatusGrid xs={6} onClick={() => OnClickHandler('Staging')}>
        <BuildNumTypography align="center">{isLoading ? <Skeleton /> : staging.length}</BuildNumTypography>
        <BuildNameTypography align="center">{isLoading ? <Skeleton /> : 'Staging'}</BuildNameTypography>
      </BuildStatusGrid>
      <BuildStatusGrid xs={6} onClick={() => OnClickHandler('Active')}>
        <BuildNumTypography align="center">{isLoading ? <Skeleton /> : active.length}</BuildNumTypography>
        <BuildNameTypography align="center">{isLoading ? <Skeleton /> : 'Active'}</BuildNameTypography>
      </BuildStatusGrid>
      <BuildStatusGrid xs={6} onClick={() => OnClickHandler('Concluded')}>
        <BuildNumTypography align="center">{isLoading ? <Skeleton /> : concluded.length}</BuildNumTypography>
        <BuildNameTypography align="center">{isLoading ? <Skeleton /> : 'Concluded'}</BuildNameTypography>
      </BuildStatusGrid>
    </Grid>
  );
};
