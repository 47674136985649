import React, {useState} from 'react';
import {ChromePicker, RGBColor} from 'react-color';

function ColorPicker(props: {color: RGBColor; setColor: (val: RGBColor) => void}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: any) => {
    props.setColor({...color.rgb});
  };

  return (
    <div>
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <div
          style={{
            width: '14px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`,
          }}
        />
      </div>

      {displayColorPicker && (
        <div
          style={{
            position: 'absolute',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <ChromePicker color={props.color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
}

export default ColorPicker;
