import {DefectType} from '@common/api/models/builds/data/defects/IDefect';

export const ALL_PARTS = null as null; // Typescript and eslint conflict on this one
export const ALL_PARTS_TXT = 'All Parts';

export interface IDefectSummary {
  uuid: string;
  buildUuid: string;
  partUuid?: string;
  defectType: DefectType;
  defectArea: number;
  defectAreaGraph: {[layerNum: number]: number};
  defectCoverage?: number; // optional due to part-area availability
  defectCoverageGraph: {[layerNum: number]: number};
  updatedAt: Date;
}

export interface IDefectSummaryGETRequest {
  buildUuid?: string | {in: Array<string>} | {notIn: Array<string>};
  uuid?: string | {in: Array<string>} | {notIn: Array<string>};
  defectType: DefectType | {in: Array<DefectType>} | {notIn: Array<DefectType>};
  defectCoverage: number | {lte?: number; gte?: number};
  defectArea: number | {lte?: number; gte?: number};
  partName: string | {like: string; notLike: string};
}

export interface IDefectSummaryGETResponse extends IDefectSummary {
  name: string;
  partName?: string;
  partLayerStart?: number;
  partLayerEnd?: number;
  partAreaGraph?: {[layerNum: number]: number};
}

export type IDefectSummarySortOptions = 'partUuid' | 'buildUuid' | 'defectType' | 'defectCoverage' | 'defectArea';
