import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {RootState} from '../../store/reducers';
import {FetchingState} from '../../store/model/liveUpdateStore';
import DraftBuild from './draftBuild/DraftBuild';
import {Button} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {BuildState} from '@common/api/models/builds/IBuild';
import {assertUnreachable} from '@common/utils/utils';
import StagingBuild from './liveBuild/StagingBuild';
import ActiveBuild from './liveBuild/ActiveBuild';
import {useBuildStoreActions} from '../../store/actions';
import SubmittedStep from './draftBuild/draftBuildSteps/SubmittedStep';
import StagingCompleteBuild from './liveBuild/StagingCompleteBuild';
import LoadingPage from '../../components/organisms/LoadingPage';

function BuildNotFound() {
  const history = useHistory();

  return (
    <>
      <Alert severity="error">Build not found</Alert>
      <Button variant={'contained'} color={'primary'} style={{marginTop: 10}} onClick={() => history.push('/builds')}>
        Return to builds
      </Button>
    </>
  );
}

export function ViewBuild() {
  const {uuid, liveStep} = useParams<{uuid: string; liveStep?: string}>();

  const buildStore = useSelector((state: RootState) => state.buildStore);
  const build = buildStore.byId[uuid];
  const draftBuildIsSubmitted = !!localStorage.getItem(`${uuid}-submitted-time`);
  const stagingComplete = !!localStorage.getItem(`${uuid}-staging-complete`);

  const buildActions = useBuildStoreActions();

  useEffect(() => {
    if (uuid) {
      buildActions.ensureConsistent({uuid: uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  if (!build && [FetchingState.Fetching, FetchingState.None].includes(buildStore.fetched)) {
    return <LoadingPage loadingText="Loading Build..." />;
  } else if (!build) {
    return <BuildNotFound />;
  } else if (draftBuildIsSubmitted) {
    return <SubmittedStep build={build} />;
  } else if (stagingComplete) {
    return <StagingCompleteBuild build={build} />;
  } else {
    // Special case: make settings available in draft
    if (liveStep === 'settings') {
      return <ActiveBuild build={build} />;
    }

    switch (build.state) {
      case BuildState.DRAFT:
        return <DraftBuild build={build} />;
      case BuildState.STAGING:
        return <StagingBuild build={build} />;
      case BuildState.PAUSED:
      case BuildState.ACTIVE:
        return <ActiveBuild build={build} />;
      case BuildState.SUCCEEDED:
      case BuildState.COMPLETED:
      case BuildState.FAILED:
        return <ActiveBuild build={build} />;
    }
    assertUnreachable(build.state);
  }
}
