import React from 'react';
import {useSelector} from 'react-redux';
import {Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {DefectType, getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {RootState} from '../../../../store/reducers/index';
import LineAreaChart from '../../../../components/atoms/ApexCharts/LineAreaChart';
import {chartColors} from '../chartColors';
import {FetchingState} from '../../../../store/model/liveUpdateStore';

function BuildDefectsChart({buildUuid}: {buildUuid: string}) {
  const defectSummary = useSelector((state: RootState) =>
    Object.values(state.defectSummaryStore.byId).find(
      (defectSummary) =>
        buildUuid === defectSummary.buildUuid && !defectSummary.partUuid && defectSummary.defectType === DefectType.Lsdd
    )
  );
  const defectSummariesFetching = useSelector(
    (state: RootState) => state.defectSummaryStore.fetched === FetchingState.Fetching
  );

  if (!defectSummary && defectSummariesFetching) return <Skeleton />;
  if (!defectSummary)
    return <Typography style={{marginTop: '16px'}}>Unable to view defect statistics for this build</Typography>;

  const maxLayerNum = Math.max(
    ...Object.keys(defectSummary.defectCoverageGraph || {}).map((layerNum) => Number(layerNum))
  );

  const series = {
    defectType: defectSummary.defectType,
    name: getPrettyDefectType(defectSummary.defectType),
    data: Array(maxLayerNum)
      .fill(0)
      .map((_, layerNum) =>
        defectSummary.defectCoverageGraph[layerNum]
          ? Number((defectSummary.defectCoverageGraph[layerNum] * 100).toFixed(1))
          : 0
      ),
  };

  return (
    <LineAreaChart
      chartID={buildUuid}
      valueFormatter={(val) => `${val?.toFixed(0)}%`}
      series={[series]}
      height={250}
      type="numeric"
      options={{
        colors: [chartColors.purple],
      }}
    />
  );
}

export default BuildDefectsChart;
