import {AvailableComparisons} from '@common/api/apiRequest';
import {Role} from '@common/api/models/users/IUser';
import {FiltersState} from '../IndexPage';
import buildFilterSchema, {buildDefaultFilters} from './build';
import batchFilterSchema, {batchDefaultFilters} from './batch';
import partFilterSchema from './part';
import machineParameterFilterSchema from './machineParameter';
import similarityReportFilterSchema, {similarityReportDefaultFilters} from './similarityReport';
import similarityComparisonFilterSchema from './similarityComparison';
import buildIntensityReportFilterSchema, {buildIntensityReportDefaultFilters} from './buildIntensityReport';
import defectSummaryFilterSchema from './defectSummary';
import buildReportFilterSchema, {buildReportDefaultFilters} from './buildReport';
import defectStatisticFilterSchema, {defectStatisticDefaultFilters} from './defectStatistic';
import {userDefaultFilters, userFilterSchema} from './users';
import {organizationFilterSchema} from './organization';
import {materialDefaultFilters, materialFilterSchema} from './material';
import {machineDefaultFilters, machineFilterSchema} from './machine';
import {deviceDefaultFilters, deviceFilterSchema} from './device';
import {buildAttachmentDefaultFilters, buildAttachmentFilterSchema} from './buildAttachments';
import alertFilterSchema, {alertDefaultFilters} from './alert';
import {AutocompleteGetTagProps} from '@material-ui/lab';
import {ctReportDefaultFilters, ctReportFilterSchema} from './ctReport';

export type FilterSchemaType = {
  field: string;
  type:
    | 'string'
    | 'float'
    | 'int'
    | 'boolean'
    | 'enumSingle'
    | 'enumMultiple'
    | 'resourceSelect'
    | 'dateTimeRange'
    | 'dateRange'
    | 'booleanList';
  label?: string;
  placeholder?: string;
  enumValues?: Array<string | number>;
  enumLabels?: {[key: string]: string};
  booleanValues?: Array<string | number>;
  booleanLabels?: {[key: string]: string};
  labelFn?: (record: any) => string;
  searchGET?: any;
  valueField?: string;
  searchField?: string;
  urlIdField?: string;
  tagFn?: (value: any[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  colorFn?: (value: any) => string;
  permanentComparison?: AvailableComparisons;
  excludeComparisons?: Array<AvailableComparisons>;
  min?: number;
  max?: number;
  fullWidth?: boolean;
  transformValue?: (direction: 'in' | 'out', value: any) => any;
  requiredAuth?: Role;
};

// [key: string] could be replaced with template literal types using ResourceTypes, when we upgrade typescript.
// https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html
export default {
  buildFilterSchema,
  batchFilterSchema,
  machineParameterFilterSchema,
  partFilterSchema,
  similarityReportFilterSchema,
  similarityComparisonFilterSchema,
  buildIntensityReportFilterSchema,
  defectSummaryFilterSchema,
  defectStatisticFilterSchema,
  buildReportFilterSchema,
  ctReportFilterSchema,
  userFilterSchema,
  organizationFilterSchema,
  materialFilterSchema,
  machineFilterSchema,
  deviceFilterSchema,
  buildAttachmentFilterSchema,
  alertFilterSchema,
} as {[key: string]: Array<FilterSchemaType>};

export const defaultFilters = {
  buildDefaultFilters,
  batchDefaultFilters,
  ctReportDefaultFilters,
  similarityReportDefaultFilters,
  buildIntensityReportDefaultFilters,
  defectStatisticDefaultFilters,
  buildReportDefaultFilters,
  userDefaultFilters,
  materialDefaultFilters,
  machineDefaultFilters,
  deviceDefaultFilters,
  buildAttachmentDefaultFilters,
  alertDefaultFilters,
} as {
  [key: string]: Partial<FiltersState>;
};
