import {Pagination} from '../../common';

export enum CTReportStatus {
  DRAFT = 'draft',
  SUBMITTED = 'submitted',
  GENERATING = 'generating',
  ALIGNMENT = 'alignment',
  ALIGNING = 'aligning',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface ICTReport {
  uuid: string;
  name: string;
  partUuid: string;
  voxelSize: number;
  transformationMatrix: number[];
  status: CTReportStatus;
  createdAt: Date;
  attachmentUuid?: string;
  failureMessage?: string;
}

export interface ICTPointCloud {
  uuid: string;
  reportUuid: string;
  numPoints: number;
  size: number;
  url: string;
  createdAt: Date;
}

export interface ICTReportGETRequest extends Pagination {
  uuid?: string;
  partUuid?: string;
  status?: CTReportStatus;
  buildUuid: string | null;
  buildName: string | null;
  buildEndDate: string | null;
}

export interface ICTReportGETResponse extends ICTReport {
  buildUuid: string;
  partName: string;
}

export interface ICTReportPOSTRequest {
  name: string;
  partUuid: string;
  voxelSize: number;
}

export interface ICTReportDownloadURLSGETResponse {
  url: string;
}

export type CTSortOptions = 'name' | 'partName' | 'createdAt' | 'voxelSize' | 'status';
