import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import {omit} from 'lodash';
import isMobilePhone from 'validator/lib/isMobilePhone';

import {getRoleName, IUser} from '@common/api/models/users/IUser';
import {canCreateWithRole} from '@common/api/models/users/User.access';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from '@common/api/models/users/User.validation';

import {RootState} from '../../store/reducers';
import {userPOST} from '../../api/ajax/users';
import CustomInput from '../../components/atoms/CustomInput';
import CustomSelect from '../../components/atoms/CustomSelect';
import CustomButton from '../../components/atoms/CustomButton';
import RequiredAsterisk from '../../components/atoms/Texts/RequiredAsterisk';
import envConfig from '../../envConfig';
import Header from '../../components/organisms/Header';
import {useSmallScreenSize} from '../../utils/utilHooks';

import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';

interface IUserPassword extends IUser {
  password: string;
  passwordConfirmation: string;
}

export default function CreateUserPage() {
  const isSmallScreen = useSmallScreenSize();
  const {
    register,
    handleSubmit: onSubmit,
    errors,
    control,
  } = useForm<IUserPassword>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const auth = useSelector((state: RootState) => state.auth);
  const availableRoles = canCreateWithRole(auth.user!);

  const history = useHistory();
  const handleCancel = () => {
    history.push('/users/');
  };

  const handleSubmit = async (data: IUserPassword) => {
    let {password, ...user} = data;

    // dirty scrub of invalid mobile number
    if (!isMobilePhone(user.mobile + '')) {
      user = omit(user, 'mobile');
    }
    user = omit(user, 'confirmationPassword');

    const res = await userPOST(user, password);
    if (res.success) {
      history.replace('/users/uuid/' + res.data.uuid + '/');
    }
  };

  const TableCellComponent = isSmallScreen ? SmallCell : TableCell;
  const PhoneCellComponent = isSmallScreen ? SmallPhoneCell : PhoneCell;

  return (
    <React.Fragment>
      <Header
        helmet="Create User"
        title="Create User"
        breadcrumbs={[{title: 'Users', path: '/users'}, 'Create User']}
      />

      <Grid container spacing={6} justifyContent={'center'}>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardHeader title={'Account'} style={isSmallScreen ? {padding: '12px'} : {}}></CardHeader>
            <CardContent style={isSmallScreen ? {padding: '12px'} : {}}>
              <Grid container alignContent={'center'} spacing={2}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <AccountCircle style={{fontSize: isSmallScreen ? 60 : 100}} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Card style={{overflow: 'visible'}}>
            <CardHeader title={'Details'} style={isSmallScreen ? {padding: '12px'} : {}}></CardHeader>
            <CardContent style={isSmallScreen ? {padding: '12px'} : {}}>
              <form id="createUserForm">
                <Table style={{borderBottom: 'none', tableLayout: 'fixed'}} size={'small'}>
                  <colgroup>
                    <col style={{width: isSmallScreen ? '100px' : '200px'}} />
                    <col style={{width: 'auto'}} />
                  </colgroup>
                  <TableBody>
                    <TableRow>
                      <TableCellComponent align="left" variant="head">
                        Role <RequiredAsterisk />
                      </TableCellComponent>
                      <TableCellComponent>
                        <Controller
                          render={({...renderProps}) => (
                            <CustomSelect
                              label={
                                <>
                                  Role <RequiredAsterisk />
                                </>
                              }
                              data={availableRoles}
                              computeData={getRoleName}
                              helperText={errors.role?.message}
                              error={!!errors.role}
                              inputProps={{
                                ...renderProps,
                                'aria-required': 'true',
                              }}
                            />
                          )}
                          name="role"
                          control={control}
                          rules={{required: 'Please select a role'}}
                          defaultValue=""
                        />
                      </TableCellComponent>
                    </TableRow>
                    <TableRow>
                      <TableCellComponent align="left" variant="head">
                        First Name <RequiredAsterisk />
                      </TableCellComponent>
                      <TableCellComponent>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.firstName?.message}
                          error={!!errors.firstName}
                          inputProps={{
                            name: 'firstName',
                            inputRef: register({
                              validate: (value) => {
                                const validationResult = validateFirstName(value);
                                if (!validationResult.success) {
                                  return validationResult.errors[0];
                                }
                                return true;
                              },
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCellComponent>
                    </TableRow>

                    <TableRow>
                      <TableCellComponent align="left" variant="head">
                        Last Name <RequiredAsterisk />
                      </TableCellComponent>
                      <TableCellComponent>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.lastName?.message}
                          error={!!errors.lastName}
                          inputProps={{
                            name: 'lastName',
                            inputRef: register({
                              validate: (value) => {
                                const validationResult = validateLastName(value);
                                if (!validationResult.success) {
                                  return validationResult.errors[0];
                                }
                                return true;
                              },
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCellComponent>
                    </TableRow>

                    <TableRow>
                      <TableCellComponent align="left" variant="head">
                        {envConfig.isOnPremise ? 'Email / Username' : 'Email'} <RequiredAsterisk />
                      </TableCellComponent>
                      <TableCellComponent>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.email?.message}
                          error={!!errors.email}
                          inputProps={{
                            name: 'email',
                            type: 'email',
                            inputRef: register({
                              validate: (value) => {
                                const validationResult = validateEmail(value, envConfig.isOnPremise);
                                if (!validationResult.success) {
                                  return validationResult.errors[0];
                                }
                                return true;
                              },
                            }),
                            autoComplete: 'off',
                          }}
                        />
                      </TableCellComponent>
                    </TableRow>

                    <TableRow>
                      <TableCellComponent align="left" variant="head">
                        Mobile
                      </TableCellComponent>
                      <PhoneCellComponent>
                        <Controller
                          as={<PhoneInput country="au" inputProps={{name: 'mobile'}} masks={{au: '. .... ....'}} />}
                          name={'mobile'}
                          control={control}
                          defaultValue=""
                        />
                      </PhoneCellComponent>
                    </TableRow>

                    {envConfig.isOnPremise && (
                      <>
                        <TableRow>
                          <TableCellComponent align="left" variant="head">
                            Password <RequiredAsterisk />
                          </TableCellComponent>
                          <TableCellComponent>
                            <CustomInput
                              type={'text'}
                              fullWidth
                              inputProps={{
                                name: 'password',
                                type: 'password',
                                inputRef: register({
                                  validate: (value) => {
                                    const validationResult = validatePassword(value);
                                    if (!validationResult.success) {
                                      return validationResult.errors[0];
                                    }
                                    return true;
                                  },
                                }),
                                'aria-required': 'true',
                                // autoComplete: 'off',
                                autoComplete: 'new-password',
                              }}
                              helperText={errors.password?.message}
                              error={!!errors.password}
                            />
                          </TableCellComponent>
                        </TableRow>
                        <TableRow>
                          <TableCellComponent align="left" variant="head">
                            Confirm Password <RequiredAsterisk />
                          </TableCellComponent>
                          <TableCellComponent>
                            <CustomInput
                              type={'text'}
                              fullWidth
                              inputProps={{
                                name: 'passwordConfirmation',
                                type: 'password',
                                inputRef: register({
                                  validate: (value) => {
                                    const password = control.getValues('password');
                                    if (password !== value) return 'Passwords do not match!';
                                    return true;
                                  },
                                }),
                                'aria-required': 'true',
                                // autoComplete: 'off',
                                autoComplete: 'new-password',
                              }}
                              helperText={errors.passwordConfirmation?.message}
                              error={!!errors.passwordConfirmation}
                            />
                          </TableCellComponent>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardContent
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant={'outlined'} onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <CustomButton
                    type="submit"
                    form="createUserForm"
                    variant={'contained'}
                    color={'primary'}
                    onClick={onSubmit(handleSubmit)}
                    text="Create User"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const SmallCell = styled(TableCell)`
  padding: 6px;
  padding-right: 6px !important;
`;

const PhoneCell = styled(TableCell)`
  input[type] {
    width: 100%;
  }
`;

const SmallPhoneCell = styled(TableCell)`
  padding: 6px;
  padding-right: 6px !important;

  input[type] {
    width: 100%;
  }
`;
