import React from 'react';
import {BatchAvailability} from '@common/api/models/materials/batches/IBatch';
import {ChipStatus} from '../../../../components/atoms/Status/Status';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {Grid, Radio} from '@material-ui/core';
import {IBuild} from '@common/api/models/builds/IBuild';

export interface BatchesRow {
  uuid: string;
  availability: BatchAvailability;
  name: string;
  materials: string;
  quantity: number;
  registered: Date | string;
  draftBuild?: IBuild;
}

const batchTableSchema = [
  {
    title: 'Name',
    field: 'name',
    width: 'auto',
    render: (row: BatchesRow) => {
      if (row.draftBuild) {
        return (
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item style={{minWidth: '42px'}}>
              <Radio checked={row.draftBuild.batchUuid === row.uuid} />
            </Grid>
            <Grid item style={{overflow: 'hidden'}}>
              <EllipsisTextWithTooltip>{row.name}</EllipsisTextWithTooltip>
            </Grid>
          </Grid>
        );
      } else {
        return <EllipsisTextColumnTooltip>{row.name}</EllipsisTextColumnTooltip>;
      }
    },
  },
  {
    title: 'Availability',
    field: 'availability',
    width: '132px',
    sorting: false,
    render: (row: BatchesRow) => {
      switch (row.availability as BatchAvailability) {
        case BatchAvailability.Available:
          return <ChipStatus message="Available" variant="success" />;
        case BatchAvailability['In use']:
          return <ChipStatus message="In use" variant="warning" />;
        case BatchAvailability.Expired:
          return <ChipStatus message="Expired" variant="error" />;
      }
    },
  },
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Material Composition',
    field: 'materials',
    sorting: false,
    render: (row: BatchesRow) => <EllipsisTextColumnTooltip>{row.materials}</EllipsisTextColumnTooltip>,
  },
  {title: 'Total Qty (kg)', field: 'quantity', width: '148px'},
  {
    title: 'Registered on',
    field: 'registered',
    type: 'date',
    width: '160px',
    emptyValue: '',
    render: (row: BatchesRow) => row.registered && <DateWithTimeTooltip timestamp={row.registered} />,
  },
];

export default batchTableSchema;
