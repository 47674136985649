import {RootState} from '../../../store/reducers';
import {useSelector} from 'react-redux';
import {CustomToggleButtonGroup} from '../../../components/atoms/Switches/CustomToggle';
import React from 'react';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {useBuildIntensityReportStoreActions} from '../../../store/actions';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';
import {Box} from '@material-ui/core';
import {GRAPH_DATA_TYPE_TOOLTIPS} from '../IntensityGraph/GraphOptionsModal';

export enum ChartDataType {
  Mean = 'mean',
  Median = 'median',
  Min = 'min',
  Max = 'max',
  MaxCount = 'max_count',
  TotalCount = 'total_count',
  STD = 'std',
}

export function IntensityChartOptionsModal({open, onClose}: {open: boolean; onClose: () => void}) {
  const isXsScreenSize = useExtraSmallScreenSize();
  const currentChartDataType = useSelector((state: RootState) => state.buildIntensityReportStore.chartDataType);
  const {setChartDataType} = useBuildIntensityReportStoreActions();

  return (
    <GenericDialog
      title="Graph Options"
      isOpen={open}
      closeDialog={onClose}
      maxWidth="sm"
      closeText="Close"
      topAlign
      content={
        <Box display="flex" justifyContent="center">
          <CustomToggleButtonGroup<ChartDataType>
            values={ChartDataType}
            currentValue={currentChartDataType}
            updateValue={setChartDataType}
            orientation={isXsScreenSize ? 'vertical' : 'horizontal'}
            tooltipOverrides={GRAPH_DATA_TYPE_TOOLTIPS}
          />
        </Box>
      }
    />
  );
}
