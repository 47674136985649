import moment from 'moment';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Tooltip} from '@material-ui/core';

import {ISliceAttachmentGETResponse, SliceState} from '@common/api/models/attachments/ISliceAttachment';
import {assertUnreachable} from '@common/utils/utils';
import {inflect} from 'inflection';
import {ChipStatus} from './Status';

function StateSymbol({sliceAttachment}: {sliceAttachment: ISliceAttachmentGETResponse}): JSX.Element {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    // Update the current time every 10 seconds
    setTimeout(() => setCurrentTime(moment()), 10e3);
  }, [currentTime]);

  switch (sliceAttachment.state) {
    case SliceState.NEED_TO_VERIFY:
    case SliceState.VERIFYING:
      const minutesSpentVerifying = currentTime.diff(sliceAttachment.createdAt, 'minutes');

      const sizeGB = sliceAttachment.filesize / 1e9;
      const estimatedMinutes = sizeGB * 3.7 + 2;
      const minutesRemaining = estimatedMinutes - minutesSpentVerifying;
      const etaMessage =
        minutesRemaining <= 1
          ? '~1 min. remaining'
          : minutesRemaining < 1.5
          ? '1 min. remaining'
          : `${minutesRemaining.toFixed()} mins remaining`;
      return (
        <Tooltip title={`Verifying (${etaMessage})`}>
          <StatusContainer>
            <ChipStatus message={`Verifying (${etaMessage})`} variant="warning" />
          </StatusContainer>
        </Tooltip>
      );
    case SliceState.VALID:
      const validText = `Valid (${sliceAttachment.partsCount} ${inflect('part', sliceAttachment.partsCount)})`;
      return (
        <Tooltip title={validText}>
          <StatusContainer>
            <ChipStatus message={validText} variant="success" />
          </StatusContainer>
        </Tooltip>
      );
    case SliceState.INVALID:
      return (
        <Tooltip title={'Invalid'}>
          <StatusContainer>
            <ChipStatus message="Invalid" variant="error" />
          </StatusContainer>
        </Tooltip>
      );
  }
  assertUnreachable(sliceAttachment.state);
}

export default React.memo(StateSymbol);

export const StatusContainer = styled.div`
  .MuiChip-root {
    max-width: 100%;
  }
`;
