import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {Card, CardContent, Grid} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

function GenericAlertPage({
  title,
  alertText,
  ExtraComponent,
}: {
  title: string;
  alertText: string;
  ExtraComponent?: React.FC<{}>;
}) {
  return (
    <Wrapper>
      <Helmet title={title} />
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardContent
            style={{
              padding: 25,
              overflowWrap: 'break-word',
            }}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={12}>
                <Alert severity="info">{alertText}</Alert>
              </Grid>
            </Grid>
            {ExtraComponent ? <ExtraComponent /> : <></>}
          </CardContent>
        </Card>
      </Grid>
    </Wrapper>
  );
}

export default GenericAlertPage;

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;
`;
