import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers/index';
import {Alert} from '@material-ui/lab';
import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';

const GeneratingInfoBar = () => {
  const currentReportStatus = useSelector(
    (state: RootState) => state.similarityReportStore.currentSimilarityReport!.status
  );

  switch (currentReportStatus) {
    case SimilarityStatus.Submitted:
      return (
        <Grid item xs={12} style={{maxHeight: '72px', marginBottom: '12px'}}>
          <Alert severity="info" style={{width: '100%'}}>
            <Typography>Report submitted. Comparisons will begin generating soon.</Typography>
          </Alert>
        </Grid>
      );

    case SimilarityStatus.Generating:
      return (
        <Grid item xs={12} style={{maxHeight: '72px', marginBottom: '12px'}}>
          <Alert severity="info" style={{width: '100%'}}>
            <Typography>Result generation in progress. Results will update below when ready.</Typography>
          </Alert>
        </Grid>
      );

    default:
      return <></>;
  }
};

export default GeneratingInfoBar;
