import batchTableSchema from './batch';
import buildAttachmentTableSchema from './buildAttachment';
import buildTableSchema from './build';
import defectNotificationTableSchema from './defectNotification';
import recipientTableSchema from './recipient';
import sliceAttachmentTableSchema from './sliceAttachment';
import similarityReportTableSchema from './similarityReport';
import machineParameterTableSchema from './machineParameter';
import similarityComparisonTableSchema from './similarityComparison';
import buildIntensityReportTableSchema from './buildIntensityReport';
import defectSummaryTableSchema from './defectSummary';
import defectStatisticTableSchema from './defectStatistic';
import resourcePermissionTableSchema from './resourcePermission';
import buildReportTableSchema from './buildReport';
import partTableSchema from './part';
import userTableSchema from './users';
import organizationTableSchema from './organization';
import materialTableSchema from './material';
import machineTableSchema from './machine';
import deviceTableSchema from './device';
import alertTableSchema from './alert';
import {ctReportTableSchema} from './ctReport';

export default {
  batchTableSchema,
  buildTableSchema,
  buildAttachmentTableSchema,
  defectNotificationTableSchema,
  recipientTableSchema,
  sliceAttachmentTableSchema,
  machineParameterTableSchema,
  similarityReportTableSchema,
  similarityComparisonTableSchema,
  buildIntensityReportTableSchema,
  defectSummaryTableSchema,
  defectStatisticTableSchema,
  resourcePermissionTableSchema,
  userTableSchema,
  organizationTableSchema,
  materialTableSchema,
  machineTableSchema,
  deviceTableSchema,
  buildReportTableSchema,
  partTableSchema,
  alertTableSchema,
  ctReportTableSchema,
} as {
  [key: string]: any;
};

export const rowActions = {} as {
  [key: string]: any;
};
