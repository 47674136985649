import React from 'react';
import {Box, CircularProgress, Tooltip, Typography} from '@material-ui/core';
import {CheckCircle, Error} from '@material-ui/icons';
import {dangerColor, successColor, warningColor} from '../../../../../assets/jss/material-dashboard-react';

export enum StagingResult {
  PASS = 'PASS',
  PENDING = 'PENDING',
  OLD = 'OLD',
  FAILURE = 'FAILURE',
  NONE = 'NONE',
}

export enum StagingType {
  CALIBRATION = 'Calibration',
  FOCUS = 'Focus',
}

const STAGING_RESULT_ICONS = {
  [StagingResult.PASS]: () => <CheckCircle style={{marginBottom: '4px', color: successColor[0]}} />,
  [StagingResult.PENDING]: () => <CircularProgress size={24} style={{marginBottom: '4px', color: warningColor[0]}} />,
  [StagingResult.OLD]: () => <Error style={{marginBottom: '4px', color: warningColor[0]}} />,
  [StagingResult.FAILURE]: () => <Error style={{marginBottom: '4px', color: dangerColor[0]}} />,
};

const STAGING_RESULT_TOOLTIPS = {
  [StagingType.CALIBRATION]: {
    [StagingResult.PASS]: 'Calibration operation completed successfully.',
    [StagingResult.PENDING]: 'Calibration is currently in progress.',
    [StagingResult.OLD]:
      'Calibration is for a previous build. You may continue, but it is recommended that you recalibrate.',
    [StagingResult.FAILURE]: 'No calibration result available, or the most recent calibration failed.',
  },
  [StagingType.FOCUS]: {
    [StagingResult.PASS]: 'Focus operation completed successfully.',
    [StagingResult.PENDING]: 'Focus is currently in progress.',
    [StagingResult.OLD]: 'No focus result available.',
    [StagingResult.FAILURE]: 'Some cameras failed to focus, please retry the focus operation, or manually focus.',
  },
};

function StagingResultIcon({result, type}: {result: StagingResult; type: StagingType}) {
  if (result === StagingResult.NONE) return <></>;

  const Icon = STAGING_RESULT_ICONS[result];
  const tooltip = STAGING_RESULT_TOOLTIPS[type][result];

  return (
    <Tooltip arrow title={<Typography>{tooltip}</Typography>}>
      <Box display="flex" alignItems="center">
        <Icon />
      </Box>
    </Tooltip>
  );
}

export default StagingResultIcon;
