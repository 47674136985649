import React from 'react';
import {Typography} from '@material-ui/core';
import {Panorama} from '@material-ui/icons';
import {useParams} from 'react-router-dom';
import {
  CancelButton,
  SaveAndGenerateButton,
  ToSourcePartsPageButton,
  ToTargetPartsPageButton,
} from './NavigationButtons';
import BottomToolbarContainer from '../../../components/atoms/BottomToolbarContainer';
import VerticalButton from '../../../components/atoms/VerticalButton';

const SimilarityCreateBottomToolbar = ({toggleViewportFullscreen}: {toggleViewportFullscreen: () => void}) => {
  const {step} = useParams<{step?: string}>();

  return (
    <BottomToolbarContainer columns={'min-content auto min-content min-content'}>
      <VerticalButton onClick={toggleViewportFullscreen} color="secondary">
        <Panorama />
        <Typography variant="caption">Focus viewport</Typography>
      </VerticalButton>
      <div />
      {step === 'sourcePartSelection' && (
        <>
          <CancelButton />
          <ToTargetPartsPageButton />
        </>
      )}
      {step === 'targetPartSelection' && (
        <>
          <ToSourcePartsPageButton />
          <SaveAndGenerateButton />
        </>
      )}
    </BottomToolbarContainer>
  );
};

export default SimilarityCreateBottomToolbar;
