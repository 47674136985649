import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import axios from 'axios';
import {BufferGeometry} from 'three';
import {STLLoader} from 'three/examples/jsm/loaders/STLLoader';
import pako from 'pako';
import protobuf, {Type} from 'protobufjs';
import {PointCloudParseResult} from '../types/pointCloudTypes';
import {PointCloud2} from '../types/pointCloudV2';
import {PointCloud3} from '../types/pointCloudV3';
import {pointCloudDownloadUrlGET} from '../../../../../api/ajax/pointCloud';
import {partModelAttachmentDownloadUrlGET} from '../../../../../api/ajax/partModelAttachments';

let protobufParser: Type | null = null;
protobuf.load('/files/point_cloud.proto').then((res) => (protobufParser = res.lookupType('PointCloud')));

/**
 * Parses the point cloud data based on the file extension.
 *
 * @param apiRes - The API response containing the point cloud data.
 * @param fileExtension - The file extension of the point cloud file.
 * @returns The parsed point cloud data.
 */
export const parsePointCloud = (apiRes: any, fileExtension: string): PointCloudParseResult => {
  if (apiRes.success !== undefined && !apiRes.success) {
    return {success: false, error: apiRes.error};
  }

  switch (fileExtension) {
    case 'aapc':
      return parseProtoPointCloud(apiRes);
    case 'gz':
      return parseGzJsonPointCloud(apiRes);
    default:
      return {success: false, error: 'Unknown point cloud format'};
  }
};

// Convert the .aapc bytes into a javascript object
/**
 * Parses the protobuf response of a point cloud API and converts it into a PointCloud2 object.
 * @param apiRes - The response from the point cloud API.
 * @returns An object containing the success status and the parsed point cloud data.
 */
const parseProtoPointCloud = (apiRes: any): PointCloudParseResult => {
  // Protobuf parser *should* always be loaded by the time the point cloud is downloaded...
  if (!protobufParser) return {success: false, error: 'Point cloud parser not loaded'};

  // Load point cloud object
  try {
    const pointCloudParsed = protobufParser.decode(new Uint8Array(apiRes)) as any;

    // The proto format stores data with key-value pairs, but the newer json
    // format stores data as tuples. We need to convert the KVPs to tuples.
    const pointCloud: PointCloud2 = {
      ...pointCloudParsed,
      points: pointCloudParsed.points.map((point: any) => ({
        ...point,
        coord: [point.coord.x, point.coord.y, point.coord.z],
      })),
      colours: pointCloudParsed.colours.map((colour: any) => [colour.r, colour.g, colour.b]),
      bounds: [
        pointCloudParsed.bounds.min.x,
        pointCloudParsed.bounds.min.y,
        pointCloudParsed.bounds.min.z,
        pointCloudParsed.bounds.dimensions.x,
        pointCloudParsed.bounds.dimensions.y,
        pointCloudParsed.bounds.dimensions.z,
      ],
    };

    return {success: true, pointCloud};
  } catch (e) {
    return {success: false, error: 'Could not read 3D data'};
  }
};

/**
 * Parses a Gzipped JSON point cloud response from the API.
 * @param apiRes - The Gzipped JSON response from the API.
 * @returns The parsed point cloud result.
 */
const parseGzJsonPointCloud = (apiRes: any): PointCloudParseResult => {
  const inflated = pako.inflate(apiRes, {to: 'string'});
  const json = JSON.parse(inflated);
  return {success: true, pointCloud: json};
};

/**
 * Downloads a point cloud based on the provided UUID, part UUID, and analysis type.
 *
 * @param uuid - The UUID of the point cloud.
 * @param partUuid - The UUID of the part.
 * @param analysisType - The analysis type of the point cloud.
 * @param onDownload - A callback function to handle the downloaded point cloud.
 * @param onFail - A callback function to handle the failure of the download.
 * @returns A Promise that resolves when the point cloud is downloaded successfully, and rejects otherwise.
 */
export const downloadPointCloud = async (
  uuid: string,
  partUuid: string,
  analysisType: AnalysisType3D,
  onDownload: (pointCloud: PointCloud2 | PointCloud3, partUuid: string, analysisType: AnalysisType3D) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const url = await pointCloudDownloadUrlGET(uuid);

    if (!url.success) {
      fail();
      return;
    }

    const extension = url.data.url.split('?')[0].split('.').pop() as string;

    axios
      .get(url.data.url, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loadResult = parsePointCloud(result.data, extension);
          if (loadResult.success) {
            onDownload(loadResult.pointCloud, partUuid, analysisType);
            resolve();
            return;
          }
        }
        fail();
      });
  });
};

/**
 * Downloads similarity point clouds from the specified URL.
 *
 * @param url - The URL of the point cloud to download.
 * @param partUuid - The UUID of the part.
 * @param analysisType - The type of analysis for the point cloud.
 * @param onDownload - A callback function called when the point cloud is successfully downloaded.
 * @param onFail - A callback function called when the download fails.
 * @returns A Promise that resolves when the download is complete.
 */
export const downloadSimilarityPointCloud = async (
  url: string,
  partUuid: string,
  analysisType: AnalysisType3D,
  onDownload: (apiRes: any, partUuid: string, analysisType: AnalysisType3D) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const extension = url.split('?')[0].split('.').pop() as string;

    axios
      .get(url, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loadResult = parsePointCloud(result.data, extension);
          if (loadResult.success) {
            onDownload(loadResult.pointCloud, partUuid, analysisType);
            resolve();
            return;
          }
        }
        fail();
      });
  });
};

/**
 * Downloads CT point clouds from the specified URL and invokes the appropriate callbacks.
 * @param url - The URL of the point cloud to download.
 * @param onDownload - A callback function to be invoked when the point cloud is successfully downloaded.
 *                     It receives the downloaded point cloud as a parameter.
 * @param onFail - A callback function to be invoked when the download fails.
 * @returns A Promise that resolves when the download is successful, and rejects when it fails.
 */
export const downloadCTPointCloud = async (
  url: string,
  onDownload: (pointCloud: PointCloud2 | PointCloud3) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const extension = url.split('?')[0].split('.').pop() as string;

    axios
      .get(url, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loadResult = parsePointCloud(result.data, extension);
          if (loadResult.success) {
            onDownload(loadResult.pointCloud);
            resolve();
            return;
          }
        }
        fail();
      });
  });
};

export const downloadPartModel = async (
  uuid: string,
  partUuid: string,

  onDownload: (partModel: BufferGeometry, uuid: string, partUuid: string) => void,
  onFail: () => void
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    const fail = () => {
      onFail();
      reject();
    };

    const url = await partModelAttachmentDownloadUrlGET(uuid);

    if (!url.success) {
      fail();
      return;
    }

    axios
      .get(url.data, {responseType: 'arraybuffer'})
      .catch(fail)
      .then((result) => {
        if (result) {
          const loader = new STLLoader();
          onDownload(loader.parse(result.data), uuid, partUuid);
          resolve();
          return;
        }
        fail();
      });
  });
};
