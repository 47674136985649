import React from 'react';
import {Typography, LinearProgress, Box, LinearProgressProps} from '@material-ui/core';

/* Taken from https://v4.mui.com/components/progress/#LinearWithValueLabel.tsx */
function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    label?: string;
  }
) {
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box width="100%" mr={3}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="primary">
          <b>{props.label || `${Math.round(props.value)}%`}</b>
        </Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
