import React from 'react';
import {Box, LinearProgress, Typography} from '@material-ui/core';
import {CameraRow} from '../ActiveStepFOCUS';

const AutoFocusProgressBar = ({cameraRows}: {cameraRows: CameraRow[]}) => {
  const total = cameraRows.length;
  const completed = cameraRows.filter((row) => row.result !== 'pending').length;
  const value = (completed / total) * 100;

  return (
    <>
      <Typography>Waiting for autofocus to complete...</Typography>
      <Box display="flex" alignItems="center" mb={3}>
        <Box width="100%" mr={3}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="primary">
            <b>{`${Math.round(value)}%`}</b>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AutoFocusProgressBar;
