import React from 'react';
import {TableCell, TableBody, TableRow} from '@material-ui/core';
import styled from 'styled-components';
import filesize from 'filesize';
import {Description} from '@material-ui/icons';
import {ISliceAttachmentGETResponse} from '@common/api/models/attachments/ISliceAttachment';
import {IBuildAttachment} from '@common/api/models/attachments/IAttachmentBase';
import StateSymbol from '../../../../../components/atoms/Status/SliceAttachmentStatus';
import {grayColor} from '../../../../../assets/jss/material-dashboard-react';
import {CollapseTable} from '../ConfirmationStep';
import {useExtraSmallScreenSize} from '../../../../../utils/utilHooks';

const SliceFilesCollapse = ({
  sliceAttachments,
  buildAttachments,
}: {
  sliceAttachments: ISliceAttachmentGETResponse[];
  buildAttachments: IBuildAttachment[];
}) => {
  const isXsScreen = useExtraSmallScreenSize();

  return (
    <CollapseTable>
      <TableBody>
        {sliceAttachments.map((sliceAttachment) => (
          <TableRow key={`slice-attachment-confirmation-row-${sliceAttachment.uuid}`}>
            <TableCell
              style={{
                display: 'flex',
                alignItems: 'center',
                wordBreak: 'break-word',
              }}
            >
              {isXsScreen ? <></> : <FileIcon />}
              {sliceAttachment.filename}
            </TableCell>
            <TableCell>
              <StateSymbol sliceAttachment={sliceAttachment} />
            </TableCell>
            <TableCell>{filesize(sliceAttachment.filesize)}</TableCell>
          </TableRow>
        ))}

        {buildAttachments.map((buildAttachment) => {
          return (
            <TableRow key={`build-attachment-confirmation-row-${buildAttachment.uuid}`}>
              <TableCell style={{display: 'flex', alignItems: 'center'}}>
                <FileIcon />
                {buildAttachment.filename}
              </TableCell>
              <TableCell>
                <div />
              </TableCell>
              <TableCell>{filesize(buildAttachment.filesize)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CollapseTable>
  );
};

export default SliceFilesCollapse;

const FileIcon = styled(Description)`
  margin-bottom: 6px;
  margin-right: 12px;
  color: ${grayColor[3]};
`;
