import {Tooltip, Typography} from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import {ActiveDiv, ViewerButton, ViewerButtonActive} from '../../FadingComponents';
import React, {Dispatch} from 'react';
import {BUTTON_MARGIN} from '../utils';

interface PartialLayersToggleProps {
  cssTop: number;
  mouseOn: boolean;
  hidePartialLayers: boolean;
  setHidePartialLayers: Dispatch<React.SetStateAction<boolean>>;
  partialLayersCount: number;
}

export const PartialLayersToggle = ({
  partialLayersCount,
  hidePartialLayers,
  setHidePartialLayers,
  cssTop,
  mouseOn,
}: PartialLayersToggleProps) => {
  return (
    <Tooltip
      title={
        <Typography>
          Partial Layer Images: {hidePartialLayers ? <b>Excluded</b> : <b>Included</b>}
          <br />
          <br />
          <i>
            There are a total of: <b>{partialLayersCount}</b> partial layers
          </i>
        </Typography>
      }
      placement="left"
    >
      {hidePartialLayers ? (
        <ViewerButtonActive
          onClick={() => setHidePartialLayers(!hidePartialLayers)}
          style={{
            right: BUTTON_MARGIN,
            top: cssTop,
            position: 'absolute',
          }}
          className={(!mouseOn && 'fade') || undefined}
        >
          <ActiveDiv>
            <BrokenImageIcon />
          </ActiveDiv>
        </ViewerButtonActive>
      ) : (
        <ViewerButton
          onClick={() => setHidePartialLayers(!hidePartialLayers)}
          style={{
            right: BUTTON_MARGIN,
            top: cssTop,
            position: 'absolute',
          }}
          className={(!mouseOn && 'fade') || undefined}
        >
          <BrokenImageIcon />
        </ViewerButton>
      )}
    </Tooltip>
  );
};
