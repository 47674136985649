import {GetObjectRequest} from 'aws-sdk/clients/s3';
import {s3} from '../config/aws';

export const EPS = 1e-10;

export function assertUnreachable(x: never, shouldThrow = true) {
  if (shouldThrow) {
    throw new Error("Didn't expect to get here");
  }
}

export function removeNullOrUndefined<T>(obj: T): Partial<T> {
  const res: Partial<T> = {};
  for (const [k, v] of Object.entries(obj)) {
    if (v !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (res as any)[k] = v;
    }
  }
  return res;
}

export function sleep(ms: number) {
  return new Promise<void>((res, _rej) => {
    setTimeout(() => res, ms);
  });
}

export function cloneSimple<T>(obj: T): T {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any[] = [];
    obj.forEach((o) => {
      res.push(cloneSimple(o));
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return res as any as T;
  } else if (typeof obj === 'object' && obj !== null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = {};
    for (const key of Object.keys(obj)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      res[key] = cloneSimple((obj as any)[key]);
    }
    return res;
  } else {
    return obj;
  }
}

export const getS3JSONData = async (params: GetObjectRequest) => {
  const s3Res = await s3.getObject(params).promise();
  // @ts-ignore
  return JSON.parse(s3Res.Body.toString('utf-8'));
};
