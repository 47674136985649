import {Card, CardContent, Grid, Typography} from '@material-ui/core';
import {RootState} from '../../../store/reducers';
import React from 'react';
import {useSelector} from 'react-redux';
import {BatchSelectorButton} from './BatchSelectorButton';

interface BatchSelectorCardProps {
  selectedBatchUuid?: string;
  setSelectedBatchUuid: (uuid: string | undefined) => void;
}

export function BatchSelectorCard(props: BatchSelectorCardProps) {
  const batchStore = useSelector((state: RootState) => {
    return state.batchStore;
  });
  return (
    <Card
      style={{
        backgroundColor: '#ecf6ff',
        margin: '8px 0px',
        width: '100%',
      }}
    >
      <CardContent style={{paddingBottom: '16px'}}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {props.selectedBatchUuid ? (
            <>
              <Grid container item xs={8}>
                <Grid container item xs={12} wrap="nowrap">
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      width: '72px',
                    }}
                  >
                    Name:
                  </Typography>
                  <Typography noWrap style={{width: 'calc(100% - 84px)'}}>
                    {batchStore.byId[props.selectedBatchUuid].name}
                  </Typography>
                </Grid>

                <Grid container item xs={12} wrap="nowrap">
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      width: '72px',
                    }}
                  >
                    Quantity:
                  </Typography>
                  <Typography noWrap style={{width: 'calc(100% - 72px)'}}>
                    {batchStore.byId[props.selectedBatchUuid].quantity} Kg
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container item xs={8}>
              <Typography>No Batch Selected</Typography>
            </Grid>
          )}
          <Grid item xs={4} container justifyContent="flex-end">
            <BatchSelectorButton
              placeholder={'Select Batch'}
              onSelect={(b) => {
                props.setSelectedBatchUuid(!!b ? b.uuid : undefined);
              }}
              selectedBatch={batchStore.byId[props.selectedBatchUuid!]}
              hideSelectedBatch
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
