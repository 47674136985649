import React, {useEffect, useState} from 'react';
import {cloneDeep, isEqual} from 'lodash';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {IBuild} from '@common/api/models/builds/IBuild';

import {useSensorProfileStoreActions} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import {buildConfigPUT} from '../../../api/ajax/builds';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {sensorProfilesPUT} from '../../../api/ajax/sensorProfile';
import FormActionButtons from '../../machines/sensorProfiles/ProfileEditor/FormActionButtons';

interface IBuildProfileToolbar {
  build: IBuild;
  clearErrors: () => void;
  hasError: boolean;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  setSavingError: (saving: boolean) => void;
  setSaved: (saved: boolean) => void;
}

let savingTimeoutError: null | ReturnType<typeof setTimeout> = null;

function BuildProfileToolbar({
  build,
  clearErrors,
  hasError,
  saving,
  setSaving,
  setSavingError,
  setSaved,
}: IBuildProfileToolbar) {
  const sensorProfileActions = useSensorProfileStoreActions();

  const defaultProfile = useSelector(
    (state: RootState) => state.sensorProfileStore.defaultProfiles[build.deviceSerial!]
  );
  const originalProfileJSON = useSelector(
    (state: RootState) => state.sensorProfileStore.originalBuildProfiles[build.uuid!]
  );
  const profileJSON = useSelector((state: RootState) => state.sensorProfileStore.buildProfiles[build.uuid]);

  const [applyToProfile, setApplyToProfile] = useState<boolean>(false);
  const [applyToProfileDialogOpen, setApplyToProfileDialogOpen] = useState(false);

  useEffect(() => {
    return () => {
      if (savingTimeoutError) {
        clearTimeout(savingTimeoutError);
      }
      savingTimeoutError = null;
    };
  }, []);

  useEffect(() => {
    async function toggleSavingAndSaveToProfile() {
      if (savingTimeoutError) {
        clearTimeout(savingTimeoutError);
      }
      setSaved(true);
      savingTimeoutError = null;

      if (applyToProfile) {
        const res = await sensorProfilesPUT(build.sensorProfileUuid!, {
          profileJSON: profileJSON!,
        });

        if (!res.success)
          toast('Build settings updated, but failed to save profile', {
            type: 'error',
          });
      }
      setSaving(false);
    }

    if (saving) toggleSavingAndSaveToProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.currentConfigUrl]);

  // Send through to midrig
  // Midrig saves new profile to s3 with a revision number
  // useEffect above to check for updates to the field
  async function onSave(applyToProfile = false) {
    setSaving(true);
    setSaved(false);
    setSavingError(false);
    setApplyToProfile(applyToProfile);
    setApplyToProfileDialogOpen(false);
    const res = await buildConfigPUT(build.uuid, profileJSON!);

    if (!res.success) {
      setApplyToProfile(false);
      setSaving(false);
    }

    savingTimeoutError = setTimeout(() => {
      toast('Saving build profile timed out', {type: 'error'});
      setSavingError(true);
      setSaving(false);
    }, 30_000);
  }

  function onCancel() {
    sensorProfileActions.setSensorProfile(build.uuid, cloneDeep(originalProfileJSON), {isBuild: true});

    clearErrors();
  }

  function onResetDefaults() {
    sensorProfileActions.setSensorProfile(build.uuid, cloneDeep(defaultProfile), {isBuild: true});

    clearErrors();
  }

  const hasUnsavedChanges = !isEqual(originalProfileJSON, profileJSON);

  return (
    <>
      <FormActionButtons
        isNewProfile={false}
        saving={saving}
        onSave={() => setApplyToProfileDialogOpen(true)}
        onCancel={onCancel}
        onResetDefaults={onResetDefaults}
        hasUnsavedChanges={hasUnsavedChanges}
        hasError={hasError}
        saveTooltip="Changes will come into effect on the next operation"
      />
      <GenericDialog
        hideCloseTextButton
        isOpen={applyToProfileDialogOpen}
        title="Apply changes to Sensor Profile?"
        content="Do you want these changes to apply the sensor profile which this build is using or just to this build?"
        closeDialog={() => setApplyToProfileDialogOpen(false)}
        confirmText="Just this Build"
        onSuccess={() => onSave(false)}
        additionalConfirms={[
          {
            confirmText: 'Apply to Profile & Build',
            onSuccess: () => onSave(true),
          },
        ]}
      />
    </>
  );
}

export default BuildProfileToolbar;
