import {Units} from '@common/utils/measurements';

export type MachineParameterSortOptions = 'name' | 'registered' | 'genericNotes';

export enum MachineParameterDefaultKeys {
  LaserSpeed = 'laserSpeed',
  LaserPower = 'laserPower',
  NumContours = 'numContours',
  LayerThickness = 'layerThickness',
}

export type MachineParameterDefaultFields = {
  [key in MachineParameterDefaultKeys]: string;
};

export const MACHINE_PARAMETER_DEFAULT_FIELDS: [MachineParameterDefaultKeys, string, Units][] = [
  [MachineParameterDefaultKeys.LaserSpeed, 'Laser Speed', Units.MillimetersPerMinute],
  [MachineParameterDefaultKeys.LaserPower, 'Laser Power', Units.Watts],
  [MachineParameterDefaultKeys.NumContours, 'Number of Contours', Units.Number],
  [MachineParameterDefaultKeys.LayerThickness, 'Layer Thickness', Units.Micrometers],
];

export type MachineParameterData = {
  [key: string]: string;
} & MachineParameterDefaultFields;

export interface IMachineParameter {
  uuid: string;
  organizationUuid: string;
  name: string;
  data: MachineParameterData;
  genericNotes: string;
  registered: Date;
  deletedAt?: Date;
}
