import axios from 'axios';

class LazyLayerImage {
  uuid: string;
  widthPx: number;
  heightPx: number;
  widthMM: number;

  imageBacked: HTMLImageElement | undefined;
  signedUrl?: string; // Set on request or reload

  constructor(uuid: string, widthPx: number, heightPx: number, widthMM: number = 250) {
    this.uuid = uuid;
    this.heightPx = heightPx;
    this.widthPx = widthPx;
    this.widthMM = widthMM;
  }

  srcFetcher?: Promise<string>;

  get src(): Promise<string> {
    if (!this.srcFetcher) {
      this.srcFetcher = new Promise((res, rej) => {
        this.fetchImageUrl()
          .then((url) => res(url))
          .catch((msg) => rej(msg));
      });
    }
    return this.srcFetcher;
  }

  get image(): Promise<HTMLImageElement> {
    return new Promise((res, rej) => {
      if (!this.imageBacked) {
        this.imageBacked = new Image();
        this.imageBacked.crossOrigin = 'Anonymous';
      }
      if (!this.imageBacked.src) {
        this.src
          .then((src) => {
            this.imageBacked!.onerror = () => {
              if (this.shouldFetchNewUrl) {
                this.imageBacked = new Image();
                this.imageBacked.crossOrigin = 'Anonymous';
                this.fetchImageUrl()
                  .then((url) => (this.imageBacked!.src = url))
                  .catch((msg) => rej(msg));
              }
            };
            this.imageBacked!.src = src;
            res(this.imageBacked!);
          })
          .catch((e) => rej(e));
      } else {
        res(this.imageBacked);
      }
    });
  }

  private get shouldFetchNewUrl(): boolean {
    return this.signedUrl === undefined; // TODO add check of expiration of sigV4 s3 signed url /Date=(\d{8}T\d{6}Z)/
  }

  private async fetchImageUrl(): Promise<string> {
    if (!this.shouldFetchNewUrl) return this.signedUrl!;

    try {
      const res = await axios.get(`/api/layerImages/downloadUrl/`, {params: {uuid: this.uuid}});
      this.signedUrl = res.data.data;
      return res.data.data;
    } catch (err: any) {
      if (err.response) {
        return err.response.data.message as string;
      } else {
        return err.message as string;
      }
    }
  }
}

export default LazyLayerImage;
