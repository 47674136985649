import React from 'react';
import Chart from 'react-apexcharts';

function PieChart({
  options,
  series,
  height,
  otherProps,
}: {
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  options?: ApexCharts.ApexOptions;
  height?: number;
  otherProps?: React.ComponentProps<typeof Chart>;
}) {
  const {plotOptions = {}, ...otherOptions} = options || {};

  return (
    <Chart
      type="donut"
      series={series}
      height={height}
      options={{
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
              labels: {
                show: true,
                total: {
                  show: true,
                },
                name: {
                  color: '#888',
                  fontSize: '13px',
                },
                value: {
                  color: '#111',
                  fontSize: '30px',
                },
              },
            },
          },
          ...plotOptions,
        },
        stroke: {lineCap: 'round'},
        ...otherOptions,
      }}
      {...otherProps}
    />
  );
}

export default PieChart;
