import React, {useEffect, useState} from 'react';
import {Tune} from '@material-ui/icons';
import {Button, Typography} from '@material-ui/core';
import {ComparisonWeightsType, DEFAULT_WEIGHTS} from '@common/api/models/builds/data/ISimilarity';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import VerticalButton from '../../../components/atoms/VerticalButton';
import WeightsForm from './ComparisonWeights/WeightsForm';
import {isEqual} from 'lodash';

interface SimilarityComparisonWeightsProps {
  comparisonWeights: ComparisonWeightsType;
  onSave: (newWeights: ComparisonWeightsType) => Promise<boolean>;
  useVerticalButton?: boolean;
}

function SimilarityComparisonWeights({comparisonWeights, onSave, useVerticalButton}: SimilarityComparisonWeightsProps) {
  const isSmallScreen = useSmallScreenSize();
  const [currentWeights, setCurrentWeights] = useState<ComparisonWeightsType | null>(null);
  const [saving, setSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setCurrentWeights(comparisonWeights);

    return () => setCurrentWeights(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  async function saveWeights() {
    setSaving(true);
    const success = await onSave(currentWeights!);
    setSaving(false);
    if (success) setModalOpen(false);
  }

  function resetToDefaults() {
    setCurrentWeights(DEFAULT_WEIGHTS);
  }

  return (
    <>
      {useVerticalButton ? (
        <VerticalButton onClick={() => setModalOpen(true)} color="secondary">
          <Tune />
          <Typography variant="caption">Weights</Typography>
        </VerticalButton>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setModalOpen(true)}
          endIcon={<Tune />}
          fullWidth={isSmallScreen}
          style={isSmallScreen ? {marginBottom: '12px'} : {}}
        >
          Weights
        </Button>
      )}
      <GenericDialog
        title="Comparison weights"
        isOpen={modalOpen}
        icon={<Tune />}
        onSuccess={saveWeights}
        requestInProgress={saving}
        maxWidth="sm"
        closeDialog={() => setModalOpen(false)}
        additionalConfirms={[
          {
            onSuccess: resetToDefaults,
            confirmText: 'Reset to defaults',
            confirmDisabled: isEqual(currentWeights, DEFAULT_WEIGHTS),
          },
        ]}
        content={<WeightsForm comparisonWeights={currentWeights} setComparisonWeights={setCurrentWeights} />}
      />
    </>
  );
}

export default SimilarityComparisonWeights;
