import axios from 'axios';
import {
  IBuildDataArchiveGETResponse,
  IBuildDataArchiveUrlResponse,
} from '@common/api/models/builds/data/IBuildDataArchive';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';

export async function buildDataArchiveGET(buildUuid: string): AjaxApiResult<IBuildDataArchiveGETResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${buildUuid}/dataArchive/`);
  });
}

export async function buildDataArchivePOST(
  buildUuid: string,
  sendEmail: boolean
): AjaxApiResult<IBuildDataArchiveGETResponse> {
  return ajaxWrapper(() => {
    return axios.post(`/api/builds/${buildUuid}/dataArchive/`, {sendEmail});
  }, 'Could not create build data archive');
}

export async function buildDataArchiveUrlGET(
  buildUuid: string,
  filename: string
): AjaxApiResult<IBuildDataArchiveUrlResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${buildUuid}/dataArchive/downloadUrl/${filename.replace('/', '')}`);
  }, 'Could not fetch build data archive download url');
}

export async function buildDataArchiveDELETE(buildUuid: string): AjaxApiResult<IBuildDataArchiveGETResponse> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/builds/${buildUuid}/dataArchive/`);
  }, 'Could not delete build data archive');
}
