import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Box, CircularProgress, Divider, Grid, IconButton, ListItem, Tooltip, Typography} from '@material-ui/core';
import {Error, Close, GetApp} from '@material-ui/icons';
import {BuildDataDownloadStatus, BuildDataArchive} from '../store/model/buildDataArchive';
import {RootState} from '../store/reducers';
import {useExtraSmallScreenSize, useLargeScreenSize, useSmallScreenSize} from './utilHooks';
import buildDataArchiveActions from '../store/actions/buildDataArchiveActions';
import LinearProgressWithLabel from '../components/atoms/LinearProgressWithLabel';
import EllipsisTextWithTooltip from '../components/atoms/Texts/EllipsisTextWithTooltip';
import {downloadBlob} from './webtools';

function alertUser(e: any) {
  e.preventDefault();
  e.returnValue = 'Some build data downloads are still in progress, are you sure you want to leave?';
}

type DownloadsToShow = BuildDataArchive & {show: boolean};

const BuildDataArchiveDownloadNotifications = ({
  sidebarOpen,
  pageHasBottomToolbar,
}: {
  sidebarOpen: boolean;
  pageHasBottomToolbar: boolean;
}) => {
  const isLg = useLargeScreenSize();
  const isSm = useSmallScreenSize();
  const isXs = useExtraSmallScreenSize();
  const appMargin = isLg ? 40 : isXs ? 20 : 28;
  const dispatch = useDispatch();

  const downloads = useSelector((state: RootState) => state.buildDataArchive.buildDataDownloads);
  const downloadInProgress = useSelector((state: RootState) =>
    state.buildDataArchive.buildDataDownloads.some(
      (download) => download.status === BuildDataDownloadStatus.IN_PROGRESS
    )
  );

  const [downloadsToShow, setDownloadsToShow] = useState<DownloadsToShow[]>([]);
  const [downloadsCount, setDownloadsCount] = useState(0);

  const isOpen = downloadsToShow.some((download) => !!download.show);
  const numDownloadsShowing = downloadsToShow.filter((download) => download.show).length;

  const toggleOpen = (indexToHide: number) => {
    setDownloadsToShow(
      downloadsToShow.map((download, index) => ({...download, show: indexToHide === index ? false : download.show}))
    );
  };

  useEffect(() => {
    if (downloadInProgress) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [downloadInProgress]);

  useEffect(() => {
    dispatch(buildDataArchiveActions.setNumDownloads(numDownloadsShowing));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numDownloadsShowing]);

  useEffect(() => {
    let allDownloads = Object.values(downloads);
    let numDownloads = allDownloads.length;

    // Update file count
    if (numDownloads !== downloadsCount) {
      setDownloadsCount(numDownloads);
    }

    const newDownloadsToShow = downloads.map((download, index) => {
      const current = downloadsToShow[index];
      const newDownload: DownloadsToShow = {...download, show: true};

      if (current) {
        newDownload.show = current.show;
        if (!current.show && !current.errorMessage && download.errorMessage) {
          newDownload.show = true;
        }
      }

      return newDownload;
    });

    setDownloadsToShow(newDownloadsToShow);

    return () => {
      setDownloadsToShow([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloads]);

  if (!isOpen) return <></>;

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        position: 'fixed',
        bottom: (isXs || isSm) && pageHasBottomToolbar ? '64px' : '0',
        left: isXs || isSm ? '0px' : sidebarOpen ? '264px' : '64px',
        right: '0',
        boxShadow: '0 -1px 3px 0 rgba(13, 60, 97, 0.1), 0 -1px 2px 0 rgba(13, 60, 97, 0.06)',
      }}
      height={`${48 * numDownloadsShowing + (numDownloadsShowing - 1) * 1}px`}
      bgcolor="rgb(232, 244, 253)"
      color="rgb(13, 60, 97)"
      padding={`0px ${appMargin}px `}
      zIndex={1000}
    >
      {downloadsToShow
        .map((download, index) => ({...download, outerIndex: index}))
        .filter((download) => download.show)
        .map((download, index) => {
          return (
            <React.Fragment>
              {index > 0 && <Divider style={{marginLeft: `-${appMargin}px`, marginRight: `-${appMargin}px`}} />}
              <ListItem key={`${download.zipFilename}-${index}`} style={{padding: '5px 5px 5px 16px', height: '48px'}}>
                <Grid container spacing={2}>
                  <CenteredGrid item xs={3}>
                    <EllipsisTextWithTooltip style={{fontWeight: 'bold'}}>
                      {download.zipFilename}
                    </EllipsisTextWithTooltip>
                  </CenteredGrid>

                  <CenteredGrid item xs={9}>
                    {download.status === BuildDataDownloadStatus.ERROR ? (
                      <EllipsisTextWithTooltip>{download.errorMessage}</EllipsisTextWithTooltip>
                    ) : (
                      <LinearProgressWithLabel value={download.totalProgress * 100} style={{borderRadius: '4px'}} />
                    )}

                    {download.status === BuildDataDownloadStatus.IN_PROGRESS && (
                      <CircularProgress size={20} style={{margin: '0px 6px 0px 12px'}} />
                    )}
                    {download.status === BuildDataDownloadStatus.COMPLETED && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          downloadBlob(download.zipFilename, download.blob!);
                        }}
                        style={{margin: '0px', marginLeft: '8px'}}
                      >
                        <GetApp />
                      </IconButton>
                    )}
                    {download.status === BuildDataDownloadStatus.ERROR && (
                      <Error fontSize="small" htmlColor="#FF0000" style={{margin: '0px 6px 0px 12px'}} />
                    )}

                    <Tooltip
                      title={
                        <Typography>
                          {[BuildDataDownloadStatus.COMPLETED, BuildDataDownloadStatus.ERROR].includes(download.status)
                            ? 'Download finished, remove download from list.'
                            : 'Hide active download. Download will still process in the background.'}
                        </Typography>
                      }
                    >
                      <IconButton size="small" onClick={() => toggleOpen(download.outerIndex)} style={{margin: 0}}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </CenteredGrid>
                </Grid>
              </ListItem>
            </React.Fragment>
          );
        })}
    </Box>
  );
};

export default BuildDataArchiveDownloadNotifications;

const CenteredGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;
