import * as React from 'react';
import {
  Grid,
  ModalProps,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {BatchesRow, BatchesTable} from '../Table/BatchesTable';
import {useEffect, useState} from 'react';
import {useBatchStoreActions, useMaterialStoreActions} from '../../../store/actions';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {withTheme} from 'styled-components';
import {Close} from '@material-ui/icons';

export interface BatchSelectorModalProps extends Partial<ModalProps> {
  open: boolean;
  initiallySelectedUuid?: string;
  onBatchSelected: (batch: IBatch | undefined) => any;
  disabled?: string[];
}

function BatchSelectorModal(props: BatchSelectorModalProps & {theme: Theme}) {
  const batchStoreActions = useBatchStoreActions();
  const materialStoreActions = useMaterialStoreActions();
  const {theme, onBatchSelected, initiallySelectedUuid, ...rest} = props;

  const batches = useSelector((state: RootState) => {
    return Object.values(state.batchStore.byId).filter((f) => f.current);
  });

  const materialStore = useSelector((state: RootState) => {
    return state.materialStore;
  });

  useEffect(() => {
    // TODO: paginate batches
    batchStoreActions.ensureConsistent({current: true});
    materialStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedBatchUuid, setSelectedBatchUuid] = useState<string | undefined>(initiallySelectedUuid);

  const onSubmit = () => {
    const batch = batches.find((m) => m.uuid === selectedBatchUuid);
    onBatchSelected(batch);

    props.onClose && props.onClose({}, 'escapeKeyDown');
  };

  const handleRowClick = (rowData: BatchesRow | undefined) => {
    if (props.disabled && props.disabled.includes(rowData?.uuidFull!)) {
      return;
    }
    setSelectedBatchUuid(rowData?.uuidFull);
  };

  return (
    <Dialog {...rest} maxWidth={'lg'} fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Merge Batch</Typography>
          <IconButton
            aria-label="close"
            onClick={() => props.onClose && props.onClose({}, 'escapeKeyDown')}
            style={{padding: '0px'}}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={6}>
          <Grid item sm={12} md={12} lg={12}>
            <h2>Select a Batch</h2>
            <p>Click a row to choose a Batch</p>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            style={{
              overflowX: 'scroll',
            }}
          >
            <BatchesTable
              batches={batches}
              materialsByUuid={materialStore.byId}
              onRowClick={(e, rowData) => {
                if (props.disabled && props.disabled.includes(rowData?.uuidFull!)) {
                  return;
                }
                handleRowClick(rowData);
              }}
              options={{
                rowStyle: (batchData) => {
                  if (selectedBatchUuid === batchData.uuidFull) {
                    return {
                      backgroundColor: theme.palette.grey['200'],
                      color: theme.palette.common.black,
                    };
                  } else if (props.disabled) {
                    if (props.disabled.includes(batchData.uuidFull)) {
                      return {
                        cursor: 'not-allowed !important',
                        color: theme.palette.grey['400'],
                      };
                    }
                  }

                  return {};
                },
              }}
            />
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Grid container justifyContent={'space-between'}>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'} spacing={2} sm={12}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => setSelectedBatchUuid(undefined)}>
              Clear Selection
            </Button>
          </Grid>
          <Grid item style={{marginLeft: 'auto'}}>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="primary" onClick={() => props.onClose && props.onClose({}, 'escapeKeyDown')}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withTheme(BatchSelectorModal) as (props: BatchSelectorModalProps) => any;
