import {useEffect, useMemo, useReducer, useState} from 'react';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {BufferGeometry, Color, Object3D} from 'three';
import {PointCloud2} from '../types/pointCloudV2';
import {View3DViewportParams} from '../View3DViewport';
import {PartPointClouds, PointCloudData} from '../PartPointCloud';
import {objMapValues} from '../../../../../utils/objectFunctions';
import {RootState} from '../../../../../store/reducers';
import {FetchingState} from '../../../../../store/model/liveUpdateStore';
import {usePointCloudStoreActions} from '../../../../../../src/store/actions';
import {SOURCE_PART_COLOR, TARGET_PART_COLOR} from '../AnalysisTypeHelper';
import {BoundingBox} from '../Base3DViewport';
import {PointCloud3} from '../types/pointCloudV3';
import {AnalysisTypeMap, sphereGeometry, ThreePoints, View3DState} from '../types/pointCloudTypes';
import {
  scaleMeshToBoundingBox,
  centerPointCloud,
  getPointCloudDimensions,
  invertZAndY,
  rotatePoints,
  alignToBoundingBox,
} from '../viewportFunctions/control';
import {downloadPartModel, downloadPointCloud} from '../loaders/downloadPointClouds';
import {loadPartModelAsMesh, loadPointCloudAsMesh} from '../loaders/meshLoaders';
import {loadPointCloudAsPoints, pointCloudBoundsFromPart} from '../loaders/pointLoaders';

/**
 * Custom hook that Adds and Hides Pointclouds based on the input arguments
 *
 * @param partUuids - An array of part UUIDs.
 * @param analysisTypes - A map of analysis types and their availability.
 * @param params - Viewport parameters.
 * @param renderScene - A function to render the scene.
 * @returns An object containing the viewport state, point clouds, point cloud data, scene bounds, and available analysis types.
 */
export function usePartPointClouds(
  partUuids: string[],
  analysisTypes: AnalysisTypeMap<boolean>,
  params: View3DViewportParams,
  renderScene: () => void
) {
  const [viewportState, setViewportState] = useState<View3DState>('noselection');

  const [pointCloudData] = useState(() => new PointCloudData());
  const [forcedReload, forceReload] = useReducer((x) => x + 1, 0);

  const [pointClouds] = useState(() => new PartPointClouds());

  const [availableAnalysisTypes, setAvailableAnalysisTypes] = useState<AnalysisTypeMap<boolean>>(
    objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>
  );

  const partUuidsString = JSON.stringify(partUuids);
  const selectedPartModelUuids = (params.selectedPartModels || []).map((partModel) => partModel.uuid);
  const partModelUuidsString = JSON.stringify(selectedPartModelUuids);

  useEffect(() => {
    if (partUuids.length === 0 && selectedPartModelUuids.length === 0) {
      setViewportState('noselection');
      return;
    }
  }, [partUuids.length, selectedPartModelUuids.length]);

  const loadPointCloudWrapper = (
    pointCloud: PointCloud2 | PointCloud3,
    partUuid: string,
    analysisType: AnalysisType3D
  ) => {
    pointCloudData.incDownloadedPartitions(partUuid);

    const result = params.use3DPoints
      ? loadPointCloudAsMesh(pointCloud, analysisType, params, sphereGeometry)
      : loadPointCloudAsPoints(pointCloud, analysisType, params);

    if (!result.success) {
      toast(result.error, {type: 'error'});
      return;
    }

    let object = result.object;
    let bounds: BoundingBox =
      pointCloudBoundsFromPart(params.selectedParts.find((part) => part.uuid === partUuid)!) || result.bounds;

    if (!bounds.layerBounds) bounds.layerBounds = result.bounds.layerBounds;

    if (params.centerAllParts) {
      ({points: object, bounds} = centeredPointCloud(partUuid, object, bounds, partUuids, params));
    }

    if (pointClouds.getPointClouds(partUuid, analysisType).length === 0) {
      pointClouds.addPointCloud(partUuid, analysisType, object);
      pointCloudData.setPartBounds(partUuid, bounds);
    }
  };

  const loadPartModelWrapper = async (partModel: BufferGeometry, uuid: string, partUuid: string) => {
    pointCloudData.incDownloadedPartitions(uuid);
    const initialRotation = params.rotation?.[uuid] || 0;

    let bounds: BoundingBox | null = pointCloudData.getPartBounds([partUuid]);

    if (!bounds) {
      bounds = pointCloudBoundsFromPart(params.availableParts.find((part) => part.uuid === partUuid)!);
    }

    const {frontMesh, backMesh} = loadPartModelAsMesh(partModel, params);

    frontMesh.userData.isPartModel = true;
    backMesh.userData.isPartModel = true;

    if (bounds) {
      pointCloudData.setPartBounds(uuid, bounds);
      invertZAndY(frontMesh, initialRotation);
      scaleMeshToBoundingBox(frontMesh, bounds);
      alignToBoundingBox(frontMesh, bounds);
    }
    if (pointClouds.getPointClouds(uuid, AnalysisType3D.PartModel).length === 0) {
      for (const mesh of [frontMesh, backMesh]) {
        pointClouds.addPointCloud(uuid, AnalysisType3D.PartModel, mesh);
        mesh.userData.partUuid = partUuid;
        mesh.userData.partModelUuid = uuid;
        mesh.userData.isPartModel = true;
        mesh.userData.initialRotation = initialRotation;
        mesh.userData.bounds = bounds;
      }
    }
  };

  const downloadPointCloudWrapper = (pointCloudUuid: any, partUuid: string, analysisType: AnalysisType3D) =>
    downloadPointCloud(pointCloudUuid, partUuid, analysisType, loadPointCloudWrapper, () => {
      toast('Could not download 3D data', {type: 'error'});
      setViewportState('failed');
    });

  const downloadPartModelWrapper = (partModelUuid: string, partUuid: string) =>
    downloadPartModel(partModelUuid, partUuid, loadPartModelWrapper, () => {
      toast('Could not download 3D data', {type: 'error'});
      setViewportState('failed');
    });

  const pointCloudDataString = useMemo(
    () => JSON.stringify(pointCloudData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forcedReload, pointCloudData]
  );

  useEffect(() => {
    // This useEffect is called when the selected parts or analysis types change.

    // Set visibility for previously loaded parts
    pointClouds.setVisibleParts([...partUuids, ...selectedPartModelUuids]);
    renderScene();

    // Selected parts may have changed, so available analysis types may need updating
    const newAvailableAnalysisTypes = pointCloudData.availableAnalysisTypes(partUuids);
    if (!params.isCtResults) {
      newAvailableAnalysisTypes[AnalysisType3D.Pore] = false;
    }
    setAvailableAnalysisTypes(newAvailableAnalysisTypes);

    // If no parts are selected, there's nothing to load
    if (partUuids.length === 0 && selectedPartModelUuids.length === 0) {
      return;
    }

    // If we get to this point, parts are selected,
    // so we should never be in the 'noselection' state
    if (viewportState === 'noselection') {
      setViewportState('viewing');
    }

    // Load analysis point clouds
    const promises = [] as Promise<void>[];

    selectedPartModelUuids.forEach((partModelUuid) => {
      const partUuid = params.availablePartModels?.find((partModel) => partModel.uuid === partModelUuid)?.partUuid!;

      if (pointClouds.getPointClouds(partModelUuid, AnalysisType3D.PartModel).length === 0) {
        promises.push(downloadPartModelWrapper(partModelUuid, partUuid));
      }
    });

    partUuids.forEach((partUuid) => {
      Object.values(AnalysisType3D)
        .filter(
          // Select point clouds that are not Model, have been selected by the user,
          // aren't already loaded and that are available to load
          (analysisType) =>
            analysisType !== AnalysisType3D.Model &&
            analysisTypes[analysisType] &&
            pointClouds.getPointClouds(partUuid, analysisType).length === 0 &&
            pointCloudData.getPointClouds(partUuid, analysisType).length > 0
        )
        .forEach((analysisType) => {
          // For the time being, we only load the first partition.
          promises.push(
            downloadPointCloudWrapper(
              pointCloudData.getPointClouds(partUuid, analysisType)[0].uuid,
              partUuid,
              analysisType
            )
          );
        });

      // Load model point clouds
      // Only load enough point cloud partitions to to reach params.pointLimit across all parts (worst case)
      let pointCount = 0;
      for (
        let partitionIndex = 0;
        partitionIndex < pointCloudData.getPointClouds(partUuid, AnalysisType3D.Model).length &&
        pointCount < params.pointLimit / partUuids.length;
        partitionIndex++
      ) {
        const pointCloud = pointCloudData.getPointClouds(partUuid, AnalysisType3D.Model)[partitionIndex];
        pointCount += pointCloud.numPoints;

        if (pointCloudData.getPartitionsDownloaded(partUuid) > partitionIndex) continue;

        promises.push(downloadPointCloudWrapper(pointCloud.uuid, partUuid, AnalysisType3D.Model));
      }

      const noPointCloudsLoaded = pointClouds.numPointClouds === 0;

      if (!promises.length && noPointCloudsLoaded) {
        setViewportState('unavailable');
        return;
      }
    });

    // If there's no work to be done, retain current viewport state
    if (promises.length === 0) {
      return;
    }

    setViewportState('loading');

    // Wait for all point clouds to be loaded, then render
    // Check Promise.all twice since the first round of promises adds a second to the array
    Promise.all(promises).then(async () => {
      Promise.all(promises).then(async () => {
        if (partUuids.every((partUuid) => pointCloudData.getPartitionsDownloaded(partUuid) > 0)) {
          setViewportState('viewing');
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisTypes, params.pointLimit, partUuidsString, partModelUuidsString, pointCloudDataString]);

  const pointCloudStoreActions = usePointCloudStoreActions();
  const pointCloudStore = useSelector((state: RootState) => state.pointCloudStore);

  useEffect(() => {
    if (partUuids.length === 0) return;
    pointCloudStoreActions.ensureConsistent({partUuid: partUuids});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(partUuids)]);

  const pointCloudStoreString = JSON.stringify(pointCloudStore);

  useEffect(() => {
    // Load point cloud locations for selected parts
    if (partUuids.length === 0) return;

    const relevantPointClouds = Object.values(pointCloudStore.byId).filter((pc) => partUuids.includes(pc.partUuid));

    if (pointCloudStore.fetched === FetchingState.Fetching && relevantPointClouds.length === 0) {
      setViewportState('loading');
      return;
    }
    if (pointCloudStore.fetched === FetchingState.Fetched && relevantPointClouds.length === 0) {
      setViewportState('unavailable');
      return;
    }

    relevantPointClouds.forEach(pointCloudData.addPointCloud.bind(pointCloudData));
    forceReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(partUuids), pointCloudStoreString]);

  return {
    viewportState,
    pointClouds,
    pointCloudData,
    sceneBounds:
      partUuids.length || selectedPartModelUuids.length
        ? pointCloudData.getPartBounds([...partUuids, ...selectedPartModelUuids])
        : null,
    availableAnalysisTypes,
  };
}

function centeredPointCloud(
  partUuid: string,
  object: Object3D,
  bounds: BoundingBox,
  partUuids: string[],
  params: View3DViewportParams
) {
  const points = object as ThreePoints;

  if (params.rotation && partUuid in params.rotation) {
    rotatePoints(points, params.rotation[partUuid]);
  }

  centerPointCloud(points, params.isSimilarity, params.throughPlane);

  const {size} = getPointCloudDimensions(points);
  if (params.throughPlane) {
    bounds.min = {x: 0 - size.x / 2, y: 0 - size.y / 2, z: 0 + size.z / 2};
  } else {
    bounds.min = {x: 0 - size.x / 2, y: 0, z: 0 + size.z / 2};
  }

  if (partUuids.length === 2) {
    if (partUuid === partUuids[0]) {
      points.material.color = new Color(SOURCE_PART_COLOR);
    } else {
      points.material.color = new Color(TARGET_PART_COLOR);
    }
  }

  return {points, bounds};
}
