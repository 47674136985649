import React from 'react';
import {Add} from '@material-ui/icons';
import {ViewerButton} from '../../FadingComponents';
import {BUTTON_MARGIN, BUTTON_HEIGHT} from '../utils';

interface AddViewProps {
  handleAddView: () => void;
  mouseOn: boolean;
}

export default function AddView(props: AddViewProps) {
  const {handleAddView, mouseOn} = props;
  return (
    <ViewerButton
      onClick={handleAddView}
      style={{
        right: BUTTON_MARGIN + BUTTON_HEIGHT + 32,
        top: BUTTON_MARGIN,
        position: 'absolute',
      }}
      className={(!mouseOn && 'fade') || undefined}
    >
      <Add /> Add View
    </ViewerButton>
  );
}
