import * as React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Switch, {SwitchProps} from '@material-ui/core/Switch';

const CustomSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 3,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&$disabled': {
        '& + $track': {
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
        },
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    boxShadow: 'none',
  },
  track: {
    border: 0,
    borderRadius: `25px`,
    opacity: 1,
    backgroundColor: `${theme.palette.primary.main}50`,
  },
  checked: {},
  disabled: {},
}))(Switch);

/*
 * Ant Switch appearance can be seen in the Material UI Customized Switch Samples:
 * https://v4.mui.com/components/switches/#customized-switches
 */
function AntSwitch(props: SwitchProps) {
  return <CustomSwitch {...props} />;
}

export default AntSwitch;
