import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import {DateWithTimeTooltip} from './sharedColumns';

export interface OrganizationRow {
  name: string;
  current: boolean;
  registered: Date | string;
}

export const organizationTableSchema = [
  {
    title: 'Current',
    field: 'current',
    sorting: false,
    width: '86px',
    render: (row: OrganizationRow) => (row.current ? <CheckIcon /> : <RemoveIcon />),
  },
  {title: 'Name', field: 'name'},
  {
    title: 'Registered On',
    field: 'registered',
    type: 'date' as const,
    emptyValue: '',
    width: '148px',
    render: (row: OrganizationRow) => <DateWithTimeTooltip timestamp={row.registered} />,
  },
];

export default organizationTableSchema;
