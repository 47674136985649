import React from 'react';
import {Button, Typography} from '@material-ui/core';
import {Add, LibraryAdd} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import VerticalButton from '../../../components/atoms/VerticalButton';

const AddMoreTargetPartsButton = ({uuid}: {uuid: string}) => {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();

  const handleNavigation = () => {
    history.push(`/reports/similarity/uuid/${uuid}/addMoreTargetParts`);
  };

  if (isSmallScreen) {
    return (
      <VerticalButton onClick={handleNavigation} color="secondary">
        <Add />
        <Typography variant="caption">Add targets</Typography>
      </VerticalButton>
    );
  }

  return (
    <Button variant="contained" color="primary" endIcon={<LibraryAdd />} size="medium" onClick={handleNavigation}>
      Add targets
    </Button>
  );
};

export default AddMoreTargetPartsButton;
