import React from 'react';
import {Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import SettingsInput from '@material-ui/icons/SettingsInputComponent';
import {RootState} from '../../../../store/reducers/index';
import {dangerColor} from '../../../../assets/jss/material-dashboard-react';
import FieldOfViewStates, {CAMERA_ORDER} from './FieldOfViewStates';

export function useCameraState(deviceSerial: string) {
  const device = useSelector((state: RootState) => state.deviceStore.byId[deviceSerial]);

  const fieldOfViews = CAMERA_ORDER.map((fov) => fov.map((id) => device?.cameraStates?.[id] ?? null));
  const allCamerasConnected = Object.values(device?.cameraStates || {}).length
    ? Object.values(device?.cameraStates || {}).every((cameraState) => cameraState)
    : false;

  return {fieldOfViews, allCamerasConnected};
}

function CameraState({deviceSerial}: {deviceSerial?: string}) {
  const device = useSelector((state: RootState) => state.deviceStore.byId[deviceSerial!]);
  const {fieldOfViews, allCamerasConnected} = useCameraState(deviceSerial!);

  if (!fieldOfViews || !device?.online) return <></>;

  return (
    <FieldOfViewStates
      fieldOfViews={fieldOfViews}
      icon={<SettingsInput />}
      globalStateTitle={
        allCamerasConnected ? undefined : (
          <Typography>
            <b style={{color: '#FFC7C7'}}>One or more cameras are disconnected.</b>
          </Typography>
        )
      }
      heading="Camera Connection Statuses"
      globalState={allCamerasConnected}
      offColour={dangerColor[1]}
      helperText="If one or more cameras are disconnected, build operations will fail."
      placement="left"
    />
  );
}

export default CameraState;
