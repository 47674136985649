import axios from 'axios';
import * as qs from 'qs';
import {
  ISliceAttachment,
  ISliceAttachmentGETResponse,
  ScanStrategy,
} from '@common/api/models/attachments/ISliceAttachment';

import {uploadParamType} from '../../pages/builds/shared/SliceAttachmentMultipartUpload';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';

export async function sliceAttachmentsAllGET(
  filter: QueryFilter<ISliceAttachment>
): AjaxApiResult<ISliceAttachmentGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/sliceAttachments/' + (query && '?' + query));
  }, 'Could not fetch sliceAttachments');
}

export async function sliceAttachmentByUuidDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete('/api/sliceAttachments/' + uuid);
  }, 'Could not delete slice attachment');
}

/** Download any file that has been uploaded (not just slice files) */
export async function sliceAttachmentDownloadUrlGET(uuid: string): AjaxApiResult<string> {
  return ajaxWrapper(() => {
    return axios.get('/api/sliceAttachments/downloadUrl/', {params: {uuid}});
  }, 'Could not download file');
}

// upload:

export async function sliceAttachmentMultipartUploadInitiatePOST(
  buildUuid: string,
  filename: string,
  filesize: number, // bytes
  scanStrategy: ScanStrategy | undefined
): AjaxApiResult<uploadParamType> {
  return ajaxWrapper(() => {
    return axios.post('/api/sliceAttachments/multipart/initiate', {
      buildUuid,
      filename,
      filesize,
      scanStrategy,
    });
  }, 'Could not upload file');
}

export async function sliceAttachmentMultipartUploadCompletePOST(
  uploadId: string,
  buildUuid: string,
  key: string,
  parts: {ETag: string; PartNumber: number}[],
  scanStrategy: ScanStrategy,
  isBuildPhoto?: boolean
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/sliceAttachments/multipart/complete/', {
      uploadId,
      buildUuid,
      key,
      parts,
      scanStrategy,
      isBuildPhoto,
    });
  });
}

export async function sliceAttachmentMultipartUploadAbortPOST(uploadId: string, key: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/sliceAttachments/multipart/abort/', {
      uploadId,
      key,
    });
  }, 'Could not abort upload file');
}
