import React, {useState} from 'react';
import {Card, Collapse, Grid, IconButton, Typography} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {inflect} from 'inflection';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {IBuild} from '@common/api/models/builds/IBuild';
import ResourcePermissionsTable from '../../../../components/molecules/Table/ResourcePermissionsTable';
import {RootState} from '../../../../store/reducers/index';
import {useSmallScreenSize} from '../../../../utils/utilHooks';

function UserAccess({build}: {build: IBuild}) {
  const isSmall = useSmallScreenSize();

  const [permissionsExpanded, setPermissionsExpanded] = useState<boolean>(false);
  const permissionsList = useSelector((state: RootState) => state.resourcePermissionStore.list);

  return (
    <Card style={{padding: isSmall ? '15px' : '30px'}}>
      <Grid container spacing={2} style={{margin: '0'}} justifyContent="space-between">
        <Grid item xs={12} sm={4} style={{display: 'flex'}} alignItems="center">
          <Typography variant="h6" gutterBottom display="inline">
            User Access
          </Typography>
        </Grid>

        <Grid item xs={9} sm={5} style={{display: 'flex'}} alignItems="center">
          {permissionsList.length} {inflect('Technician', permissionsList.length)}
        </Grid>
        <Grid item xs={3} sm={3} container direction="row-reverse">
          <IconButton
            style={{padding: '7px'}}
            onClick={() => setPermissionsExpanded(!permissionsExpanded)}
            aria-expanded={permissionsExpanded}
            aria-label="show more"
          >
            {permissionsExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>

        <Collapse in={permissionsExpanded} timeout="auto">
          <ResourcePermissionsTable resourceType={ResourcePermissionType.BUILD} resource={build} />
        </Collapse>
      </Grid>
    </Card>
  );
}

export default UserAccess;
