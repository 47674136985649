import axios from 'axios';
import * as qs from 'qs';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {IDefectNotification} from '@common/api/models/builds/data/defects/IDefectNotifications';

export async function defectNotificationGET(
  filter: QueryFilter<IDefectNotification>
): AjaxApiResult<IDefectNotification[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/defectNotification/' + (query && '?' + query));
  }, 'Could not get defect notification data');
}

export async function defectNotificationPOST(data: Partial<IDefectNotification>): AjaxApiResult<IDefectNotification> {
  return ajaxWrapper(() => {
    return axios.post('/api/defectNotification/', data);
  }, 'Could not create defect notification');
}

export async function defectNotificationDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/defectNotification/uuid/${uuid}`);
  }, 'Could not delete defect notification');
}
