import {ObjectFilter, QueryFilter} from '../actions/liveUpdateStore';

export enum LiveStoreActions {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',

  START_FETCH = 'START_FETCH',
  FINISH_FETCH = 'FINISH_FETCH',
  FETCH_ERROR = 'FETCH_ERROR',
  INVALIDATE = 'INVALIDATE',
  INVALIDATE_FETCH_HISTORY = 'INVALIDATE_FETCH_HISTORY',

  LOG_LAST_WS_FILTER = 'LOG_LAST_WS_FILTER',
  LOG_LAST_WS_CONN = 'LOG_LAST_WS_CONN',

  // List (table) actions
  UPDATE_LIST = 'UPDATE_LIST',
  INSERT_LIST = 'INSERT_LIST',
  DELETE_LIST = 'DELETE_LIST',

  START_LIST_FETCH = 'START_LIST_FETCH',
  FINISH_LIST_FETCH = 'FINISH_LIST_FETCH',
  FETCH_LIST_ERROR = 'FETCH_LIST_ERROR',

  LOG_LAST_WS_LIST_CONN = 'LOG_LAST_WS_LIST_CONN',
  UNSUBSCRIBE_FROM_LIST = 'UNSUBSCRIBE_FROM_LIST',
  INVALIDATE_LIST_HISTORY = 'INVALIDATE_LIST_HISTORY',
}

export enum FetchingState {
  None,
  Fetching,
  Fetched,
  Error,
}

export type FetchHistory<T> = Array<{
  filters: QueryFilter<T>;
  list: Array<T>;
  total?: number;
}>;
export interface LiveStoreState<T> {
  byId: {
    [key: string]: T;
  };
  list: Array<T>;
  listFetched: FetchingState;
  listFetchHistory: FetchHistory<T>;
  listErrorMessage: string | undefined;
  listTotal?: number;
  listFilters?: QueryFilter<T>;
  lastListWsConn?: WebSocket | null;

  // HTTP request details
  total?: number;
  fetched: FetchingState;
  fetchHistory: FetchHistory<T>;
  errorMessage: string | undefined; // Set on non-2xx response

  // Last WebSocket connection & filter applied to that connection
  lastWsFilter: {[key in keyof T]?: string} | null;
  lastWsConn: WebSocket | null;
}

export function createInitialLiveStore<T>(): LiveStoreState<T> {
  return {
    byId: {},
    fetched: FetchingState.None,
    fetchHistory: [],
    errorMessage: undefined,
    lastWsFilter: null,
    lastWsConn: null,
    list: [],
    listFetched: FetchingState.None,
    listFetchHistory: [],
    listErrorMessage: undefined,
  };
}

// Store Action Types:

interface LiveStoreActionType<P> {
  type: string; // LiveStoreActions + identifierSuffix
  payload: P;
}

export interface ApiResponsePayload<T> {
  // TODO move to common
  data: T | T[];
  count: number | undefined;
  filters?: QueryFilter<T>;
}

export type LiveStoreUpdateAction<T> = LiveStoreActionType<T>;
export type LiveStoreDeleteAction<T> = LiveStoreActionType<T>; // Note: payload is partial T - PKs only
export type LiveStoreStartFetchAction<T> = LiveStoreActionType<QueryFilter<T>>;
export type LiveStoreStartFetchActionFilter<T> = LiveStoreActionType<{
  filter: QueryFilter<T>;
  setLoading?: boolean;
}>;
export type LiveStoreFinishFetchAction<T> = LiveStoreActionType<ApiResponsePayload<T>>;
export type LiveStoreFetchErrorAction<_T> = LiveStoreActionType<string>;
export type LiveStoreInvalidateAction<_T> = LiveStoreActionType<string | undefined>;
export type LiveStoreLogLastWsFilterAction<T> = LiveStoreActionType<ObjectFilter<T> | undefined>;
export type LiveStoreLogLastWsConnAction<_T> = LiveStoreActionType<WebSocket | null>;
