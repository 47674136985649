import {Box, Typography} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers/index';
import SourcePartChips from './SourcePartChips';

function ReportSources() {
  const sources = useSelector((store: RootState) => store.similarityReportStore.currentSimilarityReport?.sources);

  return (
    <Box display="flex" alignItems="center" flexWrap="nowrap" maxWidth="100%" width="100%">
      <Typography variant="subtitle1" style={{marginRight: '8px', minWidth: '72px'}} noWrap>
        Sources:
      </Typography>
      <Box width="calc(100% - 80px)">
        <SourcePartChips sources={sources?.map((sourcePart) => `${sourcePart.name} / ${sourcePart.buildName}`)} />
      </Box>
    </Box>
  );
}

export default ReportSources;
