import {chooseImageForViewing, getSmallestImage, LayerImages} from './ViewportsPage';
import React, {useEffect, useReducer, useCallback, useState, useMemo} from 'react';
import {Stage} from 'react-konva';
import {isEqual} from 'lodash';

import {IBuild} from '@common/api/models/builds/IBuild';
import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';

import {ViewportDefect} from './DefectsPage';
import {defaultOverlayParams} from '../../../../components/molecules/Viewport/2D/analysisType';
import SingleImageViewport, {ImageOverlays} from '../../../../components/molecules/Viewport/2D/SingleImageViewport';
import {TransparencySlider} from '../../../../components/molecules/Viewport/2D/controls/TransparencySlider';
import {
  defaultPart,
  generateOverlayColourMaps,
  getCalibrationData,
  imageSizePixels,
  onPositionChange,
  shouldColourLsdd,
} from '../../../../components/molecules/Viewport/2D/utils';
import {
  defaultCmRange,
  defaultSevereDefectColours,
} from '../../../../components/molecules/Viewport/2D/MultiLayerViewport';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/reducers/index';
import LazyLayerImage from '../../../../components/atoms/LazyLayerImage';

interface DefectsViewportProps {
  build: IBuild;
  calibrationScale: number;
  height: number;
  isLoading: boolean;
  overlays: ImageOverlays[];
  defectData: ViewportDefect | undefined;
  isFitToWrapper?: boolean;
  loadedLayers: LayerImages;
  stopLoading: () => void;
  waitForStateChanges: boolean;
  setWaitForStateChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const DefectViewport = (props: DefectsViewportProps) => {
  const [stageWidth, setStageWidth] = useState(1000);
  // @ts-ignore
  const [stage, setStage] = useState<Stage | null>(null);

  const [forcedUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [analysisOverlayParams, setAnalysisOverlayParams] = useState(defaultOverlayParams);
  const [overlayParams, setOverlayParams] = useState<ImageOverlays[]>(props.overlays);
  const [lazyLayerImage, setLazyLayerImage] = useState<LazyLayerImage>();
  const [sdLoadedImages, setSdLoadedImage] = useState<{
    [layerNum: string]: {image: HTMLImageElement};
  }>({});

  const calibrationStore = useSelector((state: RootState) => state.calibrationStore);
  const {calibrationResolution} = useMemo(
    () =>
      !!props.build
        ? getCalibrationData(calibrationStore, props.build.calibrationUuid)
        : {plateBoundingBox: undefined, calibrationResolution: undefined},
    [calibrationStore, props.build]
  );

  // The type of defect the user is viewing
  const defectAnalysisType = props.defectData?.analysisType;
  // The analysis types to show. At this point, only the layer and the defect.
  const selectedAnalysisTypes = [AnalysisType2D.Layer, ...(defectAnalysisType ? [defectAnalysisType] : [])];

  const currentLayer = props.defectData?.layerNum ? props.defectData?.layerNum : 1;

  useEffect(() => {
    if (stage) {
      stage.getStage().batchDraw();
    }
  }, [stage]);

  useEffect(() => {
    props.setWaitForStateChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayer, props.defectData?.boundingBox]);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const stageOneFifth = stageWidth / 5;
    const imgStart = stageOneFifth;
    const imgEnd = stageOneFifth * 4;
    if ((e.nativeEvent.offsetX >= imgStart && e.nativeEvent.offsetX < imgEnd) || stageWidth < 600) {
      e.stopPropagation();
    }
  };

  const handleChangeDims = (width: number, _height: number) => {
    setStageWidth(width);
  };

  const chooseBestImages = async () => {
    const result: ImageOverlays[] = [];

    const loadedImages: {[key: string]: HTMLImageElement | undefined} = {};
    const partImages = props.loadedLayers[currentLayer]?.[defaultPart];

    await Promise.all(
      selectedAnalysisTypes.map((overlayKey) => {
        if (partImages[overlayKey]) {
          const overlayImages = partImages[overlayKey]?.images || [];

          const smallestGoodImage = getSmallestImage(partImages[overlayKey]?.images || []);
          // TODO: performance mode
          const bestLayerImage = chooseImageForViewing(overlayImages, false, false);
          const bestLoadedLayerImage = chooseImageForViewing(overlayImages, true, false);

          if (bestLayerImage !== bestLoadedLayerImage) {
            if (!bestLoadedLayerImage && overlayKey === AnalysisType2D.Layer) {
              // without any loaded images, we should load the smallest good image so it arrives first.
              if (smallestGoodImage) {
                smallestGoodImage.image.then((image) => {
                  // @ts-ignore
                  if (!(image as any)[props.viewportKey]) {
                    // @ts-ignore
                    (image as any)[props.viewportKey] = true;
                    image.addEventListener('load', () => {
                      forceUpdate();
                    });
                  }
                });
              }
            }

            // If the best image is not loaded, then load best other loaded image instead,
            // and wait for the actual best image to load.
            if (bestLayerImage) {
              bestLayerImage.image.then((image) => {
                // @ts-ignore
                if (!(image as any)[props.viewportKey]) {
                  // @ts-ignore
                  (image as any)[props.viewportKey] = true;
                  image.addEventListener('load', () => {
                    forceUpdate();
                  });
                }
              });
            }
          }

          return new Promise(async (res, _rej) => {
            if (bestLoadedLayerImage) {
              setLazyLayerImage(bestLoadedLayerImage);
              loadedImages[overlayKey] = await bestLoadedLayerImage.image;
              if (loadedImages[overlayKey] && loadedImages[overlayKey]!.width > 4000) {
                loadedImages[overlayKey]!.width = 4000;
                loadedImages[overlayKey]!.height = 4000;
              }
            }
            res(null);
          });
        }
        return null;
      })
    );

    /* For Coloring Severe Defect Layer */
    if (selectedAnalysisTypes.includes(AnalysisType2D.Lsdd) && shouldColourLsdd(partImages)) {
      if (sdLoadedImages?.[currentLayer]) {
        loadedImages['lsdd'] = sdLoadedImages[currentLayer].image;
      } else {
        if (loadedImages['lsdd']?.complete && loadedImages['lsdd']?.width > 400 && loadedImages['lsdd']?.height > 400) {
          loadedImages['lsdd'] = generateOverlayColourMaps(
            loadedImages['lsdd'] as HTMLImageElement,
            defaultCmRange,
            defaultSevereDefectColours,
            forceUpdate
          );
          setSdLoadedImage({
            ...sdLoadedImages,
            [currentLayer]: {
              image: loadedImages['lsdd'],
            },
          });
        }
      }
    }

    for (const overlayKey of Object.values(AnalysisType2D)) {
      if (loadedImages[overlayKey] && selectedAnalysisTypes.includes(overlayKey)) {
        const overlayParams = analysisOverlayParams[overlayKey];
        result.push({
          filters: overlayParams.filters,
          filterParams: overlayParams.filterParams,
          image: loadedImages[overlayKey],
          id: overlayKey,
        });
      }
    }

    // If it is the same, don't cause a render.
    if (isEqual(result, overlayParams)) {
      // is equal, so not updating
    } else {
      setOverlayParams(result);
    }
  };

  useEffect(() => {
    if (props.defectData && !props.waitForStateChanges) {
      chooseBestImages();
      if (stage) {
        stage.getStage().batchDraw();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisOverlayParams, forcedUpdate, currentLayer, selectedAnalysisTypes]);

  const [imageWidthPixels, imageHeightPixels]: number[] = imageSizePixels(calibrationResolution, lazyLayerImage, false);

  const onPositionChanged = useCallback(() => onPositionChange(stage), [stage]);

  return (
    <div onMouseDown={handleClick} style={{position: 'relative'}}>
      <SingleImageViewport
        isFitToWrapper={props.isFitToWrapper}
        isDefectViewport={true}
        isLoading={props.isLoading}
        overlays={overlayParams}
        onChangeDims={handleChangeDims}
        mmPerPixel={props.calibrationScale}
        imageWidthPixels={imageWidthPixels}
        imageHeightPixels={imageHeightPixels}
        height={props.height}
        stageRef={setStage}
        defectData={props.defectData}
        buildUuid={props.build.uuid}
        // getInitialPosition={() => {
        //   return {x: 100, y: 100, scale: 1, zoom: 1, viewportFit: true};
        // }}
        onPositionChange={onPositionChanged()}
        stopLoading={props.stopLoading}
        fadingChildren={
          defectAnalysisType && (
            <TransparencySlider
              analysisOverlayParams={analysisOverlayParams}
              setAnalysisOverlayParams={setAnalysisOverlayParams}
              analysisType={defectAnalysisType}
            />
          )
        }
      />
    </div>
  );
};

export default DefectViewport;
