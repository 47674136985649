import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {cloneDeep, isEqual} from 'lodash';
import {Typography} from '@material-ui/core';

import {RootState} from '../../../../store/reducers/index';
import {useAsyncDispatch} from '../../../../ReduxRoot';
import sensorProfileActions from '../../../../store/actions/sensorProfileActions';
import {sensorProfilesDELETE} from '../../../../api/ajax/sensorProfile';
import {GenericDialog} from '../../../../components/molecules/DialogButton';
import FormActionButtons from './FormActionButtons';

function FormToolbar({
  hasError,
  clearErrors,
  saving,
  setSaving,
  deviceOffline,
}: {
  hasError: boolean;
  clearErrors: () => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
  deviceOffline: boolean;
}) {
  const history = useHistory();
  const dispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();

  const profile = useSelector((state: RootState) => state.sensorProfileStore.byId[selectedUuid]);
  const profileJSON = useSelector((state: RootState) => state.sensorProfileStore.profiles[selectedUuid]);
  const defaultProfileJSON = useSelector(
    (state: RootState) => state.sensorProfileStore.defaultProfiles[profile?.deviceSerial]
  );
  const originalProfileJSON = useSelector(
    (state: RootState) => state.sensorProfileStore.originalProfiles[selectedUuid]
  );
  const profileDevice = useSelector((state: RootState) => state.deviceStore.byId[profile.deviceSerial]);

  const [deleting, setDeleting] = useState<boolean>(false);

  const [warnActiveBuildOpen, setWarnActiveBuildOpen] = useState(false);

  async function onSave(warnConfirmed: boolean) {
    setSaving(true);
    if (selectedUuid === 'new') {
      const uuid = await dispatch(sensorProfileActions.createProfile(profile.name, profile.deviceSerial, profileJSON!));

      setSaving(false);
      if (uuid) history.replace(`/sensorProfiles/${uuid}`);
    } else {
      if (!warnConfirmed && !!profileDevice?.buildUuid) {
        setWarnActiveBuildOpen(true);
        return;
      }

      await dispatch(sensorProfileActions.saveProfile(selectedUuid, profileJSON!));
    }

    setSaving(false);
  }

  function onCancel() {
    if (selectedUuid === 'new') {
      dispatch(sensorProfileActions.revertNewProfile());
      history.replace('/sensorProfiles');
    } else {
      dispatch(sensorProfileActions.setSensorProfile(selectedUuid, cloneDeep(originalProfileJSON)));
    }

    clearErrors();
  }

  function onResetDefaults() {
    dispatch(sensorProfileActions.setSensorProfile(selectedUuid, cloneDeep(defaultProfileJSON)));

    clearErrors();
  }

  async function onDelete() {
    setDeleting(true);
    await sensorProfilesDELETE(selectedUuid);
    setDeleting(false);
    history.replace('/sensorProfiles/');
  }

  const hasUnsavedChanges = !isEqual(originalProfileJSON, profileJSON);

  return (
    <>
      <FormActionButtons
        isNewProfile={selectedUuid === 'new'}
        profile={profile}
        saving={saving}
        onSave={() => onSave(false)}
        onCancel={onCancel}
        onResetDefaults={onResetDefaults}
        onDelete={onDelete}
        hasUnsavedChanges={hasUnsavedChanges}
        hasError={hasError}
        deleting={deleting}
        deviceOffline={deviceOffline}
      />
      <GenericDialog
        isOpen={warnActiveBuildOpen}
        title="Active build using this profiles device"
        content={
          <>
            <Typography>
              There is an active build using this profiles device. Changes made here won't affect the active build.
            </Typography>
            <br />
            <Typography>To modify the profile on the active build, please head to the build settings page.</Typography>
          </>
        }
        closeDialog={() => {
          setWarnActiveBuildOpen(false);
          setSaving(false);
        }}
        closeText="Cancel"
        confirmText="Save profile"
        onSuccess={() => {
          onSave(true);
          setWarnActiveBuildOpen(false);
        }}
      />
    </>
  );
}

export default FormToolbar;
