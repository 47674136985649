import React from 'react';
import styled, {createGlobalStyle, ThemeProps} from 'styled-components';

import {CssBaseline} from '@material-ui/core';

const GlobalStyle = createGlobalStyle<ThemeProps<{body: any}>>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  position: relative;
`;

const BackgroundVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  objectfit: cover;
  z-index: -100;
`;

function Auth({children}: any) {
  return (
    <React.Fragment>
      <BackgroundVideo muted loop autoPlay>
        <source src="https://public.cdn.addassure.com/am_intro.mp4" />
      </BackgroundVideo>
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </Root>
    </React.Fragment>
  );
}

export default Auth;
