import {Tooltip, TooltipProps, Typography} from '@material-ui/core';
import React from 'react';

interface ITypographyProps {
  hideTooltip: boolean;
  tooltip: string | JSX.Element;
  children: React.ReactElement;
  placement?: TooltipProps['placement'];
  spanStyle?: React.CSSProperties;
}

export default function ConditionalTooltip(props: ITypographyProps) {
  const {children, hideTooltip, tooltip, placement, spanStyle = {}} = props;

  if (hideTooltip) return <>{children}</>;

  return (
    <Tooltip placement={placement} arrow title={<Typography>{tooltip || ''}</Typography>}>
      <span style={spanStyle}>{children}</span>
    </Tooltip>
  );
}
