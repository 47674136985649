import React, {useReducer, useState} from 'react';
import {Button as MuiButton, ButtonGroup, TextField} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';

import {makeAllTimeFilter, makeLastXDaysFilter, TimeFilter} from '@common/utils/timeFilter';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const Button = styled(MuiButton)(spacing);

export interface ActionsProps {
  timeFilter: TimeFilter;
  setTimeFilter: (filter: TimeFilter) => any;
}

export default function DashboardTimeFilter(props: ActionsProps) {
  const isSmallScreen = useSmallScreenSize();
  const [selected, setSelected] = useState<number>(30);
  const [customTime, setCustomTime] = useState<number | ''>('');
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [forcedRender, forceRender] = useReducer((x) => x + 1, 0);

  const last7DaysClick = () => {
    props.setTimeFilter(makeLastXDaysFilter(7));
    setSelected(7);
  };

  const last30DaysClick = () => {
    props.setTimeFilter(makeLastXDaysFilter(30));
    setSelected(30);
  };

  const last90DaysClick = () => {
    props.setTimeFilter(makeLastXDaysFilter(90));
    setSelected(90);
  };

  const oneYearClick = () => {
    props.setTimeFilter(makeLastXDaysFilter(365));
    setSelected(365);
  };

  const onCustomTimeFilter = () => {
    if (!customTime) return;

    props.setTimeFilter(makeLastXDaysFilter(customTime));
    setSelected(customTime);
    setCustomModalOpen(false);
    // Hack to clear the focus on the custom button as it hangs around after onEnter and has the MUI ripple effect
    forceRender();
  };

  const allTimeClick = () => {
    props.setTimeFilter(makeAllTimeFilter());
    setSelected(0);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        key={forcedRender}
        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
        style={isSmallScreen ? {width: '100%'} : {}}
      >
        <Button
          variant={selected === 7 ? 'contained' : 'outlined'}
          color="primary"
          onClick={last7DaysClick}
          fullWidth={isSmallScreen}
        >
          7 days
        </Button>
        <Button
          variant={selected === 30 ? 'contained' : 'outlined'}
          color="primary"
          onClick={last30DaysClick}
          fullWidth={isSmallScreen}
        >
          30 days
        </Button>
        <Button
          variant={selected === 90 ? 'contained' : 'outlined'}
          color="primary"
          onClick={last90DaysClick}
          fullWidth={isSmallScreen}
        >
          90 days
        </Button>

        <Button
          variant={selected === 365 ? 'contained' : 'outlined'}
          color="primary"
          onClick={oneYearClick}
          fullWidth={isSmallScreen}
        >
          1 Year
        </Button>
        <Button
          variant={selected === 0 ? 'contained' : 'outlined'}
          color="primary"
          onClick={allTimeClick}
          fullWidth={isSmallScreen}
        >
          All Time
        </Button>
        <Button
          variant={[0, 7, 30, 90, 365].includes(selected) ? 'outlined' : 'contained'}
          color="primary"
          onClick={() => setCustomModalOpen(true)}
        >
          Custom
        </Button>
      </ButtonGroup>
      <GenericDialog
        title="Custom time filter"
        maxWidth="xs"
        content={
          <TextField
            autoFocus
            fullWidth
            size="small"
            name="custom-time-filter"
            label="Days ago"
            placeholder="Days ago"
            variant="outlined"
            InputLabelProps={{shrink: true}}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                onCustomTimeFilter();
              }
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (!value) {
                setCustomTime('');
                return;
              }
              if (!value.match(/^([1-9]\d*)$/)) return;
              setCustomTime(parseInt(value));
            }}
            value={customTime}
          />
        }
        isOpen={!!customModalOpen}
        closeDialog={() => setCustomModalOpen(false)}
        onSuccess={onCustomTimeFilter}
        confirmText="Create"
        confirmDisabled={!customTime}
      />
    </React.Fragment>
  );
}
