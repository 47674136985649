import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {CallSplit, InfoOutlined, MergeType, NewReleasesOutlined} from '@material-ui/icons';
import {assertUnreachable} from '@common/utils/utils';
import {TimelineGraphNode, TimelineGraphTableRow, TimeMode} from './types';
import {batchesToHistoryEvents} from './generateHistoryNodes';
import {LiveStoreState} from '../../../store/model/liveUpdateStore';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IMaterial} from '@common/api/models/materials/IMaterial';

export function batchesToFutureGraphTimelineRows(
  batchHistory: IBatch[],
  materialStore: LiveStoreState<IMaterial>,
  batchStore: LiveStoreState<IBatch>,
  buildStore: LiveStoreState<IBuild>
) {
  // Whatever. The data will sort itself out. Let's do the git graph though.
  const exampleEvents = batchesToHistoryEvents(batchHistory, materialStore, batchStore, buildStore).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const futureTableRowData: TimelineGraphTableRow[] = [];

  const onCol = new Map<number, string>();
  const colOf = new Map<string, number>();

  let maxNodesAcross = 0;

  function nextEmptyColumn() {
    let i = 0;
    while (onCol.has(i)) {
      i++;
    }
    maxNodesAcross = Math.max(maxNodesAcross, i + 1);
    return i;
  }

  let first = true;
  // Remember that we are going FORWARDS in time.
  for (const e of exampleEvents) {
    let layers: TimelineGraphNode[][] = [];
    let edges: string[][] = [];

    switch (e.type) {
      case 'merge': {
        // |
        // |\
        // | |

        const loc1 = colOf.get(e.fromA);
        const loc2 = colOf.get(e.fromB);
        if (loc1 !== undefined) {
          onCol.delete(loc1);
        }
        if (loc2 !== undefined) {
          onCol.delete(loc2);
        }
        colOf.delete(e.fromA);
        colOf.delete(e.fromB);

        const newLoc = loc1 === undefined ? loc2! : loc2 === undefined ? loc1 : Math.min(loc1, loc2);

        colOf.set(e.into.uuid, newLoc);
        onCol.set(newLoc, e.into.uuid);

        layers.push(
          [
            {
              index: newLoc,
              text: e.message,
              batch: e.into,
              date: e.date,
              icon: MergeType,
            },
          ]
          //[{index: curCol, text: '', batchUuid: e.fromA}, {index: newLoc, text: '', batchUuid: e.fromB}],
        );
        edges.push([e.fromA + '->' + e.into.uuid, e.fromB + '->' + e.into.uuid]);
        break;
      }
      case 'new':
        // Us
        //  |
        // Prev
        const newCol = nextEmptyColumn();
        colOf.set(e.batch.uuid, newCol);
        onCol.set(newCol, e.batch.uuid);

        layers.push([
          {
            index: newCol,
            text: e.message,
            batch: e.batch,
            date: e.date,
            icon: NewReleasesOutlined,
          },
        ]);
        edges.push([]);
        break;
      case 'split': {
        // | |
        // |/
        // |
        const newLoc = nextEmptyColumn();
        const curCol = colOf.get(e.old)!;
        onCol.set(newLoc, e.newUuids[1]);
        colOf.set(e.newUuids[1], newLoc);
        onCol.set(curCol, e.newUuids[0]);
        colOf.set(e.newUuids[0], curCol);
        colOf.delete(e.old);

        const firstLayer: TimelineGraphNode[] = [];
        const firstEdges: string[] = [];
        firstLayer.push({
          text: e.message,
          index: newLoc,
          batch: batchStore.byId[e.newUuids[1]],
          date: e.date,
          icon: CallSplit,
        });
        firstEdges.push(e.old + '->' + e.newUuids[0]);
        firstLayer.push({
          text: e.message,
          index: curCol,
          batch: batchStore.byId[e.newUuids[0]],
          date: e.date,
          icon: CallSplit,
        });
        firstEdges.push(e.old + '->' + e.newUuids[1]);
        layers.push(firstLayer);
        edges.push(firstEdges);

        break;
      }
      case 'stop':
      case 'action': {
        let loc = colOf.get(e.target);
        if (loc === undefined) {
          console.log('target undefined: ' + e.target);
          loc = nextEmptyColumn();
        }
        colOf.delete(e.target);
        colOf.set(e.new.uuid, loc);
        onCol.set(loc, e.new.uuid);
        layers.push([
          {
            text: e.message,
            index: loc,
            batch: e.new,
            date: e.date,
            icon: InfoOutlined,
          },
        ]);
        edges.push([e.target + '->' + e.new.uuid]);
        break;
      }
      default:
        assertUnreachable(e);
    }

    for (let i = 0; i < layers.length; i++) {
      futureTableRowData.push({
        nodes: layers[i],
        edges: edges[i],
        timeMode: first ? TimeMode.PRESENT : TimeMode.FUTURE,
      });
    }

    first = false;
  }

  return {
    futureTableRowData,
    maxNodesAcross,
  };
}
