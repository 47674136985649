import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import styled, {css} from 'styled-components';
import {Delete} from '@material-ui/icons';
import {
  ComparisonWeightsType,
  ISimilarityComparisonGETResponse,
  SimilarityStatus,
} from '@common/api/models/builds/data/ISimilarity';
import GenericTable from '../../../components/molecules/Table/GenericTable';
import {RootState} from '../../../store/reducers/index';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import AddMoreTargetPartsButton from './AddMoreTargetPartsButton';
import {useAsyncDispatch} from '../../../ReduxRoot';
import similarityComparisonActions from '../../../store/actions/similarityComparisonActions';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {useContainerHeight} from '../../../utils/utilHooks';
import ComparisonWeights from './ComparisonWeights';

const defaultFilters = {sortBy: {similarityScore: 'DESC'}} as any;

// Forced the table to refresh if some comparisons have completed
const useForcedRefresh: () => number = () => {
  const [forcedRefresh, forceRefresh] = useReducer((x) => x + 1, 0);
  const [previousWeights, setPreviousWeights] = useState<ComparisonWeightsType | undefined>();

  const comparisonProgress = useSelector((state: RootState) => state.similarityReportStore.comparisonProgress);
  const lastComparisonProgress = useSelector((state: RootState) => state.similarityReportStore.lastComparisonProgress);
  const comparisonWeights = useSelector(
    (state: RootState) => state.similarityReportStore.currentSimilarityReport?.comparisonWeights
  );

  useEffect(() => {
    if (!!comparisonProgress && !!lastComparisonProgress) {
      if (!isEqual(comparisonProgress, lastComparisonProgress)) {
        forceRefresh();
      }
    }
  }, [lastComparisonProgress, comparisonProgress]);

  useEffect(() => {
    if (!!previousWeights && !isEqual(previousWeights, comparisonWeights)) {
      forceRefresh();
    }
    setPreviousWeights(comparisonWeights);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonWeights]);

  return forcedRefresh;
};

const ComparisonsTable = () => {
  const isSmallScreen = useSmallScreenSize();
  const forcedRefresh = useForcedRefresh();
  const {height, setContainerRef} = useContainerHeight();
  const {setCurrentlyViewingComparison} = useSimilarityReportStoreActions();
  const [comparisonDeleting, setComparisonDeleting] = useState<ISimilarityComparisonGETResponse | undefined>();
  const currentlyViewingComparison = useSelector(
    (state: RootState) => state.similarityReportStore.currentlyViewingComparison
  );
  const currentReport = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport);
  const comparisons = useSelector((state: RootState) => state.similarityComparisonStore.list);

  const onRowClick = (_event: any, row: any) => {
    if (row.status !== SimilarityStatus.Success) return;

    setCurrentlyViewingComparison(row);
  };

  const useComparisons = useCallback(() => {
    return comparisons.map((comparison) => ({
      ...comparison,
      onDelete: () => setComparisonDeleting(comparison),
      reportUuid: currentReport?.uuid,
    }));
  }, [comparisons, setComparisonDeleting, currentReport]);

  return (
    <AbsoluteWrapper ref={setContainerRef}>
      <TableContainer isSmallScreen={isSmallScreen}>
        <GenericTable
          filteringEnabled
          defaultFilters={defaultFilters}
          tableHeader={<TableHeader />}
          resourceType="similarityComparison"
          useData={useComparisons}
          onRowClick={onRowClick}
          forcedRefresh={forcedRefresh}
          tableOptions={{
            rowStyle: (rowData: any) => ({
              backgroundColor: rowData?.uuid === currentlyViewingComparison?.uuid ? '#F5FaFF' : '',
              ...(rowData.status === SimilarityStatus.Success ? {} : {cursor: 'default', pointerEvents: 'none'}),
            }),
            maxBodyHeight: height && !isSmallScreen ? `calc(${height - 150}px)` : undefined,
          }}
        />
      </TableContainer>
      <DeleteModal comparisonDeleting={comparisonDeleting} setComparisonDeleting={setComparisonDeleting} />
    </AbsoluteWrapper>
  );
};

export default ComparisonsTable;

const TableHeader = () => {
  const isSmallScreen = useSmallScreenSize();
  const uuid = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport!.uuid);

  return (
    <Grid item xs={12} style={{marginBottom: '12px'}}>
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Typography variant={isSmallScreen ? 'h6' : 'h4'} style={{marginRight: '12px'}}>
          Part - Similarity
        </Typography>
        <Box display="flex">
          {!isSmallScreen && <ComparisonWeights uuid={uuid} />}
          <div style={{width: '12px'}} />
          {!isSmallScreen && <AddMoreTargetPartsButton uuid={uuid} />}
        </Box>
      </Box>
    </Grid>
  );
};

const DeleteModal = ({
  comparisonDeleting,
  setComparisonDeleting,
}: {
  comparisonDeleting: ISimilarityComparisonGETResponse | undefined;
  setComparisonDeleting: React.Dispatch<React.SetStateAction<ISimilarityComparisonGETResponse | undefined>>;
}) => {
  const dispatch = useAsyncDispatch();
  const [deleting, setDeleting] = useState(false);
  const reportUuid = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport?.uuid);

  const onDelete = async () => {
    if (!comparisonDeleting) return;

    setDeleting(true);
    const success = await dispatch(
      similarityComparisonActions.deleteSimilarityComparison(reportUuid!, comparisonDeleting)
    );
    setDeleting(false);

    if (success) {
      setComparisonDeleting(undefined);
    }
  };

  return (
    <GenericDialog
      title="Delete Comparison?"
      content={
        <>
          Are you sure you want to delete the similarity comparison for{' '}
          <b>
            <i>{comparisonDeleting?.targetPartName}</i>
          </b>
          ?
        </>
      }
      isOpen={!!comparisonDeleting}
      closeDialog={() => setComparisonDeleting(undefined)}
      onSuccess={onDelete}
      requestInProgress={deleting}
      confirmButtonProps={{endIcon: <Delete />}}
    />
  );
};

const AbsoluteWrapper = styled.div`
  height: 100%;
  position: relative;
  flex: 1;
`;

const TableContainer = styled.div<{isSmallScreen: boolean}>`
  ${({isSmallScreen}) =>
    isSmallScreen
      ? ''
      : css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          padding-bottom: 0;
          right: 0;
          overflow: auto;
          .MuiPaper-root {
            height: auto;
          }
          .generic-table-container {
            height: 100%;
            overflow: hidden;
          }
        `}
`;
