import React from 'react';
import {FiltersState} from '../../../pages/shared/IndexPage/IndexPage';
import {FilterHeader} from '../../shared/IndexPage/FilterHeader';

const PartsTreeViewHeader = ({
  filters,
  setFilters,
}: {
  filters: FiltersState;
  setFilters: (filters: FiltersState) => void;
}) => {
  return (
    <FilterHeader
      filters={filters}
      setFilters={setFilters}
      filteringEnabled
      resourceType="part"
      tableTitle=" Parts by Build"
      headerFlexDirection="row"
    />
  );
};

export default PartsTreeViewHeader;
