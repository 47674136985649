import * as dotenv from 'dotenv';
dotenv.config();

export const shortDateFormat = 'YYYY-MM-DD';
export const shortDateTimeFormat = `${shortDateFormat}, HH:mm:ss`;
export const shortNoSecondsDateTimeFormat = `${shortDateFormat}, HH:mm`;

export const descriptiveDateFormat = 'dddd, D MMMM YYYY';
export const descriptiveDateTimeFormat = `${descriptiveDateFormat}, h:mm:ss a`;
export const descriptiveNoSecondsDateTimeFormat = `${descriptiveDateFormat}, h:mm a`;
