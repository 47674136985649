import React, {useEffect, useState} from 'react';
import {Undo} from '@material-ui/icons';
import {toast} from 'react-toastify';

import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {DialogButton} from '../../../../components/molecules/DialogButton';
import {buildStopGET} from '../../../../api/ajax/builds';
import {useBuildStateTransition} from '../../shared/useBuildStateTransition';
import {BUILD_SHORT_TRANSITION_TIMEOUT} from '../index';

interface BackToStagingButtonProps {
  build: IBuild;
  fullWidthButton?: boolean;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

export function BackToStagingButton({build, fullWidthButton}: BackToStagingButtonProps) {
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    return () => clearTimeout(timeout!);
  }, []);

  useBuildStateTransition(() => {
    clearTimeout(timeout!);
    setRequesting(false);
  }, [build, [BuildState.STAGING]]);

  const handleSendBackToStagingConfirm = async () => {
    setRequesting(true);
    const res = await buildStopGET(build.uuid);
    if (!res.success) {
      setRequesting(false);
      return;
    }

    timeout = setTimeout(() => {
      setRequesting(false);
      toast('Reverting build to staging timed out', {type: 'error'});
    }, BUILD_SHORT_TRANSITION_TIMEOUT);
  };

  return (
    <React.Fragment>
      <DialogButton
        text="Revert to Staging"
        icon={<Undo />}
        dialog={{
          title: 'Send back to staging',
          content: `Are you sure you want to send this build back to staging? From there,
          you can start monitoring again, or send it back to draft.`,
          confirmText: 'Confirm and Revert',
        }}
        handleConfirm={handleSendBackToStagingConfirm}
        fullWidth={fullWidthButton}
        loading={requesting}
      />
    </React.Fragment>
  );
}
