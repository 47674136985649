import React, {useRef} from 'react';
import {Tooltip, IconButton, Typography} from '@material-ui/core';
import {InfoOutlined} from '@material-ui/icons';
import styled, {keyframes} from 'styled-components';
import {renderDateTimeString} from '../../utils/string';
import {dangerColor} from '../../assets/jss/material-dashboard-react';

const fadeAway = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
    }
`;

const CustomIconButton = styled(IconButton)`
  &.fade {
    animation: ${fadeAway} 0.2s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }
`;

export interface InfoButtonProps {
  timestamp: Date;
  width?: number;
  height?: number;
  cssTop: number;
  cssRight: number;
  mouseOn: boolean;
  isPartialLayer: boolean;
}

export default function InfoButton(props: InfoButtonProps) {
  const containerRef = useRef(null);

  return (
    <Tooltip
      placement="left"
      enterTouchDelay={0}
      PopperProps={{style: {zIndex: 10000}, container: containerRef.current}}
      title={
        <div style={{width: '184px'}}>
          <Typography style={{margin: '2px 8px 8px 8px'}}>
            {renderDateTimeString('descriptive', props.timestamp, false)}
          </Typography>

          {props.width && props.height && (
            <Typography style={{margin: '0', textAlign: 'center'}}>
              {props.width} x {props.height}
            </Typography>
          )}

          {props.isPartialLayer && (
            <Typography style={{margin: '8px 8px 8px 2px', textAlign: 'center', color: dangerColor[3]}}>
              <b>Partial Layer</b>
            </Typography>
          )}
        </div>
      }
    >
      <CustomIconButton
        className={(!props.mouseOn && 'fade') || undefined}
        style={{
          right: props.cssRight,
          top: props.cssTop,
          position: 'absolute',
          color: props.isPartialLayer ? dangerColor[0] : '#fff',
          paddingRight: 0,
        }}
        ref={containerRef}
      >
        <InfoOutlined />
      </CustomIconButton>
    </Tooltip>
  );
}
