export enum BuildDataArchiveStatus {
  Submitted = 'submitted',
  Generating = 'generating',
  Success = 'success',
  Failure = 'failure',
}

export interface IBuildDataArchive {
  uuid: string;
  buildUuid: string;
  archivePath?: string;
  createdAt: Date;
  createdByUuid: string;
  sendEmail: boolean;
  status: BuildDataArchiveStatus;
}

export interface BuildDataArchiveFile {
  filename: string;
  size: number;
}
export interface IBuildDataArchiveGETResponse {
  status: BuildDataArchiveStatus;
  files: Array<BuildDataArchiveFile>;
  createdAt: Date;
}
export interface IBuildDataArchiveUrlResponse {
  filename: string;
  signedUrl: string;
}
