import React, {useState} from 'react';
import filesize from 'filesize';
import {Delete, CloudDownload, Warning} from '@material-ui/icons';
import {Box, CircularProgress, IconButton, Tooltip, Typography} from '@material-ui/core';
import {ISliceAttachmentGETResponse, SliceState} from '@common/api/models/attachments/ISliceAttachment';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import {warningColor} from '../../../../assets/jss/material-dashboard-react';
import StateSymbol from '../../../../components/atoms/Status/SliceAttachmentStatus';
import {sliceAttachmentDownloadUrlGET} from '../../../../api/ajax/sliceAttachments';
import {downloadUrl} from '../../../../utils/webtools';
import {humanize} from 'inflection';

export interface ISliceAttachmentRow extends ISliceAttachmentGETResponse {
  onRowDelete: () => void;
  disableDelete: boolean;
}

const sliceAttachmentTableSchema = [
  {
    title: 'Name',
    field: 'filename',
    width: 'auto',
    render: (row: ISliceAttachmentRow) =>
      row.filename && <EllipsisTextColumnTooltip>{row.filename}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'State',
    align: 'left',
    field: 'state',
    width: '148px',
    render: (row: ISliceAttachmentRow) => <StateSymbol sliceAttachment={row} />,
  },
  {
    title: 'Size',
    align: 'left',
    field: 'size',
    sortField: 'filesize',
    type: 'numeric',
    width: '124px',
    render: (row: ISliceAttachmentRow) => row.filesize && filesize(row.filesize),
  },
  {
    title: 'Scan strategy',
    align: 'left',
    field: 'scanStrategy',
    sortField: 'scanStrategy',
    width: '124px',
    render: (row: ISliceAttachmentRow) => humanize(row.scanStrategy),
  },
  {
    title: 'Date Added',
    field: 'createdAt',
    align: 'left',
    type: 'date',
    width: '128px',
    emptyValue: '',
    render: (row: ISliceAttachmentRow) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Actions',
    width: '165px',
    sorting: false,
    render: (rowData: ISliceAttachmentRow) => <SliceAttachmentActions rowData={rowData} />,
  },
];

export default sliceAttachmentTableSchema;

const SliceAttachmentActions = ({rowData}: {rowData: ISliceAttachmentRow}) => {
  const [downloading, setDownloading] = useState(false);
  const isDeletedSlice = !!rowData.deletedAt;
  const disableDelete = rowData.disableDelete;

  const onDownload = async () => {
    setDownloading(true);
    const url = await sliceAttachmentDownloadUrlGET(rowData.uuid);
    if (url.success) downloadUrl(url.data, rowData.filename);
    setDownloading(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      {rowData.partsCount <= 0 && rowData.state === SliceState.VALID && (
        <Tooltip title={<Typography>Unable to infer parts from slice file</Typography>}>
          <Warning style={{color: warningColor[2], marginRight: '6px'}} />
        </Tooltip>
      )}
      <Tooltip title={<Typography>{isDeletedSlice ? 'File Deleted' : 'Download'}</Typography>}>
        <span>
          <IconButton aria-label="download" onClick={onDownload} disabled={isDeletedSlice} style={{padding: '0px 6px'}}>
            {downloading ? <CircularProgress size={20} /> : <CloudDownload />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip
        title={
          <Typography>
            {isDeletedSlice ? 'File Deleted' : disableDelete ? "Can't delete slice while build is active" : 'Remove'}
          </Typography>
        }
      >
        <span>
          <IconButton
            aria-label="delete"
            onClick={rowData.onRowDelete}
            disabled={isDeletedSlice || disableDelete}
            style={{padding: '0px 6px'}}
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};
