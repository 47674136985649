import {initialSupportState, FeatureFlagAction, SupportActions, SupportStoreState} from '../model/support';
import createReducer from './createReducer';

export const support = createReducer<SupportStoreState>(initialSupportState, {
  [SupportActions.SET_FEATURE_FLAGS](state: SupportStoreState, action: FeatureFlagAction) {
    return {
      ...state,
      featureFlags: action.payload,
    };
  },
});
