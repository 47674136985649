import React from 'react';
import {Checkbox, FormControlLabel, Grid} from '@material-ui/core';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {ViewportParams} from '../Base3DViewport';

export default function HelperOptions<T extends ViewportParams>(props: ViewportSidebarProps<T>) {
  return (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.params.showAxes}
              onChange={(event) =>
                props.setParams({
                  ...props.params,
                  showAxes: event.target.checked,
                })
              }
              name="showAxes"
            />
          }
          label="Show axes"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.params.showGrid}
              onChange={(event) =>
                props.setParams({
                  ...props.params,
                  showGrid: event.target.checked,
                })
              }
              name="showGrid"
            />
          }
          label="Show grid"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.params.showClippingPlane}
              onChange={(event) =>
                props.setParams({
                  ...props.params,
                  showClippingPlane: event.target.checked,
                })
              }
              name="showClippingPlane"
            />
          }
          label="Show clipping plane"
        />
      </Grid>
    </>
  );
}
