import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {useEffect, useState} from 'react';

export function useBuildStateTransition(onTransitionTo: () => void, [build, transitionTo]: [IBuild, BuildState[]]) {
  const [buildStateWas, setBuildStateWas] = useState<BuildState>(build.state);

  useEffect(() => {
    if (transitionTo.includes(build.state) && build.state !== buildStateWas) {
      onTransitionTo();
    }
    setBuildStateWas(build.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.state]);
}
