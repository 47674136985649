import React from 'react';
import {Tooltip, Grid, IconButton, Box, Typography} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {Skeleton} from '@material-ui/lab';

export interface ResourcePermissionRow {
  name: string;
  isProvisionerPermission: boolean;
  onDelete: () => void | undefined;
}

const resourcePermissionTableSchema = [
  {
    title: 'Name',
    field: 'user',
    width: 'auto',
    sorting: false,
    render: (row: ResourcePermissionRow) => {
      if (!row.name) return <Skeleton />;
      return (
        <Grid style={{width: '100%'}}>
          <EllipsisTextWithTooltip>{row.name}</EllipsisTextWithTooltip>
        </Grid>
      );
    },
  },
  {
    title: 'Actions',
    sorting: false,
    width: '82px',
    render: (row: ResourcePermissionRow) => {
      return (
        <Box display="flex" justifyContent="center">
          <Tooltip
            title={
              <Typography>
                {row.isProvisionerPermission
                  ? 'Cant revoke the build provisioners permission, please change provisioner first.'
                  : !!row.onDelete
                  ? 'Revoke'
                  : 'Insufficient permissions to delete permissions'}
              </Typography>
            }
          >
            <span>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  row.onDelete();
                }}
                disabled={!row.onDelete || row.isProvisionerPermission}
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      );
    },
  },
];

export default resourcePermissionTableSchema;
