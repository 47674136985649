import React, {useEffect, useState} from 'react';
import {Grid, Box, Card as MuiCard, CardContent} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {useHistory, useParams} from 'react-router-dom';
import PartSelectorTreeView from './shared/PartSelectorTreeView';
import SimilarityCreateHeader from './create/SimilarityCreateHeader';
import SelectedParts from './create/SelectedParts';
import NavigationButtons from './create/NavigationButtons';
import SelectedPart3DViewport from './shared/SelectedPart3DViewport';
import SimilarityPartInfo from './shared/SimilarityPartInfo';
import {useSimilarityReportStoreActions} from '../../store/actions/index';
import similarityReportActions from '../../store/actions/similarityReportActions';
import {partsAllGET} from '../../api/ajax/parts';
import {useSmallScreenSize} from '../../utils/utilHooks';
import SimilarityCreateBottomToolbar from './create/SimilarityCreateBottomToolbar';
import {VIEWPORT_FULLSCREEN_STYLE} from './shared/fullScreenViewportStyle';
import ComparisonWeights from './create/ComparisonWeights';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/reducers/index';
import {RotationMap} from '@common/api/models/builds/data/ISimilarity';

const Card = styled(MuiCard)(spacing);

export enum SimilarityStep {
  SOURCE = 'sourcePartSelection',
  TARGET = 'targetPartSelection',
}

export function generateSimilarityQueryString(sourcePartUuids: string[], rotations: RotationMap) {
  const sourceUuidsUrl = (sourcePartUuids || []).map((uuid) => uuid).join('&');
  const sourceRotationsUrl = (sourcePartUuids || []).map((uuid) => `${rotations[uuid]}`).join('&');
  const queryString = [sourceUuidsUrl, sourceRotationsUrl].filter((url) => !!url).join('/');

  return queryString;
}

async function loadAndSetSourceParts(
  sourcePartUuids: string[],
  rotations: string[],
  setDraftSimilarityReport: typeof similarityReportActions.setDraftSimilarityReport
) {
  const partRes = await partsAllGET({
    uuid: {
      in: sourcePartUuids,
    },
  });
  if (partRes.success && partRes.data) {
    const sortedParts = sourcePartUuids.map((uuid) => partRes.data.find((part) => part.uuid === uuid)!);
    const part = sortedParts[0];
    setDraftSimilarityReport('sourceParts', sortedParts);
    setDraftSimilarityReport('backupName', `${part.buildName} - ${part.name}`);
    if (rotations.length) {
      const rotationsMap: RotationMap = {};
      sourcePartUuids.forEach((uuid, index) => {
        rotationsMap[uuid] = parseInt(rotations[index]);
      });
      setDraftSimilarityReport('sourcePartRotations', rotationsMap);
    }
  }
}

function alertUser(e: any) {
  e.preventDefault();
  e.returnValue = '';
}

const SimilarityCreatePage = () => {
  const {
    step: urlStep,
    sourcePartUuids,
    rotations,
  } = useParams<{
    step?: SimilarityStep;
    sourcePartUuids?: string;
    rotations?: string;
  }>();
  const step = urlStep as SimilarityStep;
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();
  const [isViewportFullScreen, setIsViewportFullscreen] = useState(false);
  const {setDraftSimilarityReport, clearDraftSimilarityReport} = useSimilarityReportStoreActions();

  useEffect(() => {
    if (![SimilarityStep.SOURCE, SimilarityStep.TARGET].includes(step || '')) {
      history.replace('/reports/similarity/create/sourcePartSelection');
    } else if (step === 'targetPartSelection') {
      if (!sourcePartUuids) {
        history.replace('/reports/similarity/create/sourcePartSelection');
      }
    }
    if (!!sourcePartUuids) {
      const partUuids = sourcePartUuids.split('&');
      const sourceRotations = rotations?.split('&') || [];
      loadAndSetSourceParts(partUuids, sourceRotations, setDraftSimilarityReport);
    }

    return () => {
      clearDraftSimilarityReport();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sourcePartUuids?.length) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [sourcePartUuids]);

  return (
    <>
      <SimilarityCreateHeader />
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100% - 56px)"
        style={isViewportFullScreen ? {overflow: 'hidden', maxHeight: '0px'} : {}}
      >
        {isSmallScreen && <ComparisonWeights />}
        <PartSelectionStep type={step} isViewportFullScreen={isViewportFullScreen} />
        {isSmallScreen && (
          <SimilarityCreateBottomToolbar
            toggleViewportFullscreen={() => {
              window.scrollTo(0, 0);
              setIsViewportFullscreen(!isViewportFullScreen);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default SimilarityCreatePage;

const PartSelectionStep = ({isViewportFullScreen, type}: {isViewportFullScreen: boolean; type: SimilarityStep}) => {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const draftReport = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport);
  const {setDraftSimilarityReport} = useSimilarityReportStoreActions();
  const rotations = type === SimilarityStep.SOURCE ? draftReport.sourcePartRotations : draftReport.targetPartRotations;

  const sourcePartUuids = draftReport.sourceParts.map((part) => part.uuid);

  const currentlyViewingIsSelected = useSelector((state: RootState) =>
    (type === SimilarityStep.SOURCE ? draftReport.sourceParts : draftReport.targetParts)
      .map((part) => part.uuid)
      .includes(state.similarityReportStore.currentlyViewingPart?.uuid as any)
  );

  function onRotationChanged(partUuid: string, rotation: number) {
    const newRotations = {
      ...rotations,
      [partUuid]: rotation,
    };
    setDraftSimilarityReport(
      type === SimilarityStep.SOURCE ? 'sourcePartRotations' : 'targetPartRotations',
      newRotations
    );
    if (type === SimilarityStep.SOURCE) {
      const queryString = generateSimilarityQueryString(sourcePartUuids, newRotations);

      history.replace(`/reports/similarity/create/${SimilarityStep.SOURCE}/${queryString}`);
    }
  }
  return (
    <>
      <SelectedParts type={type} />

      <StyledGrid container spacing={isSmallScreen ? 2 : 4}>
        <Grid item xs={12} md={6} style={{minHeight: '320px', ...(isSmallScreen ? {marginBottom: '16px'} : {})}}>
          <Card padding={isSmallScreen ? 1 : 3} style={{height: '100%', overflow: 'auto'}}>
            <CardContent style={{height: '100%'}}>
              <PartSelectorTreeView mode="draft" selectionType={type} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={isSmallScreen ? 2 : 4} style={{height: 'calc(100% + 16px)'}}>
            <Grid
              item
              xs={12}
              style={
                isViewportFullScreen
                  ? VIEWPORT_FULLSCREEN_STYLE
                  : {
                      height: 'calc(100vh - 566px)',
                      minHeight: isSmallScreen ? '50vh' : '320px',
                    }
              }
            >
              <SelectedPart3DViewport
                type={type}
                isViewportFullScreen={isViewportFullScreen}
                sourcePartUuids={sourcePartUuids}
                sourcePartRotations={draftReport.sourcePartRotations}
                targetPartRotations={draftReport.targetPartRotations}
                onRotatePart={currentlyViewingIsSelected ? onRotationChanged : undefined}
              />
            </Grid>
            <Grid item xs={12} style={{height: '340px'}}>
              <Card padding={isSmallScreen ? 1 : 3} style={{height: '100%'}}>
                <SimilarityPartInfo />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>

      {!isSmallScreen && <NavigationButtons />}
    </>
  );
};

const StyledGrid = styled(Grid)`
  flex: 1;
  margin-top: 12px;
  min-height: 624px;
`;
