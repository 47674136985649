import {Button, Table, TableBody, TableCell} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import {AttachFile, CloudDownload} from '@material-ui/icons';
import filesize from 'filesize';
import * as React from 'react';

import {IAttachmentConcrete} from '@common/api/models/attachments/IAttachmentBase';
import {materialAttachmentDownloadUrlGET} from '../../../api/ajax/materialAttachments';
import {downloadUrl} from '../../../utils/webtools';

export function AttachmentDownloadTable(props: {attachments: IAttachmentConcrete[]}) {
  const downloadOnClick = async (materialUuid: string, attachmentFilename: string) => {
    const url = await materialAttachmentDownloadUrlGET(materialUuid);
    if (url.success) {
      downloadUrl(url.data, attachmentFilename);
    }
  };

  return (
    <Table size={'small'}>
      <TableBody>
        {props.attachments.map((a) => {
          return (
            <TableRow>
              <TableCell style={{borderBottom: 'none'}}>
                <AttachFile fontSize={'small'} style={{marginBottom: -5}} />
                {a.filename}
              </TableCell>
              <TableCell style={{borderBottom: 'none'}}>{filesize(a.filesize)}</TableCell>
              <TableCell style={{borderBottom: 'none'}}>
                <Button onClick={() => downloadOnClick(a.uuid, a.filename)}>
                  <CloudDownload />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
