import moment from 'moment';

export interface TimeFilter {
  name: string;
  from: Date | null;
  to: Date | null;
}

export function makeAllTimeFilter(): TimeFilter {
  return {
    name: 'All Time',
    from: null,
    to: null,
  };
}

export function makeLastXDaysFilter(days: number): TimeFilter {
  days = Math.floor(days);
  return {
    name: 'Last ' + days + ' Days',
    from: moment()
      .startOf('day')
      .subtract(days - 1, 'days')
      .toDate(),
    to: null,
  };
}
