import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, Popover, Slider, Typography} from '@material-ui/core';
import {FadingDiv, ViewerButton} from '../../FadingComponents';
import {ImageFilter} from '../imageutils';
import {Settings} from '@material-ui/icons';

interface ImageFilterControlProps {
  mouseOn?: boolean;
  divPositionStyle: React.CSSProperties;
  imageFilter: ImageFilter;
  setImageFilter: React.Dispatch<React.SetStateAction<ImageFilter>>;
}

function ImageSettings({mouseOn, divPositionStyle, imageFilter, setImageFilter}: ImageFilterControlProps) {
  const [internalImageFilter, setInternalImageFilter] = useState<ImageFilter>(imageFilter);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined = (event) => {
    if (event.currentTarget) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setInternalImageFilter(imageFilter);
  }, [imageFilter]);

  return (
    <FadingDiv className={(mouseOn === false && 'fade') || undefined} style={divPositionStyle}>
      <ViewerButton onClick={handleClick} className={(!mouseOn && 'fade') || undefined}>
        Image Settings
        <Settings
          style={{
            marginLeft: '10px',
          }}
        />
      </ViewerButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Card>
          <CardContent>
            <Box width="300px">
              <Typography id=" brightness-slider" gutterBottom>
                Brightness
              </Typography>
              <Slider
                value={internalImageFilter.brightness}
                min={0}
                max={200}
                onChange={(_e: any, val: any) => {
                  setInternalImageFilter({...internalImageFilter, brightness: val});
                  setImageFilter({...internalImageFilter, brightness: val});
                }}
                valueLabelDisplay="auto"
                aria-labelledby=" brightness-slider"
                getAriaValueText={(val: number) =>
                  `${(val <= 100 ? val : 100 + ((val - 100) / 100) * 400).toFixed(0)}%`
                }
                step={10}
                valueLabelFormat={(val: number) =>
                  `${(val <= 100 ? val : 100 + ((val - 100) / 100) * 400).toFixed(0)}%`
                }
              />
              <Typography id="contrast-slider" gutterBottom>
                Contrast
              </Typography>
              <Slider
                value={internalImageFilter.contrast}
                min={0}
                max={200}
                onChange={(_e: any, val: any) => {
                  setInternalImageFilter({...internalImageFilter, contrast: val});
                  setImageFilter({...internalImageFilter, contrast: val});
                }}
                valueLabelDisplay="auto"
                aria-labelledby="contrast-slider"
                getAriaValueText={(val: number) => `${val}`}
                step={10}
                valueLabelFormat={(val: number) => `${val}%`}
              />
              <Typography id="saturation-slider" gutterBottom>
                Saturation
              </Typography>
              <Slider
                value={internalImageFilter.saturation}
                min={0}
                max={200}
                onChange={(_e: any, val: any) => {
                  setInternalImageFilter({...internalImageFilter, saturation: val});
                  setImageFilter({...internalImageFilter, saturation: val});
                }}
                valueLabelDisplay="auto"
                aria-labelledby="saturation-slider"
                getAriaValueText={(val: number) =>
                  `${(val <= 100 ? val : 100 + ((val - 100) / 100) * 400).toFixed(0)}%`
                }
                step={10}
                valueLabelFormat={(val: number) =>
                  `${(val <= 100 ? val : 100 + ((val - 100) / 100) * 400).toFixed(0)}%`
                }
              />
              <Typography id="gammaAmplitude-slider" gutterBottom>
                Gamma Amplitude (A)
              </Typography>
              <Slider
                value={internalImageFilter.gammaAmplitude}
                min={0.1}
                max={5}
                onChange={(_e: any, val: any) => {
                  setInternalImageFilter({...internalImageFilter, gammaAmplitude: val});
                  setImageFilter({...internalImageFilter, gammaAmplitude: val});
                }}
                valueLabelDisplay="auto"
                aria-labelledby="gammaAmplitude-slider"
                step={0.1}
                getAriaValueText={(val: number) => `${val}`}
                valueLabelFormat={(val: number) => `${val}`}
              />
              <Typography id="gammaExponent-slider" gutterBottom>
                Gamma Exponent (Y)
              </Typography>
              <Slider
                value={internalImageFilter.gammaExponent}
                min={0.1}
                max={5}
                onChange={(_e: any, val: any) => {
                  setInternalImageFilter({...internalImageFilter, gammaExponent: val});
                  setImageFilter({...internalImageFilter, gammaExponent: val});
                }}
                valueLabelDisplay="auto"
                aria-labelledby="gammaExponent-slider"
                step={0.1}
                getAriaValueText={(val: number) => `${val}`}
                valueLabelFormat={(val: number) => `${val}`}
              />
            </Box>
          </CardContent>
        </Card>
      </Popover>
    </FadingDiv>
  );
}

export default ImageSettings;

export const GammaFilter = ({imageFilter, gammaKey}: {imageFilter?: ImageFilter; gammaKey: string}) => {
  return (
    <svg height="0" style={{position: 'absolute', height: '0px'}}>
      <filter id={gammaKey}>
        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR
            type="gamma"
            exponent={`${imageFilter?.gammaExponent || 1}`}
            amplitude={`${imageFilter?.gammaAmplitude || 1}`}
            offset="0"
          />
          <feFuncG
            type="gamma"
            exponent={`${imageFilter?.gammaExponent || 1}`}
            amplitude={`${imageFilter?.gammaAmplitude || 1}`}
            offset="0"
          />
          <feFuncB
            type="gamma"
            exponent={`${imageFilter?.gammaExponent || 1}`}
            amplitude={`${imageFilter?.gammaAmplitude || 1}`}
            offset="0"
          />
        </feComponentTransfer>
      </filter>
    </svg>
  );
};
