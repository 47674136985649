import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IconButton, Grid, CircularProgress, Tooltip, TextField, Button} from '@material-ui/core';
import {Edit, Save, Clear} from '@material-ui/icons';
import {Controller, useForm} from 'react-hook-form';
import {humanize} from 'inflection';
import {LocationBasedDefectsStatus} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {RootState} from '../../store/reducers/index';
import EllipsisTextWithTooltip from '../../components/atoms/Texts/EllipsisTextWithTooltip';
import {locationBasedDefectsReportPATCH} from '../../api/ajax/locationBasedDefectsReport';
import {statusChipColor} from './LocationBasedDefectsList';
import {ChipStatus} from '../../components/atoms/Status/Status';
import {downloadUrl} from '../../utils/webtools';
import {useExtraSmallScreenSize} from '../../utils/utilHooks';

function ReportHeader() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const report = useSelector((state: RootState) => state.locationBasedDefectsReportStore.byId[selectedUuid]);
  const zipFile = useSelector((state: RootState) =>
    state.locationBasedDefectsReportStore.images[selectedUuid]?.find((img) => img.filename!.includes('.zip'))
  );

  const [reportName, setReportName] = useState<string>('');
  const [reportNameEditing, setReportNameEditing] = useState(false);
  const [requestingNameUpdate, setRequestingNameUpdate] = useState(false);
  const isXs = useExtraSmallScreenSize();

  useEffect(() => {
    if (report?.name) {
      setReportName(report.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.name]);

  async function saveReportName(name: string) {
    setRequestingNameUpdate(true);
    const res = await locationBasedDefectsReportPATCH(report.uuid, name);

    if (res.success) {
      setReportName(name);
      setReportNameEditing(false);
    }

    setRequestingNameUpdate(false);
  }

  function downloadZip() {
    if (zipFile) {
      downloadUrl(zipFile.signedUrl, zipFile.filename);
    }
  }

  return (
    <Grid
      container
      style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem'}}
    >
      <Grid item xs={12} sm={6} style={{display: 'flex', alignItems: 'center'}}>
        {reportNameEditing ? (
          <EditMode
            name={reportName}
            onSave={({name}) => saveReportName(name)}
            onCancel={() => setReportNameEditing(false)}
            requestingNameUpdate={requestingNameUpdate}
          />
        ) : (
          <ViewMode name={reportName} status={report?.status} setReportNameEditing={() => setReportNameEditing(true)} />
        )}
      </Grid>
      {!!zipFile && (
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            ...(isXs ? {marginTop: '12px'} : {}),
          }}
        >
          <Button color="primary" variant="contained" onClick={downloadZip} fullWidth={isXs}>
            Download Images
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default ReportHeader;

interface ViewModeProps {
  name: string;
  status: LocationBasedDefectsStatus;
  setReportNameEditing: () => void;
}

const ViewMode = ({name, status, setReportNameEditing}: ViewModeProps) => {
  return (
    <>
      <Grid item style={{maxWidth: 'calc(100% - 46px - 124px)'}}>
        <EllipsisTextWithTooltip variant="h4">{name}</EllipsisTextWithTooltip>
      </Grid>
      <Grid item style={{margin: '-6px 12px 0px 12px'}}>
        <Tooltip title="Edit">
          <IconButton size="small" onClick={setReportNameEditing}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <ChipStatus chipSize="small" message={humanize(status)} variant={statusChipColor(status)} />
      </Grid>
    </>
  );
};

interface EditModeProps {
  name: string;
  onSave: (data: {name: string}) => void;
  onCancel: () => void;
  requestingNameUpdate: boolean;
}

const EditMode = ({name, onSave, onCancel, requestingNameUpdate}: EditModeProps) => {
  const {
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<{name: string}>({
    mode: 'all',
    defaultValues: {name},
  });

  return (
    <>
      <Grid
        item
        style={{
          width: 'calc(100% - 76px)',
          maxWidth: '360px',
          marginRight: '12px',
        }}
      >
        <form id="updateParamReportName" onSubmit={onSubmit(onSave)}>
          <Controller
            name="name"
            control={control}
            render={({onChange, value, ref}) => {
              return (
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="medium"
                  label="Profile Name"
                  onKeyDown={(event) => event.key === 'Escape' && onCancel()}
                  inputRef={ref}
                  value={value}
                  error={!!errors.name}
                  onChange={(event) => onChange(event.target.value)}
                />
              );
            }}
          />
        </form>
      </Grid>
      <Grid item>
        {requestingNameUpdate ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Tooltip title="Save">
              <IconButton size="small" onClick={onSubmit(onSave)}>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton size="small" onClick={onCancel}>
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Grid>
    </>
  );
};
