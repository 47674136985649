import React from 'react';
import {
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  GridSize,
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {Control, useForm} from 'react-hook-form';

import StringFilter from './filterComponents/StringFilter';
import NumberFilter from './filterComponents/NumberFilter';
import EnumMultipleFilter from './filterComponents/EnumMultipleFilter';
import EnumSingleFilter from './filterComponents/EnumSingleFilter';
import DateRangeFilter from './filterComponents/DateRangeFilter';
import ResourceFilter from './filterComponents/ResourceFilter';

import {FilterSchemaType} from './filterSchemas';

import {ResourceTypes, ComparisonFilters} from './IndexPage';
import {humanize, pluralize, underscore, titleize} from 'inflection';
import BooleanFilter from './filterComponents/BooleanFilter';
import BooleanListFilter from './filterComponents/BooleanListFilter';

interface CommonFilterProps {
  filterSchema: FilterSchemaType[];
  onApplyFilters: (newFilters: ComparisonFilters | undefined) => void;
  currentFilters: ComparisonFilters;
  resourceType: ResourceTypes;
}

const FilterModal = ({
  isOpen,
  onClose,
  filterSchema,
  currentFilters,
  onApplyFilters,
  resourceType,
  title,
}: CommonFilterProps & {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
}) => {
  const {control, handleSubmit: onSubmit} = useForm({
    mode: 'all',
    defaultValues: currentFilters as any,
  });

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle disableTypography style={{padding: '24px'}}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4" style={{maxWidth: 'calc(100% - 24px)'}}>
            {/* Please don't remove these, all of them are needed to properly convert resourceType */}
            Filter My {title || titleize(pluralize(humanize(underscore(resourceType))))}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} style={{padding: '0px'}}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={onSubmit(onApplyFilters)}>
        <DialogContent
          dividers
          style={{
            padding: '24px',
            width: '100%',
          }}
        >
          <Filters
            filterSchema={filterSchema}
            control={control}
            currentFilters={currentFilters}
            resourceType={resourceType}
            filterListLocation="modal"
          />
        </DialogContent>
        <DialogActions style={{padding: '16px 24px'}}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onSubmit={onSubmit(onApplyFilters)}
            onClick={onSubmit(onApplyFilters)}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FilterModal;

export const FilterListHeader = ({
  filterSchema,
  currentFilters,
  onApplyFilters,
  resourceType,
  tableHeaderFilters,
}: CommonFilterProps & {tableHeaderFilters: Array<{field: string; smGridSize: GridSize}>}) => {
  const {control, handleSubmit: onSubmit} = useForm({
    mode: 'all',
    defaultValues: currentFilters as any,
  });

  return (
    <form onSubmit={onSubmit(onApplyFilters)}>
      <Filters
        filterSchema={filterSchema}
        resourceType={resourceType}
        control={control}
        currentFilters={currentFilters}
        onSubmit={onSubmit(onApplyFilters)}
        filterListLocation="header"
        tableHeaderFilters={tableHeaderFilters}
      />
    </form>
  );
};

export interface FiltersProps {
  filterSchema: FilterSchemaType[];
  resourceType: ResourceTypes;
  currentFilters: ComparisonFilters;
  control: Control<any>;
  onSubmit?: () => void;
  filterListLocation: 'modal' | 'header';
  tableHeaderFilters?: Array<{field: string; smGridSize: GridSize}>;
}

export const Filters = ({
  filterSchema,
  resourceType,
  currentFilters,
  control,
  onSubmit,
  filterListLocation = 'modal',
  tableHeaderFilters,
}: FiltersProps) => {
  const layout = filterListLocation === 'header' ? 'row' : 'column';
  const gridLayout = filterSchema.length > 1 ? 6 : 12;

  return (
    <Grid
      container
      direction={layout}
      justifyContent="flex-start"
      alignItems="stretch"
      wrap="wrap"
      spacing={4}
      style={{paddingTop: layout === 'row' ? '10px' : undefined}}
    >
      {filterSchema.map((filter) => {
        const itemLayout = tableHeaderFilters?.find((f) => f.field === filter.field)?.smGridSize || gridLayout;
        return (
          <Grid item xs={12} sm={layout === 'row' ? itemLayout : 12} key={`filter-${resourceType}-${filter.field}`}>
            <SingleFilter
              {...filter}
              currentFilters={currentFilters}
              control={control}
              onSubmit={onSubmit}
              filterListLocation={filterListLocation}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export type FilterComponentProps = FilterSchemaType & {
  control: Control<any>;
  currentFilters: ComparisonFilters;
  onSubmit?: () => void;
  filterListLocation: 'modal' | 'header';
};

export const SingleFilter = (props: FilterComponentProps) => {
  if (props.filterListLocation === 'modal') {
    props = {...props, permanentComparison: undefined};
  }

  switch (props.type) {
    case 'string':
      return <StringFilter {...props} />;
    case 'float':
      return <NumberFilter {...props} numberType="float" />;
    case 'int':
      return <NumberFilter {...props} numberType="int" />;
    case 'boolean':
      return <BooleanFilter {...props} />;
    case 'enumMultiple':
      return <EnumMultipleFilter {...props} />;
    case 'enumSingle':
      return <EnumSingleFilter {...props} />;
    case 'dateTimeRange':
      return <DateRangeFilter {...props} dateType="dateTime" />;
    case 'dateRange':
      return <DateRangeFilter {...props} dateType="date" />;
    case 'resourceSelect':
      return <ResourceFilter {...props} />;
    case 'booleanList':
      return <BooleanListFilter {...props} />;
    default:
      return <></>;
  }
};
