import {LiveStoreReducers} from '../liveUpdateStore';
import {LiveStoreState} from '../../model/liveUpdateStore';
import createReducer from '../createReducer';
import {
  LocationBasedDefectsReportActions,
  LocationBasedDefectsReportFetchImagesAction,
  LocationBasedDefectsReportStoreState,
} from '../../model/locationBasedDefects';
import {ILocationBasedDefectsReportGETResponse} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';

export class LocationBasedDefectsReportLiveReducers extends LiveStoreReducers<
  ILocationBasedDefectsReportGETResponse,
  LocationBasedDefectsReportStoreState
> {
  additionalReducers() {
    return {
      fetchImagesSuccess(
        state: LocationBasedDefectsReportStoreState,
        {payload: {images, uuid, metadata}}: LocationBasedDefectsReportFetchImagesAction
      ): LocationBasedDefectsReportStoreState {
        return {
          ...state,
          metadata: {[uuid]: metadata},
          images: {[uuid]: images},
        };
      },
    };
  }

  createReducer(initialState: LocationBasedDefectsReportStoreState) {
    const superReducers = this.bindReducers();
    const additionalReducers = this.additionalReducers();

    return createReducer<LiveStoreState<ILocationBasedDefectsReportGETResponse>>(initialState, {
      ...superReducers,
      [LocationBasedDefectsReportActions.LOCATION_BASED_DEFECTS_REPORT_FETCH_IMAGES_SUCCESS]:
        additionalReducers.fetchImagesSuccess,
    });
  }
}
