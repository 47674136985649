import React from 'react';
import {Grid, Slider, Typography} from '@material-ui/core';
import {AnalysisType2D, getPrettyAnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';
import {AnalysisOverlayParams} from '../analysisType';
import {BUTTON_MARGIN} from '../utils';

interface TransparencySliderProps {
  analysisType: AnalysisType2D;
  analysisOverlayParams: AnalysisOverlayParams;
  setAnalysisOverlayParams: (data: AnalysisOverlayParams) => void;
}

export function TransparencySlider(props: TransparencySliderProps) {
  const transparency = props.analysisOverlayParams[props.analysisType].filterParams?.opacity ?? 1;

  // All the below statement does is update the opacity for the current analysis type,
  // all other data is retained. It's rather verbose
  const setTransparency = (value: number) => {
    props.setAnalysisOverlayParams({
      ...props.analysisOverlayParams,
      [props.analysisType]: {
        ...props.analysisOverlayParams[props.analysisType],
        filterParams: {
          ...props.analysisOverlayParams[props.analysisType].filterParams,
          opacity: value,
        },
      },
    });
  };

  return (
    <Grid
      container
      style={{
        color: 'white',
        maxWidth: 'fit-content',
        position: 'absolute',
        left: BUTTON_MARGIN * 2,
        top: BUTTON_MARGIN * 2,
      }}
    >
      <Grid item xs={12}>
        <Typography>{getPrettyAnalysisType2D(props.analysisType)} transparency</Typography>
      </Grid>
      <Grid item xs={12}>
        <Slider
          value={transparency}
          min={0}
          step={0.01}
          max={1}
          onChange={(_: any, newVal: number | number[]) => typeof newVal === 'number' && setTransparency(newVal)}
        />
      </Grid>
    </Grid>
  );
}
