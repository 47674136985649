import {Box} from '@material-ui/core';
import React from 'react';

function CenteredPage({children}: {children: React.ReactNode}) {
  return (
    <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      {children}
    </Box>
  );
}

export default CenteredPage;
