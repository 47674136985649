import {IUser} from '@common/api/models/users/IUser';

export enum LogInState {
  LoggedIn,
  LoggedOut,
  Unknown, // we can't read HTTPS cookies so yes, there is an unknown state.
}

export interface AuthState {
  user?: IUser;
  logInState: LogInState;
  emailConfirmationShown: boolean;
}

export const initialAuthState: AuthState = {
  logInState: LogInState.Unknown,
  emailConfirmationShown: false,
};

export enum AuthActions {
  AUTHENTICATED = 'AUTHENTICATED',
  LOGOUT = 'LOGOUT',
  EMAIL_CONFIRMATION_TOAST_SHOWN = 'POPUP_SHOWN',
}

interface AuthActionType<T, P> {
  type: T;
  payload: P;
}

export type AuthAction =
  | AuthActionType<typeof AuthActions.AUTHENTICATED, IUser>
  | AuthActionType<typeof AuthActions.LOGOUT, void>
  | AuthActionType<typeof AuthActions.EMAIL_CONFIRMATION_TOAST_SHOWN, void>;
