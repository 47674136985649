import styled from 'styled-components';
import {grayColor} from '../../../assets/jss/material-dashboard-react';

const DropZoneContainer = styled.div<{isDragActive: boolean}>`
  border: dashed 2px ${({isDragActive}) => (isDragActive ? grayColor[6] : grayColor[4])};
  ${({isDragActive}) => (isDragActive ? `background-color: ${grayColor[5]};` : '')};
  transition: all 0.3s;
  border-radius: 5px;
  text-align: center;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 64px;
    height: 100%;
    width: 100%;

    @media (max-width: 900px) {
      padding: 12px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
  }
`;

export default DropZoneContainer;
