import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {inflect} from 'inflection';

import {Button, Card, Grid, Typography} from '@material-ui/core';
import {useBuildAttachmentStoreActions, useSliceAttachmentStoreActions} from '../../../store/actions';
import {BuildAttachmentType, IBuildAttachment} from '@common/api/models/attachments/IAttachmentBase';
import {ISliceAttachmentGETResponse} from '@common/api/models/attachments/ISliceAttachment';
import {IBuild} from '@common/api/models/builds/IBuild';

import {RootState} from '../../../store/reducers';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import SliceFilesUploadModal from '../../../pages/builds/shared/SliceFilesUploadModal';

export function isSliceAttachment(
  attachment: ISliceAttachmentGETResponse | IBuildAttachment
): attachment is ISliceAttachmentGETResponse {
  return (attachment as ISliceAttachmentGETResponse).state !== undefined;
}

export function UploadedFilesCard(props: {build: IBuild}) {
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);

  /* Slice Attachment */
  const sliceAttachmentStoreActions = useSliceAttachmentStoreActions();
  const sliceAttachmentStore = useSelector((s: RootState) => s.sliceAttachmentStore);
  const sliceAttachments = Object.values(sliceAttachmentStore.byId).filter(
    (sliceAttachment) => sliceAttachment.buildUuid === props.build.uuid && !sliceAttachment.deletedAt
  );

  /* Build Attachment */
  const attachmentStoreActions = useBuildAttachmentStoreActions();
  const attachmentStore = useSelector((s: RootState) => s.buildAttachmentStore);
  const attachments = Object.values(attachmentStore.byId).filter(
    (attachment) => attachment.buildUuid === props.build.uuid && attachment.type === BuildAttachmentType.BuildAttachment
  );

  useEffect(() => {
    sliceAttachmentStoreActions.ensureConsistent({buildUuid: props.build.uuid});
    attachmentStoreActions.ensureConsistent({buildUuid: props.build.uuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  const isSmall = useSmallScreenSize();

  return (
    <Card style={{padding: isSmall ? '15px' : '30px'}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={isSmall ? 12 : 4} style={{display: 'flex'}}>
          <Typography variant="h6" gutterBottom display="inline">
            Uploaded Files
          </Typography>
        </Grid>

        <Grid item xs={isSmall ? 12 : 5} style={{display: 'flex'}}>
          {sliceAttachments.length} {inflect('Slice file', sliceAttachments.length)}, {attachments.length}{' '}
          {inflect('Other file', attachments.length)}
        </Grid>

        <Grid item xs={isSmall ? 12 : 3}>
          <Button
            style={{fontWeight: 400, float: 'right'}}
            fullWidth={isSmall}
            variant="outlined"
            color="primary"
            onClick={() => setUploadModalOpen(true)}
          >
            View / Upload
          </Button>
        </Grid>
      </Grid>

      <SliceFilesUploadModal isOpen={uploadModalOpen} setOpen={setUploadModalOpen} buildUuid={props.build.uuid} />
    </Card>
  );
}
