import React, {useEffect, useState} from 'react';
import {CloudUpload} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import inflection from 'inflection';
import {
  IMAGE_FILE_EXTENSIONS,
  SLICE_FILE_EXTENSIONS,
  SLICE_FILE_EXTENSIONS_PAINT,
  ScanStrategy,
  isSliceFile,
} from '@common/api/models/attachments/ISliceAttachment';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {useMultiPartUpload} from '../../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../../utils/contexts/IMultiPartUploadContext';
import DropZoneContainer from '../../../components/molecules/Cards/DropZoneContainer';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {grayColor} from '../../../assets/jss/material-dashboard-react';

type MultipartUploadProps = {
  buildUuid: string;
  orgUuid: string;
  setNumFilesUploading: React.Dispatch<React.SetStateAction<number>>;
  numFilesUploading?: number;
  acceptImage?: boolean;
  postUploadCallback?: () => void;
  isBuildPhoto?: boolean;
};

export type uploadParamType = {
  signedUrls: string[];
  filename: string;
  key: string;
  uploadId: string;
};

function SliceAttachmentMultipartUpload({
  buildUuid,
  orgUuid,
  setNumFilesUploading,
  numFilesUploading,
  acceptImage = false,
  isBuildPhoto = false,
  postUploadCallback,
}: MultipartUploadProps) {
  const [pendingScanStrategyFiles, setPendingScanStrategyFiles] = useState<File[]>([]);

  const {onDrop} = useMultiPartUpload();

  function dropZoneOnDrop(droppedFiles: File[]) {
    const includesSliceFile = droppedFiles.some((file) => isSliceFile(file.name));

    if (includesSliceFile) {
      setPendingScanStrategyFiles(droppedFiles);
      return;
    }

    onDrop(droppedFiles, MultiPartUploadResourceType.sliceAttachment, {
      resourceUuid: buildUuid,
      orgUuid: orgUuid,
      setNumFilesUploading,
      isBuildPhoto,
      postUploadCallback,
    });
  }

  function handlePendingScanStrategyFiles(scanStrategy: ScanStrategy) {
    onDrop(pendingScanStrategyFiles, MultiPartUploadResourceType.sliceAttachment, {
      resourceUuid: buildUuid,
      orgUuid: orgUuid,
      setNumFilesUploading,
      scanStrategy,
      isBuildPhoto,
      postUploadCallback,
    });
    setPendingScanStrategyFiles([]);
  }

  const sliceFileExtensionText = SLICE_FILE_EXTENSIONS.map((item) => `.${item}`).reduce(
    (outputString, item) => `${outputString}, ${item}`
  );

  const imageFileExtensionText = IMAGE_FILE_EXTENSIONS.map((item) => `.${item}`).reduce(
    (outputString, item) => `${outputString}, ${item}`
  );
  const acceptImages = {'image/*': IMAGE_FILE_EXTENSIONS.map((item) => `.${item}`)};

  return (
    <Dropzone onDrop={dropZoneOnDrop} noClick accept={acceptImage ? acceptImages : undefined}>
      {({getRootProps, getInputProps, open, isDragActive}) => (
        <DropZoneContainer isDragActive={isDragActive}>
          <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <Row>
                <CloudUpload color="primary" style={{fontSize: 48}} />
              </Row>
              <Row>
                <Typography variant="h4">Drag & drop to upload</Typography>
              </Row>
              <Row>
                <Typography variant="subtitle1">or</Typography>
              </Row>
              <Row>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={open}
                  size="large"
                  fullWidth={false}
                  endIcon={numFilesUploading ? <CircularProgress size={20} /> : undefined}
                >
                  {acceptImage ? 'Browse photos...' : 'Browse files...'}
                </Button>
              </Row>

              {!acceptImage && (
                <ScanStrategySelectionModal
                  pendingScanStrategyFiles={pendingScanStrategyFiles}
                  setPendingScanStrategyFiles={setPendingScanStrategyFiles}
                  handlePendingScanStrategyFiles={handlePendingScanStrategyFiles}
                />
              )}

              <Row>
                <Typography style={{fontStyle: 'italic', marginTop: '16px'}}>
                  {acceptImage
                    ? `Accepted Files Include Image Files ( ${imageFileExtensionText} )`
                    : `Accepted files include Slice Files (${sliceFileExtensionText}), Scene Data, and General Documents (.txt)`}
                </Typography>
              </Row>
            </div>
          </section>
        </DropZoneContainer>
      )}
    </Dropzone>
  );
}

export default SliceAttachmentMultipartUpload;

const TOOLTIPS = {
  [ScanStrategy.BORDER]:
    'Reads border contours from the slice file - where the laser passes around the outer skin of the part. ' +
    'This is the recommended strategy for most parts.',
  [ScanStrategy.PAINT]:
    'Reads both border contours and hatch vectors from the slice file. Draws them onto a virtual canvas ' +
    'with a thick line to construct the laser path. Has next best precision after border mode. Use if the default strategy failed.',
  [ScanStrategy.FILL]:
    'Reads the hatch vectors from the slice file - where the laser passes over the internal fill ' +
    'of the part. Use for parts without defined border contours or if the other strategies failed.',
};

export function ScanStrategySelectionModal({
  pendingScanStrategyFiles,
  setPendingScanStrategyFiles,
  handlePendingScanStrategyFiles,
}: {
  pendingScanStrategyFiles: File[];
  setPendingScanStrategyFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handlePendingScanStrategyFiles: (scanStrategy: ScanStrategy) => void;
}) {
  const [selectedScanStrategy, setSelectedScanStrategy] = useState<ScanStrategy>(ScanStrategy.BORDER);

  const paintModeDisabled = pendingScanStrategyFiles.some(
    (file) => !SLICE_FILE_EXTENSIONS_PAINT.includes(file.name.split('.').pop()!.toLowerCase())
  );

  useEffect(() => {
    let previousScanStrategy = localStorage.getItem('scanStrategy') as ScanStrategy | undefined;
    if (previousScanStrategy === ScanStrategy.PAINT && paintModeDisabled) {
      previousScanStrategy = undefined;
    }
    setSelectedScanStrategy(previousScanStrategy || ScanStrategy.BORDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingScanStrategyFiles]);

  function onConfirm() {
    localStorage.setItem('scanStrategy', selectedScanStrategy);
    handlePendingScanStrategyFiles(selectedScanStrategy);
  }

  return (
    <GenericDialog
      title="Select Scan Strategy"
      isOpen={pendingScanStrategyFiles.length > 0}
      closeDialog={() => setPendingScanStrategyFiles([])}
      content={
        <>
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{color: grayColor[7]}}>
              Scan Strategy
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={selectedScanStrategy}
              onChange={(_e, value) => setSelectedScanStrategy(value as ScanStrategy)}
            >
              <FormControlLabel value={ScanStrategy.BORDER} control={<Radio />} label="Border" />
              <ConditionalTooltip
                hideTooltip={!paintModeDisabled}
                tooltip={`Paint mode only supports the following slice files: ${SLICE_FILE_EXTENSIONS_PAINT.map(
                  (mode) => `.${mode}`
                ).join(', ')}`}
              >
                <FormControlLabel
                  value={ScanStrategy.PAINT}
                  control={<Radio />}
                  label="Paint"
                  disabled={paintModeDisabled}
                />
              </ConditionalTooltip>
              <FormControlLabel value={ScanStrategy.FILL} control={<Radio />} label="Fill (Depreciated)" />
            </RadioGroup>
          </FormControl>

          <Row>
            <Alert severity="info">
              {
                <>
                  <b>{inflection.humanize(selectedScanStrategy)} Scan Strategy</b>
                  <br />
                  {TOOLTIPS[selectedScanStrategy as ScanStrategy]}
                </>
              }
            </Alert>
          </Row>

          {selectedScanStrategy === ScanStrategy.PAINT && (
            <Row style={{color: 'orange', fontSize: '14px'}}>
              <Alert severity="warning">Paint scan strategy is experimental, results may not be optimal.</Alert>
            </Row>
          )}

          {selectedScanStrategy === ScanStrategy.FILL && (
            <Row style={{color: 'orange', fontSize: '14px'}}>
              <Alert severity="warning">Fill scan strategy is depreciated, results may not be optimal.</Alert>
            </Row>
          )}
        </>
      }
      confirmText="Submit"
      onSuccess={onConfirm}
    />
  );
}

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
`;
