import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';

export function InvalidEmailConfirmation() {
  toast('Email confirmation token invalid', {type: 'warning'});

  const history = useHistory();
  history.replace('/');

  return <Redirect to={'/'} />;
}
