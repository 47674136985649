import React, {useEffect} from 'react';
import {Table, TableBody, TableCell, TableRow, TextField, Typography} from '@material-ui/core';
import {inflect} from 'inflection';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {IBuild} from '@common/api/models/builds/IBuild';

import {useDraftBuild} from '../../DraftBuildContext';
import {RootState} from '../../../../../store/reducers';
import {
  useMachineStoreActions,
  useDeviceStoreActions,
  useBatchStoreActions,
  useSliceAttachmentStoreActions,
  useBuildAttachmentStoreActions,
  useRecipientStoreActions,
  useMachineParameterStoreActions,
  useSensorProfileStoreActions,
} from '../../../../../store/actions';
import {FetchingState} from '../../../../../store/model/liveUpdateStore';
import {useDefectNotificationStoreActions, useUserStoreActions} from '../../../../../store/actions/index';
import SliceFilesCollapse from './SliceFilesCollapse';
import RecipientsCollapse from './RecipientsCollapse';
import DefectNotificationsCollapse from './DefectNotificationsCollapse';
import MachineParametersCollapse from './MachineParametersCollapse';
import ConfirmationRow from './ConfirmationRow';
import {useExtraSmallScreenSize} from '../../../../../utils/utilHooks';

const useConfirmationData = (draftBuild: IBuild) => {
  const machineStoreActions = useMachineStoreActions();
  const deviceStoreActions = useDeviceStoreActions();
  const batchStoreActions = useBatchStoreActions();
  const machineParametersStoreActions = useMachineParameterStoreActions();
  const sliceAttachmentStoreActions = useSliceAttachmentStoreActions();
  const buildAttachmentStoreActions = useBuildAttachmentStoreActions();
  const recipientStoreActions = useRecipientStoreActions();
  const defectNotificationStoreActions = useDefectNotificationStoreActions();
  const userStoreActions = useUserStoreActions();
  const sensorProfileActions = useSensorProfileStoreActions();

  const machineStore = useSelector((state: RootState) => state.machineStore);
  const deviceStore = useSelector((state: RootState) => state.deviceStore);
  const batchStore = useSelector((state: RootState) => state.batchStore);
  const sliceAttachmentStore = useSelector((state: RootState) => state.sliceAttachmentStore);
  const machineParametersStore = useSelector((state: RootState) => state.machineParameterStore);
  const buildAttachmentStore = useSelector((state: RootState) => state.buildAttachmentStore);
  const recipientStore = useSelector((state: RootState) => state.recipientStore);
  const defectNotificationStore = useSelector((state: RootState) => state.defectNotificationStore);
  const sensorProfileStore = useSelector((state: RootState) => state.sensorProfileStore);
  const userStore = useSelector((state: RootState) => state.userStore);

  useEffect(() => {
    if (draftBuild.machineUuid) {
      machineStoreActions.ensureConsistent({uuid: draftBuild.machineUuid});
    }
    if (draftBuild.deviceSerial) {
      deviceStoreActions.ensureConsistent({serial: draftBuild.deviceSerial});
    }
    if (draftBuild.batchUuid) {
      batchStoreActions.ensureConsistent({uuid: draftBuild.batchUuid});
    }
    if (draftBuild.machineParametersUuid) {
      machineParametersStoreActions.ensureConsistent({
        uuid: draftBuild.machineParametersUuid,
      });
    }
    if (draftBuild.sensorProfileUuid) {
      sensorProfileActions.ensureConsistent({
        uuid: draftBuild.sensorProfileUuid,
      });
    }

    sliceAttachmentStoreActions.ensureConsistent({
      buildUuid: draftBuild.uuid,
    });
    buildAttachmentStoreActions.ensureConsistent({
      buildUuid: draftBuild.uuid,
    });
    recipientStoreActions.ensureConsistent({
      buildUuid: draftBuild.uuid,
    });
    defectNotificationStoreActions.ensureConsistent({
      buildUuid: draftBuild.uuid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    draftBuild.uuid,
    draftBuild.machineUuid,
    draftBuild.deviceSerial,
    draftBuild.batchUuid,
    draftBuild.machineParametersUuid,
  ]);

  useEffect(() => {
    const userUuids = Object.values(recipientStore.byId)
      .filter((recipient) => recipient.buildUuid === draftBuild.uuid)
      .map((recipient) => recipient.userUuid!);

    if (userUuids.length) {
      userStoreActions.ensureConsistent({uuid: userUuids});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientStore.byId, draftBuild.uuid]);

  return {
    sensorProfileLoading: sensorProfileStore.fetched === FetchingState.Fetching,
    machineLoading: machineStore.fetched === FetchingState.Fetching,
    deviceLoading: deviceStore.fetched === FetchingState.Fetching,
    batchLoading: batchStore.fetched === FetchingState.Fetching,
    machineParametersLoading: machineParametersStore.fetched === FetchingState.Fetching,
    sliceAttachmentsLoading: sliceAttachmentStore.fetched === FetchingState.Fetching,
    buildAttachmentsLoading: buildAttachmentStore.fetched === FetchingState.Fetching,
    recipientsLoading: recipientStore.fetched === FetchingState.Fetching,
    defectNotificationsLoading: defectNotificationStore.fetched === FetchingState.Fetching,
    sensorProfile: sensorProfileStore.byId[draftBuild.sensorProfileUuid!],
    machine: machineStore.byId[draftBuild.machineUuid!],
    device: deviceStore.byId[draftBuild.deviceSerial!],
    machineParameters: machineParametersStore.byId[draftBuild.machineParametersUuid!],
    batch: batchStore.byId[draftBuild.batchUuid!],
    sliceAttachments: Object.values(sliceAttachmentStore.byId).filter(
      (sliceAttachment) => sliceAttachment.buildUuid === draftBuild.uuid && !sliceAttachment.deletedAt
    ),
    buildAttachments: Object.values(buildAttachmentStore.byId).filter(
      (buildAttachment) => buildAttachment.buildUuid === draftBuild.uuid && !buildAttachment.deletedAt
    ),
    recipients: Object.values(recipientStore.byId)
      .filter((recipient) => recipient.buildUuid === draftBuild.uuid)
      .map((recipient) => ({
        ...recipient,
        user: userStore.byId[recipient.userUuid!],
      })),
    defectNotifications: Object.values(defectNotificationStore.byId).filter(
      (defectNotification) => defectNotification.buildUuid === draftBuild.uuid
    ),
  };
};

const ConfirmationTable = () => {
  const {draftBuild, setDraftWithSave} = useDraftBuild();
  const isXsScreen = useExtraSmallScreenSize();

  const baseUrl = `/builds/uuid/${draftBuild.uuid}/draft/advanced`;

  const {
    sensorProfileLoading,
    machineLoading,
    deviceLoading,
    batchLoading,
    machineParametersLoading,
    sliceAttachmentsLoading,
    buildAttachmentsLoading,
    recipientsLoading,
    defectNotificationsLoading,
    sensorProfile,
    machine,
    device,
    batch,
    machineParameters,
    sliceAttachments,
    buildAttachments,
    recipients,
    defectNotifications,
  } = useConfirmationData(draftBuild);

  return (
    <>
      <FixedTable>
        <colgroup>
          <col span={1} width={isXsScreen ? '96px' : '174px'} />
          <col span={1} width="auto" />
          <col span={1} width="48px" />
        </colgroup>
        <TableBody>
          <ConfirmationRow field="Build Name" loading={!draftBuild} url={`${baseUrl}/configuration`}>
            {draftBuild.name}
          </ConfirmationRow>
          <ConfirmationRow field="Machine" loading={machineLoading} url={`${baseUrl}/configuration`}>
            {machine?.name || <RequiredText />}
          </ConfirmationRow>
          <ConfirmationRow field="Sensor profile" loading={sensorProfileLoading} url={`${baseUrl}/configuration`}>
            {sensorProfile?.name || <RequiredText />}
          </ConfirmationRow>
          <ConfirmationRow field="Device" loading={deviceLoading} url={`${baseUrl}/configuration`}>
            {device?.deviceId || <RequiredText />}
          </ConfirmationRow>
          <ConfirmationRow field="Batch" loading={batchLoading} url={`${baseUrl}/materials`}>
            {batch ? <Link to={`/batches/uuid/${batch.uuid}`}>{batch?.name}</Link> : <RequiredText />}
          </ConfirmationRow>
          <ConfirmationRow
            field="Machine Parameters"
            loading={machineParametersLoading}
            url={`${baseUrl}/parameters`}
            collapseComponent={<MachineParametersCollapse machineParameters={machineParameters} />}
          >
            {machineParameters ? machineParameters.name : <OptionalText />}
          </ConfirmationRow>
          <ConfirmationRow
            field="Uploaded Files"
            loading={sliceAttachmentsLoading || buildAttachmentsLoading}
            url={`${baseUrl}/upload`}
            collapseComponent={
              <SliceFilesCollapse sliceAttachments={sliceAttachments} buildAttachments={buildAttachments} />
            }
          >
            {sliceAttachments.length > 0 ? (
              `${sliceAttachments.length} Slice ${inflect('file', sliceAttachments.length)}, ${
                buildAttachments.length
              } Other ${inflect('file', buildAttachments.length)}`
            ) : (
              <RequiredText />
            )}
          </ConfirmationRow>
          <ConfirmationRow
            field="Notification Recipients"
            loading={recipientsLoading}
            url={`${baseUrl}/notifications`}
            collapseComponent={<RecipientsCollapse recipients={recipients} />}
          >
            {recipients.length > 0 ? (
              `${recipients.length} ${inflect('Recipient', recipients.length)}`
            ) : (
              <OptionalText />
            )}
          </ConfirmationRow>
          <ConfirmationRow
            field="Notifications Filters"
            loading={defectNotificationsLoading}
            url={`${baseUrl}/notifications`}
            collapseComponent={<DefectNotificationsCollapse defectNotifications={defectNotifications} />}
          >
            {defectNotifications.length > 0 ? (
              `${defectNotifications.length} Defect ${inflect('notification', defectNotifications.length)} filters`
            ) : (
              <OptionalText />
            )}
          </ConfirmationRow>
          <LastRow>
            <TableCell>
              <b>Description</b>
            </TableCell>
            <TableCell colSpan={2}>
              <TextField
                label="Description"
                fullWidth
                minRows={6}
                maxRows={8}
                variant="outlined"
                multiline
                value={draftBuild.description}
                onChange={(e) =>
                  setDraftWithSave((build) => ({
                    ...build,
                    description: e.target.value,
                  }))
                }
              />
            </TableCell>
          </LastRow>
        </TableBody>
      </FixedTable>
    </>
  );
};

export default ConfirmationTable;

const OptionalText = () => {
  return (
    <Typography color="textSecondary" component="span">
      <i>(Optional)</i>
    </Typography>
  );
};
const RequiredText = ({text}: {text?: string}) => {
  return (
    <Typography color="error" component="span">
      <i>({text ? text : 'Required'})</i>
    </Typography>
  );
};

const FixedTable = styled(Table)`
  table-layout: fixed;

  td,
  th {
    padding: 8px;
  }
`;

const LastRow = styled(TableRow)`
  td {
    vertical-align: baseline;
    padding-top: 24px;
    border-bottom: none;
  }
`;
