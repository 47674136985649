import {ValidateFn, generateValidationResult, combineValidation} from '../../../types';
import {IBatch} from './IBatch';

/**
 * Validate the input name for this batch of material.
 * @param name {string} - Name of this batch
 */
export const validateBatchName: ValidateFn<string, string> = function (name) {
  if (name.length < 1) {
    const errorMessage = ['Please enter a batch name'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate the quantity value for this batch of material.
 * @param quantity {number} - Quantity of this batch of material
 */
export const validateBatchQuantity: ValidateFn<number, string> = function (quantity) {
  const errorMessages = [];
  if (quantity < 0) {
    errorMessages.push('Please enter a batch quantity value above or equal to 0');
  }
  // TODO: Check if quantity is less than the material quantity received amount
  if (errorMessages.length > 0) {
    return generateValidationResult(false, errorMessages);
  }
  return generateValidationResult(true);
};

/**
 * Validate all parameters of a batch.
 * @param batch {IBatch} - Contains information about a batch
 */
export const validateBatch: ValidateFn<Partial<IBatch>, string> = function (batch) {
  return combineValidation(validateBatchName(batch.name), validateBatchQuantity(batch.quantity));
};
