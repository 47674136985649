import React from 'react';
import {cloneDeep} from 'lodash';
import {useSelector} from 'react-redux';
import {Grid, Typography} from '@material-ui/core';
import {ShowChart} from '@material-ui/icons';
import ScatterChart from '../../../components/atoms/Charts/ScatterChart';
import LoadingPage from '../../../components/organisms/LoadingPage';
import {HoveredSelection} from '../BuildIntensityPage';
import {useIntensityGraphData} from './intensityGraphHelpers';
import {RootState} from '../../../store/reducers';
import {GraphDataType} from './GraphOptionsModal';

const IntensityGraph = ({
  d3Color,
  hoveredSelection,
}: {
  d3Color: d3.scale.Ordinal<string, string>;
  hoveredSelection: HoveredSelection;
}) => {
  const {graphData, isLoading, noneSelected} = useIntensityGraphData(d3Color);
  const graphDataType = useSelector((state: RootState) => state.buildIntensityReportStore.graphDataType);

  if (noneSelected) return <NoPartsSelected />;
  if (isLoading) return <LoadingPage loadingText="Loading statistics..." />;

  const maxLayerNum = Math.max(...graphData.flatMap((data) => data.x as number[]));

  const finalGraphData = cloneDeep(graphData);

  if (hoveredSelection) {
    finalGraphData.forEach((data, index) => {
      const isHovered =
        hoveredSelection.type === data.customdata?.[0] && hoveredSelection.uuid === data.customdata?.[1];
      if (!isHovered) {
        if (finalGraphData[index].marker) {
          finalGraphData[index].marker!.color = '#b0aeae22';
        } else if (finalGraphData[index].line) {
          finalGraphData[index].line!.color = '#b0aeae22';
          finalGraphData[index].fillcolor = '#b0aeae22';
        }
      }
    });
  }

  return (
    <ScatterChart
      data={finalGraphData}
      layout={{
        margin: {r: 10, l: 50, t: 20, b: 40, pad: 0},
        hovermode: 'x unified',
        xaxis: {title: 'Layer', showgrid: false, zeroline: false, range: [0, maxLayerNum + 5]},
        yaxis: {
          title: [GraphDataType.MaxCount, GraphDataType.TotalCount].includes(graphDataType)
            ? 'Pixel count'
            : 'Intensity',
          showline: false,
          rangemode: 'tozero',
        },
      }}
    />
  );
};

export default IntensityGraph;

export const NoPartsSelected = ({height}: {height?: number | undefined}) => {
  return (
    <Grid
      style={{
        height: height ? `${height}` : '100%',
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <ShowChart />
      <Typography>Select data to display</Typography>
    </Grid>
  );
};
