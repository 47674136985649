// Map a function over the values of an object
export const objMap = <T, U>(object: {[key: string]: T}, transform: (key: string, value: T) => U): {[key: string]: U} =>
  Object.fromEntries(Object.entries(object).map(([key, value]) => [key, transform(key, value)]));

// Map a function over the values of an object, using the original object's values as keys.
// Useful for mapping enums, so that the values of the enum become keys of the result.
export const objMapValues = <T, U>(
  object: {[key: string]: T},
  transform: (key: string, value: T) => U
): {[key: string]: U} =>
  Object.fromEntries(Object.entries(object).map(([key, value]) => [value, transform(key, value)]));

// Apply a function to the values of an object
export const objForEach = <T>(object: {[key: string]: T}, func: (key: string, value: T) => void) =>
  Object.entries(object).forEach(([key, value]) => func(key, value));

// Filter object key-value pairs using a predicate function
export const objFilter = <T extends {}, U>(object: T, predicate: (key: keyof T, value: U) => boolean): T =>
  Object.fromEntries(
    Object.entries(object).filter(([key, value]) => predicate(key as unknown as keyof T, value as U))
  ) as T;
