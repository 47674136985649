import {useMemo, useState, useEffect} from 'react';
import styled from 'styled-components';

/**
 * Using the Intersection Observer API to detect when an element (ref) is
 * visible on the page (within it's scrollable container)
 * MDM Docs: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 * Source: https://bobbyhadz.com/blog/react-check-if-element-in-viewport
 */
export const useIsInViewport = (ref: React.RefObject<HTMLDivElement>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export const LoadMoreSensor = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
  p {
    margin-left: 12px;
  }
`;
