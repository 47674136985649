import {View3DViewportParams} from '../View3DViewport';
import {useEffect, useState} from 'react';
import {PartPointClouds} from '../PartPointCloud';
import {usePartPointClouds} from './usePartPointClouds';
import {ICTReport} from '@common/api/models/builds/data/ICTReport';
import {PointCloud2} from '../types/pointCloudV2';
import {PointCloud3} from '../types/pointCloudV3';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {toast} from 'react-toastify';
import {ctReportDownloadModelPointCloudURLsGET} from '../../../../../api/ajax/ctReport';
import {sphereGeometry, ThreePoints, View3DState} from '../types/pointCloudTypes';
import {loadPointCloudAsMesh} from '../loaders/meshLoaders';
import {loadPointCloudAsPoints} from '../loaders/pointLoaders';
import {centerPointCloud} from '../viewportFunctions/control';
import {downloadCTPointCloud} from '../loaders/downloadPointClouds';

export const useCTPointClouds = (currentReport: ICTReport, params: View3DViewportParams, renderScene: () => void) => {
  const [viewportState, setViewportState] = useState<View3DState>('loading');

  const {
    viewportState: geometryViewportState,
    pointClouds: geometryPointClouds,
    sceneBounds,
  } = usePartPointClouds([currentReport.partUuid], params.selectedAnalysisTypes, params, renderScene);

  const [pointClouds] = useState(() => new PartPointClouds());

  const loadPointCloudWrapper = async (pointCloud: PointCloud2 | PointCloud3) => {
    const result = params.use3DPoints
      ? loadPointCloudAsMesh(pointCloud, AnalysisType3D.Model, params, sphereGeometry)
      : loadPointCloudAsPoints(pointCloud, AnalysisType3D.Model, params);

    if (!result.success) {
      toast(result.error, {type: 'error'});
      return;
    }

    const points = result.object as ThreePoints;

    // Center CT Point Cloud For Alignment
    centerPointCloud(points, false, true);

    pointClouds.addPointCloud(currentReport.partUuid, AnalysisType3D.CTModel, points);
  };

  const downloadPointCloudWrapper = (url: string) => {
    return downloadCTPointCloud(url, loadPointCloudWrapper, () => {
      toast('Could not download 3D data', {type: 'error'});
      setViewportState('failed');
    });
  };

  const loadCTPointClouds = async () => {
    const response = await ctReportDownloadModelPointCloudURLsGET(currentReport.uuid);

    if (!response.success || !response.data.length) {
      setViewportState('unavailable');
      return;
    }

    setViewportState('loading');

    Promise.all(response.data.map(({url}) => downloadPointCloudWrapper(url))).then(() => setViewportState('viewing'));
  };

  useEffect(() => {
    if (params.availableParts.length > 0) {
      loadCTPointClouds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport.uuid, params.availableParts]);

  useEffect(() => {
    if (geometryViewportState === 'viewing' && viewportState === 'viewing') {
      setTimeout(() => renderScene(), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometryViewportState, viewportState]);

  useEffect(() => {
    if (viewportState === 'viewing' && geometryViewportState === 'viewing') {
      const original = geometryPointClouds.getPointClouds(currentReport.partUuid, AnalysisType3D.Model)[0];
      const points = original.clone();
      // Cloning does some weird inversion to the rotation (0, 3.14, 0) becomes (-3.14, 3.14, -3.14). Can cause the desired rotation to be wrong.
      points.rotation.set(original.rotation.x, original.rotation.y, original.rotation.z);
      points.userData = original.userData;
      points.renderOrder = 2;
      pointClouds.addPointCloud(currentReport.partUuid, AnalysisType3D.Model, points);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometryViewportState, viewportState]);

  const combinedViewportState =
    geometryViewportState === 'loading' || viewportState === 'loading' ? 'loading' : viewportState;

  return {
    viewportState: combinedViewportState as View3DState,
    viewportLoading: viewportState === 'loading' || geometryViewportState === 'loading',
    pointClouds,
    sceneBounds,
  };
};
