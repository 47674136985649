import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Button, Card, CardContent, Grid, Typography} from '@material-ui/core';
import {Videocam} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import {DeviceHosts} from '@common/api/models/devices/IDevice';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {RootState} from '../../../../store/reducers/index';
import useWebcamDetails, {AllWebcamHosts} from '../shared/useWebcamDetails';

export const SideBarWebcamFeed = ({webcamHosts}: {webcamHosts?: DeviceHosts}) => {
  const {uuid} = useParams<{uuid: string}>();
  const build = useSelector((state: RootState) => state.buildStore.byId[uuid]);
  const [showAllUrls, setShowAllUrls] = useState(false);
  const {webcamURL, setWebcamURL, tooltipTitle, hostsToShow} = useWebcamDetails(build, webcamHosts);

  return (
    <Grid item xs={12} sm={12}>
      <Card>
        {/* padding needed to remove padding-bottom: 24px; on last-child */}
        <CardContent style={{padding: '16px'}}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" align="center" style={{margin: 5}}>
              Webcam Feed
            </Typography>
            <ConditionalTooltip tooltip={tooltipTitle} hideTooltip={!!webcamURL}>
              <Button
                variant="contained"
                color="primary"
                href={webcamURL}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<Videocam />}
                style={{margin: 5}}
                disabled={!webcamURL}
                onClick={() => setShowAllUrls(true)}
              >
                View Live Feed
              </Button>
            </ConditionalTooltip>
          </Grid>
          {showAllUrls && (
            <Alert severity="info" onClose={() => setShowAllUrls(false)} style={{marginTop: '12px'}}>
              <AllWebcamHosts webcamURL={webcamURL} hostsToShow={hostsToShow} setWebcamURL={setWebcamURL} />
            </Alert>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
