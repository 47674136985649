import validator from 'validator';
import {combineValidation, ValidateFn} from '../../types';
import {IUser} from './IUser';

export const validateEmail: ValidateFn<string | undefined, string> = function (email, isOnPremise?: boolean) {
  // Allow on-premise emails to just be a username, also can be an email.
  if (isOnPremise && !email.includes('@')) {
    return {success: true};
  }
  if (!!email && validator.isEmail(email)) {
    return {success: true};
  } else {
    return {success: false, errors: ['Email is invalid']};
  }
};

// TODO: i18n
export enum PasswordRules {
  AtLeast8Chars = 'At Least 8 Characters',
  OneLowercaseLetter = 'One lowercase letter',
  OneUppercaseLetter = 'One uppercase letter',
  OneNumber = 'One number',
  OneSpecialCharacter = 'One special character, like $!#%*@()....',
  OneSpecialCharacterOrNumber = 'One number or special character such as (!@#$%^&*)',
}

export const validatePassword: ValidateFn<string, PasswordRules> = function (password) {
  const errors: PasswordRules[] = [];
  if (`${password}`.length < 8) {
    errors.push(PasswordRules.AtLeast8Chars);
  }

  if (!password.match(/[a-z]/)) {
    errors.push(PasswordRules.OneLowercaseLetter);
  }
  if (!password.match(/[A-Z]/)) {
    errors.push(PasswordRules.OneUppercaseLetter);
  }
  if (!password.match(/[0-9]/)) {
    errors.push(PasswordRules.OneNumber);
  }
  if (!password.match(/[^a-zA-Z0-9 ]/)) {
    errors.push(PasswordRules.OneSpecialCharacter);
  }
  if (!password.match(/[0-9]/) && !password.match(/[^a-zA-Z0-9 ]/)) {
    errors.push(PasswordRules.OneSpecialCharacterOrNumber);
  }

  return errors.length ? {success: false, errors} : {success: true};
};

export const validateFirstName: ValidateFn<string, string> = function (firstName) {
  if (firstName.length < 1) return {success: false, errors: ['Please enter a first name']};

  // \p{L} represents any unicode letter, including international letters
  if (!firstName.match(/^(\p{L}|\s|-|')+$/iu))
    return {
      success: false,
      errors: ['First name must contain only letters, spaces, dashes and apostrophes'],
    };

  return {success: true};
};

export const validateLastName: ValidateFn<string, string> = function (lastName) {
  if (lastName.length < 1) return {success: false, errors: ['Please enter a last name']};

  // \p{L} represents any unicode letter, including international letters
  if (lastName.length > 0 && !lastName.match(/^(\p{L}|\s|-|')+$/iu))
    return {
      success: false,
      errors: ['Last name must contain only letters, dashes and apostrophes'],
    };

  return {success: true};
};

export const validateUser: ValidateFn<IUser, string> = function (user) {
  return combineValidation(
    validateEmail(user.email),
    validateFirstName(user.firstName),
    validateLastName(user.lastName)
  );
};
