import {Pagination} from '@common/api/models/common';

export enum LocationBasedDefectsStatus {
  Submitted = 'submitted',
  Generating = 'generating',
  Success = 'success',
  Failure = 'failure',
}

export interface ILocationBasedDefectsReport {
  uuid: string;
  name: string;
  reportDir?: string;
  createdAt: Date;
  status: LocationBasedDefectsStatus;
  organizationUuid: string;
}

export interface ILocationBasedDefectsBuildsLink {
  locationBasedDefectsReportUuid: string;
  buildUuid: string;
}

export type ILocationBasedDefectsReportSortOptions = 'createdAt' | 'status';

export interface ILocationBasedDefectsReportsGETRequest extends Pagination {
  uuid: string | null;
  name: string | null;
  status: LocationBasedDefectsStatus | null;
}

export interface ILocationBasedDefectsReportGETResponse extends Omit<ILocationBasedDefectsReport, 'reportDir'> {
  builds: Array<{name: string; uuid: string}>;
}

export interface ILocationBasedDefectsReportPOSTRequest {
  name: string;
  buildUuids: string[];
}

export interface ILocationBasedDefectsReportPATCHRequest {
  name: string;
}

export interface ILocationBasedDefectsReportUrlsResponse {
  filename: string;
  signedUrl: string;
}
