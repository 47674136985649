import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography as MuiTypography,
  IconButton,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Skeleton} from '@material-ui/lab';
import {spacing} from '@material-ui/system';
import {Add, Edit} from '@material-ui/icons';
import styled from 'styled-components';

import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {IDevice} from '@common/api/models/devices/IDevice';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IUser, Role} from '@common/api/models/users/IUser';
import {useDeviceStoreActions, useMachineStoreActions} from '../../../../store/actions';
import {RootState} from '../../../../store/reducers';
import {FetchingState} from '../../../../store/model/liveUpdateStore';
import {MachineImage, MachineStatusChip} from '../../../../components/molecules/avatars/MachineAvatar';
import {MachineSelectorButton} from '../../../../components/molecules/Selector/MachineSelectorButton';
import BuildDescriptionPanel from './BuildConfiguration/BuildDescriptionPanel';
import {useDraftBuild} from '../DraftBuildContext';
import {useSmallScreenSize, usePermissionsForBuild} from '../../../../utils/utilHooks';
import ChangeBuildProvisionerModal from '../../../../components/molecules/Modals/ChangeBuildProvisionerModal';
import {userSearchGET} from '../../../../api/ajax/users';
import {sensorProfilesGET} from '../../../../api/ajax/sensorProfile';
import {useHistory} from 'react-router-dom';

const Typography = styled(MuiTypography)(spacing);

const BuildConfigurationStep = () => {
  const isSmallScreen = useSmallScreenSize();
  const {draftBuild, setDraftWithSave} = useDraftBuild();

  const [loading, setLoading] = useState(true);
  const deviceActions = useDeviceStoreActions();
  const machineStoreActions = useMachineStoreActions();

  const deviceStore = useSelector((s: RootState) => s.deviceStore);
  const machineStore = useSelector((state: RootState) => state.machineStore);

  const device = deviceStore.byMachineUuid[draftBuild.machineUuid!];
  const machine = machineStore.byId[draftBuild.machineUuid!];

  useEffect(() => {
    if (draftBuild.machineUuid) {
      machineStoreActions.ensureConsistent({uuid: draftBuild.machineUuid});
      deviceActions.ensureConsistent({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftBuild.machineUuid]);

  useEffect(() => {
    // After the initial load, don't go into loading if the device store is
    // fetching. Doing so will make clicking the machine selector fail the
    // first time it's clicked (due to refetching the deviceStore)
    const machineFetching = machineStore.fetched === FetchingState.Fetching;
    const machineOrDeviceFetching = deviceStore.fetched === FetchingState.Fetching || machineFetching;

    setLoading((loading) => (loading ? machineOrDeviceFetching : machineFetching));
  }, [deviceStore.byId, deviceStore.fetched, machineStore.fetched]);

  const handleChange = (name: keyof IBuild, value: any) => {
    setDraftWithSave((build) => {
      return {
        ...build,
        [name]: value,
      };
    });
  };

  const handleMachineSelected = (machine: IMachine | null) => {
    handleChange('machineUuid', machine?.uuid || null);
    const device = machine ? deviceStore.byMachineUuid[machine.uuid] : null;
    handleChange('deviceSerial', device?.serial || null);
    handleChange('sensorProfileUuid', device?.sensorProfileUuid || null);
  };

  const handleProfileSelected = (profileUuid: string) => {
    handleChange('sensorProfileUuid', profileUuid);
  };

  return (
    <React.Fragment>
      <Grid container spacing={isSmallScreen ? 3 : 6}>
        <Grid item lg={12}>
          <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Step 1: Configuration</Typography>
        </Grid>

        <Grid item xs={12} style={{marginTop: isSmallScreen ? '12px' : undefined}}>
          <TextField
            label="Build Name*"
            variant="outlined"
            fullWidth
            value={draftBuild.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <ProvisionerDetails />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={!!machine ? 7 : 11}>
          <MachineTable
            build={draftBuild}
            machine={machine}
            device={device}
            loading={loading}
            handleMachineSelected={handleMachineSelected}
            handleProfileSelected={handleProfileSelected}
          />
        </Grid>

        {machine && device && (
          <Grid item xs={12} sm={12} md={12} lg={!!machine ? 5 : 1}>
            <ImageContainer>
              <MachineImage machine={machine} device={device} isLoading={loading} />
            </ImageContainer>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BuildDescriptionPanel value={draftBuild.description ?? ''} handleChange={handleChange} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BuildConfigurationStep;

const ProvisionerDetails = () => {
  const {draftBuild} = useDraftBuild();
  const [provisioner, setProvisioner] = useState<IUser>();
  const [changeProvisionerOpen, setChangeProvisionerOpen] = useState<boolean>(false);
  const currentUser = useSelector((state: RootState) => state.auth.user!);

  useEffect(() => {
    const fetchProvisionerDetails = async (provisionerUuid: string) => {
      const res = await userSearchGET(' ', {uuid: provisionerUuid});
      if (res.success) {
        setProvisioner(res.data[0]);
      }
    };

    if (draftBuild?.provisionerUuid) {
      setProvisioner(undefined);
      fetchProvisionerDetails(draftBuild.provisionerUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftBuild?.provisionerUuid]);

  return (
    <>
      {provisioner && (
        <ChangeBuildProvisionerModal
          open={changeProvisionerOpen}
          closeDialog={() => setChangeProvisionerOpen(false)}
          buildUuid={draftBuild.uuid}
          provisioner={provisioner}
        />
      )}
      {provisioner ? (
        <Box display="flex" alignItems="center">
          <Typography variant="h6" style={{marginRight: '12px'}}>
            Provisioner:
          </Typography>
          <Typography>{`${provisioner.firstName} ${provisioner.lastName}`}</Typography>
          {(currentUser.role >= Role.MANAGER || currentUser.uuid === draftBuild.provisionerUuid) && (
            <IconButton aria-label="edit" onClick={() => setChangeProvisionerOpen(true)}>
              <Edit />
            </IconButton>
          )}
        </Box>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

const MachineTableRow = ({fieldVariant = 'subtitle2', field, value, hideBorder}: any) => {
  const hideBorderStyle = hideBorder ? {border: 'none'} : {};
  if (!value)
    return (
      <TableRow>
        <TableCell style={{paddingLeft: '0px', ...hideBorderStyle}} colSpan={2}>
          {<Typography variant={fieldVariant}>{field}</Typography>}
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow>
      <TableCell style={{paddingLeft: '0px', ...hideBorderStyle}}>
        {<Typography variant={fieldVariant}>{field}</Typography>}
      </TableCell>
      <TableCell style={hideBorderStyle}>{value}</TableCell>
    </TableRow>
  );
};

export const MachineTable = ({
  machine,
  device,
  build,
  loading,
  handleMachineSelected,
  handleProfileSelected,
  isStaging,
}: {
  machine: IMachine;
  device: IDevice;
  build: IBuild;
  loading: boolean;
  handleMachineSelected?: (machine: IMachine | null) => void;
  handleProfileSelected?: (profileUuid: string) => void;
  isStaging?: boolean;
}) => {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const {machinePermission} = usePermissionsForBuild(build);

  return (
    <Table size={isSmallScreen ? 'small' : 'medium'}>
      <TableBody>
        {loading ? (
          [...Array(4)].map((_val, itemNum) => (
            <MachineTableRow field={<Skeleton />} value={<Skeleton />} key={`machine-config-skeleton-${itemNum}`} />
          ))
        ) : (
          <>
            {!!handleMachineSelected && (
              <MachineTableRow
                fieldVariant={isSmallScreen ? 'h6' : 'h4'}
                field="Machine"
                hideBorder
                value={
                  <Box maxWidth="300px" mb={2}>
                    <MachineSelectorButton
                      machine={machine}
                      onMachineSelected={handleMachineSelected}
                      label="Change Machine"
                      cantViewCurrentMachine={!!build.machineUuid && !machinePermission}
                      fullWidthButton
                    />
                  </Box>
                }
              />
            )}
            {!!handleProfileSelected && !!machine && !!device?.serial && (
              <MachineTableRow
                fieldVariant={isSmallScreen ? 'h6' : 'h4'}
                field="Sensor Profile"
                hideBorder
                value={
                  <Box maxWidth="348px" mb={2} display="flex" alignItems="center">
                    <Box maxWidth="300px" flex="1">
                      <SelectSensorProfile
                        selectedProfileUuid={build.sensorProfileUuid}
                        deviceSerial={device.serial}
                        handleProfileSelected={handleProfileSelected}
                        defaultProfileUuid={device.sensorProfileUuid}
                      />
                    </Box>
                    <Box width="48px">
                      <Tooltip title={<Typography>New sensor profile</Typography>}>
                        <IconButton
                          onClick={() => history.push(`/sensorProfiles/new/${device.serial}`)}
                          color="primary"
                        >
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                }
              />
            )}
            {!machine ? (
              <MachineTableRow
                field={
                  !!build.machineUuid && !machinePermission
                    ? "You don't have permission to access the currently selected machine."
                    : 'No Machine currently selected.'
                }
                fieldVariant="body1"
                hideBorder
              />
            ) : (
              <></>
            )}

            {machine && (
              <>
                {!isStaging && <MachineTableRow field="Name" value={`${machine.name} `} />}
                {!isStaging && (
                  <MachineTableRow field="Status" value={<MachineStatusChip device={device} machine={machine} />} />
                )}
                <MachineTableRow
                  field="Device ID"
                  value={device?.deviceId || <Typography color="error">No Device attached!</Typography>}
                />
                <MachineTableRow field="Machine Model" value={machine.model} />
                <MachineTableRow field="Location" value={machine.location} />
              </>
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((_theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      width: 'calc(100% - 14px - 28px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export function SelectSensorProfile({
  selectedProfileUuid,
  deviceSerial,
  handleProfileSelected,
  defaultProfileUuid,
}: {
  selectedProfileUuid?: string;
  deviceSerial?: string;
  defaultProfileUuid?: string;
  handleProfileSelected: (profileUuid: string) => void;
}) {
  const classes = useStyles();
  const [options, setOptions] = useState<{value: string; text: string}[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchProfileOptions() {
      const res = await sensorProfilesGET({deviceSerial});
      if (res.success) {
        setOptions(
          res.data.map((profile) => ({
            value: profile.uuid,
            text: `${profile.name}${defaultProfileUuid === profile.uuid ? ' (default)' : ''}`,
          }))
        );
      }
      setLoading(false);
    }
    setOptions([]);
    if (deviceSerial) {
      setLoading(true);
      fetchProfileOptions();
    }
  }, [deviceSerial, defaultProfileUuid]);

  if (loading) return <Skeleton />;

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      size="small"
      value={selectedProfileUuid}
      label="Sensor Profile*"
      classes={classes}
      onChange={(e) => handleProfileSelected(e.target.value)}
      disabled={!deviceSerial}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </TextField>
  );
}

export const ImageContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  height: 100%;
  img {
    height: auto;
  }

  @media (max-width: 600px) {
    max-width: 50vw;
  }

  .MuiAvatar-root {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
