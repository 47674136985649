import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal as MuiModal,
  ModalProps,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../../store/reducers';
import {useBatchStoreActions} from '../../../store/actions';
import {
  BatchEventData,
  BatchEventType,
  CustomEventData,
  IBatch,
  MeasurementEventData,
  RetireEventData,
  SieveEventData,
  TestEventData,
} from '@common/api/models/materials/batches/IBatch';
import {BatchSelectorButton} from '../Selector/BatchSelectorButton';
import {batchEventPOST} from '../../../api/ajax/batches';
import {assertUnreachable, cloneSimple} from '@common/utils/utils';
import CustomButton from '../../atoms/CustomButton';
import RequiredAsterisk from '../../atoms/Texts/RequiredAsterisk';

export interface BatchEventModalProps extends Partial<ModalProps> {
  open: boolean;
  initialBatchUuid: string | undefined;
}

// FIXME: Refactor the below event field props
interface SieveEventFieldsProps {
  data: SieveEventData;
  setData: (e: BatchEventData) => any;
}

interface MeasurementEventFieldsProps {
  data: MeasurementEventData;
  setData: (e: BatchEventData) => any;
}

interface EventFieldsProps {
  batch: IBatch | undefined;
  eventData: BatchEventData;
  setEventData: (e: BatchEventData) => any;
}

const ModalCard = styled(Card)`
  position: absolute;
`;

const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
`;

export function SieveEventFields(props: SieveEventFieldsProps) {
  const {data, setData} = props;

  return (
    <Table size={'small'}>
      <TableBody>
        <TableRow>
          <TableCell align="left" variant="head">
            Sieve Name <RequiredAsterisk />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type="string"
              required
              value={data.sieveName}
              onChange={(e) => setData({...data, sieveName: e.target.value})}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" variant="head">
            Sieve Type <RequiredAsterisk />
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type="string"
              required
              value={data.sieveType}
              onChange={(e) => setData({...data, sieveType: e.target.value})}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" variant="head">
            Mesh Size
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type="string"
              value={data.sieveUnits}
              onChange={(e) => setData({...data, sieveUnits: e.target.value})}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" variant="head">
            Sieve Equipment S/N
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type="string"
              value={data.sieveEquipmentSN}
              onChange={(e) =>
                setData({
                  ...data,
                  sieveEquipmentSN: e.target.value,
                })
              }
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" variant="head">
            Lost quantity
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type="number"
              inputProps={{
                min: 0,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              value={data.lostQuantity}
              onChange={(e) =>
                setData({
                  ...data,
                  lostQuantity: Number(e.target.value),
                })
              }
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function MeasurementEventFields(props: MeasurementEventFieldsProps) {
  const {data, setData} = props;

  return (
    <Table size={'small'}>
      <TableBody>
        <TableRow>
          <TableCell align="left" variant="head">
            Quantity
          </TableCell>
          <TableCell>
            <TextField
              fullWidth
              type="number"
              value={data.quantity}
              inputProps={{
                min: 0,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
              }}
              onChange={(e) =>
                setData({
                  ...data,
                  quantity: Number(e.target.value),
                })
              }
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function EventFields(props: EventFieldsProps) {
  const {batch, eventData, setEventData} = props;

  if (!batch) {
    return (
      <Typography>
        <i>Please select a batch</i>
      </Typography>
    );
  }

  // FIXME: Create components for each case to slim down the size of this function
  switch (eventData.type) {
    case BatchEventType.SIEVE:
      return <SieveEventFields data={eventData} setData={setEventData} />;
    case BatchEventType.RETIRE:
      return (
        <Typography variant="h3">
          <i>Are you sure you want to retire this batch?</i>
        </Typography>
      );
    case BatchEventType.MEASUREMENT:
      return <MeasurementEventFields data={eventData} setData={setEventData} />;
    case BatchEventType.INITIAL:
    case BatchEventType.ADD:
    case BatchEventType.MERGE:
    case BatchEventType.SPLIT:
    case BatchEventType.BUILD:
      throw new Error('Cannot do that here');
    default:
      return null;
  }
}

function BatchEventModal(props: BatchEventModalProps) {
  const batchStoreActions = useBatchStoreActions();
  const {...rest} = props;

  const batchStore = useSelector((state: RootState) => {
    return state.batchStore;
  });

  const history = useHistory();

  const [selectedBatchUuid, setSelectedBatchUuid] = useState<string | undefined>(props.initialBatchUuid);
  const [batch, setBatch] = useState<IBatch | undefined>(undefined);
  const [event, setEvent] = useState<BatchEventType>(BatchEventType.SIEVE);
  const [sieveEventData, setSieveEventData] = useState<SieveEventData>({
    type: BatchEventType.SIEVE,
    sieveName: '',
    lostQuantity: 0,
    sieveEquipmentSN: '',
    sieveType: '',
    sieveUnits: '',
  });

  useEffect(() => {
    if (selectedBatchUuid) {
      batchStoreActions.ensureConsistent({uuid: selectedBatchUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatchUuid]);

  const [testEventData, setTestEventData] = useState<TestEventData>({
    type: BatchEventType.TEST,
    testType: '',
    testDescription: '',
    results: '',
    attachmentUuids: [],
    quantityConsumed: 0,
  });
  const [retireEventData, setRetireEventData] = useState<RetireEventData>({
    type: BatchEventType.RETIRE,
  });
  const [customEventData, setCustomEventData] = useState<CustomEventData>({
    type: BatchEventType.CUSTOM,
    description: '',
    quantityConsumed: 0,
    attachmentUuids: [],
  });
  const [measurementEventData, setMeasurementEventData] = useState<MeasurementEventData>({
    type: BatchEventType.MEASUREMENT,
    quantity: 0,
  });

  let eventData: BatchEventData = undefined as any;
  let setEventData: (e: BatchEventData) => any = undefined as any;
  switch (event) {
    case BatchEventType.SIEVE:
      eventData = sieveEventData;
      setEventData = setSieveEventData as any;
      break;
    case BatchEventType.RETIRE:
      eventData = retireEventData;
      setEventData = setRetireEventData as any;
      break;
    case BatchEventType.TEST:
      eventData = testEventData;
      setEventData = setTestEventData as any;
      break;
    case BatchEventType.CUSTOM:
      eventData = customEventData;
      setEventData = setCustomEventData as any;
      break;
    case BatchEventType.MEASUREMENT:
      eventData = measurementEventData;
      setEventData = setMeasurementEventData as any;
      break;
    case BatchEventType.INITIAL:
    case BatchEventType.ADD:
    case BatchEventType.MERGE:
    case BatchEventType.SPLIT:
    case BatchEventType.BUILD:
      throw new Error("Shouldn't be here");
    default:
      assertUnreachable(event);
  }

  useEffect(() => {
    setSelectedBatchUuid(props.initialBatchUuid);
  }, [props.initialBatchUuid]);

  useEffect(() => {
    const batch = cloneSimple(selectedBatchUuid ? batchStore.byId[selectedBatchUuid] : undefined);
    if (batch) {
      setBatch(batch);
    }
  }, [selectedBatchUuid, batchStore.byId, batchStore.fetched]);

  useEffect(() => {
    // Default value of measurement field to current batch size
    if (batch && event === BatchEventType.MEASUREMENT) {
      const updatedEventData: MeasurementEventData = {
        type: BatchEventType.MEASUREMENT,
        quantity: batch.quantity,
      };
      setEventData(updatedEventData);
    }
  }, [batch, event, setEventData]);

  const onSubmit = async () => {
    if (!selectedBatchUuid) {
      toast('No batch selected', {type: 'warning'});
      return;
    }

    const res = await batchEventPOST(selectedBatchUuid, eventData);
    props.onClose && props.onClose({}, 'escapeKeyDown');
    if (res.success) {
      history.push('/batches/uuid/' + res.data.uuid + '/');
    }
  };

  const canSubmit = () => {
    if (!selectedBatchUuid || !batch) {
      return false;
    }

    switch (event) {
      case BatchEventType.SIEVE:
        // Fields are not empty
        if (!sieveEventData.sieveName || !sieveEventData.sieveType) {
          return false;
        }

        if (sieveEventData.lostQuantity < 0) {
          return false;
        }
        if (sieveEventData.lostQuantity > batch.quantity) {
          return false;
        }
        break;
      case BatchEventType.RETIRE:
        break;
      case BatchEventType.MEASUREMENT:
        if (measurementEventData.quantity < 0) {
          return false;
        }
        break;
      case BatchEventType.CUSTOM:
      case BatchEventType.TEST:
        if (testEventData.quantityConsumed < 0) {
          return false;
        }
        if (testEventData.quantityConsumed > batch.quantity) {
          return false;
        }
        break;
      default:
        assertUnreachable(event);
    }
    return true;
  };

  return (
    <Modal {...rest}>
      <ModalCard style={{maxWidth: 700}}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item sm={12} md={12} lg={12}>
              <h2>Select a batch, and an event to create.</h2>
            </Grid>
            <Grid item xs={6}>
              <BatchSelectorButton
                placeholder={'Choose a batch...'}
                onSelect={(b) => {
                  b && setSelectedBatchUuid(b.uuid);
                }}
                selectedBatch={batchStore.byId[selectedBatchUuid!]}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl style={{minWidth: 150}}>
                <InputLabel id="batch-event-type-select-label">Event Type</InputLabel>
                <Select
                  labelId="batch-event-type-select-label"
                  value={event}
                  fullWidth
                  onChange={(e) => setEvent(e.target.value as BatchEventType)}
                >
                  <MenuItem value={BatchEventType.SIEVE}>Sieve</MenuItem>
                  <MenuItem value={BatchEventType.MEASUREMENT}>Measurement</MenuItem>
                  <MenuItem value={BatchEventType.RETIRE}>Retire</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <form id="eventFieldsForm">
                <EventFields key={event} batch={batch} eventData={eventData} setEventData={setEventData} />
              </form>
            </Grid>

            <Grid item sm={12} md={12} lg={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item></Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button color="primary" onClick={() => props.onClose && props.onClose({}, 'escapeKeyDown')}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <CustomButton
                        type="submit"
                        form="eventFieldsForm"
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        disabled={!canSubmit()}
                        text="Confirm and Submit"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </ModalCard>
    </Modal>
  );
}

export default BatchEventModal;
//export default withTheme(BatchSplitModal) as (props: BatchMergeModalProps) => any;
