export enum AlertSeverity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  CRITICAL = 'critical',
}

export interface IAlert {
  uuid: string;
  deviceSerial: string;
  buildUuid?: string;
  date: Date;
  severity: AlertSeverity;
  message: string;
}

export interface IAlertGETRequest {
  uuid: string | undefined;
  deviceSerial: string | undefined;
  buildUuid: string | undefined;
  date: Date | undefined; // TODO: rename to since
  severity: AlertSeverity | undefined;
  message: string | undefined;
}

export interface IAlertGETResponse {
  uuid: string;
  date: Date;
  severity: AlertSeverity;
  message: string;
  deviceSerial: string;
  buildUuid?: string;
}
