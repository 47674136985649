import React, {useEffect, useState} from 'react';
import {Grid, ModalProps, TextField, Typography, InputAdornment, Divider as MuiDivider} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

import {RootState} from '../../../store/reducers';
import {useBatchStoreActions} from '../../../store/actions';
import {batchesSplitPOST} from '../../../api/ajax/batches';
import {BATCH_DESCRIPTION_LIMIT, BATCH_NAME_LIMIT, IBatch} from '@common/api/models/materials/batches/IBatch';
import {splitBatches} from '@common/api/models/materials/batches/Batch.utils';
import {cloneSimple} from '@common/utils/utils';
import {BatchSelectorCard} from '../Selector/BatchSelectorCard';
import {GenericDialog} from '../DialogButton';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';

const Divider = styled(MuiDivider)(spacing);

export interface BatchSplitModalProps extends Partial<ModalProps> {
  open: boolean;
  initialBatchUuid?: string;
  onClose: () => void;
  onSuccess?: () => void;
}

export function BatchSplitModal(props: BatchSplitModalProps) {
  const batchStoreActions = useBatchStoreActions();

  const batchStore = useSelector((state: RootState) => {
    return state.batchStore;
  });

  const [selectedBatchUuid, setSelectedBatchUuid] = useState<string | undefined>(props.initialBatchUuid);
  const [batch, setBatch] = useState<IBatch | undefined>(undefined);
  const [splitBatchA, setSplitBatchA] = useState<IBatch | undefined>(undefined);
  const [splitBatchB, setSplitBatchB] = useState<IBatch | undefined>(undefined);
  const [quantityA, setQuantityA] = useState('');
  const [quantityB, setQuantityB] = useState('');
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    if (selectedBatchUuid) {
      batchStoreActions.ensureConsistent({uuid: selectedBatchUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatchUuid]);

  useEffect(() => {
    setSelectedBatchUuid(props.initialBatchUuid);
  }, [props.initialBatchUuid]);

  useEffect(() => {
    const batchToSplit = cloneSimple(selectedBatchUuid ? batchStore.byId[selectedBatchUuid] : undefined);
    if (batchToSplit) {
      // useEffect triggered but no need to update as the batch to split is the same.
      if (batchToSplit.uuid === batch?.uuid) return;

      setBatch(batchToSplit);
      const [batchA, batchB] = splitBatches(batchToSplit, batchToSplit.quantity / 2);
      setSplitBatchA(batchA);
      setSplitBatchB(batchB);
      setQuantityA((batchToSplit.quantity / 2).toString());
      setQuantityB((batchToSplit.quantity / 2).toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchStore.byId, batchStore.fetched, selectedBatchUuid]);

  const onSubmit = async () => {
    if (!selectedBatchUuid) {
      toast('Must choose a batch', {type: 'warning'});
      return;
    }

    if (!splitBatchA || !splitBatchB) {
      toast('Split batch missing', {type: 'error'});
      return;
    }
    setRequesting(true);

    const res = await batchesSplitPOST(selectedBatchUuid, splitBatchA.quantity, splitBatchA, splitBatchB);
    batchStoreActions.invalidateFetchHistory();
    setRequesting(false);
    if (res.success) {
      if (props.onSuccess) {
        props.onSuccess();
      } else {
        props.onClose();
      }
    }
  };

  const onAQtyChange = () => {
    if (batch && splitBatchA && splitBatchB) {
      setQuantityB((batch.quantity - Number(quantityA)).toString());
      setSplitBatchA({...splitBatchA, quantity: Number(quantityA)});
      setSplitBatchB({
        ...splitBatchB,
        quantity: batch.quantity - Number(quantityA),
      });
    }
  };

  const onBQtyChange = () => {
    if (batch && splitBatchA && splitBatchB) {
      setQuantityA((batch.quantity - Number(quantityB)).toString());
      setSplitBatchA({
        ...splitBatchA,
        quantity: batch.quantity - Number(quantityB),
      });
      setSplitBatchB({...splitBatchB, quantity: Number(quantityB)});
    }
  };

  const isSplitValid = () => {
    if (!splitBatchA || !splitBatchB || !batch) return false;
    if (splitBatchA.quantity <= 0) return false;
    if (splitBatchB.quantity <= 0) return false;
    if (splitBatchA.name.length > BATCH_NAME_LIMIT) return false;
    if (splitBatchB.name.length > BATCH_NAME_LIMIT) return false;
    if ((splitBatchA.description?.length || 0) > BATCH_DESCRIPTION_LIMIT) return false;
    if ((splitBatchB.description?.length || 0) > BATCH_DESCRIPTION_LIMIT) return false;
    return true;
  };

  return (
    <GenericDialog
      maxWidth="sm"
      fullWidth
      title="Split Batch"
      isOpen={props.open}
      closeDialog={props.onClose}
      onSuccess={onSubmit}
      confirmText="Split Batch"
      confirmDisabled={!isSplitValid()}
      requestInProgress={requesting}
      content={
        <Grid container direction="column" style={{width: '100%'}}>
          <Grid item xs={12} style={{padding: '10px 0px'}}>
            <BatchSelectorCard selectedBatchUuid={selectedBatchUuid} setSelectedBatchUuid={setSelectedBatchUuid} />
          </Grid>
          {splitBatchA && splitBatchB && batch && (
            <Grid item style={{padding: '10px 0px'}}>
              <Grid container direction="row" justifyContent="space-between" spacing={3}>
                <Grid item xs={5}>
                  <SplitBatchDetails
                    batchLetter="A"
                    onQtyChange={onAQtyChange}
                    quantity={quantityA}
                    setQuantity={setQuantityA}
                    setSplitBatch={setSplitBatchA}
                    splitBatch={splitBatchA}
                  />
                </Grid>
                <Grid item container xs={1} justifyContent="center">
                  <Divider orientation="vertical" />
                </Grid>

                <Grid item xs={5}>
                  <SplitBatchDetails
                    batchLetter="B"
                    onQtyChange={onBQtyChange}
                    quantity={quantityB}
                    setQuantity={setQuantityB}
                    setSplitBatch={setSplitBatchB}
                    splitBatch={splitBatchB}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
    ></GenericDialog>
  );
}

function SplitBatchDetails({
  batchLetter,
  splitBatch,
  setSplitBatch,
  quantity,
  setQuantity,
  onQtyChange,
}: {
  batchLetter: string;
  splitBatch: IBatch;
  setSplitBatch: Function;
  quantity: string;
  setQuantity: Function;
  onQtyChange: () => void;
}) {
  const isXsScreen = useExtraSmallScreenSize();

  return (
    <Grid container direction="column" spacing={isXsScreen ? 3 : 4}>
      <Grid item>
        <Typography variant="h6">Batch {batchLetter}</Typography>
      </Grid>
      <Grid item>
        <TextField
          value={quantity}
          type="number"
          label={`Quantity`}
          onBlur={() => onQtyChange()}
          fullWidth
          variant="outlined"
          onChange={(e) => setQuantity(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
          }}
          size="small"
        />
      </Grid>
      <Grid item>
        <TextField
          type="text"
          fullWidth
          label={`Name`}
          value={splitBatch.name}
          onChange={(e) =>
            setSplitBatch({
              ...splitBatch,
              name: e.target.value,
            })
          }
          variant="outlined"
          size="small"
          error={splitBatch.name.length > BATCH_NAME_LIMIT}
          helperText={
            !!splitBatch.name && splitBatch.name.length > BATCH_NAME_LIMIT
              ? 'Name must be less than 60 chars'
              : undefined
          }
        />
      </Grid>
      <Grid item>
        <TextField
          type="text"
          fullWidth
          multiline
          variant="outlined"
          maxRows={5}
          label={`Description`}
          value={splitBatch.description}
          onChange={(e) =>
            setSplitBatch({
              ...splitBatch,
              description: e.target.value,
            })
          }
          size="small"
          error={!!splitBatch.description && splitBatch.description.length > BATCH_DESCRIPTION_LIMIT}
          helperText={
            !!splitBatch.description && splitBatch.description.length > BATCH_DESCRIPTION_LIMIT
              ? `Description must be less than ${BATCH_DESCRIPTION_LIMIT} chars`
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
}

export default BatchSplitModal;
