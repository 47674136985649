import {BUTTON_WIDTH, BUTTON_MARGIN} from '../utils';
import {Button, Grid, IconButton, TextField} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import React, {useState, useEffect, useRef} from 'react';

export interface GotoLayerInputProps {
  onSubmit: (value: number) => any;
  initialValue: number;
  onClose: () => any;
  stageHeight: number;
}

export function GotoLayerInput(props: GotoLayerInputProps) {
  const [value, setValue] = useState<string>('' + props.initialValue);

  const handleClick = () => {
    props.onSubmit(Number(value));
    props.onClose();
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      props.onSubmit(Number(value));
      props.onClose();
    }
  };

  const textField = useRef() as any;
  useEffect(() => {
    textField.current.select();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        right: BUTTON_WIDTH + BUTTON_MARGIN + 24,
        top: props.stageHeight - BUTTON_WIDTH * 4.5,
        padding: '2px',
        backgroundColor: '#ffffff',
      }}
    >
      <Grid container spacing={3}>
        <Grid item>
          <TextField
            inputRef={textField}
            style={{width: 80}}
            size="small"
            type="number"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </Grid>
        <Grid item>
          <Button size="small" onClick={handleClick} variant="contained" color="primary">
            Go
          </Button>
        </Grid>
        <Grid item>
          <IconButton size="small" onClick={handleClick}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
