import {LiveStoreReducers} from '../liveUpdateStore';
import {LiveStoreState} from '../../model/liveUpdateStore';
import createReducer from '../createReducer';
import {ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {CTReportActions, CTReportStoreState, CTReportUpdateViewportTransformAction} from '../../model/ctReport';

export class CTReportLiveReducers extends LiveStoreReducers<ICTReportGETResponse, CTReportStoreState> {
  additionalReducers() {
    return {
      updateViewportTransforms(
        state: CTReportStoreState,
        {payload: {viewportTransform}}: CTReportUpdateViewportTransformAction
      ): CTReportStoreState {
        return {
          ...state,
          viewportTransform,
        };
      },
    };
  }

  createReducer(initialState: CTReportStoreState) {
    const superReducers = this.bindReducers();
    const additionalReducers = this.additionalReducers();

    return createReducer<LiveStoreState<ICTReportGETResponse>>(initialState, {
      ...superReducers,
      [CTReportActions.UPDATE_VIEWPORT_TRANSFORMS]: additionalReducers.updateViewportTransforms,
    });
  }
}
