import React, {useState} from 'react';
import {Grid, Typography, Box, Popover, Button} from '@material-ui/core';
import {Control, useWatch} from 'react-hook-form';
import {cloneDeep, set} from 'lodash';
import {
  LogicalOperator,
  IDefectNotificationConditions,
  ConditionGroup,
} from '@common/api/models/builds/data/defects/IDefectNotifications';

function DisplayAndChangeOperator({
  path,
  control,
  reset,
}: {
  path: string;
  control: Control<IDefectNotificationConditions>;
  reset: (newForm: IDefectNotificationConditions) => void;
}) {
  const wholeForm: IDefectNotificationConditions | undefined = useWatch({control}) as IDefectNotificationConditions;
  const conditionGroup: ConditionGroup | undefined = useWatch({
    name: path,
    control: control,
  });

  const [anchorEl, setAnchorEl] = useState<Element>();
  const [popperOpen, setPopperOpen] = useState(false);

  const currentOperator = path === '' ? wholeForm.operator : conditionGroup?.operator;
  const conditions = path === '' ? wholeForm.conditionGroups : conditionGroup?.conditions;

  function changeOperator(selectedOperator?: LogicalOperator) {
    if (!selectedOperator) {
      setPopperOpen(true);
      return;
    }

    const newForm = cloneDeep(wholeForm!);

    if (path === '') {
      newForm.operator = selectedOperator;
    } else {
      const newConditionGroup = {
        operator: selectedOperator,
        conditions: conditions,
      };

      set(newForm, path, newConditionGroup);
    }
    reset(newForm);
    setPopperOpen(false);
  }

  return (
    <Grid item xs={12} style={{marginTop: '8px'}}>
      <Box display="flex" justifyContent="center" width="100%">
        <Button
          variant="outlined"
          color="primary"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            changeOperator();
          }}
        >
          {currentOperator?.toLocaleUpperCase()}
        </Button>

        <Popover open={popperOpen} onClose={() => setPopperOpen(false)} anchorEl={anchorEl} style={{cursor: 'pointer'}}>
          <Typography style={{padding: '8px 16px'}} onClick={() => changeOperator(LogicalOperator.AND)}>
            AND
          </Typography>
          <Typography style={{padding: '8px 16px'}} onClick={() => changeOperator(LogicalOperator.OR)}>
            OR
          </Typography>
        </Popover>
      </Box>
    </Grid>
  );
}

export default DisplayAndChangeOperator;
