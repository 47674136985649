import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {
  ISimilarityReportTableGETResponse,
  ISimilarityReportPOSTRequest,
  ISimilarityReportGETResponse,
  ISimilarityReportPOSTResponse,
  ISimilarityReportPATCHRequest,
  ISimilarityComparisonGETRequest,
  ISimilarityComparisonGETResponse,
  ISimilarityDownloadUrlResponse,
} from '@common/api/models/builds/data/ISimilarity';
import {ISimilarityReport} from '@common/api/models/builds/data/ISimilarity';
import {ISimilarityBuildsGETRequest} from '@common/api/models/builds/data/ISimilarity';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function similarityReportAllGET(
  filter: QueryFilter<ISimilarityReport>
): AjaxApiResult<ISimilarityReportTableGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/reports/similarity/' + (query && '?' + query));
  }, 'Could not fetch similarity reports');
}

export async function similarityReportSingleGET(uuid: string): AjaxApiResult<ISimilarityReportGETResponse[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/similarity/${uuid}`);
  }, 'Could not fetch similarity report');
}

export async function similarityComparisonGET(
  uuid: string,
  filter: QueryFilter<ISimilarityComparisonGETRequest>
): AjaxApiResult<ISimilarityComparisonGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get(`/api/reports/similarity/${uuid}/comparisons` + (query && '?' + query));
  }, 'Could not fetch comparisons for similarity report');
}

export async function similarityReportBuildsGET(
  uuid: string,
  filter: QueryFilter<ISimilarityBuildsGETRequest>
): AjaxApiResult<ISimilarityReportGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get(`/api/reports/similarity/${uuid}/builds` + (query && '?' + query));
  }, 'Could not fetch builds for similarity report');
}

export async function similarityReportPOST(
  similarityReport: ISimilarityReportPOSTRequest
): AjaxApiResult<ISimilarityReportPOSTResponse> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/similarity/', similarityReport);
  }, 'Could not create similarity report');
}

export async function similarityReportPATCH(
  uuid: string,
  updates: Partial<ISimilarityReportPATCHRequest>
): AjaxApiResult<ISimilarityReportPOSTResponse> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/reports/similarity/${uuid}`, updates);
  }, 'Could not update similarity report');
}

export async function similarityReportDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/similarity/${uuid}`);
  }, 'Could not delete similarity report');
}

export async function similarityComparisonDELETE(reportUuid: string, comparisonUuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/similarity/${reportUuid}/comparisons/${comparisonUuid}`);
  }, 'Could not delete similarity comparison');
}

export async function similarityComparisonsStatusGET(uuid: string): AjaxApiResult<ISimilarityReportGETResponse[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/similarity/${uuid}/comparisons/status`);
  }, 'Could not get progress for similarity report');
}

export async function similarityComparisonResultUrlGET(
  reportUuid: string,
  comparisonUuid: string
): AjaxApiResult<ISimilarityDownloadUrlResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/similarity/${reportUuid}/comparisons/${comparisonUuid}/downloadResultsCsv/`);
  }, 'Could not get progress for similarity report');
}

export async function similarityComparisonPointCloudUrlsGET(
  reportUuid: string,
  comparisonUuid: string
): AjaxApiResult<ISimilarityDownloadUrlResponse[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/similarity/${reportUuid}/comparisons/${comparisonUuid}/pointCloudDownloadUrls/`);
  }, 'Could not get 3D point cloud for similarity report');
}
