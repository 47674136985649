import * as React from 'react';
import styled from 'styled-components';
import {CardContent, Grid, Card as MuiCard} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {spacing} from '@material-ui/system';
import {useActiveStep} from './ActiveStepContext';
import {useDraftBuild} from './DraftBuildContext';
import StepNavigationButtons from './StepNavigationButtons';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const Card = styled(MuiCard)(spacing);

const ActiveStep = () => {
  const isSmallScreen = useSmallScreenSize();
  const {activeStep} = useActiveStep();
  const {setDraftWithSave, draftBuild} = useDraftBuild();

  const ActiveComponent = activeStep?.component;

  return (
    <Card mb={6} padding={isSmallScreen ? 1 : 3}>
      <CardContent>
        <Grid container spacing={isSmallScreen ? 3 : 6}>
          <Grid item sm={12} md={12} lg={12} style={{width: '100%'}}>
            {activeStep ? (
              <ActiveComponent setState={setDraftWithSave} state={draftBuild} />
            ) : (
              <Alert color="warning">Choose a step from the left</Alert>
            )}
          </Grid>
          {!isSmallScreen && <StepNavigationButtons />}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActiveStep;
