import React from 'react';
import {Card, CardContent} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {MachinePageProps} from './index';
import {DeviceAlerts} from '../../builds/liveBuild/shared/DeviceAlerts';

export function MachineLog(props: MachinePageProps) {
  return (
    <Card>
      <CardContent>
        {!props.device && (
          <Alert style={{margin: '8px 0px'}} severity="warning">
            No Device Attached to this Machine
          </Alert>
        )}
        {!!props.device && <DeviceAlerts deviceSerial={props.device.serial} />}
      </CardContent>
    </Card>
  );
}
