import {ApiFailureResponse, ApiResponse, ApiSuccessResponse} from '@common/api/apiResult';
import {AxiosResponse} from 'axios';
import {isString} from 'lodash';
import {toast} from 'react-toastify';
import * as React from 'react';

type ApiResult<T> =
  | (ApiSuccessResponse<T> & {success: true})
  | (ApiFailureResponse & {success: false; status?: number});

export type AjaxApiResult<T> = Promise<ApiResult<T>>;

/**
 * Wrapper for ajax requests which shows toast if not 2xx response
 * @param fn callable axios request function
 * @param errorTitle optional, title for error message
 */
export async function ajaxWrapper<T>(
  fn: () => Promise<AxiosResponse<ApiResponse<T>>>,
  errorTitle?: string,
  redirectOnUnauthorized?: boolean
): AjaxApiResult<T> {
  let res: ApiResult<T>;
  try {
    res = {
      success: true,
      ...((await fn()).data as ApiSuccessResponse<T>),
    };
  } catch (e) {
    // Typescript hates this error
    const err: any = e;
    if (err.response) {
      // server response outside 2xx
      res = {
        success: false,
        message: err.response.data?.message,
        status: err.response.status,
      };
      // If not logged in, reload (back to login)
      if (err.response.status === 401 && redirectOnUnauthorized !== false) {
        window.location.reload();
        return res;
      }
    } else {
      res = {success: false, message: err.message};
      console.error(err.message);
    }

    if (errorTitle) {
      toast(
        <React.Fragment>
          <b>{errorTitle}</b>
          <br />
          <ErrorMessages errors={res.message} />
        </React.Fragment>,
        {type: 'error', toastId: errorTitle}
      );
    }
  }

  return res;
}

const ErrorMessages = ({errors}: {errors: string | (string | object)[]}) => {
  if (!errors) return <></>;
  if (isString(errors)) return <>{errors}</>;

  if (errors.length <= 1) {
    const error = errors[0];

    if (isString(error)) return <>{errors}</>;
    return <ErrorMessages errors={Object.entries(error).map(([field, value]) => `${field}: ${value}`)} />;
  }

  return (
    <ul>
      {errors.map((error: string | object) => {
        return (
          <>
            {isString(error) ? (
              <li>{error}</li>
            ) : (
              Object.entries(error).map(([field, value]) => <li>{`${field}: ${value}`}</li>)
            )}
          </>
        );
      })}
    </ul>
  );
};
