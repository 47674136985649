import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Typography} from '@material-ui/core';
import {Clear, CloudDownload} from '@material-ui/icons';
import filesize from 'filesize';
import {MaterialTableProps} from 'material-table';
import React, {useState} from 'react';

import {ISliceAttachmentGETResponse} from '@common/api/models/attachments/ISliceAttachment';

import {dangerColor} from '../../../assets/jss/material-dashboard-react';
import {renderDateTimeString} from '../../../utils/string';
import StateSymbol from '../../atoms/Status/SliceAttachmentStatus';
import {CoolLinkedTable} from './CoolTable';
import {AjaxApiResult} from '../../../api/ajax/ajaxWrapper';
import {downloadUrl} from '../../../utils/webtools';

export interface SliceHistoryTableRow {
  filename: string;
  filesize: number;
  createdAt: Date;

  buildName: string;
  sliceAttachment: ISliceAttachmentGETResponse;

  index: number;
  url: string | undefined;
}

export interface SliceTableProps {
  sliceAttachments: ISliceAttachmentGETResponse[];
  errorMessage?: string;
  onRemove: (rows: SliceHistoryTableRow | SliceHistoryTableRow[]) => void;
  downloadUrlGET: (uuid: string) => AjaxApiResult<string>;
}

export type AttachmentTableProps = SliceTableProps & Partial<MaterialTableProps<SliceHistoryTableRow>>;

export function SliceHistoryTable(props: AttachmentTableProps) {
  const {sliceAttachments, errorMessage: _err, ...rest} = props;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [sliceFile, setSliceFile] = useState<SliceHistoryTableRow | undefined>();

  const handleCancel = () => {
    setShowConfirmDialog(false);
  };
  const handleConfirmation = async () => {
    setShowConfirmDialog(false);
    props.onRemove(sliceFile as SliceHistoryTableRow);
  };

  return (
    <>
      <CoolLinkedTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        columns={[
          {title: 'Name', field: 'filename', width: '30%'},
          {
            title: 'Build Name',
            field: 'buildName',
            width: '20%',
          },
          {
            title: 'State',
            field: 'state',
            width: 80,
            render: (row: SliceHistoryTableRow) => <StateSymbol sliceAttachment={row.sliceAttachment} />,
          } as any,
          {
            title: 'Size',
            field: 'size',
            type: 'numeric',
            render: (r: SliceHistoryTableRow) => filesize(r.filesize),
          },
          {
            title: 'Date Added',
            field: 'registered',
            type: 'date',
            emptyValue: '',
            render: (row: SliceHistoryTableRow) =>
              row.createdAt && <Typography>{renderDateTimeString('short', row.createdAt)}</Typography>,
          },
        ]}
        actions={[
          {
            icon: () => <CloudDownload />,
            tooltip: 'Download',
            onClick: async (e, r) => {
              const arg = Array.isArray(r) ? r : [r];
              for (const f of arg) {
                const url = await props.downloadUrlGET(f.sliceAttachment.uuid);
                if (url.success) {
                  downloadUrl(url.data, f.sliceAttachment.filename);
                }
              }
            },
          },
          {
            icon: () => <Clear />,
            tooltip: 'Remove',
            onClick: (e, r) => {
              setSliceFile(r as SliceHistoryTableRow);
              setShowConfirmDialog(true);
            },
          },
        ]}
        data={sliceAttachments
          .map((attachment: ISliceAttachmentGETResponse, index): SliceHistoryTableRow => {
            return {
              filename: attachment.filename,
              filesize: attachment.filesize,
              createdAt: attachment.createdAt,
              sliceAttachment: attachment,
              buildName: (attachment as any).build.name, // fixme
              url: '',
              index,
            };
          })
          .flat()}
        options={{
          search: false,
          pageSize: 10,
          actionsColumnIndex: 5,
          actionsCellStyle: {
            width: '50px',
          },
          ...props.options,
        }}
        {...rest}
      />
      <RemoveSliceDialog
        isOpen={showConfirmDialog}
        closeDialog={handleCancel}
        title={sliceFile?.filename}
        onSuccess={handleConfirmation}
      />
    </>
  );
}

interface RemoveSliceDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  onSuccess: () => void;
  title?: string;
}

function RemoveSliceDialog(props: RemoveSliceDialogProps) {
  const {isOpen, closeDialog, title, onSuccess} = props;
  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={closeDialog}>
      <DialogTitle id="confirmation-dialog-title">
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>Delete slice file</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>Do you wish to delete the slice file {title && `- ${title}`}?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDialog} color="primary">
          Cancel
        </Button>

        <Button onClick={onSuccess} color="primary" variant={'contained'} style={{backgroundColor: dangerColor[0]}}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
