import React, {useState} from 'react';
import {Box, Button, CircularProgress} from '@material-ui/core';
import {DeviceDetailedState, DeviceState} from '@common/api/models/devices/IDevice';
import {IBuild} from '@common/api/models/builds/IBuild';
import {buildCalibrateGET, buildStopGET, buildsByUuidPATCH} from '../../../../../api/ajax/builds';
import ConditionalTooltip from '../../../../../components/atoms/Texts/ConditionalTooltip';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import DownloadCalibration from './DownloadCalibration';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {useCameraState} from '../../shared/CameraState';

const buttonStyle = (isSmallScreen: boolean) => {
  return isSmallScreen ? {marginTop: '12px'} : {marginLeft: '12px'};
};

export const ActionButtons = ({
  deviceStateDetailed,
  deviceState,
  build,
  machine,
  hasCalibrated,
  calibrationAvailable,
  setCalibrationError,
  waitingForCalibration,
  setWaitingForCalibration,
  waitingForCalibrationToStop,
  setWaitingForCalibrationToStop,
  isCurrentlyCalibrating,
}: {
  deviceStateDetailed: DeviceDetailedState;
  deviceState: DeviceState;
  build: IBuild;
  machine: IMachine;
  hasCalibrated: boolean;
  calibrationAvailable: boolean;
  setCalibrationError: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  waitingForCalibration: boolean;
  setWaitingForCalibration: React.Dispatch<React.SetStateAction<boolean>>;
  waitingForCalibrationToStop: boolean;
  setWaitingForCalibrationToStop: React.Dispatch<React.SetStateAction<boolean>>;
  isCurrentlyCalibrating: boolean;
}) => {
  const isSmallScreen = useSmallScreenSize();

  const failedCalibration = build.hasCalibrated && !build.calibrationUuid;

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      mt={isSmallScreen ? 1 : 4}
      flexDirection={isSmallScreen ? 'column' : 'row'}
    >
      <DownloadCalibration machine={machine} />
      {failedCalibration && !isCurrentlyCalibrating && !!calibrationAvailable && (
        <UseLastCalibrationButton buildUuid={build.uuid} />
      )}
      {isCurrentlyCalibrating ? (
        <>
          <CancelCalibrationButton
            deviceStateDetailed={deviceStateDetailed}
            buildUuid={build.uuid}
            waitingForCalibrationToStop={waitingForCalibrationToStop}
            setWaitingForCalibrationToStop={setWaitingForCalibrationToStop}
          />
        </>
      ) : (
        <StartNewCalibrationButton
          buildUuid={build.uuid}
          deviceSerial={build.deviceSerial!}
          deviceState={deviceState}
          startAgain={hasCalibrated}
          setCalibrationError={setCalibrationError}
          waitingForCalibration={waitingForCalibration}
          setWaitingForCalibration={setWaitingForCalibration}
        />
      )}
    </Box>
  );
};

export const UseLastCalibrationButton = ({buildUuid}: {buildUuid: string}) => {
  const isSmallScreen = useSmallScreenSize();
  const [isRequesting, setIsRequesting] = useState(false);

  async function handleUseLastCalibration() {
    setIsRequesting(true);
    await buildsByUuidPATCH(buildUuid, {hasCalibrated: false});
    setIsRequesting(false);
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={handleUseLastCalibration}
      style={buttonStyle(isSmallScreen)}
      disabled={isRequesting}
      fullWidth={isSmallScreen}
      size={isSmallScreen ? 'small' : 'medium'}
    >
      Use last calibration {isRequesting ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
    </Button>
  );
};

const CancelCalibrationButton = ({
  deviceStateDetailed,
  buildUuid,
  waitingForCalibrationToStop,
  setWaitingForCalibrationToStop,
}: {
  deviceStateDetailed: DeviceDetailedState;
  buildUuid: string;
  waitingForCalibrationToStop: boolean;
  setWaitingForCalibrationToStop: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isSmallScreen = useSmallScreenSize();

  const handleStopCalibration = () => {
    setWaitingForCalibrationToStop(true);
    buildStopGET(buildUuid);
  };

  const isStopping = deviceStateDetailed === DeviceDetailedState.CalibratingStopping;

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={handleStopCalibration}
      style={buttonStyle(isSmallScreen)}
      disabled={isStopping || waitingForCalibrationToStop}
      fullWidth={isSmallScreen}
      size={isSmallScreen ? 'small' : 'medium'}
    >
      Cancel {waitingForCalibrationToStop ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
    </Button>
  );
};

const StartNewCalibrationButton = ({
  buildUuid,
  deviceState,
  deviceSerial,
  startAgain,
  waitingForCalibration,
  setWaitingForCalibration,
  setCalibrationError,
}: {
  buildUuid: string;
  deviceSerial: string;
  deviceState: DeviceState;
  startAgain: boolean;
  waitingForCalibration: boolean;
  setWaitingForCalibration: React.Dispatch<React.SetStateAction<boolean>>;
  setCalibrationError: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const {allCamerasConnected} = useCameraState(deviceSerial!);

  const currentlyFocusing = deviceState === DeviceState.FOCUSING || deviceState === DeviceState.PREVIEWING;

  const handleStartCalibration = async () => {
    setWaitingForCalibration(true);
    const res = await buildCalibrateGET(buildUuid);
    if (!res.success) {
      setWaitingForCalibration(false);
    } else {
      setCalibrationError(false);
    }
  };

  return (
    <ConditionalTooltip
      hideTooltip={!currentlyFocusing && allCamerasConnected}
      tooltip={
        !allCamerasConnected
          ? 'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before starting calibration.'
          : 'Please stop device focusing first, or wait for it to complete'
      }
    >
      <Button
        color="primary"
        variant={startAgain ? 'outlined' : 'contained'}
        onClick={handleStartCalibration}
        style={buttonStyle(isSmallScreen)}
        disabled={currentlyFocusing || waitingForCalibration || !allCamerasConnected}
        fullWidth={isSmallScreen}
        size={isSmallScreen ? 'small' : 'medium'}
      >
        Start New Calibration{' '}
        {waitingForCalibration ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
      </Button>
    </ConditionalTooltip>
  );
};
