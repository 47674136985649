import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {IRecipient} from '@common/api/models/builds/IRecipient';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function recipientsAllGET(filter: QueryFilter<IRecipient>): AjaxApiResult<IRecipient[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/recipients/', {params: filter});
  }, 'Could not fetch recipients');
}

export async function recipientsCreatePOST(recipient: Partial<IRecipient>): AjaxApiResult<IRecipient> {
  return ajaxWrapper(() => {
    return axios.post('/api/recipients/', recipient);
  }, 'Could not create recipient');
}

export async function recipientsByUuidDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/recipients/${uuid}`);
  }, 'Could not delete build recipient');
}

export async function recipientsByUuidPATCH(uuid: string, updates: Partial<IRecipient>): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/recipients/${uuid}`, updates);
  }, 'Could not patch build recipient');
}
