import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Box, Grid, Tooltip, Typography} from '@material-ui/core';
import {Help} from '@material-ui/icons';
import {grayColor} from '../../assets/jss/material-dashboard-react';
import {useSmallScreenSize} from '../../utils/utilHooks';
import BuildIntensityListView from './BuildIntensityListView';
import BuildIntensityGraphView from './BuildIntensityGraphView';
import BuildIntensitySummaryBarView from './BuildIntensitySummaryBarView';
import Header from '../../components/organisms/Header';
import Plotly from 'plotly.js';

const INTENSITY_HELP_TEXT = `Intensity reports are a compilation of image statistics for each 
part in a build based on their grey value. This process uses the slice file(s) to generate a 
CSV file for each part with its image statistics. A summary CSV is also generated that combines 
data from all parts in a build. For more information please contact the support team.`;

const StyledGrid = styled(Grid)`
  flex: 1;
  min-height: 768px;
`;

export type HoveredSelection = {type: 'part' | 'build'; uuid: string} | null;
export type SetHoveredSelection = React.Dispatch<React.SetStateAction<HoveredSelection>>;

const BuildIntensityPage = () => {
  const isSmallScreen = useSmallScreenSize();
  const [hoveredSelection, setHoveredSelection] = useState<HoveredSelection>(null);

  const d3Color = useMemo(
    () =>
      Plotly.d3.scale
        .ordinal<string>()
        .domain(Array.from({length: 20}, (_, i) => i.toString()))
        .range([
          '#1f77b4',
          '#ff7f0e',
          '#2ca02c',
          '#d62728',
          '#9467bd',
          '#8c564b',
          '#e377c2',
          '#7f7f7f',
          '#bcbd22',
          '#17becf',
          '#aec7e8',
          '#ffbb78',
          '#98df8a',
          '#ff9896',
          '#c5b0d5',
          '#c49c94',
          '#f7b6d2',
          '#c7c7c7',
          '#dbdb8d',
          '#9edae5',
        ]),
    []
  );

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Header
        helmet="Intensity Reports"
        title={
          <Typography variant="h4" style={{display: 'inline-flex', alignItems: 'center'}}>
            Intensity Reports{' '}
            <Tooltip title={<Typography>{INTENSITY_HELP_TEXT}</Typography>}>
              <Help style={{color: grayColor[3], marginLeft: '12px'}} />
            </Tooltip>
          </Typography>
        }
        breadcrumbs={['Intensity Reports']}
      />

      <StyledGrid container spacing={isSmallScreen ? 2 : 4} style={isSmallScreen ? {minHeight: 'auto'} : {}}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Box display="flex" flexDirection="column" height={isSmallScreen ? '384px' : '100%'}>
            <BuildIntensityListView setHoveredSelection={setHoveredSelection} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={8} style={isSmallScreen ? {marginTop: '12px'} : {}}>
          <Box display="flex" flexDirection="column" height={isSmallScreen ? '768px' : '100%'}>
            <BuildIntensityGraphView d3Color={d3Color} hoveredSelection={hoveredSelection} />
            <br />
            <BuildIntensitySummaryBarView
              d3Color={d3Color}
              hoveredSelection={hoveredSelection}
              setHoveredSelection={setHoveredSelection}
            />
          </Box>
        </Grid>
      </StyledGrid>
    </Box>
  );
};
export default BuildIntensityPage;
