import React from 'react';
import {Tooltip} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {ViewerButton} from '../../FadingComponents';
import {BUTTON_MARGIN} from '../utils';

interface CloseViewProps {
  handleClose: () => void;
  mouseOn: boolean;
}

export default function CloseView({handleClose, mouseOn}: CloseViewProps) {
  return (
    <Tooltip title="Close" placement="left">
      <ViewerButton
        onClick={handleClose}
        style={{
          right: BUTTON_MARGIN,
          top: BUTTON_MARGIN,
          position: 'absolute',
        }}
        className={(!mouseOn && 'fade') || undefined}
      >
        <Close />
      </ViewerButton>
    </Tooltip>
  );
}
