import React from 'react';
import styled from 'styled-components';
import {Box, CardContent, Typography, Grid, Chip, CircularProgress} from '@material-ui/core';
import {Widgets} from '@material-ui/icons';
import {Alert, Skeleton} from '@material-ui/lab';
import {grayColor} from '../../../assets/jss/material-dashboard-react';
import {RootState} from '../../../store/reducers/index';
import {useSelector} from 'react-redux';

type TableTypes = 'part' | 'comparison';
export type TableRowType = {
  field: string;
  value: string | JSX.Element;
  loading?: boolean | undefined;
};

export const SelectedDetailsTable = ({
  tableType,
  rows,
  minRowWidth = '100%',
  loading,
}: {
  tableType: TableTypes;
  rows?: Array<TableRowType>;
  minRowWidth?: string;
  loading?: boolean;
}) => {
  const currentComparison = useSelector((state: RootState) => state.similarityReportStore.currentlyViewingComparison);
  const title = tableType === 'part' ? 'Part Details' : 'Comparison Details';

  if (loading) return <LoadingDetails />;
  if (!rows) return <NoneSelected tableType={tableType} title={title} />;

  return (
    <CardContent style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <Box display="flex" alignItems="center" marginBottom="12px">
        <Typography variant="h6">{title}</Typography>
        {tableType === 'comparison' && !!currentComparison && (
          <Chip
            label={`${currentComparison.targetPartName} / ${currentComparison.buildName}`}
            color="primary"
            size="small"
            style={{marginLeft: '12px'}}
          />
        )}
      </Box>
      {tableType === 'comparison' && !!currentComparison?.warningMessage && (
        <Box display="flex" alignItems="center" marginBottom="12px">
          <Alert severity="warning" style={{width: '100%'}}>
            {currentComparison.warningMessage}
          </Alert>
        </Box>
      )}
      <FlexTable style={{height: '100%', overflow: 'auto'}}>
        {rows.map((row, index) => (
          <FieldValue
            key={`${index}-${row.field}-${row.value}`}
            field={row.field}
            value={row.value}
            loading={row.loading}
            minRowWidth={minRowWidth}
          />
        ))}
      </FlexTable>
    </CardContent>
  );
};

export default SelectedDetailsTable;

const LoadingDetails = () => {
  return (
    <CardContent style={{height: '100%'}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{height: '100%', overflow: 'auto'}}
      >
        <CircularProgress size={30} />
      </Grid>
    </CardContent>
  );
};

const NoneSelected = ({tableType, title}: {tableType: TableTypes; title: string}) => {
  const noneSelectedText = tableType === 'part' ? 'No part selected' : 'No comparison selected';
  const selectPrompt =
    tableType === 'part' ? 'Select a part in the tree preview' : 'Select a completed comparison in the table';

  return (
    <CardContent style={{height: '100%'}}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {/* This code is very similar to the no part selected overlay in PartPreview3DViewport.tsx */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{height: '80%', overflow: 'auto'}}
      >
        <Grid item>
          <Widgets />
        </Grid>
        <Grid item>
          <Typography style={{fontSize: 30}} align="center">
            {noneSelectedText}
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">{selectPrompt}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};

const FieldValue = ({field, value, loading, minRowWidth}: TableRowType & {minRowWidth: string}) => (
  <FlexRow minRowWidth={minRowWidth}>
    {/* 150px appears to work nicely on this table, don't be afraid to change this value in the future */}
    <Box style={{width: 150, minWidth: 150}}>
      <b>{field}</b>
    </Box>

    <Box overflow="hidden">{loading ? <Skeleton /> : <Box display="flex">{value}</Box>}</Box>
  </FlexRow>
);

const FlexTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
`;

const FlexRow = styled.div<{minRowWidth: string}>`
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  padding: 12px;
  border-bottom: 1px solid ${grayColor[4]};
  min-width: ${({minRowWidth}) => `max(${minRowWidth}, 280px)`};
`;
