import React from 'react';
import {Button, Typography} from '@material-ui/core';
import CenteredPage from './CenteredPage';
import {useHistory} from 'react-router-dom';

interface NotFoundPageProps {
  notFoundText: string;
  backToUrl?: string;
  returnText?: string | React.ReactNode;
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({notFoundText, backToUrl, returnText, children}) => {
  const history = useHistory();

  return (
    <CenteredPage>
      <Typography variant="h3" align="center" style={{marginBottom: '32px'}}>
        {notFoundText}
      </Typography>
      {backToUrl && (
        <Button variant="contained" color="primary" onClick={() => history.push(backToUrl)}>
          {returnText}
        </Button>
      )}
      {children && children}
    </CenteredPage>
  );
};

export default NotFoundPage;
