import React from 'react';
import {Delete, NoteAdd, Visibility} from '@material-ui/icons';
import {Box, Tooltip, IconButton, Grid, Radio} from '@material-ui/core';
import {IMachineParameter} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {MachineParameterRowData} from '../../../../pages/builds/draftBuild/draftBuildSteps/MachineParametersStep';

export interface MachineParameter extends IMachineParameter {
  draftBuild?: IBuild;
}

const machineParameterTableSchema = [
  {
    title: 'Name',
    field: 'name',
    width: '200px',
    cellStyle: {minWidth: '124px'},
    render: (row: MachineParameterRowData) => {
      if (row.draftBuild) {
        return (
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item style={{minWidth: '42px'}}>
              <Radio checked={row.draftBuild.machineParametersUuid === row.uuid} />
            </Grid>
            <Grid item style={{overflow: 'hidden'}}>
              <EllipsisTextWithTooltip>{row.name}</EllipsisTextWithTooltip>
            </Grid>
          </Grid>
        );
      } else {
        return <EllipsisTextColumnTooltip>{row.name}</EllipsisTextColumnTooltip>;
      }
    },
  },
  {
    title: 'Created On',
    field: 'registered',
    sortField: 'registered',
    align: 'left',
    type: 'date',
    width: '148px',
    cellStyle: {minWidth: '124px'},
    emptyValue: '',
    render: (row: MachineParameterRowData) => <DateWithTimeTooltip timestamp={row.registered} />,
  },
  {
    title: 'Notes',
    field: 'genericNotes',
    align: 'left',
    type: 'date',
    width: 'auto',
    emptyValue: '',
    render: (row: MachineParameterRowData) => <EllipsisTextColumnTooltip>{row.genericNotes}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'Actions',
    width: '168px',
    sorting: false,
    render: (rowData: MachineParameterRowData) => <MachineParametersAction rowData={rowData} />,
  },
];

export default machineParameterTableSchema;

const MachineParametersAction = React.memo(({rowData}: {rowData: MachineParameterRowData}) => {
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="View">
          <IconButton
            aria-label="view"
            onClick={(event) => {
              event.stopPropagation();
              rowData.onView();
            }}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
        <Tooltip title="Create Copy">
          <IconButton
            aria-label="create copy"
            onClick={(event) => {
              event.stopPropagation();
              rowData.onClone();
            }}
          >
            <NoteAdd />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation();
              rowData.onDelete();
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
});
