import {Box, Checkbox, Divider, FormControlLabel, Grid, Typography} from '@material-ui/core';
import React from 'react';
import {ViewportSidebarProps} from './View3DViewportSidebar';
import {View3DViewportParams} from '../View3DViewport';
import {
  BackgroundColourPicker,
  DefectAreaSlider,
  MaxPointsToDisplaySlider,
  ModelOpacitySlider,
  PointColourPicker,
  PointSizeSlider,
  Use3DPointsToggle,
} from './RenderOptions';
import {MaterialColorPicker} from '../../../Picker/MaterialColorPicker';

export const CTAlignmentOptions = (props: ViewportSidebarProps<View3DViewportParams>) => {
  return (
    <Grid item>
      <Typography variant="subtitle1">Alignment Options</Typography>

      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name="showTransformHelper"
            checked={props.params.ctAlignmentHelperEnabled}
            onChange={(event) =>
              props.setParams({
                ...props.params,
                ctAlignmentHelperEnabled: event.target.checked,
              })
            }
          />
        }
        label="Show Transform Helper"
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name="showPart"
            checked={props.params.selectedAnalysisTypes.model}
            onChange={(event) =>
              props.setParams({
                ...props.params,
                selectedAnalysisTypes: {
                  ...props.params.selectedAnalysisTypes,
                  model: event.target.checked,
                },
              })
            }
          />
        }
        label="Show Original Part"
      />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name="showCTModel"
            checked={props.params.selectedAnalysisTypes.ctModel}
            onChange={(event) =>
              props.setParams({
                ...props.params,
                selectedAnalysisTypes: {
                  ...props.params.selectedAnalysisTypes,
                  ctModel: event.target.checked,
                },
              })
            }
          />
        }
        label="Show CT Model"
      />

      <Divider style={{margin: '8px 0px'}} />

      <Typography variant="subtitle1">CT Model Options</Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <MaterialColorPicker
          currentValue={props.params.analysisTypeColorOverrides?.ctModel ?? '#b4b4b4'}
          onChange={(newColour) =>
            props.setParams({
              ...props.params,
              analysisTypeColorOverrides: {
                ctModel: '#' + newColour,
              },
            })
          }
          title="CT Model Colour"
        />
        <Checkbox
          color="primary"
          checked={props.params.overrideAnalysisTypeColors}
          onChange={(event) =>
            props.setParams({
              ...props.params,
              overrideAnalysisTypeColors: event.target.checked,
            })
          }
        />
      </Box>

      <Divider style={{margin: '8px 0px'}} />

      <Typography variant="subtitle1">Part Options</Typography>
      <Use3DPointsToggle {...props} />
      <PointSizeSlider {...props} />
      <DefectAreaSlider {...props} />
      <ModelOpacitySlider {...props} />
      <PointColourPicker {...props} />

      <Divider style={{margin: '8px 0px 14px 0px'}} />

      <MaxPointsToDisplaySlider {...props} />
      <BackgroundColourPicker {...props} />
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.params.showGrid}
              onChange={(event) =>
                props.setParams({
                  ...props.params,
                  showGrid: event.target.checked,
                })
              }
              name="showGrid"
            />
          }
          label="Show grid"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.params.showAxes}
              onChange={(event) =>
                props.setParams({
                  ...props.params,
                  showAxes: event.target.checked,
                })
              }
              name="showAxes"
            />
          }
          label="Show axes"
        />
      </Grid>
    </Grid>
  );
};
