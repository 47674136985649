import React from 'react';
import {BuildState} from '@common/api/models/builds/IBuild';
import inflection from 'inflection';
import {ChipStatus} from './Status';

export interface StatusProps {
  state: BuildState;
  size?: 'small' | 'medium' | undefined;
}

export default function StatusChip({state, size}: StatusProps) {
  const label = inflection.capitalize(state);

  switch (state) {
    case BuildState.DRAFT:
      return <ChipStatus message={label} variant="none" chipSize={size} />;
    case BuildState.STAGING:
      return <ChipStatus message={label} variant="lowWarning" chipSize={size} />;
    case BuildState.PAUSED:
      return <ChipStatus message={label} variant="warning" chipSize={size} />;
    case BuildState.ACTIVE:
      return <ChipStatus message={label} variant="info" chipSize={size} />;
    case BuildState.SUCCEEDED:
    case BuildState.COMPLETED:
      return <ChipStatus message={label} variant="success" chipSize={size} />;
    case BuildState.FAILED:
      return <ChipStatus message={label} variant="error" chipSize={size} />;
  }
}
