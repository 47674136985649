import {IconButton, CircularProgress, Tooltip, Typography} from '@material-ui/core';
import {Star, StarBorder} from '@material-ui/icons';
import React, {useState} from 'react';
import {Download, Trash} from 'react-feather';
import styled from 'styled-components';

export interface ImageGridItemProps {
  imageUrl: string;
  alt: string;
  onClick?: Function;
  onStar?: () => void;
  onDelete?: () => void;
  onDownload?: Function;
  isStared?: boolean;
  isStarring?: boolean;
  downloading?: boolean;
}

export const ImageGrid = styled.div`
  display: grid;
  gap: 7px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 10fr));
  align-items: stretch;
  padding: 20px 0px;
  list-style: none;
  cursor: pointer;
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ImageOverlayButton = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  margin: 4px;
  border-radius: 5px;
`;

/**
 * Component for an individual image in the ImageGrid.
 * @param imageUrl - The URL of the image to display.
 * @param alt - The alt text for the image.
 * @param onClick - Optional function to call when the image is clicked.
 * @param onStar - Optional function to call when the star button is clicked.
 * @param onDelete - Optional function to call when the delete button is clicked.
 * @param onDownload - Optional function to call when the download button is clicked.
 * @param isStared - Whether the image is starred or not.
 * @param isStarring - Whether the star request is in progress or not
 * @param downloading - Whether the download request is in progress or not
 */
export function ImageGridItem({
  imageUrl,
  alt,
  onClick,
  onStar,
  onDelete,
  onDownload,
  downloading,
  isStared,
  isStarring,
}: ImageGridItemProps) {
  const [isHovering, setIsHovering] = useState(false);
  const showStar = isHovering || isStared;

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '5px',
        height: '310px',
        width: '100%',
      }}
      onMouseEnter={() => onStar && setIsHovering(true)}
      onMouseLeave={() => onStar && setIsHovering(false)}
    >
      <img
        src={imageUrl}
        alt={alt}
        style={{
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          objectPosition: 'center',
        }}
        onClick={() => {
          if (onClick) onClick();
        }}
      />
      {showStar && (
        <ImageOverlay>
          {!!onStar && (
            <ImageOverlayButton>
              <Tooltip
                title={
                  <Typography>
                    Set as favorite - this will be the photo which appears as the avatar and in build photos
                  </Typography>
                }
              >
                <IconButton
                  onClick={async () => {
                    if (onStar) onStar();
                  }}
                >
                  {isStarring ? (
                    <CircularProgress size={20} style={{margin: '2px'}} />
                  ) : isStared ? (
                    <Star style={{color: 'white'}} />
                  ) : (
                    <StarBorder style={{color: 'white'}} />
                  )}
                </IconButton>
              </Tooltip>
            </ImageOverlayButton>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {isHovering && (
              <ImageOverlayButton>
                <IconButton
                  onClick={() => {
                    if (onDownload) onDownload();
                  }}
                >
                  {downloading ? (
                    <CircularProgress size={20} style={{margin: '2px'}} />
                  ) : (
                    <Download style={{color: 'white'}} />
                  )}
                </IconButton>
              </ImageOverlayButton>
            )}
            {isHovering && (
              <ImageOverlayButton>
                <IconButton
                  onClick={() => {
                    if (onDelete) onDelete();
                  }}
                >
                  <Trash style={{color: 'white'}} />
                </IconButton>
              </ImageOverlayButton>
            )}
          </div>
        </ImageOverlay>
      )}
    </div>
  );
}
