import React from 'react';
import {Chip} from '@material-ui/core';
import styled, {keyframes} from 'styled-components';

const fadeAway = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
    width: 0;
    height: 0;
    }
`;

const FadingChip = styled(Chip)`
  animation: ${fadeAway} 1s linear 1;
  animation-delay: 3s;
  animation-fill-mode: forwards;
`;

export interface DisappearingTagProps {
  label: string;
}

export default function DisappearingTag(props: DisappearingTagProps) {
  return <FadingChip color="primary" label={props.label} size="small" />;
}
