import * as THREE from 'three';
import * as FreeformControls from 'three-freeform-controls';
import Controls from 'three-freeform-controls/dist/types/controls';

export class TransformHelper extends FreeformControls.ControlsManager {
  public linked: boolean = false;
  public rotationControl?: Controls;
  constructor(
    camera: THREE.Camera,
    domElement: HTMLElement,
    private minRingRadius: number,
    private ringSize: number,
    private arrowRadius: number,
    private arrowLength: number
  ) {
    super(camera, domElement);
  }

  public link = (object: THREE.Object3D): THREE.Group => {
    const controls = this.anchor(object.children[0], {
      hideOtherHandlesOnDrag: true,
      hideOtherControlsInstancesOnDrag: true,
      highlightAxis: true,
      mode: FreeformControls.ANCHOR_MODE.FIXED,
      useComputedBounds: true,
    });

    controls.showAll(false);
    controls.setupHandle(new XTranslation(this.minRingRadius, this.arrowRadius, this.arrowLength));
    controls.setupHandle(new YTranslation(this.minRingRadius, this.arrowRadius, this.arrowLength));
    controls.setupHandle(new ZTranslation(this.minRingRadius, this.arrowRadius, this.arrowLength));
    controls.setupHandle(new XRotation(this.minRingRadius, this.ringSize));
    controls.setupHandle(new YRotation(this.minRingRadius, this.ringSize));
    controls.setupHandle(new ZRotation(this.minRingRadius, this.ringSize));

    this.linked = true;
    this.rotationControl = controls;
    return controls;
  };

  public unlink = (object: THREE.Object3D) => {
    if (this.rotationControl) {
      this.detach(object.children[0], this.rotationControl);
      this.linked = false;
    }
  };
}

const createRing = (minRadius: number, maxRadius: number, color: number): THREE.Mesh[] => {
  const sectors = 40;
  const geometry = new THREE.RingGeometry(minRadius, maxRadius, sectors);

  // Assign an index to each face, either 0 or 1, used to select a materials.
  const pattern = [0, 1, 1, 0];
  for (let i = 0; i < 2 * sectors; i++) {
    geometry.addGroup(3 * i, 3, pattern[i % 4]);
  }

  // Create the materials.
  const material = [
    new THREE.MeshStandardMaterial({
      color: color,
      side: THREE.DoubleSide,
    }),
    new THREE.MeshStandardMaterial({
      color: color,
      opacity: 0.5,
      transparent: true,
      side: THREE.DoubleSide,
    }),
  ];

  const meshes: THREE.Mesh[] = [];
  meshes.push(new THREE.Mesh(geometry, material));
  return meshes;
};

const createArrow = (minRingRadius: number, arrowRadius: number, arrowLength: number, color: number) => {
  const radialSegments = 32;
  const material = new THREE.MeshStandardMaterial({color: color});
  const meshes: THREE.Mesh[] = [];
  meshes.push(
    new THREE.Mesh(
      new THREE.CylinderGeometry(arrowRadius, arrowRadius, arrowLength, radialSegments).translate(
        0,
        arrowLength / 2,
        0
      ),
      material
    )
  );
  meshes.push(
    new THREE.Mesh(
      new THREE.ConeGeometry(2 * arrowRadius, 2 * arrowRadius, radialSegments).translate(
        0,
        arrowLength + arrowRadius,
        0
      ),
      material
    )
  );
  return meshes;
};

class XRotation extends FreeformControls.RotationGroup {
  meshes: THREE.Mesh[];
  constructor(minRingRadius: number, ringSize: number) {
    super();
    this.up = new THREE.Vector3(1, 0, 0);
    this.meshes = [];
    const ring = createRing(minRingRadius, minRingRadius + ringSize, 0xff0000);
    ring.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
    });
    this.meshes.push(...ring);
    this.add(...ring);
  }

  setColor(): void {}

  getInteractiveObjects = (): THREE.Mesh[] => {
    return [...this.meshes];
  };
}

class YRotation extends FreeformControls.RotationGroup {
  meshes: THREE.Mesh[];
  constructor(minRingRadius: number, ringSize: number) {
    super();
    this.up = new THREE.Vector3(0, 1, 0);
    this.meshes = [];
    const ring = createRing(minRingRadius, minRingRadius + ringSize, 0x00ff00);
    ring.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
    });
    this.meshes.push(...ring);
    this.add(...ring);
  }

  setColor(): void {}

  getInteractiveObjects = (): THREE.Mesh[] => {
    return [...this.meshes];
  };
}

class ZRotation extends FreeformControls.RotationGroup {
  meshes: THREE.Mesh[];
  constructor(minRingRadius: number, ringSize: number) {
    super();
    this.meshes = [];
    this.up = new THREE.Vector3(0, 0, 1);
    const ring = createRing(minRingRadius, minRingRadius + ringSize, 0x0000ff);
    this.meshes.push(...ring);
    this.add(...ring);
  }

  setColor(): void {}

  getInteractiveObjects = (): THREE.Mesh[] => {
    return [...this.meshes];
  };
}

class XTranslation extends FreeformControls.TranslationGroup {
  parallel: THREE.Vector3;
  private meshes: THREE.Mesh[];
  constructor(private minRingRadius: number, private arrowRadius: number, private arrowLength: number) {
    super();
    this.up = new THREE.Vector3(0, 1, 0);
    this.parallel = new THREE.Vector3(1, 0, 0);

    this.meshes = [];
    const positiveArrow = createArrow(arrowRadius, arrowRadius, arrowLength, 0xff0000);
    positiveArrow.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(0, 0, 1), -Math.PI / 2);
      mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), minRingRadius);
    });
    this.meshes.push(...positiveArrow);
    this.add(...positiveArrow);

    const negativeArrow = createArrow(arrowRadius, arrowRadius, arrowLength, 0xff0000);
    negativeArrow.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI / 2);
      mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), minRingRadius);
    });
    this.meshes.push(...negativeArrow);
    this.add(...negativeArrow);
  }

  setColor(): void {}

  getInteractiveObjects = (): THREE.Mesh[] => {
    return [...this.meshes];
  };
}

/**
 * Handler to translate the marker along the Y axis.
 */
class YTranslation extends FreeformControls.TranslationGroup {
  parallel: THREE.Vector3;
  private meshes: THREE.Mesh[];
  constructor(private minRingRadius: number, private arrowRadius: number, private arrowLength: number) {
    super();
    this.up = new THREE.Vector3(0, 0, 1);
    this.parallel = new THREE.Vector3(0, 1, 0);

    this.meshes = [];
    const positiveArrow = createArrow(arrowRadius, arrowRadius, arrowLength, 0x00ff00);
    positiveArrow.forEach((mesh) => {
      mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), minRingRadius);
    });
    this.meshes.push(...positiveArrow);
    this.add(...positiveArrow);

    const negativeArrow = createArrow(arrowRadius, arrowRadius, arrowLength, 0x00ff00);
    negativeArrow.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(0, 0, 1), Math.PI);
      mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), minRingRadius);
    });
    this.meshes.push(...negativeArrow);
    this.add(...negativeArrow);
  }

  setColor(): void {}

  getInteractiveObjects = (): THREE.Mesh[] => {
    return [...this.meshes];
  };
}

/**
 * Handler to translate the marker along the Z axis.
 */
class ZTranslation extends FreeformControls.TranslationGroup {
  parallel: THREE.Vector3;
  private meshes: THREE.Mesh[];
  constructor(private minRingRadius: number, private arrowRadius: number, private arrowLength: number) {
    super();
    this.up = new THREE.Vector3(1, 0, 0);
    this.parallel = new THREE.Vector3(0, 0, 1);

    this.meshes = [];
    const positiveArrow = createArrow(arrowRadius, arrowRadius, arrowLength, 0x0000ff);
    positiveArrow.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
      mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), minRingRadius);
    });
    this.meshes.push(...positiveArrow);
    this.add(...positiveArrow);

    const negativeArrow = createArrow(arrowRadius, arrowRadius, arrowLength, 0x0000ff);
    negativeArrow.forEach((mesh) => {
      mesh.rotateOnAxis(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
      mesh.translateOnAxis(new THREE.Vector3(0, 1, 0), minRingRadius);
    });
    this.meshes.push(...negativeArrow);
    this.add(...negativeArrow);
  }

  setColor(): void {}

  getInteractiveObjects = (): THREE.Mesh[] => {
    return [...this.meshes];
  };
}
