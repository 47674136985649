import {Pagination} from '../common';

export const ON_PREMISE_EMAIL_ADDRESS = '@onpremise.additiveassurance.com';

export enum Role {
  NONE,
  TECHNICIAN,
  MANAGER,
  ADMINISTRATOR,
  SUPERUSER,
}

export function getRoleName(role: Role, shortName = false): string {
  switch (role) {
    case Role.NONE:
      return 'None';
    case Role.TECHNICIAN:
      return 'Technician';
    case Role.MANAGER:
      return 'Manager';
    case Role.ADMINISTRATOR:
      return shortName ? 'Admin' : 'Administrator';
    case Role.SUPERUSER:
      return 'Superuser';
    default:
      return ''; // damn it experimental Autocomplete
  }
}

export interface IUser {
  uuid: string;
  authId?: string;
  organizationUuid: string;
  role: Role;
  firstName: string;
  lastName: string;
  email: string;
  mobile?: string;
  registered: Date;
  lastActivityOn?: Date;

  sendEmail: boolean;
  sendMessage: boolean;

  emailConfirmed?: boolean;
  termConditionAgreeDate?: Date;
  deletedAt?: Date;
}

export interface IUsersGETRequest extends Pagination {
  uuid: string | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  role: Role | undefined;
}

export type IUsersGETResponse = IUser;

export interface IUsersPOSTRequest {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Role;
  mobile: string | undefined;
}

export interface IUsersPATCHRequest {
  email: string | undefined;
  password: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  role: Role | undefined;
  mobile: string | undefined;
  organizationUuid: string | undefined;
  sendEmail: boolean | undefined;
  sendMessage: boolean | undefined;
  termConditionAgree: boolean | undefined;
}

export interface IUsersResetPasswordPOSTRequest {
  newPassword: string;
}
