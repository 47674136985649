import * as React from 'react';
import {useEffect, useState} from 'react';
import {BatchPageProps} from './index';
import {Card, CardContent} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import BuildsTableWithStatus from '../../../components/molecules/Table/BuildsTableWithStatus';
import {MachineParameterMiniTable} from '../../machines/machinePages/BuildsHistoryPage';
import {batchHistoryGET} from '../../../api/ajax/batches';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {IBuild} from '@common/api/models/builds/IBuild';

import {useBuildStoreActions} from '../../../store/actions';

export default function BuildsHistoryPage(props: BatchPageProps) {
  const [batches, setBatches] = useState<IBatch[]>([]);

  /* Build */
  const buildActions = useBuildStoreActions();
  const buildStore = useSelector((state: RootState) => state.buildStore);
  const buildContainsBatch = (build: IBuild) => batches.some((batch) => batch.uuid === build.batchUuid);
  const builds = Object.values(buildStore.byId).filter(buildContainsBatch);

  // Get batch history when the batch page changes
  useEffect(() => {
    // Get all batches that correspond to current batch. This is necessary as batches change UUID's over time as
    // actions are completed on the batches.
    batchHistoryGET(props.batch.uuid).then((res) => {
      if (res.success) {
        setBatches(res.data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.batch.uuid]);

  const batchUuidsString = batches.map((batch) => batch.uuid).join(', ');

  // When batches are updated, only find builds that have those batch UUIDs (do not grab all builds)
  useEffect(() => {
    const batchUuids = batches.map((batch) => batch.uuid);
    buildActions.ensureConsistent({batchUuid: batchUuids, includeDeleted: true});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchUuidsString]);

  return (
    <Card>
      <CardContent>
        <BuildsTableWithStatus
          builds={builds}
          exclude={['batch', 'machine']}
          detailPanel={[
            {
              render: (rowData) => <MachineParameterMiniTable build={rowData.build} />,
              tooltip: 'Machine Parameters',
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
