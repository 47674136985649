import React from 'react';
import {Typography, Tooltip} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab';
import {capitalise} from '../../../utils/string';

interface CustomToggleProps<T> {
  values: {
    [key: string]: T;
  };
  currentValue: T;
  updateValue: (value: T) => void;
  labelOverrides?: {
    [key: string]: string;
  };
  tooltipOverrides?: {
    [key: string]: string;
  };
  orientation?: 'vertical' | 'horizontal';
}

export function CustomToggleButtonGroup<T extends string>({
  values,
  currentValue,
  updateValue,
  labelOverrides = {},
  tooltipOverrides = {},
  orientation = 'horizontal',
}: CustomToggleProps<T>) {
  const isSelected = (value: any) => value === currentValue;

  return (
    <ToggleButtonGroup
      value={currentValue}
      exclusive
      onChange={(_, newValue) => {
        if (newValue != null) {
          updateValue(newValue);
        }
      }}
      orientation={orientation}
      size="small"
    >
      {Object.keys(values).map((key) => (
        <Tooltip title={<Typography>{tooltipOverrides[key] ?? ''}</Typography>} key={key}>
          <ToggleButton
            value={values[key]}
            style={
              isSelected(values[key])
                ? {
                    backgroundColor: '#2196f3',
                    color: 'white',
                    fontWeight: 'bold',
                  }
                : {
                    color: 'black',
                  }
            }
          >
            <Typography>
              {capitalise(labelOverrides[key]?.replace(/_/g, ' ') ?? values[key]?.replace(/_/g, ' '))}
            </Typography>
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
}
