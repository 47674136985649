import {AlertSeverity} from '@common/api/models/devices/IAlert';
import {assertUnreachable} from '@common/utils/utils';
import {dangerColor, roseColor, warningColor, infoColor, whiteColor} from '../assets/jss/material-dashboard-react';

export function getSeverityColor(severity: AlertSeverity) {
  switch (severity) {
    case AlertSeverity.INFO:
      return infoColor[2];
    case AlertSeverity.WARNING:
      return warningColor[0];
    case AlertSeverity.ERROR:
      return dangerColor[0];
    case AlertSeverity.CRITICAL:
      return roseColor[2];
  }
  assertUnreachable(severity);
}

export function getSeverityForegroundColor(severity: AlertSeverity) {
  switch (severity) {
    case AlertSeverity.INFO:
    case AlertSeverity.WARNING:
    case AlertSeverity.ERROR:
    case AlertSeverity.CRITICAL:
      return whiteColor;
  }
  assertUnreachable(severity);
}

// changeColourBrightness takes in a Colour and Based on the variable amt, lightens or
// darkens the colour. Positive values increase the brightness, while negative values
// decrease the brightness

export function changeColourBrightness(col: string, amt: number) {
  let usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
