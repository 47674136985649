import React from 'react';
import {Visibility} from '@material-ui/icons';
import {ActiveDiv, ViewerButton, ViewerButtonActive} from '../../FadingComponents';
import {BUTTON_MARGIN} from '../utils';

interface Props {
  checked: boolean;
  setChecked: (val: boolean) => void;
  cssTop: number;
}

export const FadingButtonsToggle = ({checked, setChecked, cssTop}: Props) => {
  if (checked)
    return (
      <ViewerButtonActive
        onClick={() => setChecked(!checked)}
        style={{
          right: BUTTON_MARGIN,
          top: cssTop,
          position: 'absolute',
        }}
      >
        <ActiveDiv>
          <Visibility />
        </ActiveDiv>
      </ViewerButtonActive>
    );

  return (
    <ViewerButton
      onClick={() => setChecked(!checked)}
      style={{
        right: BUTTON_MARGIN,
        top: cssTop,
        position: 'absolute',
      }}
    >
      <Visibility />
    </ViewerButton>
  );
};
