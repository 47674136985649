import React, {useEffect, useState} from 'react';
import {TextField, CircularProgress} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {IBuild, IBuildGetRequest} from '@common/api/models/builds/IBuild';
import {newDebouncer} from '../../../api/ajax';
import {buildsAllGET} from '../../../api/ajax/builds';
import {QueryFilter} from '../../../store/actions/liveUpdateStore';
import {OrderDirection} from '@common/utils/ordering/ordering';

const debounceSearch = newDebouncer();

type BuildOption = {value: IBuild; label: string};

const SearchAndSelectBuild = ({
  selectedBuild,
  onBuildSelected,
  filters = {},
}: {
  selectedBuild: IBuild | null;
  onBuildSelected: (build: IBuild | null) => void;
  filters: QueryFilter<IBuildGetRequest>;
}) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<BuildOption>>([]);

  useEffect(() => {
    if (loading) {
      debounceSearch(async () => {
        if (open) {
          const response = await buildsAllGET({
            take: 50,
            name: {like: search},
            sortBy: {createdAt: OrderDirection.DESC},
            ...(!!selectedBuild ? {uuid: {notEqual: selectedBuild.uuid}} : {}),
            ...filters,
          });
          if (response.success) {
            setOptions(
              response.data.map((build: IBuild) => ({
                value: build,
                label: build.name,
              }))
            );
          }
        }

        setLoading(false);
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, search]);

  const onChange = (option: BuildOption | null) => {
    onBuildSelected(option ? option.value : null);
    setSearch('');
  };

  const onInputChange = (_event: any, value: any) => {
    setSearch(value);
    setLoading(true);
  };
  const onOpen = () => {
    setOpen(true);
    setLoading(true);
  };
  const onClose = () => {
    setOpen(false);
    setLoading(false);
    setOptions([]);
  };

  return (
    <Autocomplete
      fullWidth
      filterSelectedOptions
      id={`build-search-and-select`}
      open={open}
      size="small"
      options={options}
      loading={loading}
      value={selectedBuild ? {value: selectedBuild, label: selectedBuild.name} : undefined}
      getOptionLabel={(option: any) => option.label}
      onChange={(_event: any, option: BuildOption | null) => onChange(option)}
      onInputChange={onInputChange}
      onOpen={onOpen}
      onClose={onClose}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Search & Select Build"
          placeholder="Search & Select Builds..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchAndSelectBuild;
