//https://ponyfoo.com/articles/react-data-survival-kit
// Debounces with a delay

export type Debouncer = (callback: () => any, ms: number) => void;

export function newDebouncer(): Debouncer {
  let timer: null | ReturnType<typeof setTimeout> = null;
  return (callback: () => any, ms: number) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(callback, ms);
  };
}
