import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Grid, Card as MuiCard, CardContent, Box, Button, Typography} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {ArrowBack, Check, CheckCircle} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import PartSelectorTreeView from '../shared/PartSelectorTreeView';
import SelectedPart3DViewport from '../shared/SelectedPart3DViewport';
import SelectedTargetParts from '../results/SelectedTargetParts';
import SimilarityPartInfo from '../shared/SimilarityPartInfo';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import similarityReportActions from '../../../store/actions/similarityReportActions';
import {RootState} from '../../../store/reducers/index';
import {useAsyncDispatch} from '../../../ReduxRoot';
import {CircularProgress} from '@material-ui/core';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import VerticalButton from '../../../components/atoms/VerticalButton';
import {VIEWPORT_FULLSCREEN_STYLE} from '../shared/fullScreenViewportStyle';
import LoadingPage from '../../../components/organisms/LoadingPage';
import {SimilarityStep} from '../SimilarityCreatePage';
import {RotationMap} from '@common/api/models/builds/data/ISimilarity';

const Card = styled(MuiCard)(spacing);

const AddMoreTargetPartsScreen = ({uuid, isViewportFullScreen}: {uuid: string; isViewportFullScreen: boolean}) => {
  const isSmallScreen = useSmallScreenSize();
  const {fetchReportProgress, setNewTargetPartRotations} = useSimilarityReportStoreActions();

  const sourceParts = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport?.sources);
  const currentTargetParts = useSelector((state: RootState) => state.similarityReportStore.currentTargetPartUuids);
  const targetPartRotations = useSelector((state: RootState) => state.similarityReportStore.newTargetPartRotations);
  const currentlyViewingIsSelected = useSelector((state: RootState) =>
    state.similarityReportStore.newTargetParts
      .map((part) => part.uuid)
      .includes(state.similarityReportStore.currentlyViewingPart?.uuid as any)
  );

  const sourcePartUuids = sourceParts?.map((part) => part.uuid) || [];
  const sourcePartRotations: RotationMap =
    sourceParts?.reduce(
      (rotationMap, part) => ({
        ...rotationMap,
        [part.uuid]: part.rotation,
      }),
      {}
    ) || {};

  useEffect(() => {
    fetchReportProgress(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  function onTargetRotationChanged(partUuid: string, rotation: number) {
    setNewTargetPartRotations({
      ...targetPartRotations,
      [partUuid]: rotation,
    });
  }

  if (!currentTargetParts || !sourcePartUuids) return <LoadingPage loadingText="Loading Similarity Report..." />;

  return (
    <>
      <SelectedTargetParts />

      <StyledGrid container spacing={isSmallScreen ? 2 : 4}>
        <Grid item xs={12} md={6} style={{minHeight: '320px'}}>
          <Card padding={isSmallScreen ? 1 : 3} style={{height: '100%', overflow: 'auto'}}>
            <CardContent style={{height: '100%'}}>
              <PartSelectorTreeView mode="edit" selectionType={SimilarityStep.TARGET} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={isSmallScreen ? 2 : 4} style={{height: 'calc(100% + 16px)'}}>
            <Grid
              item
              xs={12}
              style={
                isViewportFullScreen
                  ? VIEWPORT_FULLSCREEN_STYLE
                  : {
                      height: 'calc(100vh - 566px)',
                      minHeight: isSmallScreen ? '50vh' : '320px',
                    }
              }
            >
              <SelectedPart3DViewport
                isViewportFullScreen={isViewportFullScreen}
                onRotatePart={currentlyViewingIsSelected ? onTargetRotationChanged : undefined}
                sourcePartUuids={sourcePartUuids}
                sourcePartRotations={sourcePartRotations}
                targetPartRotations={targetPartRotations}
                type={SimilarityStep.TARGET}
              />
            </Grid>
            <Grid item xs={12} style={{height: '340px'}}>
              <Card padding={isSmallScreen ? 1 : 3} style={{height: '100%'}}>
                <SimilarityPartInfo />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>

      {!isSmallScreen && <NavigationButtons uuid={uuid} />}
    </>
  );
};

export default AddMoreTargetPartsScreen;

const NavigationButtons = ({uuid}: {uuid: string}) => {
  return (
    <Box width="100%" display="flex" justifyContent="flex-end" marginTop="16px">
      <CancelButton uuid={uuid} />
      <AddPartsButton uuid={uuid} />
    </Box>
  );
};

export const CancelButton = ({uuid}: {uuid: string}) => {
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();
  const {setNewTargetParts} = useSimilarityReportStoreActions();

  useEffect(() => {
    return () => {
      setNewTargetParts([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onclose = () => {
    history.push(`/reports/similarity/uuid/${uuid}`);
  };

  if (isSmallScreen) {
    return (
      <VerticalButton onClick={onclose} color="secondary">
        <ArrowBack />
        <Typography variant="caption">Cancel</Typography>
      </VerticalButton>
    );
  }

  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <Button variant="outlined" color="primary" onClick={onclose} style={{marginRight: '12px'}}>
        Cancel
      </Button>
    </Box>
  );
};

export const AddPartsButton = ({uuid}: {uuid: string}) => {
  const dispatch = useAsyncDispatch();
  const isSmallScreen = useSmallScreenSize();
  const {setNewTargetParts} = useSimilarityReportStoreActions();
  const [isRequesting, setRequesting] = useState(false);
  const history = useHistory();
  const newTargetParts = useSelector((state: RootState) => state.similarityReportStore.newTargetParts);
  const newTargetPartRotations = useSelector((state: RootState) => state.similarityReportStore.newTargetPartRotations);

  useEffect(() => {
    return () => {
      setNewTargetParts([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddParts = async () => {
    setRequesting(true);
    const success = await dispatch(
      similarityReportActions.updateSimilarityReport(uuid, {
        targetPartUuids: newTargetParts?.map((part) => part.uuid),
        targetPartRotations: newTargetPartRotations,
      })
    );

    setRequesting(false);
    if (success) {
      history.push(`/reports/similarity/uuid/${uuid}`);
    }
  };

  if (isSmallScreen) {
    return (
      <ConditionalTooltip hideTooltip={!!newTargetParts?.length} tooltip="Please select at least one target part">
        <VerticalButton color="secondary" onClick={onAddParts} disabled={!newTargetParts?.length || isRequesting}>
          {isRequesting ? <CircularProgress size={15} /> : <CheckCircle />}
          <Typography variant="caption">Add targets</Typography>
        </VerticalButton>
      </ConditionalTooltip>
    );
  }

  return (
    <ConditionalTooltip hideTooltip={!!newTargetParts?.length} tooltip="Please select at least one target part">
      <Button
        variant="contained"
        color="primary"
        style={{whiteSpace: 'nowrap'}}
        endIcon={isRequesting ? <CircularProgress size={20} /> : <Check />}
        onClick={onAddParts}
        disabled={!newTargetParts?.length || isRequesting}
      >
        Add Target Parts
      </Button>
    </ConditionalTooltip>
  );
};

const StyledGrid = styled(Grid)`
  flex: 1;
  margin-top: 12px;
  min-height: 624px;
`;
