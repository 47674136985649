import {buildsAllGET} from '../../../../api/ajax/builds';
import {FilterSchemaType} from './index';
import {AlertSeverity} from '@common/api/models/devices/IAlert';
import {getSeverityColor} from '../../../../utils/color';

export const alertDefaultFilters = {
  sortBy: {date: 'DESC'},
};

const alertFilterSchema: Array<FilterSchemaType> = [
  {field: 'message', type: 'string', permanentComparison: 'like'},
  {field: 'date', type: 'dateTimeRange'},
  {
    field: 'severity',
    type: 'booleanList',
    booleanValues: Object.values(AlertSeverity).reverse(),
    permanentComparison: 'in',
    colorFn: getSeverityColor,
    fullWidth: true,
  },
  {
    label: 'Builds',
    field: 'buildUuid',
    type: 'resourceSelect',
    searchGET: buildsAllGET,
    permanentComparison: 'in',
  },
];

export default alertFilterSchema;
