import axios from 'axios';
import * as qs from 'qs';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {IResourcePermission} from '@common/api/models/users/IResourcePermission';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function resourcePermissionsAllGET(
  filter: QueryFilter<IResourcePermission>
): AjaxApiResult<IResourcePermission[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/resourcePermissions/' + (query && '?' + query));
  }, 'Could not fetch permissions');
}

export async function resourcePermissionPOST(
  userUuid: string,
  permission: Partial<IResourcePermission>
): AjaxApiResult<IResourcePermission> {
  return ajaxWrapper(() => {
    return axios.post(`/api/users/${userUuid}/resourcePermissions`, permission);
  }, 'Could not create permission');
}

export async function resourcePermissionDELETE(
  userUuid: string,
  permissionUuid: string
): AjaxApiResult<IResourcePermission> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/users/${userUuid}/resourcePermissions/${permissionUuid}`);
  }, 'Could not delete permission for user');
}
