import path from 'path';

import {IAttachmentBase} from '../attachments/IAttachmentBase';

export type SliceAttachmentSortOptions = 'filename' | 'filesize' | 'state' | 'createdAt' | 'scanStrategy';

export enum SliceState {
  NEED_TO_VERIFY = 'need_to_verify',
  VERIFYING = 'verifying',
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum ScanStrategy {
  BORDER = 'border',
  FILL = 'fill',
  PAINT = 'paint',
}

export interface ISliceAttachment extends IAttachmentBase {
  buildUuid?: string;
  organizationUuid: string;
  state: SliceState;
  scanStrategy: ScanStrategy;
}

export const SLICE_FILE_EXTENSIONS = ['sli', 'cli', 'mtt', 'slm', 'cls', 'wza', 'zip', 'job', 'rar'];
export const SLICE_FILE_EXTENSIONS_PAINT = ['mtt', 'slm', 'wza', 'zip', 'job', 'rar'];

export const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'webp'];

// TODO: Write unit test for isSliceFile function
// TODO: add as validator to slice attachment entity
/** Check if a filename contains a slice file extension e.g. ".sli" or ".cli" */
export function isSliceFile(filename: string) {
  const ext = path.extname(filename).toLowerCase().slice(1); // trim leading dot
  return SLICE_FILE_EXTENSIONS.includes(ext);
}

export function isImageFile(filename: string) {
  const ext = path.extname(filename).toLowerCase().slice(1);
  return IMAGE_FILE_EXTENSIONS.includes(ext);
}

export interface ISliceAttachmentGETResponse extends ISliceAttachment {
  partsCount: number;
}
export interface ISliceAttachmentMultipartInitiatePostRequest {
  buildUuid: string;
  filename: string;
  filesize: number;
}

export interface ISliceAttachmentMultipartInitiatePostResponse {
  filename: string;
  uploadId: string;
  signedUrls: string[];
}

export interface ISliceAttachmentMultipartAbortPostRequest {
  buildUuid: string;
  key: string;
  uploadId: string;
}

// export interface ISliceAttachmentMultipartAbortPostResponse {}

export interface ISliceAttachmentMultipartCompleteGetRequest {
  buildUuid: string;
  key: string;
  name: string;
  uploadId: string;
  parts: {ETag: string; PartNumber: number}[];
  scanStrategy: ScanStrategy;
}

// export interface ISliceAttachmentMultipartCompleteGetResponse {}
