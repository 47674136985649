import React, {useState, useEffect} from 'react';
import {TextField, Grid} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import inflection from 'inflection';
import {omit} from 'lodash';
import ComparisonsSelector from './ComparisonSelector';
import {FilterComponentProps} from '../FilterModal';
import {newDebouncer} from '../../../../api/ajax';
import {parseCurrentFilters} from './utils';

const enumSingleComparisons = {
  equal: 'Equal',
  notEqual: 'Not equal',
};

type EnumSingleComparison = keyof typeof enumSingleComparisons;

const debounceSearch = newDebouncer();

const EnumSingleFilter = ({
  field,
  enumValues,
  enumLabels,
  control,
  placeholder,
  label,
  permanentComparison,
  currentFilters,
  onSubmit,
  excludeComparisons = [],
  filterListLocation,
}: FilterComponentProps) => {
  const initialFilter = parseCurrentFilters(currentFilters, field);
  const [internalValue, setInternalValue] = useState<string | undefined>((initialFilter[1] as string) || undefined);
  const [comparison, setComparison] = useState<EnumSingleComparison>(
    (initialFilter[0] as EnumSingleComparison) || 'equal'
  );

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSearch(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  useEffect(() => {
    control.register(field);
  }, [control, field]);

  useEffect(() => {
    const filterValue = parseCurrentFilters(currentFilters, field);
    setInternalValue(filterValue[1] ? filterValue[1] : initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const setValue = (newValue: any) => {
    setInternalValue(newValue);
    if (newValue) {
      control.setValue(field, {[comparison]: newValue});
    } else {
      control.setValue(field, undefined);
    }
    submitField();
  };

  const onComparisonChanged = (newComparison: EnumSingleComparison) => {
    setComparison(newComparison);
    control.setValue(field, {[newComparison]: internalValue});
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={!permanentComparison ? 6 : 12} sm={!permanentComparison ? 8 : 12}>
        <Autocomplete
          filterSelectedOptions
          size="small"
          id={`filter-enum-single-${field}`}
          options={enumValues || []}
          getOptionLabel={(option) => `${enumLabels ? enumLabels[option] : option}`}
          value={internalValue}
          onChange={(_event: any, options: any) => setValue(options)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder || label || inflection.capitalize(field)}
              label={label || inflection.capitalize(field)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
      {!permanentComparison && (
        <Grid item xs={6} sm={4}>
          <ComparisonsSelector
            comparisons={omit(enumSingleComparisons, excludeComparisons) as {[key in EnumSingleComparison]: string}}
            currentComparison={comparison}
            setCurrentComparison={onComparisonChanged}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EnumSingleFilter;
