import {Box, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import React from 'react';

function DownloadStartedModal() {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" maxWidth="400px">
      <Typography variant="h6">We are preparing your download</Typography>
      <br />
      <Alert severity="info">
        Please keep this tab open until your download has completed. Once we've finished processing, we will prompt you
        for a location to save the file.
      </Alert>
      <br />
      <Typography>
        You can close this modal and continue navigating the application. You can monitor the progress of your download
        in the blue bar at the bottom of the screen.
      </Typography>
    </Box>
  );
}

export default DownloadStartedModal;
