import React from 'react';
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import customInputStyle from '../../assets/jss/material-dashboard-react/components/customInputStyle';

function CustomInput({...props}: any) {
  const {classes, formControlProps, labelText, id, labelProps, inputProps, error, success, helperText} = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });

  return (
    <FormControl
      fullWidth
      {...formControlProps}
      className={(formControlProps ? formControlProps.className : '') + ' ' + classes.formControl}
    >
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          disabled: classes.disabled,
        }}
        id={id}
        {...inputProps}
      />
      {error ? (
        <Clear className={classes.feedback + ' ' + classes.labelRootError} data-testid={'Error Icon'} />
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} data-testid={'Success Icon'} />
      ) : null}

      {error ? (
        helperText ? (
          <p className={classes.labelRootError}>{helperText}</p>
        ) : null
      ) : success ? (
        helperText ? (
          <p className={classes.labelRootSuccess}>{helperText}</p>
        ) : null
      ) : helperText ? (
        <p>{helperText}</p>
      ) : null}
    </FormControl>
  );
}

// FIXME: Create proper interface for CustomInput props
// CustomInput.propTypes = {
//   classes: PropTypes.object.isRequired,
//   labelText: PropTypes.node,
//   labelProps: PropTypes.object,
//   id: PropTypes.string,
//   inputProps: PropTypes.object,
//   formControlProps: PropTypes.object,
//   error: PropTypes.bool,
//   success: PropTypes.bool
// };

export default withStyles(customInputStyle)(CustomInput);
