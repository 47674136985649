import {IPartGETRequest, IPartGETResponse, BuildsByPartsQueryGetResponse} from '@common/api/models/builds/data/IPart';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import * as qs from 'qs';
import axios from 'axios';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function partsAllGET(filter: QueryFilter<IPartGETRequest>): AjaxApiResult<IPartGETResponse[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/parts/' + (query && '?' + query));
  }, 'Could not fetch parts');
}

export async function buildsByPartQueryGET(
  filter: QueryFilter<IPartGETRequest>
): AjaxApiResult<BuildsByPartsQueryGetResponse[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/parts/builds/' + (query && '?' + query));
  }, 'Could not fetch builds');
}
