import React from 'react';
import {AppBar, Grid, Tab, Tabs as MuiTabs} from '@material-ui/core';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

/* Pages */
import {MachinePageComponent} from '../machinePages';
import {GeneralDetailsPage} from '../machinePages/GeneralDetailsPage';
import {BuildHistoryPage} from '../machinePages/BuildsHistoryPage';
import {MaintenanceLogsPage} from '../machinePages/MaintenanceLogsPage';
import MachinePermissionsPage from '../machinePages/MachinePermissionsPage';
import {MachineSettingsPage} from '../machinePages/MachineSettingsPage';
import {MachineLog} from '../machinePages/MachineLog';
import useMachine from '../hooks/useMachine';

export interface MachinePage {
  id: string;
  title: string;
  component: MachinePageComponent;
}

export const pages: MachinePage[] = [
  {
    id: 'general',
    title: 'General',
    component: GeneralDetailsPage,
  },
  {
    id: 'builds',
    title: 'Builds History',
    component: BuildHistoryPage,
  },
  {
    id: 'maintenance logs',
    title: 'Maintenance Logs',
    component: MaintenanceLogsPage,
  },
  {
    id: 'settings',
    title: 'Settings',
    component: MachineSettingsPage,
  },
  {
    id: 'permissions',
    title: 'Permissions',
    component: MachinePermissionsPage,
  },
  {
    id: 'device_logs',
    title: 'Device Logs',
    component: MachineLog,
  },
];

const Tabs = styled(MuiTabs)`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: #000000;
`;

export default function MachinePageTabs() {
  const {machine, device, uuid, activePage, filteredPages, activePageIndex} = useMachine();
  const history = useHistory();

  const handleTabChange = (e: any, newIndex: number) => {
    history.push('/machines/uuid/' + uuid + '/' + filteredPages[newIndex].id + '/');
  };

  return (
    <Grid item xs={12} md={8} lg={9}>
      <AppBar position="static" color="secondary">
        <Tabs
          value={activePageIndex}
          onChange={handleTabChange}
          variant="scrollable"
          indicatorColor="primary"
          aria-label="full width tabs example"
          scrollButtons="auto"
        >
          {filteredPages.map((p) => {
            return <Tab id={'tab-' + p.id} label={p.title} />;
          })}
        </Tabs>
      </AppBar>

      <Grid item xs={12}>
        {<activePage.component machine={machine} device={device} />}
      </Grid>
    </Grid>
  );
}
