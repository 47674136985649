import React from 'react';
import {Card, CardContent, Grid, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';

import {RootState} from '../../../store/reducers';
import {MachinePageProps} from './index';
import {ManualDeviceStateButtons} from '../../../components/molecules/ManualControls/ManualDeviceStateButtons';
import ManualBuildActionButtons from '../../../components/molecules/ManualControls/ManualBuildActionButtons';
import {WebcamButton} from '../../builds/liveBuild/shared/WebcamButton';
import {usePermissionsForBuild} from '../../../utils/utilHooks';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {Alert} from '@material-ui/lab';

const noPermissionForBuildMessage = (prefix: string) =>
  `${prefix} unavailable while the machine is busy with a build you do not have permission to access.`;

export function MachineSettingsPage(props: MachinePageProps) {
  const device = useSelector((s: RootState) => s.deviceStore.byMachineUuid[props.machine.uuid!]);
  const currentBuild = useSelector((state: RootState) =>
    Object.values(state.buildStore.byId).find((build) => build.uuid === device?.buildUuid)
  );
  const {buildPermission} = usePermissionsForBuild(currentBuild);

  // Device is assigned to a build which the user cant view.
  const blockMachineActions = !!device?.buildUuid && !buildPermission;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          {device && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant={'h6'}>Device Actions</Typography>
              </Grid>
              <Grid item xs={12}>
                <ConditionalTooltip
                  hideTooltip={!blockMachineActions}
                  tooltip={noPermissionForBuildMessage('Device actions are')}
                >
                  <ManualDeviceStateButtons deviceSerial={device.serial} disabled={blockMachineActions} />
                </ConditionalTooltip>
              </Grid>
            </React.Fragment>
          )}
          {currentBuild && (
            <React.Fragment>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '20px',
                }}
              >
                <Typography variant={'h6'}>Build Actions (Advanced)</Typography>
              </Grid>
              <Grid item xs={12}>
                <ConditionalTooltip
                  hideTooltip={!blockMachineActions}
                  tooltip={noPermissionForBuildMessage('Build actions are')}
                >
                  <ManualBuildActionButtons build={currentBuild} disabled={blockMachineActions} />
                </ConditionalTooltip>
              </Grid>
            </React.Fragment>
          )}
          {device && (
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <ConditionalTooltip
                hideTooltip={!blockMachineActions}
                tooltip={noPermissionForBuildMessage('Device webcam is')}
              >
                <WebcamButton webcamHosts={device.webcamHosts} disabled={blockMachineActions} />
              </ConditionalTooltip>
            </div>
          )}
          {!currentBuild && !device && (
            <Alert style={{margin: '8px 0px', width: '100%'}} severity="warning">
              No Device Attached to this Machine
            </Alert>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
