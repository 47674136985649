import React, {useEffect, useState} from 'react';
import {PlayArrow} from '@material-ui/icons';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DeviceDetailedState} from '@common/api/models/devices/IDevice';
import {DialogButton} from '../../../../components/molecules/DialogButton';
import {monitorBuildGET} from '../../../../api/ajax/builds';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import {toast} from 'react-toastify';
import {BUILD_LONG_TRANSITION_TIMEOUT} from '../index';
import {useCameraState} from '../shared/CameraState';
import {TextField, Typography} from '@material-ui/core';

interface IResumeMonitoringButton {
  build: IBuild;
  deviceDetailedState: DeviceDetailedState;
  fullWidthButton?: boolean;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

export const useResumeMonitoring = (build: IBuild, deviceDetailedState: DeviceDetailedState) => {
  const [resumeMonitoring, setResumeMonitoring] = useState(false);
  const [deviceDetailedStateWas, setDeviceDetailedStateWas] = useState<DeviceDetailedState>();

  const [resumeLayerNumer, setResumeLayerNumber] = useState<string>((build.lastPrintedLayerId! + 1).toString());

  const resumeLayerNumberValid =
    !isNaN(parseInt(resumeLayerNumer)) && parseInt(resumeLayerNumer) > build.lastPrintedLayerId!;

  useEffect(() => {
    return () => clearTimeout(timeout!);
  }, []);

  useEffect(() => {
    // If device state transitioned from MonitoringStopping, then the job has finished, whether successful or not.
    if (
      deviceDetailedStateWas === DeviceDetailedState.MonitoringStarting &&
      deviceDetailedState !== DeviceDetailedState.MonitoringStarting
    ) {
      setResumeMonitoring(false);
      clearTimeout(timeout!);
    }

    setDeviceDetailedStateWas(deviceDetailedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetailedState]);

  const handleResumeMonitoring = async () => {
    if (resumeLayerNumberValid) {
      setResumeMonitoring(true);
      const res = await monitorBuildGET(build.uuid, false, parseInt(resumeLayerNumer));
      if (!res.success) {
        setResumeMonitoring(false);
        return;
      }

      timeout = setTimeout(() => {
        setResumeMonitoring(false);
        toast('Resuming build timed out', {type: 'error'});
      }, BUILD_LONG_TRANSITION_TIMEOUT);
    }
  };

  return {resumeMonitoring, handleResumeMonitoring, resumeLayerNumer, setResumeLayerNumber, resumeLayerNumberValid};
};

export function ResumeMonitoringButton({build, deviceDetailedState, fullWidthButton}: IResumeMonitoringButton) {
  const {allCamerasConnected} = useCameraState(build.deviceSerial!);
  const {machinePermission} = usePermissionsForBuild(build);
  const {resumeMonitoring, handleResumeMonitoring, resumeLayerNumer, setResumeLayerNumber, resumeLayerNumberValid} =
    useResumeMonitoring(build, deviceDetailedState);

  return (
    <ConditionalTooltip
      tooltip={
        !allCamerasConnected
          ? 'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before resuming the build.'
          : "Resuming the build requires permission to access this build's machine."
      }
      hideTooltip={!!machinePermission && allCamerasConnected}
    >
      <DialogButton
        text="Resume Monitoring"
        icon={<PlayArrow />}
        loading={resumeMonitoring}
        dialog={{
          title: 'Resume Monitoring',
          content: (
            <>
              <Typography style={{paddingBottom: '15px'}}>
                Are you sure you want to resume this build? This will return the build to monitoring mode.
              </Typography>
              <TextField
                fullWidth
                required
                variant="outlined"
                helperText={
                  !resumeLayerNumberValid &&
                  `Resume layer number should be a number greater than ${build.lastPrintedLayerId}`
                }
                error={!resumeLayerNumberValid}
                size="small"
                type="number"
                label="Resume at layer"
                value={resumeLayerNumer}
                onChange={(event) => {
                  setResumeLayerNumber(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleResumeMonitoring();
                  }
                }}
              />
            </>
          ),
          confirmText: 'Resume',
          confirmButtonProps: {disabled: !resumeLayerNumberValid},
        }}
        handleConfirm={handleResumeMonitoring}
        fullWidth={fullWidthButton}
        disabled={!machinePermission || !allCamerasConnected}
      />
    </ConditionalTooltip>
  );
}
