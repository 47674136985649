import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {useLocationBasedDefectsReportActions} from '../../store/actions/index';
import {RootState} from '../../store/reducers/index';
import {useSelector} from 'react-redux';
import {FetchingState} from '../../store/model/liveUpdateStore';
import LoadingPage from '../../components/organisms/LoadingPage';
import Header from '../../components/organisms/Header';
import LocationBasedDefectsList from './LocationBasedDefectsList';
import CurrentLocationBasedDefectsReport from './CurrentLocationBasedDefectsReport';
import LocationBasedDefectsBottomToolbar from './LocationBasedDefectsBottomToolbar';
import NewReportButton from './NewReportButton';

function LocationBasedDefectsReportPage() {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const locationBasedDefectsReportActions = useLocationBasedDefectsReportActions();
  const isFetchingProfiles = useSelector(
    (state: RootState) => state.locationBasedDefectsReportStore.fetched === FetchingState.Fetching
  );
  const firstReport = useSelector((state: RootState) => Object.values(state.locationBasedDefectsReportStore.byId)?.[0]);

  useEffect(() => {
    locationBasedDefectsReportActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedUuid && firstReport) {
      history.replace(`/reports/locationBasedDefects/${firstReport.uuid}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid, firstReport]);

  if (isFetchingProfiles) return <LoadingPage loadingText="Loading Location Based Defect Reports..." />;

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        helmet="Location Based Defects"
        title="Location Based Defects"
        breadcrumbs={['Location Based Defects']}
        endAdornment={isSmallScreen ? undefined : <NewReportButton />}
      />

      <Box display="flex" flex={1}>
        {!isSmallScreen && (
          <Box height="100%" maxHeight="100%" width="400px" marginRight="24px">
            <LocationBasedDefectsList />
          </Box>
        )}

        <Box height="100%" width={isSmallScreen ? '100%' : 'calc(100% - 400px - 24px)'}>
          <CurrentLocationBasedDefectsReport />
        </Box>
      </Box>

      {isSmallScreen && <LocationBasedDefectsBottomToolbar />}
    </Box>
  );
}

export default LocationBasedDefectsReportPage;
