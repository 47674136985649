import styled from 'styled-components';

export default styled.div<{columns: string}>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background: ${(props) => props.theme.palette.common.white};
  border-top: 1px solid ${(props) => props.theme.palette.grey['400']};
  width: 100%;
  z-index: 1299;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: ${({columns}) => columns};

  button {
    padding: 4px;
  }
`;
