import React, {useEffect} from 'react';
import {Typography} from '@material-ui/core';

import {MaterialTableProps} from 'material-table';
import {useSelector} from 'react-redux';

import {CoolLinkedTable} from './CoolTable';
import {useBatchStoreActions, useMachineStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {IBuild} from '@common/api/models/builds/IBuild';
import {renderDateTimeString} from '../../../utils/string';
import {generateColumns} from '../../../utils/table';
import DateRangeSelectorModal, {dateRangeFilter} from '../Selector/DateRangeSelectorModal';

export interface BuildsRow {
  name: string;
  state: string;
  machine: string;
  created: string | Date;
  start?: string | Date;
  end?: string | Date;
  batch: string;
  url: string | undefined;

  build: IBuild;
}

export interface BuildsTableProps {
  builds: IBuild[];
  errorMessage?: string;
  inline?: boolean;
  exclude?: (keyof BuildsRow)[];
  customRender?: {
    [key in keyof BuildsRow]?: (data: BuildsRow, type: 'row' | 'group') => any;
  };
  filters?: Partial<BuildsRow>;
}

export function BuildsTable(props: BuildsTableProps & Partial<MaterialTableProps<BuildsRow>>) {
  const {builds, errorMessage, filters, inline, isLoading, ...rest} = props;
  const machineActions = useMachineStoreActions();
  const batchActions = useBatchStoreActions();

  const machineUuids = props.builds.map((b) => b.machineUuid!).filter((s) => s);
  const batchUuids = props.builds.map((b) => b.batchUuid!).filter((s) => s);

  useEffect(() => {
    if (machineUuids.length && !props.exclude?.includes('machine')) {
      machineActions.ensureConsistent({uuid: machineUuids});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(machineUuids)]);

  useEffect(() => {
    if (batchUuids.length) {
      batchActions.ensureConsistent({uuid: batchUuids});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(batchUuids)]);

  const machineStore = useSelector((s: RootState) => s.machineStore);
  const batchStore = useSelector((s: RootState) => s.batchStore);

  const buildTableOptions = {
    paging: !inline,
    sorting: !inline,
    search: !inline,
    showTitle: !inline,
    padding: (inline ? 'dense' : 'default') as 'default' | 'dense',
    toolbar: !inline,
  };

  return (
    <CoolLinkedTable
      columns={generateColumns(
        [
          {
            title: 'Created',
            field: 'created',
            type: 'date' as const,
            defaultSort: 'desc' as const,
            customSort: (a: BuildsRow, b: BuildsRow) => {
              return new Date(a.created).getTime() - new Date(b.created).getTime();
            },
            filterComponent: (props) => <DateRangeSelectorModal columnName="created" {...props} />,
            customFilterAndSearch: dateRangeFilter,
            render: (row: BuildsRow) => <Typography>{renderDateTimeString('short', row.created, true)}</Typography>,
          },
          {
            title: 'Name',
            field: 'name',
            render: (row: BuildsRow) => (
              <Typography>{`${row.name}${row.build.deletedAt ? ' (deleted)' : ''}`}</Typography>
            ),
          },
          {
            title: 'State',
            field: 'state',
            customFilterAndSearch: (term: string, rowData: BuildsRow) => {
              if (term.indexOf('&') > 0) {
                return term.split('&').includes(rowData.state);
              } else {
                return term === rowData.state;
              }
            },
          },
          {title: 'Machine', field: 'machine'},
          {
            title: 'Start',
            field: 'start',
            type: 'date' as const,
            emptyValue: '',
            filterComponent: (props) => <DateRangeSelectorModal columnName="start" {...props} />,
            customFilterAndSearch: dateRangeFilter,
            render: (row: BuildsRow) =>
              row.start && <Typography>{renderDateTimeString('short', row.start, true)}</Typography>,
          },
          {
            title: 'End',
            field: 'end',
            type: 'date' as const,
            emptyValue: '',
            filterComponent: (props) => <DateRangeSelectorModal columnName="end" {...props} />,
            customFilterAndSearch: dateRangeFilter,
            render: (row: BuildsRow) =>
              row.end && <Typography>{renderDateTimeString('short', row.end, true)}</Typography>,
          },
          {title: 'Batch', field: 'batch'},
        ],
        props.exclude,
        props.customRender
      )}
      data={builds.map((build): BuildsRow => {
        const machine = machineStore.byId[build.machineUuid!];
        const batch = batchStore.byId[build.batchUuid!];
        return {
          name: build.name,
          state: build.state,
          machine: machine ? machine.name : '',
          created: build.createdAt,
          start: build.start,
          end: build.end,
          batch: batch?.name || '',
          url: build.deletedAt ? undefined : `/builds/uuid/${build.uuid}`,
          build: build,
        };
      })}
      isLoading={
        isLoading || machineStore.fetched === FetchingState.Fetching || batchStore.fetched === FetchingState.Fetching
      }
      options={buildTableOptions}
      filters={filters}
      errorMessage={errorMessage}
      {...rest}
    />
  );
}
