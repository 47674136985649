import React, {createContext, useContext, useRef} from 'react';
import axios, {AxiosProgressEvent, RawAxiosRequestConfig, AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import {omit} from 'lodash';
import {ScanStrategy} from '@common/api/models/attachments/ISliceAttachment';
import {FileContext, FileStates, FileUploadStatus} from '../FileUploadContext';
import FileUploadUtil from '../FileUploadUtil';
import {
  MULTI_PART_REQUESTS,
  MultiPartUploadResourceType,
  MultiPartUploadStore,
  OnDropOptions,
} from './IMultiPartUploadContext';

export const MultiPartUploadContext = createContext<MultiPartUploadStore | null>(null);

function MultiPartUploadProvider({children}: {children: React.ReactNode}) {
  const {
    filesState: {files},
    addFile,
    setFilesState,
    setFileProgress,
    completeUpload,
    setFileStatus,
  } = useContext(FileContext);
  const filesRef = useRef<FileStates>();

  filesRef.current = files;

  function isUploading(filename: string): boolean {
    return filesRef.current![filename]?.status === FileUploadStatus.InProgress;
  }

  async function onDrop(
    droppedFiles: File[],
    resourceType: MultiPartUploadResourceType,
    {resourceUuid, orgUuid, setNumFilesUploading, scanStrategy, isBuildPhoto, postUploadCallback}: OnDropOptions
  ) {
    for (const file of droppedFiles) {
      if (isUploading(file.name)) {
        toast(`Duplicate file upload: ${file.name}`, {type: 'error'});
        continue;
      }

      setNumFilesUploading((numFilesLoading) => numFilesLoading + 1);
      // Initiate multipart upload
      const uploadResponse = await MULTI_PART_REQUESTS[resourceType].initiate(
        resourceUuid,
        file.name,
        file.size,
        scanStrategy
      );

      if (!uploadResponse.success) {
        setNumFilesUploading((numFilesLoading) => numFilesLoading - 1);
        continue;
      }

      const {signedUrls, filename, key, uploadId} = uploadResponse.data!;

      try {
        addFile(filename, {
          uploadId: uploadId,
          filename: filename,
          name: file.name,
          key: key,
          signedUrls: signedUrls,
          loading: new Array(signedUrls.length).fill(0),
          resourceUuid: resourceUuid,
          resourceType: resourceType,
          orgUuid: orgUuid,
          size: file.size,
          status: FileUploadStatus.InProgress,
        });

        // Wait & complete multipart upload
        const promises: Promise<AxiosResponse>[] = generateUploadPromises(signedUrls, file, filename);

        await Promise.all(promises).then((resParts: AxiosResponse[]) =>
          completeMultiPartUpload(
            resourceType,
            resParts,
            filename,
            resourceUuid,
            key,
            uploadId,
            scanStrategy!,
            isBuildPhoto!
          )
        );

        setNumFilesUploading((numFilesLoading: number) => numFilesLoading - 1);
        if (postUploadCallback) postUploadCallback();
      } catch {
        // Abort multipart upload
        await MULTI_PART_REQUESTS[resourceType].abort(uploadId, key);

        // Update context w/ error status
        setFileStatus(filename, FileUploadStatus.Error);
        setNumFilesUploading((numFilesLoading: number) => numFilesLoading - 1);

        toast('Upload failed: ' + file.name, {
          type: 'error',
        });
      }
    }
  }

  function generateUploadPromises(signedUrls: string[], file: File, filename: string) {
    const promises: Promise<AxiosResponse>[] = [];
    const source = axios.CancelToken.source();

    const numSignedUrls = signedUrls.length;
    const fileChunkSize = Math.ceil(file.size / numSignedUrls); // (bytes)

    signedUrls.forEach((signedUrl, idx) => {
      const start = idx * fileChunkSize;
      const end = (idx + 1) * fileChunkSize;
      const blob = idx < numSignedUrls - 1 ? file.slice(start, end) : file.slice(start);

      const requestConfig: RawAxiosRequestConfig = {
        headers: {
          'Content-type': file.type,
        },
        cancelToken: source.token,
        onUploadProgress: (event: AxiosProgressEvent) => {
          if (filesRef.current)
            if (filename in filesRef.current) {
              let progress = filesRef.current[filename].loading;
              progress[idx] = (event.loaded / event.total!) * 100;
              setFileProgress(filename, progress);
            } else {
              source.cancel();
            }
        },
      };
      promises.push(axios.put(signedUrl, blob, requestConfig));
    });

    return promises;
  }

  async function completeMultiPartUpload(
    resourceType: MultiPartUploadResourceType,
    resParts: AxiosResponse[],
    filename: string,
    resourceUuid: string,
    key: string,
    uploadId: string,
    scanStrategy: ScanStrategy,
    isBuildPhoto: boolean
  ) {
    if (filename in filesRef.current!) {
      const parts: {ETag: string; PartNumber: number}[] = [];
      resParts.forEach((part: AxiosResponse, partIndex: number) => {
        if (part.statusText !== 'OK') {
          throw new Error('error uploading part');
        }
        parts.push({
          ETag: (part as any).headers.etag,
          PartNumber: partIndex + 1,
        });
      });

      const resComplete = await MULTI_PART_REQUESTS[resourceType].complete(
        uploadId,
        resourceUuid,
        key,
        parts,
        scanStrategy,
        isBuildPhoto
      );
      if (!resComplete.success) {
        throw new Error('failed to complete upload');
      }

      completeUpload(filename);
    }
  }

  async function cancelUpload(filename: string) {
    // fixme how can we expose this up to multipart uploader
    const file = files[filename];
    if (file && file.status === FileUploadStatus.InProgress) {
      await MULTI_PART_REQUESTS[file.resourceType!].abort(file.uploadId, file.key);
      toast('Upload cancelled: ' + file.name, {
        type: 'info',
        toastId: file.name,
      });
    }

    setFilesState(omit(files, filename));
  }

  return (
    <MultiPartUploadContext.Provider value={{files, onDrop}}>
      {children}
      {Object.values(files).length > 0 && (
        <FileUploadUtil files={files} setState={(newState) => setFilesState(newState)} cancelUpload={cancelUpload} />
      )}
    </MultiPartUploadContext.Provider>
  );
}

export default MultiPartUploadProvider;

export const useMultiPartUpload = () => {
  const multiPartUploadContext = useContext(MultiPartUploadContext);
  if (!multiPartUploadContext) {
    throw new Error('useMultiPartUpload must be called within a MultiPartUploadProvider');
  }
  return multiPartUploadContext;
};
