// prettier-ignore
import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {withRoot} from './withRoot';
import routes from './routes/routes';
import Routes from './routes/RootRoute';
import {RootState} from './store/reducers';
import {useActions, useAuthorizationActions} from './store/actions';
import * as AuthActions from './store/actions/auth';
import {LogInState} from './store/model/auth';
import TermsDialog from './pages/TermsDialog';
import {featureFlagsGET} from './api/ajax/support';
import {setFeatureFlags} from './store/actions/supportActions';
import envConfig from './envConfig';
import {toast} from 'react-toastify';

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const allRoutes = routes(auth.user!);
  const authActions = useActions(AuthActions);
  const authorizationActions = useAuthorizationActions();

  if (auth.logInState === LogInState.Unknown) {
    authActions.authenticate();
  }

  const fetchFeatureFlags = async () => {
    const res = await featureFlagsGET();
    if (res.success) {
      dispatch(setFeatureFlags(res.data));
    }
  };

  useEffect(() => {
    if (auth.logInState === LogInState.LoggedIn) {
      fetchFeatureFlags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.logInState]);

  useEffect(() => {
    if (auth.logInState === LogInState.LoggedIn) {
      authorizationActions.ensureConsistent({userUuid: auth.user!.uuid}, {userUuid: auth.user!.uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.logInState]);

  useEffect(() => {
    if (envConfig.isOnPremise && window.location.protocol === 'http:') {
      toast(
        <>
          Connection is unsecure (http). Images won't load. Have you installed the certificate correctly?
          <br />
          <br />
          Please consult the support docs for guidance on how to install the certificate into your browser for a secure
          https connection.
        </>,
        {
          type: 'info',
          toastId: 'httpError',
          autoClose: false,
        }
      );
    }
  }, []);

  useEffect(() => {
    let pingAuthInterval: null | ReturnType<typeof setTimeout>;
    // Check for session expired due to inactivity
    if (auth.logInState === LogInState.LoggedIn) {
      pingAuthInterval = setInterval(() => authActions.authenticate(), 1000 * 60);
    }
    // HACK: reset the page every 2 hours so that websockets can be revived if they die
    const reloadTimeout = setTimeout(() => window.location.reload(), 1000 * 60 * 60 * 2);

    return () => {
      if (pingAuthInterval) {
        clearInterval(pingAuthInterval);
      }
      clearTimeout(reloadTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.logInState]);

  return (
    <React.Fragment>
      <TermsDialog user={auth.user} />
      <Routes routes={allRoutes} />
    </React.Fragment>
  );
}

export default withRoot(App);
