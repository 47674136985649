import React from 'react';
import Chart from 'react-apexcharts';

function RadialBarChart({
  options,
  series,
  height,
  otherProps,
  valueFormatter = (val) => `${val}`,
}: {
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  options?: ApexCharts.ApexOptions;
  height?: number;
  valueFormatter?: (val: any) => string;
  otherProps?: React.ComponentProps<typeof Chart>;
}) {
  const {plotOptions = {}, ...otherOptions} = options || {};
  const {radialBar = {}, ...otherPlotOptions} = plotOptions || {};

  return (
    <Chart
      type="radialBar"
      series={series}
      height={height}
      options={{
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            track: {
              background: '#ddd',
              startAngle: -135,
              endAngle: 135,
            },
            hollow: {
              margin: 20,
              size: '60%',
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '13px',
              },
              value: {
                color: '#111',
                fontSize: '30px',
                show: true,
                formatter: valueFormatter,
              },
              total: {
                show: true,
                label: 'Utilization',
                formatter: (w) => {
                  const avg =
                    w.globals.seriesTotals.reduce((a: number, b: number) => {
                      return a + b;
                    }, 0) / w.globals.series.length;
                  return avg.toFixed(0) + '%';
                },
              },
            },
            ...radialBar,
          },
          ...otherPlotOptions,
        },
        fill: {
          colors: ['#1976D2'],
        },
        stroke: {lineCap: 'round'},
        ...otherOptions,
      }}
      {...otherProps}
    />
  );
}

export default RadialBarChart;
