import React from 'react';
import {TextField, Grid, MenuItem, Tooltip, Typography} from '@material-ui/core';
import {humanize} from 'inflection';

function EnumField({
  field,
  options,
  tooltips = {},
  onChange,
  value,
  disabled,
}: {
  field: string;
  options: string[];
  tooltips?: {[option: string]: string};
  onChange: (value: string | null) => void;
  value: string | null;
  disabled: boolean;
}) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <TextField
        select
        fullWidth
        disabled={disabled || options.length === 1}
        size="small"
        variant="outlined"
        placeholder={humanize(field)}
        label={field === 'preview_render_setting' ? 'Manual focus render mode' : humanize(field) || ''}
        value={value || 'empty'}
        onChange={(e) => onChange(e.target.value === 'empty' ? null : e.target.value)}
        SelectProps={{labelWidth: 50}}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option || 'empty'}>
            {!!tooltips[option] ? (
              <Tooltip title={<Typography>{tooltips[option]}</Typography>}>
                <div>{option ? option : 'No output'}</div>
              </Tooltip>
            ) : (
              <>{option ? option : 'No output'}</>
            )}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
}

export default EnumField;
