import React from 'react';
import {Box} from '@material-ui/core';
import {Control, useWatch} from 'react-hook-form';
import {
  IDefectNotificationConditions,
  ConditionGroup as IConditionGroup,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import AddCondition from './AddCondition';
import DisplayAndChangeOperator from './DisplayAndChangeOperator';
import SingleConditionForm from './SingleCondition';
import {grayColor} from '../../../../../assets/jss/material-dashboard-react';

function ConditionGroup({
  path,
  control,
  errors,
  reset,
  rootIndex,
}: {
  path: string;
  control: Control<IDefectNotificationConditions>;
  errors: any;
  reset: (newForm: IDefectNotificationConditions) => void;
  rootIndex: number;
}) {
  const conditionGroup: IConditionGroup | undefined = useWatch({name: path, control: control});
  const conditions = conditionGroup?.conditions;

  return (
    <>
      <Box
        height="20px"
        width="100%"
        marginTop="10px"
        borderTop={`3px solid ${grayColor[0]}`}
        borderRadius="10% / 50%"
      />

      {conditions?.map((_condition, index) => (
        <React.Fragment key={`${path}.conditions[${index}]`}>
          <SingleConditionForm
            path={`${path}.conditions[${index}]`}
            rootPath={path}
            control={control}
            errors={errors}
            reset={reset}
            index={index}
            rootIndex={rootIndex}
          />
          {index !== conditions.length - 1 && <DisplayAndChangeOperator path={path} control={control} reset={reset} />}
        </React.Fragment>
      ))}

      <AddCondition path={path} control={control} reset={reset} />

      <Box
        height="20px"
        width="100%"
        paddingTop="10px"
        borderBottom={`3px solid ${grayColor[0]}`}
        borderRadius="10% / 50%"
      />
    </>
  );
}

export default ConditionGroup;
