import React from 'react';
import BottomToolbarContainer from '../../../components/atoms/BottomToolbarContainer';
import {Menu} from '@material-ui/icons';
import {Box, Button, Drawer, makeStyles} from '@material-ui/core';
import DraftBuildProgressTracker, {usePercentComplete} from './DraftBuildProgressTracker';
import {useActiveStep} from './ActiveStepContext';
import buildSteps from './draftBuildStepsConfig';
import {NextStep, PreviousStep, SaveAndStart} from './StepNavigationButtons';
import EllipsisTextWithTooltip from '../../../components/atoms/Texts/EllipsisTextWithTooltip';
import LinearProgressWithLabel from '../../../components/atoms/LinearProgressWithLabel';
import {useMobileDrawer} from '../../../utils/utilHooks';

const DraftBuildBottomToolbar = () => {
  const drawerStyles = useDrawerStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useMobileDrawer();
  const {activeStep, activeStepId} = useActiveStep();
  const {progressPercent} = usePercentComplete();

  const isLastStep = activeStepId === buildSteps.length - 1;

  return (
    <BottomToolbarContainer columns="60% 20% 20%">
      <Button
        variant="text"
        color="primary"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        style={{justifySelf: 'self-start', width: '100%'}}
      >
        <Menu />
        <Box display="flex" flexDirection="column" alignItems="flex-start" ml={3} width="calc(100% - 32px)">
          <EllipsisTextWithTooltip align="left" style={{width: '100%'}}>
            {activeStep.title}
          </EllipsisTextWithTooltip>
          <LinearProgressWithLabel value={progressPercent} style={{borderRadius: '4px'}} />
        </Box>
      </Button>

      <PreviousStep />
      {isLastStep ? <SaveAndStart /> : <NextStep />}

      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} classes={drawerStyles}>
        <DraftBuildProgressTracker onSelect={() => setIsDrawerOpen(false)} />
      </Drawer>
    </BottomToolbarContainer>
  );
};

export default DraftBuildBottomToolbar;

const useDrawerStyles = makeStyles({
  paperAnchorBottom: {
    bottom: '4rem !important',
  },
  root: {
    '& .MuiBackdrop-root': {
      bottom: '4rem !important',
    },
  },
  modal: {
    zIndex: '1298 !important' as any,
  },
});
