import React from 'react';
import {Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import Flare from '@material-ui/icons/Flare';
import {RootState} from '../../../../store/reducers/index';
import {successColor, grayColor} from '../../../../assets/jss/material-dashboard-react';
import FieldOfViewStates, {CAMERA_ORDER} from './FieldOfViewStates';

const LASER_STATE_HELPER_TEXT =
  'Laser state on signifies that we are detecting light inside the chamber, which we understand ' +
  'to be emitted from the laser. If the laser is not currently active, but laser state is on, there ' +
  'may be too much light inside the chamber and we may not be able to detect the end of the layer correctly.';

function LaserState({deviceSerial}: {deviceSerial?: string}) {
  const device = useSelector((state: RootState) => state.deviceStore.byId[deviceSerial!]);

  const fieldOfViews = CAMERA_ORDER.map((fov) => fov.map((id) => device?.laserStates?.[id] ?? null));
  const globalLaserState = Object.values(device?.laserStates || {}).some((laserState) => laserState);

  if (!fieldOfViews || !device?.online) return <></>;

  return (
    <FieldOfViewStates
      fieldOfViews={fieldOfViews}
      icon={<Flare />}
      globalStateTitle={
        <Typography>
          Global Laser State:{' '}
          <b style={globalLaserState ? {color: successColor[1]} : {}}>{globalLaserState ? 'LASER ON' : 'LASER OFF'}</b>
        </Typography>
      }
      heading="Individual Laser States"
      globalState={globalLaserState}
      offColour={grayColor[3]}
      helperText={LASER_STATE_HELPER_TEXT}
      placement="right"
    />
  );
}

export default LaserState;
