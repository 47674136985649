import React, {useEffect, useState} from 'react';
import {Grid, ModalProps, TextField, Typography} from '@material-ui/core';

import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {RootState} from '../../../store/reducers';
import {useBatchStoreActions} from '../../../store/actions';
import {batchesMergePOST} from '../../../api/ajax/batches';
import BatchMaterialCompositionWidget from '../Dashboard/BatchMaterialCompositionWidget';
import {BATCH_DESCRIPTION_LIMIT, BATCH_NAME_LIMIT, IBatch} from '@common/api/models/materials/batches/IBatch';
import {mergeBatches} from '@common/api/models/materials/batches/Batch.utils';
import {cloneSimple} from '@common/utils/utils';
import {BatchSelectorCard} from '../Selector/BatchSelectorCard';
import {GenericDialog} from '../DialogButton';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';

export interface BatchMergeModalProps extends Partial<ModalProps> {
  open: boolean;
  initialBatchAUuid?: string;
  initialBatchBUuid?: string;
  redirect?: (uuid?: string) => void;
  onClose: () => void;
}

function MergedBatchView(props: {merged: IBatch; onChange: (batch: IBatch) => any}) {
  const isXsScreen = useExtraSmallScreenSize();

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" style={{width: '100%'}}>
      <Grid item>
        <BatchMaterialCompositionWidget batch={props.merged} elevation={0} compact />
      </Grid>
      <Grid item container spacing={isXsScreen ? 3 : 4} style={{paddingTop: '18px'}}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            fullWidth
            variant="outlined"
            type="text"
            size="small"
            value={props.merged.name}
            onChange={(e) => props.onChange({...props.merged, name: e.target.value})}
            error={props.merged.name.length > BATCH_NAME_LIMIT}
            helperText={
              !!props.merged.name && props.merged.name.length > BATCH_NAME_LIMIT
                ? `Name must be less than ${BATCH_NAME_LIMIT} chars`
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            label="Description"
            size="small"
            multiline
            maxRows={7}
            value={props.merged.description}
            onChange={(e) => props.onChange({...props.merged, description: e.target.value})}
            error={!!props.merged.description && props.merged.description.length > BATCH_DESCRIPTION_LIMIT}
            helperText={
              !!props.merged.description && props.merged.description.length > BATCH_DESCRIPTION_LIMIT
                ? `Description must be less than ${BATCH_DESCRIPTION_LIMIT} chars`
                : undefined
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export function BatchMergeModal(props: BatchMergeModalProps) {
  const batchStoreActions = useBatchStoreActions();
  const {initialBatchAUuid, initialBatchBUuid} = props;

  const batchStore = useSelector((state: RootState) => {
    return state.batchStore;
  });

  const [selectedBatchAUuid, setSelectedBatchAUuid] = useState<string | undefined>(initialBatchAUuid);
  const [selectedBatchBUuid, setSelectedBatchBUuid] = useState<string | undefined>(initialBatchBUuid);
  const [batchA, setBatchA] = useState<IBatch | undefined>(undefined);
  const [batchB, setBatchB] = useState<IBatch | undefined>(undefined);
  const [mergedBatch, setMergedBatch] = useState<IBatch | undefined>(undefined);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    if (selectedBatchAUuid || selectedBatchBUuid) {
      batchStoreActions.ensureConsistent({
        uuid: [selectedBatchAUuid!, selectedBatchBUuid!].filter((a) => a),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBatchAUuid, selectedBatchBUuid]);

  useEffect(() => {
    setSelectedBatchAUuid(props.initialBatchAUuid);
    setSelectedBatchBUuid(props.initialBatchBUuid);
  }, [props.initialBatchAUuid, props.initialBatchBUuid]);

  useEffect(() => {
    const batchA = selectedBatchAUuid ? batchStore.byId[selectedBatchAUuid] : undefined;
    const batchB = selectedBatchBUuid ? batchStore.byId[selectedBatchBUuid] : undefined;
    setBatchA(batchA);
    setBatchB(batchB);
  }, [selectedBatchAUuid, selectedBatchBUuid, batchStore.byId, batchStore.fetched]);

  const onSubmit = async () => {
    if (!selectedBatchAUuid || !selectedBatchBUuid) {
      toast('Batch A and B must be selected', {type: 'warning'});
      return;
    }
    setRequesting(true);
    const res = await batchesMergePOST(selectedBatchAUuid, selectedBatchBUuid, mergedBatch || {});
    setRequesting(false);
    props.onClose();
    if (res.success) {
      if (props.redirect) {
        props.redirect(res.data.uuid);
      }
    }
  };

  useEffect(() => {
    if (batchA && batchB) {
      // useEffect triggered but no need to update as the batches to merge are the same.
      if (mergedBatch?.parentAUuid === batchA.uuid && mergedBatch.parentBUuid === batchB.uuid) {
        return;
      }
      setMergedBatch(mergeBatches(cloneSimple(batchA), cloneSimple(batchB)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchA, batchB, batchStore.fetched]);

  return (
    <GenericDialog
      maxWidth="sm"
      fullWidth
      title="Merge Batches"
      isOpen={props.open}
      closeDialog={props.onClose}
      onSuccess={onSubmit}
      confirmText="Merge"
      confirmDisabled={
        !!mergedBatch &&
        (mergedBatch.name.length > BATCH_NAME_LIMIT || (mergedBatch.description?.length || 0) > BATCH_DESCRIPTION_LIMIT)
      }
      requestInProgress={requesting}
      content={
        <Grid container style={{width: '100%'}} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Select two Batches to Merge:</Typography>
          </Grid>
          <Grid item xs={12}>
            <BatchSelectorCard selectedBatchUuid={selectedBatchAUuid} setSelectedBatchUuid={setSelectedBatchAUuid} />
          </Grid>
          <Grid item xs={12}>
            <BatchSelectorCard selectedBatchUuid={selectedBatchBUuid} setSelectedBatchUuid={setSelectedBatchBUuid} />
          </Grid>
          <Grid item xs={12}>
            {mergedBatch ? (
              <MergedBatchView merged={mergedBatch} onChange={setMergedBatch} />
            ) : (
              <i>Choose two batches to merge</i>
            )}
          </Grid>
        </Grid>
      }
    />
  );
}

export default BatchMergeModal;
