import {IBuildIntensityReportGETResponse} from '@common/api/models/builds/data/IBuildIntensityReport';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {LiveStoreReducers} from '../liveUpdateStore';
import {
  FetchPartsForBuildAction,
  BuildIntensityReportStoreState,
  BuildIntensityReportActions,
  SelectedBuildsAction,
  SelectedPartsAction,
  IntensityCSVsAction,
  GraphDataTypeAction,
  GraphDataToggleAction,
  RollingPeriodAction,
  ChartDataTypeAction,
  CreateIntensityReportAction,
  DeleteIntensityReportAction,
} from '../../model/buildIntensityReport';
import {FetchingState, LiveStoreState} from '../../model/liveUpdateStore';
import createReducer from '../createReducer';

export class BuildIntensityReportLiveReducers extends LiveStoreReducers<
  IBuildIntensityReportGETResponse,
  BuildIntensityReportStoreState
> {
  additionalReducers() {
    return {
      createIntensityReportSuccess(
        state: BuildIntensityReportStoreState,
        {payload: {report}}: CreateIntensityReportAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,
          byId: {[report.uuid]: report, ...state.byId},
        };
      },
      clearIntensityReportSuccess(state: BuildIntensityReportStoreState): BuildIntensityReportStoreState {
        return {
          ...state,
          fetched: FetchingState.None,
          byId: {},
          selectedBuilds: new Set(),
          selectedParts: new Set(),
        };
      },
      addSelectedBuild(
        state: BuildIntensityReportStoreState,
        {payload: {buildUuid, buildName}}: SelectedBuildsAction
      ): BuildIntensityReportStoreState {
        const newSelectedBuilds = new Set<string>(state.selectedBuilds);
        newSelectedBuilds.add(buildUuid);

        return {
          ...state,
          selectedBuilds: newSelectedBuilds,
          buildNamesByBuildUuid: {
            ...state.buildNamesByBuildUuid,
            [buildUuid]: buildName,
          },
        };
      },
      removeSelectedBuild(
        state: BuildIntensityReportStoreState,
        {payload: {buildUuid}}: SelectedBuildsAction
      ): BuildIntensityReportStoreState {
        const newSelectedBuilds = new Set<string>(state.selectedBuilds);
        newSelectedBuilds.delete(buildUuid);

        return {
          ...state,
          selectedBuilds: newSelectedBuilds,
        };
      },
      addSelectedPart(
        state: BuildIntensityReportStoreState,
        {payload: {part}}: SelectedPartsAction
      ): BuildIntensityReportStoreState {
        const newSelectedParts = new Set<IPartGETResponse>(state.selectedParts);
        newSelectedParts.add(part);
        return {
          ...state,
          selectedParts: newSelectedParts,
        };
      },
      removeSelectedPart(
        state: BuildIntensityReportStoreState,
        {payload: {part}}: SelectedPartsAction
      ): BuildIntensityReportStoreState {
        const newSelectedParts = new Set<IPartGETResponse>(state.selectedParts);
        newSelectedParts.delete(part);
        return {
          ...state,
          selectedParts: newSelectedParts,
        };
      },
      addIntensityCSVs(
        state: BuildIntensityReportStoreState,
        {payload: {buildUuid, newPartCSVs, newSummaryCSVs}}: IntensityCSVsAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,
          partCSVs: {...state.partCSVs, [buildUuid]: newPartCSVs},
          summaryCSVs: {...state.summaryCSVs, ...newSummaryCSVs},
        };
      },
      fetchPartsForBuildSuccess(
        state: BuildIntensityReportStoreState,
        {payload: {parts, buildUuid, total}}: FetchPartsForBuildAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,

          partsByBuildUuid: {
            ...state.partsByBuildUuid,
            [buildUuid]: {
              parts: [...(state.partsByBuildUuid[buildUuid]?.parts || []), ...parts],
              total,
            },
          },
        };
      },
      setGraphDataType(
        state: BuildIntensityReportStoreState,
        {payload: {graphDataType}}: GraphDataTypeAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,
          graphDataType: graphDataType,
        };
      },
      setGraphDataToggles(
        state: BuildIntensityReportStoreState,
        {payload: {toggleName, bool}}: GraphDataToggleAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,
          graphDataToggles: {...state.graphDataToggles, [toggleName]: bool},
        };
      },
      setRollingPeriod(
        state: BuildIntensityReportStoreState,
        {payload: {rollingPeriod}}: RollingPeriodAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,
          rollingPeriod: rollingPeriod,
        };
      },
      setChartDataType(
        state: BuildIntensityReportStoreState,
        {payload: {chartDataType}}: ChartDataTypeAction
      ): BuildIntensityReportStoreState {
        return {
          ...state,
          chartDataType: chartDataType,
        };
      },
      clearIntensityReportSelections(state: BuildIntensityReportStoreState): BuildIntensityReportStoreState {
        return {
          ...state,
          selectedBuilds: new Set(),
          selectedParts: new Set(),
        };
      },
      deleteIntensityReport(
        state: BuildIntensityReportStoreState,
        {payload: {reportUuid}}: DeleteIntensityReportAction
      ): BuildIntensityReportStoreState {
        const {[reportUuid]: _, ...byId} = state.byId;
        return {
          ...state,
          byId: byId,
        };
      },
    };
  }

  createReducer(initialState: BuildIntensityReportStoreState) {
    const superReducers = this.bindReducers();
    const additionalReducers = this.additionalReducers();

    return createReducer<LiveStoreState<IBuildIntensityReportGETResponse>>(initialState, {
      ...superReducers,
      [BuildIntensityReportActions.ADD_SELECTED_BUILD]: additionalReducers.addSelectedBuild,
      [BuildIntensityReportActions.REMOVE_SELECTED_BUILD]: additionalReducers.removeSelectedBuild,
      [BuildIntensityReportActions.ADD_SELECTED_PART]: additionalReducers.addSelectedPart,
      [BuildIntensityReportActions.REMOVE_SELECTED_PART]: additionalReducers.removeSelectedPart,
      [BuildIntensityReportActions.FETCH_PARTS_FOR_BUILD_SUCCESS]: additionalReducers.fetchPartsForBuildSuccess,
      [BuildIntensityReportActions.SET_GRAPH_DATA_TYPE]: additionalReducers.setGraphDataType,
      [BuildIntensityReportActions.SET_GRAPH_DATA_TOGGLE]: additionalReducers.setGraphDataToggles,
      [BuildIntensityReportActions.SET_ROLLING_PERIOD]: additionalReducers.setRollingPeriod,
      [BuildIntensityReportActions.SET_CHART_DATA_TYPE]: additionalReducers.setChartDataType,
      [BuildIntensityReportActions.ADD_INTENSITY_CSVS]: additionalReducers.addIntensityCSVs,
      [BuildIntensityReportActions.CREATE_BUILD_INTENSITY_REPORT_SUCCESS]:
        additionalReducers.createIntensityReportSuccess,
      [BuildIntensityReportActions.CLEAR_INTENSITY_REPORTS]: additionalReducers.clearIntensityReportSuccess,
      [BuildIntensityReportActions.CLEAR_INTENSITY_REPORTS_SELECTIONS]:
        additionalReducers.clearIntensityReportSelections,
      [BuildIntensityReportActions.DELETE_BUILD_INTENSITY_REPORT_SUCCESS]: additionalReducers.deleteIntensityReport,
    });
  }
}
