import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import buildSteps from './draftBuildStepsConfig';
import ActiveStepProvider from './ActiveStepContext';
import DraftBuildProgressTracker from './DraftBuildProgressTracker';
import ActiveStep from './ActiveStep';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {isEqual} from 'lodash';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import DraftBuildBottomToolbar from './DraftBuildBottomToolbar';
import {DraftBuildProps} from './DraftBuild';
import LoadingPage from '../../../components/organisms/LoadingPage';

function AdvancedDraftBuild({build}: DraftBuildProps) {
  const isSmallScreen = useSmallScreenSize();
  const {draftStep, uuid} = useParams<{uuid: string; draftStep?: string}>();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const slices = useSelector(
    (state: RootState) => Object.values(state.sliceAttachmentStore.byId).filter((s) => s.buildUuid === build.uuid),
    isEqual
  );
  const slicesFetchedState = useSelector((state: RootState) => state.sliceAttachmentStore.fetched);

  useEffect(() => {
    if (!draftStep && [FetchingState.Fetched, FetchingState.Error].includes(slicesFetchedState)) {
      const currentStep =
        buildSteps.find((step) => !step.completed(build, slices)) || buildSteps[buildSteps.length - 1];

      history.replace(`/builds/uuid/${uuid}/draft/advanced/${currentStep.id}/`);
      setLoading(false);
    }

    if (draftStep) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, slicesFetchedState]);

  if (loading) return <LoadingPage loadingText="Loading Build..." />;

  return (
    <ActiveStepProvider>
      <Grid container spacing={isSmallScreen ? 3 : 6}>
        {!isSmallScreen && (
          <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
            <DraftBuildProgressTracker />
          </Grid>
        )}

        <Grid item xl={8} lg={7} md={6} sm={12} xs={12}>
          <ActiveStep />
        </Grid>
      </Grid>
      {isSmallScreen && <DraftBuildBottomToolbar />}
    </ActiveStepProvider>
  );
}

export default AdvancedDraftBuild;
