import {BuildState} from '@common/api/models/builds/IBuild';
import {IPartGETRequest} from '@common/api/models/builds/data/IPart';
import {buildsByPartQueryGET} from '../../../../api/ajax/parts';
import {QueryFilter} from '../../../../store/actions/liveUpdateStore';
import {FilterSchemaType} from './index';

const partFilters: Array<FilterSchemaType> = [
  {field: 'name', type: 'string'},
  {
    field: 'buildUuid',
    label: 'build',
    type: 'resourceSelect',
    searchGET: (filters: QueryFilter<IPartGETRequest>) =>
      buildsByPartQueryGET({
        ...filters,
        buildState: {
          in: [BuildState.FAILED, BuildState.COMPLETED, BuildState.SUCCEEDED],
        },
      }),
    searchField: 'buildName',
    valueField: 'buildUuid',
  },
  {field: 'buildEndDate', type: 'dateRange', label: 'Build End Date'},
];

export default partFilters;
