import React from 'react';
import {Box, Button, CircularProgress} from '@material-ui/core';

import {ISensorProfileGETResponse} from '@common/api/models/devices/ISensorProfile';

import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {dangerColor} from '../../../../assets/jss/material-dashboard-react';
import {useExtraSmallScreenSize} from '../../../../utils/utilHooks';

interface IFormActionButtons {
  isNewProfile: boolean;
  profile?: ISensorProfileGETResponse;
  saving: boolean;
  onResetDefaults: () => void;
  onCancel: () => void;
  onSave: () => void;
  onDelete?: () => void;
  deleting?: boolean;
  hasError: boolean;
  hasUnsavedChanges: boolean;
  saveTooltip?: string;
  deviceOffline?: boolean;
}

function FormActionButtons({
  isNewProfile,
  profile,
  saving,
  onResetDefaults,
  onCancel,
  onSave,
  onDelete,
  deleting,
  hasError,
  hasUnsavedChanges,
  saveTooltip,
  deviceOffline,
}: IFormActionButtons) {
  const isXsScreen = useExtraSmallScreenSize();

  return (
    <Box width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
      {!isNewProfile && !!onDelete && (
        <ConditionalTooltip
          tooltip="Can't delete profile as it has been used by a build"
          hideTooltip={(profile?.buildsCount || 0) === 0}
          spanStyle={{marginRight: 'auto'}}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth={isXsScreen}
            onClick={onDelete}
            disabled={deleting || (profile?.buildsCount || 0) > 0}
            style={{
              ...(deleting ? {} : {backgroundColor: dangerColor[0], color: 'white'}),
              marginRight: 'auto',
            }}
          >
            Delete {deleting ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
          </Button>
        </ConditionalTooltip>
      )}
      <Button
        variant="outlined"
        color="primary"
        fullWidth={isXsScreen}
        onClick={onResetDefaults}
        disabled={saving || (!!profile && !profile?.deviceSerial)}
        style={isXsScreen ? {marginTop: '12px'} : {marginRight: '12px'}}
      >
        Reset to defaults
      </Button>
      <Button
        variant="outlined"
        color="primary"
        fullWidth={isXsScreen}
        onClick={onCancel}
        disabled={saving || (!isNewProfile && !hasUnsavedChanges)}
        style={isXsScreen ? {marginTop: '12px'} : {marginRight: '12px'}}
      >
        Cancel
      </Button>
      <ConditionalTooltip
        tooltip={
          deviceOffline
            ? 'Device is currently offline'
            : hasError
            ? 'Please correct errors before saving'
            : saveTooltip
            ? saveTooltip
            : ''
        }
        hideTooltip={!deviceOffline && !hasError && !saveTooltip}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth={isXsScreen}
          onClick={onSave}
          disabled={
            deviceOffline ||
            hasError ||
            saving ||
            (!!profile && !profile.name) ||
            (!!profile && !profile.deviceSerial) ||
            (!isNewProfile && !hasUnsavedChanges)
          }
          style={isXsScreen ? {marginTop: '12px'} : {}}
        >
          Save {saving ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
        </Button>
      </ConditionalTooltip>
    </Box>
  );
}

export default FormActionButtons;
