import React from 'react';
import {Tooltip} from '@material-ui/core';
import {ViewerButtonActive, ViewerButton, ActiveDiv} from '../FadingComponents';
import {BUTTON_MARGIN, BUTTON_WIDTH} from '../2D/utils';

interface ViewSelectorButtonProps {
  mouseOn: boolean;
  onClick?: () => void;
  disabled?: boolean;
  tooltip: string;
  buttonText: string;
}

function ViewSelectorButtonActive({mouseOn, tooltip, buttonText}: ViewSelectorButtonProps) {
  return (
    <Tooltip title={tooltip} placement="left">
      <ViewerButtonActive
        onClick={() => undefined}
        style={{
          left: BUTTON_MARGIN * 2,
          bottom: BUTTON_MARGIN,
          position: 'absolute',
        }}
        className={(!mouseOn && 'fade') || undefined}
      >
        <ActiveDiv>
          <strong>{buttonText}</strong>
        </ActiveDiv>
      </ViewerButtonActive>
    </Tooltip>
  );
}

function ViewSelectorButtonInactive({mouseOn, onClick, disabled, tooltip, buttonText}: ViewSelectorButtonProps) {
  return (
    <Tooltip title={tooltip} placement="left">
      <ViewerButton
        onClick={onClick}
        style={{
          ...{
            left: BUTTON_WIDTH + BUTTON_MARGIN * 2,
            bottom: BUTTON_MARGIN,
            position: 'absolute',
          },
          // Override color because it becomes invisible otherwise when disabled
          // Override pointerEvents so that tooltip shows
          ...(disabled ? {color: 'grey', pointerEvents: 'auto'} : {pointerEvents: 'auto'}),
        }}
        className={(!mouseOn && 'fade') || undefined}
        disabled={disabled}
      >
        {buttonText}
      </ViewerButton>
    </Tooltip>
  );
}

interface ViewSelectorProps {
  on3DToggle: () => void;
  mouseOn: boolean;
  viewport3dAvailable: boolean;
  is3DView: boolean;
}

export default function ViewSelector({mouseOn, on3DToggle, viewport3dAvailable, is3DView}: ViewSelectorProps) {
  const [TwoDButtonType, ThreeDButtonType] = is3DView
    ? [ViewSelectorButtonInactive, ViewSelectorButtonActive]
    : [ViewSelectorButtonActive, ViewSelectorButtonInactive];

  return (
    <>
      <TwoDButtonType mouseOn={mouseOn} onClick={on3DToggle} tooltip="2D view" buttonText="2D" />

      <ThreeDButtonType
        mouseOn={mouseOn}
        onClick={on3DToggle}
        disabled={!viewport3dAvailable}
        tooltip={
          viewport3dAvailable
            ? '3D view'
            : '3D view is unavailable without valid user-uploaded slice file data. ' +
              'If slices were recently uploaded, 3D models may still be processing.'
        }
        buttonText="3D"
      />
    </>
  );
}
