import React, {useState} from 'react';
import {Collapse, Box, IconButton, TableCell, TableRow} from '@material-ui/core';
import {Edit, ExpandLess, ExpandMore} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import EllipsisTextWithTooltip from '../../../../../components/atoms/Texts/EllipsisTextWithTooltip';

const ConfirmationRow = ({
  children,
  field,
  loading,
  url,
  collapseComponent,
}: {
  field: string;
  children: React.ReactNode;
  loading: boolean;
  url?: string;
  collapseComponent?: React.ReactNode;
}) => {
  const [expanded, SetExpanded] = useState(false);

  if (loading) return <RowLoading />;

  return (
    <>
      <TableRow>
        <TableCell>
          <b>{field}</b>
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <EllipsisTextWithTooltip>{children}</EllipsisTextWithTooltip>
            {collapseComponent ? (
              <IconButton onClick={() => SetExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            ) : (
              <></>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {url ? (
            <Link to={url}>
              <IconButton onClick={() => {}}>
                <Edit />
              </IconButton>
            </Link>
          ) : (
            <div />
          )}
        </TableCell>
      </TableRow>
      {collapseComponent && (
        <TableRow>
          <Collapse
            in={expanded}
            component={(props: any) => <CollapseRowsComponent {...props} expanded={expanded} />}
            timeout="auto"
          >
            {collapseComponent}
          </Collapse>
        </TableRow>
      )}
    </>
  );
};

export default ConfirmationRow;

const CollapseRowsComponent = (props: any) => (
  <CollapseTableCell colSpan={3} expanded={props.expanded ? 1 : 0}>
    <div className={props.className}>{props.children}</div>
  </CollapseTableCell>
);

const RowLoading = () => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton height="25px" />
      </TableCell>
      <TableCell>
        <Skeleton height="25px" />
      </TableCell>
      <TableCell align="right">
        <Skeleton variant="circle" width="35px" height="35px" />
      </TableCell>
    </TableRow>
  );
};

const CollapseTableCell = styled(TableCell)<{expanded: number}>`
  && {
    ${({expanded}) => !expanded && 'padding: 0px;'}
    ${({expanded}) => !expanded && 'border: none;'}
  }
`;
