import React from 'react';
import {Tooltip} from '@material-ui/core';
import {ViewerButton, ViewerButtonActive, ActiveDiv} from '../../FadingComponents';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../utils';
import {LiveTv} from '@material-ui/icons';

interface LiveUpdatingProps {
  isLiveUpdating: boolean;
  handleLiveClicked: (liveUpdating: boolean) => void;
  stageHeight: number;
  mouseOn: boolean;
}

export default function LiveUpdating({handleLiveClicked, isLiveUpdating, stageHeight, mouseOn}: LiveUpdatingProps) {
  return (
    <Tooltip title={isLiveUpdating ? 'Stop live updating' : 'Start live updating'} placement="left">
      {isLiveUpdating ? (
        <ViewerButtonActive
          onClick={() => handleLiveClicked(false)}
          style={{
            right: BUTTON_MARGIN,
            top: stageHeight - BUTTON_HEIGHT * 7,
            position: 'absolute',
            paddingRight: 0,
          }}
          className={(!mouseOn && 'fade') || undefined}
        >
          <ActiveDiv>
            <LiveTv />
          </ActiveDiv>
        </ViewerButtonActive>
      ) : (
        <ViewerButton
          onClick={() => handleLiveClicked(true)}
          style={{
            right: BUTTON_MARGIN,
            top: stageHeight - BUTTON_HEIGHT * 7,
            position: 'absolute',
            paddingRight: 0,
          }}
          className={(!mouseOn && 'fade') || undefined}
        >
          <LiveTv />
        </ViewerButton>
      )}
    </Tooltip>
  );
}
