import React, {useRef} from 'react';
import {toast} from 'react-toastify';
import {makeStyles, Tooltip} from '@material-ui/core';
import {NotListedLocation} from '@material-ui/icons';
import {ActiveDiv, ViewerButton, ViewerButtonActive} from '../../FadingComponents';
import {BUTTON_MARGIN} from '../utils';
import {grayColor} from '../../../../../assets/jss/material-dashboard-react';

const useStyles = makeStyles(() => ({
  disabled: {color: `${grayColor[1]} !important`},
}));

interface Props {
  checked: boolean;
  setChecked: (val: boolean) => void;
  mouseOn: boolean;
  boundingBoxesAvailable: boolean;
  cssTop: number;
}

export const BoundingBoxToggle = ({checked, setChecked, mouseOn, boundingBoxesAvailable, cssTop}: Props) => {
  const styles = useStyles();
  const containerRef = useRef(null);

  const toggleBoundingBoxes = async () => {
    if (!checked) {
      // @ts-ignore
      if (navigator.brave && navigator.brave.isBrave) {
        // @ts-ignore
        const isBrave = await navigator.brave.isBrave();
        if (isBrave) {
          toast(
            'Hovering your mouse over a bounding box may be unreliable in Brave browser while Brave shields are enabled.',
            {type: 'info'}
          );
        }
      }
    }

    setChecked(!checked);
  };

  return (
    <Tooltip
      title={
        <div style={{width: '184px'}}>
          {boundingBoxesAvailable
            ? `${checked ? 'Disable' : 'Activate'} Part labels and bounding boxes`
            : 'Part labels and bounding boxes currently unavailable'}
        </div>
      }
      placement="left"
      PopperProps={{container: containerRef.current}}
    >
      <span style={{right: BUTTON_MARGIN, top: cssTop, position: 'absolute'}} ref={containerRef}>
        {checked ? (
          <ViewerButtonActive
            onClick={toggleBoundingBoxes}
            className={(!mouseOn && 'fade') || undefined}
            classes={{disabled: styles.disabled}}
            disabled={!boundingBoxesAvailable}
          >
            <ActiveDiv>
              <NotListedLocation />
            </ActiveDiv>
          </ViewerButtonActive>
        ) : (
          <ViewerButton
            onClick={toggleBoundingBoxes}
            className={(!mouseOn && 'fade') || undefined}
            classes={{disabled: styles.disabled}}
            disabled={!boundingBoxesAvailable}
          >
            <NotListedLocation />
          </ViewerButton>
        )}
      </span>
    </Tooltip>
  );
};
