import React from 'react';
import {Tooltip} from '@material-ui/core';
import {GpsFixed} from '@material-ui/icons';
import {ViewerButton, ViewerButtonActive, ActiveDiv} from '../../FadingComponents';
import {BUTTON_MARGIN, BUTTON_HEIGHT} from '../utils';

interface LinkViewProps {
  handleLinkedPosition: () => void;
  mouseOn: boolean;
  linkedPosition: boolean | undefined;
}

export default function LinkView({handleLinkedPosition, mouseOn, linkedPosition}: LinkViewProps) {
  return (
    <>
      {linkedPosition ? (
        <Tooltip title="Unlink view" placement="left">
          <ViewerButtonActive
            onClick={handleLinkedPosition}
            style={{
              right: BUTTON_MARGIN,
              top: BUTTON_HEIGHT * 2 + BUTTON_MARGIN,
              position: 'absolute',
            }}
            className={(!mouseOn && 'fade') || undefined}
          >
            <ActiveDiv>
              <GpsFixed />
            </ActiveDiv>
          </ViewerButtonActive>
        </Tooltip>
      ) : (
        <Tooltip title="Link view" placement="left">
          <ViewerButton
            onClick={handleLinkedPosition}
            style={{
              right: BUTTON_MARGIN,
              top: BUTTON_HEIGHT * 2 + BUTTON_MARGIN,
              position: 'absolute',
            }}
            className={(!mouseOn && 'fade') || undefined}
          >
            <GpsFixed />
          </ViewerButton>
        </Tooltip>
      )}
    </>
  );
}
