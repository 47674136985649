import * as React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

export function ExpiredEmailConfirmation() {
  toast('Email confirmation token expired', {type: 'warning'});

  const history = useHistory();
  history.replace('/');

  return <Redirect to={'/'} />;
}
