import {Checkbox, ListItemText, MenuItem, TextField} from '@material-ui/core';
import {inflect} from 'inflection';
import React from 'react';

interface CameraSelectProps {
  availableCameras: number[];
  selectedCameras: number[];
  setSelectedCameras: (selection: number[]) => void;
  disabled?: boolean;
}

type CameraSelectEvent = React.ChangeEvent<{name?: string; value: unknown}>;

export function CameraSelect({availableCameras, selectedCameras, setSelectedCameras, disabled}: CameraSelectProps) {
  function handleChange(event: CameraSelectEvent) {
    const selectedValues = event.target.value as string[];
    let values = selectedValues.filter((value) => value !== 'toggle');

    if (selectedValues.includes('toggle') && availableCameras.length !== selectedCameras.length) {
      values = availableCameras.map((cameraNum) => cameraNum.toString());
    }
    if (!selectedValues.includes('toggle') && availableCameras.length === selectedCameras.length) {
      values = [];
    }
    setSelectedCameras(values.map((value) => Number(value)));
  }

  function renderValue() {
    if (selectedCameras.length === availableCameras.length) return 'All cameras';

    if (selectedCameras.length)
      return `${inflect('Camera', selectedCameras.length)} ${selectedCameras.sort().join(', ')}`;

    return 'None selected';
  }

  const allSelected = selectedCameras.length === availableCameras.length;
  const values = allSelected ? [...selectedCameras, 'toggle'] : selectedCameras;

  return (
    <div>
      <TextField
        select
        fullWidth
        disabled={disabled}
        label="Selected Cameras"
        placeholder="Select Cameras..."
        size="small"
        variant="outlined"
        value={values}
        onChange={handleChange}
        SelectProps={{
          multiple: true,
          labelWidth: 50,
          displayEmpty: true,
          renderValue: renderValue,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          },
        }}
        InputLabelProps={{shrink: true}}
      >
        <MenuItem key={`${availableCameras.length}-toggle-all`} value={'toggle'}>
          <Checkbox checked={allSelected} size="small" />
          <ListItemText primary={allSelected ? 'Deselect all' : 'Select all'} />
        </MenuItem>

        {availableCameras.map((cameraNum, i) => (
          <MenuItem key={i} value={cameraNum}>
            <Checkbox checked={selectedCameras.includes(cameraNum)} size="small" />
            <ListItemText primary={`Camera ${cameraNum}`} />
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
