import {assertUnreachable} from '../../../utils/utils';
import {IUser, Role} from './IUser';
import {IOrganization} from '../organization/IOrganization';

export function canEditUser(user: IUser, editor: IUser): boolean {
  if (editor.role === Role.NONE) {
    return false;
  }

  if (editor.uuid === user.uuid) {
    return true;
  }

  if (editor.role < user.role) {
    return false;
  }

  switch (editor.role) {
    case Role.TECHNICIAN:
      return false;
    case Role.MANAGER:
      if (user.role >= Role.MANAGER) {
        return false;
      }
    // falls through
    case Role.ADMINISTRATOR:
      return user.organizationUuid === editor.organizationUuid;
    case Role.SUPERUSER:
      return true;
    default:
      assertUnreachable(editor.role);
  }
}

export function canReadUser(user: IUser, editor: IUser, isSearch?: boolean): boolean {
  if (editor.role === Role.NONE) {
    return false;
  }

  if (editor.uuid === user.uuid) {
    return true;
  }

  if (isSearch && user.role !== Role.SUPERUSER && user.organizationUuid === editor.organizationUuid) {
    return true;
  }

  switch (editor.role) {
    case Role.TECHNICIAN:
      return false;
    case Role.MANAGER:
    case Role.ADMINISTRATOR:
      return user.organizationUuid === editor.organizationUuid && user.role !== Role.SUPERUSER;
    case Role.SUPERUSER:
      return true;
    default:
      assertUnreachable(editor.role);
  }
}

export function censorUserDetails(user: IUser, editor: IUser, isOnPremise: boolean): IUser {
  if (editor.role === Role.SUPERUSER || user.uuid === editor.uuid) return user;
  if (editor.role <= Role.MANAGER) {
    return censorUserInfo(user, isOnPremise);
  } else {
    return user;
  }
}

function censorUserInfo(user: IUser, isOnPremise: boolean): IUser {
  const isOnPremiseUsername = isOnPremise && !user.email.includes('@');
  const censoredEmail = isOnPremiseUsername
    ? user.email
    : user.email.split('@')[0][0] + '****@' + user.email.split('@')[1];
  const censoredPhone = !!user.mobile ? user.mobile.slice(0, 3) + '****' + user.mobile.slice(-3) : null;
  return {
    ...user,
    email: censoredEmail,
    mobile: censoredPhone,
  };
}

export function canEditRoleTo(user: IUser, editor: IUser): Role[] {
  if (editor.role === Role.NONE) {
    return [];
  }

  if (user.uuid === editor.uuid) {
    return [user.role]; // a user, even a high access one, cannot reduce (or increase) their own role.
  }
  if (!canEditUser(user, editor)) {
    return [];
  }

  switch (editor.role) {
    case Role.TECHNICIAN:
    case Role.MANAGER:
      return [user.role];
    case Role.ADMINISTRATOR:
      return [Role.NONE, Role.TECHNICIAN, Role.MANAGER, Role.ADMINISTRATOR];
    case Role.SUPERUSER:
      return [Role.NONE, Role.TECHNICIAN, Role.MANAGER, Role.ADMINISTRATOR, Role.SUPERUSER];
    default:
      assertUnreachable(editor.role);
  }
}

export function canCreateWithRole(editor: IUser): Role[] {
  switch (editor.role) {
    case Role.NONE:
    case Role.TECHNICIAN:
      return [];
    case Role.MANAGER:
      return [Role.NONE, Role.TECHNICIAN];
    case Role.ADMINISTRATOR:
      return [Role.NONE, Role.TECHNICIAN, Role.MANAGER, Role.ADMINISTRATOR];
    case Role.SUPERUSER:
      return [Role.NONE, Role.TECHNICIAN, Role.MANAGER, Role.ADMINISTRATOR, Role.SUPERUSER];
    default:
      assertUnreachable(editor.role);
  }
}

export function canEditOrgTo(user: IUser, org: IOrganization, editor: IUser) {
  switch (editor.role) {
    case Role.NONE:
      return false;
    case Role.TECHNICIAN:
    case Role.MANAGER:
    case Role.ADMINISTRATOR:
      return user.organizationUuid === org.uuid;
    case Role.SUPERUSER:
      return true;
    default:
      assertUnreachable(editor.role);
  }
}

export function canDeleteUser(user: IUser, editor: IUser): boolean {
  if (user.uuid === editor.uuid) {
    // Can't delete yourself.
    return false;
  }

  if (!canEditUser(user, editor)) {
    return false;
  }

  switch (editor.role) {
    case Role.NONE:
    case Role.TECHNICIAN:
      return false;
    case Role.MANAGER:
      return user.role < Role.MANAGER;
    case Role.ADMINISTRATOR:
      return user.role <= Role.ADMINISTRATOR;
    case Role.SUPERUSER:
      return true;
    default:
      assertUnreachable(editor.role);
  }
}

// Deleting and Resetting password has the same authorization
export function canResetPassword(user: IUser, editor: IUser): boolean {
  return canDeleteUser(user, editor);
}

export function canCreateUser(forOrg: IOrganization, forRole: Role, editor: IUser): boolean {
  switch (editor.role) {
    case Role.NONE:
    case Role.TECHNICIAN:
      return false;
    case Role.MANAGER:
      return forOrg.uuid === editor.organizationUuid && forRole <= Role.TECHNICIAN;
    case Role.ADMINISTRATOR:
      return forOrg.uuid === editor.organizationUuid && forRole <= Role.ADMINISTRATOR;
    case Role.SUPERUSER:
      return true;
    default:
      assertUnreachable(editor.role);
  }
}
