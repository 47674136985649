import {IBuild} from '@common/api/models/builds/IBuild';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {useCallback, useEffect, useReducer, useState, useRef} from 'react';
import {Role} from '@common/api/models/users/IUser';
import {RootState} from '../store/reducers';
import {useSelector} from 'react-redux';

export function usePermissionsForBuild(build?: IBuild): {
  buildPermission: boolean;
  machinePermission: boolean;
} {
  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const permissionsById = useSelector((state: RootState) => state.authorizationStore.byId);

  if (currentUser.role >= Role.MANAGER) return {buildPermission: true, machinePermission: true};
  if (!build) return {buildPermission: false, machinePermission: false};

  const permissions = Object.values(permissionsById);
  const permissionForBuild = permissions.find(
    (permission) =>
      permission.resourceType === ResourcePermissionType.BUILD &&
      permission.resourceUuid === build.uuid &&
      permission.userUuid === currentUser.uuid
  );
  const permissionForMachine = permissions.find(
    (permission) =>
      permission.resourceType === ResourcePermissionType.MACHINE &&
      permission.resourceUuid === build.machineUuid &&
      permission.userUuid === currentUser.uuid
  );

  return {
    buildPermission: !!permissionForBuild,
    machinePermission: !!permissionForMachine,
  };
}

export function usePermissionsForMachine(machine?: IMachine): {
  machinePermission: boolean;
} {
  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const permissionsById = useSelector((state: RootState) => state.authorizationStore.byId);

  if (currentUser.role >= Role.MANAGER) return {machinePermission: true};
  if (!machine) return {machinePermission: false};

  const permissions = Object.values(permissionsById);
  const permissionForMachine = permissions.find(
    (permission) =>
      permission.resourceType === ResourcePermissionType.MACHINE &&
      permission.resourceUuid === machine.uuid &&
      permission.userUuid === currentUser.uuid
  );

  return {
    machinePermission: !!permissionForMachine,
  };
}

export function useWindowSize(): {
  width: number | undefined;
  height: number | undefined;
} {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const useContainerHeight = () => {
  const [height, setHeight] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const observer = useRef(
    new ResizeObserver((entries) => {
      setTimeout(() => {
        const {height} = entries[0].contentRect;
        setHeight(height);
      }, 0);
    })
  );

  useEffect(() => {
    if (containerRef) {
      observer.current?.observe(containerRef);
    }

    const observerCopy = observer.current;
    return () => {
      if (containerRef) observerCopy?.unobserve(containerRef);
    };
  }, [containerRef, observer]);

  return {height, setContainerRef};
};

export const useContainerWidth = () => {
  const [hasInitialized, setHasInitialized] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  const observer = useRef(
    new ResizeObserver((entries) => {
      setTimeout(() => {
        const {width} = entries[0].contentRect;
        setWidth(width);
        setHasInitialized(true);
      }, 0);
    })
  );

  useEffect(() => {
    if (containerRef) {
      observer.current?.observe(containerRef);
    }

    const observerCopy = observer.current;
    return () => {
      if (containerRef) observerCopy?.unobserve(containerRef);
    };
  }, [containerRef, observer]);

  return {width, containerRef, setContainerRef, hasInitialized};
};

export enum SkipAction {
  Bump,
  Reset,
}

export const useSkipReducer = (step: number) =>
  useReducer((skip: number, action: SkipAction) => (action === SkipAction.Reset ? 0 : skip + step), 0);

export const useLargeScreenSize = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  return isMediumScreen;
};

export const useMediumScreenSize = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  return isMediumScreen;
};

export const useSmallScreenSize = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  return isSmallScreen;
};

export const useExtraSmallScreenSize = () => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  return isXsScreen;
};

export function useMobileDrawer(): [boolean, (drawerIsOpen: boolean) => void] {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const closeQuickView = () => setIsDrawerOpen(false);

    if (isDrawerOpen) {
      // Add a fake history event so that the back button closes the drawer if clicked
      window.history.pushState('dummy-route', document.title, window.location.href);

      window.addEventListener('popstate', closeQuickView);

      return () => {
        window.removeEventListener('popstate', closeQuickView);
        // If unmounted (drawer closed or left page) and dummy state exists. Remove it.
        if (window.history.state === 'dummy-route') {
          window.history.back();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  return [isDrawerOpen, setIsDrawerOpen];
}
