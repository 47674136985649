import {ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {createInitialLiveStore, LiveStoreState} from './liveUpdateStore';
import {Matrix4} from 'three';

export enum CTReportActions {
  UPDATE_VIEWPORT_TRANSFORMS = 'UPDATE_VIEWPORT_TRANSFORMS',
}

export interface CTReportStoreState extends LiveStoreState<ICTReportGETResponse> {
  viewportTransform: Matrix4;
}

export const initialCTReportStoreState: CTReportStoreState = {
  ...createInitialLiveStore(),
  viewportTransform: new Matrix4().identity(),
};

interface CTReportActionType<_T, P> {
  type: CTReportActions;
  payload: P;
}

export type CTReportUpdateViewportTransformAction = CTReportActionType<
  CTReportActions.UPDATE_VIEWPORT_TRANSFORMS,
  {viewportTransform: Matrix4}
>;
