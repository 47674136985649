import React from 'react';
import {TableCell, TableRow, TableHead, TableBody, Typography} from '@material-ui/core';
import {
  IDefectNotification,
  defectNotificationToString,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {CollapseTable} from '../ConfirmationStep';

const DefectNotificationsCollapse = ({defectNotifications}: {defectNotifications: Array<IDefectNotification>}) => {
  return (
    <CollapseTable>
      <TableHead>
        <TableRow>
          <TableCell>Conditions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {defectNotifications.map((defectNotification) => (
          <TableRow key={`defect-notification-confirmation-row-${defectNotification.uuid}`}>
            <TableCell style={{display: 'flex', alignItems: 'center'}}>
              {defectNotificationToString(defectNotification).map((string) => (
                <Typography>{string}</Typography>
              ))}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CollapseTable>
  );
};

export default DefectNotificationsCollapse;
