import React, {Dispatch, SetStateAction} from 'react';
import {TextField, MenuItem} from '@material-ui/core';

type ComparisonsSelectorProps = {
  comparisons: {[key: string]: string};
  currentComparison: string;
  setCurrentComparison: Dispatch<SetStateAction<any>>;
};

const ComparisonsSelector = ({comparisons, currentComparison, setCurrentComparison}: ComparisonsSelectorProps) => {
  return (
    <TextField
      fullWidth
      select
      size="small"
      label="Comparison"
      variant="outlined"
      onChange={(event) => setCurrentComparison(event.target.value as keyof typeof comparisons)}
      value={currentComparison}
      InputLabelProps={{
        shrink: true,
      }}
    >
      {Object.entries(comparisons)?.map(([comparison, label], index: number) => (
        <MenuItem key={index + 1} value={comparison}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ComparisonsSelector;
