import React, {useMemo} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {IBuild} from '@common/api/models/builds/IBuild';
import AddNotificationFilter from './AddNotificationFilter';
import {RootState} from '../../../../store/reducers';
import GenericTable from '../../../../components/molecules/Table/GenericTable';
import {useSmallScreenSize} from '../../../../utils/utilHooks';

const DefectNotificationFilters = ({build}: {build: IBuild}) => {
  const isSmallScreen = useSmallScreenSize();

  const buildUuidFilter = useMemo(() => ({buildUuid: build.uuid}), [build.uuid]);

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant={isSmallScreen ? 'subtitle2' : 'h5'}>Defect Notification Filters</Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-end">
          <AddNotificationFilter build={build} />
        </Grid>
      </Grid>
      <GenericTable
        filteringEnabled={false}
        resourceType="defectNotification"
        useData={useDefectNotifications}
        permanentFilters={buildUuidFilter}
      />
    </Grid>
  );
};

export default DefectNotificationFilters;

const useDefectNotifications = () => {
  const defectNotificationsList = useSelector((state: RootState) => state.defectNotificationStore.list);

  return defectNotificationsList;
};
