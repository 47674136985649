import axios from 'axios';
import * as qs from 'qs';
import {
  ILocationBasedDefectsReportGETResponse,
  ILocationBasedDefectsReportPOSTRequest,
  ILocationBasedDefectsReportUrlsResponse,
  ILocationBasedDefectsReportsGETRequest,
} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function locationBasedDefectsReportGET(
  filter: QueryFilter<ILocationBasedDefectsReportsGETRequest>
): AjaxApiResult<ILocationBasedDefectsReportGETResponse[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/reports/locationBasedDefects/' + (query && '?' + query));
  }, 'Could not fetch location based defects reports');
}

export async function locationBasedDefectsReportPOST(
  locationBasedDefectsReport: ILocationBasedDefectsReportPOSTRequest
): AjaxApiResult<ILocationBasedDefectsReportGETResponse> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/locationBasedDefects/', locationBasedDefectsReport);
  }, 'Could not create location based defects report');
}

export async function locationBasedDefectsReportPATCH(
  uuid: string,
  name: string
): AjaxApiResult<ILocationBasedDefectsReportGETResponse> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/reports/locationBasedDefects/${uuid}`, {name});
  }, 'Could not update location based defects report');
}

export async function locationBasedDefectsReportDownloadUrlsGET(
  uuid: string
): AjaxApiResult<ILocationBasedDefectsReportUrlsResponse[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/locationBasedDefects/${uuid}/downloadUrls`);
  }, 'Could not fetch images for report');
}

export async function locationBasedDefectsReportDELETE(
  uuid: string
): AjaxApiResult<ILocationBasedDefectsReportGETResponse> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/locationBasedDefects/${uuid}`);
  }, 'Could not delete location based defects report');
}

export async function locationBasedDefectsReportRegenerateGET(
  uuid: string
): AjaxApiResult<ILocationBasedDefectsReportGETResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/locationBasedDefects/${uuid}/regenerate`);
  }, 'Could not regenerate location based defects report');
}
