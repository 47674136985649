import {LiveStoreState} from '../../model/liveUpdateStore';
import createReducer from '../createReducer';
import {LiveStoreReducers} from '../liveUpdateStore';
import {
  CloneProfileAction,
  SensorProfileActions,
  SaveProfileNameAction,
  SetConfigDefaultsAction,
  SetSensorProfileAction,
  SensorProfileStoreState,
  SetProfileDeviceAction,
  CreateProfileAction,
} from '../../model/sensorProfile';
import {ISensorProfileGETResponse} from '@common/api/models/devices/ISensorProfile';
import {cloneDeep, omit} from 'lodash';

export class SensorProfileLiveReducers extends LiveStoreReducers<ISensorProfileGETResponse, SensorProfileStoreState> {
  additionalReducers() {
    return {
      setConfigDefaults(
        state: SensorProfileStoreState,
        {payload: {config, defaultProfile, deviceSerial}}: SetConfigDefaultsAction
      ): SensorProfileStoreState {
        return {
          ...state,
          configDefaults: {...state.configDefaults, [deviceSerial]: config},
          defaultProfiles: {
            ...state.defaultProfiles,
            [deviceSerial]: defaultProfile,
          },
        };
      },

      setProfile(
        state: SensorProfileStoreState,
        {payload: {uuid, profile, isBuild}}: SetSensorProfileAction
      ): SensorProfileStoreState {
        if (isBuild)
          return {
            ...state,
            buildProfiles: {
              ...state.buildProfiles,
              [uuid]: profile,
            },
          };

        return {
          ...state,
          profiles: {...state.profiles, [uuid]: profile},
        };
      },

      loadProfile(
        state: SensorProfileStoreState,
        {payload: {uuid, profile}}: SetSensorProfileAction
      ): SensorProfileStoreState {
        return {
          ...state,
          profiles: {...state.profiles, [uuid]: cloneDeep(profile)},
          originalProfiles: {
            ...state.originalProfiles,
            [uuid]: cloneDeep(profile),
          },
        };
      },

      loadBuildProfile(
        state: SensorProfileStoreState,
        {payload: {uuid, profile}}: SetSensorProfileAction
      ): SensorProfileStoreState {
        return {
          ...state,
          buildProfiles: {...state.buildProfiles, [uuid]: cloneDeep(profile)},
          originalBuildProfiles: {
            ...state.originalBuildProfiles,
            [uuid]: cloneDeep(profile),
          },
        };
      },

      setName(state: SensorProfileStoreState, {payload: {uuid, name}}: SaveProfileNameAction): SensorProfileStoreState {
        const profile = cloneDeep(state.byId[uuid] || {uuid: 'new'});
        profile.name = name;

        return {
          ...state,
          byId: {...state.byId, [uuid]: profile},
        };
      },

      setDevice(
        state: SensorProfileStoreState,
        {payload: {uuid, deviceSerial}}: SetProfileDeviceAction
      ): SensorProfileStoreState {
        const profile = cloneDeep(state.byId[uuid] || {uuid: 'new'});
        profile.deviceSerial = deviceSerial;

        return {
          ...state,
          byId: {...state.byId, [uuid]: profile},
        };
      },

      createProfile(
        state: SensorProfileStoreState,
        {payload: {profile, profileJSON}}: CreateProfileAction
      ): SensorProfileStoreState {
        return {
          ...state,
          byId: {...omit(state.byId, 'new'), [profile.uuid]: profile},
          originalProfiles: {...state.originalProfiles, [profile.uuid]: profileJSON},
          profiles: {
            ...omit(state.profiles, 'new'),
            [profile.uuid]: profileJSON,
          },
        };
      },

      revertNewProfile(state: SensorProfileStoreState): SensorProfileStoreState {
        return {
          ...state,
          byId: {...omit(state.byId, 'new')},
          profiles: {...omit(state.profiles, 'new')},
        };
      },

      cloneNewProfile(
        state: SensorProfileStoreState,
        {payload: {profile, profileJSON}}: CloneProfileAction
      ): SensorProfileStoreState {
        return {
          ...state,
          byId: {...state.byId, new: profile},
          profiles: {...state.profiles, new: profileJSON},
        };
      },
    };
  }

  createReducer(initialState: SensorProfileStoreState) {
    const superReducers = this.bindReducers();
    const additionalReducers = this.additionalReducers();

    return createReducer<LiveStoreState<ISensorProfileGETResponse>>(initialState, {
      ...superReducers,

      [SensorProfileActions.SET_SENSOR_PROFILE_CONFIG_DEFAULTS]: additionalReducers.setConfigDefaults,
      [SensorProfileActions.LOAD_SENSOR_PROFILE]: additionalReducers.loadProfile,
      [SensorProfileActions.SET_SENSOR_PROFILE]: additionalReducers.setProfile,
      [SensorProfileActions.SAVE_PROFILE_NAME]: additionalReducers.setName,
      [SensorProfileActions.SET_PROFILE_DEVICE]: additionalReducers.setDevice,
      [SensorProfileActions.CREATE_SENSOR_PROFILE]: additionalReducers.createProfile,
      [SensorProfileActions.REVERT_NEW_PROFILE]: additionalReducers.revertNewProfile,
      [SensorProfileActions.LOAD_BUILD_SENSOR_PROFILE]: additionalReducers.loadBuildProfile,
      [SensorProfileActions.CLONE_SENSOR_PROFILE]: additionalReducers.cloneNewProfile,
    });
  }
}
