import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {spacing} from '@material-ui/system';
import {Button, Divider as MuiDivider} from '@material-ui/core';
import {FiltersState} from '../../../pages/shared/IndexPage/IndexPage';
import PartsTreeViewHeader from './PartsTreeViewHeader';
import BuildsPartsTree from './BuildsPartsTree';
import {RootState} from '../../../store/reducers';
import {useBuildIntensityReportStoreActions} from '../../../store/actions/index';
import LoadingPage from '../../../components/organisms/LoadingPage';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import CreateBuildIntensityReportButton from '../CreateBuildIntensityReportButton';
import {SetHoveredSelection} from '../BuildIntensityPage';

const Divider = styled(MuiDivider)(spacing);

export type PartSelectorModeType = 'draft' | 'edit';

const PartSelectorTreeView = ({setHoveredSelection}: {setHoveredSelection: SetHoveredSelection}) => {
  const [filters, setFilters] = useState<FiltersState>({take: 25, page: 0});
  const buildIntensityReports = useSelector((state: RootState) => {
    return Object.values(state.buildIntensityReportStore.byId);
  });
  const isFetching = useSelector(
    (state: RootState) => state.buildIntensityReportStore.fetched === FetchingState.Fetching
  );
  const disableClearAll = useSelector(
    (state: RootState) =>
      state.buildIntensityReportStore.selectedParts.size === 0 &&
      state.buildIntensityReportStore.selectedBuilds.size === 0
  );

  const buildIntensityReportActions = useBuildIntensityReportStoreActions();

  useEffect(() => {
    buildIntensityReportActions.clearIntensityReports();
    buildIntensityReportActions.ensureConsistent(filters, {}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function clearAll() {
    buildIntensityReportActions.clearIntensitySelections();
  }

  if (isFetching && !buildIntensityReports.length) {
    return <LoadingPage loadingText="Loading Intensity Reports" />;
  }

  return (
    <TreeViewContainer>
      <PartsTreeViewHeader filters={filters} setFilters={setFilters} />
      <Divider mb={3} mt={3} />
      <Button
        fullWidth
        size="small"
        variant="outlined"
        color="primary"
        onClick={clearAll}
        style={{marginBottom: '1rem'}}
        disabled={disableClearAll}
      >
        Clear all selections
      </Button>
      <AbsoluteWrapper>
        <BuildsTreeViewContainer>
          <BuildsPartsTree buildIntensityReports={buildIntensityReports} setHoveredSelection={setHoveredSelection} />
        </BuildsTreeViewContainer>
        <CreateBuildWrapper>
          <CreateBuildIntensityReportButton />
        </CreateBuildWrapper>
      </AbsoluteWrapper>
    </TreeViewContainer>
  );
};

export default PartSelectorTreeView;

const TreeViewContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }

  .MuiRadio-root,
  .MuiCheckbox-root {
    padding: 4px;
  }

  .MuiCollapse-wrapperInner .MuiTreeItem-iconContainer {
    display: none;
  }
  .MuiTypography-root.MuiTreeItem-label {
    width: calc(100% - 20px);
  }
`;

const AbsoluteWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const BuildsTreeViewContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 3rem;
  left: 0;
  padding-bottom: 0;
  right: 0;
  overflow: auto;
`;

const CreateBuildWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  height: 3rem;
  justify-content: flex-end;
  align-items: center;
`;
