import * as React from 'react';
import {useMemo} from 'react';
import {Typography} from '@material-ui/core';
import {num4svg} from '../utils';

export interface ScaleProps {
  stageWidth: number;
  stageHeight: number;
  stageScale: number;
  imageWidth?: number;
}

const roundNumbers = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000].reverse();

interface Unit {
  name: string;
  mm: number;
}
const units: Unit[] = [
  {name: 'm', mm: 1e3},
  {name: 'mm', mm: 1},
  {name: 'μm', mm: 1e-3},
  {name: 'nm', mm: 1e-6},
];

const THICKNESS = 3;
const HEIGHT = 15;

export function Scale(props: ScaleProps) {
  // Note these are not pixels in the original image,
  // these are pixels in the Konva Image.
  // In this coordinate system, 1 px = 1 mm, and when scale = 1,
  // 1 mm = 1 screen pixel also
  const {scaleUnit, scaleMagnitude, scaleWidthPx} = useMemo(() => {
    const mmPerImagePx = 1;
    const mmPerScreenPx = mmPerImagePx / props.stageScale;

    // We want the scale to be no wider that 0.3x the viewport width
    const desiredScaleWidthFactor = 0.3;
    const desiredScaleWidth = props.stageWidth * desiredScaleWidthFactor;

    for (const unit of units) {
      for (const scaleMagnitude of roundNumbers) {
        const scaleUnit = unit.name;
        const scaleWidthPx = (scaleMagnitude * unit.mm) / mmPerScreenPx;
        if (scaleWidthPx < desiredScaleWidth) {
          return {scaleUnit, scaleMagnitude, scaleWidthPx};
        }
      }
    }
    return {scaleUnit: 'error', scaleMagnitude: -1, scaleWidthPx: 100};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stageScale, props.stageWidth, props.imageWidth]);

  const pathPoints = (scaleWidthPx: number) => {
    return `m 0 0 
            v ${HEIGHT} 
            h ${num4svg(scaleWidthPx)} 
            v -${HEIGHT} 
            h -${THICKNESS} 
            v ${HEIGHT - THICKNESS}
            h -${num4svg(scaleWidthPx - 2 * THICKNESS)} 
            v -${HEIGHT - THICKNESS} 
            h -${THICKNESS} `;
  };

  const svgViewbox = (scaleWidthPx: number) => {
    return `0 0 ${num4svg(scaleWidthPx)} ${HEIGHT}`;
  };

  const labelText = (scaleCount: number, unitName: string) => {
    if (unitName === 'error') return `< 1 nm`;
    return `${scaleCount} ${unitName}`;
  };

  return (
    <div style={{pointerEvents: 'none'}}>
      <svg
        viewBox={svgViewbox(scaleWidthPx)}
        style={{
          width: scaleWidthPx,
          position: 'absolute',
          left: (props.stageWidth - scaleWidthPx) / 2,
          top: props.stageHeight - HEIGHT - 15,
        }}
      >
        <path d={pathPoints(scaleWidthPx)} fill="#ffffff" stroke="none" />
      </svg>
      <Typography
        style={{
          left: props.stageWidth / 2 - 100,
          width: 200,
          top: props.stageHeight - HEIGHT - 30,
          position: 'absolute',
          color: '#FFFFFF',
        }}
        align="center"
      >
        {labelText(scaleMagnitude, scaleUnit)}
      </Typography>
    </div>
  );
}
