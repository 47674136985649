import React from 'react';
import {Box, Typography, Chip} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {useSimilarityReportStoreActions} from '../../../store/actions';
import {DEFAULT_REPORT_NAME} from '../../../store/reducers/liveUpdateStores/similarityReportReducer';
import {RootState} from '../../../store/reducers';
import PartChips from '../shared/PartChips';
import {SimilarityStep, generateSimilarityQueryString} from '../SimilarityCreatePage';
import {addOrRemoveRotations} from '../shared/PartSelectorTreeView/BuildsPartsTree';

const SelectedParts = ({type}: {type: SimilarityStep}) => {
  const history = useHistory();
  const {setDraftSimilarityReport} = useSimilarityReportStoreActions();
  const draftReport = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport);

  const isSourceStep = type === SimilarityStep.SOURCE;

  const parts = isSourceStep ? draftReport.sourceParts : draftReport.targetParts;

  const rotations = isSourceStep ? draftReport.sourcePartRotations : draftReport.targetPartRotations;

  const onRemovePart = (newParts: IPartGETResponse[]) => {
    const newRotations = addOrRemoveRotations(newParts, rotations, isSourceStep ? 'source' : 'target');

    setDraftSimilarityReport(isSourceStep ? 'sourceParts' : 'targetParts', newParts);
    setDraftSimilarityReport(isSourceStep ? 'sourcePartRotations' : 'targetPartRotations', newRotations);

    if (isSourceStep) {
      const queryString = generateSimilarityQueryString(
        newParts.map((part) => part.uuid),
        newRotations
      );

      history.replace(`/reports/similarity/create/${SimilarityStep.SOURCE}/${queryString}`);

      if (newParts?.length === 0) {
        setDraftSimilarityReport('backupName', DEFAULT_REPORT_NAME);
      } else {
        setDraftSimilarityReport('backupName', `${newParts[0].buildName}-${newParts[0].name}`);
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" overflow="auto">
      <Typography variant="h5" style={{marginRight: '12px'}}>
        Selected {type === 'sourcePartSelection' ? 'Source' : 'Target'} Parts:
      </Typography>

      {!parts || parts.length === 0 ? (
        <Chip label="None selected" disabled variant="outlined" />
      ) : (
        <PartChips parts={parts} setParts={onRemovePart} />
      )}
    </Box>
  );
};

export default SelectedParts;
