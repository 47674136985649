import React, {useMemo} from 'react';
import {Button, Card, CardActions, CardContent, CardHeader, Typography} from '@material-ui/core';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {inflect} from 'inflection';
import {isCompletedBuildState, isEngagedBuildState} from '@common/api/models/builds/IBuild';
import {RootState} from '../../../store/reducers';
import {BuildStatusWidgetStandalone} from '../../../components/molecules/Dashboard/BuildStatusWidget';

export default function BuildsHistoryWidget() {
  const history = useHistory();
  const {uuid} = useParams<{uuid: string}>();
  const allBuilds = useSelector((state: RootState) =>
    Object.values(state.buildStore.byId).filter((build) => build.machineUuid === uuid && !build.deletedAt)
  );

  const device = useSelector((s: RootState) => s.deviceStore.byMachineUuid[uuid]);
  const currentBuild = useSelector((state: RootState) =>
    Object.values(state.buildStore.byId).find((build) => build.uuid === device?.buildUuid)
  );

  const machineFilter = useMemo(() => [uuid], [uuid]);

  const totalCompleted = allBuilds.filter((build) => isCompletedBuildState(build.state)).length;

  const handleBuildClick = () => {
    if (currentBuild) history.push('/builds/uuid/' + currentBuild.uuid);
  };

  const handleCompletedBuildsClick = () => {
    history.push(`/machines/uuid/${uuid}/builds/`);
  };

  return (
    <Card>
      <CardHeader title={'Build Status'} />
      <CardContent style={{paddingTop: 0}}>
        {!!currentBuild && isEngagedBuildState(currentBuild.state) ? (
          <React.Fragment>
            <Typography>Currently building</Typography>
            <Button onClick={handleBuildClick} style={{paddingLeft: 0}}>
              {currentBuild.name}
            </Button>
          </React.Fragment>
        ) : (
          <></>
        )}
        <BuildStatusWidgetStandalone machineFilter={machineFilter} builds={allBuilds} />
      </CardContent>
      <CardActions>
        <Button onClick={handleCompletedBuildsClick} disabled={!totalCompleted}>
          {totalCompleted > 0
            ? `View all ${totalCompleted} completed ${inflect('build', totalCompleted)}`
            : 'No completed builds available'}
        </Button>
      </CardActions>
    </Card>
  );
}
