import axios from 'axios';
import {locationBasedDefectsReportDownloadUrlsGET} from '../../api/ajax/locationBasedDefectsReport';
import {LocationBasedDefectsMetadata, LocationBasedDefectsReportActions} from '../model/locationBasedDefects';

// eslint-disable-next-line import/no-anonymous-default-export
const locationBasedDefectsReportActions = {
  fetchImages(uuid: string) {
    return async (dispatch: Function) => {
      const response = await locationBasedDefectsReportDownloadUrlsGET(uuid);

      if (response.success) {
        const metadataUrl = response.data.find((item) => item.filename === 'metadata.json');
        let metadata: undefined | LocationBasedDefectsMetadata = undefined;

        if (metadataUrl) {
          const metadataResponse = await axios.get(metadataUrl.signedUrl);
          metadata = metadataResponse.data;
        }

        dispatch({
          type: LocationBasedDefectsReportActions.LOCATION_BASED_DEFECTS_REPORT_FETCH_IMAGES_SUCCESS,
          payload: {images: response.data, uuid, metadata},
        });
      }
    };
  },
};

export default locationBasedDefectsReportActions;
