import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';

import MaterialTable from 'material-table';
import {Card, CardHeader, TextField, Button, Grid, Typography} from '@material-ui/core';
import {Delete} from '@material-ui/icons';

import {IBuild} from '@common/api/models/builds/IBuild';
import {IBuildNote} from '@common/api/models/builds/IBuildNote';
import {materialTableIcons} from '../../material-table-icons';
import {useBuildNoteStoreActions, useUserStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {createBuildNotePOST, deleteBuildNoteDELETE} from '../../../api/ajax/buildNotes';
import {renderDateTimeString} from '../../../utils/string';
import EllipsisTextWithTooltip from '../../atoms/Texts/EllipsisTextWithTooltip';
import {GenericDialog} from '../DialogButton';
import {useSmallScreenSize} from '../../../utils/utilHooks';

export function NotesCard(props: {build: IBuild}) {
  const isSmall = useSmallScreenSize();
  /* Build notes */
  const buildNoteStoreActions = useBuildNoteStoreActions();
  const buildNoteStore = useSelector((s: RootState) => s.buildNoteStore);
  const auth = useSelector((s: RootState) => s.auth);

  // Sync the build notes with the database
  useEffect(() => {
    if (props.build.uuid) {
      buildNoteStoreActions.ensureConsistent({buildUuid: props.build.uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  // Button is disabled if the text field is empty
  const [isNotePending, setIsNotePending] = useState(false);
  const [isUpdatingNotes, setIsUpdatingNotes] = useState(false);
  const canSubmit = !isUpdatingNotes && isNotePending;

  const textArea = useRef() as any;
  const saveNotes = async () => {
    const textAreaValue = textArea.current.value;
    if (!textAreaValue) {
      return;
    }

    // Lock the button so that user cannot click it again
    setIsUpdatingNotes(true);
    await createBuildNotePOST(props.build.uuid, textAreaValue);

    // Unlock the create not button and clear the text area
    setIsUpdatingNotes(false);
    textArea.current.value = '';
    setIsNotePending(false);
  };

  const clearPendingNote = () => {
    textArea.current.value = '';
    setIsNotePending(false);
  };

  const compareNoteCreatedAt = (a: IBuildNote, b: IBuildNote) =>
    Number(new Date(a.createdAt)) - Number(new Date(b.createdAt));

  // Only grab notes that apply to the current build
  const buildNotes = Object.values(buildNoteStore.byId)
    .filter((note) => note.buildUuid === props.build.uuid)
    .sort(compareNoteCreatedAt);

  const userStore = useSelector((state: RootState) => state.userStore);
  const userStoreActions = useUserStoreActions();

  useEffect(() => {
    userStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserDisplayName = (uuid: string) => {
    const user = userStore.byId[uuid];
    return user ? `${user.firstName} ${user.lastName}` : uuid;
  };

  // Note that we're in the process of deleting (i.e. displaying confirmation dialog)
  const [deletingNote, setDeletingNote] = useState<IBuildNote | null>(null);

  return (
    <Card
      style={{
        padding: isSmall ? '15px' : '30px',
        marginBottom: isSmall ? '15px' : '30px',
      }}
    >
      <CardHeader title="Notes" style={{paddingBottom: 0, paddingLeft: 0}} />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <MaterialTable
            icons={materialTableIcons}
            columns={[
              {
                title: 'Name',
                field: 'name',
                width: '128px',
                cellStyle: {
                  paddingLeft: 0,
                },
                headerStyle: {
                  paddingLeft: 0,
                },
                render: (row: IBuildNote) => (
                  <EllipsisTextWithTooltip>{getUserDisplayName(row.userUuid)}</EllipsisTextWithTooltip>
                ),
              },
              {
                title: 'Time',
                field: 'createdAt',
                type: 'datetime',
                width: '168px',
                render: (row: IBuildNote) => (
                  <Typography>{renderDateTimeString('short', row.createdAt, true)}</Typography>
                ),
              },
              {
                title: 'Message',
                field: 'note',
                headerStyle: {minWidth: 250},
                render: (row: IBuildNote) => row.note.split('\n').map((line) => <Typography>{line}</Typography>),
              },
            ]}
            data={buildNotes}
            options={{
              search: false,
              padding: 'default',
              paging: false,
              showTitle: false,
              toolbar: false,
              actionsColumnIndex: -1, // Set the delete icon to the right of the table
              actionsCellStyle: {padding: '0px'},
            }}
            actions={[
              (rowData) => ({
                icon: () => <Delete />,

                tooltip:
                  rowData.userUuid === auth.user?.uuid
                    ? 'Delete Note'
                    : `Only ${rowData.userUuid} can delete this note`,
                disabled: rowData.userUuid !== auth.user?.uuid,
                // @ts-ignore: FIXME: types are not correctly
                onClick: (event, rowData: IBuiltNote) => setDeletingNote(rowData),
              }),
            ]}
          />
        </Grid>

        <Grid item xs={12} md={8} xl={6}>
          <TextField
            inputRef={textArea}
            style={{marginBottom: '10px', marginTop: '10px'}}
            id="notes"
            multiline
            minRows={2}
            maxRows={6}
            variant="outlined"
            fullWidth
            placeholder={'Add note here'}
            disabled={isUpdatingNotes}
            onChange={() => setIsNotePending(!!textArea.current.value)}
            onKeyDown={(event: React.KeyboardEvent) =>
              // Submit on control+enter
              event.keyCode === 13 && event.ctrlKey && canSubmit && saveNotes()
            }
          />

          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item>
              <Button disabled={!canSubmit} style={{display: 'block', marginLeft: 'auto'}} onClick={clearPendingNote}>
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!canSubmit}
                style={{display: 'block', marginLeft: 'auto'}}
                variant="contained"
                onClick={saveNotes}
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <GenericDialog
        title="Delete Note"
        content={`Are you sure you want to delete the note "${
          // Limit note preview to 100 chars
          deletingNote && deletingNote.note.length > 100
            ? `${deletingNote?.note.substring(0, 100)}...`
            : deletingNote?.note
        }"?`}
        confirmText="Delete"
        isOpen={!!deletingNote}
        closeDialog={() => setDeletingNote(null)}
        onSuccess={async () =>
          deletingNote &&
          (await deleteBuildNoteDELETE(deletingNote?.buildUuid, deletingNote?.createdAt)) &&
          setDeletingNote(null)
        }
        danger={true}
      />
    </Card>
  );
}
