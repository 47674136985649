import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import {Grid, ListItemText, Typography, Divider as MuiDivider} from '@material-ui/core';
import {IDevice} from '@common/api/models/devices/IDevice';
import {RootState} from '../../../../store/reducers/index';
import SearchAndSelect from '../../../../components/molecules/Selector/SearchAndSelect';
import {devicesAllGET} from '../../../../api/ajax/devices';
import {useSensorProfileStoreActions} from '../../../../store/actions/index';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';

const Divider = styled(MuiDivider)(spacing);

function ProfileDevice() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.sensorProfileStore.byId[selectedUuid]);
  const profileJSON = useSelector((state: RootState) => state.sensorProfileStore.profiles[selectedUuid]);
  const [selectedDevice, setSelectedDevice] = useState<IDevice>();
  const sensorProfileActions = useSensorProfileStoreActions();

  useEffect(() => {
    async function loadDevice() {
      const res = await devicesAllGET({serial: profile.deviceSerial, take: 1});
      if (res.success) {
        if (!!profile.deviceSerial) {
          setSelectedDevice(res.data[0]);
        }
        // Only one device exists, auto select that
        else if (res.count === 1) {
          onSelect(res.data[0]);
        }
      }
    }

    loadDevice();

    return () => setSelectedDevice(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.uuid]);

  async function searchDevices(search: string) {
    const res = await devicesAllGET({take: 50, deviceId: {like: search}});

    if (res.success) return res.data;
    else return [];
  }

  function onSelect(device: IDevice | undefined) {
    sensorProfileActions.setDevice(selectedUuid, device?.serial);
    setSelectedDevice(device);
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <ListItemText
          primary={<Typography variant="h6">Device</Typography>}
          secondary="Which Device is this profile for?"
          style={{marginBottom: '1rem'}}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} style={{marginBottom: '8px'}}>
        <ConditionalTooltip tooltip="Cant change device after profile creation" hideTooltip={selectedUuid === 'new'}>
          <SearchAndSelect
            fetchFunction={searchDevices}
            selected={selectedDevice}
            setSelected={onSelect}
            getSuggestionValue={(device) => device.deviceId}
            isSelected={(device) => device.serial === selectedDevice?.uuid}
            label="Device"
            disabled={selectedUuid !== 'new'}
            disableClearable
          />
        </ConditionalTooltip>
      </Grid>
      {!!profileJSON && (
        <Grid item xs={12}>
          <Divider my={4} />
        </Grid>
      )}
    </Grid>
  );
}

export default ProfileDevice;
