import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/reducers/index';
import {ALL_PARTS_TXT} from '@common/api/models/builds/data/defects/IDefectSummary';
import {graphData} from './DefectsGraph';
import {DefectsSummaryRow, SelectedGraphConfig} from './DefectsPage';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {objMap} from '../../../../utils/objectFunctions';
interface IUseDefectSummaries {
  selectedDefects: SelectedDefectSummary[];
  tableDefects: DefectsSummaryRow[];
  setSelectedDefects: Dispatch<SetStateAction<SelectedDefectSummary[]>>;
}

export interface SelectedDefectSummary extends DefectsSummaryRow {
  graphConfig: SelectedGraphConfig;
}

export const useDefectSummaries = (buildTotalLayers: number, partialLayerNumbers: Set<number>): IUseDefectSummaries => {
  const defectSummaries = useSelector((state: RootState) => state.defectSummaryStore.list);
  const [selectedDefects, setSelectedDefects] = useState<SelectedDefectSummary[]>([]);
  const [tableDefects, setTableDefects] = useState<DefectsSummaryRow[]>([]);

  useEffect(() => {
    setTableDefects(
      defectSummaries.map((defectSummaryRow) => {
        if (!defectSummaryRow.partName) {
          defectSummaryRow.partName = ALL_PARTS_TXT;
        }

        const areaGraph = getChartValues(
          'Area (mm2)',
          objMap(defectSummaryRow.defectAreaGraph, (_, value) => Number(value.toFixed(2))),
          buildTotalLayers,
          defectSummaryRow.partLayerStart,
          defectSummaryRow.partLayerEnd,
          false,
          partialLayerNumbers
        );
        const coverageGraph = getChartValues(
          'Coverage (%)',
          objMap(defectSummaryRow.defectCoverageGraph, (_, value) => Number((value * 100).toFixed(2))),
          buildTotalLayers,
          defectSummaryRow.partLayerStart,
          defectSummaryRow.partLayerEnd,
          false,
          partialLayerNumbers
        );

        const partAreaGraph = getChartValues(
          'Area (mm2)',
          objMap(defectSummaryRow.partAreaGraph!, (_, value) => Number(value.toFixed(2))),
          buildTotalLayers,
          defectSummaryRow.partLayerStart,
          defectSummaryRow.partLayerEnd,
          true,
          partialLayerNumbers
        );

        const defectArea = Object.entries(defectSummaryRow.defectAreaGraph)
          .filter(([layerNum]) => !partialLayerNumbers.has(Number(layerNum)))
          .reduce((acc, [_, value]) => acc + value, 0);
        const partArea = Object.entries(defectSummaryRow.partAreaGraph!)
          .filter(([layerNum]) => !partialLayerNumbers.has(Number(layerNum)))
          .reduce((acc, [_, value]) => acc + value, 0);
        const defectCoverage = (defectArea / partArea) * 100;

        return {
          ...defectSummaryRow,
          uuid: defectSummaryRow.uuid,
          defectType: defectSummaryRow.defectType,
          partName: defectSummaryRow.partName,
          partUuid: defectSummaryRow.partUuid,
          defectArea: defectArea,
          defectCoverage: defectCoverage,
          areaGraph: areaGraph,
          coverageGraph: coverageGraph,
          tableData: {
            checked: Boolean(selectedDefects.find((defect) => defect.uuid === defectSummaryRow.uuid)),
          },
          partAreaGraph: partAreaGraph,
        };
      })
    );
  }, [defectSummaries, selectedDefects, buildTotalLayers, partialLayerNumbers]);

  return {selectedDefects, setSelectedDefects, tableDefects};
};

function getChartValues(
  chartName: string,
  graph: {[layerNum: number]: number},
  numLayers: number,
  partLayerStart?: number,
  partLayerEnd?: number,
  isPartArea?: boolean,
  partialLayerNumbers?: Set<number>
): graphData {
  const layerIndices = Array.from<unknown, number>({length: numLayers}, (_, index) => {
    return index;
  });
  if (isPartArea && partLayerStart && partLayerEnd) {
    layerIndices.splice(partLayerStart, 0, partLayerStart);
    layerIndices.splice(partLayerEnd + 1, 0, partLayerEnd, partLayerEnd);
  }
  const areas: (number | null)[] = layerIndices.map((layer, idx) => {
    if (partLayerStart && partLayerEnd) {
      const isStartLayer = layer === partLayerStart && layerIndices[idx + 1] === partLayerStart;
      const isEndLayer = layer === partLayerEnd && layerIndices[idx - 1] === partLayerEnd;
      if (isPartArea && (isStartLayer || isEndLayer)) return 0;
      if (layer >= partLayerStart && layer <= partLayerEnd!) {
        if (graph[layer]) {
          return graph[layer];
        } else {
          return 0;
        }
      } else {
        return null;
      }
    } else {
      if (graph[layer]) return graph[layer];
      return 0;
    }
  });

  if (partialLayerNumbers) {
    // Filter out partial layer numbers
    let graphValues: {x: number[]; y: number[]} = {x: [], y: []};
    graphValues = layerIndices.reduce((acc, layer) => {
      if (!partialLayerNumbers.has(layer)) {
        acc.x.push(layer);
        acc.y.push(graph[layer] || 0);
      }
      return acc;
    }, graphValues);

    return {
      name: chartName,
      x: graphValues.x,
      y: graphValues.y,
    };
  }

  return {
    name: chartName,
    x: layerIndices,
    y: areas,
  };
}
