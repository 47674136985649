import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {Helmet} from 'react-helmet';

import {Button as MuiButton, Card, CardContent, Grid, Typography} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {Alert} from '@material-ui/lab';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(6)}px;
  }
`;

const CustomAlert = styled(Alert)`
  .MuiAlert-message {
    flex-direction: row;
    justify-content: start;
  }
`;

function ExternalSupport() {
  return (
    <Wrapper>
      <Helmet title="Support" />
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardContent
            style={{
              padding: 25,
              overflowWrap: 'break-word',
            }}
          >
            <Typography align={'center'} variant={'subtitle1'}>
              For support, questions or suggestions, please contact us at
              <p
                style={{
                  marginTop: '0px',
                }}
              >
                <a href={`mailto://support@additiveassurance.com`}>support@additiveassurance.com</a>
              </p>
            </Typography>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={12}>
                <CustomAlert severity="info">Please login to access more resources!</CustomAlert>
              </Grid>
            </Grid>

            <Button
              // @ts-ignore
              component={Link}
              // @ts-ignore
              to="/login"
              variant="contained"
              color="secondary"
              mt={2}
              style={{
                marginTop: '10px',
              }}
            >
              Proceed to login
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Wrapper>
  );
}

export default ExternalSupport;
