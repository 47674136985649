import React, {useState} from 'react';
import {IconButton} from '@material-ui/core';
import {Alert as MuiAlert, AlertTitle} from '@material-ui/lab';
import {spacing} from '@material-ui/system';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import styled from 'styled-components';
import {useSmallScreenSize} from '../../utils/utilHooks';

const Alert = styled(MuiAlert)(spacing);

export interface NoPermissionForDeviceProps {
  children?: React.ReactNode;
  mt?: number;
  initiallyExpanded?: boolean;
}

export default function NoPermissionForDevice({children, mt, initiallyExpanded}: NoPermissionForDeviceProps) {
  const [expanded, setExpanded] = useState(initiallyExpanded || false);
  const isSmallScreen = useSmallScreenSize();
  const alertTitle = "You don't have permission to access this build's machine.";

  return (
    <Alert
      severity="warning"
      mt={mt !== undefined ? mt : isSmallScreen ? 3 : 6}
      action={
        <IconButton size="small" onClick={() => setExpanded(!expanded)} aria-label="expand">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      }
    >
      {expanded ? (
        <>
          <AlertTitle>{alertTitle}</AlertTitle>
          {children}
        </>
      ) : (
        // Put alert title in body when not expanded (title looks odd on its own)
        alertTitle
      )}
    </Alert>
  );
}
