import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {IOrganization} from '@common/api/models/organization/IOrganization';

import CustomInput from '../../components/atoms/CustomInput';
import CustomButton from '../../components/atoms/CustomButton';
import {organizationCreatePOST} from '../../api/ajax/organizations';
import RequiredAsterisk from '../../components/atoms/Texts/RequiredAsterisk';
import Header from '../../components/organisms/Header';

export default function CreateOrganizationPage() {
  const {
    register,
    errors,
    handleSubmit: onSubmit,
  } = useForm<IOrganization>({
    mode: 'all',
  });
  const history = useHistory();

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = async (data: IOrganization) => {
    const res = await organizationCreatePOST(data);
    if (res.success) {
      history.replace('/organizations/uuid/' + res.data.uuid + '/');
    }
  };

  return (
    <React.Fragment>
      <Header
        helmet="Create Organization"
        title="Create Organization"
        breadcrumbs={[{title: 'Organization', path: '/organizations'}, 'Create Organization']}
      />

      <Grid container spacing={6} justifyContent={'center'}>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardHeader title={'Organization'}></CardHeader>
            <CardContent>
              <form id="createOrganizationForm">
                <Table style={{borderBottom: 'none'}} size={'small'}>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" variant="head">
                        Name <RequiredAsterisk />
                      </TableCell>
                      <TableCell>
                        <CustomInput
                          type={'text'}
                          fullWidth
                          helperText={errors.name?.message}
                          error={!!errors.name}
                          inputProps={{
                            name: 'name',
                            inputRef: register({
                              required: 'Please enter a name',
                            }),
                            'aria-required': 'true',
                            autoComplete: 'off',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardContent
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant={'outlined'} onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <CustomButton
                    type="submit"
                    form="createOrganizationForm"
                    variant={'contained'}
                    color={'primary'}
                    onClick={onSubmit(handleSubmit)}
                    text="Create Organization"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
