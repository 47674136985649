import styled from 'styled-components';

export const DarkAnchor = styled.a`
  color: #95caff;
  :hover {
    color: #a8cef5;
  }
  :active {
    color: #ecb3fd;
  }
  :visited {
    color: #ecb3fd;
  }
`;
