import {CircularProgress, Typography} from '@material-ui/core';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import React from 'react';
import styled from 'styled-components';
import failedPNG from '../../../assets/img/calibration-cancelling.png';
import defectsLoadingPNG from '../../../assets/img/calibration-capturing.png';
import calculatingPNG from '../../../assets/img/generic-calculating.png';
import generatingPNG from '../../../assets/img/generic-generating.png';

export const ProcessingUploadedData = () => {
  return (
    <GenericLoadingStep
      heading="Processing uploaded CT data..."
      subtitle="Please wait while we generate 3D models of your CT data."
      imgSrc={calculatingPNG}
      imgAlt="Image describing a calculating process"
    />
  );
};

export const PointCloudsLoading = () => {
  return (
    <GenericLoadingStep
      heading="Generating 3D models..."
      subtitle="Please wait while we generate 3D models of your CT data."
      imgSrc={generatingPNG}
      imgAlt="Image describing a generation process"
    />
  );
};

export const DefectsLoading = () => {
  return (
    <GenericLoadingStep
      heading="Processing alignment and calculating CT defects..."
      subtitle="This may take a while."
      imgSrc={defectsLoadingPNG}
      imgAlt="Image describing a calculating process"
    />
  );
};

export const FailureStep = () => {
  return (
    <GenericLoadingStep
      heading="CT Report Generation Failed."
      subtitle="If this issue persists, please contact support."
      imgSrc={failedPNG}
      imgAlt="Image describing a failure"
      hideSpinner
    />
  );
};

const GenericLoadingStep = ({
  heading,
  subtitle,
  imgSrc,
  imgAlt,
  hideSpinner = false,
}: {
  heading: string;
  subtitle: string;
  imgSrc: string;
  imgAlt: string;
  hideSpinner?: boolean;
}) => {
  const isSmallScreen = useSmallScreenSize();

  return (
    <Container isSmallScreen={isSmallScreen}>
      <img src={imgSrc} alt={imgAlt} />
      <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="center">
        {heading}
      </Typography>
      <Typography variant={isSmallScreen ? 'body1' : 'subtitle1'} align="center" style={{width: '75%'}}>
        {subtitle}
      </Typography>
      <br />
      <br />
      {!hideSpinner && <CircularProgress size={isSmallScreen ? undefined : 48} />}
    </Container>
  );
};

const Container = styled.div<{isSmallScreen: boolean}>`
  display: flex;
  height: 100%;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2,
  h4 {
    padding: ${({isSmallScreen}) => (isSmallScreen ? '12px 0px 12px 0px' : '32px 0px 12px 0px')};
  }
  img {
    height: ${({isSmallScreen}) => (isSmallScreen ? '30vh' : '40vh')};
    max-height: 450px;
  }
`;
