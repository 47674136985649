import axios from 'axios';
import {PointCloudGETRequest, PointCloudGETResponse} from '@common/api/models/builds/data/IPointCloud';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function pointCloudAllGET(
  filter: QueryFilter<PointCloudGETRequest>
): AjaxApiResult<PointCloudGETResponse[]> {
  return ajaxWrapper(() => axios.get('/api/pointCloud/', {params: filter}), 'Could not fetch point clouds');
}

export async function pointCloudDownloadUrlGET(uuid: string): AjaxApiResult<{url: string}> {
  return ajaxWrapper(() => axios.get(`/api/pointCloud/${uuid}/downloadUrl/`), 'Could not fetch point cloud');
}
