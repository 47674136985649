import {IMachine, IMachinesTableGETResponse} from '@common/api/models/devices/machines/IMachine';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function machinesAllGET(filter: QueryFilter<IMachinesTableGETResponse>): AjaxApiResult<IMachine[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/machines/' + (query && '?' + query));
  }, 'Could not fetch machines');
}

export async function machineCreatePOST(machine: Partial<IMachine>): AjaxApiResult<IMachine> {
  return ajaxWrapper(() => {
    return axios.post('/api/machines/', machine);
  }, 'Could not create machine');
}

export async function machineByUuidPATCH(uuid: string, updates: Partial<IMachine>): AjaxApiResult<IMachine> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/machines/uuid/${uuid}`, updates);
  }, 'Could not update machine');
}
