import {Pagination} from '@common/api/models/common';

export enum BuildIntensityReportStatus {
  Submitted = 'submitted',
  Generating = 'generating',
  Success = 'success',
  Failure = 'failure',
}

export interface IBuildIntensityReport {
  uuid: string;
  reportUrl?: string;
  createdAt: Date;
  buildUuid: string;
  status: BuildIntensityReportStatus;
}

export type IBuildIntensityReportSortOptions = 'buildName' | 'buildUuid' | 'buildEndDate' | 'createdAt' | 'status';

export interface IBuildIntensityReportsGETRequest extends Pagination {
  uuid: string | null;
  buildUuid: string | null;
  buildName: string | null;
  buildEndDate: string | null;
  status: BuildIntensityReportStatus | null;
}

export interface IBuildIntensityReportGETResponse extends Omit<IBuildIntensityReport, 'reportUrl'> {
  buildUuid: string;
  buildEndDate: Date;
  buildName: string;
}

export interface IBuildIntensityReportPOSTRequest {
  buildUuid: string;
}
export interface IBuildIntensityReportUrlResponse {
  url: string;
}
