import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';

import {Color} from 'three';
import {View3DViewportParams} from '../View3DViewport';
import {SOURCE_PART_COLOR, TARGET_PART_COLOR} from '../AnalysisTypeHelper';
import {PartPointClouds} from '../PartPointCloud';
import {ThreePoints} from '../types/pointCloudTypes';
import {PointCloudViewportParams} from '../types/params';

export const toggleSimilarityTransparency = (
  params: PointCloudViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  pointClouds.forEachPointCloud((partModel) => {
    if (!partModel.userData.isSimilarityPartPointCloud) {
      (partModel as ThreePoints).material.depthWrite = !params.isTransparent;
    }
    (partModel as ThreePoints).material.opacity = params.isTransparent ? 0.5 : 1;
  });

  renderScene();
};

export const toggleSimilarityRotationColours = (
  params: View3DViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  pointClouds.forEachPointCloud((partModel) => {
    const partUuid = partModel.userData.partUuid;

    if (params.selectedParts.length === 2) {
      if (partUuid === params.selectedParts[0].uuid) {
        (partModel as ThreePoints).material.color = new Color(SOURCE_PART_COLOR);
      } else {
        (partModel as ThreePoints).material.color = new Color(TARGET_PART_COLOR);
      }
    }
  });

  renderScene();
};

export const toggleSimilarityGeometry = (
  params: View3DViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  const allGeometryEnabled = params.selectedAnalysisTypes[AnalysisType3D.Geometry];
  const someGeometryEnabled = !!allGeometryEnabled || !!params.sourceGeometryEnabled || !!params.targetGeometryEnabled;

  pointClouds.forEachPointCloud(
    (partModel) => {
      if (partModel.userData.similarityGeometryType === 'source') {
        const sourceEnabled = !!params.sourceGeometryEnabled;
        partModel.visible = sourceEnabled || allGeometryEnabled;
      }
      if (partModel.userData.similarityGeometryType === 'target') {
        const targetEnabled = !!params.targetGeometryEnabled;
        partModel.visible = targetEnabled || allGeometryEnabled;
      }
    },
    {analysisTypes: [AnalysisType3D.Geometry]}
  );

  pointClouds.forEachAnalysisType((group) => (group.visible = someGeometryEnabled), {
    analysisTypes: [AnalysisType3D.Geometry],
  });

  renderScene();
};
