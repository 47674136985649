import * as React from 'react';
import styled from 'styled-components';
import {Typography, Chip, ChipProps as MuiChipProps} from '@material-ui/core';

import {
  dangerColor,
  grayColor,
  infoColor,
  lowWarningColor,
  successColor,
  warningColor,
} from '../../../assets/jss/material-dashboard-react';

export type StatusVariant = 'primary' | 'secondary' | 'error' | 'lowWarning' | 'warning' | 'success' | 'info' | 'none';
export interface StatusProps {
  variant: StatusVariant;
  message: string;
  inTitle?: boolean;
  chipSize?: MuiChipProps['size'];
}

interface ChipProps {
  rgbcolor: string;
  size?: string;
}

// TODO: Replace list with a different element as it's confusing when someone uses voiceover.
export const StatusItem = styled.li`
  &:before {
    background-color: ${(props: ChipProps) => props.rgbcolor};
    box-shadow: 0px 0px 4px 1px ${(props: ChipProps) => props.rgbcolor};

    content: '';
    display: inline-block;
    width: ${(props: ChipProps) => props.size};
    height: ${(props: ChipProps) => props.size};
    border-radius: ${(props: ChipProps) => props.size};
    margin-right: ${(props: ChipProps) => props.size};
  }
`;

export const StatusContainer = styled.ul`
  white-space: nowrap;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  list-style: none;
`;

const getStatusColor = (variant: StatusVariant) => {
  switch (variant) {
    case 'error':
      return dangerColor[0];
    case 'lowWarning':
      return lowWarningColor[0];
    case 'warning':
      return warningColor[0];
    case 'success':
      return successColor[0];
    case 'info':
      return infoColor[0];
    case 'none':
      return grayColor[4];
    default:
      return grayColor[0];
  }
};
export default function Status(props: StatusProps) {
  const colour = getStatusColor(props.variant);

  return (
    <StatusContainer>
      <Typography variant={props.inTitle ? 'h5' : 'body1'}>
        <StatusItem size={props.inTitle ? '12px' : '9px'} rgbcolor={colour}>
          {props.message}
        </StatusItem>
      </Typography>
    </StatusContainer>
  );
}

export const ChipStatus = (props: StatusProps) => {
  const colour = getStatusColor(props.variant);

  // Black text on light background
  if (['none', 'lowWarning'].includes(props.variant))
    return <Chip label={props.message} style={{backgroundColor: colour}} size={props.chipSize} />;

  return (
    <Chip
      label={props.message}
      size={props.chipSize}
      color={props.variant === 'secondary' ? 'secondary' : 'primary'}
      style={{
        lineHeight: '32px',
        ...(['primary', 'secondary'].includes(props.variant) ? {} : {backgroundColor: colour}),
      }}
    />
  );
};
