import axios from 'axios';
import * as qs from 'qs';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {DefectStatisticGETResponse} from '@common/api/models/builds/data/defects/IDefectStatistic';

export async function defectStatisticGET(
  filter: QueryFilter<DefectStatisticGETResponse> // N.B. Type is response due to bad typing requirements of redux store
): AjaxApiResult<DefectStatisticGETResponse[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/defectStats/' + (query && '?' + query));
  }, 'Could not get defect statistic data');
}
export async function defectStatisticCsvUrlGET(buildUuid: string): AjaxApiResult<string> {
  return ajaxWrapper(() => {
    return axios.get(`/api/defectStats/csvUrl/${buildUuid}`);
  }, 'Could not get download link');
}
