import React from 'react';
import {Card, CardContent, Table, TableBody} from '@material-ui/core';
import {BatchPageProps} from './index';
import {toast} from 'react-toastify';

import ViewEditFieldRow from '../../../components/atoms/ViewEditFieldRow';
import {batchesByUuidPATCH} from '../../../api/ajax/batches';
import {validator} from '@common/api/types';
import {validateBatchName} from '@common/api/models/materials/batches/Batch.validation';

export default function GeneralDetailsPage(props: BatchPageProps) {
  return (
    <Card className="rt-batch-generalinfo">
      <CardContent>
        <Table style={{tableLayout: 'fixed'}}>
          <colgroup>
            <col span={1} width="148x" />
            <col span={1} width="auto" />
            <col span={1} width="96px" />
          </colgroup>
          <TableBody>
            <ViewEditFieldRow
              type="text"
              name={'Name'}
              value={props.batch.name}
              validateFn={(value) => validator(validateBatchName, value, (message) => toast(message, {type: 'error'}))}
              saveValue={async (name) => {
                await batchesByUuidPATCH(props.batch.uuid, {name});
              }}
            />
            <ViewEditFieldRow
              type="text"
              name={'Description'}
              value={props.batch.description!}
              saveValue={async (description) => {
                await batchesByUuidPATCH(props.batch.uuid, {description});
              }}
              multiline
            />
            <ViewEditFieldRow
              type="number"
              name={'Quantity'}
              value={props.batch.quantity}
              units="kg"
              readOnly={true}
              saveValue={() => {}}
            />
            <ViewEditFieldRow
              type="text"
              name={'Active'}
              value={props.batch.current ? 'Yes' : 'No'}
              readOnly={true}
              saveValue={() => {}}
            />
            <ViewEditFieldRow
              type="date"
              name={'Registered Date'}
              value={props.batch.registered}
              readOnly={true}
              saveValue={() => {}}
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
