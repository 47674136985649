import React from 'react';
import BottomToolbarContainer from '../../../components/atoms/BottomToolbarContainer';
import {Menu} from '@material-ui/icons';
import {Box, Button, Drawer, makeStyles, Typography} from '@material-ui/core';
import {useMobileDrawer} from '../../../utils/utilHooks';
import CTDefectFilters from './CTDefectFilters';
import {CTFiltersMap} from '../../../components/molecules/Viewport/3D/types/pointCloudTypes';

const DraftBuildBottomToolbar = ({
  ctFilters,
  ctFilterRanges,
  setCtFilters,
}: {
  ctFilters: CTFiltersMap;
  ctFilterRanges: CTFiltersMap;
  setCtFilters: (filters: CTFiltersMap) => void;
}) => {
  const drawerStyles = useDrawerStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useMobileDrawer();

  return (
    <BottomToolbarContainer columns="60% 40%">
      <Button
        variant="text"
        color="primary"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        style={{justifySelf: 'self-start', width: '100%'}}
      >
        <Menu />
        <Box display="flex" flexDirection="column" alignItems="flex-start" ml={3} width="calc(100% - 32px)">
          <Typography align="left" style={{width: '100%'}}>
            Defect Filters
          </Typography>
        </Box>
      </Button>

      <Box />

      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} classes={drawerStyles}>
        <Box display="flex" flexDirection="column" padding="12px" overflow="hidden">
          <CTDefectFilters ctFilterRanges={ctFilterRanges} ctFilters={ctFilters} setCtFilters={setCtFilters} />
        </Box>
      </Drawer>
    </BottomToolbarContainer>
  );
};

export default DraftBuildBottomToolbar;

const useDrawerStyles = makeStyles({
  paperAnchorBottom: {
    bottom: '4rem !important',
  },
  root: {
    '& .MuiBackdrop-root': {
      bottom: '4rem !important',
    },
  },
  modal: {
    zIndex: '1298 !important' as any,
  },
});
