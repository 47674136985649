import React from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import CenteredPage from './CenteredPage';

function LoadingPage({loadingText}: {loadingText: string}) {
  return (
    <CenteredPage>
      <Typography variant="h3" align="center" style={{marginBottom: '32px'}}>
        {loadingText}
      </Typography>
      <CircularProgress />
    </CenteredPage>
  );
}

export default LoadingPage;
