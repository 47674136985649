import React from 'react';
import {Card as MuiCard, Paper, Typography, CardActionArea, Box, useTheme, Chip as MuiChip} from '@material-ui/core';
import {
  TimelineOppositeContent,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector as MuiTimelineConnector,
  TimelineContent as MuiTimelineContent,
  TimelineDot as MuiTimelineDot,
} from '@material-ui/lab';
import styled from 'styled-components';
import buildSteps, {BuildStep} from './draftBuildStepsConfig';
import {useDraftBuild} from './DraftBuildContext';
import {useActiveStep} from './ActiveStepContext';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import LinearProgressWithLabel from '../../../components/atoms/LinearProgressWithLabel';

export const usePercentComplete = () => {
  const {isStepComplete} = useDraftBuild();
  const {activeStepId} = useActiveStep();

  let lastCompletedInChain = -1;
  for (const step of buildSteps) {
    if (isStepComplete(step)) lastCompletedInChain += 1;
    else break;
  }
  // Only show completed upto the current step
  lastCompletedInChain = Math.min(lastCompletedInChain, activeStepId);

  const progressPercent = Math.round(((lastCompletedInChain + 1) / buildSteps.length) * 100);

  return {progressPercent, lastCompletedInChain};
};

const DraftBuildProgressTracker = ({onSelect}: {onSelect?: () => void}) => {
  const {progressPercent, lastCompletedInChain} = usePercentComplete();
  const isSmallScreen = useSmallScreenSize();

  return (
    <Paper>
      <Box
        style={{
          padding: isSmallScreen ? '12px 12px 0px 12px' : '24px 24px 0px 24px',
        }}
      >
        <Typography variant="h5">Step Tracker</Typography>
        <LinearProgressWithLabel value={progressPercent} style={{borderRadius: '4px'}} />
      </Box>

      <Timeline align="left" style={{padding: '0px', margin: isSmallScreen ? '0px' : '24px 0px'}}>
        {buildSteps.map((step, index) => (
          <TimelineNavigationItem
            key={step.id}
            step={step}
            index={index}
            lastCompletedInChain={lastCompletedInChain}
            onSelect={onSelect}
          />
        ))}
      </Timeline>
    </Paper>
  );
};

export default DraftBuildProgressTracker;

type TimelineItemProps = {
  step: BuildStep;
  index: number;
  lastCompletedInChain: number;
  onSelect?: () => void;
};
const TimelineNavigationItem = ({step, index, lastCompletedInChain, onSelect}: TimelineItemProps) => {
  const theme = useTheme();
  const {isStepComplete} = useDraftBuild();
  const {activeStepId, handleStepChange} = useActiveStep();
  const isSmallScreen = useSmallScreenSize();

  const completed = index <= lastCompletedInChain;
  const optionallyIncomplete = completed && !isStepComplete(step, false);
  const active = activeStepId === index;

  return (
    <Card
      onClick={() => {
        onSelect && onSelect();
        handleStepChange(step.id);
      }}
    >
      {/* CardActionArea is a used to give the card a clickable on hover feel */}
      <CardActionArea
        style={{
          padding: isSmallScreen ? '0px 12px' : '0px 24px',
          background: active ? theme.palette.grey[200] : '',
        }}
      >
        <TimelineItem style={isSmallScreen ? {minHeight: '60px'} : {}}>
          {/* The custom style is a hack to reduce left padding */}
          <TimelineOppositeContent style={{flex: 0, padding: 0, paddingLeft: 5}} />
          <TimelineSeparator>
            <TimelineConnector index={index} completed={completed} direction="start" />
            <TimelineDot
              color={optionallyIncomplete ? 'secondary' : completed ? 'primary' : 'grey'}
              isSmallScreen={isSmallScreen}
            >
              <step.icon />
            </TimelineDot>
            <TimelineConnector index={index} completed={completed} direction="end" />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant={isSmallScreen ? 'subtitle2' : 'h6'}>
              {step.title}
              {step.optional ? (
                <Chip color="default" label="optional" size="small" isSmallScreen={isSmallScreen} />
              ) : (
                <></>
              )}
            </Typography>
            <Typography>{step.subtitle}</Typography>
          </TimelineContent>
        </TimelineItem>
      </CardActionArea>
    </Card>
  );
};

const TimelineConnector = ({
  index,
  completed,
  direction,
}: {
  index: number;
  completed: boolean;
  direction: 'start' | 'end';
}) => {
  const theme = useTheme();

  const comparisonIndex = direction === 'start' ? 0 : buildSteps.length - 1;
  const backgroundColor = completed ? theme.palette.primary.main : '';

  return (
    <MuiTimelineConnector
      style={{
        backgroundColor: backgroundColor,
        visibility: index === comparisonIndex ? 'hidden' : 'visible',
      }}
    />
  );
};

const Card = styled(MuiCard)`
  padding: 0px;
`;

const TimelineDot = styled(MuiTimelineDot)<{isSmallScreen: boolean}>`
  margin: 0px;
  width: ${({isSmallScreen}) => (isSmallScreen ? '32px' : '42px')};
  height: ${({isSmallScreen}) => (isSmallScreen ? '32px' : '42px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimelineContent = styled(MuiTimelineContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Chip = styled(MuiChip)<{isSmallScreen: boolean}>`
  margin-left: 8px;
  font-weight: 500;
  margin-bottom: 4px;
  ${({isSmallScreen}) => (isSmallScreen ? 'height: 18px; font-size: 12px;' : '')}
`;
