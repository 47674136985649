import React from 'react';
import {Role, getRoleName} from '@common/api/models/users/IUser';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import envConfig from '../../../../envConfig';

export interface UserRow {
  name: string;
  email: string;
  role: Role;
  registered: Date | string;
  lastActivityOn: Date | string | undefined;
}

export const userTableSchema = [
  {
    title: 'Name',
    field: 'name',
    sortField: 'firstName',
    render: (row: UserRow) => <EllipsisTextColumnTooltip>{row.name}</EllipsisTextColumnTooltip>,
  },
  {
    title: envConfig.isOnPremise ? 'Email / Username' : 'Email',
    field: 'email',
    sortField: 'email',
    render: (row: UserRow) => <EllipsisTextColumnTooltip>{row.email}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'Role',
    field: 'role',
    width: '112px',
    render: (row: UserRow) => <EllipsisTextColumnTooltip>{getRoleName(row.role)}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'Registered On',
    field: 'registered',
    type: 'date' as const,
    width: '148px',
    emptyValue: '',
    render: (row: UserRow) => <DateWithTimeTooltip timestamp={row.registered} />,
  },
  {
    title: 'Last Activity On',
    field: 'lastActivityOn',
    type: 'date' as const,
    emptyValue: '',
    width: '184px',
    render: (row: UserRow) =>
      !!row.lastActivityOn ? <DateWithTimeTooltip timestamp={row.lastActivityOn} type="long" /> : '-',
  },
];

export default userTableSchema;
