import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {BackToStagingButton} from './BackToStagingButton';
import {ResumeMonitoringButton} from './ResumeMonitoringButton';
import {PauseBuildButton} from './PauseBuildButton';
import {ResumeBuildButton} from './ReprovisionButton';
import FinishBuildButton from './FinishBuildButton';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {RootState} from '../../../../store/reducers/index';
import {useSelector} from 'react-redux';

interface BuildStateButtonProps {
  buildIsCompleted: boolean;
  hasLayers: boolean;
  build: IBuild;
}

const BuildStateButtons = ({buildIsCompleted, hasLayers, build}: BuildStateButtonProps) => {
  const device = useSelector((state: RootState) => state.deviceStore.byId[build.deviceSerial!]);

  return (
    <>
      {!buildIsCompleted && (
        <React.Fragment>
          {!hasLayers && (
            <Wrapper>
              <BackToStagingButton build={build} fullWidthButton />
            </Wrapper>
          )}

          {hasLayers && (
            <Wrapper>
              {build.state === BuildState.PAUSED ? (
                <ResumeMonitoringButton build={build} deviceDetailedState={device?.stateDetailed} fullWidthButton />
              ) : (
                <PauseBuildButton build={build} deviceDetailedState={device?.stateDetailed} fullWidthButton />
              )}
            </Wrapper>
          )}

          {hasLayers && (
            <Wrapper>
              <FinishBuildButton build={build} fullWidthButton />
            </Wrapper>
          )}
        </React.Fragment>
      )}

      {buildIsCompleted && (
        <Wrapper>
          <ResumeBuildButton build={build} fullWidthButton />
        </Wrapper>
      )}
    </>
  );
};

export default BuildStateButtons;

const Wrapper = ({children}: {children: React.ReactNode}) => {
  const isSmallScreen = useSmallScreenSize();

  if (isSmallScreen) return <Box mb={2}>{children}</Box>;

  return <Grid item>{children}</Grid>;
};
