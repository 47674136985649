import React, {useState} from 'react';
import {CoolLinkedTable} from './CoolTable';
import {MaterialTableProps} from 'material-table';
import {renderDateTimeString} from '../../../utils/string';
import {Typography} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {GenericDialog} from '../DialogButton';
import {machineLogDELETE} from '../../../api/ajax/machineLogs';

export interface MaintenanceLogEntry {
  uuid: string;
  user: string;
  message: string;
  date: Date;
  userUuid: string;
}

export interface MaintenanceLogRow {
  uuid: string;
  user: string;
  message: string;
  date: Date;
  userUuid: string;
  url: string;
}

export function MaintenanceLogTable(
  props: {
    messages: MaintenanceLogEntry[];
    errorMessage?: string;
    filters?: Partial<MaintenanceLogRow>;
  } & Partial<MaterialTableProps<MaintenanceLogRow>>
) {
  const auth = useSelector((s: RootState) => s.auth);
  const [deletingLog, setDeletingLog] = useState<MaintenanceLogEntry | null>(null);
  const [deleting, setDeleting] = useState(false);

  const {messages, errorMessage, filters, ...rest} = props;
  return (
    <div style={{maxWidth: '100%'}}>
      <CoolLinkedTable
        columns={[
          {title: 'User', field: 'user', width: '168px'},
          {
            title: 'Message',
            field: 'message',
            render: (row: MaintenanceLogEntry) => {
              const lines = row.message.split(/\n/);
              return (
                <>
                  {lines.map((line, index) => (
                    <Typography
                      style={
                        index === 0
                          ? {marginBottom: '6px'}
                          : index === lines.length - 1
                          ? {marginTop: '6px'}
                          : {margin: '6px 0px'}
                      }
                    >
                      {line}
                    </Typography>
                  ))}
                </>
              );
            },
          },
          {
            title: 'Date',
            field: 'date',
            type: 'date',
            width: '168px',
            render: (row: MaintenanceLogEntry) => (
              <Typography>{renderDateTimeString('short', row.date, true)}</Typography>
            ),
          },
        ]}
        data={messages.map((m): MaintenanceLogRow => ({...m, url: ''}))}
        filters={filters}
        errorMessage={errorMessage}
        linked={false}
        options={{
          actionsColumnIndex: -1, // Set the delete icon to the right of the table
          actionsCellStyle: {padding: '0px'},
        }}
        actions={[
          (rowData) => ({
            icon: () => <Delete />,

            tooltip: rowData.userUuid === auth.user?.uuid ? 'Delete Log' : `Only ${rowData.user} can delete this note`,
            disabled: rowData.userUuid !== auth.user?.uuid,
            // @ts-ignore: FIXME: types are not correctly
            onClick: (event, rowData: IBuiltNote) => setDeletingLog(rowData),
          }),
        ]}
        {...rest}
      />
      <GenericDialog
        title="Delete Maintenance Log"
        content={
          <>
            <Typography>Are you sure you want to delete the following log?</Typography>
            {deletingLog?.message.split(/\n/).map((line) => (
              <Typography style={{margin: 'revert'}}>
                <b>
                  <i>{line}</i>
                </b>
              </Typography>
            ))}
          </>
        }
        confirmText="Delete"
        isOpen={!!deletingLog}
        closeDialog={() => setDeletingLog(null)}
        onSuccess={async () => {
          setDeleting(true);
          if (deletingLog) {
            await machineLogDELETE(deletingLog?.uuid);
          }
          setDeleting(false);
          setDeletingLog(null);
        }}
        requestInProgress={deleting}
        danger={true}
      />
    </div>
  );
}
