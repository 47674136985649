import {Box, Button, Card, CircularProgress, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useCtReportStoreActions} from '../../store/actions';
import {FetchingState} from '../../store/model/liveUpdateStore';
import {RootState} from '../../store/reducers';
import React from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import CTReportStepView from './steps/CTReportStep';
import Header from '../../components/organisms/Header';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {CTReportStatus} from '@common/api/models/builds/data/ICTReport';

function CTReportPage() {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const {uuid: ctReportUuid} = useParams<{uuid: string}>();

  const ctReportActions = useCtReportStoreActions();

  useEffect(() => {
    if (ctReportUuid) ctReportActions.ensureConsistent({uuid: ctReportUuid, take: 1}, {}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctReportUuid]);

  const ctReportStore = useSelector((state: RootState) => state.ctReportStore.byId);

  const fetchingReports = useSelector((state: RootState) => state.ctReportStore.fetched === FetchingState.Fetching);

  const currentCTReport = ctReportStore[ctReportUuid];

  useEffect(() => {
    if (currentCTReport && currentCTReport.status === CTReportStatus.COMPLETED) {
      history.replace(`/reports/ct/${ctReportUuid}/results`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCTReport]);

  if (fetchingReports) {
    return (
      <Box height={'100%'} width={'100%'} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!currentCTReport) {
    return (
      <>
        <Alert severity="error">CT Report not found</Alert>
        <Button
          variant={'contained'}
          color={'primary'}
          style={{marginTop: 10}}
          onClick={() => history.push('/reports/ct')}
        >
          Return to CT Reports
        </Button>
      </>
    );
  }

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Header
        helmet={currentCTReport.name}
        title={
          <Typography variant="h4" style={{display: 'inline-flex', alignItems: 'center'}}>
            {currentCTReport.name}
          </Typography>
        }
        breadcrumbs={[
          {title: 'CT Reports', path: '/reports/ct/'},
          {title: currentCTReport.name, path: `/reports/ct/${currentCTReport.uuid}`},
        ]}
      />
      {currentCTReport.status === CTReportStatus.COMPLETED && isSmallScreen ? (
        <CTReportStepView currentReport={currentCTReport} />
      ) : (
        <Card style={{flex: 10, height: isSmallScreen ? '484px' : undefined}}>
          <CTReportStepView currentReport={currentCTReport} />
        </Card>
      )}
    </Box>
  );
}

export default CTReportPage;
