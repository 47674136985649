import React from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {IDevice} from '@common/api/models/devices/IDevice';
import ladyInspectDocument from '../../../../../assets/img/lady-inspect-document.png';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';

const NoFocusResults = ({device, waitingForFocus}: {device: IDevice; waitingForFocus: boolean}) => {
  const isSmallScreen = useSmallScreenSize();

  if (!device) return <></>;

  return (
    <NoFocusResultsContainer isSmallScreen={isSmallScreen}>
      <img src={ladyInspectDocument} alt="lady with magnifying glass inspecting paper" />
      {waitingForFocus ? (
        <>
          <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="center">
            Initializing autofocus...
          </Typography>
          <CircularProgress size={isSmallScreen ? 30 : 40} />
        </>
      ) : (
        <>
          <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="center">
            No focusing results found
          </Typography>
          <Typography variant={isSmallScreen ? 'body1' : 'subtitle1'} align="center">
            Please begin autofocusing or launch the manual focus mode.
          </Typography>
        </>
      )}
    </NoFocusResultsContainer>
  );
};

export default NoFocusResults;

const NoFocusResultsContainer = styled.div<{isSmallScreen: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({isSmallScreen}) => (isSmallScreen ? '12px 0px' : '64px 0px')};
  h2,
  h4 {
    padding: ${({isSmallScreen}) => (isSmallScreen ? '12px 0px 12px 0px' : '32px 0px 12px 0px')};
  }
  img {
    height: 25vh;
    max-height: 250px;
  }
`;
