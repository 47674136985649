import React from 'react';
import {ColorPicker} from 'material-ui-color';
import {Typography, Divider, Slider, Grid, Tooltip} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import {
  LineSettingsInfo,
  LineType,
  RollingDataConfig,
  SelectedGraphConfig,
  RollingMethod,
} from '../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {CustomToggleButtonGroup} from '../../atoms/Switches/CustomToggle';
import {Line} from '../Modals/GraphOptionsModal';
import {capitalise} from '../../../utils/string';
import AntSwitch from '../../atoms/Switches/AntSwitch';
import {SelectedDefectSummary} from '../../../pages/builds/liveBuild/activeBuildPages/useDefectSummaries';
import ConditionalTooltip from '../../atoms/Texts/ConditionalTooltip';

export const LineSettings = ({
  lineSettings,
  line,
  updateGraphConfig,
}: {
  lineSettings: LineSettingsInfo;
  line: Line;
  updateGraphConfig: <K extends keyof SelectedGraphConfig>(key: K, value: SelectedGraphConfig[K]) => void;
}) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <Typography noWrap gutterBottom variant="subtitle2">
              {line === Line.dataLine ? 'Data Line Settings' : 'Rolling Data Line Settings'}
            </Typography>
          </Grid>
          <Grid item style={{flexGrow: 1, marginLeft: '1rem'}}>
            <Divider></Divider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Grid item>
            <Typography style={{fontWeight: 'bold'}}>Line Style :</Typography>
          </Grid>
          <Grid item>
            <Typography style={{marginLeft: '10px'}}>{capitalise(lineSettings.lineType)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item sm={7} xs={12}>
            <CustomToggleButtonGroup<LineType>
              currentValue={lineSettings.lineType}
              values={LineType}
              updateValue={(newLineType) =>
                updateGraphConfig(line, {
                  ...lineSettings,
                  lineType: newLineType,
                })
              }
              labelOverrides={{
                dotDash: 'Dash & Dot',
              }}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <ColorPicker
              value={lineSettings.lineColor}
              onChange={(newColour) =>
                updateGraphConfig(line, {
                  ...lineSettings,
                  lineColor: '#' + newColour.hex,
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Grid item>
            <Typography style={{fontWeight: 'bold'}}>Line width :</Typography>
          </Grid>
          <Grid item>
            <Typography style={{marginLeft: '10px'}}>{lineSettings.lineWeight}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Slider
            value={lineSettings.lineWeight ?? 1}
            min={1}
            max={6}
            onChange={(_, newWeight) => {
              updateGraphConfig(line, {
                ...lineSettings,
                lineWeight: newWeight as number,
              });
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const TrendlineSettings = ({
  rollingData,
  selectedDefect,
  updateGraphConfig,
  useCoverage,
}: {
  rollingData: RollingDataConfig;
  selectedDefect?: SelectedDefectSummary;
  updateGraphConfig: <K extends keyof SelectedGraphConfig>(key: K, value: SelectedGraphConfig[K]) => void;
  useCoverage: boolean;
}) => {
  const graphConfig = selectedDefect?.graphConfig;

  return (
    <Grid container direction="column" spacing={1} style={{margin: '1rem 0rem'}}>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Grid item>
            <Typography noWrap gutterBottom variant="subtitle2">
              {!selectedDefect && 'Global '}Rolling Data Settings
            </Typography>
          </Grid>
          <Grid item style={{flexGrow: 1, marginLeft: '1rem'}}>
            <Divider></Divider>
          </Grid>
        </Grid>
      </Grid>
      {selectedDefect && (
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={5} xs={7}>
                  <Typography noWrap style={{fontWeight: 'bold'}}>
                    Individual Settings
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <AntSwitch
                    size="small"
                    checked={graphConfig?.useOverrides}
                    onClick={() => updateGraphConfig('useOverrides', !graphConfig?.useOverrides)}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    style={{maxWidth: '30vw'}}
                    title={
                      <Typography style={{padding: '4px'}}>
                        Enabling Individual Settings will use the settings below rather than the global settings
                      </Typography>
                    }
                    placement="right"
                  >
                    <InfoIcon style={{marginLeft: '5px'}} fontSize="small" color="primary"></InfoIcon>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={5} xs={7}>
                  <Typography noWrap style={{fontWeight: 'bold'}}>
                    Show Trendline
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <AntSwitch
                    size="small"
                    checked={graphConfig?.showTrendline}
                    onClick={() => updateGraphConfig('showTrendline', !graphConfig?.showTrendline)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={5} xs={7}>
                  <Typography noWrap style={{fontWeight: 'bold'}}>
                    Show Original Data
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <AntSwitch
                    checked={graphConfig?.showOriginal}
                    size="small"
                    onClick={() => updateGraphConfig('showOriginal', !graphConfig?.showOriginal)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sm={5} xs={7}>
                  <Typography noWrap style={{fontWeight: 'bold'}}>
                    Show Part Area
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={1}>
                  <ConditionalTooltip
                    tooltip="Cannot turn on part areas when coverage is turned on"
                    hideTooltip={!useCoverage}
                    placement="right"
                  >
                    <AntSwitch
                      size="small"
                      checked={graphConfig?.showPartArea}
                      onClick={() => updateGraphConfig('showPartArea', !graphConfig?.showPartArea)}
                      disabled={useCoverage}
                      color="primary"
                    />
                  </ConditionalTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <Typography gutterBottom style={{fontWeight: 'bold'}}>
                  Function :
                </Typography>
              </Grid>
              <Grid item>
                <Typography gutterBottom style={{marginLeft: '10px'}}>
                  {capitalise(rollingData.rollingMethod)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CustomToggleButtonGroup<RollingMethod>
              currentValue={rollingData.rollingMethod}
              values={RollingMethod}
              updateValue={(newMethod) =>
                updateGraphConfig('rollingDataConfig', {
                  ...rollingData,
                  rollingMethod: newMethod,
                })
              }
            />
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item>
                <Typography style={{fontWeight: 'bold'}}>Rolling Period :</Typography>
              </Grid>
              <Grid item>
                <Typography style={{marginLeft: '10px'}}>{rollingData.rollingPeriod}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Slider
              value={rollingData.rollingPeriod}
              step={1}
              min={1}
              max={200}
              onChange={(_, newPeriod) =>
                updateGraphConfig('rollingDataConfig', {
                  ...rollingData,
                  rollingPeriod: newPeriod as number,
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
