import React, {useState, useEffect} from 'react';
import {Grid, TextField} from '@material-ui/core';
import inflection from 'inflection';
import {FilterComponentProps} from '../FilterModal';
import {Autocomplete} from '@material-ui/lab';
import {newDebouncer} from '../../../../api/ajax';
import {parseCurrentFilters} from './utils';

const options = [true, false];

const debounceSearch = newDebouncer();

const BooleanFilter = ({field, control, label, currentFilters, onSubmit, filterListLocation}: FilterComponentProps) => {
  const initialValue = parseCurrentFilters(currentFilters, field);
  const [internalValue, setInternalValue] = useState<boolean>(initialValue[1]);

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSearch(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  useEffect(() => {
    control.register(field);
  }, [control, field]);

  useEffect(() => {
    const filterValue = parseCurrentFilters(currentFilters, field);
    setInternalValue(filterValue[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const setValue = (option: boolean) => {
    setInternalValue(option);
    control.setValue(field, option);
    submitField();
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Autocomplete
          filterSelectedOptions
          size="small"
          id={`boolean-filter-${field}`}
          options={options || []}
          getOptionLabel={(option) => (!!option ? 'True' : 'False')}
          value={internalValue}
          onChange={(_event: any, option: any) => setValue(option)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={label || inflection.capitalize(field)}
              label={label || inflection.capitalize(field)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default BooleanFilter;
