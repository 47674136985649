import React from 'react';
import {DeviceAvailability, isDeviceOnline} from '@common/api/models/devices/IDevice';
import {DateWithTimeTooltip} from './sharedColumns';
import {Box, Typography} from '@material-ui/core';
import DeviceAvailabilityStatus from '../../../../components/atoms/Status/DeviceAvailabilityStatus';
import LaserState from '../../../builds/liveBuild/shared/LaserState';
import CameraState from '../../../builds/liveBuild/shared/CameraState';

export interface DeviceRow {
  availability: DeviceAvailability;
  serial: string;
  deviceId: string;
  model: string;
  createdAt: Date | string;
  machine: string;
  url: string | undefined;
}

const deviceTableSchema = [
  {
    title: 'Availability',
    field: 'availability',
    render: (row: DeviceRow) => <DeviceAvailabilityStatus availability={row.availability} />,
  },
  {title: 'Device', field: 'deviceId'},
  {title: 'Model', field: 'model'},
  {title: 'Machine', field: 'machine', sortField: 'machineName'},
  {
    title: 'Registered On',
    field: 'createdAt',
    defaultSort: 'desc',
    type: 'date' as const,
    emptyValue: '',
    width: '148px',
    render: (row: DeviceRow) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Camera State',
    field: 'cameraState',
    sorting: false,
    width: '142px',
    render: (row: DeviceRow) => {
      if (!isDeviceOnline(row.availability)) return <Typography color="error">Device is offline</Typography>;

      return (
        <Box display="flex" marginLeft="-10px">
          <CameraState deviceSerial={row.serial} />
          <LaserState deviceSerial={row.serial} />
        </Box>
      );
    },
  },
];

export default deviceTableSchema;
