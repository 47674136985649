import * as React from 'react';
import {Box, Card, CardContent, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {MachineAvatar} from '../../../components/molecules/avatars/MachineAvatar';
import {renderDateString, renderDateTimeString} from '../../../utils/string';
import useMachine from '../hooks/useMachine';
import CameraState from '../../builds/liveBuild/shared/CameraState';
import LaserState from '../../builds/liveBuild/shared/LaserState';

const Centered = styled.div`
  text-align: center;
`;

export default function MachineInfoCard() {
  const {device, machine} = useMachine();

  return (
    <Card>
      <CardContent>
        <Centered>
          <MachineAvatar machine={machine} device={device} />

          <Typography variant="body2" component="div" gutterBottom style={{marginTop: '0.5rem'}}>
            <Box fontWeight="fontWeightMedium">{machine.name}</Box>
            <Box fontWeight="fontWeightRegular">{machine.model}</Box>
            {machine.location ? (
              <Box fontWeight="fontWeightRegular">{machine.location}</Box>
            ) : (
              <Box fontWeight="fontWeightRegular">
                <i>No location specified</i>
              </Box>
            )}
          </Typography>

          {device?.online && (
            <Box display="flex" justifyContent="center" margin="8px 0px">
              <CameraState deviceSerial={device.serial} />
              <LaserState deviceSerial={device.serial} />
            </Box>
          )}

          <Typography variant="body2" component="div" gutterBottom>
            <br />
            <Box fontWeight="fontWeightRegular">Commissioned: {renderDateString('short', machine.commissioned)}</Box>
            <Box fontWeight="fontWeightRegular">Registered: {renderDateString('short', machine.registered)}</Box>
            {device && device.lastActivityTime && (
              <Box fontWeight="fontWeightRegular">
                Last seen: {renderDateTimeString('short', device.lastActivityTime, true)}
              </Box>
            )}
          </Typography>
        </Centered>
      </CardContent>
    </Card>
  );
}
