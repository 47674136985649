import {BatchAvailability} from '@common/api/models/materials/batches/IBatch';
import {materialsAllGET} from '../../../../api/ajax/materials';
import {FilterSchemaType} from './index';

export const batchDefaultFilters = {retired: false, current: true};

const batchFilters: Array<FilterSchemaType> = [
  {field: 'name', type: 'string'},
  {
    field: 'availability',
    type: 'enumSingle',
    enumValues: Object.values(BatchAvailability).filter((value) => typeof value !== 'number'),
  },
  {
    field: 'materialUuid',
    label: 'material',
    type: 'resourceSelect',
    searchGET: materialsAllGET,
  },
  {field: 'quantity', label: 'Total Qty', type: 'float', placeholder: 'kg'},
  {field: 'registered', label: 'Registered', type: 'dateRange'},
];

export default batchFilters;
