import React from 'react';
import {Tooltip} from '@material-ui/core';
import {OfflineBolt} from '@material-ui/icons';
import {ActiveDiv, ViewerButton, ViewerButtonActive} from '../../FadingComponents';
import {BUTTON_MARGIN} from '../utils';

interface Props {
  checked: boolean;
  setChecked: (val: boolean) => void;
  mouseOn: boolean;
  cssTop: number;
}

export const PerformanceToggle = ({checked, setChecked, mouseOn, cssTop}: Props) => {
  return (
    <Tooltip title={`${checked ? 'Disable' : 'Activate'} Performance Mode`} placement="left">
      {checked ? (
        <ViewerButtonActive
          onClick={() => setChecked(!checked)}
          style={{
            right: BUTTON_MARGIN,
            top: cssTop,
            position: 'absolute',
          }}
          className={(!mouseOn && 'fade') || undefined}
        >
          <ActiveDiv>
            <OfflineBolt />
          </ActiveDiv>
        </ViewerButtonActive>
      ) : (
        <ViewerButton
          onClick={() => setChecked(!checked)}
          style={{
            right: BUTTON_MARGIN,
            top: cssTop,
            position: 'absolute',
          }}
          className={(!mouseOn && 'fade') || undefined}
        >
          <OfflineBolt />
        </ViewerButton>
      )}
    </Tooltip>
  );
};
