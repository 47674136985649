import React from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import SimilarityHeader from '../shared/SimilarityHeader';
import {useAsyncDispatch} from '../../../ReduxRoot';
import {RootState} from '../../../store/reducers';
import similarityReportActions from '../../../store/actions/similarityReportActions';
import Header from '../../../components/organisms/Header';
import ResultsProgress from './ResultsProgress';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const SimilarityResultsHeader = ({uuid}: {uuid: string}) => {
  const dispatch = useAsyncDispatch();
  const isSmallScreen = useSmallScreenSize();
  const reportName = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport?.name);
  const reportStatus = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport?.status);

  const onNameUpdated = async (newName: string) => {
    const success = await dispatch(similarityReportActions.updateSimilarityReport(uuid, {name: newName}));

    return success;
  };

  return (
    <Header
      helmet={`${reportName} Similarity Report`}
      title={
        <Box display="flex" alignItems="center" width="100%">
          <SimilarityHeader reportName={reportName!} onChangeAccepted={onNameUpdated} similarityStatus={reportStatus} />
        </Box>
      }
      breadcrumbs={[{title: 'Similarity Reports', path: '/reports/similarity/'}, reportName!]}
      endAdornment={isSmallScreen ? undefined : <ResultsProgress />}
      endAdornmentFlex={1}
    />
  );
};

export default SimilarityResultsHeader;
