import React, {useEffect, useState} from 'react';
import {Grid, InputAdornment, TextField} from '@material-ui/core';

import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import {RootState} from '../../store/reducers';
import {useMaterialStoreActions} from '../../store/actions';
import {BATCH_DESCRIPTION_LIMIT, BATCH_NAME_LIMIT, IBatch} from '@common/api/models/materials/batches/IBatch';
import {batchCreatePOST} from '../../api/ajax/batches';
import {MaterialSelectorButton} from '../../components/molecules/Selector/MaterialSelectorButton';
import {MaterialSupplyWidget} from '../../components/molecules/Dashboard/MaterialSupplyWidget';
import {GenericDialog} from '../../components/molecules/DialogButton';
import {useExtraSmallScreenSize} from '../../utils/utilHooks';

export function CreateBatchModal(props: {isModalOpen: boolean; closeModal: () => void; initialMaterialUuid?: string}) {
  const isXsScreen = useExtraSmallScreenSize();
  const materialStoreActions = useMaterialStoreActions();
  const [draft, setDraft] = useState<Partial<IBatch>>({
    description: '',
    name: '',
  });
  const [requesting, setRequesting] = useState(false);

  const [materialUuid, setMaterialUuid] = useState<string | undefined>(props.initialMaterialUuid);
  const [quantity, setQuantity] = useState<number>(0);

  const setField = (field: keyof IBatch, value: any) => {
    setDraft({...draft, [field]: value});
  };

  const handleCreateClick = async () => {
    if (!materialUuid || !quantity) {
      toast('Material must be chosen, with quantity > 0');
      return;
    }
    setRequesting(true);
    const res = await batchCreatePOST(draft, materialUuid, quantity);
    setRequesting(false);
    if (res.success) {
      props.closeModal();
    }
  };

  const materialStore = useSelector((state: RootState) => {
    return state.materialStore;
  });

  useEffect(() => {
    if (materialUuid) {
      materialStoreActions.ensureConsistent({uuid: materialUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialUuid]);

  const material = materialUuid ? materialStore.byId[materialUuid] : undefined;

  const canCreate = () => {
    if (!material || !materialUuid) return false;
    if (!draft.name || draft.name.length > BATCH_NAME_LIMIT) return false;
    if (!quantity || quantity < 0 || quantity > material.quantityRemaining) return false;
    if ((draft.description?.length || 0) > BATCH_DESCRIPTION_LIMIT) return false;
    return true;
  };

  return (
    <GenericDialog
      isOpen={props.isModalOpen}
      closeDialog={props.closeModal}
      title="Create Batch"
      fullWidth
      requestInProgress={requesting}
      content={
        <Grid container direction="column" alignItems="stretch" spacing={isXsScreen ? 3 : 4}>
          <Grid item>
            <TextField
              type="text"
              placeholder="Name*"
              label="Name"
              variant="outlined"
              value={draft.name}
              fullWidth
              size="small"
              onChange={(e) => setField('name', e.target.value)}
              inputProps={{
                'aria-required': 'true',
              }}
              error={!!draft.name && draft.name.length > BATCH_NAME_LIMIT}
              helperText={
                !!draft.name && draft.name.length > BATCH_NAME_LIMIT ? 'Name must be less than 60 chars' : undefined
              }
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              type="text"
              placeholder="Description"
              label="Description"
              fullWidth
              multiline
              value={draft.description}
              onChange={(e) => setField('description', e.target.value)}
              variant="outlined"
              size="small"
              error={!!draft.description && draft.description.length > BATCH_DESCRIPTION_LIMIT}
              helperText={
                !!draft.description && draft.description.length > BATCH_DESCRIPTION_LIMIT
                  ? `Description must be less than ${BATCH_DESCRIPTION_LIMIT} chars`
                  : undefined
              }
            />
          </Grid>
          <Grid item>
            <MaterialSelectorButton
              placeholder={'Choose a material...'}
              onSelect={(m) => setMaterialUuid(m?.uuid)}
              selectedMaterial={material}
              fullWidth={true}
            />
          </Grid>
          <Grid item>
            <TextField
              type="number"
              placeholder="Quantity"
              label="Quantity"
              fullWidth
              variant="outlined"
              defaultValue={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              inputProps={{
                'aria-required': 'true',
              }}
              InputProps={{
                endAdornment: <InputAdornment position="start">kg</InputAdornment>,
              }}
              size="small"
            />
          </Grid>
          <Grid item>
            {material && (
              <Grid item xs={12}>
                <MaterialSupplyWidget
                  elevation={0}
                  material={{
                    ...material,
                    quantityRemaining: material.quantityRemaining - quantity,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      }
      onSuccess={handleCreateClick}
      confirmText="Create"
      confirmDisabled={!canCreate()}
    />
  );
}
