import * as React from 'react';
import {Grid, Theme, Button, Dialog, DialogProps, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {MaterialsRow, MaterialsTable} from '../Table/MaterialsTable';
import {useEffect, useState} from 'react';
import {useMaterialStoreActions} from '../../../store/actions';
import {IMaterial} from '@common/api/models/materials/IMaterial';
import styled, {withTheme} from 'styled-components';

export interface MaterialSelectorModalProps extends Partial<DialogProps> {
  open: boolean;
  initiallySelectedUuid?: string;
  onMaterialSelected: (material: IMaterial) => any;
}

const CustomGrid = styled(Grid)`
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

function MaterialSelectorModal(props: MaterialSelectorModalProps & {theme: Theme}) {
  const materialStoreActions = useMaterialStoreActions();
  const {theme, onMaterialSelected, initiallySelectedUuid, ...rest} = props;

  const materials = useSelector((state: RootState) => {
    return Object.values(state.materialStore.byId);
  });
  useEffect(() => {
    materialStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedMaterialUuid, setSelectedMaterialUuid] = useState<string | undefined>(initiallySelectedUuid);

  const onSubmit = () => {
    if (selectedMaterialUuid) {
      onMaterialSelected(materials.find((m) => m.uuid === selectedMaterialUuid)!);
    }
    rest.onClose && rest.onClose({}, 'escapeKeyDown');
  };

  const handleRowClick = (rowData: MaterialsRow | undefined) => {
    setSelectedMaterialUuid(rowData?.uuid);
  };

  return (
    <Dialog {...rest} scroll={'paper'} maxWidth={'lg'}>
      <DialogTitle>Select a Material</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={6}>
          <Grid item sm={12} md={12} lg={12}>
            <h2>Select a Material</h2>
            <p>Click a row to choose a Material</p>
          </Grid>
          <CustomGrid item sm={12} md={12} lg={12}>
            <MaterialsTable
              materials={materials}
              onRowClick={(e, rowData) => handleRowClick(rowData)}
              options={{
                tableLayout: 'fixed',
                rowStyle: (materialData) =>
                  selectedMaterialUuid === materialData.uuid
                    ? {
                        backgroundColor: theme.palette.grey['200'],
                        color: theme.palette.common.black,
                      }
                    : {},
              }}
            />
          </CustomGrid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={() => setSelectedMaterialUuid(undefined)}>
              Clear Selection
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button color="primary" onClick={() => rest.onClose && rest.onClose({}, 'escapeKeyDown')}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withTheme(MaterialSelectorModal) as (props: MaterialSelectorModalProps) => any;
