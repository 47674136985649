import {FeatureFlagResponse} from '@common/api/models/support';

export enum SupportActions {
  SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
}

export interface SupportStoreState {
  featureFlags: FeatureFlagResponse;
}

export const initialSupportState: SupportStoreState = {
  featureFlags: {bulkDataDownloader: false},
};

interface SupportActionType<_T, P> {
  type: SupportActions;
  payload: P;
}

export type FeatureFlagAction = SupportActionType<SupportActions.SET_FEATURE_FLAGS, FeatureFlagResponse>;
