import React from 'react';
import VerticalButton from '../../../../components/atoms/VerticalButton';
import BottomToolbarContainer from '../../../../components/atoms/BottomToolbarContainer';
import {Settings} from '@material-ui/icons';
import {Typography} from '@material-ui/core';
import {useHistory, useParams} from 'react-router-dom';
import {ActiveBuildPage} from '../ActiveBuild';

const ActiveBuildBottomToolbar = ({pages}: {pages: ActiveBuildPage[]}) => {
  const history = useHistory();
  const {uuid, liveStep} = useParams<{uuid: string; liveStep: string}>();

  const activePage = pages.find((p) => p.id === liveStep);

  const handlePageChange = (newIndex: number) => {
    history.push('/builds/uuid/' + uuid + '/live/' + pages[newIndex].id + '/');
  };

  const handleSettingsnavigation = () => {
    history.push('/builds/uuid/' + uuid + '/settings/');
  };

  return (
    <BottomToolbarContainer columns="20% 20% 20% 20% 20%">
      {pages
        .filter((page) => !page.hidden)
        .map(({icon, title, smallTitle, id}, index) => {
          return (
            <VerticalButton
              key={id}
              onClick={() => handlePageChange(index)}
              color={id === activePage?.id ? 'primary' : 'secondary'}
            >
              {icon}
              <Typography variant="caption">{smallTitle || title}</Typography>
            </VerticalButton>
          );
        })}

      <VerticalButton
        onClick={handleSettingsnavigation}
        color={window.location.pathname.includes('/settings') ? 'primary' : 'secondary'}
      >
        <Settings />
        <Typography variant="caption">Settings</Typography>
      </VerticalButton>
    </BottomToolbarContainer>
  );
};

export default ActiveBuildBottomToolbar;
