import semver from 'semver';

import {ComparisonPoints, ExplicitPoint, PointCloudLoadResult} from '../types/pointCloudTypes';
import {Comparison, Point, PointCloud2} from '../types/pointCloudV2';
import {Coord, PointCloud3} from '../types/pointCloudV3';
import {BufferGeometry, Color, Float32BufferAttribute, InstancedMesh, Points, PointsMaterial, Vector3} from 'three';
import {
  AnalysisType3D,
  comparisonTypeToAnalysisType3D,
  CTDefectType3D,
} from '@common/api/models/builds/data/defects/IDefect';
import {BoundingBox} from '../Base3DViewport';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {PointCloudViewportParams} from '../types/params';
import {View3DViewportParams} from '../View3DViewport';

// Convert parsed point cloud into list of points
/**
 * Loads points from a point cloud and returns an array of ExplicitPoint objects.
 * @param pointCloud - The point cloud data.
 * @param centerPoints - Optional. Specifies whether to center the points around the centroid. Default is false.
 * @returns An array of ExplicitPoint objects representing the loaded points.
 */
export const loadPoints = (pointCloud: PointCloud2 | PointCloud3, centerPoints: boolean = false): ExplicitPoint[] => {
  const minSize = pointCloud.minSize && pointCloud.minSize > 0 ? pointCloud.minSize : 1;

  let points: ExplicitPoint[] = [];
  if (semver.satisfies(pointCloud.version, '>=3.0.0')) {
    const pointCloud3 = pointCloud as PointCloud3;
    const indexedColours = pointCloud3.colourMap
      ? pointCloud3.colourMap.map((colour) => new Color(...colour.map((value) => value / 255)))
      : [];

    points = pointCloud3.points.map((point, idx) => {
      const colour =
        indexedColours.length > 0
          ? indexedColours[pointCloud3.colourIndex ? pointCloud3.colourIndex[idx] : 0]
          : new Color(0xffffff);

      const ctDefects = Object.values(CTDefectType3D).reduce((acc, key) => {
        if (pointCloud3[key]) {
          acc[key] = pointCloud3[key]![idx];
        }
        return acc;
      }, {} as {[key in CTDefectType3D]?: number});

      return {
        position: new Vector3(point[0], point[2], -point[1]),
        colour,
        scale: pointCloud3.segments ? minSize * Math.pow(2, pointCloud3.segments[idx].sizeExp) : minSize,
        defectArea: pointCloud3.defectAreas ? pointCloud3.defectAreas[idx] : undefined,
        ...ctDefects,
        defectId: pointCloud3.defectIds ? pointCloud3.defectIds[idx] : undefined,
      };
    });
  } else {
    const pointCloud2 = pointCloud as PointCloud2;
    const indexedColours = pointCloud2.colours
      ? pointCloud2.colours.map((colour) => new Color(...colour.map((value) => value / 255)))
      : [];
    points = pointCloud2.points.map((point) => ({
      position: new Vector3(point.coord[0], point.coord[2], -point.coord[1]),
      colour: indexedColours.length > 0 ? indexedColours[point.colourIndex ?? 0] : new Color(0xffffff),
      scale: minSize * Math.pow(2, point.sizeExp ?? 0),
    }));
  }

  if (centerPoints) {
    const centroid = points
      .reduce((acc, point) => acc.add(point.position), new Vector3(0, 0, 0))
      .divideScalar(points.length);

    points.forEach((point) => {
      point.position.sub(centroid);
    });
  }

  return points;
};

/**
 * Loads comparison points from a point cloud.
 * @param pointCloud - The point cloud containing the comparison points.
 * @param _isScaled - A boolean indicating whether the points are scaled.
 * @returns An object containing the loaded comparison points.
 */
export const loadComparisons = (pointCloud: PointCloud2 | PointCloud3, _isScaled: boolean): ComparisonPoints => {
  const comparisons: ComparisonPoints = {};

  const minSize = pointCloud.minSize && pointCloud.minSize > 0 ? pointCloud.minSize : 1;

  let position: Vector3, scale: number, pointComparisons: Comparison[];

  pointCloud.points.forEach((point: Point | Coord, i: number) => {
    if ((pointCloud as PointCloud3).segments) {
      pointComparisons =
        //@ts-ignore
        pointCloud.segments[i].comparison ||
        //@ts-ignore
        pointCloud.segments[i].comparisons;
    } else {
      pointComparisons =
        //@ts-ignore
        point.comparison || point.comparisons;
    }
  });

  pointCloud.points.forEach((point: Point | Coord, idx: number) => {
    if (semver.satisfies(pointCloud.version, '>=3.0.0')) {
      position = new Vector3((point as Coord)[0], (point as Coord)[2], -(point as Coord)[1]);
      if ((pointCloud as PointCloud3).segments) {
        //@ts-ignore
        scale = minSize * Math.pow(2, pointCloud.segments[idx].sizeExp);

        pointComparisons =
          //@ts-ignore
          pointCloud.segments[idx].comparison ||
          //@ts-ignore
          pointCloud.segments[idx].comparisons;
      }
    } else {
      position = new Vector3((point as Point).coord[0], (point as Point).coord[2], -(point as Point).coord[1]);
      scale = minSize * Math.pow(2, (point as Point).sizeExp ?? 0);
      // For backwards compatibility JSON vs Protobuf (proto may have changed incorrectly in analysis)
      pointComparisons =
        //@ts-ignore
        point.comparison || point.comparisons;
    }
    pointComparisons.forEach((comparison) => {
      const type = comparisonTypeToAnalysisType3D(comparison.type || 0);
      const metric = comparison.comparisonMetric || 0;
      if (!comparisons[type]) comparisons[type] = [];

      comparisons[type]!.push({
        position,
        scale,
        colour: new Color(),
        similarityCoefficient: type === AnalysisType3D.ASIM ? (1 - metric) * 100 : metric * 100,
      });
    });
  });
  return comparisons;
};

/**
 * Loads the bounds of a point cloud.
 * @param pointCloud - The point cloud to load the bounds from.
 * @returns The bounding box of the point cloud.
 */
export const loadPointCloudBounds = (pointCloud: PointCloud2 | PointCloud3): BoundingBox => {
  let min = new Vector3(pointCloud.bounds[0], pointCloud.bounds[2], -pointCloud.bounds[1]);
  let dimensions = new Vector3(pointCloud.bounds[3], pointCloud.bounds[5], -pointCloud.bounds[4]);
  if (!!pointCloud.layerBounds) {
    let layerBounds = {
      min: pointCloud.layerBounds[0],
      max: pointCloud.layerBounds[1],
    };
    return {min, dimensions, layerBounds};
  }

  return {min, dimensions};
};

/**
 * Calculates the point cloud bounds from a given part.
 * @param part - The part object containing the bounds information.
 * @returns The bounding box of the point cloud, or null if the part or bounds are invalid.
 */
export const pointCloudBoundsFromPart = (part: IPartGETResponse): BoundingBox | null => {
  if (!part || !part.bounds || part.bounds.length !== 6) {
    return null;
  }
  let min = new Vector3(part.bounds[0], part.bounds[2], -part.bounds[1]);
  let dimensions = new Vector3(part.bounds[3], part.bounds[5], -part.bounds[4]);

  if (!!part.layerStart && !!part.layerEnd) {
    let layerBounds = {
      min: part.layerStart!,
      max: part.layerEnd!,
    };
    return {min, dimensions, layerBounds};
  }

  return {min, dimensions};
};

/**
 * Loads a points object based on the given parameters.
 *
 * @param points - An array of explicit points.
 * @param analysisType - The type of analysis for the 3D viewport.
 * @param params - The parameters for the point cloud viewport.
 * @returns The loaded points object.
 */
const loadPointsObject = (points: ExplicitPoint[], analysisType: AnalysisType3D, params: PointCloudViewportParams) => {
  const positions = points.flatMap((point: ExplicitPoint) => point.position.toArray());
  const colours = points.flatMap((point: ExplicitPoint) => point.colour.toArray());

  const geometry = new BufferGeometry();
  geometry.setDrawRange(0, params.pointLimit);
  geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
  geometry.setAttribute('color', new Float32BufferAttribute(colours, 3));

  const isModel = analysisType === AnalysisType3D.Model;
  const material = new PointsMaterial({
    transparent: isModel,
    clippingPlanes: [params.clippingPlane],
    size: params.pointSize,
    opacity: isModel ? params.modelOpacity : 1,
    ...(isModel && params.overridePointColour
      ? {
          vertexColors: false,
          color: new Color(params.overrideColour),
        }
      : {
          vertexColors: true,
          color: new Color(0xffffff),
        }),
  });

  const object = new Points(geometry, material);

  // We need to render the model after the defects, so that the defects are
  // visible through the model when transparent.
  object.renderOrder = analysisType === AnalysisType3D.Model ? 2 : 1;
  return object;
};

/**
 * Loads a point cloud as points
 *
 * @param pointCloud - The point cloud to load.
 * @param analysisType - The analysis type for the point cloud.
 * @param params - The viewport parameters.
 * @returns The result of loading the point cloud as points.
 */
export const loadPointCloudAsPoints = (
  pointCloud: PointCloud2 | PointCloud3,
  analysisType: AnalysisType3D,
  params: View3DViewportParams
): PointCloudLoadResult => {
  const points = loadPoints(pointCloud, params.centerAllParts);

  const object = loadPointsObject(points, analysisType, params);
  object.name = `${analysisType}Partition${pointCloud.partitionNum}`;
  object.userData.pointData = points;

  // Bounds are usually loaded from the part in the DB. We can also load them from the point cloud itself.
  // For parts, the result should be the same.
  // This is needed for similarity point clouds, as they don't store bounds in the DB and they differ from the part bounds.
  const bounds = loadPointCloudBounds(pointCloud);

  return {success: true, object, bounds};
};

/**
 * Converts a mesh to a point cloud.
 *
 * @param mesh - The mesh to convert.
 * @param analysisType - Analysis Type of the Pointcloud.
 * @param params - The parameters for the point cloud viewport.
 * @returns The converted point cloud.
 */
export const convertMeshToPoints = (
  mesh: InstancedMesh,
  analysisType: AnalysisType3D,
  params: PointCloudViewportParams
) => {
  const points = mesh.userData.pointData;
  const pointCloud = loadPointsObject(points, analysisType, params);
  pointCloud.visible = mesh.visible;
  pointCloud.name = mesh.name;
  pointCloud.userData = mesh.userData;
  // @ts-ignore
  pointCloud.geometry.setAttribute('color', mesh.instanceColor);
  return pointCloud;
};
