import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Button as MuiButton} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import GenericAlertPage from './GenericAlertPage';

function NoRole() {
  return (
    <GenericAlertPage
      title="No Role"
      alertText="Your account has yet to be assigned a user role. Please
                  contact your administrator."
      ExtraComponent={BackToLoginButton}
    />
  );
}

export default NoRole;

const Button = styled(MuiButton)(spacing);

const BackToLoginButton = () => {
  return (
    <Button
      // @ts-ignore
      component={Link}
      // @ts-ignore
      to="/login"
      variant="contained"
      color="secondary"
      mt={2}
      style={{
        marginTop: '10px',
      }}
    >
      Proceed to login
    </Button>
  );
};
