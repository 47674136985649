/**
 * Render Base Chart Component.
 *
 * This script is responsible for setting up a Chart so we don't do it everywhere else.
 */
import Plotly from '../../../../vendor/custom-plotly';
import createPlotlyComponent from 'react-plotly.js/factory';

const Chart = createPlotlyComponent(Plotly);

export default Chart;
