import React, {useState, useEffect} from 'react';
import {Grid, FormControlLabel, Checkbox, Box} from '@material-ui/core';
import inflection from 'inflection';
import ComparisonsSelector from './ComparisonSelector';
import {FilterComponentProps} from '../FilterModal';
import {flatten, omit} from 'lodash';
import styled from 'styled-components';
import {newDebouncer} from '../../../../api/ajax';
import {parseCurrentFilters} from './utils';

const enumMultipleComparisons = {
  in: 'Any of',
  notIn: 'None of',
};

type EnumMultipleComparison = keyof typeof enumMultipleComparisons;

const debounceSubmit = newDebouncer();

const BooleanListFilter = ({
  field,
  booleanValues,
  control,
  onSubmit,
  currentFilters,
  enumLabels,
  permanentComparison,
  excludeComparisons = [],
  filterListLocation,
  colorFn,
}: FilterComponentProps) => {
  const initialFilter = parseCurrentFilters(currentFilters, field);
  const [internalValue, setInternalValue] = useState<Array<string>>(
    initialFilter[1] ? (flatten([initialFilter[1]]) as Array<string>) : []
  );
  const [comparison, setComparison] = useState<EnumMultipleComparison>(
    ((permanentComparison ?? initialFilter[0]) as EnumMultipleComparison) || 'in'
  );

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSubmit(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  useEffect(() => {
    control.register(field);
  }, [control, field]);

  useEffect(() => {
    const filterValue = parseCurrentFilters(currentFilters, field);
    setInternalValue(filterValue[1] ? filterValue[1] : initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const toggleValue = (newValue: string) => {
    let newList = [];
    if (internalValue.includes(newValue)) {
      newList = internalValue.filter((value) => value !== newValue);
    } else {
      newList = [...internalValue, newValue];
    }

    setInternalValue(newList);

    if (newValue.length) {
      control.setValue(field, {[comparison]: newList});
    } else {
      control.setValue(field, undefined);
    }
    submitField();
  };

  const onComparisonChanged = (newComparison: EnumMultipleComparison) => {
    setComparison(newComparison);
    control.setValue(field, {[newComparison]: internalValue});
  };

  return (
    <Grid container spacing={4}>
      <StyledGrid
        item
        xs={!permanentComparison ? 6 : 12}
        sm={!permanentComparison ? 8 : 12}
        hasPopupIcon={internalValue.length === 0}
      >
        <Box display="flex" flexWrap="wrap" alignItems="center">
          {booleanValues?.map((value) => {
            const label = enumLabels?.[value] || inflection.humanize(`${value}`);
            const color = colorFn ? colorFn(value) : undefined;

            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={internalValue.includes(`${value}`)}
                    onChange={() => toggleValue(`${value}`)}
                    name={`${value}`}
                    style={{color: color}}
                  />
                }
                label={label}
              />
            );
          })}
        </Box>
      </StyledGrid>
      {!permanentComparison && (
        <Grid item xs={6} sm={4}>
          <ComparisonsSelector
            comparisons={
              omit(enumMultipleComparisons, excludeComparisons) as {
                [key: string]: string;
              }
            }
            currentComparison={comparison}
            setCurrentComparison={onComparisonChanged}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default BooleanListFilter;

const StyledGrid = styled(Grid)<{hasPopupIcon: boolean}>`
  .MuiAutocomplete-hasPopupIcon {
    .MuiInputBase-root {
      ${({hasPopupIcon}) => !hasPopupIcon && 'padding-right: 12px;'}
    }
  }
`;
