import {assertUnreachable} from '@common/utils/utils';

export const BATCH_NAME_LIMIT = 60;
export const BATCH_DESCRIPTION_LIMIT = 1024;

export interface MaterialComposition {
  materialUuid: string;
  weight: number;
}

export interface AgeComposition {
  usedCount: number;
  weight: number;
}

export type BatchSortOptions = 'name' | 'registered' | 'quantity';
export enum BatchAvailability {
  'Available',
  'In use',
  'Expired',
}

export enum BatchEventType {
  INITIAL = 'initial',
  ADD = 'add',
  MEASUREMENT = 'measurement',
  MERGE = 'merge',
  SPLIT = 'split',
  BUILD = 'build',
  SIEVE = 'sieve',
  RETIRE = 'retire',
  // TODO what are these / they look depreciated:
  TEST = 'test',
  CUSTOM = 'custom',
}

export function batchEventTypeTitle(type: BatchEventType) {
  switch (type) {
    case BatchEventType.INITIAL:
      return 'New Batch';
    case BatchEventType.ADD:
      return 'Added Material';
    case BatchEventType.MEASUREMENT:
      return 'Measurement';
    case BatchEventType.MERGE:
      return 'Merge';
    case BatchEventType.SPLIT:
      return 'Split';
    case BatchEventType.BUILD:
      return 'Build';
    case BatchEventType.SIEVE:
      return 'Sieve';
    case BatchEventType.RETIRE:
      return 'Retired';
    case BatchEventType.TEST:
      return 'Test';
    case BatchEventType.CUSTOM:
      return 'Custom Event';
    default:
      assertUnreachable(type);
  }
}

export interface EventDataBase {
  type: BatchEventType;
}

// These events only contain auxillary information not obtainable from the flat fields in IBatch.

export interface AddEventData extends EventDataBase {
  type: BatchEventType.ADD;
  materialUuid: string;
  quantity: number;
}

export interface InitialEventData extends EventDataBase {
  type: BatchEventType.INITIAL;
  materialUuid: string;
  quantity: number;
}

export interface MeasurementEventData extends EventDataBase {
  type: BatchEventType.MEASUREMENT;
  quantity: number;
}

export interface MergeEventData extends EventDataBase {
  type: BatchEventType.MERGE;
}

export interface SplitEventData extends EventDataBase {
  type: BatchEventType.SPLIT;
}

export interface BuildEventData extends EventDataBase {
  type: BatchEventType.BUILD;
  buildUuid: string;
  quantityConsumed: number;
}

export interface SieveEventData extends EventDataBase {
  type: BatchEventType.SIEVE;
  sieveName?: string;
  sieveType: string;
  sieveUnits: string;
  sieveEquipmentSN: string;
  lostQuantity: number;
}

export interface RetireEventData extends EventDataBase {
  type: BatchEventType.RETIRE;
}

export interface TestEventData extends EventDataBase {
  type: BatchEventType.TEST;
  testType: string;
  testDescription: string;
  results: string;
  quantityConsumed: number;
  attachmentUuids: string[];
}

export interface CustomEventData extends EventDataBase {
  type: BatchEventType.CUSTOM;
  description: string;
  quantityConsumed: number;
  attachmentUuids: string[];
}

export type BatchEventData =
  | InitialEventData
  | AddEventData
  | MeasurementEventData
  | MergeEventData
  | SplitEventData
  | BuildEventData
  | SieveEventData
  | RetireEventData
  | TestEventData
  | CustomEventData;

export interface IBatch {
  uuid: string;
  organizationUuid: string;
  registered: Date;

  // Cached values. These values need to be maintained every time updates from batches happen.
  materialComposition: MaterialComposition[];
  ageComposition: AgeComposition[];
  quantity: number;
  description?: string;
  name: string;
  retired: boolean;
  availability?: BatchAvailability;

  // History operations
  current: boolean;
  parentAUuid?: string;
  parentBUuid?: string;
  creationEventType: BatchEventType;
  creationEventData: BatchEventData;

  successorAUuid?: string;
  successorBUuid?: string;
  successionEventType?: BatchEventType;
}

export interface IBatchGETRequest {
  uuid: string | undefined;
  current: boolean | undefined;
  retired: boolean | undefined;
  name: string | undefined;
  quantity: number | undefined;
}

export interface IBatchGETResponse extends IBatch {
  availability?: BatchAvailability;
}

export interface IBatchPOSTRequest {
  materialUuid: string;
  name: string;
  quantity: number;
  description: string | undefined;
}

export interface IBatchPATCHRequest {
  name: string | undefined;
  description: string | undefined;
}

export interface IBatchMergePOSTRequest extends IBatchPATCHRequest {}

export interface IBatchSplitPOSTRequest {
  quantityA: number;
  batchA: {
    name: string;
    description: string;
  };
  batchB: {
    name: string;
    description: string;
  };
}
