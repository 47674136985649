import {Button, Grid, TextField} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {Add, Delete} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/reducers';
import IndexPage from '../shared/IndexPage/IndexPage';
import {Alert} from '@material-ui/lab';
import SearchAndSelectBuild from '../../components/molecules/Selector/SearchAndSelectBuild';
import SearchAndSelect from '../../components/molecules/Selector/SearchAndSelect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {partsAllGET} from '../../api/ajax/parts';
import {IBuild} from '@common/api/models/builds/IBuild';
import {ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {GenericDialog} from '../../components/molecules/DialogButton';
import {ctReportPOST} from '../../api/ajax/ctReport';
import {useAsyncDispatch} from '../../ReduxRoot';
import ctReportActions from '../../store/actions/ctReportActions';

function CTReportsListPage() {
  const [reportDeleting, setReportDeleting] = useState<ICTReportGETResponse | undefined>();

  const ctReports = useSelector((state: RootState) => state.ctReportStore.list);

  const useCTReportData = useCallback(() => {
    return ctReports.map((report) => ({
      ...report,
      onClickUrl: `/reports/ct/${report.uuid}`,
      onDelete: () => setReportDeleting(report),
    }));
  }, [ctReports]);

  return (
    <>
      <IndexPage
        resourceType="ctReport"
        CreateResourceComponent={NewCTReportButton}
        useData={useCTReportData}
        title="CT Reports"
      />
      <DeleteModal reportDeleting={reportDeleting} setReportDeleting={setReportDeleting} />
    </>
  );
}

export default CTReportsListPage;

function NewCTReportButton() {
  const [isModalOpen, setModalOpen] = useState(false);

  const onNewReport = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={onNewReport}>
        <Add />
        New Report
      </Button>
      <NewCTReportModal isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
}

function NewCTReportModal({isOpen, setOpen}: {isOpen: boolean; setOpen: (isOpen: boolean) => void}) {
  const [requesting, setRequesting] = useState(false);
  const [name, setName] = useState<string>('');
  const [build, setBuild] = useState<IBuild | null>(null);
  const [part, setPart] = useState<IPartGETResponse>();
  const [voxelSize, setVoxelSize] = useState<number>();
  const [voxelSizeError, setVoxelSizeError] = useState(false);
  const history = useHistory();

  function closeModal() {
    setName('');
    setBuild(null);
    setPart(undefined);
    setVoxelSize(undefined);
    setOpen(false);
  }

  const fetchParts = async (search: string) => {
    const res = await partsAllGET({
      hasCtReport: 'false',
      uuid: {notIn: part ? [part.uuid] : []},
      buildUuid: {in: build ? [build?.uuid] : []},
      ...(!!search ? {name: {like: search}} : {}),
      take: 50,
    });

    if (res.success) return res.data;
    else return [];
  };

  const onSubmit = async () => {
    setRequesting(true);
    if (!part) return;
    if (!voxelSize) {
      setVoxelSizeError(true);
      return;
    }

    const res = await ctReportPOST({
      name: name,
      partUuid: part.uuid,
      voxelSize: voxelSize as number,
    });

    if (res.success) {
      closeModal();
      history.replace(`/reports/ct/${res.data.uuid}`);
    }
    setRequesting(false);
  };

  return (
    <GenericDialog
      title="New CT Report"
      confirmText="Create Report"
      isOpen={isOpen}
      closeDialog={closeModal}
      onSuccess={onSubmit}
      confirmDisabled={requesting || !part || !name || name.length < 3}
      requestInProgress={requesting}
      content={
        <Grid container spacing={2} style={{overflow: 'hidden'}}>
          <Grid item xs={12}>
            <Alert severity="info">
              CT Analysis can only be performed on Parts in Builds with <b>Valid Slice Files</b> for the respective
              Part.
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              size="small"
              value={name}
              onChange={(event) => {
                if (event.target) {
                  setName(event.target.value);
                }
              }}
              error={voxelSizeError}
              helperText={voxelSizeError && 'Please Enter Voxel Size of CT Data'}
            />
          </Grid>

          <Grid item xs={12}>
            <SearchAndSelectBuild
              selectedBuild={build}
              onBuildSelected={(build) => setBuild(build)}
              filters={{hasSliceData: true}}
            />
          </Grid>

          <Grid item xs={12}>
            <SearchAndSelect<IPartGETResponse>
              disabled={!build}
              selected={part}
              setSelected={setPart}
              fetchFunction={fetchParts}
              getSuggestionValue={(part) => part.name}
              isSelected={(option) => option.uuid === part?.uuid}
              label="Part"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              label="Voxel Size of CT Data (mm)"
              variant="outlined"
              size="small"
              value={voxelSize}
              onChange={(event) => {
                if (event.target) {
                  setVoxelSize(parseFloat(event.target.value));
                  setVoxelSizeError(false);
                }
              }}
              error={voxelSizeError}
              helperText={voxelSizeError && 'Please Enter Voxel Size of CT Data in mm'}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

const DeleteModal = ({
  reportDeleting,
  setReportDeleting,
}: {
  reportDeleting: ICTReportGETResponse | undefined;
  setReportDeleting: React.Dispatch<React.SetStateAction<ICTReportGETResponse | undefined>>;
}) => {
  const dispatch = useAsyncDispatch();
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if (!reportDeleting) return;

    setDeleting(true);
    const success = await dispatch(ctReportActions.deleteCtReport(reportDeleting));
    setDeleting(false);

    if (success) {
      setReportDeleting(undefined);
    }
  };

  return (
    <GenericDialog
      danger
      title="Delete Report?"
      confirmText="Delete"
      content={
        <>
          Are you sure you want to delete the CT report for{' '}
          <b>
            <i>{reportDeleting?.partName}</i>
          </b>
          ?
        </>
      }
      isOpen={!!reportDeleting}
      closeDialog={() => setReportDeleting(undefined)}
      onSuccess={onDelete}
      requestInProgress={deleting}
      confirmButtonProps={{endIcon: <Delete />}}
    />
  );
};
