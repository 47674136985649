import {KonvaEventObject} from 'konva/types/Node';
import {Stage} from 'konva/types/Stage';
import {Vector2d} from 'konva/types/types';
import {max, minBy, throttle} from 'lodash';
import React, {useMemo} from 'react';
import {Layer, Rect} from 'react-konva';

import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {calculateImageOffset, transformPartBoundsToImagePosition} from './utils';

interface BoundingBoxProps {
  parts: IPartGETResponse[];
  imageWidthMM: number;
  imageHeightMM: number;
  plateBoundingBox: [number, number, number, number];
  calibrationResolution: [number, number];
  scale: number;
  zoomScale: number;
  currentLayer?: number;
  onPartHover?: (part: IPartGETResponse | null) => void;
  isSingleHoveredPart?: boolean;
}

export function BoundingBox({
  imageHeightMM,
  imageWidthMM,
  plateBoundingBox,
  calibrationResolution,
  scale,
  parts,
  currentLayer,
  onPartHover,
  isSingleHoveredPart = false,
  zoomScale,
}: BoundingBoxProps) {
  const [hoveredPartUuid, setHoveredPartUuid] = React.useState<string | null>(null);

  const {imageOrigin, imageScale} = calculateImageOffset(
    plateBoundingBox,
    scale,
    calibrationResolution,
    imageWidthMM,
    imageHeightMM
  );

  const transformedParts = useMemo(
    () => transformPartBoundsToImagePosition(parts, imageOrigin, imageScale),
    [parts, imageOrigin, imageScale]
  );

  const handlePartHover = (part?: IPartGETResponse | null) => {
    setHoveredPartUuid(part?.uuid ?? null);
    onPartHover?.(part ?? null);
  };

  const findHoveredPart = (pointerPosition: Vector2d) => {
    // The cursor may be hovering over multiple overlapped parts.
    // Find the hovered part by finding the part with its center closest to
    // the cursor out of those whose bounds contain the cursor.
    const hoveredParts = transformedParts.filter(({boundsMM}) => {
      return (
        pointerPosition.x > boundsMM.x &&
        pointerPosition.x < boundsMM.x + boundsMM.width &&
        pointerPosition.y > boundsMM.y &&
        pointerPosition.y < boundsMM.y + boundsMM.height
      );
    });
    const partsWithDistances = hoveredParts.map((part) => ({
      ...part,
      pointerDistance: Math.sqrt((part.center.x - pointerPosition.x) ** 2 + (part.center.y - pointerPosition.y) ** 2),
    }));
    const closestPart = minBy(partsWithDistances, 'pointerDistance');

    return closestPart;
  };

  const getMMPointerPosition = (stage: Stage) => {
    // Pointer position is initially given to us relative to the top left corner of the stage.
    // Transform this into image mm-space.
    // Ideally we'd use Konva's getRelativePointerPosition, but this doesn't seem to be exposed.
    const mousePos = stage.getPointerPosition();
    if (!mousePos) {
      return null;
    }
    const transform = stage.getAbsoluteTransform().copy().invert();
    return transform.point(mousePos);
  };

  const handleHover = (event: KonvaEventObject<TouchEvent> | KonvaEventObject<MouseEvent>) => {
    const stage = event.target.getStage();
    const pointerPosition = !!stage ? getMMPointerPosition(stage) : null;
    pointerPosition && handlePartHover(findHoveredPart(pointerPosition));
  };

  const handleMouseMove = useMemo(
    () => throttle(handleHover, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transformedParts]
  );

  return (
    <Layer onMouseMove={handleMouseMove} onMouseLeave={() => handlePartHover(null)} onTouchMove={handleHover}>
      {transformedParts.map(({boundsMM, uuid, areas = {}}) => {
        const layerNums = Object.keys(areas).map((layerNum) => parseInt(layerNum));
        const maxLayer = max(layerNums) || 0;

        if (layerNums.length && currentLayer && currentLayer > maxLayer) {
          return <></>;
        }

        return (
          <Rect
            key={uuid}
            x={boundsMM.x}
            y={boundsMM.y}
            width={boundsMM.width}
            height={boundsMM.height}
            stroke={isSingleHoveredPart || uuid === hoveredPartUuid ? 'orange' : 'red'}
            strokeWidth={((isSingleHoveredPart ? 2.4 : 1.2) * imageScale) / zoomScale}
          />
        );
      })}
    </Layer>
  );
}
