import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';
import {ISimilarityReport, ISimilarityReportGETResponse} from '@common/api/models/builds/data/ISimilarity';
import similarityReportActions from '../../store/actions/similarityReportActions';
import IndexPage from '../shared/IndexPage/IndexPage';
import {RootState} from '../../store/reducers';
import {GenericDialog} from '../../components/molecules/DialogButton';
import {useAsyncDispatch} from '../../ReduxRoot';

const CreateSimilarityReportButton = () => {
  const history = useHistory();

  const handleAddReport = () => {
    history.push('/reports/similarity/create/sourcePartSelection');
  };

  return (
    <Button variant="contained" color="primary" onClick={handleAddReport}>
      <Add />
      New Report
    </Button>
  );
};

const SimilarityListPage = () => {
  const [reportDeleting, setReportDeleting] = useState<ISimilarityReport | undefined>();

  const useData = () => {
    const reportList = useSelector((state: RootState) => {
      return state.similarityReportStore.list as ISimilarityReportGETResponse[];
    });

    return reportList.map((report) => {
      return {
        ...report,
        formattedSources: report.sources.map((part) => (part ? `${part.name} / ${part.buildName}` : undefined)),
        onDelete: () => setReportDeleting(report),
        onClickUrl: `/reports/similarity/uuid/${report.uuid}`,
      };
    });
  };

  return (
    <>
      <IndexPage
        resourceType="similarityReport"
        CreateResourceComponent={CreateSimilarityReportButton}
        useData={useData}
      />
      <DeleteModal reportDeleting={reportDeleting} setReportDeleting={setReportDeleting} />
    </>
  );
};

export default SimilarityListPage;

const DeleteModal = ({
  reportDeleting,
  setReportDeleting,
}: {
  reportDeleting: ISimilarityReport | undefined;
  setReportDeleting: React.Dispatch<React.SetStateAction<ISimilarityReport | undefined>>;
}) => {
  const dispatch = useAsyncDispatch();
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if (!reportDeleting) return;

    setDeleting(true);
    const success = await dispatch(similarityReportActions.deleteSimilarityReport(reportDeleting));
    setDeleting(false);

    if (success) {
      setReportDeleting(undefined);
    }
  };

  return (
    <GenericDialog
      title="Delete Report?"
      content={
        <>
          Are you sure you want to delete the similarity report{' '}
          <b>
            <i>{reportDeleting?.name}</i>
          </b>
          ?
        </>
      }
      isOpen={!!reportDeleting}
      closeDialog={() => setReportDeleting(undefined)}
      onSuccess={onDelete}
      requestInProgress={deleting}
      confirmButtonProps={{endIcon: <Delete />}}
    />
  );
};
