import {Button, ButtonGroup, IconButton, Menu, MenuItem, Snackbar} from '@material-ui/core';
import * as React from 'react';
import {useCallback, useEffect, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/reducers';
import {useBatchStoreActions, useMaterialStoreActions} from '../../store/actions';
import {Add, CallSplit, MergeType, MoreVert} from '@material-ui/icons';
import BatchMergeModal from '../../components/molecules/Forms/BatchMergeModal';
import BatchSplitModal from '../../components/molecules/Forms/BatchSplitModal';
import {useHistory} from 'react-router-dom';
import {CreateBatchModal} from './CreateBatchModal';
import IndexPage from '../shared/IndexPage/IndexPage';
import {BatchesRow} from '../shared/IndexPage/tableSchemas/batch';
import {BatchAvailability} from '@common/api/models/materials/batches/IBatch';
import {useSmallScreenSize} from '../../utils/utilHooks';

const BatchActionsButtonGroup = (props: {selectedUUIDs: string[]; forceRefresh: () => void}) => {
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [splitModalOpen, setSplitModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const history = useHistory();
  const smallScreen = useSmallScreenSize();

  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddBatch = () => {
    handleMenuClose();
    setAddModalOpen(true);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleMergeBatches = () => {
    handleMenuClose();
    setMergeModalOpen(true);
  };

  const handleSplitBatch = () => {
    handleMenuClose();
    setSplitModalOpen(true);
  };

  return (
    <>
      {smallScreen ? (
        <>
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu open={Boolean(anchorEl)} onClose={handleMenuClose} keepMounted anchorEl={anchorEl}>
            <MenuItem onClick={handleMergeBatches}>
              <MergeType />
              Merge Batches
            </MenuItem>
            <MenuItem onClick={handleSplitBatch}>
              <CallSplit />
              Split Batch
            </MenuItem>
            <MenuItem onClick={handleAddBatch}>
              <Add />
              Add Batch
            </MenuItem>
          </Menu>
        </>
      ) : (
        <ButtonGroup>
          <Button variant="outlined" color="primary" onClick={handleMergeBatches}>
            <MergeType />
            Merge Batches
          </Button>
          <Button variant="outlined" color="primary" onClick={handleSplitBatch}>
            <CallSplit />
            Split Batch
          </Button>
          <Button variant="contained" color="primary" onClick={handleAddBatch}>
            <Add />
            Add Batch
          </Button>
        </ButtonGroup>
      )}

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={snackOpen}
        onClose={handleSnackClose}
        message="Error loading machines. Please refresh"
      />
      {mergeModalOpen && (
        <BatchMergeModal
          open={mergeModalOpen}
          initialBatchAUuid={props.selectedUUIDs[0]}
          initialBatchBUuid={props.selectedUUIDs[1]}
          onClose={() => setMergeModalOpen(false)}
          redirect={(uuid?: string) => history.push('/batches/uuid/' + uuid)}
        />
      )}

      {splitModalOpen && (
        <BatchSplitModal
          open={splitModalOpen}
          initialBatchUuid={props.selectedUUIDs[0]}
          onClose={() => setSplitModalOpen(false)}
          onSuccess={() => {
            setSplitModalOpen(false);
            props.forceRefresh();
          }}
        />
      )}
      {addModalOpen && <CreateBatchModal isModalOpen={addModalOpen} closeModal={() => setAddModalOpen(false)} />}
    </>
  );
};

export function BatchesPage() {
  const [selectedUUIDs, setSelectedUUID] = useState<string[]>([]);
  const [forcedRefresh, forceRefresh] = useReducer((x) => x + 1, 0);
  const materialStoreActions = useMaterialStoreActions();
  const batchStoreActions = useBatchStoreActions();
  const batchList = useSelector((state: RootState) => state.batchStore.list);
  const materialsByUuid = useSelector((state: RootState) => state.materialStore.byId);

  useEffect(() => {
    materialStoreActions.ensureConsistent({});
    batchStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useBatchData = useCallback(() => {
    return batchList.map((batch) => {
      let materials = batch.materialComposition
        .sort((a, b) => b.weight - a.weight)
        .map(({materialUuid}) => materialsByUuid[materialUuid]?.name)
        .join(', ');
      const availability = batch.availability as BatchAvailability;

      return {
        materials,
        availability,
        onClickUrl: `/batches/uuid/${batch.uuid}/`,
        tableData: {
          checked: selectedUUIDs.includes(batch.uuid),
        },

        ...batch,
      };
    });
  }, [selectedUUIDs, batchList, materialsByUuid]);

  const BatchActionsComponent = useCallback(
    () => <BatchActionsButtonGroup selectedUUIDs={selectedUUIDs} forceRefresh={forceRefresh} />,
    [selectedUUIDs]
  );

  return (
    <IndexPage
      useData={useBatchData}
      CreateResourceComponent={BatchActionsComponent}
      resourceType="batch"
      title="Batches"
      onSelectionChange={(rows: BatchesRow[]) => {
        setSelectedUUID(rows.map((row) => row.uuid));
      }}
      forcedRefresh={forcedRefresh}
    />
  );
}
