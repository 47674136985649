import React, {useReducer, useState} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {Close, Add} from '@material-ui/icons';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {IBuild} from '@common/api/models/builds/IBuild';
import {
  IDefectNotificationConditions,
  DEFAULT_NOTIFICATION_CONDITION,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {defectNotificationPOST} from '../../../../api/ajax/defectNotification';
import NotificationForm from './NotificationFilter/NotificationForm';

function AddNotificationFilter({build}: {build: IBuild}) {
  const [isOpen, setIsOpen] = useState(false);
  const [forcedUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const {
    errors,
    control,
    reset,
    handleSubmit: onSubmit,
  } = useForm<IDefectNotificationConditions>({
    mode: 'all',
    defaultValues: {...DEFAULT_NOTIFICATION_CONDITION},
    shouldUnregister: false,
  });

  function onClose() {
    resetForm();
    setIsOpen(false);
  }

  function onOpen() {
    // State can persists after creation - ensure it's cleared
    resetForm();
    setIsOpen(true);
  }

  async function handleSubmit(data: IDefectNotificationConditions) {
    setRequestInProgress(true);
    const res = await defectNotificationPOST({
      buildUuid: build.uuid,
      conditions: data,
    });
    setRequestInProgress(false);

    if (!res.success) return;

    toast('Defect notification condition added!', {type: 'info'});
    onClose();
  }

  function resetForm() {
    reset({...DEFAULT_NOTIFICATION_CONDITION});
    // reset() resets the form, but it's internal state is persisted - triggering a re-render is a workaround.
    forceUpdate();
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={onOpen} startIcon={<Add />}>
        Add Condition
      </Button>

      <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onClose}>
        <form id="createNotificationFilterForm" onSubmit={onSubmit(handleSubmit)} key={forcedUpdate}>
          <DialogTitle disableTypography style={{padding: '24px'}}>
            <NotificationModalHeader onClose={onClose} />
          </DialogTitle>

          <StyledDialogContent dividers>
            <NotificationForm control={control} errors={errors} reset={reset} />
          </StyledDialogContent>
          <DialogActions style={{padding: '16px 24px'}}>
            <NotificationFilterActions
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              onClose={onClose}
              requestInProgress={requestInProgress}
              resetForm={resetForm}
            />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default AddNotificationFilter;

const NotificationModalHeader = ({onClose}: {onClose: () => void}) => {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Add Condition</Typography>
        <IconButton aria-label="close" onClick={onClose} style={{padding: '0px'}}>
          <Close />
        </IconButton>
      </Grid>
    </>
  );
};

const NotificationFilterActions = ({
  onSubmit,
  onClose,
  handleSubmit,
  requestInProgress,
  resetForm,
}: {
  onSubmit: any;
  handleSubmit: (data: any) => void;
  onClose: () => void;
  requestInProgress: boolean;
  resetForm: () => void;
}) => {
  return (
    <>
      <Button variant="outlined" color="primary" onClick={onClose} disabled={requestInProgress}>
        Cancel
      </Button>
      <Button variant="outlined" color="primary" onClick={resetForm} disabled={requestInProgress}>
        Clear
      </Button>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSubmit(handleSubmit)}
        endIcon={requestInProgress ? <CircularProgress size={20} /> : <Add />}
        disabled={requestInProgress}
      >
        Add
      </Button>
    </>
  );
};

const StyledDialogContent = styled(DialogContent)`
  padding: 24px 0px;
  margin: 0px 24px;
  overflow: hidden;
`;
