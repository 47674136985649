import React, {useEffect, useReducer, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import {Button as MuiButton, Grid, Menu, MenuItem, Typography as MuiTypography, Box} from '@material-ui/core';
import {flatten, uniq} from 'lodash';
import {IBuild} from '@common/api/models/builds/IBuild';
import {useBuildStoreActions, useMaterialStoreActions} from '../../../../store/actions';
import {RootState} from '../../../../store/reducers';
import {StateInput} from '../../../index';
import {useDraftBuild} from '../DraftBuildContext';
import GenericTable from '../../../../components/molecules/Table/GenericTable';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import {CreateBatchModal} from '../../../materials/CreateBatchModal';
import BatchMergeModal from '../../../../components/molecules/Forms/BatchMergeModal';
import BatchSplitModal from '../../../../components/molecules/Forms/BatchSplitModal';
import {batchDefaultFilters} from '../../../../pages/shared/IndexPage/filterSchemas/batch';

export interface BuildConfigurationProps extends StateInput<IBuild> {}

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

function BatchSelectionStep() {
  const isSmallScreen = useSmallScreenSize();
  const buildActions = useBuildStoreActions();
  const {draftBuild} = useDraftBuild();
  const [forcedTableRefresh, forceTableRefresh] = useReducer((x) => x + 1, 0);
  const [isCreateBatchModalOpen, setCreateBatchModalOpen] = useState(false);
  const [isSplitBatchModalOpen, setSplitBatchModalOpen] = useState(false);
  const [isMergeBatchModalOpen, setMergeBatchModalOpen] = useState(false);
  const [selectedBatchUuid, setSelectedBatchUuid] = useState<string>();

  // Batch modals make a ensureConsistent request to builds. Resubscribe to the draft build on close.
  function resubscribeToBuild() {
    buildActions.ensureConsistent({uuid: draftBuild.uuid});
  }

  return (
    <div>
      <CreateBatchModal
        isModalOpen={isCreateBatchModalOpen}
        closeModal={() => {
          setCreateBatchModalOpen(false);
          resubscribeToBuild();
        }}
      />
      <BatchSplitModal
        open={isSplitBatchModalOpen}
        onClose={() => {
          setSplitBatchModalOpen(false);
          forceTableRefresh();
          resubscribeToBuild();
        }}
        initialBatchUuid={selectedBatchUuid}
      />
      <BatchMergeModal
        open={isMergeBatchModalOpen}
        onClose={() => {
          setMergeBatchModalOpen(false);
          forceTableRefresh();
          resubscribeToBuild();
        }}
        initialBatchAUuid={selectedBatchUuid}
      />
      <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="space-between">
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Typography variant={isSmallScreen ? 'h6' : 'h4'} style={{marginBottom: '1rem'}}>
            Step 2: Material Batch Selection
          </Typography>

          <BatchSelectionActionsButton
            setCreateBatchModalOpen={setCreateBatchModalOpen}
            setMergeBatchModalOpen={setMergeBatchModalOpen}
            splitBatchModalOpen={setSplitBatchModalOpen}
          />
        </Box>
      </Grid>
      <BatchSelectionTable setSelectedBatchUuid={setSelectedBatchUuid} forcedTableRefresh={forcedTableRefresh} />
    </div>
  );
}

export default BatchSelectionStep;

const BatchSelectionActionsButton = (props: {
  setCreateBatchModalOpen: Function;
  setMergeBatchModalOpen: Function;
  splitBatchModalOpen: Function;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createBatchModalOpen = () => {
    props.setCreateBatchModalOpen(true);
    setAnchorEl(null);
  };

  const mergeBatchModalOpen = () => {
    props.setMergeBatchModalOpen(true);
    setAnchorEl(null);
  };

  const splitBatchModalOpen = () => {
    props.splitBatchModalOpen(true);
    setAnchorEl(null);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        color="primary"
        variant="contained"
        fullWidth={isSmallScreen}
        onClick={handleClick}
        endIcon={<MoreVertIcon />}
      >
        Batch Options
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{minWidth: 'fit-content'}}
      >
        <MenuItem onClick={createBatchModalOpen}>
          <AddIcon />
          <Typography>Add Batch</Typography>
        </MenuItem>
        <MenuItem onClick={splitBatchModalOpen}>
          <CallSplitIcon />
          <Typography>Split Batch</Typography>
        </MenuItem>
        <MenuItem onClick={mergeBatchModalOpen}>
          <CallMergeIcon />
          <Typography>Merge Batch</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const useBatches = () => {
  const materialStoreActions = useMaterialStoreActions();
  const {draftBuild} = useDraftBuild();
  const batchList = useSelector((state: RootState) => state.batchStore.list);
  const materialsByUuid = useSelector((state: RootState) => state.materialStore.byId);

  useEffect(() => {
    if (batchList.length) {
      const batchMaterialUuids = flatten(
        batchList.map((batch) => batch.materialComposition.map((material) => material.materialUuid))
      );
      materialStoreActions.ensureConsistent({uuid: uniq(batchMaterialUuids)});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchList]);

  return batchList.map((batch) => {
    return {
      ...batch,
      draftBuild,
      materials: batch.materialComposition
        .sort((a, b) => b.weight - a.weight)
        .map(({materialUuid}) => materialsByUuid[materialUuid]?.name)
        .join(', '),
    };
  });
};

const defaultFilters = {sortBy: {registered: 'DESC'}} as any;

const BatchSelectionTable = (props: {setSelectedBatchUuid: (uuid: string) => void; forcedTableRefresh: number}) => {
  const {setDraftWithSave, draftBuild} = useDraftBuild();

  const onRowClick = (_event: any, row: any) => {
    setDraftWithSave((draftBuild) => {
      const newBatchUuid = draftBuild.batchUuid === row?.uuid ? null : row?.uuid;
      props.setSelectedBatchUuid(newBatchUuid);
      return {
        ...draftBuild,
        batchUuid: newBatchUuid,
      };
    });
  };

  return (
    <Grid item xs={12} style={{paddingTop: '32px'}}>
      <GenericTable
        filteringEnabled
        permanentFilters={batchDefaultFilters}
        defaultFilters={defaultFilters}
        tableTitle="Choose a Batch"
        resourceType="batch"
        useData={useBatches}
        onRowClick={onRowClick}
        minTableWidth="880px"
        forcedRefresh={props.forcedTableRefresh}
        tableOptions={{
          rowStyle: (rowData: any) => ({
            backgroundColor: rowData?.uuid === draftBuild.batchUuid ? '#F5FaFF' : '',
          }),
        }}
      />
    </Grid>
  );
};
