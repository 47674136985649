import React, {useEffect, useState} from 'react';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {IBuild, isCompletedBuildState, isEngagedBuildState} from '@common/api/models/builds/IBuild';
import DraftBuildProvider from '../DraftBuildContext';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core';
import buildSubmittedImg from '../../../../assets/img/build-submitted.png';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {RootState} from '../../../../store/reducers';
import {useDeviceStoreActions} from '../../../../store/actions';
import {useSelector} from 'react-redux';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {BUILD_SHORT_TRANSITION_TIMEOUT} from '../../liveBuild/index';
import BuildHeader from '../../shared/BuildHeader';
import Header from '../../../../components/organisms/Header';
import {DeviceDetailedState} from '@common/api/models/devices/IDevice';

const Card = styled(MuiCard)(spacing);

const SubmittedStep = ({build: draftBuild}: {build: IBuild}) => {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const [wasStagingStarting, setWasStagingStarting] = useState(false);

  // Stay up to date with the current device state to determine if online or not
  const deviceStore = useSelector((s: RootState) => s.deviceStore);
  const deviceActions = useDeviceStoreActions();
  const device = deviceStore.byId[draftBuild.deviceSerial!];

  const transitionFromSubmitted = () => {
    localStorage.removeItem(`${draftBuild.uuid}-submitted-time`);
    history.push(`/builds/uuid/${draftBuild.uuid}`);
  };

  useEffect(() => {
    // Even if device is online check to see if
    const buildTimeoutTimer = setTimeout(() => {
      toast('Starting build has timed out', {type: 'error'});
      transitionFromSubmitted();
    }, BUILD_SHORT_TRANSITION_TIMEOUT); // Set for 200s to give time for the build to start

    // If the build is old enough, transition to the build page
    const buildSubmittedTime = parseInt(String(localStorage.getItem(`${draftBuild.uuid}-submitted-time`)));
    if (buildSubmittedTime && Date.now() - buildSubmittedTime > BUILD_SHORT_TRANSITION_TIMEOUT) {
      toast('Starting build has timed out', {type: 'error'});
      transitionFromSubmitted();
    }

    return () => clearTimeout(buildTimeoutTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (draftBuild.deviceSerial) {
      deviceActions.ensureConsistent({serial: draftBuild.deviceSerial});
    }
  }, [draftBuild.deviceSerial, deviceActions]);

  useEffect(() => {
    // This will be triggered if the device successfully transitions to staging
    if (isEngagedBuildState(draftBuild.state) || isCompletedBuildState(draftBuild.state)) {
      transitionFromSubmitted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftBuild]);

  useEffect(() => {
    // This will be triggered if the device transitions into an offline state
    if (!device) return;
    if (device.online !== true) {
      toast('Device is offline, cannot start build', {type: 'error'});
      transitionFromSubmitted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    if (!device) return;

    if (
      [DeviceDetailedState.StagingStarting, DeviceDetailedState.Staging, DeviceDetailedState.StagingStopping].includes(
        device.stateDetailed
      )
    ) {
      setWasStagingStarting(true);
    }

    // Gone from any staging state -> idling. Indicates failure to start staging. Show error and redirect to draft build page.
    if (wasStagingStarting && device.stateDetailed === DeviceDetailedState.Idling) {
      toast('Device failed to enter staging, please check device logs.', {type: 'error'});
      transitionFromSubmitted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.stateDetailed, wasStagingStarting]);

  return (
    <DraftBuildProvider build={draftBuild} slices={[]} isSimpleWorkflow={false}>
      <Header
        helmet={`Draft Submitted - ${draftBuild.name}`}
        title={<BuildHeader build={draftBuild} />}
        breadcrumbs={[{title: 'Builds', path: '/builds'}, draftBuild.name]}
      />

      <Grid container spacing={isSmallScreen ? 3 : 6}>
        <Grid item xs={12}>
          <Card mb={isSmallScreen ? 3 : 6} padding={isSmallScreen ? 1 : 3}>
            <CardContent style={isSmallScreen ? {padding: '3vh'} : {}}>
              <img src={buildSubmittedImg} alt="Build submitted" />
              <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="center">
                Your Build has been submitted.
              </Typography>
              <Typography variant="subtitle1" align="center">
                We're just setting up a few things, you'll be redirected to the staging build page shortly...
              </Typography>
              <CircularProgress />

              <Button color="primary" variant="outlined" component={Link} to="/builds">
                Return to builds list
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DraftBuildProvider>
  );
};

export default SubmittedStep;

const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6vh;
  padding-bottom: 6vh !important;

  h2 {
    padding: 32px 0px;
  }
  img {
    height: 25vh;
  }
  .MuiCircularProgress-root {
    margin: 32px 0px;
  }
`;
