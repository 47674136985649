import moment from 'moment';
import {ParseError, parsePhoneNumber} from 'libphonenumber-js';
import {
  descriptiveDateFormat,
  descriptiveDateTimeFormat,
  descriptiveNoSecondsDateTimeFormat,
  shortDateFormat,
  shortDateTimeFormat,
  shortNoSecondsDateTimeFormat,
} from './config';

export function capitalise(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export type DateStringType = 'short' | 'descriptive';

export function renderDateString(type: DateStringType, inputString: moment.MomentInput) {
  switch (type) {
    case 'short':
      return moment(inputString).format(shortDateFormat);
    case 'descriptive':
      return moment(inputString).format(descriptiveDateFormat);
  }
}

export function renderDateTimeString(type: DateStringType, inputString: moment.MomentInput, hideSeconds?: boolean) {
  if (type === 'short') {
    return moment(inputString).format(hideSeconds ? shortNoSecondsDateTimeFormat : shortDateTimeFormat);
  } else {
    return moment(inputString).format(hideSeconds ? descriptiveNoSecondsDateTimeFormat : descriptiveDateTimeFormat);
  }
}

export function renderMobileNumber(mobile?: string) {
  try {
    return parsePhoneNumber('+' + mobile).formatInternational();
  } catch (error) {
    if (error instanceof ParseError) {
      return mobile;
    } else {
      throw error;
    }
  }
}

export const capitalizeFirstLetter = (item: string) => {
  return item[0].toUpperCase() + item.slice(1);
};
