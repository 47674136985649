import React from 'react';
import {Box, BoxProps, Tooltip, Typography} from '@material-ui/core';
import {renderDateString, renderDateTimeString} from '../../../../utils/string';
import EllipsisTextWithTooltip, {IEllipsisTextProps} from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';

export const DateWithTimeTooltip = ({
  timestamp,
  type = 'short',
}: {
  timestamp: string | Date | undefined;
  type?: 'short' | 'long';
}) => {
  if (!timestamp) return <></>;

  let dateTime = renderDateString('short', timestamp);
  const longDate = renderDateTimeString('short', timestamp);

  if (type === 'long') {
    dateTime = longDate;
  }

  return (
    <Tooltip title={longDate}>
      <Typography>{dateTime}</Typography>
    </Tooltip>
  );
};

export const EllipsisTextColumnTooltip = ({
  children,
  boxProps,
  ellipsisTextProps,
}: {
  children?: string | React.ReactElement;
  ellipsisTextProps?: IEllipsisTextProps;
  boxProps?: BoxProps;
}) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="nowrap" overflow="hidden" {...boxProps}>
      <EllipsisTextWithTooltip {...ellipsisTextProps}>{children}</EllipsisTextWithTooltip>
    </Box>
  );
};
