export enum ResourcePermissionType {
  BUILD = 'build',
  MACHINE = 'machine',
}

export interface IResourcePermission {
  uuid: string;
  resourceType: ResourcePermissionType;
  resourceUuid: string;
  userUuid: string;
  createdAt: Date;
}

export type IResourcePermissionSortOptions = 'createdAt';

export interface IResourcePermissionGETRequest {
  uuid?: string | {in: Array<string>} | {notIn: Array<string>};
  userUuid?: string | {in: Array<string>} | {notIn: Array<string>};
  resourceUuid?: string | {in: Array<string>} | {notIn: Array<string>};
  resourceType: ResourcePermissionType | {in: Array<ResourcePermissionType>} | {notIn: Array<ResourcePermissionType>};
}

export interface IResourcePermissionPOSTRequest {
  resourceType: ResourcePermissionType;
  resourceUuid: string;
}
