import React, {useEffect} from 'react';
import {IBuild} from '@common/api/models/builds/IBuild';
import GenericTable from '../../../../components/molecules/Table/GenericTable';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {AlertsRow} from '../../../shared/IndexPage/tableSchemas/alert';
import {GridSize, Paper} from '@material-ui/core';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {AlertSeverity} from '@common/api/models/devices/IAlert';
import {useBuildStoreActions} from '../../../../store/actions';
import {uniq} from 'lodash';

export interface DeviceAlertProps {
  build?: IBuild;
  deviceSerial?: string;
}

const useDeviceAlerts = () => {
  const buildStoreActions = useBuildStoreActions();
  const alertStore = useSelector((state: RootState) => state.alertStore.list);
  const buildsById = useSelector((state: RootState) => state.buildStore.byId);

  const allBuildUuids = uniq(alertStore.map((alert) => alert.buildUuid ?? '').filter((uuid) => uuid !== ''));

  useEffect(() => {
    buildStoreActions.ensureConsistent({
      uuid: uniq(allBuildUuids),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBuildUuids]);

  return alertStore.map<AlertsRow>((alert) => ({
    date: alert.date,
    message: alert.message,
    severity: alert.severity,
    build: buildsById[alert.buildUuid || '']?.name || undefined,
  }));
};

export const DeviceAlerts = (props: DeviceAlertProps) => {
  const isSmallScreen = useSmallScreenSize();

  const tableHeaderFilters: Array<{field: string; smGridSize: GridSize}> = [
    {field: 'severity', smGridSize: props.deviceSerial ? 6 : 12},
    {field: 'message', smGridSize: 6},
    {field: 'date', smGridSize: 6},
  ];
  if (props.deviceSerial) {
    tableHeaderFilters.push({field: 'buildUuid', smGridSize: 6});
  }

  return (
    <Paper style={{padding: isSmallScreen || props.deviceSerial ? '18px' : '24px 32px'}}>
      <GenericTable
        tableTitle="Device Alerts"
        resourceType="alert"
        filteringEnabled
        useData={useDeviceAlerts}
        permanentFilters={
          props.build ? {buildUuid: props.build.uuid} : props.deviceSerial ? {deviceSerial: props.deviceSerial} : {}
        }
        defaultFilters={{
          severity: {
            in: [AlertSeverity.CRITICAL, AlertSeverity.ERROR],
          },
        }}
        tableHeaderFilters={tableHeaderFilters}
        showFilterModal={false}
        ignoreColumns={!props.deviceSerial ? ['build'] : []}
        minTableWidth="800px"
      />
    </Paper>
  );
};
