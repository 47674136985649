import React, {useEffect, useRef, useState} from 'react';
import {Button, ButtonProps, CircularProgress} from '@material-ui/core';
import {ErrorOutline} from '@material-ui/icons';
import styled from 'styled-components';

import {whiteColor, primaryColor, dangerColor} from '../../assets/jss/material-dashboard-react';

export interface CustomButtonProps extends ButtonProps {
  text: string;
  loading?: boolean;
  error?: boolean;
  icon?: JSX.Element;
  onClick?: () => void;
}

const calcSpinnerColour = (colour: 'inherit' | 'primary' | 'secondary' | undefined) => {
  switch (colour) {
    case 'primary':
      return whiteColor;
    case 'secondary':
      return primaryColor[0];
    default:
      return whiteColor;
  }
};

const SpinnerInsideButton = styled(CircularProgress)`
  position: absolute;
  color: ${({color}) => calcSpinnerColour(color)};
`;

function CustomButton({disabled, loading, error, text, icon, onClick, ...props}: CustomButtonProps) {
  const [isLoading, setIsLoading] = useState(false);
  const unmounted = useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const onClickHandler = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setIsLoading(true);
    onClick && (await onClick());

    // Sometimes the onclick function that is passed in causes this component to unmount.
    // This check prevents memory leaks from occurring.
    if (!unmounted.current) {
      setIsLoading(false);
    }
  };
  return (
    <Button
      disabled={disabled || isLoading || loading}
      startIcon={error ? <ErrorOutline style={{color: dangerColor[0]}} /> : ''}
      {...props}
      onClick={onClickHandler}
    >
      {(isLoading || loading) && (
        <SpinnerInsideButton size={20} color={props.variant === 'outlined' ? 'secondary' : 'primary'} />
      )}
      {icon}
      {text}
    </Button>
  );
}

CustomButton.defaultProps = {
  text: 'Submit',
  loading: false,
};

export default CustomButton;
