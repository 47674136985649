import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {
  ISimilarityReport,
  ISimilarityComparisonGETResponse,
  ISimilarityReportGETResponse,
  ISimilarityComparisonStatusGETResponse,
  ComparisonWeightsType,
  RotationMap,
} from '@common/api/models/builds/data/ISimilarity';

export enum SimilarityReportActions {
  DELETE_SIMILARITY_REPORT_SUCCESS = 'DELETE_SIMILARITY_REPORT_SUCCESS',
  SET_SIMILARITY_REPORT_DRAFT = 'SET_SIMILARITY_REPORT_DRAFT',
  CLEAR_SIMILARITY_REPORT_DRAFT = 'CLEAR_SIMILARITY_REPORT_DRAFT',
  SET_SIMILARITY_CURRENTLY_VIEWING_PART = 'SET_SIMILARITY_CURRENTLY_VIEWING_PART',
  CREATE_SIMILARITY_REPORT_REQUEST = 'CREATE_SIMILARITY_REPORT_REQUEST',
  CREATE_SIMILARITY_REPORT_SUCCESS = 'CREATE_SIMILARITY_REPORT_SUCCESS',
  CREATE_SIMILARITY_REPORT_FAILURE = 'CREATE_SIMILARITY_REPORT_FAILURE',
  FETCH_PARTS_FOR_BUILD_SUCCESS = 'FETCH_PARTS_FOR_BUILD_SUCCESS',
  CLEAR_PARTS_FOR_BUILD = 'CLEAR_PARTS_FOR_BUILD',
  FETCH_SIMILARITY_REPORT_REQUEST = 'FETCH_SIMILARITY_REPORT_REQUEST',
  FETCH_SIMILARITY_REPORT_SUCCESS = 'FETCH_SIMILARITY_REPORT_SUCCESS',
  FETCH_SIMILARITY_REPORT_FAILURE = 'FETCH_SIMILARITY_REPORT_FAILURE',
  UPDATE_SIMILARITY_REPORT_SUCCESS = 'UPDATE_SIMILARITY_REPORT_SUCCESS',
  SET_SIMILARITY_CURRENTLY_VIEWING_COMPARISON = 'SET_SIMILARITY_CURRENTLY_VIEWING_COMPARISON',
  FETCH_SIMILARITY_REPORT_PROGRESS_SUCCESS = 'FETCH_SIMILARITY_REPORT_PROGRESS_SUCCESS',
  SET_NEW_TARGET_PARTS_SUCCESS = 'SET_NEW_TARGET_PARTS_SUCCESS',
  SET_NEW_TARGET_PART_ROTATIONS_SUCCESS = 'SET_NEW_TARGET_PART_ROTATIONS_SUCCESS',
}

export interface DraftSimilarityReport {
  name: string;
  backupName: string;
  sourceParts: IPartGETResponse[];
  targetParts: IPartGETResponse[];
  sourcePartRotations: RotationMap;
  targetPartRotations: RotationMap;
  comparisonWeights: ComparisonWeightsType;
}

export interface DeleteSimilarityReportAction {
  type: string;
  payload: ISimilarityReport;
}

export interface ClearDraftSimilarityReportAction {
  type: string;
}
export interface SetDraftSimilarityReportAction {
  type: string;
  payload: {field: keyof DraftSimilarityReport; value: any};
}

export interface SetCurrentlyViewingPartAction {
  type: string;
  payload: IPartGETResponse | undefined;
}

export interface SetCurrentlyViewingComparisonAction {
  type: string;
  payload: ISimilarityComparisonGETResponse | undefined;
}

export interface CreateSimilarityReportAction {
  type: string;
  payload: ISimilarityReportGETResponse;
}

export interface ClearPartsForBuildAction {
  type: string;
}

export interface FetchPartsForBuildAction {
  type: string;
  payload: {
    buildUuid: string;
    buildName: string;
    parts: IPartGETResponse[];
    total: number;
  };
}

export interface SetNewTargetPartsAction {
  type: string;
  payload: IPartGETResponse[];
}

export interface SetNewTargetPartRotationsAction {
  type: string;
  payload: RotationMap;
}
export interface FetchSimilarityComparisonStatusAction {
  type: string;
  payload: ISimilarityComparisonStatusGETResponse;
}
export interface FetchSimilarityReportAction extends CreateSimilarityReportAction {}
export interface UpdateSimilarityReportAction extends CreateSimilarityReportAction {}
