import React from 'react';
import {Tooltip, Typography} from '@material-ui/core';
import {ViewerButton} from '../../FadingComponents';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../utils';
import {ArrowUpward, KeyboardArrowUp, KeyboardArrowDown, ArrowDownward} from '@material-ui/icons';

interface NavigateLayerProps {
  mouseOn: boolean;
  stageHeight: number;
  incLayer: (val: number) => void;
  totalLayers: number;
  currentLayer: number | undefined;
  handleFrameButtonClick: () => void;
}

export default function NavigateLayer({
  stageHeight,
  incLayer,
  totalLayers,
  mouseOn,
  handleFrameButtonClick,
  currentLayer,
}: NavigateLayerProps) {
  return (
    <>
      <Tooltip title="Forward 10 layers" placement="left">
        <ViewerButton
          onClick={() => incLayer(10)}
          style={{
            right: BUTTON_MARGIN,
            top: stageHeight - BUTTON_HEIGHT * 6,
            position: 'absolute',
          }}
          disabled={currentLayer === totalLayers}
          className={(!mouseOn && 'fade') || undefined}
        >
          <ArrowUpward />
        </ViewerButton>
      </Tooltip>
      <Tooltip title="Forward 1 layer" placement="left">
        <ViewerButton
          onClick={() => incLayer(1)}
          style={{
            right: BUTTON_MARGIN,
            top: stageHeight - BUTTON_HEIGHT * 5.25,
            position: 'absolute',
          }}
          disabled={currentLayer === totalLayers}
          className={(!mouseOn && 'fade') || undefined}
        >
          <KeyboardArrowUp />
        </ViewerButton>
      </Tooltip>

      <Tooltip title="Click to go to specific layer" placement="left">
        <ViewerButton
          style={{
            position: 'absolute',
            right: '0px',
            top: stageHeight - BUTTON_HEIGHT * 4.4,
            color: '#FFFFFF',
            width: 44,
          }}
          onClick={handleFrameButtonClick}
          className={(!mouseOn && 'fade-half') || undefined}
        >
          <Typography style={{fontSize: 15, textAlign: 'center'}}>{currentLayer}</Typography>
        </ViewerButton>
      </Tooltip>
      <Tooltip title="Backward 1 layer" placement="left">
        <ViewerButton
          onClick={() => incLayer(-1)}
          style={{
            right: BUTTON_MARGIN,
            top: stageHeight - BUTTON_HEIGHT * 3.75,
            position: 'absolute',
          }}
          disabled={currentLayer === 1}
          className={(!mouseOn && 'fade') || undefined}
        >
          <KeyboardArrowDown />
        </ViewerButton>
      </Tooltip>
      <Tooltip title="Backward 10 layer" placement="left">
        <ViewerButton
          onClick={() => incLayer(-10)}
          style={{
            right: BUTTON_MARGIN,
            top: stageHeight - BUTTON_HEIGHT * 3,
            position: 'absolute',
          }}
          disabled={currentLayer === 1}
          className={(!mouseOn && 'fade') || undefined}
        >
          <ArrowDownward />
        </ViewerButton>
      </Tooltip>
    </>
  );
}
