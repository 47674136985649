import React from 'react';
import {useHistory} from 'react-router-dom';
import {Box, IconButton, Tooltip, Typography} from '@material-ui/core';
import {Delete, LibraryAdd, WarningRounded} from '@material-ui/icons';
import {ISimilarityReportGETResponse} from '@common/api/models/builds/data/ISimilarity';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import SimilarityStatusChip from '../../../similarityReport/shared/SimilarityStatusChip';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {warningColor} from '../../../../assets/jss/material-dashboard-react';
import SourcePartChips from '../../../../pages/similarityReport/results/SourcePartChips';

interface SimilarityReportRow extends ISimilarityReportGETResponse {
  formattedSources: string[];
  onDelete: () => void;
}

const similarityReportTableSchema = [
  {
    title: 'Created at',
    field: 'createdAt',
    width: '132px',
    render: (row: SimilarityReportRow) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Name',
    field: 'name',
    width: 'auto',
    render: (row: SimilarityReportRow) => {
      if (!!row.sourceWarningMessage)
        return (
          <Box display="flex" alignItems="center" flexWrap="nowrap" overflow="hidden">
            <Box style={{overflow: 'hidden', marginRight: '6px'}}>
              <EllipsisTextWithTooltip>{row.name}</EllipsisTextWithTooltip>
            </Box>
            <Box style={{width: '24px', height: '24px', marginTop: '-2px'}}>
              <Tooltip title={<Typography>{row.sourceWarningMessage}</Typography>}>
                <WarningRounded style={{color: warningColor[1]}} />
              </Tooltip>
            </Box>
          </Box>
        );
      return (
        <Box display="flex" alignItems="center" flexWrap="nowrap" overflow="hidden">
          <EllipsisTextColumnTooltip>{row.name}</EllipsisTextColumnTooltip>
        </Box>
      );
    },
  },
  {
    title: 'Status',
    field: 'status',
    width: '144px',
    render: (row: SimilarityReportRow) => {
      return <SimilarityStatusChip similarityStatus={row.status} />;
    },
  },
  {
    title: 'Sources',
    field: 'sources',
    sorting: false,
    width: 'auto',
    render: (row: SimilarityReportRow) => {
      return <SourcePartChips sources={row.formattedSources} />;
    },
  },
  {
    title: '# Target Parts',
    field: 'targetPartCount',
    width: 'auto',
  },
  {
    title: 'Actions',
    width: '112px',
    sorting: false,
    render: (rowData: SimilarityReportRow) => <SimilarityReportActions rowData={rowData} />,
  },
];

export default similarityReportTableSchema;

const SimilarityReportActions = ({rowData}: {rowData: SimilarityReportRow}) => {
  const history = useHistory();

  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title="Add more Target Parts">
        <IconButton
          aria-label="AddMoreTargetParts"
          onClick={(event) => {
            event.stopPropagation();
            history.push(`/reports/similarity/uuid/${rowData.uuid}/addMoreTargetParts`);
          }}
        >
          <LibraryAdd />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          onClick={(event) => {
            event.stopPropagation();
            rowData.onDelete();
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
