import {
  ISimilarityComparisonGETRequest,
  ISimilarityComparisonGETResponse,
} from '@common/api/models/builds/data/ISimilarity';
import {ApiFailureResponse} from '@common/api/apiResult';
import {isObject, isString, cloneDeep} from 'lodash';
import {QueryFilter, WSQueryFilter} from './liveUpdateStore';
import {similarityComparisonGET, similarityComparisonDELETE} from '../../api/ajax/similarityReport';
import {RootState} from '../reducers';
import {
  ApiResponsePayload,
  LiveStoreActions,
  LiveStoreFetchErrorAction,
  LiveStoreFinishFetchAction,
  LiveStoreStartFetchAction,
} from '../model/liveUpdateStore';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  deleteSimilarityComparison(reportUuid: string, comparison: ISimilarityComparisonGETResponse) {
    return async (dispatch: Function) => {
      const response = await similarityComparisonDELETE(reportUuid, comparison.uuid);

      if (response.success) {
        dispatch({
          type: LiveStoreActions.DELETE_LIST + 'SIMILARITY_COMPARISON',
          payload: comparison,
        });
        return true;
      } else {
        return false;
      }
    };
  },

  _finishListFetch(
    response: ApiResponsePayload<ISimilarityComparisonGETResponse>,
    filters: QueryFilter<ISimilarityComparisonGETRequest>
  ): () => LiveStoreFinishFetchAction<ISimilarityComparisonGETResponse> {
    return () => ({
      type: LiveStoreActions.FINISH_LIST_FETCH + 'SIMILARITY_COMPARISON',
      payload: {...response, filters},
    });
  },

  _errorListFetch(message: string): () => LiveStoreFetchErrorAction<ISimilarityComparisonGETResponse> {
    return () => ({
      type: LiveStoreActions.FETCH_LIST_ERROR + 'SIMILARITY_COMPARISON',
      payload: message,
    });
  },

  _startListFetch(): () => LiveStoreStartFetchAction<ISimilarityComparisonGETResponse> {
    return () => ({
      type: LiveStoreActions.START_LIST_FETCH + 'SIMILARITY_COMPARISON',
      payload: {},
    });
  },

  fetchAndSubscribeToList(
    queryFilters: QueryFilter<ISimilarityComparisonGETRequest>,
    _wsFilters?: WSQueryFilter<ISimilarityComparisonGETRequest>
  ) {
    return async (dispatch: Function, getState: () => RootState) => {
      let response;
      const filters = cloneDeep(queryFilters);
      const uuid = getState().similarityReportStore.currentSimilarityReport?.uuid;

      if (!uuid) throw new Error('Failed to fetch comparisons: No Report UUID provided.');

      if (filters.take) {
        if (filters.take > 100) filters.take = 100;
      } else {
        filters.take = 10;
      }
      if (isObject(filters.similarityScore)) {
        if ('lte' in filters.similarityScore) {
          filters.similarityScore.lte = (filters.similarityScore.lte as number) / 100;
        }
        if ('gte' in filters.similarityScore) {
          filters.similarityScore.gte = (filters.similarityScore.gte as number) / 100;
        }
      }

      dispatch(this._startListFetch());
      response = await similarityComparisonGET(uuid, filters);

      if (!response.success) {
        response = response as ApiFailureResponse;
        const message = isString(response.message) ? response.message : JSON.stringify(response.message);

        dispatch(this._errorListFetch(message));
        return;
      }

      dispatch(this._finishListFetch(response as ApiResponsePayload<ISimilarityComparisonGETResponse>, filters));
    };
  },
};
