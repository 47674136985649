import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import Similarity3DViewport from '../../../components/molecules/Viewport/3D/Similarity3DViewport';
import {RootState} from '../../../store/reducers/index';
import {useContainerHeight} from '../../../utils/utilHooks';

const Results3DViewport = ({isViewportFullScreen}: {isViewportFullScreen: boolean}) => {
  const {height, setContainerRef} = useContainerHeight();

  const currentlyViewingComparison = useSelector(
    (state: RootState) => state.similarityReportStore.currentlyViewingComparison
  );
  const reportUuid = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport!.uuid);

  return (
    <Container ref={setContainerRef} isViewportFullScreen={isViewportFullScreen}>
      <Similarity3DViewport
        similarityComparison={currentlyViewingComparison}
        reportUuid={reportUuid}
        height={height ? height - 6 : height}
      />
    </Container>
  );
};

export default Results3DViewport;

const Container = styled.div<{isViewportFullScreen: boolean}>`
  height: 100%;
  border-radius: ${({isViewportFullScreen}) => (isViewportFullScreen ? '0px' : '4px')};
  box-shadow: 0 0 14px 0 rgb(53 64 82 / 5%);
  overflow: hidden;
`;
