import React, {useState} from 'react';
import {Grid, Typography, IconButton, Box, Popover} from '@material-ui/core';
import {AddOutlined} from '@material-ui/icons';
import {Control, useWatch} from 'react-hook-form';
import {cloneDeep, set} from 'lodash';
import {
  LogicalOperator,
  DEFAULT_SINGLE_CONDITION,
  DEFAULT_CONDITION_GROUP,
  IDefectNotificationConditions,
  ConditionGroup,
} from '@common/api/models/builds/data/defects/IDefectNotifications';

function AddCondition({
  path,
  control,
  reset,
}: {
  path: string;
  control: Control<IDefectNotificationConditions>;
  reset: (newForm: IDefectNotificationConditions) => void;
}) {
  const wholeForm: IDefectNotificationConditions | undefined = useWatch({control}) as IDefectNotificationConditions;
  const conditionGroup: ConditionGroup | undefined = useWatch({
    name: path,
    control: control,
  });

  const [anchorEl, setAnchorEl] = useState<Element>();
  const [popperOpen, setPopperOpen] = useState(false);

  const currentOperator = path === '' ? wholeForm.operator : conditionGroup?.operator;
  const conditions = path === '' ? wholeForm.conditionGroups : conditionGroup?.conditions;

  function onAddCondition(selectedOperator?: LogicalOperator) {
    if (!conditions) return;

    if (conditions.length <= 1 && !selectedOperator) {
      setPopperOpen(true);
      return;
    }
    const newOperator = selectedOperator || currentOperator;

    const newCondition = path === '' ? DEFAULT_CONDITION_GROUP : DEFAULT_SINGLE_CONDITION;

    const newForm = cloneDeep(wholeForm!);

    if (path === '') {
      newForm.operator = newOperator!;
      newForm.conditionGroups = [...(conditions as ConditionGroup[]), newCondition as ConditionGroup];
    } else {
      const newConditionGroup = {
        operator: newOperator,
        conditions: [...conditions, newCondition],
      };
      set(newForm, path, newConditionGroup);
    }

    reset(newForm);
    setPopperOpen(false);
  }

  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center" width="100%">
        <IconButton
          color="primary"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            onAddCondition();
          }}
        >
          <AddOutlined />
        </IconButton>

        <Popover open={popperOpen} onClose={() => setPopperOpen(false)} anchorEl={anchorEl} style={{cursor: 'pointer'}}>
          <Typography style={{padding: '8px 16px'}} onClick={() => onAddCondition(LogicalOperator.AND)}>
            AND
          </Typography>
          <Typography style={{padding: '8px 16px'}} onClick={() => onAddCondition(LogicalOperator.OR)}>
            OR
          </Typography>
        </Popover>
      </Box>
    </Grid>
  );
}

export default AddCondition;
