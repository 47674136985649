import React, {useCallback, useState, Dispatch, SetStateAction} from 'react';
import {CircularProgress, TextField, Typography} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {IUser} from '@common/api/models/users/IUser';
import {userSearchGET} from '../../../api/ajax/users';
import {debounce} from 'lodash';
import {QueryFilter} from '../../../store/actions/liveUpdateStore';

export type SelectedUserType =
  | Pick<IUser, 'uuid' | 'firstName' | 'lastName' | 'email' | 'mobile' | 'sendEmail' | 'sendMessage'>
  | undefined;

const SearchAndSelectUser = ({
  selectedUser,
  setSelectedUser,
  filterOutUuids,
  queryFilters,
}: {
  selectedUser: SelectedUserType;
  setSelectedUser: Dispatch<SetStateAction<SelectedUserType | undefined>>;
  filterOutUuids?: Array<string>;
  queryFilters?: QueryFilter<IUser>;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<SelectedUserType>>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContactSearch = useCallback(
    debounce(async (inputValue) => {
      const val = inputValue.trim();
      const filters: any = {...queryFilters};

      if (filterOutUuids) filters.uuid = {notIn: filterOutUuids};
      const userRes = await userSearchGET(val !== '' ? val : ' ', {
        take: 50,
        ...filters,
      });
      if (userRes.success) {
        const res: SelectedUserType[] = userRes.data.map((user) => {
          const newRes: SelectedUserType = {
            uuid: user.uuid,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            mobile: user.mobile,
            sendEmail: user.sendEmail,
            sendMessage: user.sendMessage,
          };
          return newRes;
        });
        setOptions(res);
      } else {
        setOptions([]);
      }
      setLoading(false);
    }, 500),
    [filterOutUuids]
  );

  const isSameRecipient = (option: SelectedUserType) => {
    if (!option || !selectedUser) return false;
    return option.uuid === selectedUser.uuid;
  };

  const getSuggestionValue = (user: SelectedUserType, withEmail: boolean = true) => {
    if (user) {
      if (withEmail) {
        return `${user.firstName} ${user.lastName} (${user.email})`;
      }
      return `${user.firstName} ${user.lastName}`;
    }
    return 'Loading...';
  };

  const renderOption = (user: SelectedUserType, {inputValue}: any) => {
    const label = getSuggestionValue(user, false);
    const matches = match(label, inputValue);
    const parts = parse(label, matches);

    return (
      <Typography>
        {parts.map((part, index) => {
          const fontWeightValue = part.highlight ? 700 : 300;
          return (
            <span key={part.text + String(index)} style={{fontWeight: fontWeightValue}}>
              {part.text}
            </span>
          );
        })}
      </Typography>
    );
  };

  return (
    <Autocomplete
      id="existing-contact"
      open={open}
      size="small"
      getOptionSelected={(option) => isSameRecipient(option)}
      getOptionLabel={getSuggestionValue}
      options={options}
      loading={loading}
      value={selectedUser || null}
      onChange={(_event: any, option: any) => setSelectedUser(option)}
      renderOption={renderOption}
      onOpen={() => {
        setOpen(true);
        handleContactSearch('');
        setLoading(true);
      }}
      onClose={() => {
        setOpen(false);
        setLoading(false);
        setOptions([]);
      }}
      onInputChange={(_e, value) => {
        handleContactSearch(value);
        setLoading(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Users..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchAndSelectUser;
