import {Pagination, SpanQuery} from '@common/api/models/common';
import {OrderDirection} from '@common/utils/ordering/ordering';

export enum SimilarityStatus {
  Submitted = 'submitted',
  Generating = 'generating',
  Success = 'success',
  Failure = 'failure',
}

export enum SimilarityComparisonType {
  intensity = 'ASIM',
  hotSpots = 'HotSpots',
  coldSpots = 'ColdSpots',
  lsdd = 'Lsdd',
  moderateSpatter = 'ModerateSpatter',
  seriousSpatter = 'SeriousSpatter',
}

export type DefectScoresType = {
  [key in SimilarityComparisonType]: number | null;
};
export type ComparisonWeightsType = {
  [key in SimilarityComparisonType]: [number | null, number, number];
};

export const DEFAULT_DEFECT_SCORES: DefectScoresType = {
  [SimilarityComparisonType.intensity]: null,
  [SimilarityComparisonType.hotSpots]: null,
  [SimilarityComparisonType.coldSpots]: null,
  [SimilarityComparisonType.lsdd]: null,
  [SimilarityComparisonType.moderateSpatter]: null,
  [SimilarityComparisonType.seriousSpatter]: null,
};

export const DEFAULT_WEIGHTS: ComparisonWeightsType = {
  [SimilarityComparisonType.intensity]: [null, 0.3, 1],
  [SimilarityComparisonType.hotSpots]: [null, 0.03, 2],
  [SimilarityComparisonType.coldSpots]: [null, 0.03, 2],
  [SimilarityComparisonType.lsdd]: [null, 0.15, 1],
  [SimilarityComparisonType.moderateSpatter]: [null, 0.1, 1],
  [SimilarityComparisonType.seriousSpatter]: [null, 0.1, 1],
};

export interface ISimilaritySource {
  uuid: string;
  status: SimilarityStatus;
  createdAt: Date;
  finishedAt?: Date;
  warningMessage?: string;
}

export interface ISimilaritySourcePartsLink {
  similaritySourceUuid: string;
  partUuid: string;
  rotation: number;
}

export interface ISimilarityReport {
  uuid: string;
  name: string;
  similaritySourceUuid?: string;
  status: SimilarityStatus;
  createdAt: Date;
  comparisonWeights: ComparisonWeightsType;
}

export interface ISimilarityComparison {
  uuid: string;
  similaritySourceUuid?: string;
  targetPartUuid: string;
  targetPartRotation: number;
  createdAt: Date;
  finishedAt?: Date;
  status: SimilarityStatus;
  statisticsUrl?: string;
  warningMessage?: string;
  defectScores: DefectScoresType;
  geometrySimilarity?: number;
  similarityScore?: number;
}

export interface ISimilarityPointCloud {
  uuid: string;
  comparisonUuid: string;
  numPoints: number;
  size: number;
  url: string;
  createdAt: Date;
}

export interface ISimilarityComparisonGETRequest extends Pagination {
  targetPartName?: string | {like?: string; notLike?: string};
  buildName?: string | {like?: string; notLike?: string};
  buildUuid?: string | {in: Array<string>} | {notIn: Array<string>};
  buildEndDate?: {since?: string; until?: string};
  similarityScore?: SpanQuery;
  status?: SimilarityStatus;
  hasWarningMessage?: boolean;
  sortBy?: {[key in ComparisonSortOptions]: OrderDirection};
}
export interface ISimilarityReportGETResponse extends ISimilarityReport {
  sourceWarningMessage?: string;
  sources: Array<{
    name: string;
    uuid: string;
    buildName: string;
    buildUuid: string;
    totalArea: number;
    rotation: number;
  }>;
}

export type ReportSortOptions = 'name' | 'status' | 'createdAt' | 'targetPartCount';

export interface ISimilarityReportTableGETRequest extends Pagination {
  name?: string | {like: string};
  buildName?: string | {like: string};
  buildUuid: string | {in: Array<string>} | {notIn: Array<string>};
  sourcePartUuid: string | {in: Array<string>} | {notIn: Array<string>};
  targetPartUuid: string | {in: Array<string>} | {notIn: Array<string>};
  targetPartCount?: {gte?: number; lte?: number};
  status?: SimilarityStatus;
  partName?: string | {like: string};
  sortBy?: {[key in ReportSortOptions]: OrderDirection};
}

export interface ISimilarityReportTableGETResponse extends ISimilarityReportGETResponse {
  targetPartCount: number;
}

export type ComparisonSortOptions = 'targetPartName' | 'similarityScore' | 'status';

export type RotationMap = {[uuid: string]: number};

export interface ISimilarityReportPOSTRequest {
  name?: string;
  comparisonWeights?: ComparisonWeightsType;
  targetPartUuids: string[];
  sourcePartUuids: string[];
  sourcePartRotations: RotationMap;
  targetPartRotations: RotationMap;
}

export interface ISimilarityReportPATCHRequest {
  name?: string;
  targetPartUuids?: string[];
  targetPartRotations: RotationMap;
  comparisonWeights?: ComparisonWeightsType;
}

export interface ISimilarityReportPOSTResponse extends ISimilarityReportGETResponse {
  comparisons: (ISimilarityComparison & {
    buildName?: string;
    targetPartName?: string;
  })[];
}

export interface ISimilarityComparisonGETResponse extends ISimilarityComparison {
  buildName?: string;
  targetPartName?: string;
  similarityScore?: number;
}
export interface ISimilarityComparisonStatusGETResponse {
  uuid: string;
  reportStatus: SimilarityStatus;
  [SimilarityStatus.Submitted]: string[];
  [SimilarityStatus.Generating]: string[];
  [SimilarityStatus.Success]: string[];
  [SimilarityStatus.Failure]: string[];
}

export interface ISimilarityDownloadUrlResponse {
  url: string;
}

export interface ISimilarityBuildsGETRequest extends Pagination {
  buildName?: string | {like?: string; notLike?: string};
}

export interface ISimilarityBuildsGETResponse {
  buildName?: string;
  buildUuid?: string;
}

export function calculateAverageWeightedScore(
  defectScores: DefectScoresType,
  comparisonWeights: ComparisonWeightsType
) {
  // Recreating numpys np.logspace
  function logSpace(start: number, stop: number, length: number) {
    const step = (stop - start) / (length - 1);
    const linSpaceArray = Array.from({length: length}, (_, i) => start + step * i);
    return linSpaceArray.map((value) => Math.pow(10, value));
  }

  // Recreating numpys np.average
  function average(metrics: Array<number>, weights: Array<number>) {
    const weightedSum = metrics.reduce((sum, score, index) => {
      return sum + score * weights[index];
    }, 0);
    const totalWeights = weights.reduce((sum, weight) => sum + weight, 0);

    return weightedSum / totalWeights;
  }

  const [standardisedMetrics, standardisedWeights] = calculateStandardisedMetrics(defectScores, comparisonWeights);

  const weightsProvided = standardisedWeights.every((weight) => weight !== null);

  // If no weights provided, we weight via a logspace array
  const metrics = weightsProvided ? standardisedMetrics : standardisedMetrics.sort();
  const weights = weightsProvided ? standardisedWeights : logSpace(1, 0, metrics.length);

  return average(metrics, weights);
}

export function similarityWeightFunction(halfPoint: number, slope: number, score: number) {
  // inspired from https://stats.stackexchange.com/questions/214877 and the inverse logit function
  // this is a reverse s-shaped curve from (0,1) to (1,0), with the point (r, 0.5)
  const xTerm = score ** (-Math.log(2) / Math.log(halfPoint));
  return -1 / (1 + (xTerm / (1 - xTerm)) ** (-2 * slope)) + 1;
}

function calculateStandardisedMetrics(defectScores: DefectScoresType, comparisonWeights: ComparisonWeightsType) {
  const standardisedMetrics: Array<number> = [];
  const weights: Array<number> = [];
  for (const [defectType, score] of Object.entries(defectScores)) {
    const [weight, halfPoint, slope] = comparisonWeights[defectType as SimilarityComparisonType];

    weights.push(weight);
    if (defectType === SimilarityComparisonType.intensity) {
      standardisedMetrics.push(similarityWeightFunction(halfPoint, slope, 1 - score));
    } else {
      standardisedMetrics.push(similarityWeightFunction(halfPoint, slope, Math.abs(score)));
    }
  }

  return [standardisedMetrics, weights];
}
