import React, {useEffect, Ref} from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Typography,
} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';

import {NavLink as RouterNavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootState} from '../../store/reducers';
import {useSliceAttachmentStoreActions} from '../../store/actions';
import {FetchingState} from '../../store/model/liveUpdateStore';
import ErrorBoundary from '../../utils/ErrorBoundary';
import {SliceHistoryTable} from '../../components/molecules/Table/SliceHistoryTable';
import {sliceAttachmentByUuidDELETE, sliceAttachmentDownloadUrlGET} from '../../api/ajax/sliceAttachments';

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const NavLink = React.forwardRef((props: any, ref: Ref<HTMLAnchorElement>) => (
  <RouterNavLink innerRef={ref} {...props} />
));

export function SliceHistoryPage() {
  const sliceAttachmentActions = useSliceAttachmentStoreActions();
  const sliceAttachmentStore = useSelector((state: RootState) => state.sliceAttachmentStore);

  useEffect(() => {
    sliceAttachmentActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sliceAttachments = Object.values(sliceAttachmentStore.byId).filter((attachment: any) => attachment.build);

  const handleRemoveSlice = async (sliceAttachment: any) => {
    await sliceAttachmentByUuidDELETE(sliceAttachment.uuid);
  };

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={2}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Slice File History
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/builds/">
              Slice Files
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <ErrorBoundary>
              <SliceHistoryTable
                sliceAttachments={sliceAttachments}
                onRemove={(r) => {
                  const arg = Array.isArray(r) ? r : [r];
                  for (const a of arg) {
                    handleRemoveSlice(a.sliceAttachment);
                  }
                }}
                downloadUrlGET={sliceAttachmentDownloadUrlGET}
                isLoading={sliceAttachmentStore.fetched === FetchingState.Fetching}
              />
            </ErrorBoundary>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
