import Q from 'q';
import {downloadZip} from 'client-zip';
import {downloadBlob} from '../../utils/webtools';
import {RootState} from '../reducers';
import {buildDataArchiveUrlGET} from '../../api/ajax/buildDataArchive';
import {BuildDataArchiveActions, BuildDataDownloadStatus} from '../model/buildDataArchive';
import {BuildDataArchiveFile} from '@common/api/models/builds/data/IBuildDataArchive';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  downloadData(buildUuid: string, files: Array<BuildDataArchiveFile>, zipFilename: string) {
    return async (dispatch: Function, getState: () => RootState) => {
      const index = getState().buildDataArchive.buildDataDownloads.length;

      dispatch({
        type: BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_START,
        payload: {
          downloads: files.map((file) => ({
            filename: file.filename,
            total: file.size,
            loaded: 0,
          })),
          totalProgress: 0,
          status: BuildDataDownloadStatus.IN_PROGRESS,
          zipFilename,
        },
      });

      const downloadFile = (url: string, filename: string): Q.Promise<Blob> => {
        const defer = Q.defer<Blob>();

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function (progress) {
          dispatch({
            type: BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_PROGRESS,
            payload: {
              index,
              filename,
              loaded: progress.loaded,
              total: progress.total,
            },
          });
        };

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status < 400) {
              defer.resolve(xhr.response);
            } else {
              defer.reject(new Error());
            }
          }
        };
        xhr.send();

        return defer.promise;
      };

      try {
        async function* generateSequence(start: number, end: number) {
          for (let i = start; i <= end; i++) {
            const file = files[i];
            const res = await buildDataArchiveUrlGET(buildUuid, file.filename);
            if (res.success) {
              const blob = await downloadFile(res.data.signedUrl, file.filename);

              yield {name: file.filename.replace('/', ''), input: blob};
            } else {
              throw new Error();
            }
          }
        }

        const blob = await downloadZip(generateSequence(0, files.length - 1), {buffersAreUTF8: true}).blob();
        downloadBlob(zipFilename, blob);

        dispatch({
          type: BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_FINISH,
          payload: {index, blob},
        });
      } catch (err) {
        console.log(err);
        dispatch({
          type: BuildDataArchiveActions.BUILD_DATA_ARCHIVE_DOWNLOAD_ERROR,
          payload: {index, errorMessage: 'Oops, something went wrong!'},
        });
      }
    };
  },

  setNumDownloads: (numDownloads: number) => {
    return async (dispatch: Function) => {
      dispatch({
        type: BuildDataArchiveActions.BUILD_DATA_ARCHIVE_SET_NUM_DOWNLOADS,
        payload: {numDownloads},
      });
    };
  },
};
