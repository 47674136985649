import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Grid, Button as MuiButton, Typography} from '@material-ui/core';
import {ArrowBack, ArrowForward, CheckCircle} from '@material-ui/icons';
import {spacing} from '@material-ui/system';
import {DeviceAvailability} from '@common/api/models/devices/IDevice';
import {IBuild} from '@common/api/models/builds/IBuild';
import {useHistory} from 'react-router-dom';
import buildSteps from './draftBuildStepsConfig';
import CustomButton from '../../../components/atoms/CustomButton';
import {provisionBuildGET} from '../../../api/ajax/builds';
import {useActiveStep} from './ActiveStepContext';
import {useDraftBuild} from './DraftBuildContext';
import {RootState} from '../../../store/reducers';
import {useDeviceStoreActions} from '../../../store/actions';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {useSmallScreenSize, usePermissionsForBuild} from '../../../utils/utilHooks';
import VerticalButton from '../../../components/atoms/VerticalButton';

const Button = styled(MuiButton)(spacing);

const StepNavigationButtons = () => {
  const {activeStepId} = useActiveStep();
  const isLastStep = activeStepId !== buildSteps.length - 1;

  return (
    <>
      <Grid item sm={4}>
        <PreviousStep />
      </Grid>
      <Grid item sm={8}>
        <Grid container alignItems="flex-start" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <SaveAndClose />
          </Grid>
          <Grid item>{isLastStep ? <NextStep /> : <SaveAndStart />}</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StepNavigationButtons;

export const SaveAndClose = ({isSimpleWorkflow}: {isSimpleWorkflow?: boolean}) => {
  const history = useHistory();
  const handleSaveAndGoBackClicked = () => history.push('/builds/');

  return (
    <Button color="primary" variant="outlined" onClick={handleSaveAndGoBackClicked} fullWidth={isSimpleWorkflow}>
      Save and Close
    </Button>
  );
};

export const PreviousStep = () => {
  const {activeStepId, handleStepChange} = useActiveStep();
  const isSmallScreen = useSmallScreenSize();

  const handlePreviousClicked = () => {
    if (activeStepId <= 0) return;

    handleStepChange(buildSteps[activeStepId - 1].id);
  };

  if (activeStepId === undefined || activeStepId <= 0) {
    if (isSmallScreen) return <div />;
    return <></>;
  }

  if (isSmallScreen)
    return (
      <VerticalButton onClick={() => handlePreviousClicked()} color="secondary">
        <ArrowBack />
        <Typography variant="caption">Previous</Typography>
      </VerticalButton>
    );

  return (
    <Button color="primary" variant="outlined" onClick={handlePreviousClicked}>
      <ArrowBack /> Previous
    </Button>
  );
};

export const NextStep = () => {
  const {activeStep, activeStepId, handleStepChange} = useActiveStep();
  const {isStepComplete} = useDraftBuild();
  const isSmallScreen = useSmallScreenSize();

  const handleNextClicked = () => {
    if (activeStepId >= buildSteps.length - 1) return;

    handleStepChange(buildSteps[activeStepId + 1].id);
  };

  if (activeStepId === undefined || activeStepId >= buildSteps.length - 1) return <></>;

  if (isSmallScreen)
    return (
      <VerticalButton onClick={() => handleNextClicked()} color="secondary">
        <ArrowForward />
        <Typography variant="caption">Next Step</Typography>
      </VerticalButton>
    );

  return (
    <Button color="primary" variant="contained" onClick={handleNextClicked} disabled={!isStepComplete(activeStep)}>
      Next Step <ArrowForward />
    </Button>
  );
};

export const useMachineStatusText = (draftBuild: IBuild) => {
  const device = useSelector((s: RootState) => s.deviceStore.byId[draftBuild.deviceSerial!]);
  const machine = useSelector((s: RootState) => s.machineStore.byId[draftBuild.machineUuid!]);
  const {machinePermission} = usePermissionsForBuild(draftBuild);

  if (!machine) return '';
  if (!device) return "Machine isn't associated with a device.";

  const deviceIsOnline = device?.online;
  const machineBusy = machine.availability !== DeviceAvailability.Available && device.buildUuid !== draftBuild.uuid;

  if (!machinePermission) return "You don't have permission to access the currently selected machine.";
  if (!deviceIsOnline) return 'Device is offline, please turn the device on.';
  if (machineBusy)
    return 'Machine is busy with another build, please complete operations and return when the machine is available.';

  return '';
};

export const SaveAndStart = ({isSimpleWorkflow}: {isSimpleWorkflow?: boolean}) => {
  const [requesting, setRequesting] = useState(false);
  const {activeStepId} = useActiveStep(isSimpleWorkflow);
  const {draftBuild, isComplete} = useDraftBuild();
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const savingDraft = useSelector((state: RootState) => state.draftBuild.saveState === 'saving');

  // Stay up to date with the current device state to determine if online or not
  const device = useSelector((s: RootState) => s.deviceStore.byId[draftBuild.deviceSerial!]);
  const machine = useSelector((s: RootState) => s.machineStore.byId[draftBuild.machineUuid!]);
  const deviceActions = useDeviceStoreActions();
  const machineStatusText = useMachineStatusText(draftBuild);

  useEffect(() => {
    if (draftBuild.deviceSerial) {
      deviceActions.ensureConsistent({serial: draftBuild.deviceSerial});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftBuild.deviceSerial]);

  const handleSubmitClicked = async () => {
    setRequesting(true);
    const res = await provisionBuildGET(draftBuild.uuid, {isSimpleWorkflow});
    if (res.success) {
      localStorage.setItem(`${draftBuild.uuid}-submitted-time`, String(Date.now()));
      history.push(`/builds/uuid/${draftBuild.uuid}`);
    } else {
      setRequesting(false);
    }
  };

  const deviceIsOnline = device?.online;
  const machineBusy = machine?.availability !== DeviceAvailability.Available;

  const tooltipTitle = !isComplete
    ? 'Draft not complete, please fill in all necessary information.'
    : machineStatusText;

  if (!isSimpleWorkflow && activeStepId !== buildSteps.length - 1) return <></>;

  if (isSmallScreen && !isSimpleWorkflow)
    return (
      <ConditionalTooltip tooltip={tooltipTitle} hideTooltip={isComplete && deviceIsOnline && !machineBusy}>
        <VerticalButton
          onClick={() => handleSubmitClicked()}
          color="secondary"
          disabled={!isComplete || !deviceIsOnline || machineBusy || savingDraft}
        >
          <CheckCircle />
          <Typography variant="caption">{isComplete ? 'Start' : 'Incomplete'}</Typography>
        </VerticalButton>
      </ConditionalTooltip>
    );

  return (
    <ConditionalTooltip tooltip={tooltipTitle} hideTooltip={isComplete && deviceIsOnline && !machineBusy}>
      <CustomButton
        color="primary"
        variant="contained"
        text={isComplete ? 'Save and Start' : 'Incomplete'}
        onClick={handleSubmitClicked}
        disabled={!isComplete || !deviceIsOnline || machineBusy || savingDraft}
        loading={requesting}
        fullWidth={isSimpleWorkflow}
      />
    </ConditionalTooltip>
  );
};
