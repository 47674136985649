import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Grid, TextField, CircularProgress} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import inflection from 'inflection';
import {isString, omit} from 'lodash';
import {FilterComponentProps} from '../FilterModal';
import ComparisonsSelector from './ComparisonSelector';
import {newDebouncer} from '../../../../api/ajax';
import styled from 'styled-components';
import {parseCurrentFilters} from './utils';

const debounceSearch = newDebouncer();

const enumComparisons = {
  in: 'Any of',
  notIn: 'None of',
};

type EnumComparison = keyof typeof enumComparisons;

type SelectOption = {value: string; label: string};

const ResourceFilter = ({
  field,
  control,
  label,
  searchGET,
  currentFilters,
  labelFn,
  searchField = 'name',
  valueField = 'uuid',
  placeholder,
  urlIdField,
  permanentComparison,
  onSubmit,
  excludeComparisons = [],
  filterListLocation,
}: FilterComponentProps) => {
  const initialFilter = parseCurrentFilters(currentFilters, field);
  const [internalValue, setInternalValue] = useState<Array<SelectOption>>([]);
  const [comparison, setComparison] = useState<EnumComparison>(
    ((permanentComparison ?? initialFilter[0]) as EnumComparison) || 'in'
  );

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<SelectOption>>([]);
  const urlParams = useParams<{[key: string]: any}>();

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSearch(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  useEffect(() => {
    control.register(field);

    const initialFetch = async () => {
      const params = {
        [valueField]: isString(currentFilters[field]) ? currentFilters[field] : currentFilters[field][comparison],
      };
      let urlIdParams;
      if (urlIdField) urlIdParams = urlParams[urlIdField];

      const response = urlIdParams ? await searchGET(urlIdParams, params) : await searchGET(params);

      if (response.success) {
        setInternalValue(
          response.data.map((record: any) => ({
            value: record[valueField],
            label: labelFn ? labelFn(record) : record[searchField],
          }))
        );

        control.setValue(field, {
          [comparison]: response.data.map((record: any) => record[valueField]),
        });
      }
    };

    if (currentFilters[field]) initialFetch();
    else setInternalValue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, field]);

  useEffect(() => {
    if (loading) {
      debounceSearch(async () => {
        if (open) {
          const params: {[key: string]: any} = {take: 5};
          if (search && search.length) params[searchField] = {like: search};
          if (internalValue)
            params[valueField] = {
              notIn: internalValue.map((option) => option.value),
            };

          let urlIdParams;
          if (urlIdField) urlIdParams = urlParams[urlIdField];
          const response = urlIdParams ? await searchGET(urlIdParams, params) : await searchGET(params);
          if (response.success) {
            setOptions(
              response.data.map((record: any) => ({
                value: record[valueField],
                label: labelFn ? labelFn(record) : record[searchField],
              }))
            );
          }
        }

        setLoading(false);
      }, 250);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, search]);

  const onChange = (options: any) => {
    const values = options.map((option: SelectOption) => option?.value);
    if (values.length) {
      control.setValue(field, {[comparison]: values});
    } else {
      control.setValue(field, undefined);
    }
    setInternalValue(options);
    submitField();
  };
  const onComparisonChanged = (newComparison: EnumComparison) => {
    const values = internalValue.map((option: SelectOption) => option?.value);
    setComparison(newComparison);
    if (values.length) {
      control.setValue(field, {[newComparison]: values});
    }
  };
  const onInputChange = (_event: any, value: any) => {
    setSearch(value);
    setLoading(true);
  };
  const onOpen = () => {
    setOpen(true);
    setLoading(true);
  };
  const onClose = () => {
    setOpen(false);
    setLoading(false);
    setOptions([]);
  };

  if (internalValue === undefined && currentFilters[field]) {
    return (
      <Grid container xs={12} justifyContent="center" style={{width: '100%'}}>
        <CircularProgress size={25} />
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <StyledGrid
        item
        xs={!permanentComparison ? 6 : 12}
        sm={!permanentComparison ? 8 : 12}
        hasPopupIcon={internalValue.length === 0 && !loading}
      >
        <Autocomplete
          multiple
          disableClearable
          id={`filter-search-${field}`}
          open={open}
          size="small"
          options={options.filter((option) => !internalValue?.some((selected) => selected.value === option.value))}
          loading={loading}
          value={internalValue}
          getOptionLabel={(option: any) => option.label}
          getOptionSelected={(option: any, value: any) => option.value === value.value}
          onChange={(_event: any, options: any) => onChange(options)}
          onInputChange={onInputChange}
          onOpen={onOpen}
          onClose={onClose}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={inflection.capitalize(label || field)}
              placeholder={placeholder || `Search ${inflection.pluralize(inflection.capitalize(label || field))}`}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : internalValue.length ? null : (
                      params.InputProps.endAdornment
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </StyledGrid>
      {!permanentComparison && (
        <Grid item xs={6} sm={4}>
          <ComparisonsSelector
            comparisons={
              omit(enumComparisons, excludeComparisons) as {
                [key: string]: string;
              }
            }
            currentComparison={comparison}
            setCurrentComparison={onComparisonChanged}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ResourceFilter;

const StyledGrid = styled(Grid)<{hasPopupIcon: boolean}>`
  .MuiAutocomplete-hasPopupIcon {
    .MuiInputBase-root {
      ${({hasPopupIcon}) => !hasPopupIcon && 'padding-right: 12px;'}
    }
  }
`;
