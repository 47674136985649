import React from 'react';
import {SensorProfileState} from '@common/api/models/devices/ISensorProfile';
import {humanize} from 'inflection';
import {ChipStatus, StatusVariant} from '../../../../components/atoms/Status/Status';

function ValidationStatusChip({
  hasUnsavedChanges,
  state,
  isNew,
}: {
  hasUnsavedChanges: boolean;
  state: SensorProfileState;
  isNew: boolean;
}) {
  function validationChipColor(): StatusVariant {
    if (isNew) return 'secondary';
    if (hasUnsavedChanges) return 'none';

    switch (state) {
      case SensorProfileState.VALIDATING:
        return 'info';
      case SensorProfileState.VALID:
        return 'success';
      case SensorProfileState.INVALID:
        return 'error';
    }
  }

  function validationChipText() {
    if (isNew) return 'New';
    if (hasUnsavedChanges) return 'Unsaved';

    return humanize(state);
  }

  return <ChipStatus chipSize="small" message={validationChipText()} variant={validationChipColor()} />;
}

export default ValidationStatusChip;
