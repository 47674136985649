import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {TimeFilter, makeLastXDaysFilter} from '@common/utils/timeFilter';
import moment from 'moment';
import {Grid, Box, Typography, CircularProgress} from '@material-ui/core';
import {IMetricsResponse} from '@common/api/models/metrics';
import {useOrganizationStoreActions} from '../../store/actions/index';
import {RootState} from '../../store/reducers/index';
import DashboardTimeFilter from './Analytics/DashboardTimeFilter';
import {metricsGET} from '../../api/ajax/metrics';
import BuildStatus from './Analytics/BuildStatus';
import MachineUtilization from './Analytics/MachineUtilization';
import AnalysedCounts from './Analytics/AnalysedCounts';
import ActiveBuildsWidget from './Analytics/ActiveBuildsWidget';
import SwitchableChart from './Analytics/SwitchableChart';
import {useSmallScreenSize} from '../../utils/utilHooks';
import Header from '../../components/organisms/Header';
import {renderDateString} from '../../utils/string';

function DashboardPage() {
  const isSmallScreen = useSmallScreenSize();
  const authUser = useSelector((s: RootState) => s.auth.user);
  const userUuid = authUser?.uuid;

  const user = useSelector((state: RootState) => state.userStore.byId[userUuid as string]);
  const organizationID = user ? user.organizationUuid : '';

  const organization = useSelector((state: RootState) => state.organizationStore.byId[organizationID]);
  const organizationStoreActions = useOrganizationStoreActions();

  const userFirstName = user ? user.firstName : authUser?.firstName;
  const organizationText = organization ? organization.name : '';

  useEffect(() => {
    if (organizationID) {
      organizationStoreActions.ensureConsistent({uuid: organizationID});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationID]);

  const [timeFilter, setTimeFilter] = useState<TimeFilter>(makeLastXDaysFilter(30));
  const currentDateTime = renderDateString('descriptive', new Date());

  return (
    <>
      <Header
        helmet="Analytics Dashboard"
        title={
          <Box>
            <Typography variant="h4" display="inline">
              Welcome{userFirstName ? ` back, ${userFirstName}` : '.'}
            </Typography>
            <Typography
              variant="body2"
              style={isSmallScreen ? {} : {marginLeft: '12px'}}
              display={isSmallScreen ? undefined : 'inline'}
            >
              {currentDateTime}
            </Typography>
          </Box>
        }
        breadcrumbs={organization ? organizationText : 'Loading Organization...'}
        endAdornment={
          isSmallScreen ? undefined : <DashboardTimeFilter timeFilter={timeFilter} setTimeFilter={setTimeFilter} />
        }
      />
      {isSmallScreen && (
        <Grid justifyContent="space-between" container spacing={6}>
          <Grid item {...(isSmallScreen ? {xs: 12, sm: 12} : {})}>
            <DashboardTimeFilter timeFilter={timeFilter} setTimeFilter={setTimeFilter} />
          </Grid>
        </Grid>
      )}

      <MetricCards timeFilter={timeFilter} />
    </>
  );
}

export default DashboardPage;

function MetricCards({timeFilter}: {timeFilter: TimeFilter}) {
  const [metrics, setMetrics] = useState<IMetricsResponse | null>(null);

  useEffect(() => {
    async function fetchMetrics() {
      const res = await metricsGET(timeFilter.from ? {start: {since: moment.utc(timeFilter.from).format()}} : {});

      if (res.success) {
        setMetrics(res.data);
      }
    }

    fetchMetrics();
    return () => setMetrics(null);
  }, [timeFilter]);

  if (!metrics)
    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Typography variant="h3" style={{marginBottom: '32px'}}>
          Loading Dashboard...
        </Typography>
        <CircularProgress />
      </Box>
    );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <BuildStatus buildStateCount={metrics.buildStateCount} timeFilter={timeFilter} />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <MachineUtilization machineIdleTime={metrics.machineIdleTime} />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <AnalysedCounts partsCount={metrics.partsCount} buildsCount={metrics.completedBuildDurations.length} />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <ActiveBuildsWidget />
      </Grid>

      <Grid item xs={12} sm={12} md={8}>
        <SwitchableChart metrics={metrics} />
      </Grid>
    </Grid>
  );
}
