import React from 'react';
import {Box, Typography, Chip} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {inflect} from 'inflection';
import {RootState} from '../../../store/reducers';
import {useSimilarityReportStoreActions} from '../../../store/actions';
import {successColor} from '../../../assets/jss/material-dashboard-react';
import PartChips from '../shared/PartChips';

const SelectedTargetParts = () => {
  const targetParts = useSelector((state: RootState) => state.similarityReportStore.newTargetParts);
  const currentTargetParts = useSelector((state: RootState) => state.similarityReportStore.currentTargetPartUuids);
  const numberOfCurrentParts = Object.keys(currentTargetParts || {}).length;

  const {setNewTargetParts} = useSimilarityReportStoreActions();

  return (
    <Box display="flex" alignItems="center" overflow="auto">
      <Typography variant="h5" style={{marginRight: '12px'}}>
        Selected Target Parts:
      </Typography>
      <Chip
        label={`${!!currentTargetParts ? numberOfCurrentParts : 'Loading'} Current ${inflect(
          'Part',
          numberOfCurrentParts || 0
        )}`}
        style={{
          backgroundColor: successColor[0],
          color: '#fff',
          marginRight: '12px',
        }}
      />
      <PartChips parts={targetParts || []} setParts={(newParts) => setNewTargetParts(newParts)} />
    </Box>
  );
};

export default SelectedTargetParts;
