import {cloneDeep, range, reduce} from 'lodash';
import {assertUnreachable} from '@common/utils/utils';
import {RollingMethod} from '../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {SelectedDefectSummary} from '../pages/builds/liveBuild/activeBuildPages/useDefectSummaries';
import {alpha} from '@material-ui/core';
import {humanize} from 'inflection';

const calcAverage = (numbers: number[]) => {
  return reduce(numbers, (a, b) => a + b, 0) / (numbers.length || 1);
};

const calcMedian = (numbers: number[]) => {
  const mid = Math.floor(numbers.length / 2),
    nums = [...numbers].sort((a, b) => a - b);
  return numbers.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const calcMax = (numbers: number[]) => {
  return Math.max(...numbers);
};

const calcMin = (numbers: number[]) => {
  return Math.min(...numbers);
};

const getRidOfNulls = (numbers: (number | null)[]) => {
  return numbers.map((n) => n ?? 0);
};

const trimNumber = (number: number | null) => {
  return number ? Number(number.toFixed(2)) : 0;
};

/**
 * Generates x and y coordinates depicting a Rolling Average Line, given an input array containing raw data
 * @param inputArray Array containing the Raw data
 * @param startLayer Starting layer of the Part
 * @param rollingPeriod Rolling Period of the Rolling Average line
 * @param averageType Rolling Function (Average, Median, Min, Max)
 */

export function calculateRollingAverages(
  inputArray: (number | null)[],
  layerNumbers: number[],
  rollingPeriod: number,
  averageType: RollingMethod
) {
  let averagingFunction: (numbers: number[]) => number = calcAverage;
  switch (averageType) {
    case RollingMethod.average:
      averagingFunction = calcAverage;
      break;
    case RollingMethod.max:
      averagingFunction = calcMax;
      break;
    case RollingMethod.min:
      averagingFunction = calcMin;
      break;
    case RollingMethod.median:
      averagingFunction = calcMedian;
      break;
    case RollingMethod.minMax:
      return {
        x: [],
        y: [],
      };
    default:
      assertUnreachable(averageType);
  }

  const layerIndices = range(layerNumbers[0] + rollingPeriod / 2, inputArray.length - rollingPeriod / 2);
  const rollingAverages = layerIndices.map((idx) => {
    const thisRange = getRidOfNulls(inputArray.slice(idx - rollingPeriod / 2, idx + rollingPeriod / 2));
    return trimNumber(averagingFunction(thisRange));
  });

  const layersX = layerNumbers.slice(rollingPeriod / 2, layerNumbers.length - rollingPeriod / 2);

  return {x: layersX, y: rollingAverages};
}

function calculateMinMax(inputArray: (number | null)[], layerNumbers: number[], rollingPeriod: number) {
  const minLine = calculateRollingAverages(inputArray, layerNumbers, rollingPeriod, RollingMethod.min);
  const maxLine = calculateRollingAverages(inputArray, layerNumbers, rollingPeriod, RollingMethod.max);
  const averageLine = calculateRollingAverages(inputArray, layerNumbers, rollingPeriod, RollingMethod.average);

  return {
    minLine: minLine.y,
    maxLine: maxLine.y,
    averageLine: averageLine.y,
    layerIndices: averageLine.x,
  };
}

export const generateGraph = (
  defect: SelectedDefectSummary,
  globalGraphPrefix: string,
  useCoverage: boolean,
  x?: number[],
  y?: (number | null)[]
): Partial<Plotly.PlotData> => {
  const settingsType = !x ? 'dataLineSettings' : 'trendLineSettings';

  const hoverPrefix =
    settingsType === 'dataLineSettings'
      ? ''
      : defect.graphConfig.useOverrides
      ? defect.graphConfig.rollingDataConfig.rollingMethod + ' '
      : globalGraphPrefix + ' ';

  return {
    name: `${defect.partName}`,
    x: x ?? defect.areaGraph.x,
    y: useCoverage ? y ?? defect.coverageGraph.y : y ?? defect.areaGraph.y,
    line: {
      color: defect.graphConfig[settingsType].lineColor,
      width: defect.graphConfig[settingsType].lineWeight,
      dash: defect.graphConfig[settingsType].lineType,
    },
    hovertemplate: useCoverage
      ? `
<b>${defect.partName} - ${getPrettyDefectType(defect.defectType)}</b>
<br>
${humanize(hoverPrefix)}Coverage: %{y}%
<extra></extra>`
      : `
<b>${defect.partName} - ${getPrettyDefectType(defect.defectType)}</b>
<br>
${humanize(hoverPrefix)}Area: %{y} mm²
<extra></extra>`,
  };
};

export const generatePartGraph = (defect: SelectedDefectSummary): Partial<Plotly.PlotData> => {
  return {
    ...defect.partAreaGraph,
    name: `${defect.partName}`,
    fill: 'tozeroy',
    fillcolor: alpha(defect.graphConfig.trendLineSettings.lineColor, 0.25),
    line: {
      color: defect.graphConfig.trendLineSettings.lineColor,
    },
    hovertemplate: `
<b>${defect.partName} - ${getPrettyDefectType(defect.defectType)}</b>
<br>
Part Area: %{y} mm²
<extra></extra>`,
  };
};

export const generateMinMaxGraphs = (
  defect: SelectedDefectSummary,
  globalGraphPrefix: string,
  useCoverage: boolean,
  rollingPeriod: number,
  showOriginalData?: boolean
): Partial<Plotly.PlotData>[] => {
  const originalGraphData = generateGraph(defect, globalGraphPrefix, useCoverage);
  const minMaxResults = calculateMinMax(
    originalGraphData.y as Array<number>,
    originalGraphData.x as number[],
    rollingPeriod
  );

  return [
    {
      x: [
        ...minMaxResults.layerIndices,
        ...cloneDeep(minMaxResults.layerIndices).reverse(),
        minMaxResults.layerIndices[0],
      ],
      y: [...minMaxResults.minLine, ...cloneDeep(minMaxResults.maxLine).reverse(), minMaxResults.minLine[0]],
      text: [
        ...cloneDeep(minMaxResults.maxLine).map((minMax) => minMax.toString()),
        ...minMaxResults.minLine.reverse().map((minMax) => minMax.toString()),
        minMaxResults.minLine[0].toString(),
      ],
      fill: 'toself',
      // @ts-ignore
      hoveron: 'points+fills',
      fillcolor: alpha(defect.graphConfig.trendLineSettings.lineColor, 0.3),
      line: {color: 'transparent'},
      name: `${defect.partName} Min-Max`,
      hoverinfo: 'x+y+text',
      hovertemplate: `
<b>${defect.partName} - ${getPrettyDefectType(defect.defectType)}</b>
<br>
Min: %{text}${useCoverage ? ' %' : ''}
<br>
Max: %{y}${useCoverage ? ' %' : ''}
<extra></extra>`,
    },

    {
      x: minMaxResults.layerIndices,
      y: minMaxResults.averageLine,
      line: {
        color: defect.graphConfig.trendLineSettings.lineColor,
        width: defect.graphConfig.trendLineSettings.lineWeight,
        dash: defect.graphConfig.trendLineSettings.lineType,
      },
      name: `${defect.partName} Average`,
      hoverinfo: 'x+y+text',
      hovertemplate: `
<b>${defect.partName} - ${getPrettyDefectType(defect.defectType)}</b>
<br>
Average ${useCoverage ? 'Coverage' : 'Area'}: %{y} mm²${useCoverage ? ' %' : ''}
<extra></extra>`,
    },
    showOriginalData ? originalGraphData : {},
  ];
};
