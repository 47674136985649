import {InputAdornment, TextField} from '@material-ui/core';
import {ColorPicker} from 'material-ui-color';
import React, {useEffect, useState} from 'react';

interface MaterialColorPickerProps {
  title: string;
  currentValue: string;
  onChange: (color: string) => void;
}

export const MaterialColorPicker = (props: MaterialColorPickerProps) => {
  const [internalValue, setInternalValue] = useState<string>(props.currentValue);

  useEffect(() => {
    if (props.currentValue !== internalValue) {
      if (/^#[0-9A-F]{6}$/i.test(internalValue)) {
        props.onChange(internalValue.replace('#', ''));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  useEffect(() => {
    if (props.currentValue !== internalValue) setInternalValue(props.currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentValue]);

  return (
    <TextField
      variant="outlined"
      style={{
        width: '100%',
        margin: '10px 0px',
      }}
      label={props.title}
      InputLabelProps={{
        style: {color: 'white'},
      }}
      value={internalValue}
      onChange={(event) => {
        if (event.target.value) {
          setInternalValue(event.target.value);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ColorPicker
              hideTextfield
              value={props.currentValue}
              disableAlpha
              onChange={(newColor) => props.onChange(newColor.hex)}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
