import React, {useState} from 'react';
import {Box, IconButton, Tooltip, CircularProgress, Chip, Typography} from '@material-ui/core';
import {Delete, CloudDownload} from '@material-ui/icons';

import {IBuildReportGETResponse, BuildReportStatus} from '@common/api/models/builds/data/IBuildReport';

import {ChipStatus} from '../../../../components/atoms/Status/Status';
import {downloadUrl} from '../../../../utils/webtools';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import {buildReportDownloadUrlGET} from '../../../../api/ajax/buildReport';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {inflect} from 'inflection';

interface BuildReportRow extends IBuildReportGETResponse {
  onDelete: () => void;
}

const buildReportTableSchema = [
  {
    title: 'Build Name',
    field: 'buildName',
    width: 'auto',
    render: (row: BuildReportRow) => {
      return <EllipsisTextColumnTooltip>{row.buildName}</EllipsisTextColumnTooltip>;
    },
  },
  {
    title: 'Status',
    field: 'status',
    width: '112px',
    render: (row: BuildReportRow) => {
      switch (row.status) {
        case BuildReportStatus.Submitted:
          return <ChipStatus message="Submitted" variant="info" />;
        case BuildReportStatus.Generating:
          return <ChipStatus message="Generating" variant="info" />;
        case BuildReportStatus.Success:
          return <ChipStatus message="Complete" variant="success" />;
        case BuildReportStatus.Failure:
          return <ChipStatus message="Failed" variant="error" />;
        default:
          return <></>;
      }
    },
  },
  {
    title: 'Report sections',
    field: 'reportConfiguration',
    width: 'auto',
    sortable: false,
    render: ({selectedPartNames, reportConfiguration: {includedParts}}: BuildReportRow) => {
      return (
        <Box>
          <ConditionalTooltip
            tooltip={
              <Box>
                {selectedPartNames.map((name) => (
                  <Typography>{name}</Typography>
                ))}
              </Box>
            }
            hideTooltip={!includedParts || !includedParts.length}
          >
            <Chip
              color="primary"
              size="small"
              label={includedParts ? `${includedParts.length} ${inflect('parts', includedParts.length)}` : 'All parts'}
              style={{marginTop: '6px', marginRight: '6px'}}
            />
          </ConditionalTooltip>
        </Box>
      );
    },
  },
  {
    title: 'Created at',
    field: 'createdAt',
    width: '132px',
    render: (row: BuildReportRow) => <DateWithTimeTooltip timestamp={row.createdAt} />,
  },
  {
    title: 'Build end',
    field: 'buildEndDate',
    width: '132px',
    render: (row: BuildReportRow) => <DateWithTimeTooltip timestamp={row.buildEndDate} />,
  },
  {
    title: 'Actions',
    sorting: false,
    width: '112px',
    render: (row: BuildReportRow) => <ReportActions row={row} />,
  },
];

export default buildReportTableSchema;

const ReportActions = ({row}: {row: BuildReportRow}) => {
  const [requesting, setRequesting] = useState(false);

  const handleDownload = async () => {
    if (requesting) return;

    setRequesting(true);
    const url = await buildReportDownloadUrlGET(row.uuid);

    if (!url.success) {
      setRequesting(false);
      return;
    }

    downloadUrl(url.data.url, `${row.buildName}-report.pdf`);
    setRequesting(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      {row.status === BuildReportStatus.Success && (
        <Tooltip title="Download report">
          <IconButton
            aria-label="download"
            onClick={(e) => {
              e.stopPropagation();
              handleDownload();
            }}
          >
            {requesting ? <CircularProgress size={20} /> : <CloudDownload />}
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="Remove">
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            row.onDelete();
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
