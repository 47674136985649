import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Role} from '@common/api/models/users/IUser';
import {pages} from '../viewMachineSections/MachinePageTabs';
import {RootState} from '../../../store/reducers';

export default function useMachine() {
  const {uuid, page} = useParams<{uuid: string; page: string}>();
  const machine = useSelector((state: RootState) => state.machineStore.byId[uuid]);
  const deviceStore = useSelector((state: RootState) => state.deviceStore);
  const device = deviceStore.byMachineUuid[uuid];

  const currentUser = useSelector((state: RootState) => state.auth.user!);

  const filteredPages = currentUser.role < Role.MANAGER ? pages.filter((page) => page.id !== 'permissions') : pages;

  const activePageIndex = filteredPages.findIndex((p) => p.id === page);
  const activePage = filteredPages[activePageIndex];

  return {machine, uuid, device, activePageIndex, activePage, filteredPages};
}
