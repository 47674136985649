import moment from 'moment';

// Does't appear to be a class validaton library that makes per field validation available, for, say, frontend forms.
// It's not that hard to roll it out, with the benefit of full control.

export interface ValidationSuccess {
  success: true;
}

export interface ValidationFailure<T extends string> {
  success: false;
  errors: T[];
}

export type ValidationResult<T extends string> = ValidationSuccess | ValidationFailure<T>;

/**
 * A function used to validate a value and return a ValidationResult.
 */
export type ValidateFn<T, U extends string> = (value: T, isOnPremise?: boolean) => ValidationResult<U>;

export function combineValidation<T extends string>(...validations: ValidationResult<T>[]): ValidationResult<T> {
  return validations.reduce(
    (prev, curr) => {
      if (prev.success === false && curr.success === false) {
        return {success: false, errors: [...prev.errors, ...curr.errors]};
      } else if (prev.success === false) {
        return prev;
      } else {
        return curr;
      }
    },
    {success: true}
  );
}

/**
 * Generate validation results for validation functions inside models.
 * @template T - Generic that should extend `string`
 * @param isSuccess {boolean} - Whether the validation was a success or not
 * @param errors {T[]} - An array of error messages
 */
export function generateValidationResult<T extends string>(isSuccess: boolean, errors: T[] = []): ValidationResult<T> {
  const validationResult = {success: isSuccess};
  if (!isSuccess) {
    return {...validationResult, errors} as ValidationFailure<T>;
  }
  return validationResult as ValidationSuccess;
}

/**
 * Validate if a value conforms to a certain datetime format string.
 * @param value {moment.MomentInput} - Value that you want to validate
 * @param formatString {moment.MomentFormatSpecification} - Format string of the datetime format
 */
export function validateDateTime(value: moment.MomentInput, formatString?: moment.MomentFormatSpecification): boolean {
  return moment(value, formatString, true).isValid();
}

/**
 * Helper function that determines if a value is valid.
 *
 * Also has an `onError` handler.
 * @template T - Type for the input value
 * @template U - Type for the error output values. Must extend `string` type.
 * @param validationFn {ValidateFn<T, U>} - Validation function that returns a `ValidationResult<U>`
 * @param value {T} - Input value to validate value
 * @param onError {(error: U) => {}} - Function to run when an error occurs.
 */
export function validator<T, U extends string>(
  validationFn: ValidateFn<T, U>,
  value: T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: U) => any
): boolean {
  const validationResult = validationFn(value);
  if (!validationResult.success && onError) {
    const errorMessage = (validationResult as ValidationFailure<U>).errors[0];
    onError(errorMessage);
  }
  return validationResult.success;
}
