import * as React from 'react';
import {ModalProps, useTheme} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {MachineRow, MachinesTable} from '../Table/MachinesTable';
import {useEffect, useState} from 'react';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {GenericDialog} from '../DialogButton';

export interface MachineSelectorModalProps extends Partial<ModalProps> {
  open: boolean;
  initiallySelectedUuid?: string;
  onMachineSelected: (machine: IMachine | null) => any;
  machineFilter?: (machine: IMachine) => boolean;
  excludeColumns?: (keyof MachineRow)[];
  onClose: () => void;
}

function MachineSelectorModal({
  initiallySelectedUuid,
  open,
  onClose,
  excludeColumns,
  onMachineSelected,
}: MachineSelectorModalProps) {
  const theme = useTheme();
  const machines = useSelector((state: RootState) => state.machineStore.list);
  const [selectedMachineUuid, setSelectedMachineUuid] = useState<string | undefined>(initiallySelectedUuid);

  useEffect(() => {
    if (initiallySelectedUuid && !selectedMachineUuid) setSelectedMachineUuid(initiallySelectedUuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiallySelectedUuid]);

  const onSubmit = () => {
    if (selectedMachineUuid) {
      onMachineSelected(machines.find((m) => m.uuid === selectedMachineUuid)!);
    } else {
      onMachineSelected(null);
    }
    onClose();
  };

  const handleRowClick = (rowData: MachineRow | undefined) => {
    setSelectedMachineUuid(rowData?.uuid);
  };

  return (
    <GenericDialog
      title="Select a Machine"
      maxWidth="lg"
      isOpen={open}
      closeDialog={onClose}
      onSuccess={onSubmit}
      additionalConfirms={[
        {
          onSuccess: () => setSelectedMachineUuid(undefined),
          confirmText: 'Clear selection',
          confirmDisabled: !selectedMachineUuid,
        },
      ]}
      content={
        <MachinesTable
          exclude={excludeColumns}
          onRowClick={(_e, rowData) => handleRowClick(rowData as MachineRow | undefined)}
          tableOptions={{
            rowStyle: (machineData) =>
              selectedMachineUuid === machineData.uuid
                ? {
                    backgroundColor: blue['50'],
                    color: theme.palette.common.black,
                  }
                : {},
          }}
        />
      }
    />
  );
}

export default MachineSelectorModal;
