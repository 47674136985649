import React, {useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {Redirect} from 'react-router-dom';

/* Store */
import {useMachineStoreActions} from '../../store/actions';

/* Sections */
import AttachedDevice from './viewMachineSections/AttachedDevice';
import MachineInfoCard from './viewMachineSections/MachineInfoCard';
import BuildsHistoryWidget from './viewMachineSections/BuildsHistoryWidget';
import MachinePageTabs from './viewMachineSections/MachinePageTabs';
import useMachine from './hooks/useMachine';
import {useBuildStoreActions, useDeviceStoreActions} from '../../store/actions';
import Header from '../../components/organisms/Header';
import LoadingPage from '../../components/organisms/LoadingPage';

export default function ViewMachine() {
  const {machine, uuid, activePage} = useMachine();
  const machineStoreActions = useMachineStoreActions();
  const buildActions = useBuildStoreActions();
  const deviceActions = useDeviceStoreActions();

  useEffect(() => {
    if (uuid) {
      machineStoreActions.ensureConsistent({uuid});
      deviceActions.ensureConsistent({machineUuid: uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      buildActions.ensureConsistent({machineUuid: uuid, includeDeleted: true});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  if (!activePage) return <Redirect to={'/machines/uuid/' + uuid + '/general/'} />;
  if (!machine) return <LoadingPage loadingText="Loading Machine" />;

  return (
    <>
      <Header
        helmet={`${machine.name} Machine`}
        title={machine.name}
        breadcrumbs={[{title: 'Machines', path: '/machines'}, machine.name]}
      />

      <Grid container spacing={6}>
        <Grid item xs={12} md={4} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MachineInfoCard />
            </Grid>
            <Grid item xs={12}>
              <AttachedDevice />
            </Grid>
            <Grid item xs={12}>
              <BuildsHistoryWidget />
            </Grid>
          </Grid>
        </Grid>
        <MachinePageTabs />
      </Grid>
    </>
  );
}
