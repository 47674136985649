import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import NotificationRecipients from '../../shared/DefectNotifications/NotificationRecipients';
import DefectNotificationFilters from '../../shared/DefectNotifications/DefectNotificationFilters';
import {useDraftBuild} from '../DraftBuildContext';
import {useSmallScreenSize} from '../../../../utils/utilHooks';

const NotificationStep = () => {
  const isSmallScreen = useSmallScreenSize();
  const {draftBuild} = useDraftBuild();

  return (
    <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="space-between">
      <Grid item xs={12}>
        <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Step 6: Notifications</Typography>
      </Grid>

      <NotificationRecipients build={draftBuild} />
      <DefectNotificationFilters build={draftBuild} />
    </Grid>
  );
};

export default NotificationStep;
