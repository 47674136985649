import React from 'react';
import {DefectsSummaryRow} from '../../../builds/liveBuild/activeBuildPages/DefectsPage';
import {EllipsisTextColumnTooltip} from './sharedColumns';
import {getPrettyDefectType, stringToDefectType} from '@common/api/models/builds/data/defects/IDefect';

const defectSummaryTableSchema = [
  {
    title: 'Type',
    field: 'defectType',
    width: '132px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectsSummaryRow) => getPrettyDefectType(stringToDefectType(row.defectType)),
  },
  {
    title: 'Part',
    field: 'partName',
    width: '168px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectsSummaryRow) => (
      <EllipsisTextColumnTooltip>{row.partName ?? 'All Parts'}</EllipsisTextColumnTooltip>
    ),
    sorting: false,
  },
  {
    title: 'Area (mm²)',
    field: 'defectArea',
    type: 'numeric',
    align: 'right',
    width: '132px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectsSummaryRow) =>
      row.defectArea
        ? `${new Intl.NumberFormat('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(row.defectArea)}`
        : '-',
  },
  {
    title: 'Coverage (%)',
    field: 'defectCoverage',
    type: 'numeric',
    align: 'right',
    width: '132px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectsSummaryRow) =>
      row.defectCoverage
        ? `${new Intl.NumberFormat('en', {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          }).format(row.defectCoverage)}%`
        : '-',
  },
];

export default defectSummaryTableSchema;
