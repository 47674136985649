import React, {useEffect, useRef, useState} from 'react';
import {Grid} from '@material-ui/core';
import {Alert, Skeleton} from '@material-ui/lab';
import useImage from 'use-image';
import {ICalibration} from '@common/api/models/devices/ICalibration';
import {calibrationPreviewUrlGET} from '../../../../../api/ajax/calibrations';
import {SelfManagedSingleImageViewport} from '../../../../../components/molecules/Viewport/2D/SelfManagedSingleImageViewport';
import {defaultImageFilters, ImageFilter} from '../../../../../components/molecules/Viewport/2D/imageutils';
import ImageSettings from '../../../../../components/molecules/Viewport/2D/controls/ImageSettings';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../../../../../components/molecules/Viewport/2D/utils';
import {isTouchDevice} from '../../../../../utils/webtools';

const CalibrationViewport = ({latestCalibration, height}: {latestCalibration: ICalibration; height: number}) => {
  const isTouchScreen = isTouchDevice();
  const [calibrationImageUrl, setCalibrationImageUrl] = useState<string>('');
  const [loadingImageUrl, setLoadingImageUrl] = useState(false);
  const [imageFilter, setImageFilter] = useState<ImageFilter>(defaultImageFilters);
  const [mouseOn, setMouseOn] = useState(isTouchScreen);
  const [calibrationImage, loaded] = useImage(calibrationImageUrl, 'anonymous');
  const viewportRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!latestCalibration) return;
    setLoadingImageUrl(true);

    calibrationPreviewUrlGET(latestCalibration.uuid).then((resp) => {
      if (resp.success && resp.data?.url) {
        setCalibrationImageUrl(resp.data.url);
      }
      setLoadingImageUrl(false);
    });
  }, [latestCalibration]);

  useEffect(() => {
    if (calibrationImage && loaded === 'loaded') {
      viewportRef?.current?.scrollIntoView();
    }
  }, [calibrationImage, loaded]);

  const onMouseEnter = () => {
    setMouseOn(true);
  };

  const onMouseLeave = () => {
    setMouseOn(false);
  };

  if ((!!calibrationImageUrl && loaded === 'loading') || loadingImageUrl) {
    return (
      <>
        <Grid item xs={12}>
          <Alert severity="info">Downloading calibration image...</Alert>
        </Grid>
        <Skeleton variant="rect" height={height - 80} style={{marginTop: '1rem'}} />
      </>
    );
  }

  if (!calibrationImageUrl || !calibrationImage) {
    return (
      <Grid item xs={12}>
        <Alert severity="warning">No calibration preview available for this build.</Alert>
      </Grid>
    );
  }

  return (
    <Grid
      item
      xs={12}
      ref={viewportRef}
      style={{maxWidth: '100%', width: '100%'}}
      onMouseEnter={isTouchScreen ? undefined : onMouseEnter}
      onMouseLeave={isTouchScreen ? undefined : onMouseLeave}
      onMouseMove={isTouchScreen ? undefined : onMouseEnter}
    >
      <SelfManagedSingleImageViewport
        image={calibrationImage}
        mmPerPixel={latestCalibration.scale}
        imageWidthPixels={calibrationImage.width}
        imageHeightPixels={calibrationImage.height}
        height={height}
        imageFilter={imageFilter}
        mouseOn={mouseOn}
        setMouseOn={isTouchScreen ? (_: boolean) => {} : setMouseOn}
      >
        <ImageSettings
          divPositionStyle={{
            left: BUTTON_MARGIN * 2,
            top: height - BUTTON_HEIGHT,
            position: 'absolute',
          }}
          imageFilter={imageFilter}
          setImageFilter={setImageFilter}
          mouseOn={mouseOn}
        />
      </SelfManagedSingleImageViewport>
    </Grid>
  );
};

export default CalibrationViewport;
