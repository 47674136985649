import * as React from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';

export function EmailConfirmed() {
  toast('Email confirmed!', {type: 'success'});

  const history = useHistory();
  history.replace('/');

  return <Redirect to={'/dashboard'} />;
}
