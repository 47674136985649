export enum ThemeActions {
  SET_THEME = 'SET_THEME',
}

export interface ThemeState {
  currentTheme: number;
}

export const initialThemeState: ThemeState = {
  currentTheme: 0,
};

interface ThemeActionType<_T, P> {
  type: ThemeActions;
  payload: P;
}

export type ThemeAction = ThemeActionType<ThemeActions.SET_THEME, number>;
