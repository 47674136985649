import {DeviceAvailability} from '@common/api/models/devices/IDevice';
import {devicesAllGET} from '../../../../api/ajax/devices';
import {FiltersState} from '../IndexPage';
import {FilterSchemaType} from './index';
import {getAllModels} from '@common/api/models/devices/machines/Machine.utils';

export const machineDefaultFilters: Partial<FiltersState> = {
  sortBy: {
    availability: 'DESC',
  },
};

const modelEnums = getAllModels();
const modelEnumLabels = getAllModels(true);

export const machineFilterSchema: Array<FilterSchemaType> = [
  {
    field: 'availability',
    type: 'enumMultiple',
    excludeComparisons: ['notIn'],
    enumValues: Object.values(DeviceAvailability),
  },
  {
    field: 'name',
    type: 'string',
  },
  {
    field: 'model',
    type: 'enumMultiple',
    enumValues: modelEnums,
    enumLabels: modelEnums.reduce((acc, model, index) => ({...acc, [model]: modelEnumLabels[index]}), {}),
  },
  {
    field: 'deviceUuid',
    searchField: 'deviceId',
    type: 'resourceSelect',
    label: 'device',
    searchGET: devicesAllGET,
  },
  {
    field: 'registered',
    type: 'dateRange',
  },
];
