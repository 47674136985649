import React from 'react';
import {TextField, MenuItem} from '@material-ui/core';

import {Control, Controller, get} from 'react-hook-form';
import inflection from 'inflection';
import {IDefectNotificationConditions} from '@common/api/models/builds/data/defects/IDefectNotifications';

type FormInputProps = {
  control: Control<IDefectNotificationConditions>;
  errors: any;
  name: string;
  label?: string;
};

export function SelectFormInput({
  control,
  errors,
  name,
  options,
  label,
}: FormInputProps & {options: Array<[string, string]>}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{required: true}}
      render={({onChange, value, ref}) => {
        return (
          <TextField
            select
            fullWidth
            size="small"
            style={{width: '200px'}}
            variant="outlined"
            label={label ? `${label}*` : `${inflection.humanize(inflection.underscore(name))}*`}
            inputRef={ref}
            value={value}
            error={!!get(errors, name)}
            onChange={(event) => onChange(event.target.value)}
          >
            {options.map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
}

const positiveNumberOrEmpty = /^(0|[1-9]\d*)(\.+\d*)?$/;
const maxFloat = 9e36;

export function FloatFormInput({control, errors, name, max, label}: FormInputProps & {max?: number; label?: string}) {
  function onFloatChanged(newValue: string) {
    if (!newValue) return undefined;
    if (!newValue.match(positiveNumberOrEmpty)) return;

    return parseFloat(newValue);
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{
        required: true,
        max: {
          value: max || maxFloat,
          message: `${max === 100 ? 'Must be between 0-100%' : 'Number too large!'}`,
        },
        pattern: {
          value: positiveNumberOrEmpty,
          message: 'Must be a positive number',
        },
        validate: {
          positive: (v) => {
            if (parseFloat(v) >= 0.01) return;
            return 'Must be a number greater than 0.01';
          },
        },
      }}
      render={({onChange, value, ref}) => {
        return (
          <TextField
            size="small"
            type="number"
            variant="outlined"
            style={{width: '200px', marginRight: '6px'}}
            label={label ? `${label}*` : `${inflection.humanize(inflection.underscore(name))}`}
            inputRef={ref}
            value={value}
            error={!!get(errors, name)}
            helperText={get(errors, name)?.message}
            onChange={(event) => onChange(onFloatChanged(event.target.value))}
          />
        );
      }}
    />
  );
}
