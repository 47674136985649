import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Add} from '@material-ui/icons';
import {Button} from '@material-ui/core';
import {Role} from '@common/api/models/users/IUser';
import {IMachinesTableGETResponse} from '@common/api/models/devices/machines/IMachine';
import IndexPage from '../shared/IndexPage/IndexPage';
import {useDeviceStoreActions} from '../../store/actions';
import {RootState} from '../../store/reducers';
import {uniq} from 'lodash';

const useMachineData = () => {
  const machineList = useSelector((state: RootState) => state.machineStore.list);

  const deviceStoreActions = useDeviceStoreActions();

  useEffect(() => {
    const serials: string[] = machineList.map((machine) => machine.deviceSerial!).filter((serial) => !!serial);

    deviceStoreActions.ensureConsistent({serial: {in: uniq(serials)}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineList]);

  return machineList.map((machine: IMachinesTableGETResponse) => {
    return {
      ...machine,
      deviceName: machine.deviceName,
      deviceSerial: machine.deviceSerial,
      onClickUrl: '/machines/uuid/' + machine.uuid,
    };
  });
};

const CreateMachineButton = () => {
  const history = useHistory();
  const auth = useSelector((state: RootState) => state.auth);
  const canCreateMachine = auth.user!.role >= Role.ADMINISTRATOR;

  if (!canCreateMachine) return <></>;

  return (
    <Button variant="contained" color="primary" onClick={() => history.push('/machines/createMachine/')}>
      <Add />
      New Machine
    </Button>
  );
};

export function MachinesPage() {
  return <IndexPage useData={useMachineData} resourceType="machine" CreateResourceComponent={CreateMachineButton} />;
}
