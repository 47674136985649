import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import * as qs from 'qs';
import GenericAlertPage from './GenericAlertPage';

function Unauthorized() {
  const {search} = useLocation();
  const query = qs.parse(search.replace(/^\?/, '')) as {
    redirect: string;
    client_id: string;
  };

  useEffect(() => {
    if (query.redirect) {
      setTimeout(() => {
        window.location.href = `${query.redirect}?client_id=${query.client_id}&returnTo=${window.location.origin}/`;
      }, 2500);
    }
  }, [query.redirect, query.client_id]);

  return <GenericAlertPage title="Unauthorized" alertText="You are not authorized to access this content." />;
}

export default Unauthorized;
