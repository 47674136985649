import React from 'react';
import {useSelector} from 'react-redux';
import {useAsyncDispatch} from '../../../ReduxRoot';
import {RootState} from '../../../store/reducers';
import similarityReportActions from '../../../store/actions/similarityReportActions';
import {ComparisonWeightsType} from '@common/api/models/builds/data/ISimilarity';
import SimilarityComparisonWeights from '../shared/SimilarityComparisonWeights';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const ComparisonWeights = ({uuid}: {uuid: string}) => {
  const dispatch = useAsyncDispatch();
  const isSmallScreen = useSmallScreenSize();
  const comparisonWeights = useSelector(
    (state: RootState) => state.similarityReportStore.currentSimilarityReport?.comparisonWeights
  );

  const onWeightsUpdated = async (newWeights: ComparisonWeightsType) => {
    const success = await dispatch(
      similarityReportActions.updateSimilarityReport(uuid, {
        comparisonWeights: newWeights,
      })
    );

    return success;
  };

  if (!comparisonWeights) return <></>;

  return (
    <SimilarityComparisonWeights
      comparisonWeights={comparisonWeights}
      onSave={onWeightsUpdated!}
      useVerticalButton={isSmallScreen}
    />
  );
};

export default ComparisonWeights;
