import {Box, Breadcrumbs as MuiBreadcrumbs, Link, Typography} from '@material-ui/core';
import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import {useExtraSmallScreenSize, useLargeScreenSize, useSmallScreenSize} from '../../utils/utilHooks';
import {Helmet} from 'react-helmet';

interface HeaderProps {
  title: string | React.ReactNode;
  breadcrumbs: Array<{title: string; path: string} | string> | string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  helmet: string;
  startAdornmentWidth?: string;
  endAdornmentFlex?: number;
}

const SpacedBreadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Breadcrumbs = styled(SpacedBreadcrumbs)<{maxItemWidth: string}>`
  max-width: 100%;
  .MuiBreadcrumbs-li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${({maxItemWidth}) => maxItemWidth};
  }
`;

const AppBar = styled.div<{margin: number}>`
  margin-top: ${({margin}) => `-${margin}px`};
  margin-left: ${({margin}) => `-${margin}px`};
  margin-bottom: 24px;
  width: calc(100% + ${({margin}) => `${margin * 2}px`});
  padding: 12px ${({margin}) => `${margin}px`};
  background: ${({theme}) => theme.header.background};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Header = ({
  title,
  helmet,
  breadcrumbs,
  startAdornment,
  endAdornment,
  startAdornmentWidth = '0px',
  endAdornmentFlex,
}: HeaderProps) => {
  const isLg = useLargeScreenSize();
  const isSm = useSmallScreenSize();
  const isXs = useExtraSmallScreenSize();
  const appMargin = isLg ? 40 : isXs ? 20 : 28;

  return (
    <>
      <Helmet title={helmet} />
      <AppBar margin={appMargin} style={{paddingRight: isXs || isSm ? '12px' : undefined}}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          flexWrap={isXs ? 'wrap' : undefined}
        >
          <Box
            display="flex"
            alignItems="center"
            marginLeft={isXs || isSm ? '28px' : undefined}
            marginRight="0px"
            flex={1}
            overflow={'hidden'}
            maxWidth={`calc(100% - ${isXs || isSm ? '28px' : '0px'})`}
          >
            {startAdornment && <Box>{startAdornment}</Box>}
            <Box display="flex" flexDirection="column" flex={1} maxWidth={`calc(100% - ${startAdornmentWidth})`}>
              {React.isValidElement(title) ? (
                title
              ) : (
                <Typography variant="h4" display="inline">
                  {title}
                </Typography>
              )}

              {Array.isArray(breadcrumbs) ? (
                <Breadcrumbs
                  aria-label="Breadcrumb"
                  mt={1}
                  maxItemWidth={`calc(${100 / breadcrumbs.length}% - ${
                    (20 * (breadcrumbs.length - 1)) / breadcrumbs.length
                  }px)`}
                >
                  {breadcrumbs.map((breadcrumb) => {
                    if (typeof breadcrumb === 'string') {
                      return <Typography key={breadcrumb}>{breadcrumb}</Typography>;
                    }
                    return (
                      <Link component={NavLink} to={breadcrumb.path} key={breadcrumb.path}>
                        {breadcrumb.title}
                      </Link>
                    );
                  })}
                </Breadcrumbs>
              ) : (
                breadcrumbs
              )}
            </Box>
          </Box>
          {endAdornment && (
            <Box display="flex" justifyContent="flex-end" flex={endAdornmentFlex}>
              {endAdornment}
            </Box>
          )}
        </Box>
      </AppBar>
    </>
  );
};

export default Header;
