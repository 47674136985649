import React from 'react';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {EllipsisTextColumnTooltip} from './sharedColumns';

const partTableSchema = [
  {
    title: 'Name',
    field: 'name',
    width: 'auto',
    render: (row: IPartGETResponse) => row.name && <EllipsisTextColumnTooltip>{row.name}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'Build name',
    align: 'left',
    field: 'buildName',
    width: '148px',
    render: (row: IPartGETResponse) =>
      row.buildName && <EllipsisTextColumnTooltip>{row.buildName}</EllipsisTextColumnTooltip>,
  },
  {
    title: '# Layers',
    align: 'left',
    field: 'numLayers',
    width: '148px',
    render: (row: IPartGETResponse) =>
      row.layerStart &&
      row.layerEnd && <EllipsisTextColumnTooltip>{`${row.layerEnd - row.layerStart}`}</EllipsisTextColumnTooltip>,
  },
];

export default partTableSchema;
