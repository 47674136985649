import {clone, isEqual} from 'lodash';
import {
  ISimilarityReport,
  ISimilarityReportGETResponse,
  ISimilarityComparisonGETResponse,
  ISimilarityComparisonStatusGETResponse,
  DEFAULT_WEIGHTS,
  RotationMap,
} from '@common/api/models/builds/data/ISimilarity';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {FetchingState, LiveStoreState} from '../../model/liveUpdateStore';
import createReducer from '../createReducer';
import {LiveStoreReducers} from '../liveUpdateStore';
import {
  DeleteSimilarityReportAction,
  DraftSimilarityReport,
  SetDraftSimilarityReportAction,
  SetCurrentlyViewingPartAction,
  FetchSimilarityComparisonStatusAction,
  SimilarityReportActions,
  UpdateSimilarityReportAction,
  FetchSimilarityReportAction,
  CreateSimilarityReportAction,
  FetchPartsForBuildAction,
  SetCurrentlyViewingComparisonAction,
  SetNewTargetPartsAction,
  SetNewTargetPartRotationsAction,
} from '../../model/similarityReport';

export interface SimilarityReportState extends LiveStoreState<ISimilarityReport> {
  draftSimilarityReport: DraftSimilarityReport;
  currentlyViewingPart?: IPartGETResponse;
  currentlyViewingComparison?: ISimilarityComparisonGETResponse;
  currentSimilarityReport?: ISimilarityReportGETResponse;
  currentReportFetching?: FetchingState;
  isCreatingReport: boolean;
  partsByBuildUuid: {
    [buildUuid: string]: {parts: IPartGETResponse[]; total: number};
  };
  buildNamesByBuildUuid: {[buildUuid: string]: string};
  comparisonProgress?: ISimilarityComparisonStatusGETResponse;
  lastComparisonProgress?: ISimilarityComparisonStatusGETResponse;
  currentTargetPartUuids?: {[key: string]: boolean};
  newTargetParts: IPartGETResponse[];
  newTargetPartRotations: RotationMap;
}

export const DEFAULT_REPORT_NAME = 'New Report - Similarity Coefficient';

export const similarityReportInitialState: Pick<
  SimilarityReportState,
  | 'draftSimilarityReport'
  | 'currentlyViewingPart'
  | 'isCreatingReport'
  | 'partsByBuildUuid'
  | 'buildNamesByBuildUuid'
  | 'currentReportFetching'
  | 'currentlyViewingComparison'
  | 'comparisonProgress'
  | 'lastComparisonProgress'
  | 'currentTargetPartUuids'
  | 'newTargetParts'
  | 'newTargetPartRotations'
> = {
  draftSimilarityReport: {
    name: '',
    backupName: DEFAULT_REPORT_NAME,
    sourceParts: [],
    targetParts: [],
    comparisonWeights: DEFAULT_WEIGHTS,
    targetPartRotations: {},
    sourcePartRotations: {},
  },
  currentlyViewingPart: undefined,
  currentlyViewingComparison: undefined,
  isCreatingReport: false,
  partsByBuildUuid: {},
  buildNamesByBuildUuid: {},
  currentReportFetching: FetchingState.None,
  comparisonProgress: undefined,
  lastComparisonProgress: undefined,
  currentTargetPartUuids: undefined,
  newTargetParts: [],
  newTargetPartRotations: {},
};

export class SimilarityReportLiveReducers extends LiveStoreReducers<ISimilarityReport, SimilarityReportState> {
  additionalReducers() {
    return {
      deleteSimilarityReportSuccess(
        state: SimilarityReportState,
        {payload}: DeleteSimilarityReportAction
      ): SimilarityReportState {
        const index = state.list.findIndex((report) => report.uuid === payload.uuid);
        if (index < 0) return state;

        const newList = clone(state.list);
        newList.splice(index, 1);

        return {
          ...state,
          list: newList,
        };
      },

      setDraftSimilarityReport(
        state: SimilarityReportState,
        {payload: {field, value}}: SetDraftSimilarityReportAction
      ): SimilarityReportState {
        const draftReport = {...state.draftSimilarityReport};

        return {
          ...state,
          draftSimilarityReport: {...draftReport, [field]: value},
        };
      },

      clearDraftSimilarityReport(state: SimilarityReportState): SimilarityReportState {
        return {
          ...state,
          draftSimilarityReport: similarityReportInitialState.draftSimilarityReport,
        };
      },

      setCurrentlyViewingPart(
        state: SimilarityReportState,
        {payload}: SetCurrentlyViewingPartAction
      ): SimilarityReportState {
        return {
          ...state,
          currentlyViewingPart: payload,
        };
      },

      createSimilarityReportRequest(state: SimilarityReportState): SimilarityReportState {
        return {
          ...state,
          isCreatingReport: true,
        };
      },

      createSimilarityReportSuccess(
        state: SimilarityReportState,
        {payload}: CreateSimilarityReportAction
      ): SimilarityReportState {
        return {
          ...state,
          currentSimilarityReport: payload,
          currentReportFetching: FetchingState.Fetched,
          draftSimilarityReport: similarityReportInitialState.draftSimilarityReport,
          isCreatingReport: false,
        };
      },

      createSimilarityReportFailure(state: SimilarityReportState): SimilarityReportState {
        return {
          ...state,
          isCreatingReport: false,
        };
      },

      fetchPartsForBuildSuccess(
        state: SimilarityReportState,
        {payload: {parts, buildUuid, buildName, total}}: FetchPartsForBuildAction
      ): SimilarityReportState {
        return {
          ...state,
          buildNamesByBuildUuid: {
            ...state.buildNamesByBuildUuid,
            [buildUuid]: buildName,
          },
          partsByBuildUuid: {
            ...state.partsByBuildUuid,
            [buildUuid]: {
              parts: [...(state.partsByBuildUuid[buildUuid]?.parts || []), ...parts],
              total,
            },
          },
        };
      },

      clearPartsForBuild(state: SimilarityReportState): SimilarityReportState {
        return {
          ...state,
          partsByBuildUuid: {},
        };
      },

      fetchSimilarityReportRequest(state: SimilarityReportState): SimilarityReportState {
        return {
          ...state,
          currentReportFetching: FetchingState.Fetching,
          comparisonProgress: undefined,
          lastComparisonProgress: undefined,
          currentTargetPartUuids: undefined,
          currentSimilarityReport: undefined,
        };
      },

      fetchSimilarityReportSuccess(
        state: SimilarityReportState,
        {payload}: FetchSimilarityReportAction
      ): SimilarityReportState {
        return {
          ...state,
          currentSimilarityReport: payload,
          currentReportFetching: FetchingState.Fetched,
          currentlyViewingComparison: undefined,
        };
      },

      fetchSimilarityReportFailure(state: SimilarityReportState): SimilarityReportState {
        return {
          ...state,
          currentReportFetching: FetchingState.Error,
        };
      },

      updateSimilarityReportSuccess(
        state: SimilarityReportState,
        {payload}: UpdateSimilarityReportAction
      ): SimilarityReportState {
        return {
          ...state,
          currentSimilarityReport: payload,
        };
      },

      setCurrentlyViewingComparison(
        state: SimilarityReportState,
        {payload}: SetCurrentlyViewingComparisonAction
      ): SimilarityReportState {
        return {
          ...state,
          currentlyViewingComparison: payload,
        };
      },

      fetchSimilarityProgressSuccess(
        state: SimilarityReportState,
        {payload}: FetchSimilarityComparisonStatusAction
      ): SimilarityReportState {
        if (isEqual(state.comparisonProgress, payload)) return state;

        const {generating, success, failure, submitted, reportStatus} = payload;

        const currentTargetPartUuids = [...generating, ...success, ...failure, ...submitted].reduce((obj, value) => {
          obj[value] = true;
          return obj;
        }, {} as {[key: string]: boolean});

        return {
          ...state,
          lastComparisonProgress: state.comparisonProgress,
          comparisonProgress: payload,
          currentTargetPartUuids: currentTargetPartUuids,
          currentSimilarityReport: {
            ...state.currentSimilarityReport!,
            status: reportStatus,
          },
        };
      },

      setNewTargetParts(state: SimilarityReportState, {payload}: SetNewTargetPartsAction): SimilarityReportState {
        return {
          ...state,
          newTargetParts: payload,
        };
      },

      setNewTargetPartRotations(
        state: SimilarityReportState,
        {payload}: SetNewTargetPartRotationsAction
      ): SimilarityReportState {
        return {
          ...state,
          newTargetPartRotations: payload,
        };
      },
    };
  }

  createReducer(initialState: SimilarityReportState) {
    const superReducers = this.bindReducers();
    const additionalReducers = this.additionalReducers();

    return createReducer<LiveStoreState<ISimilarityReport>>(initialState, {
      ...superReducers,
      [SimilarityReportActions.DELETE_SIMILARITY_REPORT_SUCCESS]: additionalReducers.deleteSimilarityReportSuccess,
      [SimilarityReportActions.SET_SIMILARITY_REPORT_DRAFT]: additionalReducers.setDraftSimilarityReport,
      [SimilarityReportActions.CLEAR_SIMILARITY_REPORT_DRAFT]: additionalReducers.clearDraftSimilarityReport,
      [SimilarityReportActions.SET_SIMILARITY_CURRENTLY_VIEWING_PART]: additionalReducers.setCurrentlyViewingPart,
      [SimilarityReportActions.CREATE_SIMILARITY_REPORT_REQUEST]: additionalReducers.createSimilarityReportRequest,
      [SimilarityReportActions.CREATE_SIMILARITY_REPORT_SUCCESS]: additionalReducers.createSimilarityReportSuccess,
      [SimilarityReportActions.CREATE_SIMILARITY_REPORT_FAILURE]: additionalReducers.createSimilarityReportFailure,
      [SimilarityReportActions.FETCH_PARTS_FOR_BUILD_SUCCESS]: additionalReducers.fetchPartsForBuildSuccess,
      [SimilarityReportActions.CLEAR_PARTS_FOR_BUILD]: additionalReducers.clearPartsForBuild,
      [SimilarityReportActions.FETCH_SIMILARITY_REPORT_REQUEST]: additionalReducers.fetchSimilarityReportRequest,
      [SimilarityReportActions.FETCH_SIMILARITY_REPORT_SUCCESS]: additionalReducers.fetchSimilarityReportSuccess,
      [SimilarityReportActions.FETCH_SIMILARITY_REPORT_FAILURE]: additionalReducers.fetchSimilarityReportFailure,
      [SimilarityReportActions.UPDATE_SIMILARITY_REPORT_SUCCESS]: additionalReducers.updateSimilarityReportSuccess,
      [SimilarityReportActions.SET_SIMILARITY_CURRENTLY_VIEWING_COMPARISON]:
        additionalReducers.setCurrentlyViewingComparison,
      [SimilarityReportActions.FETCH_SIMILARITY_REPORT_PROGRESS_SUCCESS]:
        additionalReducers.fetchSimilarityProgressSuccess,
      [SimilarityReportActions.SET_NEW_TARGET_PARTS_SUCCESS]: additionalReducers.setNewTargetParts,
      [SimilarityReportActions.SET_NEW_TARGET_PART_ROTATIONS_SUCCESS]: additionalReducers.setNewTargetPartRotations,
    });
  }
}
