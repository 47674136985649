import React, {useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import {IBuild} from '@common/api/models/builds/IBuild';
import {GenericDialog} from '../../components/molecules/DialogButton';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildIntensityReportActions from '../../store/actions/buildIntensityReportActions';
import SearchAndSelectBuild from '../../components/molecules/Selector/SearchAndSelectBuild';
import {COMPLETED_BUILD_FILTER} from '../shared/IndexPage/filterSchemas/buildIntensityReport';

function CreateBuildIntensityReportButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedBuild, setSelectedBuild] = useState<IBuild | null>(null);
  const asyncDispatch = useAsyncDispatch();

  const handleCreateReport = async () => {
    if (!selectedBuild) return;

    setCreating(true);
    const success = await asyncDispatch(
      buildIntensityReportActions.createBuildIntensityReport(
        {
          buildUuid: selectedBuild.uuid,
        },
        selectedBuild.name,
        selectedBuild.end
      )
    );

    if (success) onClose();
    else setCreating(false);
  };

  const onClose = () => {
    setSelectedBuild(null);
    setCreating(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setIsModalOpen(true)}>
        <Add />
        Add build
      </Button>
      <GenericDialog
        title="New Intensity Report"
        maxWidth="xs"
        content={
          <Grid container>
            <Grid item xs={12}>
              <Alert severity="info" style={{marginBottom: '24px'}}>
                Intensity reports are only available for completed builds with at least 1 valid slice file and no slice
                files pending verification.
                <br />
                <br />
                Builds are only allowed 1 intensity report, if you wish to regenerate the report, please delete it and
                then regenerate.
              </Alert>
              <SearchAndSelectBuild
                selectedBuild={selectedBuild}
                onBuildSelected={(build: IBuild | null) => setSelectedBuild(build)}
                filters={{
                  ...COMPLETED_BUILD_FILTER,
                  hasIntensityReport: false,
                  hasSliceData: true,
                }}
              />
            </Grid>
          </Grid>
        }
        isOpen={!!isModalOpen}
        closeDialog={onClose}
        onSuccess={handleCreateReport}
        confirmText="Create"
        confirmDisabled={!selectedBuild}
        requestInProgress={creating}
      />
    </>
  );
}

export default CreateBuildIntensityReportButton;
