import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import {Card as MuiCard, Divider as MuiDivider, Typography, Box} from '@material-ui/core';
import {Skeleton, Alert} from '@material-ui/lab';
import {ICalibration} from '@common/api/models/devices/ICalibration';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IDevice} from '@common/api/models/devices/IDevice';
import {StagingBuildDataProps} from '..';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {RootState} from '../../../../store/reducers/index';
import {FetchingState} from '../../../../store/model/liveUpdateStore';
import CalibrationDescription from './shared/CalibrationDescription';
import CalibrationViewport from './OverviewStep/CalibrationViewport';
import OverviewActions from './OverviewStep/OverviewActions';
import StagingResultIcon, {StagingType, StagingResult} from './shared/StagingResultIcon';
import DeviceFocusAlert from './FocusStep/DeviceFocusAlert';
import {UseLastCalibrationButton} from './CalibrationStep/ActionButtons';

const MonitoringCard = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function getCalibrationResult(
  latestCalibration: ICalibration | undefined,
  build: IBuild,
  isCurrentlyCalibrating: boolean,
  calibFetching: boolean
) {
  let calibrationResult = StagingResult.FAILURE;

  if (latestCalibration && latestCalibration.buildUuid === build.uuid) calibrationResult = StagingResult.PASS;
  if (latestCalibration && latestCalibration.buildUuid !== build.uuid) calibrationResult = StagingResult.OLD;
  if (build.hasCalibrated && !build.calibrationUuid) calibrationResult = StagingResult.FAILURE;
  if (isCurrentlyCalibrating) calibrationResult = StagingResult.PENDING;
  if (calibFetching) calibrationResult = StagingResult.NONE;

  return calibrationResult;
}

function getFocusResult(device: IDevice, isCurrentlyFocusing: boolean) {
  let focusResult = StagingResult.NONE;

  const focusResults = Object.values(device?.focusResult?.cameras || {});
  const allSuccess = focusResults.every((camera) => !camera.pending && camera.result.success);
  const anyPending = focusResults.some((camera) => !!camera.pending);
  const someFail = focusResults.some((camera) => !camera.pending && !camera.result.success);

  if (allSuccess) focusResult = StagingResult.PASS;
  if (someFail) focusResult = StagingResult.FAILURE;
  if (!focusResults.length) focusResult = StagingResult.OLD;
  if (anyPending || isCurrentlyFocusing) focusResult = StagingResult.PENDING;

  return focusResult;
}

export const ActiveStepOVERVIEW = ({
  build,
  latestCalibration,
  isCurrentlyCalibrating,
  isCurrentlyFocusing,
}: StagingBuildDataProps) => {
  const isSmallScreen = useSmallScreenSize();
  const device = useSelector((state: RootState) => state.deviceStore.byMachineUuid[build.machineUuid!]);
  const calibrationsFetched = useSelector((state: RootState) => state.calibrationStore.fetched);
  const [height, setHeight] = useState(100);

  useEffect(() => {
    const handleResize = () => {
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      setHeight(Math.max(vh - 750, 500));
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!device) return <></>;

  const calibFetching = calibrationsFetched === FetchingState.Fetching;
  const failedCalibration = build.hasCalibrated && !build.calibrationUuid;

  const focusResults = Object.values(device?.focusResult?.cameras || {}).filter(
    (camera) => !camera.pending && !!camera.result
  );

  return (
    <MonitoringCard p={isSmallScreen ? 4 : 6}>
      <Box display="flex" alignItems="center" mb={isSmallScreen ? 2 : 4}>
        <Typography variant="h6" style={{marginRight: '12px'}}>
          Device Focus
        </Typography>
        <StagingResultIcon type={StagingType.FOCUS} result={getFocusResult(device, isCurrentlyFocusing)} />
      </Box>

      <DeviceFocusAlert device={device} focusResults={focusResults} isCurrentlyCalibrating={false} build={build} />

      <Box display="flex" alignItems="center" mb={isSmallScreen ? 2 : 4} marginTop="12px">
        <Typography variant="h6" style={{marginRight: '12px'}}>
          Device Calibration
        </Typography>
        <StagingResultIcon
          type={StagingType.CALIBRATION}
          result={getCalibrationResult(latestCalibration, build, isCurrentlyCalibrating, calibFetching)}
        />
      </Box>

      {calibFetching && <CalibrationsFetching height={height} />}

      {!latestCalibration && !calibFetching && <Alert severity="warning">No calibration result available.</Alert>}

      {latestCalibration && !calibFetching && (
        <>
          <Box my={isSmallScreen ? 2 : 4}>
            <CalibrationDescription
              calibration={latestCalibration}
              currentBuild={build}
              isCurrentlyCalibrating={isCurrentlyCalibrating}
            />
          </Box>
          {!failedCalibration && !isCurrentlyCalibrating && (
            <Box pt={isSmallScreen ? 2 : 4}>
              <CalibrationViewport latestCalibration={latestCalibration} height={height} />
            </Box>
          )}
        </>
      )}

      {failedCalibration && !isCurrentlyCalibrating && !!latestCalibration && (
        <Box display="flex" width="100%" justifyContent="flex-end">
          <UseLastCalibrationButton buildUuid={build.uuid} />
        </Box>
      )}

      <Divider mt={isSmallScreen ? 3 : 6} style={{height: '2px'}} />

      <OverviewActions
        build={build}
        device={device}
        calibrationAvailable={!!latestCalibration}
        isCurrentlyCalibrating={isCurrentlyCalibrating}
        isCurrentlyFocusing={isCurrentlyFocusing}
        focusFailed={getFocusResult(device, isCurrentlyFocusing) === StagingResult.FAILURE}
        failedCalibration={failedCalibration}
      />
    </MonitoringCard>
  );
};

const CalibrationsFetching = ({height}: {height: number}) => {
  return (
    <Box>
      <Skeleton />
      <Skeleton />
      <Skeleton variant="rect" height={height} />
    </Box>
  );
};
