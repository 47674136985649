import React from 'react';
import Chart from './Chart';

export interface ScatterChartProps {
  data: Partial<Plotly.PlotData>[];
  height?: number;
  width?: number;
  disableZoom?: boolean;
  margin?: Partial<Plotly.Margin>;
  layout?: Partial<Plotly.Layout>;
  onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
  showLegend?: boolean;
}

export default function ScatterChart({disableZoom = true, showLegend = false, ...props}: ScatterChartProps) {
  // Convert input data points into a scatter chart
  const transformedData = props.data.map((data) => {
    return {...data, type: 'scatter' as const};
  });

  return (
    <Chart
      data={transformedData}
      layout={{
        dragmode: false,
        autosize: true,
        // Set to undefined to make chart responsive
        height: props.height,
        width: props.width,
        margin: props.margin,
        showlegend: showLegend,
        xaxis: {
          fixedrange: disableZoom,
          showticklabels: true,
          automargin: true,
        },
        yaxis: {fixedrange: disableZoom, automargin: true},
        hovermode: 'closest',
        ...props.layout,
      }}
      useResizeHandler
      style={{width: '100%', height: '100%'}}
      onClick={props.onClick}
    />
  );
}
