import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Card, CardContent, Collapse, Grid, IconButton, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {RootState} from '../../../../store/reducers/index';
import {FetchingState} from '../../../../store/model/liveUpdateStore';
import {MachineAvatar} from '../../../../components/molecules/avatars/MachineAvatar';
import {grayColor} from '../../../../assets/jss/material-dashboard-react';
import {MachineTable} from '../../draftBuild/draftBuildSteps/BuildConfigurationStep';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';

export const SideBarTargetMachine = ({machineUuid}: {machineUuid: string}) => {
  const [expanded, setExpanded] = useState(false);

  const {uuid} = useParams<{uuid: string}>();
  const build = useSelector((state: RootState) => state.buildStore.byId[uuid]);
  const {machinePermission} = usePermissionsForBuild(build);

  const deviceStore = useSelector((state: RootState) => state.deviceStore);
  const machineStore = useSelector((state: RootState) => state.machineStore);

  const device = deviceStore.byMachineUuid[machineUuid!];
  const machine = machineStore.byId[machineUuid!];

  return (
    <Grid item xs={12} sm={12}>
      <Card>
        {/* padding needed to remove padding-bottom: 24px; on last-child */}
        <CardContent style={{padding: '16px'}}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" align="center" style={{margin: 5}}>
                  Target Machine
                </Typography>
              </Grid>
            </Grid>

            {!machinePermission && (
              <Alert severity="warning">You don't have permission to access this build's machine.</Alert>
            )}

            {!!device && !device.online && (
              <Grid item>
                <Alert severity="warning">Device is offline.</Alert>
              </Grid>
            )}

            {machine && device && (
              <Grid item>
                <MachineAvatar
                  machine={machine}
                  device={device}
                  isLoading={
                    machineStore.fetched === FetchingState.Fetching || deviceStore.fetched === FetchingState.Fetching
                  }
                />
              </Grid>
            )}

            {!!machine && (
              <Grid item>
                <Divider />
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => setExpanded(!expanded)}
                >
                  <Typography variant="subtitle2">{machine?.name}</Typography>
                  <IconButton onClick={() => setExpanded(!expanded)} aria-expanded={expanded} aria-label="show more">
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Box>
                <Collapse in={expanded} timeout="auto">
                  <MachineTable
                    build={build}
                    machine={machine}
                    device={device}
                    loading={machineStore.fetched === FetchingState.Fetching}
                    isStaging={true}
                  />
                </Collapse>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Divider = styled.div`
  margin: 16px 0px;
  margin-left: -24px;
  height: 2px;
  width: calc(100% + 48px);
  background: ${grayColor[10]};
`;
