import React, {useState} from 'react';
import {CircularProgress, Grid, IconButton, TextField, Tooltip} from '@material-ui/core';
import {Edit, Save, Clear} from '@material-ui/icons';
import {Controller, useForm} from 'react-hook-form';
import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import SimilarityStatusChip from './SimilarityStatusChip';
import EllipsisTextWithTooltip from '../../../components/atoms/Texts/EllipsisTextWithTooltip';

interface SimilarityHeaderProps {
  reportName: string;
  onChangeAccepted: (newName: string) => Promise<boolean>;
  similarityStatus?: SimilarityStatus;
}

const SimilarityHeader = ({reportName, similarityStatus, onChangeAccepted}: SimilarityHeaderProps) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const onRename = async (newName: string) => {
    setIsRequesting(true);
    const success = await onChangeAccepted(newName);
    setIsRequesting(false);
    if (success) setIsRenaming(false);
  };

  return (
    <>
      {isRenaming ? (
        <EditMode
          reportName={reportName}
          onSave={({reportName}) => onRename(reportName)}
          onCancel={() => setIsRenaming(false)}
          isRequesting={isRequesting}
        />
      ) : (
        <ViewMode
          reportName={reportName}
          similarityStatus={similarityStatus}
          setIsRenaming={() => setIsRenaming(true)}
        />
      )}
    </>
  );
};

export default SimilarityHeader;

interface ViewModeProps {
  reportName: string;
  similarityStatus?: SimilarityStatus;
  setIsRenaming: () => void;
}

const ViewMode = ({reportName, similarityStatus, setIsRenaming}: ViewModeProps) => {
  return (
    <>
      <Grid
        item
        style={{
          maxWidth: `calc(100% - 46px${!!similarityStatus ? ' - 124px' : ''})`,
        }}
      >
        <EllipsisTextWithTooltip variant="h4">{reportName}</EllipsisTextWithTooltip>
      </Grid>
      <Grid item style={{margin: '-6px 8px 0px 8px'}}>
        <Tooltip title="Edit">
          <IconButton size="small" onClick={setIsRenaming}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        {!!similarityStatus && <SimilarityStatusChip similarityStatus={similarityStatus} size="small" />}
      </Grid>
    </>
  );
};

interface EditModeProps {
  reportName: string;
  onSave: (data: {reportName: string}) => void;
  onCancel: () => void;
  isRequesting: boolean;
}

const EditMode = ({reportName, onSave, onCancel, isRequesting}: EditModeProps) => {
  const {
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<{reportName: string}>({
    mode: 'all',
    defaultValues: {reportName},
  });

  return (
    <>
      <Grid item style={{width: 'calc(100% - 76px)', maxWidth: '360px'}}>
        <form id="updateSimilarityCoefName" onSubmit={onSubmit(onSave)}>
          <Controller
            name="reportName"
            control={control}
            render={({onChange, value, ref}) => {
              return (
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="small"
                  label="Report Name"
                  onKeyDown={(event) => event.key === 'Escape' && onCancel()}
                  inputRef={ref}
                  value={value}
                  error={!!errors.reportName}
                  onChange={(event) => onChange(event.target.value)}
                />
              );
            }}
          />
        </form>
      </Grid>
      <Grid item>
        {isRequesting ? (
          <CircularProgress size={20} style={{marginLeft: '6px'}} />
        ) : (
          <>
            <Tooltip title="Save">
              <IconButton size="small" onClick={onSubmit(onSave)} style={{marginBottom: '0px', marginTop: '0px'}}>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton size="small" onClick={onCancel} style={{marginBottom: '0px', marginTop: '0px'}}>
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Grid>
    </>
  );
};
