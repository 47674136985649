import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import cookieConfig from './CookieConfig';
import {RootState} from '../../../store/reducers/index';
import {LogInState} from '././../../../store/model/auth';
import * as AuthActions from '../../../store/actions/auth';
import {useActions} from '../../../store/actions/index';
import envConfig from '../../../envConfig';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './cookies.css';
import 'vanilla-cookieconsent';

export default function useCookieConsent() {
  const logInState = useSelector((state: RootState) => state.auth.logInState);
  const authActions = useActions(AuthActions);

  useEffect(() => {
    if (logInState === LogInState.LoggedIn && !envConfig.isOnPremise && !envConfig.isDev) {
      if (!document.getElementById('cc--main')) {
        (window as any).CC = window.initCookieConsent();
        (window as any).CC.run(cookieConfig(authActions.logout));
      }
    }
  }, [logInState, authActions.logout]);
}
