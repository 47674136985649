import React from 'react';
import {TableCell, TableRow, TableBody} from '@material-ui/core';
import recipientTableSchema, {RecipientRow} from '../../../../../pages/shared/IndexPage/tableSchemas/recipient';
import {CollapseTable} from '../ConfirmationStep';

const RecipientsCollapse = ({
  recipients,
}: {
  recipients: Array<Omit<RecipientRow, 'userAllowEmail' | 'userAllowSms' | 'isCurrentUser' | 'onEdit'>>;
}) => {
  return (
    <CollapseTable>
      <TableBody>
        {recipients.map((recipient) => (
          <TableRow key={`recipient-confirmation-row-${recipient.userUuid}`}>
            <TableCell>{recipientTableSchema[0].render(recipient as RecipientRow)}</TableCell>
            <TableCell>{recipientTableSchema[1].render(recipient as RecipientRow)}</TableCell>
            <TableCell>{recipientTableSchema[2].render(recipient as RecipientRow)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </CollapseTable>
  );
};

export default RecipientsCollapse;
