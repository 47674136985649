import React from 'react';
import {Alert, Color} from '@material-ui/lab';
import {max} from 'lodash';
import moment from 'moment';
import {Box} from '@material-ui/core';
import {CameraFocusingState, DeviceState, IDevice} from '@common/api/models/devices/IDevice';
import {renderDateTimeString} from '../../../../../utils/string';
import {IBuild} from '@common/api/models/builds/IBuild';

const DeviceFocusAlert = ({
  device,
  focusResults,
  isCurrentlyCalibrating,
  build,
}: {
  device: IDevice;
  focusResults: CameraFocusingState[];
  isCurrentlyCalibrating: boolean;
  build: IBuild;
}) => {
  const focusUnixTimestamps = focusResults.map((camera) =>
    camera.pending ? 0 : moment(camera?.result.timestamp).unix()
  );
  let severityType: Color = 'info';
  let text: String | React.ReactNode = '';

  const lastDeviceFocus = max(focusUnixTimestamps);
  const focusedOnThisBuild = lastDeviceFocus && lastDeviceFocus >= moment(build.createdAt).unix();

  if (!device) return <></>;

  if (device.state === DeviceState.FOCUSING) {
    severityType = 'warning';
    text = 'Device auto-focusing is in progress. Please wait for auto-focus to finish.';
  } else if (isCurrentlyCalibrating) {
    severityType = 'warning';
    text = 'Please manually stop calibration to begin focusing.';
  } else if (!device.supportsAutofocus) {
    severityType = 'info';
    text = 'Device does not support auto-focus. Please manually focus the device.';
  } else if (lastDeviceFocus) {
    const someFail = focusResults.some((camera) => !camera.pending && !camera.result.success);
    const deviceFocusTimeString = renderDateTimeString('descriptive', moment.unix(lastDeviceFocus));

    if (someFail) {
      severityType = 'warning';
      text = (
        <>
          Some cameras failed to focus during the last focus operation. Please try again, or manually focus the cameras.
          <br />
          <br />
          Last device focus operation was recorded on {deviceFocusTimeString}.
        </>
      );
    } else if (focusedOnThisBuild) {
      severityType = 'success';
      text = 'Successfully focused for this build!';
    } else {
      severityType = 'info';
      text = `Last device focus operation was recorded on ${deviceFocusTimeString}.`;
    }
  } else {
    severityType = 'info';
    text = 'No device focus operation recorded. It is recommended to focus the device before continuing.';
  }

  return (
    <Box pb={4}>
      <Alert severity={severityType}>{text}</Alert>
    </Box>
  );
};

export default DeviceFocusAlert;
