import {CssBaseline, StylesProvider} from '@material-ui/core';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/styles';
import {ThemeProvider} from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import * as React from 'react';
import maTheme from './theme';
import {useSelector} from 'react-redux';
import {RootState} from './store/reducers';
import {Helmet} from 'react-helmet';

// A theme with custom primary and secondary color.
// It's optional.
/*
const theme = createTheme({
	palette: {
		primary: {
			light: "#e5e5e5",
			main: "#727272",
			dark: "#363839",
			contrastText: "#fff",
		},
		secondary: {
			light: "#ff9800",
			main: "#e43e10",
			dark: "#a93000",
			contrastText: "#fff",
		},
	},
});*/

export function withRoot(Component: any) {
  function WithRoot(props: object) {
    const theme = useSelector((state: RootState) => state.theme);
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <React.Fragment>
        <Helmet titleTemplate="%s | Additive Assurance" defaultTitle="Additive Assurance - Web App" />

        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
              <ThemeProvider theme={maTheme[theme.currentTheme]}>
                {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...props} />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </React.Fragment>
    );
  }

  return WithRoot;
}
