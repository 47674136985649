import {IUser} from '@common/api/models/users/IUser';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {userPATCH} from '../api/ajax/users';
import {useActions} from '../store/actions';
import * as AuthActions from '../store/actions/auth';

interface TermsDialogProps {
  user: IUser | undefined;
}

const SubTitle = styled(Typography)`
  margin-bottom: 10px;
`;

const CustomDialogContent = styled(DialogContent)`
  &.MuiTypography-colorTextSecondary {
    color: #333;
  }

  max-height: 300px;
`;

const CustomListItem = styled.li`
  text-align: justify;
`;

export default function TermsDialog(props: TermsDialogProps) {
  const {user} = props;
  const authActions = useActions(AuthActions);
  const [open, setOpen] = React.useState(true);
  const [disabled, setDisabled] = useState(true);

  const handleClose = () => {
    setOpen(false);
    authActions.logout();
  };

  const handleAgree = () => {
    userPATCH(user!.uuid, {termConditionAgree: true});
    setOpen(false);
  };

  const handleScroll = (event: any) => {
    const target = event.target;
    if (target) {
      if (target.scrollHeight - parseInt(target.scrollTop) <= target.clientHeight + 10) {
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (!open) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const display = useMemo(() => {
    if (user) {
      if (user.termConditionAgreeDate) {
        const current = moment();
        const lastActive = moment(user?.termConditionAgreeDate);
        const dif = current.diff(lastActive, 'months');

        return dif >= 6;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [user]);

  if (user && display) {
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title" aria-describedby="dialog-description">
        <DialogTitle
          id="dialog-title"
          style={{
            textAlign: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <Typography variant="h4">Welcome to the Additive Assurance AMiRIS platform</Typography>
        </DialogTitle>
        <CustomDialogContent style={{}} onScroll={handleScroll}>
          <SubTitle variant="h5" color="textPrimary">
            Terms of use
          </SubTitle>
          <Typography style={{marginBottom: '10px'}} color="textPrimary" align="justify">
            A full copy of our Standard Terms and Conditions of Service and Privacy Policy has been provided to your
            account administrator. Please read these documents carefully. We draw your attention to our Acceptable Use
            Policy (Schedule 1 of our Standard Terms and Conditions of Service) outlined below. Should you have any
            questions, please contact your account administrator or Additive Assurance support
            (support@additiveassurance.com). You must provide an express written acceptance of our Standard Terms and
            Conditions before accessing our Service. This may be done by clicking the acceptance box below.
          </Typography>
          <SubTitle variant="h5" color="textPrimary">
            Acceptable Use Policy
          </SubTitle>
          <Typography variant="subtitle1" color="textPrimary">
            Introduction
          </Typography>
          <ul>
            <CustomListItem>
              This acceptable use policy (the "Policy") sets out the rules governing:
              <ol>
                <CustomListItem>the use of the Platform Services; and</CustomListItem>
                <CustomListItem>
                  the transmission, storage and processing of content by you, or by any person on your behalf, using the
                  Platform Services ("Content").
                </CustomListItem>
              </ol>
            </CustomListItem>
            <CustomListItem>
              References in this Policy to "you" are to any customer for the Platform Services and any individual user
              of the Platform Services (and "your" should be construed accordingly); and references in this Policy to
              "us" are to Additive Assurance Pty Ltd (and "we" and "our" should be construed accordingly).
            </CustomListItem>
            <CustomListItem>By using the Services, you agree to the rules set out in this Policy.</CustomListItem>
            <CustomListItem>
              We will ask for your express agreement to the terms of this Policy before you upload or submit any Content
              or otherwise use the Services.
            </CustomListItem>
            <CustomListItem>
              You must be at least 18 years of age to use the Services; and by using the Services, you warrant and
              represent to us that you are at least 18 years of age.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1" color="textPrimary">
            General usage rules
          </Typography>
          <ul>
            <CustomListItem>
              You must not use the Services in any way that causes, or may cause, damage to the Services or impairment
              of the availability or accessibility of the Services.
            </CustomListItem>
            <CustomListItem>
              You must not use the Services:
              <ol>
                <CustomListItem>
                  in any way that is unlawful, illegal, fraudulent, deceptive or harmful; or
                </CustomListItem>
                <CustomListItem>
                  in connection with any unlawful, illegal, fraudulent, deceptive or harmful purpose or activity.
                </CustomListItem>
              </ol>
            </CustomListItem>
            <CustomListItem>
              You must ensure that all Content complies with the provisions of this Policy.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Unlawful Content</Typography>
          <ul>
            <CustomListItem>
              Content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be
              capable of giving rise to legal action against any person (in each case in any jurisdiction and under any
              applicable law).
            </CustomListItem>
            <CustomListItem>
              Content, and the use of Content by us in any manner licensed or otherwise authorised by you, must not:
              <ol>
                <CustomListItem>be libellous or maliciously false;</CustomListItem>
                <CustomListItem>be obscene or indecent;</CustomListItem>
                <CustomListItem>
                  infringe any copyright, moral right, database right, trade mark right, design right, right in passing
                  off, or other intellectual property right;
                </CustomListItem>
                <CustomListItem>
                  infringe any right of confidence, right of privacy or right under data protection legislation;
                </CustomListItem>
                <CustomListItem>constitute negligent advice or contain any negligent statement;</CustomListItem>
                <CustomListItem>
                  constitute an incitement to commit a crime, instructions for the commission of a crime or the
                  promotion of criminal activity;
                </CustomListItem>
                <CustomListItem>be in contempt of any court, or in breach of any court order;</CustomListItem>
                <CustomListItem>
                  constitute a breach of racial or religious hatred or discrimination legislation;
                </CustomListItem>
                <CustomListItem>constitute a breach of official secrets legislation; or</CustomListItem>
                <CustomListItem>constitute a breach of any contractual obligation owed to any person.</CustomListItem>
              </ol>
            </CustomListItem>
            <CustomListItem>
              You must ensure that Content is not and has never been the subject of any threatened or actual legal
              proceedings or other similar complaint.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Negligent advice</Typography>
          <ul>
            <CustomListItem>
              Content must not consist of or contain any advice, instructions or other information that may be acted
              upon and could, if acted upon, cause death, illness or personal injury, damage to property, or any other
              loss or damage.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Regulated businesses</Typography>
          <ul>
            <CustomListItem>
              The sale, resale or other disposition of products and any related technology or documentation are subject
              to the export control laws, regulations and orders of Australia and may be subject to the export and/or
              import control laws and regulations of other countries. You agree to comply with all such laws,
              regulations and orders and acknowledge that You shall not directly or indirectly export any products to
              any country to which such export or transmission is restricted or prohibited. You acknowledge Your
              responsibility to obtain any license to create, design, build, export, re-export or import as may be
              required and we are not liable for any damages arising from your failure to comply with any regulatory or
              other legal requirements.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Monitoring</Typography>
          <ul>
            <CustomListItem>
              You acknowledge that we may actively monitor the Content and the use of the Services.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Data mining</Typography>
          <ul>
            <CustomListItem>
              You must not conduct any systematic or automated data scraping, data mining, data extraction or data
              harvesting, or other systematic or automated data collection activity, by means of or in relation to the
              Services.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Hyperlinks</Typography>
          <ul>
            <CustomListItem>
              You must not link to any material using or by means of the Services that would, if it were made available
              through the Services, breach the provisions of this Policy.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Harmful software</Typography>
          <ul>
            <CustomListItem>
              The Content must not contain or consist of, and you must not promote, distribute or execute by means of
              the Services, any viruses, worms, spyware, adware or other harmful or malicious software, programs,
              routines, applications or technologies.The Content must not contain or consist of, and you must not
              promote, distribute or execute by means of the Services, any viruses, worms, spyware, adware or other
              harmful or malicious software, programs, routines, applications or technologies.
            </CustomListItem>
            <CustomListItem>
              The Content must not contain or consist of, and you must not promote, distribute or execute by means of
              the Services, any software, programs, routines, applications or technologies that will or may have a
              material negative effect upon the performance of a computer or introduce material security risks to a
              computer.
            </CustomListItem>
          </ul>
          <Typography variant="subtitle1">Warranties</Typography>
          <ul>
            <CustomListItem>
              The Customer acknowledges that complex software is never wholly free from defects, errors and bugs, and
              subject to the other provisions of these Terms and Conditions, the Provider gives no warranty or
              representation that the Platform Services will be wholly free from defects, errors and bugs.
            </CustomListItem>
            <CustomListItem>
              The Customer acknowledges that complex software is never entirely free from security vulnerabilities, and
              subject to the other provisions of these Terms and Conditions, the Provider gives no warranty or
              representation that the Platform Services will be entirely secure.
            </CustomListItem>
            <CustomListItem>
              The Customer acknowledges that the Platform Services are designed to be compatible only with that software
              and those systems specified as compatible in the Platform Services Specification, and the Provider does
              not warrant or represent that the Platform Services will be compatible with any other software or systems.
            </CustomListItem>
            <CustomListItem>
              The Customer acknowledges that the Platform Services, System Outputs and any information available on the
              Platform is not professional advice and the Customer agrees to use its own professional judgement or to
              seek all appropriate financial, legal and other advice as applicable before relying on any information
              obtained from the Platform Services or the System Outputs.
            </CustomListItem>
            <CustomListItem>
              The Provider offers no warranty or guarantee as to the accuracy or completeness of the System Outputs and
              any reliance on the System Outputs by the Customer is at the Customer’s own discretion.
            </CustomListItem>
            <CustomListItem>
              The Customer acknowledges that the Provider will not provide any legal, financial, accountancy or taxation
              advice under these Terms and Conditions or in relation to the Platform Services; and, except to the extent
              expressly provided otherwise in these Terms and Conditions, the Provider does not warrant or represent
              that the Platform Services or the use of the Platform Services by the Customer will not give rise to any
              legal liability on the part of the Customer or any other person.
            </CustomListItem>
          </ul>
          <Typography>
            Your license is granted on the provision of strict adherence to these conditions. You must ensure that all
            Content complies with the provision of the Acceptable Use Policy and more generally with our standard Terms
            and Conditions of Service.
          </Typography>
        </CustomDialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Disagree
          </Button>
          <Button color="secondary" onClick={handleAgree} autoFocus variant="contained" disabled={disabled}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
