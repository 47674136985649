import React from 'react';
import {useSelector} from 'react-redux';
import {Chip} from '@material-ui/core';
import styled, {css} from 'styled-components';
import {RootState} from '../../../store/reducers';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const SavedIndicator = styled(Chip)<{isSmallScreen: boolean}>`
  ${({isSmallScreen}) =>
    isSmallScreen
      ? css`
          margin: 0 0 0.5rem 0;
          width: 100%;
        `
      : ''}
`;

const SaveStateChip = () => {
  const isSmallScreen = useSmallScreenSize();
  const saveState = useSelector((state: RootState) => state.draftBuild.saveState);

  switch (saveState) {
    case 'success':
      return <SavedIndicator label="Saved" isSmallScreen={isSmallScreen} size="small" />;
    case 'saving':
      return <SavedIndicator label="Saving..." variant="outlined" isSmallScreen={isSmallScreen} size="small" />;
    case 'error':
      return <SavedIndicator label={`Error saving`} color="primary" isSmallScreen={isSmallScreen} size="small" />;
  }
};

export default SaveStateChip;
