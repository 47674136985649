import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Grid, Typography, Tooltip, Chip} from '@material-ui/core';
import {RootState} from '../../store/reducers/index';

function BuildListChips() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const report = useSelector((state: RootState) => state.locationBasedDefectsReportStore.byId[selectedUuid]);

  if (!report) return <></>;

  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2" style={{marginBottom: '6px'}}>
        Included Builds
      </Typography>
      {report.builds.map((build) => (
        <Tooltip key={`lbd-build-chip-${build.uuid}`} title={<Typography>{build.name}</Typography>}>
          <Chip
            label={build.name}
            color="primary"
            size="small"
            style={{maxWidth: '2560px', marginRight: '8px', marginBottom: '8px'}}
          />
        </Tooltip>
      ))}
    </Grid>
  );
}

export default BuildListChips;
