import createReducer from './createReducer';
import {
  DraftBuildActions,
  SetDraftBuildSaveStateAction,
  DraftBuildInitialStoreState,
  DraftBuildStoreState,
} from '../model/draftBuild';

export const draftBuild = createReducer<DraftBuildStoreState>(DraftBuildInitialStoreState, {
  [DraftBuildActions.SET_DRAFT_BUILD_SAVE_STATE](state: DraftBuildStoreState, action: SetDraftBuildSaveStateAction) {
    return {...state, saveState: action.payload};
  },
});
