type SuccessSaveState = 'success' | 'saving';
type FailedSaveState = 'error';

export type SaveState = SuccessSaveState | FailedSaveState;

export interface DraftBuildStoreState {
  saveState: SaveState;
  error: string;
}

export const DraftBuildInitialStoreState: DraftBuildStoreState = {
  saveState: 'success',
  error: '',
};

export enum DraftBuildActions {
  SET_DRAFT_BUILD_SAVE_STATE = 'SET_DRAFT_BUILD_SAVE_STATE',
}

export interface DraftBuildActionType<T, P> {
  type: T;
  payload: P;
}

export type SetDraftBuildSaveStateAction = DraftBuildActionType<
  typeof DraftBuildActions.SET_DRAFT_BUILD_SAVE_STATE,
  SaveState
>;
