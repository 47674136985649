import axios from 'axios';

import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {BatchAttachmentsGETRequest, BatchAttachmentsGETResponse} from '@common/api/models/attachments/IBatchAttachment';

export async function batchAttachmentsAllGET(
  filter: QueryFilter<BatchAttachmentsGETRequest>
): AjaxApiResult<BatchAttachmentsGETResponse[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/batchAttachments/', {params: filter});
  }, 'Could not fetch batchAttachments');
}
