import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {IBuild, IBuildGetRequest} from '@common/api/models/builds/IBuild';
import {MidrigPreviewLinks} from '@common/api/midrig/messages';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {SensorProfileType} from '../../store/model/sensorProfile';

export async function buildsAllGET(filter: QueryFilter<IBuildGetRequest>): AjaxApiResult<IBuild[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/builds/' + (query && '?' + query));
  }, 'Could not fetch builds');
}

export async function buildsByUuidGET(uuid: string): AjaxApiResult<IBuild> {
  return ajaxWrapper(() => {
    return axios.get('/api/builds/' + uuid);
  }, 'Could not fetch build');
}

export async function buildsCreatePOST(data: Partial<IBuild>): AjaxApiResult<IBuild & {useAdvancedWorkflow?: boolean}> {
  return ajaxWrapper(() => {
    return axios.post('/api/builds/', data);
  }, 'Could not create build');
}

export async function buildsByUuidPATCH(uuid: string, updates: Partial<IBuild>): AjaxApiResult<IBuild> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/builds/${uuid}`, updates);
  }, 'Could not update build');
}

export async function provisionBuildGET(
  uuid: string,
  params: {skipStateCheck?: boolean; isSimpleWorkflow?: boolean} = {}
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/provision`, {
      params,
    });
  }, 'Could not start build');
}

export async function closeBuildGET(uuid: string, skipStateCheck?: boolean): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/close`, {
      params: {skipStateCheck},
    });
  }, 'Could not close build');
}

export async function monitorBuildGET(
  uuid: string,
  skipStateCheck?: boolean,
  resumeLayerNumber?: number
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/monitor`, {
      params: {skipStateCheck, resumeLayerNumber},
    });
  }, 'Could not monitor build');
}

export async function buildStopGET(uuid: string, skipStateCheck?: boolean): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/stop`, {
      params: {skipStateCheck},
    });
  }, 'Could not stop build');
}

export async function buildPreviewGET(uuid: string, skipStateCheck?: boolean): AjaxApiResult<MidrigPreviewLinks> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/preview`, {
      params: {skipStateCheck},
    });
  });
}

export async function buildFocusGET(
  uuid: string,
  cameraIds: number[],
  skipStateCheck?: boolean
): AjaxApiResult<MidrigPreviewLinks> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/focus`, {
      params: {cameraIds: JSON.stringify(cameraIds), skipStateCheck},
    });
  });
}

export async function buildCalibrateGET(uuid: string, skipStateCheck?: boolean): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/calibrate`, {
      params: {skipStateCheck},
    });
  }, 'Unable to calibrate machine');
}

export async function deleteBuildDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/builds/${uuid}`);
  }, 'Could not delete build');
}

export async function buildSetStateGET(uuid: string, operation: string): AjaxApiResult<IBuild> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/setState/${operation}`);
  }, 'Unable to set state manually');
}

export async function buildConfigCurrentGET(uuid: string): AjaxApiResult<string> {
  return ajaxWrapper(() => {
    return axios.get(`/api/builds/${uuid}/config/current`);
  }, 'Unable to get current build config');
}

export async function buildConfigPUT(uuid: string, config: SensorProfileType): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.put(`/api/builds/${uuid}/config/current`, {
      config,
    });
  }, 'Unable to update build config');
}
