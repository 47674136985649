import React, {useState} from 'react';
import {Box, Card, CardContent, CardHeader, MenuItem, Select, Typography} from '@material-ui/core';
import SevereDefectChart from './SevereDefectChart';
import BuildDurationHistogram from './BuildDurationHistogram';
import {IMetricsResponse} from '@common/api/models/metrics';
import {meanBy} from 'lodash';
import {humanize} from 'inflection';
import styled from 'styled-components';
import AntSwitch from '../../../components/atoms/Switches/AntSwitch';

enum SelectedChart {
  buildDurationHistogram = 'build_duration_histogram',
  severeDefectPerBuild = 'severe_defect_per_build',
}

const AVAILABLE_CHARTS = {
  [SelectedChart.severeDefectPerBuild]: {
    id: 'severeDefect',
    title: 'Severe defect per build',
    Component: SevereDefectChart,
    props: ['buildsDefectCoverage'],
  },
  [SelectedChart.buildDurationHistogram]: {
    id: 'buildDurationHistogram',
    title: 'Build duration',
    Component: BuildDurationHistogram,
    props: ['completedBuildDurations'],
  },
};

function SwitchableChart({metrics}: {metrics: IMetricsResponse}) {
  const [selectedChart, setSelectedChart] = useState<SelectedChart>(SelectedChart.buildDurationHistogram);
  const [usePercentageValues, setUsePercentageValues] = useState(false);

  const chart = AVAILABLE_CHARTS[selectedChart];
  const ChartComponent = chart.Component;

  return (
    <Card style={{height: '100%', overflow: 'auto'}}>
      <CardHeader
        title={
          <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
            {/* <Typography variant="h6">{chart.title}</Typography> */}
            <HeaderSelect
              value={selectedChart}
              onChange={(e) => setSelectedChart(e.target.value as SelectedChart)}
              inputProps={{'aria-label': 'Without label'}}
              displayEmpty
              disableUnderline
            >
              {Object.values(SelectedChart).map((value) => (
                <MenuItem value={value} key={value}>
                  {humanize(value)}{' '}
                </MenuItem>
              ))}
            </HeaderSelect>

            {selectedChart === SelectedChart.buildDurationHistogram && !!metrics.completedBuildDurations.length && (
              <Box display="flex" alignItems="center">
                <Typography>Use percentage values?</Typography>
                <Box mr={3} ml={2}>
                  <AntSwitch
                    size="small"
                    checked={usePercentageValues}
                    onClick={() => setUsePercentageValues(!usePercentageValues)}
                  />
                </Box>
                <AverageDuration completedBuildDurations={metrics.completedBuildDurations} />
              </Box>
            )}
          </Box>
        }
        style={{paddingBottom: '0px'}}
      />

      <CardContent style={{minWidth: '600px'}}>
        <ChartComponent metrics={metrics} usePercentageValues={usePercentageValues} />
      </CardContent>
    </Card>
  );
}

export default SwitchableChart;

function AverageDuration({
  completedBuildDurations,
}: {
  completedBuildDurations: IMetricsResponse['completedBuildDurations'];
}) {
  return (
    <Typography variant="h6" style={{lineHeight: '1'}}>
      Average duration: {(meanBy(completedBuildDurations, 'duration') / 1000 / 60 / 60).toFixed(1)} hours
    </Typography>
  );
}

const HeaderSelect = styled(Select)`
  font-size: 1.0625rem;
  font-weight: 600;
  margin-bottom: 12px;
  .MuiSelect-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const CenteredDiv = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
