import React from 'react';
import {Tooltip} from '@material-ui/core';
import {PhotoCamera} from '@material-ui/icons';
import {ViewerButton} from '../../FadingComponents';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../utils';

interface CombinedDownloadProps {
  StackedDownload: () => void;
  stageHeight: number;
  mouseOn: boolean;
}

export default function CombinedDownload(props: CombinedDownloadProps) {
  const {StackedDownload, stageHeight, mouseOn} = props;
  return (
    <Tooltip
      title="Download a screenshot of the current viewport, including the layer image and visible analysis overlays"
      placement="left"
    >
      <ViewerButton
        onClick={StackedDownload}
        style={{
          left: BUTTON_MARGIN * 2,
          top: stageHeight - BUTTON_HEIGHT * 3.4,
          position: 'absolute',
        }}
        className={(!mouseOn && 'fade') || undefined}
      >
        Screenshot{' '}
        <PhotoCamera
          style={{
            marginLeft: '10px',
          }}
        />
      </ViewerButton>
    </Tooltip>
  );
}
