import React, {useEffect, useState} from 'react';
import {cloneDeep} from 'lodash';

import {
  AppBar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import {GlobalGraphConfig, SelectedGraphConfig} from '../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {GenericDialog} from '../DialogButton';
import EllipsisTextWithTooltip from '../../atoms/Texts/EllipsisTextWithTooltip';
import {SelectedDefectSummary} from '../../../pages/builds/liveBuild/activeBuildPages/useDefectSummaries';
import AntSwitch from '../../atoms/Switches/AntSwitch';
import {LineSettings, TrendlineSettings} from '../Forms/GraphConfigForms';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import ConditionalTooltip from '../../atoms/Texts/ConditionalTooltip';

export enum Line {
  dataLine = 'dataLineSettings',
  trendLine = 'trendLineSettings',
}

export interface UpdatePropertyProps {
  property: string;
  value: any;
  line?: Line;
  uuid?: string;
}

export default function DefectsGraphOptionsModal(props: {
  open: boolean;
  onClose: () => void;
  globalGraphConfig: GlobalGraphConfig;
  setGlobalGraphConfig: React.Dispatch<React.SetStateAction<GlobalGraphConfig>>;
  selectedDefects: SelectedDefectSummary[];
  setSelectedDefects: (selectedDefects: SelectedDefectSummary[]) => void;
}) {
  const {open, onClose, globalGraphConfig, setGlobalGraphConfig, selectedDefects, setSelectedDefects} = props;

  const isSmallScreen = useSmallScreenSize();
  const [currentDefect, setCurrentDefect] = useState<SelectedDefectSummary | null>(null);

  useEffect(() => {
    if (currentDefect) {
      setCurrentDefect(
        selectedDefects.find((defect) => defect.uuid === (currentDefect as SelectedDefectSummary).uuid) ?? null
      );
    } else {
      setCurrentDefect(null);
    }
  }, [selectedDefects, currentDefect]);

  useEffect(
    () => {
      if (globalGraphConfig.useOverrides === undefined) return;
      setSelectedDefects(
        selectedDefects.map<SelectedDefectSummary>((defect) => {
          defect.graphConfig.useOverrides = globalGraphConfig.useOverrides!;
          return defect;
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalGraphConfig.useOverrides, selectedDefects.length, setSelectedDefects]
  );

  return (
    <GenericDialog
      isOpen={open}
      fullWidth
      closeDialog={onClose}
      title="Graph Options"
      maxWidth="md"
      closeText="Close"
      content={
        <Grid container direction="column" spacing={2} style={{padding: '5px'}}>
          {isSmallScreen && (
            <Grid item style={{marginBottom: '1rem'}}>
              <AppBar position="static" color="transparent" style={{boxShadow: 'none', width: '70vw'}}>
                <Tabs value={currentDefect} onChange={(_, defect) => setCurrentDefect(defect)} variant="scrollable">
                  <Tab label="Global Settings" value={null} />
                  {selectedDefects.map((defect) => (
                    <Tab label={defect.name} value={defect} />
                  ))}
                </Tabs>
              </AppBar>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="row" justifyContent="space-between">
              {!isSmallScreen && (
                <Grid item xs={3}>
                  <List
                    subheader={
                      <ListSubheader
                        style={{
                          padding: 0,
                          margin: 0,
                          marginBottom: '5px',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        <Typography variant="subtitle2">Selected Defects</Typography>
                      </ListSubheader>
                    }
                  >
                    <ListItem button selected={!currentDefect} onClick={() => setCurrentDefect(null)}>
                      <ListItemText primary={<EllipsisTextWithTooltip>Global Settings</EllipsisTextWithTooltip>} />
                    </ListItem>

                    {selectedDefects.map((defect) => (
                      <ListItem button onClick={() => setCurrentDefect(defect)} selected={defect === currentDefect}>
                        <ListItemText
                          primary={<EllipsisTextWithTooltip>{defect.name}</EllipsisTextWithTooltip>}
                          primaryTypographyProps={{noWrap: true}}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
              <Grid item>
                <Divider orientation="vertical"></Divider>
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 8}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item xs={12} sm={5}>
                        <Typography noWrap variant="h6">
                          {!!currentDefect ? (currentDefect as SelectedDefectSummary).name : 'Global Settings'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <GraphConfigurationMenu
                    defect={currentDefect as SelectedDefectSummary}
                    globalGraphConfig={globalGraphConfig}
                    setGlobalGraphConfig={setGlobalGraphConfig}
                    selectedDefects={selectedDefects}
                    setSelectedDefects={setSelectedDefects}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    ></GenericDialog>
  );
}

const GraphConfigurationMenu = ({
  defect,
  globalGraphConfig,
  setGlobalGraphConfig,
  selectedDefects,
  setSelectedDefects,
}: {
  defect?: SelectedDefectSummary;
  globalGraphConfig: GlobalGraphConfig;
  setGlobalGraphConfig: React.Dispatch<React.SetStateAction<GlobalGraphConfig>>;
  selectedDefects: SelectedDefectSummary[];
  setSelectedDefects: (selectedDefects: SelectedDefectSummary[]) => void;
}) => {
  const updateDefect = (updatedDefect: SelectedDefectSummary) => {
    const currentIndex = selectedDefects.findIndex((defect) => defect.uuid === updatedDefect.uuid);
    const newSelectedDefects = cloneDeep(selectedDefects);
    newSelectedDefects.splice(currentIndex, 1, updatedDefect);
    setSelectedDefects(newSelectedDefects);
  };

  function updateGraphConfig<K extends keyof SelectedGraphConfig>(key: K, value: SelectedGraphConfig[K]) {
    if (!defect) return;

    updateDefect({
      ...defect,
      graphConfig: {...defect.graphConfig, useOverrides: true, [key]: value},
    });
  }

  return (
    <div>
      {!defect ? (
        <GlobalSettings globalGraphConfig={globalGraphConfig} setGlobalGraphConfig={setGlobalGraphConfig} />
      ) : (
        <Grid item>
          <TrendlineSettings
            rollingData={defect!.graphConfig.rollingDataConfig}
            updateGraphConfig={updateGraphConfig}
            selectedDefect={defect}
            useCoverage={globalGraphConfig.useCoverage}
          />
          {defect.graphConfig.showTrendline && (
            <LineSettings
              lineSettings={defect?.graphConfig.trendLineSettings!}
              updateGraphConfig={updateGraphConfig}
              line={Line.trendLine}
            />
          )}

          <LineSettings
            lineSettings={defect?.graphConfig.dataLineSettings!}
            updateGraphConfig={updateGraphConfig}
            line={Line.dataLine}
          />
        </Grid>
      )}
    </div>
  );
};

const GlobalSettings = ({
  setGlobalGraphConfig,
  globalGraphConfig,
}: {
  setGlobalGraphConfig: React.Dispatch<React.SetStateAction<GlobalGraphConfig>>;
  globalGraphConfig: GlobalGraphConfig;
}) => {
  function updateGlobalGraphConfig<K extends keyof GlobalGraphConfig>(key: K, value: GlobalGraphConfig[K]) {
    setGlobalGraphConfig({
      ...globalGraphConfig,
      [key]: value,
    });
  }

  const toggleAllTrendLines = () => updateGlobalGraphConfig('showTrendline', !globalGraphConfig.showTrendline);

  const toggleCoverage = () => updateGlobalGraphConfig('useCoverage', !globalGraphConfig.useCoverage);

  const toggleOverride = () => updateGlobalGraphConfig('useOverrides', !globalGraphConfig.useOverrides);

  const toggleOriginal = () => updateGlobalGraphConfig('showOriginal', !(globalGraphConfig.showOriginal ?? true));

  const toggleAllParts = () => updateGlobalGraphConfig('showPartArea', !globalGraphConfig.showPartArea);

  return (
    <>
      <Grid container direction="column" spacing={2} style={{margin: '1rem 0rem'}}>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={7} sm={3}>
              <Typography noWrap style={{fontWeight: 'bold'}}>
                Use % Coverage
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item>
              <AntSwitch size="small" checked={globalGraphConfig.useCoverage} onClick={toggleCoverage} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={7} sm={3}>
              <Typography noWrap style={{fontWeight: 'bold'}}>
                Individual Settings
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item>
              <AntSwitch size="small" checked={globalGraphConfig.useOverrides} onClick={toggleOverride} />
            </Grid>
            <Grid item>
              <Tooltip
                style={{maxWidth: '30vw'}}
                title={
                  <Typography style={{padding: '4px'}}>
                    Enabling Individual Settings will use the settings for Individual Graphs found in their respective
                    tabs rather than the global settings
                  </Typography>
                }
                placement="right"
              >
                <InfoIcon style={{marginLeft: '5px'}} fontSize="small" color="primary"></InfoIcon>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={7} sm={3}>
              <Typography noWrap style={{fontWeight: 'bold'}}>
                Show Trendlines
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item>
              <AntSwitch size="small" checked={globalGraphConfig.showTrendline} onClick={toggleAllTrendLines} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={7} sm={3}>
              <Typography noWrap style={{fontWeight: 'bold'}}>
                Show Original Data
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item>
              <AntSwitch size="small" checked={globalGraphConfig.showOriginal} onClick={toggleOriginal} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={7} sm={3}>
              <Typography noWrap style={{fontWeight: 'bold'}}>
                Show Part Areas
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item>
              <ConditionalTooltip
                tooltip="Cannot turn on part areas when coverage is turned on"
                hideTooltip={!globalGraphConfig.useCoverage}
                placement="right"
              >
                <AntSwitch
                  size="small"
                  checked={globalGraphConfig.showPartArea}
                  onClick={toggleAllParts}
                  disabled={globalGraphConfig.useCoverage}
                />
              </ConditionalTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TrendlineSettings
          rollingData={globalGraphConfig.rollingDataConfig}
          updateGraphConfig={updateGlobalGraphConfig as any}
          useCoverage={globalGraphConfig.useCoverage}
        />
      </Grid>
    </>
  );
};
