import React, {useEffect, useState} from 'react';
import {Snackbar, Typography, Grid, CircularProgress} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Close} from '@material-ui/icons';
import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {toast} from 'react-toastify';

import {closeBuildGET} from '../../../../api/ajax/builds';
import {DialogButton, GenericDialog} from '../../../../components/molecules/DialogButton';
import VerticalButton from '../../../../components/atoms/VerticalButton';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {useBuildStateTransition} from '../../shared/useBuildStateTransition';
import {BUILD_SHORT_TRANSITION_TIMEOUT} from '../index';

interface LiveBuildControlProps {
  build: IBuild;
  isSmallButton?: boolean;
  fullWidthButton?: boolean;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

export default function FinishBuildButton({build, isSmallButton = false, fullWidthButton}: LiveBuildControlProps) {
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const {machinePermission} = usePermissionsForBuild(build);

  useEffect(() => {
    return () => clearTimeout(timeout!);
  }, []);

  useBuildStateTransition(() => {
    clearTimeout(timeout!);
    setRequesting(false);
  }, [build, [BuildState.COMPLETED, BuildState.SUCCEEDED, BuildState.FAILED]]);

  const handleCloseBuildConfirm = async () => {
    setRequesting(true);
    const res = await closeBuildGET(build.uuid);
    if (!res.success) {
      setRequesting(false);
      return;
    }

    timeout = setTimeout(() => {
      setRequesting(false);
      toast('Finishing build timed out', {type: 'error'});
    }, BUILD_SHORT_TRANSITION_TIMEOUT);
  };

  const DIALOG_PROPS = {
    title: 'Finish Build',
    content: (
      <Typography>
        Are you sure you want to close this build? This will mark the build as finished,{' '}
        <b>
          <i>end monitoring for the build permanently</i>
        </b>{' '}
        and release the machine for other builds to start.
      </Typography>
    ),
    confirmText: isSmallButton ? 'Finish Build' : 'Confirm and Close',
  };

  return (
    <Grid item>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <ConditionalTooltip
        tooltip="Finishing the build requires permission to access this build's machine."
        hideTooltip={!!machinePermission}
      >
        {isSmallButton ? (
          <>
            <VerticalButton
              onClick={() => setIsOpen(true)}
              color="secondary"
              disabled={!machinePermission || requesting}
            >
              {requesting ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <Close />
                  <Typography variant="caption">Finish Build</Typography>
                </>
              )}
            </VerticalButton>
            <GenericDialog
              {...DIALOG_PROPS}
              isOpen={isOpen}
              closeDialog={() => setIsOpen(false)}
              onSuccess={handleCloseBuildConfirm}
              maxWidth="lg"
            />
          </>
        ) : (
          <DialogButton
            danger
            text="Finish Build"
            dialog={DIALOG_PROPS}
            handleConfirm={handleCloseBuildConfirm}
            fullWidth={fullWidthButton}
            disabled={!machinePermission}
            loading={requesting}
          />
        )}
      </ConditionalTooltip>
    </Grid>
  );
}
