import {BatchEventType, IBatch} from '@common/api/models/materials/batches/IBatch';
import {CallSplit, InfoOutlined, MergeType, NewReleasesOutlined} from '@material-ui/icons';
import {assertUnreachable} from '@common/utils/utils';
import {TimelineGraphNode, TimelineGraphTableRow, TimeMode} from './types';
import {LiveStoreState} from '../../../store/model/liveUpdateStore';
import {IBuild} from '@common/api/models/builds/IBuild';
import {IMaterial} from '@common/api/models/materials/IMaterial';

interface HistoryRowBase {
  type: 'merge' | 'new' | 'split' | 'stop' | 'action';
  date: Date;
  message: string;
}

interface HistoryRowMerge extends HistoryRowBase {
  type: 'merge';
  fromA: string;
  fromB: string;
  into: IBatch;
}

interface HistoryRowNew extends HistoryRowBase {
  type: 'new';
  batch: IBatch;
}

interface HistoryRowSplit extends HistoryRowBase {
  type: 'split';
  old: string;
  new: [IBatch | undefined, IBatch | undefined];
  selfUuid: string; // Only the first split event encountered gets through - this field is for identifying the first
  // element had it been split most recently.
  newUuids: [string, string];
}

interface HistoryRowAction extends HistoryRowBase {
  type: 'action';
  target: string;
  new: IBatch;
}

interface HistoryRowStop extends HistoryRowBase {
  type: 'stop';
  target: string;
  new: IBatch;
}

type HistoryEvent = HistoryRowMerge | HistoryRowNew | HistoryRowSplit | HistoryRowAction | HistoryRowStop;

export function batchesToHistoryEvents(
  batches: IBatch[],
  materialStore: LiveStoreState<IMaterial>,
  batchStore: LiveStoreState<IBatch>,
  buildStore: LiveStoreState<IBuild>
): HistoryEvent[] {
  const res: HistoryEvent[] = [];

  // Because we are only looking at the "birth" events, a split event gets seen twice, once by A and once by B.
  // It isn't good enough to choose either an A or a B, because one could be missing when searching backwards.
  // So we keep the first one, and discard the second occurrence, indexed by the parent uuid.
  const countedSplits = new Set<string>();

  for (const b of batches) {
    switch (b.creationEventData.type) {
      case BatchEventType.INITIAL: {
        const mat = materialStore.byId[b.creationEventData.materialUuid];
        res.push({
          type: 'new',
          message: 'New Batch with ' + b.creationEventData.quantity + 'kg of ' + mat?.name,
          date: b.registered,
          batch: b,
        });
        break;
      }
      case BatchEventType.ADD: {
        const mat = materialStore.byId[b.creationEventData.materialUuid];
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message: 'Adding ' + b.creationEventData.quantity + 'kg of ' + mat?.name,
          new: b,
        });
        break;
      }
      case BatchEventType.MEASUREMENT: {
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message: 'Measured batch',
          new: b,
        });
        break;
      }
      case BatchEventType.MERGE: {
        res.push({
          type: 'merge',
          message: 'Merge batches',
          date: b.registered,
          fromA: b.parentAUuid!,
          fromB: b.parentBUuid!,
          into: b,
        });
        break;
      }
      case BatchEventType.SPLIT: {
        const parent = (b.parentAUuid || b.parentBUuid)!;
        if (!parent) {
          throw new Error('No parent found ' + JSON.stringify(b));
        }
        if (!countedSplits.has(parent)) {
          countedSplits.add(parent);

          const parentBatch = batchStore.byId[parent];

          res.push({
            type: 'split',
            message: 'Split batch',
            old: parent,
            date: b.registered,
            selfUuid: b.uuid,
            new: [batchStore.byId[parentBatch?.successorAUuid!], batchStore.byId[parentBatch?.successorBUuid!]],
            newUuids: [parentBatch?.successorAUuid!, parentBatch?.successorBUuid!],
          });
        }
        break;
      }
      case BatchEventType.BUILD: {
        const build = buildStore.byId[b.creationEventData.buildUuid];
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message: 'Completed build "' + build?.name + '"',
          new: b,
        });
        break;
      }
      case BatchEventType.SIEVE: {
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message: 'Sieving done. Type: ' + b.creationEventData.sieveType,
          new: b,
        });
        break;
      }
      case BatchEventType.RETIRE: {
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message: 'Batch retired',
          new: b,
        });
        break;
      }
      case BatchEventType.CUSTOM: {
        let message = '';
        if (b.creationEventData.type) {
          message += b.creationEventData.type;
        }
        if (b.creationEventData.description) {
          if (message) {
            message += ' - ';
          }
          message += b.creationEventData.description;
        }
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message,
          new: b,
        });
        break;
      }
      case BatchEventType.TEST: {
        let message = `Test: ${b.creationEventData.type} - ${b.creationEventData.testDescription}`;
        res.push({
          type: 'action',
          date: b.registered,
          target: b.parentAUuid!,
          message,
          new: b,
        });
        break;
      }
      default:
        assertUnreachable(b.creationEventData);
    }
  }

  return res;
}

export function batchesToHistoryGraphTimelineRows(
  batchHistory: IBatch[],
  materialStore: LiveStoreState<IMaterial>,
  batchStore: LiveStoreState<IBatch>,
  buildStore: LiveStoreState<IBuild>
) {
  // Whatever. The data will sort itself out. Let's do the git graph though.
  const exampleEvents = batchesToHistoryEvents(batchHistory, materialStore, batchStore, buildStore)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .reverse();

  const historyTableRowData: TimelineGraphTableRow[] = [];

  const onCol = new Map<number, string>();
  const colOf = new Map<string, number>();

  let maxNodesAcross = 0;

  function nextEmptyColumn() {
    let i = 0;
    while (onCol.has(i)) {
      i++;
    }
    maxNodesAcross = Math.max(maxNodesAcross, i + 1);
    return i;
  }

  let first = true;
  // Remember we are going backwards in time, so mental model the splits and joins accordingly.
  for (const e of exampleEvents) {
    let layers: TimelineGraphNode[][] = [];
    let edges: string[][] = [];

    if (first) {
      switch (e.type) {
        case 'merge':
          colOf.set(e.into.uuid, 0);
          onCol.set(0, e.into.uuid);
          break;
        case 'new':
          colOf.set(e.batch.uuid, 0);
          onCol.set(0, e.batch.uuid);
          break;
        case 'split':
          colOf.set(e.selfUuid, 0);
          onCol.set(0, e.selfUuid);
          break;
        case 'action':
          colOf.set(e.new.uuid, 0);
          onCol.set(0, e.new.uuid);
          break;
        case 'stop':
          colOf.set(e.new.uuid, 0);
          onCol.set(0, e.new.uuid);
          break;
        default:
          assertUnreachable(e);
      }
    }

    switch (e.type) {
      case 'merge': {
        // |
        // |\
        // | |

        const newLoc = nextEmptyColumn();
        onCol.set(newLoc, e.fromB);
        colOf.set(e.fromB, newLoc);
        const curCol = colOf.get(e.into.uuid)!;
        onCol.set(curCol, e.fromA);
        colOf.set(e.fromA, curCol);
        colOf.delete(e.into.uuid);
        layers.push(
          [
            {
              index: curCol,
              text: e.message,
              batch: e.into,
              date: e.date,
              icon: MergeType,
            },
          ]
          //[{index: curCol, text: '', batchUuid: e.fromA}, {index: newLoc, text: '', batchUuid: e.fromB}],
        );
        edges.push([e.fromA + '->' + e.into.uuid, e.fromB + '->' + e.into.uuid]);
        break;
      }
      case 'new':
        // when going backwards, this is really the "end", not the beginning.
        // Really doing nothing actually.
        const oldCol = colOf.get(e.batch.uuid)!;
        colOf.delete(e.batch.uuid);
        onCol.delete(oldCol);
        layers.push([
          {
            index: oldCol,
            text: e.message,
            batch: e.batch,
            date: e.date,
            icon: NewReleasesOutlined,
          },
        ]);
        edges.push([]);
        break;
      case 'split': {
        // | |
        // |/
        // |
        // when going backwards, a split is effectively a join.
        // When going backwards, we are assuming that we don't have "dangling" branches here.
        const loc1 = colOf.get(e.newUuids[0]);
        const loc2 = colOf.get(e.newUuids[1]);
        if (loc1 !== undefined) {
          onCol.delete(loc1);
        }
        if (loc2 !== undefined) {
          onCol.delete(loc2);
        }
        colOf.delete(e.newUuids[0]);
        colOf.delete(e.newUuids[1]);
        const newLoc = loc1 === undefined ? loc2! : loc2 === undefined ? loc1 : Math.min(loc1, loc2);

        colOf.set(e.old, newLoc);
        onCol.set(newLoc, e.old);

        const firstLayer: TimelineGraphNode[] = [];
        const firstEdges: string[] = [];
        if (loc1 !== undefined) {
          firstLayer.push({
            text: e.message,
            index: loc1,
            batch: batchStore.byId[e.newUuids[0]],
            date: e.date,
            icon: CallSplit,
          });
          firstEdges.push(e.old + '->' + e.newUuids[0]);
        }
        if (loc2 !== undefined) {
          firstLayer.push({
            text: e.message,
            index: loc2,
            batch: batchStore.byId[e.newUuids[1]],
            date: e.date,
            icon: CallSplit,
          });
          firstEdges.push(e.old + '->' + e.newUuids[1]);
        }
        layers.push(firstLayer);
        edges.push(firstEdges);
        break;
      }
      case 'stop':
      case 'action': {
        const loc = colOf.get(e.new.uuid);
        if (loc === undefined) {
          throw new Error('Action with invalid result id' + JSON.stringify(e));
        }
        colOf.delete(e.new.uuid);
        colOf.set(e.target, loc);
        onCol.set(loc, e.target);
        layers.push([
          {
            text: e.message,
            index: loc,
            batch: e.new,
            date: e.date,
            icon: InfoOutlined,
          },
        ]);
        edges.push([e.target + '->' + e.new.uuid]);
        break;
      }
      default:
        assertUnreachable(e);
    }

    for (let i = 0; i < layers.length; i++) {
      historyTableRowData.push({
        nodes: layers[i],
        edges: edges[i],
        timeMode: first ? TimeMode.PRESENT : TimeMode.HISTORY,
      });
    }

    first = false;
  }

  return {
    historyTableRowData,
    maxNodesAcross,
  };
}
