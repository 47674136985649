import React, {useState, useEffect} from 'react';
import {TextField, Grid} from '@material-ui/core';
import inflection from 'inflection';
import {omit} from 'lodash';
import {FilterComponentProps} from '../FilterModal';
import {newDebouncer} from '../../../../api/ajax';

// ^(0|[1-9]\d*) - pre decimal must be single 0 or +ve number starting with 1-9
// (\.+\d*)?$- optional ".", if exists only numbers can follow
const positiveNumberOrEmpty = /^(0|[1-9]\d*)(\.+\d*)?$/;
const positiveNumber = /^([1-9]\d*)$/;
const maxFloat = 9e36;

const valueOut = (value: number, transformValue?: (direction: 'out' | 'in', value: any) => any) => {
  if (!value) return '';
  return !!transformValue ? transformValue('out', value) : value;
};

const valueIn = (value: number, transformValue?: (direction: 'out' | 'in', value: any) => any) => {
  if (!value) return '';
  return !!transformValue ? transformValue('in', value) : value;
};

const debounceSearch = newDebouncer();

const NumberFilter = ({
  field,
  control,
  label,
  currentFilters,
  placeholder,
  numberType,
  min,
  max,
  transformValue,
  onSubmit,
  filterListLocation,
}: FilterComponentProps & {numberType: 'float' | 'int'}) => {
  const [internalMin, setInternalMin] = useState(valueIn(currentFilters[field]?.gte, transformValue));
  const [internalMax, setInternalMax] = useState(valueIn(currentFilters[field]?.lte, transformValue));

  const numberRegex = numberType === 'int' ? positiveNumber : positiveNumberOrEmpty;

  const autoApplyFilter = filterListLocation === 'header';

  const submitField = () => {
    debounceSearch(() => {
      if (autoApplyFilter && onSubmit) onSubmit();
    }, 250);
  };

  useEffect(() => {
    control.register(field);
  }, [control, field]);

  useEffect(() => {
    const filterValue = currentFilters[field];
    setInternalMin(filterValue ? filterValue.gte : valueIn(filterValue?.gte, transformValue));
    setInternalMax(filterValue ? filterValue.lte : valueIn(filterValue?.lte, transformValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const numberToString = (value: number, stringValue: string) => {
    return value === parseFloat(stringValue) ? stringValue : value.toLocaleString('fullwide', {useGrouping: false});
  };

  const clearValue = (comparisonField: string, setValue: any) => {
    const currentValue = control.getValues(field);
    const newValue = omit(currentValue, comparisonField);

    setValue('');
    control.setValue(field, newValue);
    submitField();
  };

  const setMin = (newValue: string) => {
    const currentValue = control.getValues(field);
    let value = Math.min(parseFloat(newValue), maxFloat);
    value = min ? Math.max(value, min) : value;
    const filterValue = valueOut(value, transformValue);

    if (!newValue) return clearValue('gte', setInternalMin);
    if (!newValue.match(numberRegex)) return;

    control.setValue(field, {...currentValue, gte: filterValue});
    setInternalMin(numberToString(value, newValue));
    if (autoApplyFilter && onSubmit) onSubmit();
  };

  const setMax = (newValue: string) => {
    const currentValue = control.getValues(field);
    let value = Math.min(parseFloat(newValue), maxFloat);
    value = max ? Math.min(value, max) : value;
    const filterValue = valueOut(value, transformValue);

    if (!newValue) return clearValue('lte', setInternalMax);
    if (!newValue.match(numberRegex)) return;

    control.setValue(field, {...currentValue, lte: filterValue});
    setInternalMax(numberToString(value, newValue));
    if (autoApplyFilter && onSubmit) onSubmit();
  };

  const onMinBlur = () => {
    let currentValue = control.getValues(field);
    if (currentValue?.lte && internalMin && internalMin > valueIn(currentValue.lte, transformValue)) {
      currentValue = clearValue('lte', setInternalMax);
    }
  };

  const onMaxBlur = () => {
    let currentValue = control.getValues(field);
    if (currentValue?.gte && internalMax && internalMax < valueIn(currentValue.gte, transformValue)) {
      currentValue = clearValue('gte', setInternalMin);
    }
  };

  const baseLabel = label || inflection.capitalize(field);
  const fromLabel = `${baseLabel} from${min !== undefined ? ` (min: ${min})` : ''}`;
  const toLabel = `${baseLabel} to${max !== undefined ? ` (max: ${max})` : ''}`;

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <TextField
          size="small"
          fullWidth
          name={field}
          placeholder={placeholder || fromLabel}
          label={fromLabel}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            // "." passes the regex but get's converted to NaN. If "." is entered, send "0." instead
            const value = e.target.value;
            value === '.' ? setMin('0.') : setMin(value);
          }}
          onBlur={onMinBlur}
          value={internalMin}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          size="small"
          fullWidth
          name={field}
          label={toLabel}
          placeholder={placeholder || toLabel}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            // "." passes the regex but get's converted to NaN. If "." is entered, send "0." instead
            const value = e.target.value;
            value === '.' ? setMax('0.') : setMax(value);
          }}
          onBlur={onMaxBlur}
          value={internalMax}
        />
      </Grid>
    </Grid>
  );
};

export default NumberFilter;
