import axios from 'axios';
import * as qs from 'qs';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {
  ICTReport,
  ICTReportDownloadURLSGETResponse,
  ICTReportGETRequest,
  ICTReportGETResponse,
  ICTReportPOSTRequest,
} from '@common/api/models/builds/data/ICTReport';
import {uploadParamType} from '../../pages/builds/shared/SliceAttachmentMultipartUpload';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function ctReportsAllGet(filter: QueryFilter<ICTReportGETRequest>): AjaxApiResult<ICTReportGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/reports/ct/' + (query && '?' + query));
  }, 'Could not fetch CT reports');
}

export async function ctReportPOST(ctReport: ICTReportPOSTRequest): AjaxApiResult<ICTReport> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/ct/', ctReport);
  }, 'Could not create CT report');
}

export async function ctReportDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/ct/${uuid}`);
  }, 'Could not delete CT report');
}

export async function ctReportAlignmentPATCH(uuid: string, transform: number[]) {
  return ajaxWrapper(() => {
    return axios.patch(`/api/reports/ct/${uuid}/alignment`, {
      transform,
    });
  }, 'Could not send alignment for CT report');
}

// --  CT ATTACHMENT UPLOAD METHODS  -- //

export async function ctAttachmentMultipartUploadInitiatePOST(
  reportUuid: string,
  filename: string,
  filesize: number
): AjaxApiResult<uploadParamType> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/ctAttachments/multipart/initiate', {
      reportUuid,
      filename,
      filesize,
    });
  }, 'Could not upload file');
}

export async function ctAttachmentMultipartUploadCompletePOST(
  uploadId: string,
  reportUuid: string,
  key: string,
  parts: {ETag: string; PartNumber: number}[]
): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/ctAttachments/multipart/complete/', {
      uploadId,
      reportUuid,
      key,
      parts,
    });
  });
}

export async function ctAttachmentMultipartUploadAbortPOST(uploadId: string, key: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/ctAttachments/multipart/abort/', {uploadId, key});
  }, 'Could not abort upload file');
}

export async function ctReportDownloadModelPointCloudURLsGET(
  reportUuid: string
): AjaxApiResult<ICTReportDownloadURLSGETResponse[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/ct/${reportUuid}/pointCloudDownloadUrls/`);
  }, 'Could not fetch Models for CT reports');
}
