import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Paper, Grid, Button, CircularProgress} from '@material-ui/core';
import {LocationBasedDefectsStatus} from '@common/api/models/builds/data/defects/ILocationBasedDefectsReport';
import {RootState} from '../../store/reducers/index';
import NotFoundPage from '../../components/organisms/NotFoundPage';
import ReportHeader from './ReportHeader';
import ViewportTabs from './ViewportTabs';
import BuildListChips from './BuildListChips';
import LoadingPage from '../../components/organisms/LoadingPage';
import {locationBasedDefectsReportRegenerateGET} from '../../api/ajax/locationBasedDefectsReport';

function CurrentLocationBasedDefectsReport() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const [requesting, setRequesting] = useState(false);
  const report = useSelector((state: RootState) => state.locationBasedDefectsReportStore.byId[selectedUuid]);

  async function regenerateReport() {
    setRequesting(true);
    await locationBasedDefectsReportRegenerateGET(selectedUuid);
    setRequesting(false);
  }

  if (!report)
    return (
      <Paper style={{padding: '24px', height: '100%'}}>
        <NotFoundPage notFoundText={selectedUuid ? 'Report not found!' : 'Please select or create a report.'} />
      </Paper>
    );

  if ([LocationBasedDefectsStatus.Generating, LocationBasedDefectsStatus.Submitted].includes(report.status)) {
    return (
      <Paper style={{padding: '24px', height: '100%'}}>
        <LoadingPage loadingText="Report is currently generating, please wait for completion." />;
      </Paper>
    );
  }

  if (report.status === LocationBasedDefectsStatus.Failure) {
    return (
      <Paper style={{padding: '24px', height: '100%'}}>
        <NotFoundPage notFoundText="Report failed to generate, please try again or contact support">
          <Button color="primary" variant="contained" onClick={regenerateReport} disabled={requesting}>
            Regenerate Report {requesting && <CircularProgress size={20} style={{marginLeft: '8px'}} />}
          </Button>
        </NotFoundPage>
      </Paper>
    );
  }

  return (
    <Paper style={{padding: '24px', height: '100%'}}>
      <Grid container direction="column" spacing={2}>
        <ReportHeader />
        <BuildListChips />
        <ViewportTabs />
      </Grid>
    </Paper>
  );
}

export default CurrentLocationBasedDefectsReport;
