import {initialThemeState, ThemeAction, ThemeActions, ThemeState} from '../model/theme';
import createReducer from './createReducer';

export const theme = createReducer<ThemeState>(initialThemeState, {
  [ThemeActions.SET_THEME](state: ThemeState, action: ThemeAction) {
    return {
      ...state,
      currentTheme: action.payload,
    };
  },
});
