import React from 'react';

import Chart from './Chart';

interface PieChartProps {
  data: Plotly.Datum[];
  labels: Plotly.Datum[];
  height?: number;
  width?: number;
}

function PieChart(props: PieChartProps) {
  return (
    <Chart
      data={[
        {
          values: props.data,
          labels: props.labels,
          type: 'pie',
          textinfo: 'none',
        },
      ]}
      layout={{
        autosize: true,
        height: props.height,
        width: props.width,
        margin: {t: 0, b: 0, l: 0, r: 0},
        showlegend: false,
      }}
      config={{displayModeBar: false}}
    />
  );
}

export default PieChart;
