import React from 'react';
import {IMetricsResponse} from '@common/api/models/metrics';
import {Typography} from '@material-ui/core';
import LineAreaChart from '../../../components/atoms/ApexCharts/LineAreaChart';
import {chartColors} from './chartColors';
import {CenteredDiv} from './SwitchableChart';
import waitingForSomethingPNG from '../../../assets/img/waiting-for-something.png';

function SevereDefectChart({metrics: {buildsDefectCoverage}}: {metrics: IMetricsResponse}) {
  if (!buildsDefectCoverage.length)
    return (
      <CenteredDiv>
        <Typography variant="h4">No recent builds...</Typography>
        <img
          src={waitingForSomethingPNG}
          style={{maxHeight: '25vh', marginTop: '1rem'}}
          alt="bird chirping musically in a park"
        />
      </CenteredDiv>
    );

  return (
    <LineAreaChart
      chartID="builds-defect-coverage"
      valueFormatter={(val) => `${val?.toFixed(0)}%`}
      height={500}
      series={[
        {
          name: 'Severe Defect',
          data: buildsDefectCoverage.map((build) => build.defectCoverage * 100),
        },
      ]}
      options={{
        colors: [chartColors.purple],
        xaxis: {
          categories: buildsDefectCoverage.map((build) => build.name),
          title: {
            text: 'Build Name',
          },
        },
        yaxis: {
          labels: {formatter: (val) => `${val.toFixed(1)}%`},
          title: {
            text: 'Severe Defect Coverage (%)',
          },
        },
        markers: {size: buildsDefectCoverage.length > 1 ? 0 : 6},
      }}
    />
  );
}

export default SevereDefectChart;
