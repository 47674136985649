import {
  LiveStoreDeleteAction,
  LiveStoreFinishFetchAction,
  LiveStoreState,
  LiveStoreUpdateAction,
} from '../../model/liveUpdateStore';
import {IBuild} from '@common/api/models/builds/IBuild';
import {LiveStoreReducers, ReducerFunctions} from '../liveUpdateStore';

export interface BuildStoreState extends LiveStoreState<IBuild> {
  byMachineUuid: {[key: string]: IBuild[]};
  total?: number;
}

export class BuildLiveStoreReducer extends LiveStoreReducers<IBuild, BuildStoreState> {
  getReducerFunctions(
    getStoreId: (payload: IBuild) => string,
    getStoreIndex: (payload: IBuild, store: LiveStoreState<IBuild>) => number
  ): ReducerFunctions<IBuild, BuildStoreState> {
    const superReducers = super.getReducerFunctions(getStoreId, getStoreIndex);
    return {
      ...superReducers,

      update(state: BuildStoreState, action: LiveStoreUpdateAction<IBuild>): BuildStoreState {
        const storeId = getStoreId(action.payload);
        const byMachineUuid = Object.assign({}, state.byMachineUuid);

        // Delete old reference in byMachines
        const oldMachineUuid = state.byId[storeId]?.machineUuid;
        if (oldMachineUuid) {
          byMachineUuid[oldMachineUuid] = (byMachineUuid[oldMachineUuid] || []).filter(
            (build) => build.uuid !== action.payload.uuid
          );
        }

        // Update byMachines with new reference
        const newMachineUuid = action.payload.machineUuid;
        if (newMachineUuid) {
          // Filter out existing build from byMachineUuid
          const otherBuilds = (byMachineUuid[newMachineUuid] || []).filter(
            (build) => build.uuid !== action.payload.uuid
          );
          byMachineUuid[newMachineUuid] = otherBuilds.concat(action.payload);
        }

        return superReducers.update({...state, byMachineUuid}, action);
      },

      delete(state: BuildStoreState, action: LiveStoreDeleteAction<IBuild>): BuildStoreState {
        superReducers.delete(state, action);

        const storeId = getStoreId(action.payload);
        const byMachineUuid = Object.assign({}, state.byMachineUuid);

        // Delete old reference in byMachines
        const oldMachineUuid = state.byId[storeId]?.machineUuid;
        if (oldMachineUuid) {
          byMachineUuid[oldMachineUuid] = (byMachineUuid[oldMachineUuid] || []).filter(
            (build) => build.uuid !== action.payload.uuid
          );
        }

        return {...state, byMachineUuid};
      },

      finishFetch(state: BuildStoreState, action: LiveStoreFinishFetchAction<IBuild>): BuildStoreState {
        const byMachineUuid = Object.assign({}, state.byMachineUuid);

        const data = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data];
        for (const build of data) {
          const storeId = getStoreId(build);

          // Delete old reference in byMachines
          const oldMachineUuid = state.byId[storeId]?.machineUuid;
          if (oldMachineUuid) {
            byMachineUuid[oldMachineUuid] = (byMachineUuid[oldMachineUuid] || []).filter((b) => b.uuid !== build.uuid);
          }

          // Update byMachines with new reference
          const newMachineUuid = build.machineUuid;
          if (newMachineUuid) {
            // Filter out existing build from byMachineUuid
            const otherBuilds = (byMachineUuid[newMachineUuid] || []).filter((b) => b.uuid !== build.uuid);
            byMachineUuid[newMachineUuid] = otherBuilds.concat(build);
          }
        }

        return superReducers.finishFetch({...state, byMachineUuid}, action);
      },
    };
  }
}
