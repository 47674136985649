import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {
  IDefectNotification,
  defectNotificationToString,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {defectNotificationDELETE} from '../../../../api/ajax/defectNotification';

const defectNotificationTableSchema = [
  {
    title: 'Conditions',
    field: 'conditions',
    sorting: false,
    width: 'auto',
    render: (rowData: IDefectNotification) => {
      function boldOperator(string: string, operator: 'AND' | 'OR') {
        const substrings = string.split(operator);
        return (
          <>
            {substrings[0]} <b>{operator}</b> {substrings[1]}
          </>
        );
      }

      const conditionStrings = defectNotificationToString(rowData).map((string) => {
        if ('AND' === string || 'OR' === string) return <b>{string}</b>;
        if (string.includes('AND')) return boldOperator(string, 'AND');
        if (string.includes('OR')) return boldOperator(string, 'OR');
        if (string === '') return <br />;
        return string;
      });

      return conditionStrings.map((string) => <Typography>{string}</Typography>);
    },
  },
  {
    title: 'Actions',
    width: '96px',
    sorting: false,
    render: (rowData: IDefectNotification) => {
      return (
        <div>
          <IconButton
            aria-label="delete"
            onClick={() => {
              defectNotificationDELETE(rowData.uuid);
            }}
          >
            <Delete />
          </IconButton>
        </div>
      );
    },
  },
];

export default defectNotificationTableSchema;
