import React from 'react';
import {TextField} from '@material-ui/core';

import {IBuild} from '@common/api/models/builds/IBuild';

interface BuildDescriptionPanelProps {
  value: string;
  handleChange: (name: keyof IBuild, value: any) => void;
}

function BuildDescriptionPanel(props: BuildDescriptionPanelProps) {
  const {value, handleChange} = props;

  return (
    <TextField
      label="Description"
      fullWidth
      minRows={8}
      maxRows={8}
      variant="outlined"
      multiline
      value={value}
      onChange={(e) => handleChange('description', e.target.value)}
    />
  );
}

export default BuildDescriptionPanel;
