import React, {useEffect, useState, useRef} from 'react';
import {KonvaNodeEvents, Image} from 'react-konva';
import {ImageConfig} from 'konva/types/shapes/Image';
import {makeBackgroundTransparent} from './imageutils';
import {CircularProgress} from '@material-ui/core';
import Portal from './Portal';

export interface FilteredImageProps extends ImageConfig, KonvaNodeEvents {
  image: HTMLImageElement;
  id: string;
  transparentBackground?: {
    r: number;
    g: number;
    b: number;
  };
}

export function FilteredImage(props: FilteredImageProps) {
  const {image, ...rest} = props;
  const imageRef = useRef<any>(null);
  const [localImage, setLocalImage] = useState(image);

  useEffect(() => {
    if (image instanceof HTMLImageElement) {
      setLocalImage(image);
    }
  }, [image]);

  if (props.transparentBackground) {
    if (image instanceof HTMLImageElement) {
      const transparentImage = makeBackgroundTransparent(
        image,
        props.transparentBackground.r,
        props.transparentBackground.g,
        props.transparentBackground.b
      );
      setLocalImage(transparentImage);
    }
  }

  useEffect(() => {
    if (image) {
      if (image instanceof HTMLImageElement && imageRef.current) {
        // imageRef.current.cache();
        imageRef.current.getLayer().batchDraw();
      }
    }
  }, [image, props.filters, props.red, props.green, props.alpha, props.blue, props.opacity]);

  const isImageLoaded = localImage.complete && localImage.naturalWidth !== 0;

  return (
    <React.Fragment>
      {!isImageLoaded && (
        <Portal>
          <CircularProgress
            color="secondary"
            size={30}
            style={{
              margin: 'auto 0',
              color: 'rgba(25, 118, 210, 0.8)',
            }}
          />
        </Portal>
      )}
      {isImageLoaded && <Image ref={imageRef} {...rest} image={localImage} />}
    </React.Fragment>
  );
}
