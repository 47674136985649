import React, {useState} from 'react';
import moment from 'moment';
import {DateRangePicker} from 'react-date-range';
import {TextField, Dialog, Button, Grid} from '@material-ui/core';
import styled from 'styled-components';

import {renderDateString} from '../../../utils/string';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const CustomDiv = styled.div`
  margin: 20px;
  .rdrDefinedRangesWrapper {
    display: none;
  }
`;

interface FilterParams<T> {
  startDate: moment.Moment;
  endDate: moment.Moment;
  isSame: boolean;
  isRefresh: boolean;
  columnName: keyof T;
}

export const dateRangeFilter = <T,>(filter: FilterParams<T>, rowData: T) => {
  if (filter.isRefresh) return true;
  return (
    moment(rowData[filter.columnName] as string).isSameOrAfter(filter.startDate, 'day') &&
    moment(rowData[filter.columnName] as string).isSameOrBefore(filter.endDate, 'day')
  );
};

export interface FilterProps<T> {
  columnDef: any;
  onFilterChanged: (rowId: string, value: FilterParams<T>) => void;
  columnName: keyof T;
}

export default function DateRangeSelectorModal<T>(props: FilterProps<T>) {
  const [open, setOpen] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>('');
  const [isRefresh, setIsRefresh] = useState<boolean>(true);
  const initialState = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ];
  const [state, setState] = useState(initialState);

  const handleOpen = () => {
    setOpen(true);
    setIsRefresh(false);
  };

  const handleClose = () => {
    setOpen(false);
    if (isRefresh) {
      setInputVal('');
    }
  };

  const handleSave = () => {
    const startDate = moment(state[0].startDate);
    const endDate = moment(state[0].endDate);
    const isSame = startDate.isSame(endDate);
    setOpen(false);
    if (isRefresh) {
      setInputVal('');
    } else if (isSame) {
      setInputVal(renderDateString('short', startDate));
    } else {
      setInputVal(`${renderDateString('short', startDate)} - ${renderDateString('short', endDate)}`);
    }

    props.onFilterChanged(props.columnDef.tableData.id, {
      startDate,
      endDate,
      isSame,
      isRefresh,
      columnName: props.columnName,
    });
  };
  return (
    <>
      <TextField type="input" onClick={handleOpen} value={inputVal} />
      <Dialog open={open} onClose={handleClose} aria-labelledby="filter-dialog-title">
        <CustomDiv>
          <DateRangePicker
            editableDateInputs={true}
            onChange={(item: any) => {
              setIsRefresh(false);
              setState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
          <Grid container>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsRefresh(true);
                  setState(initialState);
                }}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={3} md={5} />
            <Grid item xs={7} md={5}>
              <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={handleSave}>
                Save
              </Button>
              <Button variant="contained" onClick={handleClose}>
                Exit
              </Button>
            </Grid>
          </Grid>
        </CustomDiv>
      </Dialog>
    </>
  );
}
