import React from 'react';
import {Box, ButtonGroup, useMediaQuery} from '@material-ui/core';

import {BuildState, IBuild} from '@common/api/models/builds/IBuild';
import {buildSetStateGET} from '../../../api/ajax/builds';
import {LiveBuildProps} from '../../../pages/builds/liveBuild';
import {capitalise} from '../../../utils/string';
import {DialogButton} from '../DialogButton';

function ManualBuildStateButtons(props: LiveBuildProps & {disabled?: boolean}) {
  const handleSetStateOperation = (operation: string) => {
    buildSetStateGET(props.build.uuid, operation);
  };

  const isXs = useMediaQuery('(max-width:600px)');

  if (isXs) {
    return (
      <Box display="flex" flexDirection="column">
        <ButtonGroup orientation="vertical" size="small" disabled={props.disabled}>
          <ButtonGroup orientation={'horizontal'} size="small" disabled={props.disabled}>
            {dialogButtons(props.build, Object.values(BuildState).slice(0, 4), handleSetStateOperation)}
          </ButtonGroup>
          <ButtonGroup orientation={'horizontal'} size="small" style={{marginTop: '12px'}} disabled={props.disabled}>
            {dialogButtons(props.build, Object.values(BuildState).slice(4, 7), handleSetStateOperation)}
          </ButtonGroup>
        </ButtonGroup>
      </Box>
    );
  }

  return (
    <ButtonGroup orientation="horizontal" size="small" disabled={props.disabled}>
      {dialogButtons(props.build, Object.values(BuildState), handleSetStateOperation)}
    </ButtonGroup>
  );
}

export default ManualBuildStateButtons;

const dialogButtons = (
  build: IBuild,
  buildStates: BuildState[],
  handleSetStateOperation: (operation: string) => void
) => {
  return buildStates.map((buildState) => (
    <DialogButton
      key={`build-state-button-${buildState}`}
      dialog={{
        title: `Set build state to ${buildState}`,
        content: `Are you sure you wish to set the state of "${build.name}" to ${buildState}?`,
      }}
      handleConfirm={handleSetStateOperation.bind(null, buildState)}
      text={capitalise(buildState)}
    />
  ));
};
