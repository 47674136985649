import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {similarityReportBuildsGET} from '../../../../api/ajax/similarityReport';
import {FilterSchemaType} from './index';

export const similarityComparisonsDefaultFilters = {
  sortBy: {similarityScore: 'DESC'},
};

const similarityComparisonFilters: Array<FilterSchemaType> = [
  {
    field: 'targetPartName',
    label: 'Part Name',
    type: 'string',
    excludeComparisons: ['equal', 'notEqual'],
  },
  {
    field: 'status',
    type: 'enumMultiple',
    enumValues: Object.values(SimilarityStatus),
    enumLabels: {
      [SimilarityStatus.Success]: 'Completed',
      [SimilarityStatus.Failure]: 'Failed',
      [SimilarityStatus.Generating]: 'Generating',
      [SimilarityStatus.Submitted]: 'Submitted',
    },
  },
  {
    field: 'hasWarningMessage',
    label: 'Has warning message?',
    type: 'boolean',
  },
  {
    field: 'buildUuid',
    label: 'Build Name',
    type: 'resourceSelect',
    searchField: 'buildName',
    valueField: 'buildUuid',
    searchGET: similarityReportBuildsGET,
    urlIdField: 'uuid',
  },
  {
    field: 'buildEndDate',
    label: 'Build End Date',
    type: 'dateRange',
  },
  {
    field: 'similarityScore',
    label: 'Similarity Score',
    type: 'float',
    min: 0,
    max: 100,
  },
];

export default similarityComparisonFilters;
