import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {INotification} from '@common/api/models/wip/INotification';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function notificationsAllGET(filter: QueryFilter<INotification>): AjaxApiResult<INotification[]> {
  return ajaxWrapper(() => {
    return axios.get('/api/notification/', {params: filter});
  }, 'Could not fetch notifications');
}

export async function notificationSeenPatch(uuid: string): AjaxApiResult<INotification[]> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/notification/${uuid}`);
  }, 'Could not update notifications');
}
