import {Box, Typography} from '@material-ui/core';
import React from 'react';
import DeviceAvailabilityStatus from '../../../../components/atoms/Status/DeviceAvailabilityStatus';
import {DeviceAvailability, isDeviceOnline} from '@common/api/models/devices/IDevice';
import {DateWithTimeTooltip} from './sharedColumns';
import CameraState from '../../../builds/liveBuild/shared/CameraState';
import LaserState from '../../../builds/liveBuild/shared/LaserState';

export interface MachineRow {
  availability: DeviceAvailability;
  name: string;
  model: string;
  deviceName?: string;
  deviceSerial?: string;
  registered: Date | string;
  uuid: string;
  url: string;
}

export const machineTableSchema = [
  {
    title: 'Availability',
    field: 'availability',
    width: '172px',
    render: (row: MachineRow) => <DeviceAvailabilityStatus availability={row.availability} />,
  },
  {title: 'Name', field: 'name'},
  {title: 'Manufacturer', field: 'manufacturer'},
  {title: 'Model', field: 'model'},
  {
    title: 'Device',
    field: 'device',
    render: (row: MachineRow) => row.deviceName ?? <Typography color="error">No device attached</Typography>,
  },
  {
    title: 'Registered On',
    field: 'registered',
    defaultSort: 'desc',
    type: 'date' as const,
    emptyValue: '',
    render: (row: MachineRow) => <DateWithTimeTooltip timestamp={row.registered} />,
  },
  {
    title: 'Camera State',
    field: 'cameraState',
    sorting: false,
    width: '142px',
    render: (row: MachineRow) => {
      if (!row.deviceSerial) return <Typography color="error">No device attached</Typography>;
      if (!isDeviceOnline(row.availability)) return <Typography color="error">Device is offline</Typography>;

      return (
        <Box display="flex" marginLeft="-10px">
          <CameraState deviceSerial={row.deviceSerial} />
          <LaserState deviceSerial={row.deviceSerial} />
        </Box>
      );
    },
  },
];

export default machineTableSchema;
