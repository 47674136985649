import React, {useState} from 'react';
import {Checkbox, Grid, Tab, Tabs, Tooltip, Typography} from '@material-ui/core';
import {cloneDeep} from 'lodash';
import {
  ComparisonWeightsType,
  SimilarityComparisonType,
  similarityWeightFunction,
} from '@common/api/models/builds/data/ISimilarity';
import {getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import WeightsColumn from './WeightsColumn';
import {WeightTypes} from './utils';
import LineChart from '../../../../components/atoms/ApexCharts/LineChart';
import {Info} from '@material-ui/icons';

interface WeightsFormProps {
  comparisonWeights: ComparisonWeightsType | null;
  setComparisonWeights: (newWeights: ComparisonWeightsType) => void;
}

function WeightsForm({comparisonWeights, setComparisonWeights}: WeightsFormProps) {
  const isSmallScreen = useSmallScreenSize();
  const [selectedTab, setSelectedTab] = useState<WeightTypes>(WeightTypes.Weight);

  if (!comparisonWeights) return <></>;

  const usingLogWeights = Object.values(comparisonWeights).every((value) => !value[0]);

  function onChange(defectType: SimilarityComparisonType, value: number | null, index: number) {
    const newWeights = cloneDeep(comparisonWeights!);
    newWeights[defectType][index] = value;
    setComparisonWeights(newWeights);
  }

  function toggleWeights() {
    const newWeights = cloneDeep(comparisonWeights!);
    Object.keys(newWeights).forEach((defectType) => {
      newWeights[defectType as SimilarityComparisonType][0] = usingLogWeights ? 1 : null;
    });
    setComparisonWeights(newWeights);
  }

  return (
    <Grid container spacing={2} style={{width: '100%', margin: '0px'}}>
      {isSmallScreen && (
        <Tabs value={selectedTab} onChange={(_, newTab) => setSelectedTab(newTab)}>
          {Object.values(WeightTypes).map((weighType) => (
            <Tab label={weighType} value={weighType} />
          ))}
        </Tabs>
      )}

      <DefectNamesColumn comparisonWeights={comparisonWeights} />

      {(selectedTab === WeightTypes.MidPoint || !isSmallScreen) && (
        <WeightsColumn
          weightType={WeightTypes.MidPoint}
          index={1}
          comparisonWeights={comparisonWeights}
          onChange={onChange}
          xs={isSmallScreen ? 6 : 3}
        />
      )}
      {(selectedTab === WeightTypes.Slope || !isSmallScreen) && (
        <WeightsColumn
          weightType={WeightTypes.Slope}
          index={2}
          comparisonWeights={comparisonWeights}
          onChange={onChange}
          xs={isSmallScreen ? 6 : 3}
        />
      )}
      {(selectedTab === WeightTypes.Weight || !isSmallScreen) && (
        <WeightsColumn
          weightType={WeightTypes.Weight}
          index={0}
          comparisonWeights={comparisonWeights}
          onChange={onChange}
          xs={isSmallScreen ? 6 : 3}
        />
      )}
      {(selectedTab === WeightTypes.Weight || !isSmallScreen) && (
        <DynamicLogWeightsCheckbox usingLogWeights={usingLogWeights} toggleWeights={toggleWeights} />
      )}
      <ComparisonWeightsChart comparisonWeights={comparisonWeights} />
    </Grid>
  );
}

export default WeightsForm;

function ComparisonWeightsChart({comparisonWeights}: {comparisonWeights: WeightsFormProps['comparisonWeights']}) {
  const xTicks = Array(101)
    .fill(null)
    .map((_, index) => index / 100);

  const series = Object.entries(comparisonWeights!).map(([defectType, weights]) => {
    const [weight, midPoint, slope] = weights;

    return {
      name: getPrettyDefectType(defectType as SimilarityComparisonType),
      data: xTicks.map((x) => ({
        x: x,
        y: weight === 0 ? 0 : similarityWeightFunction(midPoint, slope, x) * (!!weight ? weight : 1),
      })),
    };
  });
  return (
    <Grid item xs={12} style={{marginBottom: '16px'}}>
      <LineChart
        chartID="comparison-weights"
        series={series}
        height={200}
        type="numeric"
        options={{
          legend: {position: 'right'},
          xaxis: {
            tickAmount: 10,
            labels: {formatter: (val) => `${(Number(val) * 100).toFixed(0)}%`},
            title: {text: 'Dissimilarity'},
          },
          yaxis: {
            labels: {formatter: (val) => `${(Number(val) * 100).toFixed(0)}%`},
            title: {text: 'Weighted Similarity'},
          },
          chart: {
            toolbar: {
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: false,
                zoomout: false,
                pan: true,
                reset: true,
              },
            },
          },
          tooltip: {shared: true},
          colors: ['#0ad135', '#ff761a', '#1cb6fd', '#f12cd0', '#2265f7', '#f72121'],
        }}
      />
    </Grid>
  );
}

function DefectNamesColumn({comparisonWeights}: {comparisonWeights: WeightsFormProps['comparisonWeights']}) {
  const isSmallScreen = useSmallScreenSize();

  return (
    <Grid item container spacing={2} xs={isSmallScreen ? 6 : 3} style={{margin: '0px'}}>
      <Grid item xs={12}>
        <div />
      </Grid>
      {Object.keys(comparisonWeights!).map((defectType) => (
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}} key={`${defectType}-name-column`}>
          <Typography>
            <b>{getPrettyDefectType(defectType as SimilarityComparisonType)}</b>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

function DynamicLogWeightsCheckbox({
  usingLogWeights,
  toggleWeights,
}: {
  usingLogWeights: boolean;
  toggleWeights: () => void;
}) {
  const isSmallScreen = useSmallScreenSize();

  return (
    <>
      <Grid item container spacing={2} xs={isSmallScreen ? 6 : 9} style={{margin: '0px'}}>
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
          Dynamic log weights
          <Tooltip
            title={
              <Typography>
                If no weights are specified, logarithmically-scaled weights ranging from 0 to 10 are dynamically
                applied, with the strongest weights being applied to the scores which are most dissimilar.
              </Typography>
            }
            interactive
          >
            <Info color="action" style={{marginLeft: '6px'}} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item container spacing={2} xs={isSmallScreen ? 6 : 3} style={{margin: '0px'}}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Checkbox checked={usingLogWeights} onClick={toggleWeights} />
        </Grid>
      </Grid>
    </>
  );
}
