import {FilterSchemaType} from './index';
import {IBuildGetRequest} from '@common/api/models/builds/IBuild';
import {CTReportStatus} from '@common/api/models/builds/data/ICTReport';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {buildsAllGET} from '../../../../api/ajax/builds';
import {partsAllGET} from '../../../../api/ajax/parts';
import {QueryFilter} from '../../../../store/actions/liveUpdateStore';

export const ctReportDefaultFilters = {
  sortBy: {createdAt: 'DESC'},
};

export const ctReportFilterSchema: Array<FilterSchemaType> = [
  {
    field: 'partUuid',
    label: 'Part Name',
    type: 'resourceSelect',
    searchGET: (filters: QueryFilter<IPartGETResponse>) => partsAllGET({...filters}),
  },
  {
    field: 'buildName',
    label: 'Build Name',
    type: 'string',
  },
  {
    field: 'buildUuid',
    label: 'Build',
    type: 'resourceSelect',
    searchGET: (filters: QueryFilter<IBuildGetRequest>) => buildsAllGET({...filters}),
  },
  {
    field: 'status',
    label: 'CT Report Status',
    type: 'enumMultiple',
    enumValues: Object.values(CTReportStatus),
    enumLabels: {
      [CTReportStatus.DRAFT]: 'Draft',
      [CTReportStatus.SUBMITTED]: 'Submitted',
      [CTReportStatus.GENERATING]: 'Generating',
      [CTReportStatus.ALIGNMENT]: 'Waiting for Alignment',
      [CTReportStatus.ALIGNING]: 'Aligning',
      [CTReportStatus.COMPLETED]: 'Completed',
      [CTReportStatus.FAILED]: 'Failed',
    },
  },
  {field: 'buildEndDate', label: 'Build End Date', type: 'dateRange'},
];
