import React from 'react';
import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {ChipStatus} from '../../../components/atoms/Status/Status';

export default function SimilarityStatusChip({
  similarityStatus,
  size,
}: {
  similarityStatus: SimilarityStatus;
  size?: 'small' | 'medium' | undefined;
}) {
  switch (similarityStatus) {
    case SimilarityStatus.Submitted:
      return <ChipStatus message="Submitted" variant="info" chipSize={size} />;
    case SimilarityStatus.Generating:
      return <ChipStatus message="Generating" variant="info" chipSize={size} />;
    case SimilarityStatus.Success:
      return <ChipStatus message="Complete" variant="success" chipSize={size} />;
    case SimilarityStatus.Failure:
      return <ChipStatus message="Failed" variant="error" chipSize={size} />;
    default:
      return <></>;
  }
}
