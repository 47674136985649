import {toast} from 'react-toastify';

export function downloadUrlMultiple(downloads: [string, string][]) {
  if (downloads.length === 0) return;

  const thisDownload = downloads.pop();
  downloadUrl(...thisDownload!);
  // For some reason, need to wait a second before triggering the next or it gets ignored by the browser
  setTimeout(() => downloadUrlMultiple(downloads), 1000);
}

export function downloadUrl(url: string, filename: string) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = url;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadBlob(filename: string, blob: Blob) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export async function downloadResource(url: string, filename: string) {
  try {
    const response = await fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: 'cors',
    });

    if (response.status < 400) {
      const blob = await response.blob();

      if (blob) {
        let blobUrl = window.URL.createObjectURL(blob);
        downloadUrl(blobUrl, filename);
      }
    } else {
      toast(`Failed to download ${filename}`, {type: 'error'});
    }
  } catch (e) {
    toast(`Failed to download ${filename}`, {type: 'error'});
    console.error(e);
  }
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}
