import React from 'react';
import BarChart, {BarChartProps} from './BarChart';

type HorizontalBarChartProps = Omit<BarChartProps, 'orientation'>;

function HorizontalBarChart({disableZoom = true, ...props}: HorizontalBarChartProps) {
  // Convert input data points into a horizontal bar chart
  const transformedData = props.data.map((data) => {
    return {...data, type: 'bar' as const, orientation: 'h' as const};
  });

  const transformedLayout: Partial<Plotly.Layout> = {
    // Top margin has too much spacing if there's no title provided
    margin: props.layout?.title ? props.margin : {t: 20, ...props.margin},
    yaxis: {
      fixedrange: disableZoom,
      automargin: true,
      // Add spacing between tick labels and y-axis line
      showticksuffix: 'all',
      ticksuffix: ' ',
    },
    ...props.layout,
  };

  return (
    <BarChart
      {...props}
      orientation="h"
      data={transformedData.reverse()} // Horizontal Bar Chart seems to render bottom to top
      layout={transformedLayout}
    />
  );
}

export default HorizontalBarChart;
