import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';

import {IBuild} from '@common/api/models/builds/IBuild';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {
  IMachineParameter,
  MACHINE_PARAMETER_DEFAULT_FIELDS,
  MachineParameterDefaultKeys,
} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';

import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {Link} from 'react-router-dom';
import {
  useBatchStoreActions,
  useDeviceStoreActions,
  useMachineParameterStoreActions,
  useMachineStoreActions,
  useSensorProfileStoreActions,
} from '../../../store/actions';

import {useSmallScreenSize, usePermissionsForBuild} from '../../../utils/utilHooks';
import {grayColor} from '../../../assets/jss/material-dashboard-react';
import {renderDateTimeString} from '../../../utils/string';

interface BuildMetaCardProps {
  build: IBuild;
}

export function BuildMetaCard({build}: BuildMetaCardProps) {
  const isSmall = useSmallScreenSize();
  /* Machine */
  const machineStoreActions = useMachineStoreActions();
  const machineStore = useSelector((state: RootState) => state.machineStore);
  const machine = machineStore.byId[build.machineUuid!];

  /* Device */
  const deviceStoreActions = useDeviceStoreActions();
  const deviceStore = useSelector((state: RootState) => state.deviceStore);
  const device = deviceStore.byId[build.deviceSerial!];

  /* Batch */
  const batchStoreActions = useBatchStoreActions();
  const batchStore = useSelector((state: RootState) => state.batchStore);
  const batch = batchStore.byId[build.batchUuid!];
  const currentOrSuccessorUuid = (batch: IBatch) => {
    return batch.successorAUuid || batch.successorBUuid || batch.uuid;
  };

  /* Machine parameters */
  const machineParametersStoreActions = useMachineParameterStoreActions();
  const machineParametersStore = useSelector((state: RootState) => state.machineParameterStore);
  const machineParameters = machineParametersStore.byId[build.machineParametersUuid!];

  const calibration = useSelector((state: RootState) => state.calibrationStore.byId[build.calibrationUuid!]);

  const sensorProfileStoreActions = useSensorProfileStoreActions();
  const sensorProfileStore = useSelector((state: RootState) => state.sensorProfileStore);
  const sensorProfile = sensorProfileStore.byId[build.sensorProfileUuid!];

  const {machinePermission} = usePermissionsForBuild(build);

  const buildDescriptionLines = (build.description || '-').split(/\n/);

  useEffect(() => {
    if (build.machineUuid) {
      machineStoreActions.ensureConsistent({uuid: build.machineUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.machineUuid]);

  useEffect(() => {
    if (build.deviceSerial) {
      deviceStoreActions.ensureConsistent({serial: build.deviceSerial});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.deviceSerial]);

  useEffect(() => {
    if (build.batchUuid) {
      batchStoreActions.ensureConsistent({uuid: build.batchUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.batchUuid]);

  useEffect(() => {
    if (build.machineParametersUuid) {
      machineParametersStoreActions.ensureConsistent({
        uuid: build.machineParametersUuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.machineParametersUuid]);

  useEffect(() => {
    if (build.sensorProfileUuid) {
      sensorProfileStoreActions.ensureConsistent({uuid: build.sensorProfileUuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.sensorProfileUuid]);

  return (
    <Card style={{padding: isSmall ? '7px' : '15px'}}>
      <CardHeader title="Build Metadata" style={{paddingBottom: 0}} />

      <CardContent style={{paddingBottom: '8px'}}>
        <Grid container style={{marginBottom: '-1px'}}>
          <FieldValue
            field="Layer Count"
            value={`${build.lastPrintedLayerId || 'N/A'} / ${build.numLayers || 'N/A'}`}
          />

          <FieldValue field="Start Date" value={build.start ? renderDateTimeString('short', build.start) : undefined} />
          <FieldValue
            field="End Date"
            value={build.end ? renderDateTimeString('short', build.end) : undefined}
            isLoadable={false}
          />
          <FieldValue
            field="Description"
            value={
              <>
                {buildDescriptionLines.map((line, index) => (
                  <Typography
                    key={line}
                    style={
                      index === 0
                        ? {marginBottom: '6px'}
                        : index === buildDescriptionLines.length - 1
                        ? {marginTop: '6px'}
                        : {margin: '6px 0px'}
                    }
                  >
                    {line}
                  </Typography>
                ))}
              </>
            }
            isLoadable={false}
          />
          <FieldValue
            field="Machine"
            value={
              !machinePermission
                ? '******'
                : machine && (
                    <Link style={{textDecoration: 'none'}} to={`/machines/uuid/${machine.uuid}`}>
                      {machine.name}
                    </Link>
                  )
            }
          />
          <FieldValue
            field="Device"
            value={
              !machinePermission
                ? '******'
                : machine &&
                  device && (
                    <Link style={{textDecoration: 'none'}} to={`/machines/uuid/${machine.uuid}`}>
                      {device.deviceId}
                    </Link>
                  )
            }
          />
          <FieldValue
            field="Batch"
            value={
              batch && (
                <Link style={{textDecoration: 'none'}} to={`/batches/uuid/${currentOrSuccessorUuid(batch)}/timeline/`}>
                  {batch.name}
                </Link>
              )
            }
          />
          <FieldValue
            field="Machine Parameters"
            value={machineParameters && <MachineParametersCollapse machineParameters={machineParameters} />}
            isLoadable={false}
          />
          <FieldValue
            field="Calibration Date"
            value={calibration && renderDateTimeString('short', calibration.registered)}
          />
          <FieldValue
            field="Calibration Scale"
            value={calibration && `${parseFloat((calibration.scale * 1000).toFixed(2))} µm/px`}
          />
          <FieldValue
            field="Sensor Profile"
            value={
              sensorProfile && (
                <Link style={{textDecoration: 'none'}} to={`/sensorProfiles/${build.sensorProfileUuid}`}>
                  {sensorProfile.name}
                </Link>
              )
            }
          />
        </Grid>
        <div style={{width: '100%', height: '1px', backgroundColor: 'white', position: 'relative'}} />
      </CardContent>
    </Card>
  );
}

function MachineParametersCollapse({machineParameters}: {machineParameters: IMachineParameter}) {
  const [parametersExpanded, setParametersExpanded] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {machineParameters!.name}
        <IconButton
          onClick={() => setParametersExpanded(!parametersExpanded)}
          aria-expanded={parametersExpanded}
          aria-label="show more"
          size="small"
          // CSS overrides are to align icon with text
          style={{height: 17, width: 17, marginLeft: '10px'}}
        >
          {parametersExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={parametersExpanded}>
          <Grid container spacing={1} style={{marginBottom: '12px'}}>
            {/* Parameter notes */}
            {machineParameters.genericNotes && (
              <>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Notes</Typography>
                </Grid>
                <Grid item xs={6}>
                  {machineParameters.genericNotes}
                </Grid>
              </>
            )}

            {/* Parameter values - built-in */}
            {MACHINE_PARAMETER_DEFAULT_FIELDS.map(
              ([key, name, unit]) =>
                machineParameters.data[key] && (
                  <React.Fragment key={`${name}-${machineParameters.data[key]}`}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {machineParameters.data[key]} {unit}
                    </Grid>
                  </React.Fragment>
                )
            )}

            {/* Parameter values - custom */}
            {Object.keys(machineParameters.data)
              // Get keys that are not default
              .filter((key) => !(Object.values(MachineParameterDefaultKeys) as string[]).includes(key))
              .map((key) => (
                <React.Fragment key={`${key}-${machineParameters.data[key]}`}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">{key}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {machineParameters.data[key]}
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}

function FieldValue({
  field,
  value,
  isLoadable = true,
}: {
  field: string;
  value?: string | JSX.Element | false;
  isLoadable?: boolean;
}) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} style={{borderBottom: `1px solid ${grayColor[5]}`, padding: '14px 0px'}}>
      <Typography style={{color: grayColor[7], fontSize: '0.85rem', paddingBottom: '6px', fontWeight: 500}}>
        {field}
      </Typography>
      {value ||
        (isLoadable ? (
          <Skeleton width="100%" />
        ) : (
          <Typography>
            <b>-</b>
          </Typography>
        ))}
    </Grid>
  );
}
