import React, {useEffect, Dispatch, SetStateAction} from 'react';
import {Box, Button, CircularProgress} from '@material-ui/core';
import {CameraFocusingState, DeviceState, IDevice} from '@common/api/models/devices/IDevice';
import ConditionalTooltip from '../../../../../components/atoms/Texts/ConditionalTooltip';
import {buildFocusGET, buildPreviewGET, buildStopGET} from '../../../../../api/ajax/builds';
import {CameraRow} from '../ActiveStepFOCUS';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import {useCameraState} from '../../shared/CameraState';

const ActionButtons = ({
  cameraRows,
  device,
  focusResults,
  buildUuid,
  waitingForFocus,
  setWaitingForFocus,
  setDidStartManualFocusing,
  setWaitingForPreview,
  waitingForPreview,
  setFocusingError,
  setPreviewError,
}: {
  cameraRows: CameraRow[];
  device: IDevice;
  focusResults: CameraFocusingState[];
  buildUuid: string;
  waitingForFocus: boolean;
  setWaitingForFocus: Dispatch<SetStateAction<boolean>>;
  setDidStartManualFocusing: Dispatch<SetStateAction<boolean>>;
  waitingForPreview: boolean;
  setWaitingForPreview: Dispatch<SetStateAction<boolean>>;
  setFocusingError: Dispatch<SetStateAction<boolean>>;
  setPreviewError: Dispatch<SetStateAction<boolean>>;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const {allCamerasConnected} = useCameraState(device.serial);

  const isAutoFocusInProgress = device.state === DeviceState.FOCUSING || waitingForFocus;
  const isPreviewInProgress = device.state === DeviceState.PREVIEWING || waitingForPreview;

  const isCalibrating = device.state === DeviceState.CALIBRATING;

  const buttonStyle = isSmallScreen ? {marginTop: '12px'} : {marginLeft: '12px'};

  const selectedCameras = cameraRows.filter((camera) => camera.selected).map((camera) => camera.id);

  const handleStartFocus = async () => {
    setFocusingError(false);
    setPreviewError(false);
    setWaitingForFocus(true);
    try {
      const res = await buildFocusGET(buildUuid, selectedCameras);
      if (!res.success) setWaitingForFocus(false);
    } catch {
      setWaitingForFocus(false);
    }
  };

  const handleStopFocus = async () => {
    // If the front-end thinks the build is already not focusing,
    // pretend that success occurred instantly as there won't be a state transition.
    setWaitingForFocus(device.state === DeviceState.FOCUSING);
    await buildStopGET(buildUuid);
  };

  const handleStartPreview = async () => {
    setFocusingError(false);
    setPreviewError(false);
    setWaitingForPreview(true);
    try {
      const res = await buildPreviewGET(buildUuid);
      setDidStartManualFocusing(true);
      if (!res.success) setWaitingForPreview(false);
    } catch {
      setWaitingForPreview(false);
    }
  };

  const handleStopPreview = async () => {
    setWaitingForPreview(device.state === DeviceState.PREVIEWING);
    await buildStopGET(buildUuid);
  };

  useEffect(() => {
    setWaitingForFocus(false);
    setWaitingForPreview(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.state]);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      mt={isSmallScreen ? 1 : 4}
      flexDirection={isSmallScreen ? 'column' : 'row'}
    >
      {isPreviewInProgress && (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleStopPreview}
          style={buttonStyle}
          disabled={waitingForPreview}
          fullWidth={isSmallScreen}
          size={isSmallScreen ? 'small' : 'medium'}
        >
          Stop Manual Focus
        </Button>
      )}

      {!isAutoFocusInProgress && (
        <ConditionalTooltip
          tooltip={
            'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before starting manual focus.'
          }
          hideTooltip={allCamerasConnected}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={handleStartPreview}
            style={buttonStyle}
            disabled={isPreviewInProgress || isCalibrating || !allCamerasConnected}
            fullWidth={isSmallScreen}
            size={isSmallScreen ? 'small' : 'medium'}
          >
            Launch Manual Focus
            {isPreviewInProgress ? <CircularProgress style={{marginLeft: '12px'}} size={20} /> : ''}
          </Button>
        </ConditionalTooltip>
      )}

      {isAutoFocusInProgress && (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleStopFocus}
          style={buttonStyle}
          disabled={waitingForFocus}
          fullWidth={isSmallScreen}
          size={isSmallScreen ? 'small' : 'medium'}
        >
          Cancel Autofocus
        </Button>
      )}

      {!isPreviewInProgress && device.supportsAutofocus && (
        <ConditionalTooltip
          tooltip={
            !allCamerasConnected
              ? 'One or more cameras are disconnected. Please ensure all cameras are correctly connected to the device before starting auto focus.'
              : 'No Cameras Selected'
          }
          hideTooltip={!!selectedCameras.length && allCamerasConnected}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleStartFocus}
            style={buttonStyle}
            disabled={!selectedCameras.length || isAutoFocusInProgress || isCalibrating || !allCamerasConnected}
            fullWidth={isSmallScreen}
            size={isSmallScreen ? 'small' : 'medium'}
          >
            {!focusResults.length ? 'Launch Autofocus' : 'Focus Selected Cameras'}
            {isAutoFocusInProgress && <CircularProgress style={{marginLeft: '12px'}} size={20} />}
          </Button>
        </ConditionalTooltip>
      )}
    </Box>
  );
};

export default ActionButtons;
