import {DefectSeverity, DefectType, getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {FilterSchemaType} from './index';

export const defectStatisticDefaultFilters = {
  sortBy: {layerNum: 'DESC', defectArea: 'DESC'},
};

const FILTERABLE_DEFECT_TYPES = Object.values(DefectType).filter((defectType) => defectType !== DefectType.Unknown);

const defectStatisticFilters: Array<FilterSchemaType> = [
  {
    field: 'defectType',
    label: 'Type',
    type: 'enumMultiple',
    enumValues: FILTERABLE_DEFECT_TYPES,
    enumLabels: FILTERABLE_DEFECT_TYPES.reduce(
      (defectTypes, current) => ({
        [current]: getPrettyDefectType(current),
        ...defectTypes,
      }),
      {}
    ),
  },
  {
    field: 'severity',
    type: 'enumMultiple',
    enumValues: Object.values(DefectSeverity),
  },
  {
    field: 'partName',
    label: 'Part Name',
    type: 'string',
  },
  {
    field: 'layerNum',
    label: 'Layer',
    type: 'int',
    min: 0,
  },
  {
    field: 'defectArea',
    label: 'Area (mm²)',
    type: 'float',
    min: 0,
  },
  {
    field: 'defectCoverage',
    label: 'Coverage (%)',
    type: 'float',
    min: 0,
    max: 100,
  },
  {
    field: 'excludePartialLayers',
    label: 'Exclude Partial Layers',
    type: 'boolean',
  },
];

export default defectStatisticFilters;
