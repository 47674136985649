import React, {useState} from 'react';
import {IconButton} from '@material-ui/core';
import {Alert as MuiAlert, AlertTitle} from '@material-ui/lab';
import {spacing} from '@material-ui/system';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import styled from 'styled-components';
import {renderDateTimeString} from '../../utils/string';
import {useSmallScreenSize} from '../../utils/utilHooks';

const Alert = styled(MuiAlert)(spacing);

export interface OfflineDeviceAlertProps {
  lastActivityTime?: Date;
  children?: React.ReactNode;
  mt?: number;
  mb?: number;
}

export default function OfflineDeviceAlert(props: OfflineDeviceAlertProps) {
  const [expanded, setExpanded] = useState(false);
  const isSmallScreen = useSmallScreenSize();

  const lastSeenText = props.lastActivityTime
    ? `, last seen at ${renderDateTimeString('short', props.lastActivityTime)}.`
    : '';
  const alertTitle = `Device is offline${lastSeenText}`;

  return (
    <Alert
      severity="warning"
      mt={props.mt !== undefined ? props.mt : isSmallScreen ? 3 : 6}
      mb={props.mb}
      action={
        <IconButton size="small" onClick={() => setExpanded(!expanded)} aria-label="expand">
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      }
    >
      {expanded ? (
        <>
          <AlertTitle>{alertTitle}</AlertTitle>
          {props.children}
        </>
      ) : (
        // Put alert title in body when not expanded (title looks odd on its own)
        alertTitle
      )}
    </Alert>
  );
}
