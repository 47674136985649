/**
 * See https://github.com/plotly/plotly.js#modules for more details.
 */
import Plotly from 'plotly.js/lib/core';
import Pie from 'plotly.js/lib/pie';
import Bar from 'plotly.js/lib/bar';
import Scatter from 'plotly.js/lib/scatter';

Plotly.register([Bar, Pie, Scatter]);

export default Plotly;
