import React from 'react';
import {humanize} from 'inflection';
import {TextField, MenuItem} from '@material-ui/core';

function CameraModeSelect({
  availableModes,
  onChange,
  value,
  disabled,
}: {
  availableModes: string[];
  onChange: (mode: string) => void;
  value: string | null;
  disabled?: boolean;
}) {
  return (
    <TextField
      select
      fullWidth
      disabled={disabled}
      size="small"
      value={value}
      onChange={(e) => !disabled && onChange(e.target.value)}
      variant="outlined"
      label="Camera mode"
      SelectProps={{labelWidth: 50}}
    >
      {availableModes.map((mode, i) => (
        <MenuItem key={i} value={mode}>
          {mode === 'params_preview'
            ? humanize(mode.replace('params_preview', 'manual focus'))
            : humanize(mode.replace('params_', ''))}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default CameraModeSelect;
