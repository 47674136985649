import axios from 'axios';
import * as qs from 'qs';
import {
  ISensorProfileGETRequest,
  ISensorProfileGETResponse,
  ISensorProfilePUTRequest,
  ISensorProfileConfigDefaultsResponse,
  ISensorProfileYAMLResponse,
} from '@common/api/models/devices/ISensorProfile';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import {SensorProfileType} from '../../store/model/sensorProfile';

export async function sensorProfilesGET(
  filter: QueryFilter<ISensorProfileGETRequest>
): AjaxApiResult<ISensorProfileGETResponse[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/sensorProfiles/' + (query && '?' + query));
  }, 'Could not fetch sensor profiles');
}

export async function sensorProfilesPUT(
  uuid: string,
  params: ISensorProfilePUTRequest
): AjaxApiResult<ISensorProfileGETResponse> {
  return ajaxWrapper(() => {
    return axios.put(`/api/sensorProfiles/${uuid}/`, params);
  }, 'Failed to save sensor profile');
}

export async function sensorProfilesDELETE(uuid: string): AjaxApiResult<ISensorProfileGETResponse> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/sensorProfiles/${uuid}/`);
  }, 'Failed to delete sensor profile');
}

export async function configDefaultsGET(deviceSerial: string): AjaxApiResult<ISensorProfileConfigDefaultsResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/sensorProfiles/configDefaults/${deviceSerial}`);
  }, 'Failed to load sensor profiles configuration');
}

export async function profileYAMLGet(uuid: string): AjaxApiResult<ISensorProfileYAMLResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/sensorProfiles/${uuid}/profileYAML`);
  }, 'Failed to load sensor profile');
}

export async function sensorProfilesPOST(
  name: string,
  deviceSerial: string,
  profileJSON: SensorProfileType
): AjaxApiResult<ISensorProfileGETResponse> {
  return ajaxWrapper(() => {
    return axios.post(`/api/sensorProfiles/`, {
      name,
      deviceSerial,
      profileJSON,
    });
  }, 'Failed to create sensor profile');
}
