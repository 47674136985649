import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Layout, Route as RouteRecord} from './routes';

import DashboardLayout from '../layouts/Dashboard';
import LandingLayout from '../layouts/LandingLayout';
import AuthLayout from '../layouts/Auth';
import Page404 from '../pages/auth/Page404';
import ExternalSupport from '../pages/ExternalSupport';
import {useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {LogInState} from '../store/model/auth';
import FileUploadProvider from '../utils/FileUploadContext';
import NoRole from '../pages/NoRole';
import Unauthorized from '../pages/Unauthorized';
import useCookieConsent from '../components/molecules/cookies/CookieConsent';
import MultiPartUploadProvider from '../utils/contexts/MultiPartUploadContext';

const childRoutes = (LayoutComponent: any, routes: RouteRecord[]) =>
  routes.map((route, index) => {
    if ('children' in route) {
      return route.children.map((childRoute, index) => {
        const {path, component: Component} = childRoute;
        return (
          <Route
            key={`${path}-${index}`}
            path={path}
            exact
            render={(props) => (
              <LayoutComponent routes={routes} currentRoute={childRoute}>
                <Component {...props} />
              </LayoutComponent>
            )}
          />
        );
      });
    } else {
      const Component = route.component;
      return (
        <Route
          key={index}
          path={route.path}
          exact
          render={(props) => (
            <LayoutComponent routes={routes} currentRoute={route}>
              <Component {...props} />
            </LayoutComponent>
          )}
        />
      );
    }
  });

export interface RouteRouteParams {
  routes: RouteRecord[];
}

const RootRoute = ({routes}: RouteRouteParams) => {
  const auth = useSelector((state: RootState) => state.auth);

  useCookieConsent();

  return (
    <Router>
      <FileUploadProvider>
        <MultiPartUploadProvider>
          <Switch>
            {auth.logInState === LogInState.LoggedIn &&
              childRoutes(
                DashboardLayout,
                routes.filter((r) => r.layout === Layout.DASHBOARD)
              )}
            {childRoutes(
              AuthLayout,
              routes.filter((r) => r.layout === Layout.AUTH)
            )}
            <Route
              render={(props) => {
                if (auth.logInState === LogInState.Unknown) {
                  return (
                    <LandingLayout>
                      <h4>Loading...</h4>
                    </LandingLayout>
                  );
                } else if (auth.logInState === LogInState.LoggedOut) {
                  // If adding more unauthenticated routes, be sure to add to the const UNAUTHENTICATED_ROUTES in ReduxRoot.tsx
                  if (props.location.pathname.includes('/support')) {
                    return (
                      <LandingLayout>
                        <ExternalSupport />
                      </LandingLayout>
                    );
                  } else if (props.location.pathname.includes('/noRole')) {
                    return (
                      <LandingLayout>
                        <NoRole />
                      </LandingLayout>
                    );
                  } else if (props.location.pathname.includes('/unauthorized')) {
                    return (
                      <LandingLayout>
                        <Unauthorized />
                      </LandingLayout>
                    );
                  }
                } else {
                  return (
                    <LandingLayout>
                      <Page404 />
                    </LandingLayout>
                  );
                }
              }}
            />
          </Switch>
        </MultiPartUploadProvider>
      </FileUploadProvider>
    </Router>
  );
};

export default RootRoute;
