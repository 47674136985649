import {LiveStoreDeleteAction, LiveStoreState} from '../../model/liveUpdateStore';
import {IBuildNote} from '@common/api/models/builds/IBuildNote';
import {LiveStoreReducers, ReducerFunctions} from '../liveUpdateStore';

export class BuildNotesLiveStoreReducer extends LiveStoreReducers<IBuildNote, LiveStoreState<IBuildNote>> {
  getReducerFunctions(
    getStoreId: (payload: IBuildNote) => string,
    getStoreIndex: (payload: IBuildNote, store: LiveStoreState<IBuildNote>) => number
  ): ReducerFunctions<IBuildNote, LiveStoreState<IBuildNote>> {
    const superReducers = super.getReducerFunctions(getStoreId, getStoreIndex);
    return {
      ...superReducers,

      delete(state: LiveStoreState<IBuildNote>, action: LiveStoreDeleteAction<IBuildNote>): LiveStoreState<IBuildNote> {
        const modifiedAction = Object.assign({}, action);

        // Date returned is stored is expected in UTC time but without the
        // trailing 'Z' which the store include for its keys
        //@ts-ignore
        modifiedAction.payload.createdAt = new Date(action.payload.createdAt + 'Z').toISOString();

        return superReducers.delete(state, modifiedAction);
      },
    };
  }
}
