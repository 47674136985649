import {IMachine} from './IMachine';

export function getMachineCalibPattern(machine: IMachine) {
  if (!machine) return;

  return MACHINE_DETAILS[machine.manufacturer]?.[machine.model]?.calibPattern;
}

export function getMachineCalibFile(machine: IMachine) {
  if (!machine) return;
  return MACHINE_DETAILS[machine.manufacturer]?.[machine.model]?.calibFile;
}

export function getMachineImage(machine: IMachine) {
  if (!machine) return;

  return MACHINE_DETAILS[machine.manufacturer]?.[machine.model]?.image;
}

export function getAllModels(includeManufacturer = false) {
  return Object.entries(MACHINE_DETAILS)
    .map(([manufacturer, manufacturerDetails]) =>
      Object.keys(manufacturerDetails).map((model) => (includeManufacturer ? `${manufacturer} ${model}` : model))
    )
    .reduce((allModels, models) => [...allModels, ...models]);
}

export enum CalibPattern {
  C250 = 'CalibPatternC-250',
  C280 = 'CalibPatternC-280',
  D = 'CalibPatternD',
  E = 'CalibPatternE',
  F = 'CalibPatternF',
  G = 'CalibPatternG',
  H = 'CalibPatternH',
}

// Must match the filenames as defined in /client/public/files/
export enum CalibFile {
  C250 = 'calib_31x31_sq225mm_quadID-Ren.stl',
  C250_EOS = 'calib_31x31_sq225mm_quadID-EOS.stl',
  C280 = 'calib_31x31_sq225mm_quadID-Ren.stl',
  D = 'calib_31x37_SisRound_quadID.stl',
  E = 'calib_35x38_500L_quadID.stl',
  F = 'calib_RBV.STL',
  G = 'calib_420x420_AI_Metalfab.STL',
  H = '',
}

// Taken from https://docs.google.com/spreadsheets/d/1wOlZo-OSpiiwOkDZp-_xOyCuxkUeVTD_0sWJzH6AomE/edit#gid=0
// Or ask Marten for the latest version
export const MACHINE_DETAILS: {
  [manufacturer: string]: {
    [model: string]: {
      calibFile: CalibFile;
      calibPattern: CalibPattern;
      image: string;
    };
  };
} = {
  EOS: {
    M280: {
      calibFile: CalibFile.C250_EOS,
      calibPattern: CalibPattern.C250,
      image: 'EOS_M280.png',
    },
    M290: {
      calibFile: CalibFile.C250_EOS,
      calibPattern: CalibPattern.C250,
      image: 'EOS_M290.png',
    },
    M400: {
      calibFile: CalibFile.G,
      calibPattern: CalibPattern.G,
      image: 'EOS_M400.png',
    },
  },
  Renishaw: {
    AM250: {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'RENISHAW_AM250.png',
    },
    AM400: {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'RENISHAW_AM400.png',
    },
    'RenAM 500': {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'RENISHAW_AM500_Q.png',
    },
    'RenAM 500 RBV': {
      calibFile: CalibFile.F,
      calibPattern: CalibPattern.F,
      image: 'RENISHAW_AM500_Q.png',
    },
  },
  'SLM Solutions': {
    '250HL': {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'SLM_250HL_gen2.png',
    },
    '280': {
      calibFile: CalibFile.C280,
      calibPattern: CalibPattern.C280,
      image: 'SLM_280.png',
    },
    '280HL': {
      calibFile: CalibFile.C280,
      calibPattern: CalibPattern.C280,
      image: 'SLM_280HL.png',
    },
    '500': {
      calibFile: CalibFile.E,
      calibPattern: CalibPattern.E,
      image: 'SLM_500.png',
    },
    '500HL': {
      calibFile: CalibFile.E,
      calibPattern: CalibPattern.E,
      image: 'SLM_500HL.png',
    },
  },
  '3D Systems': {
    'ProX DMP 320': {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'ProX_DMP_320.png',
    },
  },
  Trumpf: {
    'TruPrint 3000': {
      calibFile: CalibFile.D,
      calibPattern: CalibPattern.D,
      image: 'Trumpf_TruPrint_3000.png',
    },
  },
  Sisma: {
    'MYSINT 300': {
      calibFile: CalibFile.D,
      calibPattern: CalibPattern.D,
      image: 'SISMA_Mysint_300.png',
    },
  },
  'Concept Laser': {
    'M2 Series 4': {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'M2_Series_4.png',
    },
    'M2 Series 5': {
      calibFile: CalibFile.C250,
      calibPattern: CalibPattern.C250,
      image: 'M2_Series_5.png',
    },
  },
  'Additive Industries': {
    'MetalFAB G2': {
      calibFile: CalibFile.G,
      calibPattern: CalibPattern.G,
      image: 'Additive_Industries_MetalFAB_G2.png',
    },
    'MetalFAB G2 (experimental)': {
      calibFile: CalibFile.H,
      calibPattern: CalibPattern.H,
      image: 'Additive_Industries_MetalFAB_G2.png',
    },
  },
};
