import {Role} from '@common/api/models/users/IUser';
import {FiltersState} from '../IndexPage';
import {FilterSchemaType} from './index';
import envConfig from '../../../../envConfig';

export const userDefaultFilters: Partial<FiltersState> = {
  sortBy: {
    lastActivityOn: 'DESC',
  },
};

export const userFilterSchema: Array<FilterSchemaType> = [
  {
    field: 'firstName',
    type: 'string',
    label: 'First Name',
  },
  {
    field: 'lastName',
    type: 'string',
    label: 'Last Name',
  },
  {
    field: 'email',
    type: 'string',
    label: envConfig.isOnPremise ? 'Email / Username' : 'Email',
    excludeComparisons: ['equal', 'notEqual'],
    requiredAuth: Role.ADMINISTRATOR,
  },
  {
    field: 'lastActivityOn',
    label: 'Last Activity',
    type: 'dateTimeRange',
  },
  {
    field: 'registered',
    type: 'dateRange',
  },
];
