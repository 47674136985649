import {IMachineParameter} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import qs from 'qs';

export async function machineParametersAllGET(
  filter: QueryFilter<IMachineParameter>
): AjaxApiResult<IMachineParameter[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/machineParameters/' + (query && '?' + query));
  }, 'Could not fetch machineParameters');
}

export async function machineParameterByUuidGET(uuid: string): AjaxApiResult<IMachineParameter> {
  return ajaxWrapper(() => {
    return axios.get(`/api/machineParameters/uuid/${uuid}`);
  }, 'Could not fetch machine parameter');
}

export async function machineParameterByUuidDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/machineParameters/uuid/${uuid}`);
  }, 'Could not delete machine parameter');
}

export async function machineParameterCreatePOST(obj: IMachineParameter): AjaxApiResult<IMachineParameter> {
  return ajaxWrapper(() => {
    return axios.post('/api/machineParameters/', obj);
  }, 'Could not create machine parameter');
}
