const props = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiCardHeader: {
    titleTypographyProps: {variant: 'h6'},
  },
};

export default props;
