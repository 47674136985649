import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {spacing} from '@material-ui/system';
import {
  Avatar,
  Badge,
  Box as MuiBox,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
  Divider,
} from '@material-ui/core';
import {ExpandLess, ExpandMore, MenuOpen, Menu as MenuIcon} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

import {Power} from 'react-feather';
import {NavLink as RouterNavLink, withRouter} from 'react-router-dom';
import {rgba} from 'polished';
import {useSelector} from 'react-redux';

import '../../../vendor/perfect-scrollbar.css';

import {getRoleName} from '@common/api/models/users/IUser';
import {useActions, useUserStoreActions} from '../../store/actions';
import * as AuthActions from '../../store/actions/auth';
import aa_logo_text from '../../assets/img/aa_logo_rgb_lite_shadow.png';
import aa_logo_only from '../../assets/img/logo.png';
import {useSmallScreenSize} from '../../utils/utilHooks';

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink style={{textDecoration: 'none'}} innerRef={ref} {...props} />
));

const Box = styled(MuiBox)(spacing);

const TopDrawer = styled(MuiDrawer)`
  .MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid #ec813d;
  }
`;
const Drawer = styled(MuiDrawer)`
  border-right: 0;
  width: ${({hover}) => (hover ? '260px' : '64px')} !important;
  .MuiPaper-root {
    width: ${({hover}) => (hover ? '260px' : '64px')} !important;
    overflow: hidden;
  }
  transition: all 0.2s ease-in-out;
  > * {
    transition: all 0.2s ease-in-out;
  }

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${(props) => props.theme.sidebar.background};
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ec813d;
    border-radius: 20px;
    border: 3px solid #ec813d;
  }
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: Nunito, Roboto;
  min-height: 73px;
  padding-left: ${({hover}) => (hover ? 7 : 10)}px;
  ${({hover}) => (hover ? 'margin-top: 1px' : 'margin-bottom: 1px')};
  padding-right: 0px;
`;

const BrandIcon = styled.div`
  background-image: ${({hover}) => (hover ? `url(${aa_logo_text})` : `url(${aa_logo_only})`)};
  width: ${({hover}) => (hover ? '164px' : '47px')};
  height: ${({hover}) => (hover ? '57px' : '47px')};
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
`;

const ActiveLinkCss = css`
  &.${(props) => props.activeClassName} {
    background: #ec813d;
    border-radius: 10px 0px 0px 10px;
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      transition: all 0.2s;
    }
    &:before {
      height: 15px;
      width: 10px;
      border-bottom-right-radius: 100px 45px;
      top: -15px;
      box-shadow: 0 8px 0 0 #ec813d;
      right: 0px;
      left: calc(100% - 10px);
    }
    &:after {
      height: 15px;
      width: 10px;
      border-top-right-radius: 100px 45px;
      bottom: -15px;
      box-shadow: 0 -8px 0 0 #ec813d;
      right: 0px;
      left: calc(100% - 10px);
    }

    &:hover {
      background: #d16827;
      &:before {
        box-shadow: 0 8px 0 0 #d16827;
      }
      &:after {
        box-shadow: 0 -8px 0 0 #d16827;
      }
    }

    svg {
      color: rgba(0, 0, 0);
      opacity: 0.75;
    }
    span {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

const Category = styled(ListItem)`
  padding-top: ${({theme}) => theme.spacing(3) - 4}px;
  padding-bottom: ${({theme}) => theme.spacing(3) - 4}px;
  padding-left: 22px;
  padding-right: 22px;
  font-weight: ${({theme}) => theme.typography.fontWeightRegular};
  transition: all 0.2s;
  margin-top: 6px;
  margin-bottom: 6px;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.35);
  }

  ${ActiveLinkCss}

  &.${(props) => props.activeClassName} {
    width: calc(100% - 10px);
    margin: 6px 7px 6px 10px;
    padding: 8px 0px 8px 12px;
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  white-space: nowrap;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-top: ${({theme}) => theme.spacing(3) - 4}px;
  padding-bottom: ${({theme}) => theme.spacing(3) - 4}px;
  padding-left: ${(props) => props.theme.spacing(15)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  ${ActiveLinkCss}

  &.${(props) => props.activeClassName} {
    width: calc(100% - 52px);
    margin: 6px 7px 6px 52px;
    padding: 8px 0px 8px 8px;
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    white-space: nowrap;
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) => props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
  padding-top: ${(props) => props.theme.spacing(2.75) + 8}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.725rem;
  display: block;
  padding: 1px;
`;

const MobileMenuButton = styled(IconButton)`
  position: fixed;
  z-index: 1201;
  left: 0px;
  top: 12px;
`;

const MenuButton = styled(IconButton)`
  position: fixed;
  top: 58px;
  background-color: ${(props) => props.theme.sidebar.header.background};
  z-index: 1201;
  left: ${({hover}) => (hover ? '239px' : '45px')};
  transition: left 0.2s ease-in-out, color 0.2s ease-in-out;
  border: 2px solid ${(props) => props.theme.sidebar.color}7F;
  color: ${(props) => props.theme.sidebar.color}7F;
  border-radius: 50px;
  padding: 4px;
  margin: 0px;

  svg {
    height: 18px;
    width: 18px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
    color: ${(props) => props.theme.sidebar.color}CC;
    border: 2px solid ${(props) => props.theme.sidebar.color}CC;
  }
`;

const StyledBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;

  span {
    background-color: ${(props) => props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 14px;
    width: 14px;
    border-radius: 50%;
    margin-bottom: 7px;
  }
`;

const LogoutButton = styled(Power)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

function SidebarCategory({name, icon, isOpen, isCollapsable, badge, hover, isActive, ...rest}) {
  const CategoryIcon = icon;

  return (
    <Category {...rest} hover={hover} className={isActive ? 'active' : undefined} activeClassName="active">
      {CategoryIcon && <CategoryIcon />}
      <CategoryText>{hover ? name : ''}&nbsp;</CategoryText>
      {isCollapsable && hover ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
      {badge && hover ? <CategoryBadge label={badge} /> : ''}
    </Category>
  );
}

const isActive = (path, match, location) => {
  return !!(match || location.pathname.startsWith(path));
};

function SidebarLink({name, to, badge, hover}) {
  return (
    <Link button dense component={NavLink} exact to={to} activeClassName="active" isActive={isActive.bind(this, to)}>
      <LinkText>{hover ? name : ''}&nbsp;</LinkText>
      {hover & badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  );
}

let leaveTimeout;
let enterTimeout;

function Sidebar({routes, location, toggleSidebar, sidebarOpen, ...rest}) {
  const [hover, setHover] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallScreen = useSmallScreenSize();

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      let isActive = pathName.indexOf(route.path) === 0;
      if (route.paths) {
        for (const p of route.paths) {
          if (pathName.indexOf(p) === 0) {
            isActive = true;
          }
        }
      }

      const isOpen = route.open;
      const isHome = route.containsHome && pathName === '/' ? true : false;

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => (sidebarOpen ? initOpenRoutes() : []));
  const theme = useTheme();

  const authActions = useActions(AuthActions);

  const auth = useSelector((state) => state.auth);
  const userStoreActions = useUserStoreActions();

  // Retrieve user's name
  const userUuid = auth.user?.uuid;
  const user = useSelector((state) => (userUuid ? state.userStore.byId[userUuid] : undefined));
  const firstName = user ? user.firstName : auth.user?.firstName;
  const lastName = user ? user.lastName : auth.user?.lastName;

  // Work out user's full name and role
  const fullName = user ? `${firstName} ${lastName}` : 'User';
  const userRole = auth.user ? getRoleName(auth.user.role) : 'Guest Account';

  useEffect(() => {
    userStoreActions.ensureConsistent({uuid: userUuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUuid]);

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, {[item]: false}))
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, {[index]: !openRoutes[index]}));
  };

  const logoutClick = () => {
    authActions.logout();
  };

  const open = sidebarOpen || hover || mobileOpen;

  return (
    <>
      {isSmallScreen ? (
        <MobileMenuButton onClick={() => setMobileOpen(!mobileOpen)}>
          {mobileOpen ? <MenuOpen /> : <MenuIcon />}
        </MobileMenuButton>
      ) : (
        <MenuButton
          onClick={() => {
            toggleSidebar();
            setHover(false);
          }}
          hover={open}
          onMouseEnter={() => clearTimeout(leaveTimeout)}
          onMouseLeave={() => {
            // Allows for user to make a small mistake and accidentally leave and re-enter
            leaveTimeout = setTimeout(() => {
              setHover(false);
              !sidebarOpen && setOpenRoutes([]);
            }, 300);
          }}
        >
          {sidebarOpen ? <MenuOpen /> : <MenuIcon />}
        </MenuButton>
      )}
      <TopDrawer
        open={isSmallScreen ? mobileOpen : true}
        onClose={() => setMobileOpen(false)}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
      >
        <Drawer
          variant={isSmallScreen ? 'temporary' : 'permanent'}
          open={isSmallScreen ? mobileOpen : true}
          anchor="left"
          hover={open}
          onMouseEnter={() => {
            clearTimeout(leaveTimeout);
            // Allows for user to pass over the sidebar and out of the app without opening it.
            enterTimeout = setTimeout(() => {
              setOpenRoutes(initOpenRoutes());
              setHover(true);
            }, 150);
          }}
          onMouseLeave={() => {
            clearTimeout(enterTimeout);
            // Allows for user to make a small mistake and accidentally leave and re-enter
            leaveTimeout = setTimeout(() => {
              setHover(false);
              !sidebarOpen && setOpenRoutes([]);
            }, 300);
          }}
          {...rest}
        >
          <Box display="flex" style={{backgroundColor: theme.sidebar.header.background}}>
            <NavLink to="/dashboard" style={{margin: '0 auto'}}>
              <Brand hover={open}>
                <BrandIcon hover={open} />
              </Brand>
            </NavLink>
          </Box>

          <Scrollbar>
            <List disablePadding>
              <Items>
                {routes.map((category, index) => (
                  <React.Fragment key={index}>
                    {!isSmallScreen ? (
                      <>
                        {index !== 0 && <Divider style={{backgroundColor: '#88888888', margin: '6px 12px'}} />}
                        {category.children ? (
                          category.children.map((route) => (
                            <SidebarCategory
                              isCollapsable={false}
                              name={route.name}
                              to={route.path}
                              component={NavLink}
                              icon={route.icon}
                              hover={open}
                              exact
                              badge={route.badge}
                              isActive={location.pathname.indexOf(route.path) === 0}
                            />
                          ))
                        ) : (
                          <SidebarCategory
                            isCollapsable={false}
                            name={category.id}
                            to={category.path}
                            component={NavLink}
                            icon={category.icon}
                            hover={open}
                            exact
                            badge={category.badge}
                            isActive={location.pathname.indexOf(category.path) === 0}
                          />
                        )}
                      </>
                    ) : category.children ? (
                      <React.Fragment key={index}>
                        <SidebarCategory
                          isOpen={!openRoutes[index]}
                          isCollapsable={true}
                          name={category.id}
                          icon={category.icon}
                          hover={open}
                          button={true}
                          onClick={() => toggle(index)}
                          isActive={
                            !open && category.children.some((route) => location.pathname.indexOf(route.path) === 0)
                          }
                        />

                        <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                          {category.children.map((route, index) => (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              hover={open}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                            />
                          ))}
                        </Collapse>
                      </React.Fragment>
                    ) : (
                      <SidebarCategory
                        isCollapsable={false}
                        name={category.id}
                        to={category.path}
                        component={NavLink}
                        icon={category.icon}
                        hover={open}
                        exact
                        badge={category.badge}
                        isActive={!open && location.pathname.indexOf(category.path) === 0}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Items>
            </List>
          </Scrollbar>
          <SidebarFooter>
            <Grid container justifyContent="space-between" wrap="nowrap">
              <NavLink exact to={`/users/uuid/${user?.uuid}`}>
                <Grid item zeroMinWidth>
                  <Grid container spacing={2} wrap="nowrap">
                    <Grid item>
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                        hover={open}
                      >
                        <Avatar
                          alt={fullName}
                          src="/static/img/avatars/avatar-1.jpg"
                          style={{width: '40px', height: '40px', margin: '0px 0px 8px -4px'}}
                        />
                      </StyledBadge>
                    </Grid>
                    {open && (
                      <Grid item zeroMinWidth>
                        <Tooltip title={fullName}>
                          <SidebarFooterText variant="body2" noWrap>
                            {fullName}
                          </SidebarFooterText>
                        </Tooltip>
                        <SidebarFooterSubText variant="caption">{userRole}</SidebarFooterSubText>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </NavLink>
              {open && (
                <Grid item zeroMinWidth>
                  <Tooltip title={<Typography>Log out</Typography>}>
                    <IconButton onClick={logoutClick}>
                      <LogoutButton />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </SidebarFooter>
        </Drawer>
      </TopDrawer>
    </>
  );
}

export default withRouter(Sidebar);
