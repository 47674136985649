import React, {useState} from 'react';
import {
  Button as MuiButton,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';

import {Link, useHistory, useLocation} from 'react-router-dom';

import {Helmet} from 'react-helmet';

import {useActions} from '../../store/actions';
import * as AuthActions from '../../store/actions/auth';
import {validatePassword} from '@common/api/models/users/User.validation';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(AccountCircle)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

function SignIn() {
  const history = useHistory();
  const location = useLocation<{from: {pathname: string}}>();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [signingIn, setSigningIn] = useState(false);
  // FIXME: Do something with this hint, or remove (currently unused)
  const [, setHintTarget] = useState<any>(null);
  const [, setOpenHint] = useState<boolean>(false);
  const authActions = useActions(AuthActions);

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);

    const passwordValidation = validatePassword(event.target.value);
    setHintTarget(event.currentTarget);
    if (!passwordValidation.success && event.target.value) {
      setOpenHint(true);
    } else {
      setOpenHint(false);
    }
  };

  const handleSignInClick = async (event: any) => {
    setSigningIn(true);
    event.preventDefault();
    const res = await authActions.login(email, password);
    setSigningIn(false);
    if (res.success) {
      // Redirect user to previous page they were trying to access
      const {from} = location.state || {from: {pathname: '/'}};
      history.replace(from);
    }
  };

  return (
    <Wrapper>
      <Helmet title="Sign In" />
      <div style={{textAlign: 'center'}}>
        <BigAvatar />
      </div>

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Sign in to continue.
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Please contact your administrator for an account
      </Typography>
      <form onSubmit={handleSignInClick}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">Email / Username</InputLabel>
          <Input id="email" name="email" autoComplete="email" autoFocus onChange={handleEmailChange} />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            name="password"
            type="password"
            id="password"
            autoComplete="password"
            onChange={handlePasswordChange}
          />
        </FormControl>
        <Button
          // @ts-ignore

          onSubmit={(event) => {
            event.preventDefault();
            handleSignInClick(event);
          }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={signingIn}
          mb={2}
          mt={4}
        >
          {signingIn ? <CircularProgress size={25} /> : 'Sign in'}
        </Button>
        <Button
          // @ts-ignore
          component={Link}
          // @ts-ignore
          to="/auth/reset-password"
          fullWidth
          color="primary"
          disabled={signingIn}
        >
          Forgot password
        </Button>
      </form>
    </Wrapper>
  );
}

export default SignIn;
