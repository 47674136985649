import {Column} from 'material-table';

export function generateColumns<RowData extends {url: string | undefined}>(
  columns: Column<RowData>[],
  exclude?: (keyof RowData)[],
  customRender?: {
    [key in keyof RowData]?: (data: RowData, type: 'row' | 'group') => any;
  }
) {
  return columns
    .filter((col) => !(exclude || []).includes(col.field as keyof RowData))
    .map((col) => ({
      ...col,
      render:
        customRender && customRender[col.field as keyof RowData]
          ? customRender[col.field as keyof RowData]
          : col.render,
    }));
}
