import {BuildsByPartsQueryGetResponse, IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {Box, Checkbox, CircularProgress, Typography} from '@material-ui/core';
import {ChevronRight, ExpandMore} from '@material-ui/icons';
import {TreeItem, TreeView} from '@material-ui/lab';
import {clone} from 'lodash';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {useSimilarityReportStoreActions} from '../../../../store/actions/index';
import {RootState} from '../../../../store/reducers';
import {FiltersState} from '../../../shared/IndexPage/IndexPage';
import {PartSelectorModeType} from '../PartSelectorTreeView';
import {LoadMoreSensor, useIsInViewport} from '../../../../utils/LoadMoreSensor';
import {SimilarityStep, generateSimilarityQueryString} from '../../SimilarityCreatePage';
import {RotationMap} from '@common/api/models/builds/data/ISimilarity';
import {DEFAULT_REPORT_NAME} from '../../../../store/reducers/liveUpdateStores/similarityReportReducer';

const BuildsPartsTree = ({
  builds,
  filters,
  selectionType,
  mode,
}: {
  builds: BuildsByPartsQueryGetResponse[];
  filters: Omit<FiltersState, 'page' | 'take'>;
  selectionType: SimilarityStep;
  mode: PartSelectorModeType;
}) => {
  return (
    <>
      {builds.map((build) => (
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          key={`build-tree-view-${build.buildUuid}`}
        >
          <TreeItem nodeId={'0'} label={build.buildName} key={`build-tree-item-${build.buildUuid}`}>
            <PartsForBuild
              buildUuid={build.buildUuid}
              buildName={build.buildName}
              filters={filters}
              selectionType={selectionType}
              mode={mode}
            />
          </TreeItem>
        </TreeView>
      ))}
    </>
  );
};

export default BuildsPartsTree;

const PartsForBuild = React.memo(
  ({
    buildUuid,
    buildName,
    filters,
    selectionType,
    mode,
  }: {
    buildUuid: string;
    buildName: string;
    filters: Omit<FiltersState, 'page' | 'take'>;
    selectionType: SimilarityStep;
    mode: PartSelectorModeType;
  }) => {
    const [skipParts, setSkipParts] = useState(0);
    const loadMoreRef: React.RefObject<HTMLDivElement> = useRef(null);
    const shouldLoadMore = useIsInViewport(loadMoreRef);

    const {fetchPartsForBuild} = useSimilarityReportStoreActions();

    const parts = useSelector(
      (state: RootState) => state.similarityReportStore.partsByBuildUuid[buildUuid]?.parts || []
    );
    const totalParts = useSelector(
      (state: RootState) => state.similarityReportStore.partsByBuildUuid[buildUuid]?.total
    );

    const allPartsLoaded = useMemo(
      () => totalParts !== undefined && parts.length >= totalParts,
      [totalParts, parts.length]
    );

    useEffect(() => {
      if (shouldLoadMore && !allPartsLoaded) {
        fetchPartsForBuild(skipParts, buildUuid, buildName, {...filters});
        setSkipParts((skip) => skip + 25);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoadMore, parts.length]);

    return (
      <>
        {parts?.map((part, nodeId) => (
          <TreeItem
            nodeId={`${nodeId + 1}`}
            label={<PartLabel buildName={buildName} part={part} selectionType={selectionType} mode={mode} />}
            key={`part-tree-item-${part.uuid}`}
          />
        ))}
        {totalParts === 0 && (
          <Typography>
            <b>No Parts found for this build</b>
          </Typography>
        )}
        {!allPartsLoaded && (
          <LoadMoreSensor ref={loadMoreRef}>
            <CircularProgress size={14} />
            <Typography>Loading more parts...</Typography>
          </LoadMoreSensor>
        )}
      </>
    );
  }
);

interface PartLabelProps {
  buildName: string;
  part: IPartGETResponse;
  selectionType: SimilarityStep;
  mode: PartSelectorModeType;
}

const PartLabel = ({buildName, part, selectionType, mode}: PartLabelProps) => {
  if (mode === 'draft') return <DraftReportPartLabel buildName={buildName} part={part} selectionType={selectionType} />;

  return <AddTargetPartLabel part={part} />;
};

export const addOrRemovePart = (parts: IPartGETResponse[], part: IPartGETResponse, isSelected: boolean) => {
  let newParts: IPartGETResponse[] = clone(parts) || [];

  if (isSelected)
    newParts.splice(
      newParts.findIndex((target) => target.uuid === part.uuid),
      1
    );
  else {
    if (newParts.length + 1 >= 100) {
      toast('Cannot select Part, maximum of 100 Parts reached.', {
        type: 'error',
      });
      return;
    }
    newParts = [...newParts, part];
  }

  return newParts;
};

export const addOrRemoveRotations = (parts: IPartGETResponse[], rotations: RotationMap, type: 'source' | 'target') => {
  const newRotations: RotationMap = {};

  if (parts.length === 0) return newRotations;
  // Ensure one source part has a 0 rotation so that it acts as the base part.
  if (parts.length === 1 && type === 'source') return {[parts[0].uuid]: 0};

  parts.forEach((part) => {
    newRotations[part.uuid] = rotations[part.uuid] || 0;
  });

  return newRotations;
};

// checks two parts total area and ensure that the difference is less than 1%
const sameTotalArea = (sourceAreas: (number | undefined)[], targetArea: number | undefined) => {
  if (sourceAreas.includes(undefined) || !targetArea) return true;
  const areas = [...sourceAreas.map((num) => num ?? 0), targetArea];
  const maxArea: number = Math.max(...areas);
  const minArea: number = Math.min(...areas);
  const averageArea: number = areas.reduce((sum, volume) => sum + volume, 0) / areas.length;

  return Math.abs(maxArea - minArea) < averageArea * 0.01;
};

// message to explain why part isn't selectable
const disabledTooltipMsg = (partIsSource: boolean, partIsTarget: boolean) =>
  partIsTarget
    ? 'Cannot select target as source'
    : partIsSource
    ? 'Cannot select source as target'
    : "Part's volume is too dissimilar to source parts";

const DraftReportPartLabel = ({part, selectionType}: Omit<PartLabelProps, 'mode'>) => {
  const history = useHistory();
  const {setCurrentlyViewingPart, setDraftSimilarityReport} = useSimilarityReportStoreActions();

  const targetParts = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport.targetParts);
  const sourceParts = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport.sourceParts);
  const targetPartRotations = useSelector(
    (state: RootState) => state.similarityReportStore.draftSimilarityReport.targetPartRotations
  );
  const sourcePartRotations = useSelector(
    (state: RootState) => state.similarityReportStore.draftSimilarityReport.sourcePartRotations
  );

  const sourcePartAreas = useSelector((state: RootState) =>
    state.similarityReportStore.draftSimilarityReport.sourceParts.map((part) => part.totalArea)
  );

  const canSelectSource = sourceParts.length ? sameTotalArea([sourceParts[0].totalArea], part.totalArea) : true;
  const canSelectTarget = sameTotalArea(sourcePartAreas, part.totalArea);

  const currentlyViewingPartUuid = useSelector(
    (state: RootState) => state.similarityReportStore.currentlyViewingPart?.uuid
  );

  const isSourcePart = sourceParts.map((part) => part.uuid).includes(part.uuid);
  const isTargetPart = targetParts.map((part) => part.uuid).includes(part.uuid);

  const isSelected =
    selectionType === SimilarityStep.SOURCE
      ? !!sourceParts?.find((source) => source.uuid === part.uuid)
      : !!targetParts?.find((target) => target.uuid === part.uuid);

  const isCurrentlyViewing = currentlyViewingPartUuid === part.uuid;

  const cannotInteract =
    selectionType === SimilarityStep.TARGET ? isSourcePart || !canSelectTarget : isTargetPart || !canSelectSource;

  const onChange = () => {
    if (selectionType === SimilarityStep.SOURCE) {
      const newParts = addOrRemovePart(sourceParts, part, isSelected);
      if (newParts) {
        const newRotations = addOrRemoveRotations(newParts, sourcePartRotations, 'source');

        const firstSource = newParts[0];
        setDraftSimilarityReport('sourceParts', newParts);
        setDraftSimilarityReport('sourcePartRotations', newRotations);
        setDraftSimilarityReport(
          'backupName',
          firstSource ? `${firstSource.buildName}-${firstSource.name}` : DEFAULT_REPORT_NAME
        );

        const queryString = generateSimilarityQueryString(
          newParts.map((part) => part.uuid),
          newRotations
        );
        history.replace(`/reports/similarity/create/${SimilarityStep.SOURCE}/${queryString}`);
      }
    } else {
      const newParts = addOrRemovePart(targetParts, part, isSelected);
      if (newParts) {
        const newRotations = addOrRemoveRotations(newParts, targetPartRotations, 'target');
        setDraftSimilarityReport('targetParts', newParts);
        setDraftSimilarityReport('targetPartRotations', newRotations);
      }
    }
  };

  return (
    <ConditionalTooltip
      hideTooltip={!cannotInteract}
      tooltip={disabledTooltipMsg(isSourcePart, isTargetPart)}
      placement="bottom-start"
    >
      <Box
        display="flex"
        alignItems="center"
        onClick={() => {
          if (!cannotInteract) setCurrentlyViewingPart(part);
        }}
        style={{background: isCurrentlyViewing ? 'rgb(230 230 227 / 70%)' : ''}}
      >
        <Checkbox checked={isSelected} onClick={onChange} size="small" disabled={cannotInteract} />
        <Typography>{part.name}</Typography>
      </Box>
    </ConditionalTooltip>
  );
};

const AddTargetPartLabel = ({part}: {part: IPartGETResponse}) => {
  const {setCurrentlyViewingPart, setNewTargetParts} = useSimilarityReportStoreActions();

  const targetParts = useSelector((state: RootState) => state.similarityReportStore.newTargetParts);
  const sourceParts = useSelector(
    (state: RootState) => state.similarityReportStore.currentSimilarityReport?.sources || []
  );
  const sourcePartAreas = useSelector(
    (state: RootState) =>
      state.similarityReportStore.currentSimilarityReport?.sources.map((part) => part.totalArea) || []
  );

  const currentTargetParts = useSelector(
    (state: RootState) => state.similarityReportStore.currentTargetPartUuids || {}
  );
  const currentlyViewingPartUuid = useSelector(
    (state: RootState) => state.similarityReportStore.currentlyViewingPart?.uuid
  );

  const isCurrentlySelected = !!targetParts?.find((target) => target.uuid === part.uuid);
  const isCurrentTarget = currentTargetParts[part.uuid] === true;
  const isCurrentlyViewing = currentlyViewingPartUuid === part.uuid;

  const partIsSource = sourceParts.map((source) => source.uuid)?.includes(part.uuid) ?? false;

  const canSelectTarget = sameTotalArea(sourcePartAreas, part.totalArea);

  const onChange = () => {
    const newParts = addOrRemovePart(targetParts, part, isCurrentlySelected);
    if (newParts) setNewTargetParts(newParts);
  };

  return (
    <ConditionalTooltip
      hideTooltip={!partIsSource && !isCurrentTarget && canSelectTarget}
      tooltip={disabledTooltipMsg(partIsSource, isCurrentTarget)}
      placement="bottom-start"
    >
      <Box
        display="flex"
        alignItems="center"
        onClick={() => setCurrentlyViewingPart(part)}
        style={{background: isCurrentlyViewing ? 'rgb(230 230 227 / 70%)' : ''}}
      >
        <Checkbox
          checked={isCurrentlySelected || isCurrentTarget}
          onClick={onChange}
          size="small"
          disabled={isCurrentTarget || partIsSource || !canSelectTarget}
        />
        <Typography>{part.name}</Typography>
      </Box>
    </ConditionalTooltip>
  );
};
