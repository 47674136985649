import {ICalibration} from '@common/api/models/devices/ICalibration';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function calibrationsAllGET(filter: QueryFilter<ICalibration>): AjaxApiResult<ICalibration[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/calibrations/' + (query && '?' + query));
  }, 'Could not fetch calibrations');
}

// Gets a signed download URL for the preview file.
export async function calibrationPreviewUrlGET(uuid: string): AjaxApiResult<{url: string}> {
  return ajaxWrapper(() => {
    return axios.get(`/api/calibrations/${uuid}/previewUrl`);
  }, 'Could not generate preview image URL');
}
