import createReducer from './createReducer';
import {AuthAction, AuthActions, AuthState, initialAuthState, LogInState} from '../model/auth';
import {isEqual, omit} from 'lodash';

export const auth = createReducer<AuthState>(initialAuthState, {
  [AuthActions.AUTHENTICATED](state: AuthState, action: AuthAction) {
    // If no changes to the user, don't trigger an update in state as this is a frequent action and
    // can cause rendering issues in components due to poorly managed state handling across the app.
    if (isEqual(omit(state.user || {}, 'lastActivityOn'), omit(action.payload || {}, 'lastActivityOn'))) {
      return state;
    }

    return {...state, user: action.payload, logInState: LogInState.LoggedIn};
  },
  [AuthActions.LOGOUT](_state: AuthState, _action: AuthAction) {
    return {logInState: LogInState.LoggedOut, emailConfirmationShown: true};
  },
  [AuthActions.EMAIL_CONFIRMATION_TOAST_SHOWN](state: AuthState, _action: AuthAction) {
    return {...state, emailConfirmationShown: true};
  },
});
