import {Box, Button, Select} from '@material-ui/core';
import styled, {keyframes} from 'styled-components';
import {constructImageFilter, defaultImageFilters, ImageFilter} from './2D/imageutils';

const fadeAway = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
`;

const fadeAwayHalf = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0.7;
  }
`;

export const ViewerButton = styled(Button)`
  color: #ffffff;
  min-width: 0px;
  padding: 0;

  &:disabled {
    color: #999999;
  }
  &.fade {
    animation: ${fadeAway} 0.2s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }

  &.fade-half {
    animation: ${fadeAwayHalf} 0.2s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }
`;

export const ViewerButtonActive = styled(Button)`
  color: #ffffff;
  padding: 0;
  min-width: 0px;

  &.fade {
    animation: ${fadeAwayHalf} 0.2s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }

  &.fade-half {
    animation: ${fadeAwayHalf} 0.2s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }
`;

export const CustomSelect = styled(Select)`
  &.fade {
    animation: ${fadeAway} 0.2s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }
`;

export const FadingDiv = styled.div`
  &.fade {
    animation: ${fadeAway} 0.1s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }
`;

export const FadingBox = styled(Box)`
  &.fade {
    animation: ${fadeAway} 0.1s linear 1 !important;
    animation-delay: 0s !important;
    animation-fill-mode: forwards !important;
  }
`;

export const ActiveDiv = styled.div`
  color: rgba(25, 118, 210, 1);
`;

export const BlankDiv = styled.div<{
  hidePointer?: boolean;
  gammaKey?: string;
  fullscreen?: boolean;
  imageFilters?: ImageFilter;
}>`
  background-color: transparent !important;
  box-sizing: content-box;
  canvas {
    outline: none !important;
    ${({imageFilters, gammaKey}) =>
      `${gammaKey ? `filter: url('#${gammaKey}')` : ''} ${constructImageFilter(imageFilters ?? defaultImageFilters)};`}
  }

  ${({hidePointer}) => hidePointer && 'cursor: none;'}

  @media (min-width: 600px) {
    border: 3px solid rgb(34, 34, 34);
    border-radius: 4px;
    &:focus-within {
      ${({fullscreen}) => !fullscreen && 'border: 3px solid rgba(25, 118, 210, 1);'}
    }
    canvas {
      outline: none;
    }
    div {
      outline: none;
    }
  }
`;
