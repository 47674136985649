import React, {useEffect, useMemo} from 'react';
import {Typography} from '@material-ui/core';
import {MaterialTableProps} from 'material-table';

import {useSelector} from 'react-redux';

import {CoolLinkedTable} from './CoolTable';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import {IMaterial} from '@common/api/models/materials/IMaterial';
import {assertUnreachable} from '@common/utils/utils';
import {IBuild, isEngagedBuildState} from '@common/api/models/builds/IBuild';
import Status from '../../atoms/Status/Status';
import {useBuildStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {FetchingState} from '../../../store/model/liveUpdateStore';
import {renderDateTimeString} from '../../../utils/string';
import {generateColumns} from '../../../utils/table';

type BatchAvailability = 'Available' | 'Used' | 'Out of Date';
export interface BatchesRow {
  uuid: string;
  available: BatchAvailability;
  name: string;
  materials: string;
  description: string;
  quantity: number;
  registered: Date | string;
  uuidFull: string;
  url: string;
  batch: IBatch;
}

export function BatchesTable(
  props: {
    batches: IBatch[];
    materialsByUuid: {[key: string]: IMaterial};
    errorMessage?: string;
    exclude?: (keyof BatchesRow)[];
    customRender?: {
      [key in keyof BatchesRow]?: (data: BatchesRow, type: 'row' | 'group') => any;
    };
    filters?: Partial<BatchesRow>;
  } & Partial<MaterialTableProps<BatchesRow>>
) {
  const {batches, errorMessage, filters, isLoading, ...rest} = props;

  const buildActions = useBuildStoreActions();
  const allBatchUuids = useMemo(() => batches.map((b) => b.uuid), [batches]);
  const buildStore = useSelector((state: RootState) => state.buildStore);

  useEffect(() => {
    if (allBatchUuids.length) {
      buildActions.ensureConsistent({batchUuid: allBatchUuids});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allBatchUuids)]);

  const activeBuildsByBatchUuid = useMemo(() => {
    const result: {[key: string]: IBuild} = {};
    for (const b of Object.values(buildStore.byId)) {
      if (b.batchUuid) {
        if (isEngagedBuildState(b.state)) {
          result[b.batchUuid] = b;
        }
      }
    }
    return result;
  }, [buildStore.byId]);

  const data: BatchesRow[] = useMemo(() => {
    return batches.map((b): BatchesRow & any => {
      let available: BatchAvailability = 'Out of Date';
      if (b.current) {
        const myBuilds = activeBuildsByBatchUuid[b.uuid];
        if (myBuilds) {
          available = 'Used';
        } else {
          available = 'Available';
        }
      }

      return {
        available,
        uuid: b.uuid.substr(0, 7),
        name: b.name,
        materials: b.materialComposition
          .sort((a, b) => b.weight - a.weight)
          .map(({materialUuid}) => props.materialsByUuid[materialUuid]?.name)
          .join(', '),
        description: b.description,
        quantity: b.quantity,
        registered: b.registered,
        uuidFull: b.uuid,
        batch: b,
        url: '/batches/uuid/' + b.uuid,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBuildsByBatchUuid, batches, props.materialsByUuid]);

  return (
    <CoolLinkedTable
      options={{
        selection: true,
        ...rest.options,
      }}
      columns={generateColumns(
        [
          {
            title: 'Availability',
            field: 'available',
            // @ts-ignore
            width: 100,
            render: (row: BatchesRow) => {
              switch (row.available) {
                case 'Available':
                  return <Status variant={'success'} message={row.available} />;
                case 'Used':
                  return <Status variant={'warning'} message={row.available} />;
                case 'Out of Date':
                  return <Status variant={'info'} message={row.available} />;
                default:
                  assertUnreachable(row.available);
              }
            },
          },
          {title: 'Name', field: 'name'},
          {title: 'Material Composition', field: 'materials'},
          {title: 'Total Qty (kg)', field: 'quantity'},
          {
            title: 'Registered on',
            field: 'registered',
            defaultSort: 'desc',
            type: 'date',
            emptyValue: '',
            render: (row: BatchesRow) =>
              row.registered && <Typography>{renderDateTimeString('short', row.registered)}</Typography>,
          },
        ],
        props.exclude,
        props.customRender
      )}
      data={data}
      filters={filters}
      errorMessage={errorMessage}
      isLoading={isLoading || buildStore.fetched === FetchingState.Fetching}
      {...rest}
    />
  );
}
