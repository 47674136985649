import {
  BuildIntensityReportStatus,
  IBuildIntensityReportPOSTRequest,
} from '@common/api/models/builds/data/IBuildIntensityReport';
import {buildIntensityReportDELETE, buildIntensityReportPOST} from '../../api/ajax/buildIntensityReport';
import {
  BuildIntensityReportActions,
  PART_CSV_DEFAULT_DATA,
  PartCSVsDataType,
  SelectedBuildsAction,
  SelectedPartsAction,
  SUMMARY_CSV_DEFAULT_DATA,
  SummaryCSVsDataType,
} from '../model/buildIntensityReport';
import {partsAllGET} from '../../../src/api/ajax/parts';
import {buildIntensityReportDownloadUrlGET} from '../../../src/api/ajax/buildIntensityReport';
import JSZip from 'jszip';
import {toast} from 'react-toastify';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {GraphDataType} from '../../pages/buildIntensityReport/IntensityGraph/GraphOptionsModal';
import {ChartDataType} from '../../pages/buildIntensityReport/IntensitySummaryBar/ChartOptionsModel';
import {cloneDeep} from 'lodash';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createBuildIntensityReport(
    buildIntensityReport: IBuildIntensityReportPOSTRequest,
    buildName: string,
    buildEndDate: Date | undefined
  ) {
    return async (dispatch: Function) => {
      const response = await buildIntensityReportPOST(buildIntensityReport);

      if (response.success) {
        dispatch({
          type: BuildIntensityReportActions.CREATE_BUILD_INTENSITY_REPORT_SUCCESS,
          payload: {report: {...response.data, buildName, buildEndDate}},
        });
        return true;
      } else {
        return false;
      }
    };
  },

  deleteBuildIntensityReport(reportUuid: string) {
    return async (dispatch: Function) => {
      const response = await buildIntensityReportDELETE(reportUuid);

      if (response.success) {
        dispatch({
          type: BuildIntensityReportActions.DELETE_BUILD_INTENSITY_REPORT_SUCCESS,
          payload: {reportUuid},
        });
        return true;
      } else {
        return false;
      }
    };
  },

  addSelectedBuild(buildUuid: string, buildName: string): SelectedBuildsAction {
    return {
      type: BuildIntensityReportActions.ADD_SELECTED_BUILD,
      payload: {buildUuid, buildName},
    };
  },

  removeSelectedBuild(buildUuid: string): SelectedBuildsAction {
    return {
      type: BuildIntensityReportActions.REMOVE_SELECTED_BUILD,
      payload: {buildUuid, buildName: ''},
    };
  },

  addSelectedPart(part: IPartGETResponse): SelectedPartsAction {
    return {
      type: BuildIntensityReportActions.ADD_SELECTED_PART,
      payload: {part},
    };
  },

  removeSelectedPart(part: IPartGETResponse): SelectedPartsAction {
    return {
      type: BuildIntensityReportActions.REMOVE_SELECTED_PART,
      payload: {part},
    };
  },

  addIntensityCSVs(reportUuid: string, buildUuid: string) {
    return async (dispatch: Function) => {
      const url = await buildIntensityReportDownloadUrlGET(reportUuid);
      if (!url.success) return;

      const response = await fetch(url.data.url);
      const blob = await response.blob();

      // Extract the CSV files from the zip file
      const zip = await JSZip.loadAsync(blob);

      const csvFiles = Object.values(zip.files);

      // Parse the CSV files and store the data in Redux
      const newPartCSVs: {[partUuid: string]: PartCSVsDataType} = {};
      const newSummaryCSVs: {[buildUuid: string]: SummaryCSVsDataType} = {};

      await Promise.all(
        csvFiles.map(async (file: JSZip.JSZipObject) => {
          const csvString = await file.async('text');
          const lines = csvString.trim().split('\n');

          // Extract column names and data
          const columns = lines[0].split(',');
          const data = lines.slice(1).map((line: string) => line.split(','));
          const isSummary = file.name.includes('summary');

          const dataObj = cloneDeep(isSummary ? SUMMARY_CSV_DEFAULT_DATA : PART_CSV_DEFAULT_DATA);
          data.forEach((line) => {
            line.forEach((cell, columnIndex) => {
              const column = columns[columnIndex];
              if (!!(dataObj as any)[column]) {
                if (['part_uuid', 'part_name'].includes(column)) {
                  (dataObj as any)[column].push(cell);
                } else {
                  (dataObj as any)[column].push(parseFloat(cell));
                }
              }
            });
          });

          if (isSummary) {
            newSummaryCSVs[buildUuid] = dataObj as SummaryCSVsDataType;
          } else {
            newPartCSVs[dataObj.part_uuid[0]] = dataObj as PartCSVsDataType;
          }
        })
      );

      dispatch({
        type: BuildIntensityReportActions.ADD_INTENSITY_CSVS,
        payload: {buildUuid, newPartCSVs, newSummaryCSVs},
      });
    };
  },

  fetchPartsForBuild(skip: number, buildUuid: string) {
    return async (dispatch: Function) => {
      const res = await partsAllGET({
        skip,
        take: 25,
        buildUuid,
      });
      if (res.success) {
        dispatch({
          type: BuildIntensityReportActions.FETCH_PARTS_FOR_BUILD_SUCCESS,
          payload: {parts: res.data, buildUuid, total: res.count!},
        });
      } else {
        toast('Failed to fetch parts', {type: 'error'});
      }
    };
  },

  setGraphDataType(graphDataType: GraphDataType) {
    return async (dispatch: Function) => {
      dispatch({
        type: BuildIntensityReportActions.SET_GRAPH_DATA_TYPE,
        payload: {graphDataType},
      });
    };
  },

  setGraphDataToggles(toggleName: string, bool: boolean) {
    return async (dispatch: Function) => {
      dispatch({
        type: BuildIntensityReportActions.SET_GRAPH_DATA_TOGGLE,
        payload: {toggleName, bool},
      });
    };
  },

  setRollingPeriod(rollingPeriod: number) {
    return async (dispatch: Function) => {
      dispatch({
        type: BuildIntensityReportActions.SET_ROLLING_PERIOD,
        payload: {rollingPeriod},
      });
    };
  },

  setChartDataType(chartDataType: ChartDataType) {
    return async (dispatch: Function) => {
      dispatch({
        type: BuildIntensityReportActions.SET_CHART_DATA_TYPE,
        payload: {chartDataType},
      });
    };
  },

  setReportStatus(buildUuid: string, status: BuildIntensityReportStatus) {
    return {
      type: BuildIntensityReportActions.SET_REPORT_STATUS,
      payload: {buildUuid, status},
    };
  },

  clearIntensityReports() {
    return {
      type: BuildIntensityReportActions.CLEAR_INTENSITY_REPORTS,
    };
  },
  clearIntensitySelections() {
    return {
      type: BuildIntensityReportActions.CLEAR_INTENSITY_REPORTS_SELECTIONS,
    };
  },
};
