import React, {useEffect} from 'react';
import {Grid, Card as MuiCard, CardContent} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import ComparisonsTable from './ComparisonsTable';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';

import Results3DViewport from './Results3DViewport';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {VIEWPORT_FULLSCREEN_STYLE} from '../shared/fullScreenViewportStyle';
import ComparisonDefectScores from './ComparisonDefectScores';
import ReportSources from './ReportSources';

const Card = styled(MuiCard)(spacing);

const ResultsContent = ({isViewportFullScreen}: {isViewportFullScreen: boolean}) => {
  const isSmallScreen = useSmallScreenSize();
  const {setCurrentlyViewingComparison} = useSimilarityReportStoreActions();

  useEffect(() => {
    return () => {
      setCurrentlyViewingComparison(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={isSmallScreen ? 2 : 4}>
      <Grid item xs={12}>
        <ReportSources />
      </Grid>
      <Grid item xs={12} md={6} style={isSmallScreen ? {} : {minHeight: '620px'}}>
        <Card padding={isSmallScreen ? 1 : 3} style={{height: '100%', overflow: 'hidden'}}>
          <CardContent style={{height: '100%'}}>
            <ComparisonsTable />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={isSmallScreen ? 2 : 4} style={{height: 'calc(100% + 16px)'}}>
          <Grid
            item
            xs={12}
            style={
              isViewportFullScreen
                ? VIEWPORT_FULLSCREEN_STYLE
                : {
                    height: 'calc(100vh - 550px)',
                    minHeight: isSmallScreen ? '50vh' : '320px',
                  }
            }
          >
            <Results3DViewport isViewportFullScreen={isViewportFullScreen} />
          </Grid>
          <Grid item xs={12} style={{height: '320px'}}>
            <Card padding={isSmallScreen ? 1 : 3} style={{height: '100%'}}>
              <ComparisonDefectScores />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultsContent;
