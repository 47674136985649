import React, {useEffect, useState} from 'react';
import {Slider, Typography} from '@material-ui/core';
import {FadingDiv} from '../../FadingComponents';

interface CmRangeSliderProps {
  mouseOn: boolean;
  divPositionStyle: React.CSSProperties;
  cmRange: number[];
  onChange?: (val: number[]) => void;
  onChangeCommitted?: (val: number[]) => void;
  min?: number;
  max?: number;
}

function CmRangeSlider({
  mouseOn,
  divPositionStyle,
  onChange,
  onChangeCommitted,
  cmRange,
  min = 20, // to avoid noise
  max = 256,
}: CmRangeSliderProps) {
  const [internalCmRange, setInternalCmRange] = useState<number[]>(cmRange);

  useEffect(() => {
    setInternalCmRange(cmRange);
  }, [cmRange]);

  return (
    <FadingDiv className={(!mouseOn && 'fade') || undefined} style={divPositionStyle}>
      <Typography id="range-slider" gutterBottom style={{color: '#fff', marginBottom: '0'}}>
        Colour map range
      </Typography>
      <Slider
        value={internalCmRange}
        min={min}
        max={max}
        onChange={(_e: any, val: any) => {
          setInternalCmRange(val);
          onChange && onChange(val);
        }}
        onChangeCommitted={(_e: any, val: any) => onChangeCommitted && onChangeCommitted(val)}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={(val: number) => `${val}`}
      />
    </FadingDiv>
  );
}

export default CmRangeSlider;
