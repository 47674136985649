import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, ClickAwayListener, Tooltip, Typography} from '@material-ui/core';
import {Videocam} from '@material-ui/icons';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DeviceHosts} from '@common/api/models/devices/IDevice';
import {RootState} from '../../../../store/reducers/index';
import useWebcamDetails, {AllWebcamHosts} from '../shared/useWebcamDetails';
import VerticalButton from '../../../../components/atoms/VerticalButton';

export const LiveBuildWebcamButton = ({build, isSmallScreen}: {build: IBuild; isSmallScreen: boolean}) => {
  const webcamHosts = useSelector((state: RootState) => state.deviceStore.byId[build.deviceSerial!]?.webcamHosts);
  const [showAllUrls, setShowAllUrls] = useState(false);
  const {webcamURL, setWebcamURL, tooltipTitle, hostsToShow} = useWebcamDetails(build, webcamHosts);
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const buttonProps = !showAllUrls ? {href: webcamURL, target: '_blank', rel: 'noopener noreferrer'} : {};

  function onClick() {
    if (hostsToShow && hostsToShow.length > 1) {
      setShowAllUrls(true);
      setTooltipOpen(!isTooltipOpen);
    }
  }

  return (
    <CustomTooltip
      tooltipTitle={tooltipTitle}
      webcamURL={webcamURL}
      showAllUrls={showAllUrls}
      setWebcamURL={setWebcamURL}
      hostsToShow={hostsToShow}
      isTooltipOpen={isTooltipOpen}
      setTooltipOpen={setTooltipOpen}
    >
      <span style={{display: 'flex'}}>
        {isSmallScreen ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<Videocam />}
            disabled={!webcamURL}
            onClick={onClick}
            style={{fontWeight: 400}}
            {...buttonProps}
          >
            View Live Webcam Feed
          </Button>
        ) : (
          <VerticalButton
            size="small"
            color="primary"
            disabled={!webcamURL}
            onClick={onClick}
            style={{margin: '0px 12px'}}
            {...buttonProps}
          >
            <Videocam />
            <Typography variant="caption">Webcam</Typography>
          </VerticalButton>
        )}
      </span>
    </CustomTooltip>
  );
};

const CustomTooltip = ({
  children,
  tooltipTitle,
  webcamURL,
  showAllUrls,
  setWebcamURL,
  hostsToShow,
  isTooltipOpen,
  setTooltipOpen,
}: {
  children: React.ReactElement;
  tooltipTitle: string;
  showAllUrls: boolean;
  webcamURL: string;
  hostsToShow?: DeviceHosts;
  setWebcamURL: (url: string) => void;
  isTooltipOpen: boolean;
  setTooltipOpen: (isOpen: boolean) => void;
}) => {
  if (showAllUrls) {
    return (
      <ClickAwayListener onClickAway={() => isTooltipOpen && setTooltipOpen(false)}>
        <Tooltip
          arrow
          interactive
          key="all-urls"
          open={isTooltipOpen}
          title={
            <AllWebcamHosts webcamURL={webcamURL} hostsToShow={hostsToShow} setWebcamURL={setWebcamURL} isInTooltip />
          }
        >
          {children}
        </Tooltip>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip title={<Typography>{tooltipTitle || 'View live webcam feed'}</Typography>} key="webcam-tooltip">
      {children}
    </Tooltip>
  );
};
