import React from 'react';
import {Chip, withStyles, Tooltip, Typography, IconButton, Box} from '@material-ui/core';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {cloneDeep} from 'lodash';
import {useSimilarityReportStoreActions} from '../../../store/actions';
import {Clear} from '@material-ui/icons';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxHeight: '80vh',
    maxWidth: '50vw',
    overflow: 'auto',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'normal',
    padding: '12px',
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `0px 0px 15px ${theme.palette.grey[400]}`,
  },
}))(Tooltip);

const PartChips = ({
  parts,
  setParts,
}: {
  parts: IPartGETResponse[];
  setParts: (newParts: IPartGETResponse[]) => void;
}) => {
  const {setCurrentlyViewingPart} = useSimilarityReportStoreActions();

  const onRemovePart = (index: number) => {
    const newParts = cloneDeep(parts);
    newParts.splice(index, 1);
    setParts(newParts);
  };

  const removeAllParts = () => setParts([]);

  if (parts.length <= 5) {
    return (
      <>
        {parts.map((part, index) => (
          <Chip
            key={index}
            style={{marginRight: '12px'}}
            label={part.name}
            onDelete={() => onRemovePart(index)}
            onClick={() => setCurrentlyViewingPart(part)}
            color="primary"
          />
        ))}
      </>
    );
  }

  return (
    <CustomTooltip
      interactive
      title={
        <ul style={{margin: '0px', paddingLeft: '24px'}}>
          {parts.map((part, index) => (
            <li key={`${part.uuid}-${index}`}>
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap={false}>
                <Typography>{part.name}</Typography>
                <IconButton
                  size="small"
                  style={{marginLeft: '24px', width: '20px'}}
                  onClick={() => onRemovePart(index)}
                >
                  <Clear style={{height: '20px', width: '20px'}} />
                </IconButton>
              </Box>
            </li>
          ))}
        </ul>
      }
    >
      <Chip label={`${parts.length} Parts Selected`} color="primary" onDelete={removeAllParts} />
    </CustomTooltip>
  );
};

export default PartChips;
