import {
  AssignmentTurnedIn,
  Layers,
  Notifications,
  SettingsInputComponent,
  Tune,
  CloudUpload as MuiCloudUpload,
  SvgIconComponent,
  PersonAdd,
} from '@material-ui/icons';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {IBuild} from '@common/api/models/builds/IBuild';
import {ISliceAttachment} from '@common/api/models/attachments/ISliceAttachment';
import {StateInput} from '../../index';

import BuildConfigurationStep from './draftBuildSteps/BuildConfigurationStep';
import BatchSelectionStep from './draftBuildSteps/BatchSelectionStep';
import MachineParametersStep from './draftBuildSteps/MachineParametersStep';
import UploadStep from './draftBuildSteps/UploadStep';
import NotificationStep from './draftBuildSteps/NotificationsStep';
import ConfirmationStep from './draftBuildSteps/ConfirmationStep';
import UserAccessStep from './draftBuildSteps/UserAccessStep';

const CloudUpload = styled(MuiCloudUpload)(spacing);

type BuildWizardInputComponent = (props: StateInput<IBuild>) => JSX.Element;

export interface BuildStep {
  id: string;
  title: string;
  subtitle: string;
  component: BuildWizardInputComponent;
  icon: SvgIconComponent;
  optional: boolean;
  nonBlockingRequired?: boolean;
  completed: (build: IBuild, slices: ISliceAttachment[], isSimpleWorkflow?: boolean) => boolean;
}

const draftBuildStepsConfig: BuildStep[] = [
  {
    id: 'configuration',
    component: BuildConfigurationStep,
    title: 'Step 1: Configuration',
    subtitle: 'Select device and general info',
    icon: Tune,
    optional: false,
    completed: (build) => {
      return build && !!build.name && !!build.machineUuid && !!build.deviceSerial && !!build.sensorProfileUuid; // TODO: validation
    },
  },

  {
    id: 'materials',
    component: BatchSelectionStep,
    title: 'Step 2: Material Batch Selection',
    subtitle: 'Choose a batch',
    icon: Layers,
    optional: false,
    completed: (build, _slices, isSimpleWorkflow) => {
      return isSimpleWorkflow ? true : build && !!build.batchUuid; // TODO: validation
    },
  },

  {
    id: 'parameters',
    component: MachineParametersStep,
    title: 'Step 3: Machine Parameters',
    subtitle: 'Choose parameter sets',
    icon: SettingsInputComponent,
    optional: true,
    completed: (build, slices) => {
      // Either machine parameters are set, or the user left it out but has done the previous and next steps.
      // Made optional temporarily
      return (build && !!build.machineParametersUuid) || (slices.length > 0 && !!build.batchUuid);
    },
  },

  {
    id: 'upload',
    component: UploadStep,
    title: 'Step 4: Upload',
    subtitle: 'Slice files, scene data and general docs',
    icon: CloudUpload,
    optional: false, // optionally, make uploading of a slice file optional.
    nonBlockingRequired: true,
    completed: (build, sliceAttachments) => {
      return build && sliceAttachments.filter((sliceFile) => !sliceFile.deletedAt).length > 0;
    },
  },

  {
    id: 'user_access',
    component: UserAccessStep,
    title: 'Step 5: User Access',
    subtitle: 'Grant access to other technician users',
    icon: PersonAdd,
    optional: true,
    completed: () => {
      return true;
    },
  },

  {
    id: 'notifications',
    component: NotificationStep,
    title: 'Step 6: Notifications',
    subtitle: 'Recipients and preferences',
    icon: Notifications,
    optional: true,
    completed: () => {
      return true;
    },
  },

  {
    id: 'confirmation',
    component: ConfirmationStep,
    title: 'Step 7: Confirmation',
    subtitle: 'Final check before submission',
    icon: AssignmentTurnedIn,
    optional: false,
    completed: (_build) => {
      return true;
    },
  },
];

export default draftBuildStepsConfig;
