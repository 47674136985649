import React from 'react';
import {Typography} from '@material-ui/core';
import {Panorama} from '@material-ui/icons';
import {useParams} from 'react-router-dom';

import BottomToolbarContainer from '../../../components/atoms/BottomToolbarContainer';
import VerticalButton from '../../../components/atoms/VerticalButton';
import AddMoreTargetPartsButton from './AddMoreTargetPartsButton';
import {AddPartsButton, CancelButton} from './AddMoreTargetPartsScreen';
import ComparisonWeights from './ComparisonWeights';

const SimilarityResultsBottomToolbar = ({toggleViewportFullscreen}: {toggleViewportFullscreen: () => void}) => {
  const {uuid, step} = useParams<{uuid: string; step?: string}>();

  return (
    <BottomToolbarContainer columns={'min-content auto min-content min-content'}>
      <VerticalButton onClick={toggleViewportFullscreen} color="secondary">
        <Panorama />
        <Typography variant="caption">Focus viewport</Typography>
      </VerticalButton>
      <div />
      {step !== 'addMoreTargetParts' && <ComparisonWeights uuid={uuid} />}
      {step !== 'addMoreTargetParts' && <AddMoreTargetPartsButton uuid={uuid} />}
      {step === 'addMoreTargetParts' && (
        <>
          <CancelButton uuid={uuid} />
          <AddPartsButton uuid={uuid} />
        </>
      )}
    </BottomToolbarContainer>
  );
};

export default SimilarityResultsBottomToolbar;
