import React, {useState} from 'react';
import {useMultiPartUpload} from '../../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../../utils/contexts/IMultiPartUploadContext';
import {CommonCTStepProps} from './CTReportStep';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import {CloudUpload} from '@material-ui/icons';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import DropZoneContainer from '../../../components/molecules/Cards/DropZoneContainer';

export const UploadCTDataStep = (props: CommonCTStepProps) => {
  const {currentCTReport} = props;
  const {onDrop} = useMultiPartUpload();
  const [numFilesUploading, setNumFilesUploading] = useState<number>(0);

  const orgUuid = useSelector((state: RootState) => state.auth.user!.organizationUuid);

  function dropZoneOnDrop(droppedFiles: File[]) {
    onDrop(droppedFiles, MultiPartUploadResourceType.ctAttachment, {
      resourceUuid: currentCTReport.uuid,
      orgUuid: orgUuid,
      setNumFilesUploading,
    });
  }

  return (
    <Dropzone onDrop={dropZoneOnDrop} noClick accept={{'application/zip': ['.zip']}} maxFiles={1}>
      {({getRootProps, getInputProps, open, isDragActive}) => {
        return (
          <DropZoneContainer isDragActive={isDragActive}>
            <section className="container">
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <Row>
                  <CloudUpload color="primary" style={{fontSize: 48}} />
                </Row>
                <Row>
                  <Typography variant="h4">Drag & drop to upload</Typography>
                </Row>
                <Row>
                  <Typography variant="subtitle1">or</Typography>
                </Row>
                <Row>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={open}
                    size="large"
                    fullWidth={false}
                    endIcon={numFilesUploading ? <CircularProgress size={20} /> : undefined}
                  >
                    Browse files...
                  </Button>
                </Row>
                <Row>
                  <Typography style={{fontStyle: 'italic', marginTop: '16px'}}>Only .zip files accepted</Typography>
                </Row>
              </div>
            </section>
          </DropZoneContainer>
        );
      }}
    </Dropzone>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 0px;
`;
