import {RouteComponentProps} from 'react-router-dom';
import {toast} from 'react-toastify';
import {IPart} from '@common/api/models/builds/data/IPart';
import {
  ComparisonWeightsType,
  ISimilarityComparisonGETResponse,
  ISimilarityReport,
  RotationMap,
} from '@common/api/models/builds/data/ISimilarity';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {partsAllGET} from '../../api/ajax/parts';
import {
  SimilarityReportActions,
  SetDraftSimilarityReportAction,
  SetCurrentlyViewingPartAction,
  ClearPartsForBuildAction,
  DraftSimilarityReport,
  SetCurrentlyViewingComparisonAction,
  ClearDraftSimilarityReportAction,
  SetNewTargetPartRotationsAction,
} from '../model/similarityReport';
import {
  similarityComparisonsStatusGET,
  similarityReportDELETE,
  similarityReportPATCH,
  similarityReportPOST,
  similarityReportSingleGET,
} from '../../api/ajax/similarityReport';
import {RootState} from '../reducers';
import {QueryFilter} from './liveUpdateStore';
import {SetNewTargetPartsAction} from '../model/similarityReport';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  deleteSimilarityReport(report: ISimilarityReport) {
    return async (dispatch: Function) => {
      const response = await similarityReportDELETE(report.uuid);

      if (response.success) {
        dispatch({
          type: SimilarityReportActions.DELETE_SIMILARITY_REPORT_SUCCESS,
          payload: report,
        });
        return true;
      } else {
        return false;
      }
    };
  },

  setDraftSimilarityReport<F extends keyof DraftSimilarityReport, V extends DraftSimilarityReport[F]>(
    field: F,
    value: V
  ): SetDraftSimilarityReportAction {
    return {
      type: SimilarityReportActions.SET_SIMILARITY_REPORT_DRAFT,
      payload: {field, value},
    };
  },

  clearDraftSimilarityReport(): ClearDraftSimilarityReportAction {
    return {
      type: SimilarityReportActions.CLEAR_SIMILARITY_REPORT_DRAFT,
    };
  },

  setCurrentlyViewingPart(currentlyViewingPart: IPartGETResponse | undefined): SetCurrentlyViewingPartAction {
    return {
      type: SimilarityReportActions.SET_SIMILARITY_CURRENTLY_VIEWING_PART,
      payload: currentlyViewingPart,
    };
  },

  createSimilarityReport(history: RouteComponentProps['history']) {
    return async (dispatch: Function, getState: () => RootState) => {
      dispatch({
        type: SimilarityReportActions.CREATE_SIMILARITY_REPORT_REQUEST,
      });
      const draftSimilarityReport = getState().similarityReportStore.draftSimilarityReport;

      const similarityReport = {
        name: draftSimilarityReport.name || draftSimilarityReport.backupName,
        sourcePartUuids: draftSimilarityReport.sourceParts!.map((sourcePart) => sourcePart!.uuid!),
        targetPartUuids: draftSimilarityReport.targetParts!.map((targetPart) => targetPart!.uuid!),
        sourcePartRotations: draftSimilarityReport.sourcePartRotations,
        targetPartRotations: draftSimilarityReport.targetPartRotations,
        comparisonWeights: draftSimilarityReport.comparisonWeights,
      };

      const response = await similarityReportPOST(similarityReport);

      if (response.success) {
        dispatch({
          type: SimilarityReportActions.CREATE_SIMILARITY_REPORT_SUCCESS,
          payload: response.data,
        });
        history.push(`/reports/similarity/uuid/${response.data.uuid}`);
      } else {
        dispatch({
          type: SimilarityReportActions.CREATE_SIMILARITY_REPORT_FAILURE,
        });
      }
    };
  },

  fetchPartsForBuild(skip: number, buildUuid: string, buildName: string, filters: QueryFilter<IPart>) {
    return async (dispatch: Function) => {
      const res = await partsAllGET({
        skip,
        take: 25,
        buildUuid,
        ...filters,
      });

      if (res.success) {
        dispatch({
          type: SimilarityReportActions.FETCH_PARTS_FOR_BUILD_SUCCESS,
          payload: {parts: res.data, buildUuid, buildName, total: res.count!},
        });
      } else {
        toast('Failed to fetch parts', {type: 'error'});
      }
    };
  },

  clearPartsForBuild(): ClearPartsForBuildAction {
    return {
      type: SimilarityReportActions.CLEAR_PARTS_FOR_BUILD,
    };
  },

  fetchSimilarityReport(uuid: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      dispatch({
        type: SimilarityReportActions.FETCH_SIMILARITY_REPORT_REQUEST,
      });

      const response = await similarityReportSingleGET(uuid);
      if (response.success) {
        dispatch({
          type: SimilarityReportActions.FETCH_SIMILARITY_REPORT_SUCCESS,
          payload: response.data,
        });
        return;
      }

      dispatch({
        type: SimilarityReportActions.FETCH_SIMILARITY_REPORT_FAILURE,
      });
    };
  },

  updateSimilarityReport(
    uuid: string,
    changes: {
      name?: string;
      targetPartUuids?: string[];
      targetPartRotations?: RotationMap;
      comparisonWeights?: ComparisonWeightsType;
    }
  ) {
    return async (dispatch: Function, _getState: () => RootState) => {
      const response = await similarityReportPATCH(uuid, changes);
      if (response.success) {
        dispatch({
          type: SimilarityReportActions.UPDATE_SIMILARITY_REPORT_SUCCESS,
          payload: response.data,
        });
        return true;
      }
      return false;
    };
  },

  setNewTargetParts(targetParts: IPartGETResponse[]): SetNewTargetPartsAction {
    return {
      type: SimilarityReportActions.SET_NEW_TARGET_PARTS_SUCCESS,
      payload: targetParts,
    };
  },

  setNewTargetPartRotations(targetPartRotations: RotationMap): SetNewTargetPartRotationsAction {
    return {
      type: SimilarityReportActions.SET_NEW_TARGET_PART_ROTATIONS_SUCCESS,
      payload: targetPartRotations,
    };
  },

  setCurrentlyViewingComparison(
    currentlyViewingComparison: ISimilarityComparisonGETResponse | undefined
  ): SetCurrentlyViewingComparisonAction {
    return {
      type: SimilarityReportActions.SET_SIMILARITY_CURRENTLY_VIEWING_COMPARISON,
      payload: currentlyViewingComparison,
    };
  },

  fetchReportProgress(uuid: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      const response = await similarityComparisonsStatusGET(uuid);

      if (response.success) {
        dispatch({
          type: SimilarityReportActions.FETCH_SIMILARITY_REPORT_PROGRESS_SUCCESS,
          payload: response.data,
        });
      }
    };
  },
};
