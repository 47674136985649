import {FilterSchemaType} from './index';

export const organizationFilterSchema: Array<FilterSchemaType> = [
  {
    field: 'name',
    type: 'string',
  },
  {
    field: 'registered',
    type: 'dateRange',
  },
];
