import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {Typography} from '@material-ui/core';
import React from 'react';
import {BUTTON_MARGIN} from '../utils';

interface HoveredPartLabelProps {
  hoveredPart: IPartGETResponse | null;
}

export function HoveredPartLabel(props: HoveredPartLabelProps) {
  if (!props.hoveredPart) {
    return <></>;
  }
  return (
    <div
      style={{
        left: 120 + BUTTON_MARGIN * 3, // 120 is the width of the part list button
        top: BUTTON_MARGIN * 2,
        position: 'absolute',
        color: '#fff',
      }}
    >
      <Typography>
        Hovered part: <span style={{color: 'orange'}}>{props.hoveredPart.name}</span>
      </Typography>
    </div>
  );
}
