import React, {ReactNode, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Card, CardContent, Grid, Typography, Box} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Help from '@material-ui/icons/Help';

import {versionGET} from '../api/ajax/support';
import {useSelector} from 'react-redux';
import {RootState} from '../store/reducers';
import {useDeviceStoreActions} from '../store/actions';
import EllipsisTextWithTooltip from '../components/atoms/Texts/EllipsisTextWithTooltip';
import {useSmallScreenSize} from '../utils/utilHooks';
import Header from '../components/organisms/Header';

export function SupportPage() {
  const [webappVersion, setWebappVersion] = useState<string | null>(null);
  const [vcsRef, setVcsRef] = useState<string | null>(null);
  const [awsRegion, setAwsRegion] = useState<string | null>(null);
  const deviceStoreActions = useDeviceStoreActions();
  const devices = useSelector((state: RootState) => state.deviceStore.byId);
  const isSmallScreen = useSmallScreenSize();

  useEffect(() => {
    versionGET().then((result) => {
      if (result.success) {
        setWebappVersion(result.data.appVersion);
        setVcsRef(result.data.vcsRef);
        setAwsRegion(result.data.awsRegion);
      }
    });
    deviceStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Header helmet="Support" title="Support" breadcrumbs={['Support']} />
      <Grid container direction="column" alignItems="center">
        <Grid container spacing={6} style={{maxWidth: isSmallScreen ? '95%' : '60%'}}>
          {webappVersion && (
            <StyledGrid item xs={12} sm={12} md={12}>
              <Alert severity="info">
                <Typography variant="subtitle2">Webapp Version</Typography>
                <Box width="100%" display="flex">
                  <Box width="120px">Version: </Box>
                  <b>{webappVersion}</b>
                </Box>
                <Box width="100%" display="flex">
                  <Box width="120px">VCS Ref: </Box>
                  <b>{vcsRef}</b>
                </Box>
                <Box width="100%" display="flex">
                  <Box width="120px">Region: </Box>
                  <b>{awsRegion}</b>
                </Box>
              </Alert>
            </StyledGrid>
          )}
          {!!Object.values(devices).length && (
            <StyledGrid item xs={12} sm={12} md={12}>
              <Alert severity="info">
                <Typography variant="subtitle2">Device Versions</Typography>

                {Object.values(devices).map((device) => (
                  <Box width="100%" display="flex">
                    <Box width="120px">
                      <EllipsisTextWithTooltip>{device.deviceId}: </EllipsisTextWithTooltip>
                    </Box>
                    <b>
                      sw{device.swVer}, hw{device.hwVer}
                    </b>
                  </Box>
                ))}
              </Alert>
            </StyledGrid>
          )}
          <Grid item xs={12} sm={12} md={12}>
            <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="stretch">
              <SupportCardItem
                titleText="User Guide"
                linkURL="https://support.addassure.com/"
                icon={<MenuBookIcon fontSize="large" />}
                descriptionText="Guides to help you get up and running with both hardware and software."
              ></SupportCardItem>
              <SupportCardItem
                titleText="How to Guide"
                linkURL="https://support.addassure.com/docs/category/platform-guide"
                icon={<Help fontSize="large"></Help>}
                descriptionText="Details regarding our workflow broken up into intuitive sections."
              ></SupportCardItem>
              <SupportCardItem
                titleText="Troubleshooting"
                linkURL="https://support.addassure.com/docs/troubleshooting"
                icon={<QuestionAnswerIcon fontSize="large" />}
                descriptionText="Many error cases are explained and walked through to resolution."
              ></SupportCardItem>
              <SupportCardItem
                titleText="Contact Us"
                linkURL="https://support.addassure.com/docs/contact_us"
                icon={<ContactSupportIcon fontSize="large" />}
                descriptionText="Get in touch!"
              ></SupportCardItem>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const StyledGrid = styled(Grid)`
  .MuiAlert-message {
    width: 100%;
  }
`;

const SupportCardItem = (props: {titleText: string; descriptionText: string; linkURL: string; icon: ReactNode}) => {
  return (
    <Grid item xs={12} md={6}>
      <Card onClick={() => window.open(props.linkURL)} style={{cursor: 'pointer', height: '100%'}}>
        <CardContent>
          <Box display="flex">
            <Box pr={3}>{props.icon}</Box>
            <Box pt={1}>
              <Typography variant="h6">{props.titleText}</Typography>
              <Typography>{props.descriptionText}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
