import React from 'react';

import {MaterialTableProps} from 'material-table';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {IMachinesTableGETResponse} from '@common/api/models/devices/machines/IMachine';
import {DeviceAvailability} from '@common/api/models/devices/IDevice';
import GenericTable, {GenericTableProps} from './GenericTable';

export interface MachineRow {
  availability: DeviceAvailability;
  name: string;
  model: string;
  device?: string;
  registered: Date | string;
  uuid: string;
  url: string;
}

const useMachineData = () => {
  const machineStore = useSelector((state: RootState) => state.machineStore.list);

  return machineStore.map((machine: IMachinesTableGETResponse) => {
    return {
      ...machine,
      device: machine.deviceName,
    };
  });
};

export function MachinesTable({
  exclude,
  tableOptions = {},
  onRowClick,
}: {
  exclude?: (keyof MachineRow)[];
} & Partial<GenericTableProps>) {
  return (
    <GenericTable
      resourceType="machine"
      useData={useMachineData}
      tableProps={{exclude} as Partial<MaterialTableProps<MachineRow>>}
      onRowClick={onRowClick}
      tableOptions={tableOptions}
      filteringEnabled
    />
  );
}
