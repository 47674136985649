import {BuildsByPartsQueryGetResponse} from '@common/api/models/builds/data/IPart';
import React, {useState, useMemo, useEffect, useRef, Dispatch, SetStateAction} from 'react';
import {omit} from 'lodash';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import {buildsByPartQueryGET} from '../../../api/ajax/parts';
import {CircularProgress, Typography, Divider as MuiDivider} from '@material-ui/core';
import {FiltersState} from '../../../pages/shared/IndexPage/IndexPage';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import PartsTreeViewHeader from './PartSelectorTreeView/PartsTreeViewHeader';
import {useIsInViewport, LoadMoreSensor} from '../../../utils/LoadMoreSensor';
import BuildsPartsTree from './PartSelectorTreeView/BuildsPartsTree';
import {BuildState} from '@common/api/models/builds/IBuild';
import {SimilarityStep} from '../SimilarityCreatePage';

const Divider = styled(MuiDivider)(spacing);

export type PartSelectorModeType = 'draft' | 'edit';

const PartSelectorTreeView = ({selectionType, mode}: {selectionType: SimilarityStep; mode: PartSelectorModeType}) => {
  const [filters, setFilters] = useState<FiltersState>({take: 25, page: 0});
  const [builds, setBuilds] = useState<BuildsByPartsQueryGetResponse[]>([]);
  const [allBuildsLoaded, setAllBuildsLoaded] = useState(false);
  const loadMoreRef: React.RefObject<HTMLDivElement> = useRef(null);
  const shouldLoadMore = useIsInViewport(loadMoreRef);

  const {clearPartsForBuild, setCurrentlyViewingPart} = useSimilarityReportStoreActions();

  useEffect(() => {
    if (shouldLoadMore && !allBuildsLoaded) {
      loadBuilds(filters, setBuilds, setFilters, setAllBuildsLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore, filters]);

  useEffect(() => {
    return () => {
      clearPartsForBuild();
      setCurrentlyViewingPart(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFilters = (filters: FiltersState) => {
    setBuilds([]);
    setAllBuildsLoaded(false);
    clearPartsForBuild();
    loadBuilds(filters, setBuilds, setFilters, setAllBuildsLoaded);
  };

  const filtersWithoutPagination = useMemo(() => omit(filters, ['take', 'page']), [filters]);

  return (
    <TreeViewContainer>
      <PartsTreeViewHeader filters={filters} setFilters={updateFilters} />
      <Divider mb={3} mt={3} />
      <AbsoluteWrapper>
        <BuildsTreeViewContainer>
          <BuildsPartsTree
            builds={builds}
            filters={filtersWithoutPagination}
            selectionType={selectionType}
            mode={mode}
          />
          {allBuildsLoaded && builds.length === 0 && <Typography>No Builds found</Typography>}
          {!allBuildsLoaded && (
            <LoadMoreSensor ref={loadMoreRef}>
              <CircularProgress size={14} />
              <Typography>Loading more builds...</Typography>
            </LoadMoreSensor>
          )}
        </BuildsTreeViewContainer>
      </AbsoluteWrapper>
    </TreeViewContainer>
  );
};

export default PartSelectorTreeView;

const loadBuilds = async (
  filters: FiltersState,
  setBuilds: Dispatch<SetStateAction<BuildsByPartsQueryGetResponse[]>>,
  setFilters: Dispatch<SetStateAction<FiltersState>>,
  setAllBuildsLoaded: Dispatch<SetStateAction<boolean>>
) => {
  const {page, ...query} = filters;
  const skip = page * filters.take;

  const res = await buildsByPartQueryGET({
    ...query,
    skip,
    buildState: {
      in: [BuildState.FAILED, BuildState.COMPLETED, BuildState.SUCCEEDED],
    },
  });
  if (res.success) {
    setBuilds((currentBuilds) => [...currentBuilds, ...res.data]);
    setAllBuildsLoaded(res.count! <= skip + filters.take);
    setFilters({...filters, page: filters.page + 1});
  }
};

const TreeViewContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }

  .MuiRadio-root,
  .MuiCheckbox-root {
    padding: 4px;
  }

  .MuiCollapse-wrapperInner .MuiTreeItem-iconContainer {
    display: none;
  }
`;

const AbsoluteWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const BuildsTreeViewContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 0;
  right: 0;
  overflow: auto;
`;
