import React, {useState} from 'react';
import {Box, Button, Typography} from '@material-ui/core';
import {Divider as MuiDivider, CardContent, Card as MuiCard} from '@material-ui/core';
import {useSmallScreenSize} from '../../utils/utilHooks';
import styled from 'styled-components';
import {spacing} from '@material-ui/system';
import IntensityGraph from './IntensityGraph/IntensityGraph';
import {IntensityGraphOptionsModal} from './IntensityGraph/GraphOptionsModal';
import {HoveredSelection} from './BuildIntensityPage';

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const BuildIntensityGraphView = ({
  d3Color,
  hoveredSelection,
}: {
  d3Color: d3.scale.Ordinal<string, string>;
  hoveredSelection: HoveredSelection;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const [isGraphModalOpen, setGraphModalOpen] = useState(false);

  return (
    <>
      <Card style={{height: isSmallScreen ? '484px' : '66%', overflow: 'visible'}}>
        <CardContent style={{height: '100%'}}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Intensity per Layer</Typography>
            <Button variant="outlined" onClick={() => setGraphModalOpen(true)} color="primary" size="small">
              Graph Options
            </Button>
          </Box>

          <Divider my={isSmallScreen ? 3 : 6} />

          <StyledBox height={`calc(100% - ${isSmallScreen ? '54px' : '72px'})`}>
            <IntensityGraph d3Color={d3Color} hoveredSelection={hoveredSelection} />
          </StyledBox>
        </CardContent>
      </Card>

      <IntensityGraphOptionsModal open={isGraphModalOpen} onClose={() => setGraphModalOpen(false)} />
    </>
  );
};
export default BuildIntensityGraphView;

const StyledBox = styled(Box)`
  .hoverlayer {
    svg.main-svg,
    svg.main-svg * {
      overflow: visible !important;
      z-index: 9999;
    }
  }
`;
