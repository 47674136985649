import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {BatchEventData, IBatch} from '@common/api/models/materials/batches/IBatch';
import * as qs from 'qs';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function batchesAllGET(filter: QueryFilter<IBatch>): AjaxApiResult<IBatch[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/batches/' + (query && '?' + query));
  }, 'Could not fetch batches');
}

export async function batchCreatePOST(
  batch: Partial<IBatch>,
  materialUuid: string,
  quantity: number
): AjaxApiResult<IBatch> {
  return ajaxWrapper(() => {
    return axios.post('/api/batches/', {...batch, materialUuid, quantity});
  }, 'Could not create batch');
}

export async function batchesByUuidPATCH(uuid: string, update: Partial<IBatch>): AjaxApiResult<IBatch> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/batches/${uuid}`, update);
  }, 'Could not update batch');
}

export async function batchHistoryGET(uuid: string): AjaxApiResult<IBatch[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/batches/${uuid}/history/`);
  }, 'Could not fetch batch history');
}

export async function batchFutureGET(uuid: string): AjaxApiResult<IBatch[]> {
  return ajaxWrapper(() => {
    return axios.get(`/api/batches/${uuid}/future/`);
  }, 'Could not fetch batch future');
}

// Batch operations
/**
 * @param uuidA
 * @param uuidB
 * @param update Accepts name, description updates.
 */
export async function batchesMergePOST(uuidA: string, uuidB: string, update: Partial<IBatch>): AjaxApiResult<IBatch> {
  return ajaxWrapper(() => {
    return axios.post(`/api/batches/merge/${uuidA}/${uuidB}`, update);
  }, 'Could not merge batches');
}

export async function batchesSplitPOST(
  uuid: string,
  quantityA: number,
  batchA: Partial<IBatch>,
  batchB: Partial<IBatch>
): AjaxApiResult<[IBatch, IBatch]> {
  return ajaxWrapper(() => {
    return axios.post(`/api/batches/${uuid}/split/`, {
      quantityA,
      batchA,
      batchB,
    });
  }, 'Could not split batch');
}

export async function batchEventPOST(uuid: string, event: BatchEventData): AjaxApiResult<IBatch> {
  return ajaxWrapper(() => {
    return axios.post(`/api/batches/${uuid}/event/`, {...event});
  }, 'Could not submit batch event');
}
