import React from 'react';
import {materialCreatePOST} from '../../api/ajax/materials';
import CustomButton from '../../components/atoms/CustomButton';
import {IMaterial, IMaterialPost} from '@common/api/models/materials/IMaterial';
import {Button, Card, CardContent, Grid, InputBase, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import moment from 'moment';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {Alert} from '@material-ui/lab';
import RequiredAsterisk from '../../components/atoms/Texts/RequiredAsterisk';
import Header from '../../components/organisms/Header';

export function CreateMaterialPage() {
  const materialDefaultValues: Partial<IMaterialPost> = {
    name: '', // required
    description: '',
    manufactured: undefined, // required
    dateReceived: new Date(),
    quantityReceived: 0, // required
    type: '',
    grade: '',
    supplier: '',
    supplierLot: '',
    chemicalComposition: '',
    powderSize: '',
    tapDensity: '',
    hallFlowCharacteristics: '',
  };
  const {
    register,
    control,
    formState,
    handleSubmit: onSubmit,
  } = useForm<IMaterial>({
    mode: 'all',
    defaultValues: materialDefaultValues,
  });
  const {isValid} = formState;

  const history = useHistory();

  const handleCreateClick = async (data: IMaterialPost) => {
    const postData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== ''));
    const res = await materialCreatePOST(postData);
    if (res.success) {
      history.replace('/materials/uuid/' + res.data.uuid + '/');
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div>
      <Header
        helmet="Create Material"
        title="Create Material"
        breadcrumbs={[{title: 'Materials', path: '/materials/'}, 'Create Material']}
      />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={10} xl={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <form id="createMaterialForm">
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" variant="head">
                            Name <RequiredAsterisk />
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              name="name"
                              placeholder="Name"
                              fullWidth
                              inputRef={register({required: true})}
                              inputProps={{
                                'aria-required': 'true',
                              }}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Description
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              name="description"
                              placeholder="Description"
                              multiline
                              rowsMax={5}
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Date Manufactured <RequiredAsterisk />
                          </TableCell>
                          <TableCell>
                            <Controller
                              render={({onChange}) => (
                                <InputBase
                                  type="date"
                                  placeholder="manufactured"
                                  fullWidth
                                  name="manufactured"
                                  onChange={(e) => onChange(moment(e.target.value, 'YYYY-MM-DD'))}
                                  inputProps={{
                                    'aria-required': 'true',
                                    max: moment().format('YYYY-MM-DD'),
                                  }}
                                  autoComplete="off"
                                />
                              )}
                              name="manufactured"
                              control={control}
                              rules={{required: true}}
                              defaultValue={moment().format('YYYY-MM-DD')}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Received Date
                          </TableCell>
                          <TableCell>
                            <Controller
                              render={({onChange}) => (
                                <InputBase
                                  type="date"
                                  placeholder="dateReceived"
                                  fullWidth
                                  name="dateReceived"
                                  onChange={(e) => onChange(moment(e.target.value, 'YYYY-MM-DD'))}
                                  autoComplete="off"
                                  inputProps={{max: moment().format('YYYY-MM-DD')}}
                                />
                              )}
                              name="dateReceived"
                              control={control}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Quantity <RequiredAsterisk />
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="number"
                              placeholder="Quantity"
                              name="quantityReceived"
                              fullWidth
                              inputProps={{
                                min: 0,
                                'aria-required': 'true',
                              }}
                              inputRef={register({
                                required: true,
                                min: 0,
                                validate: (quantity) => quantity > 0,
                              })}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Material Class
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Class"
                              name="type"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Grade
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Grade"
                              name="grade"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Supplier
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Supplier"
                              name="supplier"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Supplier Lot No.
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Supplier Lot"
                              name="supplierLot"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Chemical Composition
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Chemical Composition"
                              name="chemicalComposition"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Powder Size
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Powder Size"
                              name="powderSize"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Tap Density
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Tap density"
                              name="tapDensity"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Apparent Density
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Apparent density"
                              name="apparentDensity"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" variant="head">
                            Hall Flow Characteristics
                          </TableCell>
                          <TableCell>
                            <InputBase
                              type="text"
                              placeholder="Hall Flow Characteristics"
                              name="hallFlowCharacteristics"
                              fullWidth
                              inputRef={register}
                              autoComplete="off"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </form>
                  <Alert severity="info" style={{marginTop: 16}}>
                    Certificates & other attachments can be uploaded after a material has been created
                  </Alert>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button size="large" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <CustomButton
                    type="submit"
                    form="createMaterialForm"
                    text={'Create'}
                    onClick={onSubmit(handleCreateClick)}
                    disabled={!isValid}
                    variant="contained"
                    color="primary"
                    size="large"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
