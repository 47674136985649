import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {ISimilarityComparison, SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';

import Base3DViewport, {initialParams} from './Base3DViewport';
import {usePointCloudEffects} from './pointCloudHooks/usePointCloudEffects';
import {useSimilarityPointClouds} from './pointCloudHooks/useSimilarityPointClouds';
import {SimilarityViewportSidebar} from './Sidebar/View3DViewportSidebar';
import {ViewportMessage, showOverlayMessage} from './ViewportMessage';
import {View3DViewportParams} from './View3DViewport';
import {RootState} from '../../../../store/reducers/index';
import {usePartStoreActions} from '../../../../store/actions/index';
import {objMapValues} from '../../../../utils/objectFunctions';
import {MinMax} from '../../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import AnalysisTypeHelper from './AnalysisTypeHelper';
import {initialPointCloudParams} from './types/params';
import {AnalysisTypeMap, boxGeometry} from './types/pointCloudTypes';
import {toggleAnalysisTypeVisibility} from './viewportFunctions/control';

export interface ISimilarity3DViewport {
  similarityComparison?: ISimilarityComparison;
  reportUuid: string;
  height?: number;
}

export const initialSimilarityParams: View3DViewportParams = {
  ...initialParams,
  ...initialPointCloudParams,
  pointSize: 0.8,
  use3DPoints: true,
  isTransparent: true,
  availableParts: [],
  selectedParts: [],
  pointSizeCutoff: {},
  analysisAmountCutoff: {},
  availableAnalysisTypes: objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>,
  selectedAnalysisTypes: {
    ...(objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>),
    [AnalysisType3D.Geometry]: true,
  },
  analysisTypeSizes: objMapValues(AnalysisType3D, () => ({})) as AnalysisTypeMap<MinMax<number>>,
  isSimilarity: true,
  sourceGeometryEnabled: false,
  targetGeometryEnabled: false,
  comparisonScaling: false,
};

export default function Similarity3DViewport({height, reportUuid, similarityComparison}: ISimilarity3DViewport) {
  const [internalSimilarityComparison, setInternalSimilarityComparison] = useState<ISimilarityComparison>();
  const [params, setParams] = useState<View3DViewportParams>(initialSimilarityParams);

  const sources = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport!.sources);
  const sourcePartDetails = sources.find((source) => source.rotation === 0) || sources[0];
  const sourcePartUuid = sourcePartDetails.uuid;

  const rerenderRef = React.useRef(() => {});
  // Function to force a rerender of the 3D viewport
  const renderScene = rerenderRef.current;
  const {viewportState, viewportLoading, pointClouds, sceneBounds, availableAnalysisTypes, analysisTypeSizes} =
    useSimilarityPointClouds(internalSimilarityComparison, sourcePartUuid, reportUuid, params, renderScene);

  const partStoreActions = usePartStoreActions();
  const sourcePart = useSelector((state: RootState) =>
    sources.length !== 0 ? state.partStore.byId[sourcePartUuid] : null
  );
  const targetPart = useSelector((state: RootState) =>
    similarityComparison?.targetPartUuid ? state.partStore.byId[similarityComparison.targetPartUuid] : null
  );

  useEffect(() => {
    // Rapid changes of the comparison selected causes loading issues / race conditions.
    // Instead of debouncing, we only set the comparison if we're not in a loading state. (Check again after loaded)
    // More or less the same effect as debouncing, except without the delay.
    // Only difference being that it may load twice (first and last selected) instead of only last.
    // Used instead of debouncing as the loading time could exceed the debounce delay.
    if (!viewportLoading && similarityComparison?.uuid !== internalSimilarityComparison?.uuid) {
      setInternalSimilarityComparison(similarityComparison);

      if (similarityComparison) {
        setParams((params) => ({
          ...params,
          rotation: {
            [sourcePartUuid]: sourcePartDetails.rotation,
            [similarityComparison.targetPartUuid]: similarityComparison.targetPartRotation,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [similarityComparison, viewportLoading, sourcePartUuid]);

  useEffect(() => {
    if (internalSimilarityComparison && internalSimilarityComparison.status === SimilarityStatus.Success) {
      const filter = {
        uuid: {
          in: [sourcePartUuid, internalSimilarityComparison.targetPartUuid],
        },
      };
      partStoreActions.ensureConsistent({...filter, includeFirstLayer: true}, filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalSimilarityComparison]);

  useEffect(() => {
    if (sourcePart) {
      setParams((params) => ({
        ...params,
        selectedParts: targetPart ? [sourcePart, targetPart] : [sourcePart],
        availableParts: targetPart ? [sourcePart, targetPart] : [sourcePart],
      }));
    }
  }, [sourcePart, targetPart]);

  useEffect(() => {
    // Update params if available analysis types changes
    setParams((params) => ({
      ...params,
      availableAnalysisTypes,
      // Select model analysis type if none are selected
      selectedAnalysisTypes: Object.values(params.selectedAnalysisTypes).some((enabled) => enabled)
        ? params.selectedAnalysisTypes
        : initialSimilarityParams.selectedAnalysisTypes,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAnalysisTypes]);

  useEffect(() => {
    toggleAnalysisTypeVisibility(params, setParams, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedAnalysisTypes, pointClouds.numParts, renderScene]);

  usePointCloudEffects(pointClouds, params, renderScene, viewportState, boxGeometry);

  return (
    <Base3DViewport
      sceneGroup={pointClouds}
      sceneBounds={sceneBounds}
      params={params}
      sidebar={
        <SimilarityViewportSidebar
          params={{...params, analysisTypeSizes}}
          sceneBounds={sceneBounds}
          setParams={setParams}
          resetParams={() =>
            setParams((params) => ({
              ...initialSimilarityParams,
              availableAnalysisTypes: params.availableAnalysisTypes,
              analysisTypeSizes: params.analysisTypeSizes,
              selectedParts: params.selectedParts,
              availableParts: params.availableParts,
            }))
          }
          viewportState={viewportLoading ? 'loading' : viewportState}
        />
      }
      sidebarInitOpen={false}
      overlayMessage={
        <ViewportMessage
          viewportState={viewportLoading ? 'loading' : viewportState}
          noSelectionTitle="No comparison selected"
          noSelectionMessage="Select a completed comparison to the left."
        />
      }
      showOverlayMessage={viewportLoading ? true : showOverlayMessage(viewportState)}
      showHelpers={['viewing', 'loading'].includes(viewportState)}
      rerenderRef={rerenderRef}
      height={height}
      rightButtons={
        <AnalysisTypeHelper
          {...params}
          analysisTypeSizes={analysisTypeSizes}
          setAnalysisType={(newAnalysisType: AnalysisType3D, oldAnalysisType: AnalysisType3D) =>
            setParams((params) => ({
              ...params,
              selectedAnalysisTypes: {
                ...params.selectedAnalysisTypes,
                [oldAnalysisType]: false,
                [newAnalysisType]: true,
              },
            }))
          }
        />
      }
    />
  );
}
