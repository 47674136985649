import React from 'react';
import {cloneDeep, get, set} from 'lodash';
import {SensorProfileType} from '../../../../store/model/sensorProfile';
import NumberField from './NumberField';
import EnumField from './EnumField';
import {DIFFERENT_VALUES_TEXT} from './ProfileForm';

function getAllValues(profileJSON: SensorProfileType, fieldPaths: string[]) {
  return fieldPaths.map((path) => get(profileJSON, path));
}

function getValue(profileJSON: SensorProfileType, fieldPaths: string[]) {
  const allValues = getAllValues(profileJSON, fieldPaths);

  if (new Set(allValues).size === 1) return allValues[0];

  return DIFFERENT_VALUES_TEXT;
}

const CALIBRATION_PATTERN_TOOLTIPS = {
  'CalibPatternC-250': 'The most common square pattern, typically used for build plates of size (250, 250)[mm]',
  'CalibPatternC-280': 'The most common square pattern, extended for SLM280 systems',
  CalibPatternD: 'Circular pattern, typical in SISMA and Trumpf systems',
  CalibPatternE: 'Custom pattern for large printers, typical in SLM500 systems',
  CalibPatternF: 'Custom pattern for reduced build volumes',
};

function ProfileField({
  fieldPaths,
  fieldName,
  config,
  profileJSON,
  updateProfile,
  calculateFrameRateFn,
  setErrors,
  errors,
  readOnly,
  selectedCameras,
  rowType,
}: {
  fieldPaths: string[];
  fieldName: string;
  config: {[key: string]: any};
  profileJSON: SensorProfileType;
  updateProfile: (paths: string[], value: any, updatingProfile?: SensorProfileType) => void;
  calculateFrameRateFn: (exposureTime?: number | null) => {
    calculatedValue: number;
    overallMin: number;
    overallMax: number;
  };
  setErrors: (errors: {[key: string]: boolean}) => void;
  errors: {[key: string]: boolean};
  readOnly: boolean;
  selectedCameras: Array<number>;
  rowType: 'column' | 'row';
}) {
  if (fieldPaths.length === 0) return <></>;

  const value = getValue(profileJSON, fieldPaths);
  const allValues = getAllValues(profileJSON, fieldPaths);

  function validateRangeAndUpdate(newValue: number | null) {
    if (fieldName !== 'exposure_time' || newValue === null) {
      updateProfile(fieldPaths, newValue);
      return;
    }

    const {calculatedValue, overallMin, overallMax} = calculateFrameRateFn(newValue);

    const newFrameRate = Math.floor(Math.min(overallMax, calculatedValue, 0.99 / newValue) * 1e5) / 1e5;
    const frameRateRoundedToRange = Math.max(Math.min(newFrameRate, overallMax), overallMin);
    const frameRatePaths = fieldPaths.map((path) => path.replace('[exposure_time]', '[frame_rate]'));

    const newProfile = cloneDeep(profileJSON!);

    fieldPaths.forEach((path) => set(newProfile, path, newValue));
    frameRatePaths.forEach((path) => set(newProfile, path, frameRateRoundedToRange));
    updateProfile([], null, newProfile);
  }

  if (config.type === 'number') {
    return (
      <NumberField
        disabled={readOnly}
        field={fieldName}
        value={value}
        onChange={validateRangeAndUpdate}
        min={config.min}
        max={fieldName === 'frame_rate' ? calculateFrameRateFn().calculatedValue : config.max}
        unit={config.unit}
        defaultValue={config.default}
        error={!!fieldPaths.map((path) => get(errors, path)).filter((error) => !!error)[0]}
        allValues={allValues
          .map((value, index) => `#${selectedCameras[index]}: ${value ? value.toFixed(2) : ''}`)
          .sort()}
        setError={(error: boolean) => {
          setErrors({
            ...errors,
            ...fieldPaths.reduce((errors, path) => ({...errors, [path]: error}), {}),
          });
        }}
        rowType={rowType}
      />
    );
  }

  if (config.type === 'enum') {
    return (
      <EnumField
        disabled={readOnly}
        field={fieldName}
        value={value}
        options={config.enum}
        onChange={(newValue) => updateProfile(fieldPaths, newValue)}
        tooltips={fieldName === 'pattern' ? CALIBRATION_PATTERN_TOOLTIPS : undefined}
      />
    );
  }
  return <></>;
}

export default ProfileField;
