import React, {FunctionComponent} from 'react';

import {Card, CardProps, CardContent as MuiCardContent, CardHeader, IconButton} from '@material-ui/core';
import styled, {useTheme} from 'styled-components';

import {MoreVertical} from 'react-feather';

export interface WidgetProps {
  title: string;
  hideSettings?: boolean;
  subTitle?: string;
  cardProps?: CardProps;
  cardContentStyles?: any;
}

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const Widget: FunctionComponent<WidgetProps> = ({hideSettings = true, ...props}) => {
  const theme = useTheme();

  return (
    <Card {...props.cardProps}>
      <CardHeader
        action={
          !hideSettings && (
            <IconButton aria-label="settings">
              <MoreVertical />
            </IconButton>
          )
        }
        title={props.title}
        subheader={props.subTitle}
      />
      <CardContent theme={theme} style={props.cardContentStyles}>
        {props.children}
      </CardContent>
    </Card>
  );
};

export default Widget;
