import React from 'react';
import {Card, CardContent, Grid} from '@material-ui/core';
import {MachinePageProps} from './index';
import ResourcePermissionsTable from '../../../components/molecules/Table/ResourcePermissionsTable';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';

function MachinePermissionsPage({machine}: MachinePageProps) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <ResourcePermissionsTable resourceType={ResourcePermissionType.MACHINE} resource={machine} />
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MachinePermissionsPage;
