import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import {Grid, Button} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';
import {IBuild} from '@common/api/models/builds/IBuild';
import AdvancedDraftBuild from './AdvancedDraftBuild';
import {useSliceAttachmentStoreActions} from '../../../store/actions/index';
import DraftBuildProvider from './DraftBuildContext';
import SaveStateChip from './SaveStateChip';
import DeleteBuildButton from './DeleteBuildButton';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {RootState} from '../../../store/reducers/index';
import SimpleDraftBuild from './SimpleDraftBuild';
import Header from '../../../components/organisms/Header';
import BuildHeader from '../shared/BuildHeader';

export interface DraftBuildProps {
  build: IBuild;
}

function DraftBuild({build}: DraftBuildProps) {
  const isSmallScreen = useSmallScreenSize();
  const {draftType, uuid} = useParams<{uuid: string; draftType: string}>();
  const history = useHistory();

  const slices = useSelector(
    (state: RootState) => Object.values(state.sliceAttachmentStore.byId).filter((s) => s.buildUuid === build.uuid),
    isEqual
  );

  const sliceAttachmentsStoreActions = useSliceAttachmentStoreActions();

  useEffect(() => {
    sliceAttachmentsStoreActions.ensureConsistent({buildUuid: build.uuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.uuid]);

  useEffect(() => {
    const isAdvancedDraft = localStorage.getItem(`${uuid}-advanced-draft`);
    if (!draftType) {
      if (isAdvancedDraft) {
        history.replace(`/builds/uuid/${uuid}/draft/advanced/`);
      } else {
        history.replace(`/builds/uuid/${uuid}/draft/simple/`);
      }
    }
  }, [draftType, history, uuid]);

  return (
    <DraftBuildProvider build={build} slices={slices} isSimpleWorkflow={draftType === 'simple'}>
      <Header
        helmet={`Draft Build - ${build.name}`}
        title={<BuildHeader build={build} />}
        breadcrumbs={[{title: 'Builds', path: '/builds'}, build.name]}
        endAdornment={
          isSmallScreen ? undefined : (
            <Grid container justifyContent="flex-end" wrap="nowrap">
              <BackToSimpleButton />
              <DeleteBuildButton />
            </Grid>
          )
        }
      />

      {isSmallScreen && <SaveStateChip />}
      {isSmallScreen && <DeleteBuildButton />}
      {isSmallScreen && <BackToSimpleButton />}

      {draftType === 'advanced' ? <AdvancedDraftBuild build={build} /> : <SimpleDraftBuild build={build} />}
    </DraftBuildProvider>
  );
}

export default DraftBuild;

function BackToSimpleButton() {
  const {draftType, uuid} = useParams<{uuid: string; draftType: string}>();
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();

  if (draftType !== 'advanced') return <></>;

  function exitAdvancedMode() {
    localStorage.removeItem(`${uuid}-advanced-draft`);
    history.push(`/builds/uuid/${uuid}/draft/simple/`);
  }

  return (
    <Grid item style={isSmallScreen ? {marginTop: '8px', marginBottom: '8px'} : {marginRight: '8px'}}>
      <Button
        color="primary"
        variant="outlined"
        onClick={exitAdvancedMode}
        fullWidth={isSmallScreen}
        startIcon={<ArrowBack />}
      >
        Exit advanced mode
      </Button>
    </Grid>
  );
}
