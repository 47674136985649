import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button as MuiButton, Grid, Paper as MuiPaper, Theme, Box} from '@material-ui/core';
import {AccessAlarm, Apps, Assessment, Assignment, Panorama, ViewAgenda} from '@material-ui/icons';
import styled, {withTheme} from 'styled-components';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import {BuildState, IBuild, isCompletedBuildState, isRunningBuildState} from '@common/api/models/builds/IBuild';

import {ActiveTabComponent} from './index';
import ViewportsPage, {LayerImages} from './activeBuildPages/ViewportsPage';
import DefectsPage from './activeBuildPages/DefectsPage';
import BuildSettings from '../BuildSettings';
import {GeneralInfo} from './activeBuildPages/GeneralInfo';
import LiveBuildSettingsButton from './shared/LiveBuildSettingsButton';
import OfflineDeviceAlert from '../../../components/molecules/OfflineDeviceAlert';
import {RootState} from '../../../store/reducers';
import {
  useBuildAttachmentStoreActions,
  useCalibrationStoreActions,
  useDeviceStoreActions,
  useLayerImageStoreActions,
} from '../../../store/actions';

import config from '../../../config/config';
import ErrorBoundary from '../../../utils/ErrorBoundary';
import {SkipAction, useSkipReducer, useSmallScreenSize} from '../../../utils/utilHooks';
import LazyLayerImage from '../../../components/atoms/LazyLayerImage';
//@ts-ignore
import layerImageWorker from './activeBuildPages/layerImage.worker';
import {GenerateLayerImagesResponse, getCalibrationData} from '../../../components/molecules/Viewport/2D/utils';
import BuildTabs from '../../../components/molecules/Tabs/BuildTabs';
import {ILayerImage, LayerImageResolutionSize} from '@common/api/models/builds/data/ILayerImage';
import envConfig from '../../../envConfig';
import ActiveBuildBottomToolbar from './activeBuildPages/ActiveBuildBottomToolbar';
import BuildStateButtons from './activeBuildPages/BuildStateButtons';
import {BuildAttachmentType} from '@common/api/models/attachments/IAttachmentBase';
import BuildPhotoAvatar from '../../../components/atoms/BuildPhotoAvatar';
import Header from '../../../components/organisms/Header';
import BuildHeader from '../shared/BuildHeader';
import {DeviceAlerts} from './shared/DeviceAlerts';
import {missingLayerNumbersGET, partialLayerNumbersGET} from '../../../api/ajax/layerImages';
import {maxBy} from 'lodash';
import {LiveBuildWebcamButton} from './activeBuildPages/LiveBuildWebcamButton';

const layerWorker = new layerImageWorker();

export enum ViewType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  GRID = 'grid',
}

const Paper = styled(MuiPaper)`
  background-color: ${(props) => `${props.theme.palette.primary.main}30`};
  padding: 0.25rem;
`;

export const Button = styled(MuiButton)`
  color: ${(props) => `${props.theme.palette.primary.main}75`};
  min-width: auto;
  padding: 5px;

  &:not(:last-child) {
    margin-right: 0.7rem;
  }

  &.active {
    background-color: ${(props) => `${props.theme.palette.primary.main}`};
    color: white;
  }

  &.rotated {
    transform: rotate(90deg);
  }

  &.four-square {
    .square-container {
      width: 20px;
      height: 20px;
      position: relative;
      overflow: hidden;

      .MuiSvgIcon-root {
        left: -6px;
        top: -6px;
        position: absolute;
        font-size: 43px;
      }
    }
  }
`;

export interface ActiveBuildProps {
  build: IBuild;
}

export interface ActiveBuildPage {
  id: string;
  title: string;
  smallTitle?: string;
  component: ActiveTabComponent;
  hidden?: boolean;
  completedOnly?: boolean; // only visible for completed builds?
  icon?: JSX.Element;
}

const defectsPageID = 'defects';
const viewportsPageID = 'viewports';

export const pagesRaw: ActiveBuildPage[] = [
  {
    id: 'general',
    title: 'General Info',
    smallTitle: 'General',
    component: GeneralInfo,
    icon: <Assignment />,
  },
  {
    id: viewportsPageID,
    title: 'Viewports',
    component: ViewportsPage,
    icon: <Panorama />,
  },
  ...(envConfig.hideDefectsPage
    ? []
    : [
        {
          id: defectsPageID,
          title: 'Defects',
          component: DefectsPage,
          icon: <Assessment />,
        },
      ]),
  {
    id: 'alerts',
    title: 'Device Alerts',
    smallTitle: 'Alerts',
    component: DeviceAlerts,
    icon: <AccessAlarm />,
  },
  {
    id: 'settings',
    title: 'Notification Settings',
    component: BuildSettings,
    hidden: true,
  },
];

export const filterBuildPages = (build: IBuild) => {
  return pagesRaw.filter((p) => !p.completedOnly || completedStates.includes(build.state));
};

const completedStates: BuildState[] = [BuildState.FAILED, BuildState.COMPLETED, BuildState.SUCCEEDED];

function ActiveBuild(props: ActiveBuildProps & {theme: Theme}) {
  const isSmallScreen = useSmallScreenSize();

  const [layerImageSkip, updateLayerImageSkip] = useSkipReducer(config.paginationStepSize.layerImage);

  const {uuid, liveStep} = useParams<{uuid: string; liveStep: string}>();

  const [viewType, setViewType] = useState<ViewType>(ViewType.GRID); // INFO: this does not work well on mobile

  const [layerImages, setLayerImages] = useState<LayerImages>({});
  const [cmLoadedImages, setCmLoadedImage] = useState<{}>({});
  const [layerCalls, setLayerCalls] = useState<number[]>([]);
  const [partialLayerNumbers, setPartialLayerNumbers] = useState<Set<number>>(new Set());
  const [missingLayerNumbers, setMissingLayerNumbers] = useState<Set<number>>(new Set());

  /* Layer Image Store */
  const layerImageActions = useLayerImageStoreActions();
  const layerImageStore = useSelector((state: RootState) => state.layerImageStore);

  /* Calibration Store */
  const calibrationActions = useCalibrationStoreActions();
  const calibrationStore = useSelector((state: RootState) => state.calibrationStore);

  /* Device Store */
  const deviceActions = useDeviceStoreActions();

  /* Build Attachment Store */
  const buildAttachmentStoreActions = useBuildAttachmentStoreActions();
  const buildPhotoMain = useSelector((s: RootState) =>
    Object.values(s.buildAttachmentStore.byId).find(
      (attachment) =>
        attachment.buildUuid === props.build.uuid && attachment.type === BuildAttachmentType.BuildPhotoMain
    )
  );

  useEffect(() => {
    props.build.calibrationUuid &&
      calibrationActions.ensureConsistent({
        uuid: props.build.calibrationUuid,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.calibrationUuid]);

  const {calibrationScale} = useMemo(
    () => getCalibrationData(calibrationStore, props.build.calibrationUuid),
    [calibrationStore, props.build.calibrationUuid]
  );

  useEffect(() => {
    layerWorker.onmessage = (event: MessageEvent) => {
      setLayerImages((previousLayerImages) => {
        const response = event.data.res as GenerateLayerImagesResponse;
        Object.entries(response).forEach(([layerId, data]) => {
          const layerNum = Number(layerId);
          Object.keys(data['birdsEye']).forEach((layerType: string) => {
            Object.keys(data['birdsEye'][layerType].images).forEach((imgId) => {
              const imgNum = Number(imgId);
              const {uuid, width, height} = data['birdsEye'][layerType].images[imgNum].imageSize;
              // if an existing LazyLayerImage exists, use that instead of recreating it.
              const existingLazyLayer =
                previousLayerImages[layerNum]?.['birdsEye']?.[layerType]?.images?.[imgNum]?.['image'];

              // TODO why is this done here?
              response[layerNum]['birdsEye'][layerType].images[imgNum]['image'] =
                existingLazyLayer || new LazyLayerImage(uuid, width, height);
            });
          });
        });

        return response as LayerImages;
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateLayerImageSkip(SkipAction.Reset);
  }, [props.build.uuid, updateLayerImageSkip]);

  useEffect(() => {
    buildAttachmentStoreActions.ensureConsistent({
      buildUuid: props.build.uuid,
      type: BuildAttachmentType.BuildPhotoMain,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid, props.build.state]);

  useEffect(() => {
    const storedLayerImages: ILayerImage[] = Object.values(layerImageStore.byId).filter(
      (layer) => layer.buildUuid === props.build.uuid
    );
    // generate layer images on the first call and once last response comes back
    if (
      !layerCalls.includes(storedLayerImages.length) &&
      //first call
      ((layerImageSkip === 0 && storedLayerImages.length > 0) ||
        storedLayerImages.length === config.paginationStepSize.layerImage ||
        (layerImageStore.total &&
          // assuming there could be 500 missing image in last response
          storedLayerImages.length >= layerImageStore.total - 500))
    ) {
      setLayerCalls([storedLayerImages.length, ...layerCalls]);
      layerWorker.postMessage({layerImages: storedLayerImages});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerImageStore.byId]);

  useEffect(() => {
    if (liveStep === defectsPageID) {
      const storedLayerImages: ILayerImage[] = Object.values(layerImageStore.byId);

      // check if we need to fetch more
      const viewableImages = storedLayerImages.filter((layerImage) => layerImage.type !== 'layerMask');

      if (layerImageSkip < props.build.numLayers) {
        if (layerImageSkip === 0 || viewableImages.length === 0) {
          layerImageActions.ensureConsistent(
            {
              buildUuid: props.build.uuid,
              layerTake: config.paginationStepSize.layerImage,
              layerSkip: layerImageSkip,
            },
            {buildUuid: props.build.uuid}
          );
        }
        updateLayerImageSkip(SkipAction.Bump);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerImageStore.byId, liveStep]);

  useEffect(() => {
    if (props.build.deviceSerial) {
      deviceActions.ensureConsistent({serial: props.build.deviceSerial});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.deviceSerial]);

  function fetchLayerData(layerId: number) {
    layerImageActions.ensureConsistent(
      {
        buildUuid: props.build.uuid,
        layerId: layerId,
      },
      {buildUuid: props.build.uuid}
    );
  }

  const totalLayers = useMemo(() => {
    let highestLayer = props.build.lastPrintedLayerId || 0;

    const objects = Object.values(layerImageStore.byId).filter(
      (li) =>
        li.buildUuid === props.build.uuid &&
        li.type !== 'layerMask' &&
        li.resolutionSize !== LayerImageResolutionSize.BIT_12
    );
    return Math.max(highestLayer, maxBy(objects, 'layerId')?.layerId || highestLayer);
  }, [layerImageStore.byId, props.build.lastPrintedLayerId, props.build.uuid]);

  const updatePartialLayers = useCallback(async () => {
    const res = await partialLayerNumbersGET(props.build.uuid);
    if (res.success) {
      setPartialLayerNumbers(new Set(res.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid, totalLayers]);

  const updateMissingLayers = useCallback(async () => {
    const res = await missingLayerNumbersGET(props.build.uuid);
    if (res.success) {
      setMissingLayerNumbers(new Set(res.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid, totalLayers]);

  useEffect(() => {
    // Update partial and missing layers when we get a new layer, or the build has changed
    updatePartialLayers();
    updateMissingLayers();
  }, [updatePartialLayers, updateMissingLayers]);

  useEffect(() => {
    // LayerImages are kept upto date with websocket updates, so we can also populate partialLayerNumbers from the store which may be more up-to date.
    const partialLayerStored = new Set(
      Object.values(layerImageStore.byId)
        .filter((layer) => layer.buildUuid === props.build.uuid && layer.isPartialLayer)
        .map((layer) => layer.layerId)
    );
    setPartialLayerNumbers(new Set([...partialLayerStored, ...partialLayerNumbers]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerImageStore.byId, props.build.uuid]);

  const pages = filterBuildPages(props.build);

  const activePageIndex = pages.findIndex((p) => p.id === liveStep);
  const activePage = pages[activePageIndex];

  const buildIsCompleted = isCompletedBuildState(props.build.state);

  const hasLayers = !!props.build.lastPrintedLayerId;

  if (!activePage) {
    // Viewports is full screen on small devices and blocks useful information, hence navigate to the general page.
    if (isSmallScreen) {
      return <Redirect to={'/builds/uuid/' + uuid + '/live/general/'} />;
    } else {
      return <Redirect to={'/builds/uuid/' + uuid + '/live/viewports/'} />;
    }
  }

  return (
    <React.Fragment>
      <Header
        helmet={`${
          isRunningBuildState(props.build.state)
            ? 'Live'
            : props.build.state === BuildState.FAILED
            ? 'Failed'
            : 'Completed'
        } Build - ${props.build.name}`}
        title={<BuildHeader build={props.build} />}
        breadcrumbs={[
          {title: 'Builds', path: '/builds'},
          {title: props.build.name, path: `/builds/uuid/${uuid}`},
          activePage.title,
        ]}
        startAdornment={
          <BuildPhotoAvatar
            signedUrl={buildPhotoMain?.signedUrl}
            pxSize={isSmallScreen ? 26 : 38}
            imgStyle={{marginRight: '12px'}}
          />
        }
        startAdornmentWidth={buildPhotoMain?.signedUrl ? (isSmallScreen ? '38px' : '50px') : '0px'}
        endAdornment={
          isSmallScreen ? undefined : (
            <Grid container direction="row" spacing={2} wrap="nowrap">
              {!isCompletedBuildState(props.build.state) && (
                <LiveBuildWebcamButton build={props.build} isSmallScreen={false} />
              )}
              <BuildStateButtons build={props.build} hasLayers={hasLayers} buildIsCompleted={buildIsCompleted} />
              <Grid item>
                <LiveBuildSettingsButton buildUuid={props.build.uuid} />
              </Grid>
            </Grid>
          )
        }
      />

      {isSmallScreen && (
        <>
          <BuildStateButtons build={props.build} hasLayers={hasLayers} buildIsCompleted={buildIsCompleted} />
          {!isCompletedBuildState(props.build.state) && (
            <Box mb={2}>
              <LiveBuildWebcamButton build={props.build} isSmallScreen />
            </Box>
          )}
        </>
      )}

      <DeviceOfflineAlert build={props.build} />

      {isSmallScreen && <ActiveBuildBottomToolbar pages={pages} />}
      <Grid container justifyContent="space-between" style={{marginBottom: '0'}}>
        {!isSmallScreen && (
          <Grid item>
            <BuildTabs pages={pages} activeBuildProps={props} />
          </Grid>
        )}

        {/* Only show view type selector in defects tab */}
        {liveStep === defectsPageID && !isSmallScreen && (
          <Grid item>
            <Paper style={{display: 'flex', flexWrap: 'nowrap'}} elevation={0}>
              <Button
                className={viewType === ViewType.HORIZONTAL ? 'active' : ''}
                onClick={() => setViewType(ViewType.HORIZONTAL)}
              >
                <ViewAgenda fontSize="small" />
              </Button>
              <Button
                className={`rotated ${viewType === ViewType.VERTICAL ? 'active' : ''}`}
                onClick={() => setViewType(ViewType.VERTICAL)}
              >
                <ViewAgenda fontSize="small" />
              </Button>

              <Button
                className={`four-square ${viewType === ViewType.GRID ? 'active' : ''}`}
                onClick={() => setViewType(ViewType.GRID)}
              >
                <div className="square-container">
                  <Apps />
                </div>
              </Button>
            </Paper>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ErrorBoundary>
            {
              <activePage.component
                build={props.build}
                layerImages={layerImages}
                totalLayers={totalLayers}
                partialLayerNumbers={partialLayerNumbers}
                missingLayerNumbers={missingLayerNumbers}
                calibrationScale={calibrationScale}
                fetchLayerData={fetchLayerData}
                cmLoadedImages={cmLoadedImages}
                setCmLoadedImage={setCmLoadedImage}
                viewType={isSmallScreen ? ViewType.HORIZONTAL : viewType}
              />
            }
          </ErrorBoundary>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(ActiveBuild);

const DeviceOfflineAlert = ({build}: {build: IBuild}) => {
  const isSmallScreen = useSmallScreenSize();
  const device = useSelector((state: RootState) => state.deviceStore.byId[build.deviceSerial!]);

  if (isRunningBuildState(build.state) && device && !device.online)
    return (
      <OfflineDeviceAlert lastActivityTime={device.lastActivityTime} mb={isSmallScreen ? 3 : 0}>
        No layers, defects or alerts will be received and the build cannot be paused or completed until the device comes
        online.
      </OfflineDeviceAlert>
    );

  return <></>;
};
