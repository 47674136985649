import React from 'react';
import {ButtonGroup} from '@material-ui/core';
import {Warning} from '@material-ui/icons';
import {toast} from 'react-toastify';
import {deviceStatePUT} from '../../../api/ajax/devices';
import {DialogButton} from '../DialogButton';

export function ManualDeviceStateButtons(props: {deviceSerial: string; disabled?: boolean}) {
  const handleStateUpdate = (value: string) => {
    deviceStatePUT(props.deviceSerial, value).then(() => {
      toast('Sent device command!', {type: 'info'});
    });
  };

  return (
    <ButtonGroup orientation="horizontal" disabled={props.disabled}>
      <DialogButton
        dialog={{
          title: 'Restart device software',
          content: `Are you sure you wish to restart the device's software?`,
          icon: <Warning />,
        }}
        handleConfirm={handleStateUpdate.bind(null, 'restart_soft')}
        text="Restart software"
        size="small"
      />
      <DialogButton
        dialog={{
          title: 'Restart device',
          content: `Are you sure you wish to restart the device?`,
          icon: <Warning />,
        }}
        handleConfirm={handleStateUpdate.bind(null, 'restart_hard')}
        text="Restart"
        size="small"
      />
      <DialogButton
        dialog={{
          title: 'Shutdown device',
          content: `Are you sure you wish to shutdown the device? You will need to physically power on the device to regain access.`,
          icon: <Warning />,
        }}
        danger={true}
        handleConfirm={handleStateUpdate.bind(null, 'shutdown')}
        text="Shutdown"
        size="small"
      />
    </ButtonGroup>
  );
}
