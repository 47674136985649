import {Button} from '@material-ui/core';
import * as React from 'react';
import {useState} from 'react';
import {IBatch} from '@common/api/models/materials/batches/IBatch';
import BatchSelectorModal from './BatchSelectorModal';

export interface BatchSelectorButtonProps {
  selectedBatch?: IBatch;
  placeholder: string;
  onSelect: (batch: IBatch | undefined) => any;
  disabled?: string[];
  hideSelectedBatch?: boolean;
}

export function BatchSelectorButton(props: BatchSelectorButtonProps) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <React.Fragment>
      <Button color="primary" variant="outlined" onClick={() => setModalOpen(true)}>
        {props.selectedBatch ? (
          props.hideSelectedBatch ? (
            'Change Batch'
          ) : (
            props.selectedBatch.name
          )
        ) : (
          <i>{props.placeholder}</i>
        )}
      </Button>

      <BatchSelectorModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        initiallySelectedUuid={props.selectedBatch?.uuid}
        onBatchSelected={(b) => {
          props.onSelect(b);
          setModalOpen(false);
        }}
        disabled={props.disabled}
      />
    </React.Fragment>
  );
}
