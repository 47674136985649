import React, {useState} from 'react';
import {Grid, Typography as MuiTypography} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {IBuild} from '@common/api/models/builds/IBuild';
import {RootState} from '../../../../store/reducers';
import {StateInput} from '../../../index';
import SliceAttachmentMultipartUpload from '../../shared/SliceAttachmentMultipartUpload';
import {useSmallScreenSize} from '../../../../utils/utilHooks';

import {UploadedBuildAttachmentsTable, UploadedSliceFileAttachmentsTable} from '../../shared/SliceFilesUploadModal';

export interface BuildConfigurationProps extends StateInput<IBuild> {
  isStaging?: boolean;
}
const Typography = styled(MuiTypography)(spacing);

const UploadStep = ({state: build, isStaging = false}: BuildConfigurationProps) => {
  const [numFilesUploading, setNumFilesUploading] = useState(0);
  const isSmallScreen = useSmallScreenSize();
  const currentUser = useSelector((state: RootState) => state.auth.user);

  return (
    <div>
      <Grid container spacing={isSmallScreen ? 3 : 6} justifyContent="space-between">
        {!isStaging && (
          <Grid item xs={12}>
            <Typography variant={isSmallScreen ? 'h6' : 'h4'}>Step 4: Upload</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <SliceAttachmentMultipartUpload
            buildUuid={build.uuid}
            orgUuid={currentUser!.organizationUuid}
            setNumFilesUploading={setNumFilesUploading}
            numFilesUploading={numFilesUploading}
          />
        </Grid>

        <UploadedSliceFileAttachmentsTable buildUuid={build.uuid} />
        <UploadedBuildAttachmentsTable buildUuid={build.uuid} />
      </Grid>
    </div>
  );
};

export default UploadStep;
