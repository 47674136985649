import {subscribeToLiveResource} from '../../api/websocket/liveResource';

import {RootState} from '../reducers';
import {assertUnreachable} from '@common/utils/utils';
import {MessageType, Resource} from '@common/api/websocket/resourceMessage';
import {
  ApiResponsePayload,
  FetchHistory,
  LiveStoreActions,
  LiveStoreDeleteAction,
  LiveStoreFetchErrorAction,
  LiveStoreFinishFetchAction,
  LiveStoreInvalidateAction,
  LiveStoreLogLastWsConnAction,
  LiveStoreLogLastWsFilterAction,
  LiveStoreStartFetchAction,
  LiveStoreStartFetchActionFilter,
  LiveStoreState,
  LiveStoreUpdateAction,
} from '../model/liveUpdateStore';

import {identity, isEqual, pickBy, flatten, isString} from 'lodash';
import {AjaxApiResult} from '../../api/ajax/ajaxWrapper';
import {Pagination} from '@common/api/models/common';
import {ApiFailureResponse, ApiSuccessResponse} from '@common/api/apiResult';
import {AvailableComparisons} from '@common/api/apiRequest';
import {OrderDirection} from '@common/utils/ordering/ordering';

type PrimitiveOrArray = string | number | boolean | string[] | number[] | boolean[];

export type ObjectFilter<T> = {
  [key in keyof T]?:
    | PrimitiveOrArray
    | {
        [key in AvailableComparisons]?: PrimitiveOrArray;
      };
};

export type LayerPagination = {
  layerTake: number;
  layerSkip: number;
};

export type Ordering = {
  order: string;
};

export type SortBy<T> = {
  sortBy: {[key in keyof T]?: OrderDirection};
};

export type QueryFilter<T> = ObjectFilter<T> &
  Partial<Pagination> &
  (SortBy<T> | {}) &
  (LayerPagination | {}) &
  (Ordering | {}) &
  Partial<SortBy<T>>;

export type WSQueryFilter<T> = ObjectFilter<T>;

async function fetchData<T>(
  filters: QueryFilter<T>,
  fetchHistory: FetchHistory<T>,
  dispatch: Function,
  fetchFn: (filters: QueryFilter<T>) => AjaxApiResult<T[]>,
  startFetch: (filters: QueryFilter<T>, setLoading?: boolean) => void,
  errorFetch: (message: string) => void,
  finishFetch: (response: ApiResponsePayload<T>, filters: QueryFilter<T>) => void,
  ignorePrevious?: boolean
) {
  let response;

  const matchingExistingFilterList = fetchHistory.find((history) => isEqual(history.filters, filters));

  const lastFilterIsMatching = fetchHistory.length
    ? isEqual(fetchHistory[fetchHistory.length - 1].filters, filters)
    : false;

  // Last filter was exactly the same as this one - no need to refresh data or WS conn
  if (lastFilterIsMatching && !ignorePrevious) return;

  // If a matching filter exists, we don't need to trigger a loading state.
  // We can render the data, but need to still make a fetch request as the data may be stale.
  if (matchingExistingFilterList) {
    dispatch(startFetch(filters, false));
  } else {
    dispatch(startFetch(filters, true));
  }
  response = await fetchFn(filters);

  // Don't open a new WS connection if error
  if (!response.success) {
    response = response as ApiFailureResponse;
    const message = isString(response.message) ? response.message : JSON.stringify(response.message);

    dispatch(errorFetch(message));
    return;
  }

  response = response as ApiSuccessResponse<T[]>;

  if (!response.data) {
    dispatch(errorFetch('Failed to retrieve data'));
    return;
  }

  dispatch(finishFetch(response as ApiResponsePayload<T>, filters));

  return response;
}

/**
 * Returns actions for managing a live-updated store. Should be only be created by `useLiveStoreActions`.
 * @param resourceType Resource identifier
 * @param url WS endpoint URL
 * @param fetchFn Callable fn to first populate store
 * @param selectFn Callable fn to return LiveStoreState from RootState
 */
export function createLiveStoreActions<T, Y extends LiveStoreState<T>>(
  resourceType: Resource,
  wsUrl: string | null,
  fetchFn: (filter: QueryFilter<T>) => AjaxApiResult<T[]>,
  selectFn: (state: RootState) => Y
) {
  const actionSuffix: string = Resource[resourceType];
  return {
    /**** ENSURE CONSISTENT ACTIONS ****/
    update(object: T): () => LiveStoreUpdateAction<T> {
      return () => ({
        type: LiveStoreActions.UPDATE + actionSuffix,
        payload: object,
      });
    },

    delete(object: T): () => LiveStoreDeleteAction<T> {
      return () => ({
        type: LiveStoreActions.DELETE + actionSuffix,
        payload: object,
      });
    },

    invalidate(error?: string): () => LiveStoreInvalidateAction<T> {
      return () => ({
        type: LiveStoreActions.INVALIDATE + actionSuffix,
        payload: error,
      });
    },

    invalidateFetchHistory() {
      return async (dispatch: Function) => {
        dispatch({
          type: LiveStoreActions.INVALIDATE_FETCH_HISTORY + actionSuffix,
          payload: null,
        });
      };
    },

    _finishFetch(response: ApiResponsePayload<T>, filters: QueryFilter<T>): () => LiveStoreFinishFetchAction<T> {
      return () => ({
        type: LiveStoreActions.FINISH_FETCH + actionSuffix,
        payload: {...response, filters},
      });
    },

    _errorFetch(message: string): () => LiveStoreFetchErrorAction<T> {
      return () => ({
        type: LiveStoreActions.FETCH_ERROR + actionSuffix,
        payload: message,
      });
    },

    _startFetch(filter: QueryFilter<T>, setLoading: boolean = true): () => LiveStoreStartFetchActionFilter<T> {
      return () => ({
        type: LiveStoreActions.START_FETCH + actionSuffix,
        payload: {filter, setLoading},
      });
    },

    _logLastWsFilter(wsFilter?: ObjectFilter<T>): () => LiveStoreLogLastWsFilterAction<T> {
      return () => ({
        type: LiveStoreActions.LOG_LAST_WS_FILTER + actionSuffix,
        payload: wsFilter,
      });
    },

    _logLastWsConn(conn: WebSocket | null): () => LiveStoreLogLastWsConnAction<T> {
      return () => ({
        type: LiveStoreActions.LOG_LAST_WS_CONN + actionSuffix,
        payload: conn,
      });
    },

    /**
     * Populates store & establishes WS for live-store updates
     * @param filters
     * @param wsFilter
     */
    ensureConsistent(filters: QueryFilter<T>, wsFilter?: WSQueryFilter<T>, ignorePrevious: boolean = false) {
      return async (dispatch: Function, getState: () => RootState) => {
        const state = getState();
        const store: Y = selectFn(state);
        const filter = pickBy(filters, identity) as QueryFilter<T>;

        const response = await fetchData(
          filter,
          store.fetchHistory,
          dispatch,
          fetchFn,
          this._startFetch,
          this._errorFetch,
          this._finishFetch,
          ignorePrevious
        );

        if (!response) return;
        if (!wsUrl) return;

        if (!!store.lastWsConn && !!store.lastWsConn.close) {
          store.lastWsConn.close();
        }

        // Establish new WS connection if wsUrl provided
        const conn = await subscribeToLiveResource<T>(
          wsUrl,
          (msg) => {
            if (msg.resource === resourceType) {
              switch (msg.type) {
                case MessageType.UPDATE:
                case MessageType.INSERT:
                  dispatch(this.update(msg.payload as any));
                  break;
                case MessageType.DELETE:
                  dispatch(this.delete(msg.payload as any));
                  break;
                default:
                  assertUnreachable(msg);
              }
            }
          },
          () => {},
          (_msg: string) => {
            if (conn && conn.close) {
              conn.close();
            }
          },
          wsFilter || filters
        );

        // Store last WS connection for later
        dispatch(this._logLastWsConn(conn));
      };
    },

    /**** FETCH AND SUBSCRIBE TO LIST ACTIONS****/
    updateInList(object: T): () => LiveStoreUpdateAction<T> {
      return () => ({
        type: LiveStoreActions.UPDATE_LIST + actionSuffix,
        payload: object,
      });
    },

    insertInList(object: T): () => LiveStoreUpdateAction<T> {
      return () => ({
        type: LiveStoreActions.INSERT_LIST + actionSuffix,
        payload: object,
      });
    },

    deleteInList(object: T): () => LiveStoreDeleteAction<T> {
      return () => ({
        type: LiveStoreActions.DELETE_LIST + actionSuffix,
        payload: object,
      });
    },

    _logLastWsListConn(conn: WebSocket | null): () => LiveStoreLogLastWsConnAction<T> {
      return () => ({
        type: LiveStoreActions.LOG_LAST_WS_LIST_CONN + actionSuffix,
        payload: conn,
      });
    },

    _finishListFetch(response: ApiResponsePayload<T>, filters: QueryFilter<T>): () => LiveStoreFinishFetchAction<T> {
      return () => ({
        type: LiveStoreActions.FINISH_LIST_FETCH + actionSuffix,
        payload: {...response, filters},
      });
    },

    _errorListFetch(message: string): () => LiveStoreFetchErrorAction<T> {
      return () => ({
        type: LiveStoreActions.FETCH_LIST_ERROR + actionSuffix,
        payload: message,
      });
    },

    _startListFetch(): () => LiveStoreStartFetchAction<T> {
      return () => ({
        type: LiveStoreActions.START_LIST_FETCH + actionSuffix,
        payload: {},
      });
    },

    unsubscribeFromList() {
      return async (dispatch: Function) => {
        dispatch({
          type: LiveStoreActions.UNSUBSCRIBE_FROM_LIST + actionSuffix,
          payload: null,
        });
      };
    },

    invalidateListHistory() {
      return async (dispatch: Function) => {
        dispatch({
          type: LiveStoreActions.INVALIDATE_LIST_HISTORY + actionSuffix,
          payload: null,
        });
      };
    },

    fetchAndSubscribeToList(filters: QueryFilter<T>, wsFilters?: WSQueryFilter<T>, options?: {unpaginated?: boolean}) {
      return async (dispatch: Function, getState: () => RootState) => {
        const state = getState();
        const store: Y = selectFn(state);
        const filter = pickBy(filters, identity) as QueryFilter<T>;

        if (filters.take) {
          if (filters.take > 100) filters.take = 100;
        } else if (!options?.unpaginated) {
          filters.take = 10;
        }

        const response = await fetchData(
          filter,
          store.listFetchHistory,
          dispatch,
          fetchFn,
          this._startListFetch,
          this._errorListFetch,
          this._finishListFetch
        );

        if (!response) return;
        if (!wsUrl) return;

        if (!!store.lastListWsConn && !!store.lastListWsConn.close) {
          store.lastListWsConn.close();
        }

        // Create WS subscription to the items in the list by filtering by uuid
        const conn = await subscribeToLiveResource<T>(
          wsUrl,
          (msg) => {
            if (msg.resource === resourceType) {
              switch (msg.type) {
                case MessageType.UPDATE:
                  dispatch(this.updateInList(msg.payload as any));
                  break;
                case MessageType.INSERT:
                  dispatch(this.insertInList(msg.payload as any));
                  break;
                case MessageType.DELETE:
                  dispatch(this.deleteInList(msg.payload as any));
                  break;
                default:
                  assertUnreachable(msg);
              }
            }
          },
          () => {},
          (_msg: string) => {
            if (conn && conn.close) {
              conn.close();
            }
          },
          wsFilters ||
            ({
              uuid: flatten([response.data]).map((resource: any) => resource.uuid),
            } as WSQueryFilter<T>)
        );

        // Store last WS connection so we can close later
        dispatch(this._logLastWsListConn(conn));
      };
    },
  };
}
