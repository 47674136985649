import React from 'react';
import {useSelector} from 'react-redux';
import {Typography, Box} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {RootState} from '../../../../store/reducers/index';
import {FetchingState} from '../../../../store/model/liveUpdateStore';

export function FieldValue({
  field,
  value,
  marginTop = '4px',
}: {
  field: string;
  value?: string | React.ReactNode;
  marginTop?: string;
}) {
  return (
    <Box display="flex" marginTop={marginTop}>
      <Typography style={{width: '120px'}}>
        <b>{field}</b>
      </Typography>
      {value && <Typography>{value}</Typography>}
    </Box>
  );
}

export function MachineField({machineUuid}: {machineUuid: string}) {
  const machine = useSelector((state: RootState) =>
    Object.values(state.machineStore.byId).find((machine) => machineUuid === machine.uuid)
  );
  const machinesFetching = useSelector((state: RootState) => state.machineStore.fetched === FetchingState.Fetching);

  return (
    <FieldValue
      marginTop="16px"
      field="Machine"
      value={machinesFetching ? <Skeleton style={{width: '200px'}} /> : machine?.name || 'N/A'}
    />
  );
}

export function BatchField({batchUuid}: {batchUuid: string}) {
  const batch = useSelector((state: RootState) =>
    Object.values(state.batchStore.byId).find((batch) => batchUuid === batch.uuid)
  );
  const batchesFetching = useSelector((state: RootState) => state.batchStore.fetched === FetchingState.Fetching);

  return (
    <FieldValue field="Batch" value={batchesFetching ? <Skeleton style={{width: '200px'}} /> : batch?.name || 'N/A'} />
  );
}
