import React, {Component, ErrorInfo, ReactNode} from 'react';
import styled from 'styled-components';
import {Box, IconButton, Typography} from '@material-ui/core';
import aa_logo from '../assets/img/logo.png';
import {Alert, AlertTitle} from '@material-ui/lab';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
  expanded: boolean;
}

const BrandIcon = styled.div`
  background-image: url(${aa_logo});
  width: 96px;
  height: 96px;
  background-size: contain;
  margin-top: 64px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
`;

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
    expanded: false,
  };

  static getDerivedStateFromError = (_: Error) => {
    return {hasError: true};
  };

  componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  };

  setExpanded(expanded: boolean) {
    this.setState({...this.state, expanded});
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <BrandIcon />
          <Typography variant="h2" style={{padding: '24px 0px'}}>
            Oops, something went wrong.
          </Typography>
          <Typography variant="subtitle1">
            Please refresh the page. If the error persists, please contact support.
          </Typography>
          <Alert
            severity="warning"
            style={{
              width: '100%',
              maxWidth: this.state.expanded ? '100%' : '450px',
              marginTop: '48px',
            }}
            action={
              <IconButton size="small" onClick={() => this.setExpanded(!this.state.expanded)} aria-label="expand">
                {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            }
          >
            {this.state.expanded ? (
              <>
                <AlertTitle>{this.state.error?.toString()}</AlertTitle>
                <p style={{whiteSpace: 'pre-wrap'}}>{this.state.errorInfo?.componentStack}</p>
              </>
            ) : (
              <>{this.state.error?.toString()}</>
            )}
          </Alert>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
