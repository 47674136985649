import {generateValidationResult} from '@common/api/types';
import {DefectType, getPrettyDefectType} from './IDefect';

export enum Units {
  MM = 'mm',
  PERCENT = '%',
}

export type DefectNotificationSortOptions =
  | 'defectType'
  | 'defectAreaThreshold'
  | 'defectCoverageThreshold'
  | 'consecutiveLayers';

export enum DefectNotificationType {
  BUILD = 'build',
  PART = 'part',
}

export enum LogicalOperator {
  OR = 'or',
  AND = 'and',
}

export enum FilterType {
  INDIVIDUAL = 'individual',
  ACCUMULATED = 'accumulated',
}

export interface IDefectNotification {
  uuid: string;
  buildUuid: string;
  conditions: IDefectNotificationConditions;
  triggers: NotificationTriggers;
}

export type StartEndLayer = [number, number];
export type NotificationTriggers = {[buildOrPartUuid: string]: {inAlarm: boolean; startEndLayer: Array<StartEndLayer>}};

// SEE "EXAMPLE_CONDITION" IN dummyDefectNotifications.ts for an example condition structure

export interface IDefectNotificationConditions {
  notificationType: DefectNotificationType;
  consecutiveLayers: number;
  operator: LogicalOperator;
  conditionGroups: Array<ConditionGroup>;
}

export interface ConditionGroup {
  operator: LogicalOperator;
  conditions: Array<SingleCondition>;
}

export interface SingleCondition {
  filterType: FilterType;
  defectType: DefectType;
  unit: Units;
  threshold: number;
  triggers: ConditionTriggers;
}

export type ConditionResult = {averageValue: number; startEndLayer: StartEndLayer};
export type ConditionTriggers = {[buildOrPartUuid: string]: Array<ConditionResult>};

export const DEFAULT_SINGLE_CONDITION: SingleCondition = {
  filterType: undefined,
  defectType: undefined,
  unit: undefined,
  threshold: undefined,
  triggers: {},
};

export const DEFAULT_CONDITION_GROUP: ConditionGroup = {
  operator: LogicalOperator.AND,
  conditions: [DEFAULT_SINGLE_CONDITION],
};

export const DEFAULT_NOTIFICATION_CONDITION: IDefectNotificationConditions = {
  notificationType: undefined,
  consecutiveLayers: 1,
  operator: LogicalOperator.AND,
  conditionGroups: [DEFAULT_CONDITION_GROUP],
};

export function validateDefectNotificationConditions(conditions: IDefectNotificationConditions) {
  const errors: string[] = [];

  // Validate top level conditions
  if (!Object.values(DefectNotificationType).includes(conditions.notificationType)) {
    errors.push(`Notification type must be one of ${Object.values(DefectNotificationType).join(', ')}`);
  }

  if (!conditions.consecutiveLayers || conditions.consecutiveLayers < 1) {
    errors.push('Consecutive layers must be a positive number');
  }

  if (!Object.values(LogicalOperator).includes(conditions.operator)) {
    errors.push(`Logical operator must be one of ${Object.values(LogicalOperator).join(', ')}`);
  }

  if (conditions.conditionGroups.length < 1) {
    errors.push("Condition groups can't be empty");
  }

  // Validate each condition group
  conditions.conditionGroups.forEach((conditionGroup) => {
    if (conditionGroup.conditions.length < 1) {
      errors.push(`Condition groups can't be empty`);
      return;
    }

    if (!Object.values(LogicalOperator).includes(conditionGroup.operator)) {
      errors.push(`Logical operators must be one of ${Object.values(LogicalOperator).join(', ')}`);
    }

    // Validate each condition
    conditionGroup.conditions.forEach((condition) => {
      if (!Object.values(FilterType).includes(condition.filterType)) {
        errors.push(`Filter type  must be one of ${Object.values(FilterType).join(', ')}`);
      }

      if (!Object.values(DefectType).includes(condition.defectType)) {
        errors.push(`Defect type must be one of ${Object.values(DefectType).join(', ')}`);
      }

      if (!Object.values(Units).includes(condition.unit)) {
        errors.push(`Can only select defect area or defect coverage`);
      }

      if (!condition.threshold || condition.threshold < 0.01) {
        errors.push(`Threshold must be a number greater than 0.01`);
      }
    });
  });

  const success = errors.length === 0;
  return generateValidationResult(success, errors);
}

export function defectNotificationToString(defectNotification: IDefectNotification) {
  const conditions = defectNotification.conditions;

  const strings: string[] = [];

  strings.push(
    `Notify me, when ${
      conditions.notificationType === DefectNotificationType.BUILD ? 'the build' : 'a part'
    } meets for following conditions for ${conditions.consecutiveLayers} consecutive layer(s):`
  );

  if (!conditions || !conditions.conditionGroups) {
    return strings;
  }

  strings.push(''); // Add empty line for better representation in the UI
  conditions.conditionGroups.forEach((conditionGroup, index) => {
    const conditionGroupStrings = conditionGroup.conditions.map((condition) => {
      const layerString = conditions.consecutiveLayers > 1 ? 'Each layer' : 'A layer';
      const filterString = condition.filterType === 'individual' ? 'an occurrence' : 'an accumulation';
      const prettyDefectType = getPrettyDefectType(condition.defectType);
      const prettyUnits = condition.unit === Units.MM ? 'defect area' : 'defect coverage';
      const threshold = condition.threshold.toLocaleString(undefined, {maximumFractionDigits: 2});
      const prettyThreshold = condition.unit === Units.MM ? `${threshold}mm²` : `${threshold}%`;

      return `${layerString} has ${filterString} of ${prettyDefectType} with a ${prettyUnits} over ${prettyThreshold}`;
    });

    strings.push(conditionGroupStrings.join(` ${conditionGroup.operator.toLocaleUpperCase()} `));

    if (index !== conditions.conditionGroups.length - 1) {
      strings.push(conditions.operator.toLocaleUpperCase());
    }
  });

  return strings;
}

// Notify me when a part meets the following conditions for 4 consecutive layers:
// A layer has a single defect of serious spatter with a defect area (mm^2) over 9000 and a layer has a total of severe defect with a defect coverage (%) over 50
