import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Box, LinearProgress, Tooltip, Typography} from '@material-ui/core';
import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import {useSmallScreenSize} from '../../../utils/utilHooks';

const PROGRESS_REFRESH_INTERVAL_MS = 15_000;

const ResultsProgress = ({marginBottom}: {marginBottom?: string}) => {
  const isSmallScreen = useSmallScreenSize();
  const reportUuid = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport?.uuid);
  const reportStatus = useSelector((state: RootState) => state.similarityReportStore.currentSimilarityReport?.status);
  const comparisonProgress = useSelector((state: RootState) => state.similarityReportStore.comparisonProgress);

  const {fetchReportProgress} = useSimilarityReportStoreActions();

  const reportIsGenerating =
    reportStatus === SimilarityStatus.Generating || reportStatus === SimilarityStatus.Submitted;

  useEffect(() => {
    let interval: null | ReturnType<typeof setInterval> = null;

    if (reportUuid && reportIsGenerating) {
      fetchReportProgress(reportUuid);

      interval = setInterval(() => {
        fetchReportProgress(reportUuid);
      }, PROGRESS_REFRESH_INTERVAL_MS);
    }

    return () => clearInterval(interval!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportUuid, reportStatus]);

  if (!reportIsGenerating || !comparisonProgress) return <></>;

  const total =
    comparisonProgress.success.length +
    comparisonProgress.failure.length +
    comparisonProgress.generating.length +
    comparisonProgress.submitted.length;
  const completed = comparisonProgress.success.length;
  const failed = comparisonProgress.failure.length;
  const submitted = comparisonProgress.submitted.length;
  const generating = comparisonProgress.generating.length;

  const percentComplete = ((completed + failed) / total) * 100;

  const statusString = [
    [submitted, 'Submitted'],
    [generating, 'Generating'],
    [completed, 'Completed'],
    [failed, 'Failed'],
  ]
    .filter(([value]) => !!value)
    .map(([value, name]) => `${value} ${name}`)
    .join(', ');

  return (
    <Box display="flex" width="100%" marginBottom={marginBottom} minWidth={isSmallScreen ? undefined : '300px'}>
      <Tooltip title={<Typography>{statusString}</Typography>}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="flex-end"
          height="100%"
          width="calc(100% - 8px)"
        >
          <Typography variant="body2" color="primary">
            <b>Generating - {percentComplete.toFixed(0)}%</b>
          </Typography>
          <LinearProgress style={{width: '100%', borderRadius: '4px'}} variant="determinate" value={percentComplete} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ResultsProgress;
