import React from 'react';
import {Box, Card, CardContent, Typography} from '@material-ui/core';
import {useMediumScreenSize} from '../../../utils/utilHooks';

function AnalysedCounts({buildsCount, partsCount}: {buildsCount: number; partsCount: number}) {
  const isMediumScreenSize = useMediumScreenSize();

  return (
    <Box
      display="flex"
      flexDirection={isMediumScreenSize ? 'row' : 'column'}
      justifyContent="space-between"
      height="100%"
    >
      <Card style={{[isMediumScreenSize ? 'width' : 'height']: 'calc(50% - 8px)'}}>
        <CardContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            paddingBottom: '16px',
          }}
        >
          <Typography variant="h3" align="center" style={{marginBottom: '12px'}}>
            {buildsCount}
          </Typography>
          <Typography variant="h6" align="center">
            Builds analysed
          </Typography>
        </CardContent>
      </Card>
      <Card style={{[isMediumScreenSize ? 'width' : 'height']: 'calc(50% - 8px)'}}>
        <CardContent
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            paddingBottom: '16px',
          }}
        >
          <Typography variant="h3" align="center" style={{marginBottom: '12px'}}>
            {partsCount}
          </Typography>
          <Typography variant="h6" align="center">
            Parts analysed
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default AnalysedCounts;
