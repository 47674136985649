import React, {useState, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Delete} from '@material-ui/icons';
import {BuildReportStatus, IBuildReportGETResponse} from '@common/api/models/builds/data/IBuildReport';
import IndexPage from '../shared/IndexPage/IndexPage';
import {RootState} from '../../store/reducers';
import {GenericDialog} from '../../components/molecules/DialogButton';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportActions from '../../store/actions/buildReportActions';
import CreateBuildReportButton from './CreateReportButton';

const BuildReportListPage = () => {
  const [reportDeleting, setReportDeleting] = useState<IBuildReportGETResponse | undefined>();
  const buildReports = useSelector((state: RootState) => {
    return state.buildReportStore.list;
  });
  const asyncDispatch = useAsyncDispatch();

  useEffect(() => {
    const generatingStatuses = [BuildReportStatus.Submitted, BuildReportStatus.Generating];
    if (buildReports.some((report) => generatingStatuses.includes(report.status))) {
      const interval = setInterval(() => {
        asyncDispatch(buildReportActions.refreshList(false));
      }, 15_000);

      return () => clearInterval(interval);
    }
  }, [buildReports, asyncDispatch]);

  const useData = useCallback(() => {
    return buildReports.map((report) => ({
      ...report,
      onDelete: () => setReportDeleting(report),
    }));
  }, [buildReports]);

  return (
    <>
      <IndexPage
        resourceType="buildReport"
        CreateResourceComponent={CreateBuildReportButton}
        useData={useData}
        minTableWidth="792px"
      />
      <DeleteModal reportDeleting={reportDeleting} setReportDeleting={setReportDeleting} />
    </>
  );
};
export default BuildReportListPage;

const DeleteModal = ({
  reportDeleting,
  setReportDeleting,
}: {
  reportDeleting: IBuildReportGETResponse | undefined;
  setReportDeleting: React.Dispatch<React.SetStateAction<IBuildReportGETResponse | undefined>>;
}) => {
  const dispatch = useAsyncDispatch();
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if (!reportDeleting) return;

    setDeleting(true);
    const success = await dispatch(buildReportActions.deleteBuildReport(reportDeleting));
    setDeleting(false);

    if (success) {
      setReportDeleting(undefined);
    }
  };

  return (
    <GenericDialog
      title="Delete Report?"
      content={
        <>
          Are you sure you want to delete the build report for{' '}
          <b>
            <i>{reportDeleting?.buildName}</i>
          </b>
          ?
        </>
      }
      isOpen={!!reportDeleting}
      closeDialog={() => setReportDeleting(undefined)}
      onSuccess={onDelete}
      requestInProgress={deleting}
      confirmButtonProps={{endIcon: <Delete />}}
    />
  );
};
