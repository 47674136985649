import axios from 'axios';
import * as qs from 'qs';
import {
  IBuildReportsGETRequest,
  IBuildReportGETResponse,
  IBuildReportPOSTRequest,
  IBuildReportUrlResponse,
} from '@common/api/models/builds/data/IBuildReport';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function buildReportAllGET(
  filter: QueryFilter<IBuildReportsGETRequest>
): AjaxApiResult<IBuildReportGETResponse[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/reports/buildReport/' + (query && '?' + query));
  }, 'Could not fetch build reports');
}

export async function buildReportPOST(buildReport: IBuildReportPOSTRequest): AjaxApiResult<IBuildReportGETResponse> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/buildReport/', buildReport);
  }, 'Could not create build report');
}

export async function buildReportDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/buildReport/${uuid}`);
  }, 'Could not delete build report');
}

export async function buildReportDownloadUrlGET(uuid: string): AjaxApiResult<IBuildReportUrlResponse> {
  return ajaxWrapper(() => {
    return axios.get(`/api/reports/buildReport/${uuid}/downloadUrl`);
  }, 'Could not get build report download URL');
}
