import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import * as qs from 'qs';
import {IAlert} from '@common/api/models/devices/IAlert';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function alertsAllGET(filter: QueryFilter<IAlert>): AjaxApiResult<IAlert[]> {
  const query = qs.stringify(filter, {arrayFormat: 'brackets'});
  return ajaxWrapper(() => {
    return axios.get('/api/alerts/' + (query && '?' + query));
  }, 'Could not fetch alerts');
}
