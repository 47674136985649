import React from 'react';
import {Button} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootState} from '../../store/reducers';
import {OrganizationRow} from '../shared/IndexPage/tableSchemas/organization';
import IndexPage from '../shared/IndexPage/IndexPage';

const AddOrganization = () => {
  const history = useHistory();

  return (
    <Button variant="contained" color="primary" onClick={() => history.push('/organizations/createOrganization')}>
      <Add />
      Add Organization
    </Button>
  );
};

export function OrganizationsPage() {
  const useOrganizationData = () => {
    const authState = useSelector((state: RootState) => state.auth);
    const organizationStore = useSelector((s: RootState) => s.organizationStore.list);
    const userOrganization = authState.user?.organizationUuid;

    return organizationStore.map<OrganizationRow>((org) => {
      return {
        name: org.name,
        current: org.uuid === userOrganization,
        registered: org.registered,
        onClickUrl: `/organizations/uuid/${org.uuid}/`,
      };
    });
  };

  return (
    <IndexPage
      useData={useOrganizationData}
      resourceType="organization"
      CreateResourceComponent={AddOrganization}
      title="Organizations"
    />
  );
}
