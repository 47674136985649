import React from 'react';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import SimilarityComparisonWeights from '../shared/SimilarityComparisonWeights';
import {ComparisonWeightsType} from '@common/api/models/builds/data/ISimilarity';

const ComparisonWeights = () => {
  const comparisonWeights = useSelector(
    (state: RootState) => state.similarityReportStore.draftSimilarityReport.comparisonWeights
  );

  const {setDraftSimilarityReport} = useSimilarityReportStoreActions();

  const onComparisonWeightsSaved = async (comparisonWeights: ComparisonWeightsType) => {
    setDraftSimilarityReport('comparisonWeights', comparisonWeights);
    return true;
  };

  return <SimilarityComparisonWeights comparisonWeights={comparisonWeights} onSave={onComparisonWeightsSaved} />;
};

export default ComparisonWeights;
