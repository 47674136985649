import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {LiveStoreState, createInitialLiveStore} from './liveUpdateStore';
import {
  BuildIntensityReportStatus,
  IBuildIntensityReportGETResponse,
} from '@common/api/models/builds/data/IBuildIntensityReport';
import {GraphDataType} from '../../pages/buildIntensityReport/IntensityGraph/GraphOptionsModal';
import {ChartDataType} from '../../pages/buildIntensityReport/IntensitySummaryBar/ChartOptionsModel';

export enum BuildIntensityReportActions {
  CREATE_BUILD_INTENSITY_REPORT_SUCCESS = 'CREATE_BUILD_INTENSITY_REPORT_SUCCESS',
  DELETE_BUILD_INTENSITY_REPORT_SUCCESS = 'DELETE_BUILD_INTENSITY_REPORT_SUCCESS',
  FETCH_PARTS_FOR_BUILD_SUCCESS = 'FETCH_PARTS_FOR_BUILD_SUCCESS',
  CLEAR_PARTS_FOR_BUILD = 'CLEAR_PARTS_FOR_BUILD',
  FETCH_ZIP_FOR_BUILD_SUCCESS = 'FETCH_ZIP_FOR_BUILD_SUCCESS',
  ADD_SELECTED_BUILD = 'ADD_SELECTED_BUILD',
  REMOVE_SELECTED_BUILD = 'REMOVE_SELECTED_BUILD',
  ADD_SELECTED_PART = 'ADD_SELECTED_PART',
  REMOVE_SELECTED_PART = 'REMOVE_SELECTED_PART',
  SET_CHART_DATA_TYPE = 'SET_CHART_DATA_TYPE',
  SET_GRAPH_DATA_TYPE = 'SET_GRAPH_DATA_TYPE',
  SET_GRAPH_DATA_TOGGLE = 'SET_GRAPH_DATA_TOGGLE',
  SET_ROLLING_PERIOD = 'SET_ROLLING_PERIOD',
  ADD_INTENSITY_CSVS = 'ADD_INTENSITY_CSVS',
  SET_REPORT_STATUS = 'SET_REPORT_STATUS',
  CLEAR_INTENSITY_REPORTS = 'CLEAR_INTENSITY_REPORTS',
  CLEAR_INTENSITY_REPORTS_SELECTIONS = 'CLEAR_INTENSITY_REPORTS_SELECTIONS',
}

export type PartCSVsDataType = {
  part_uuid: string[];
  part_name: string[];
  layer: number[];
  mean: number[];
  median: number[];
  std: number[];
  min: number[];
  max: number[];
  max_count: number[];
  total_count: number[];
};
export type SummaryCSVsDataType = Omit<PartCSVsDataType, 'layer'>;

export const PART_CSV_DEFAULT_DATA = {
  part_uuid: [] as string[],
  part_name: [] as string[],
  layer: [] as number[],
  mean: [] as number[],
  median: [] as number[],
  std: [] as number[],
  min: [] as number[],
  max: [] as number[],
  max_count: [] as number[],
  total_count: [] as number[],
};

export const SUMMARY_CSV_DEFAULT_DATA = {
  part_uuid: [] as string[],
  part_name: [] as string[],
  mean: [] as number[],
  median: [] as number[],
  std: [] as number[],
  min: [] as number[],
  max: [] as number[],
  max_count: [] as number[],
  total_count: [] as number[],
};

export type PartCSVsType = {[buildUuid: string]: {[partUuid: string]: PartCSVsDataType}};
export type BuildNameByUuidType = {[buildUuid: string]: string};
export type GraphDataTogglesType = {[config: string]: boolean};

export interface BuildIntensityReportStoreState extends LiveStoreState<IBuildIntensityReportGETResponse> {
  partCSVs: PartCSVsType;
  summaryCSVs: {[buildUuid: string]: SummaryCSVsDataType};
  selectedBuilds: Set<string>;
  selectedParts: Set<IPartGETResponse>;
  partsByBuildUuid: {
    [buildUuid: string]: {parts: IPartGETResponse[]; total: number};
  };
  buildNamesByBuildUuid: BuildNameByUuidType;
  graphDataType: GraphDataType;
  graphDataToggles: {[config: string]: boolean};
  rollingPeriod: number;
  chartDataType: ChartDataType;
}

export const initialBuildIntensityReportState: BuildIntensityReportStoreState = {
  ...createInitialLiveStore(),
  partCSVs: {},
  summaryCSVs: {},
  selectedBuilds: new Set(),
  selectedParts: new Set(),
  buildNamesByBuildUuid: {},
  partsByBuildUuid: {},
  graphDataType: GraphDataType.Mean,
  graphDataToggles: {
    showTrendline: true,
    showScatter: false,
    showSTD: false,
  },
  rollingPeriod: 30,
  chartDataType: ChartDataType.Mean,
};

export interface ClearIntensityReportAction {
  type: BuildIntensityReportActions.CLEAR_INTENSITY_REPORTS;
}

export interface CreateIntensityReportAction {
  type: BuildIntensityReportActions.CREATE_BUILD_INTENSITY_REPORT_SUCCESS;
  payload: {
    report: IBuildIntensityReportGETResponse;
  };
}

export interface DeleteIntensityReportAction {
  type: BuildIntensityReportActions.DELETE_BUILD_INTENSITY_REPORT_SUCCESS;
  payload: {
    reportUuid: string;
  };
}

export interface SelectedPartsAction {
  type: BuildIntensityReportActions.ADD_SELECTED_PART | BuildIntensityReportActions.REMOVE_SELECTED_PART;
  payload: {part: IPartGETResponse};
}

export interface SelectedBuildsAction {
  type: BuildIntensityReportActions.ADD_SELECTED_BUILD | BuildIntensityReportActions.REMOVE_SELECTED_BUILD;
  payload: {
    buildUuid: string;
    buildName: string;
  };
}

export interface IntensityCSVsAction {
  type: BuildIntensityReportActions.ADD_INTENSITY_CSVS;
  payload: {
    buildUuid: string;
    newPartCSVs: {[partUuid: string]: PartCSVsDataType};
    newSummaryCSVs: {[buildUuid: string]: SummaryCSVsDataType};
  };
}

export interface FetchPartsForBuildAction {
  type: BuildIntensityReportActions.FETCH_PARTS_FOR_BUILD_SUCCESS;
  payload: {
    buildUuid: string;
    parts: IPartGETResponse[];
    total: number;
  };
}

export interface GraphDataTypeAction {
  type: BuildIntensityReportActions;
  payload: {
    graphDataType: GraphDataType;
  };
}

export interface GraphDataToggleAction {
  type: BuildIntensityReportActions;
  payload: {
    toggleName: string;
    bool: boolean;
  };
}

export interface RollingPeriodAction {
  type: BuildIntensityReportActions;
  payload: {
    rollingPeriod: number;
  };
}

export interface SetReportStatusAction {
  type: BuildIntensityReportActions.SET_REPORT_STATUS;
  payload: {
    buildUuid: string;
    status: BuildIntensityReportStatus;
  };
}

export interface RollingPeriodAction {
  type: BuildIntensityReportActions;
  payload: {
    rollingPeriod: number;
  };
}

export interface ChartDataTypeAction {
  type: BuildIntensityReportActions;
  payload: {
    chartDataType: ChartDataType;
  };
}
