import React, {useEffect, useState} from 'react';
import {Pause} from '@material-ui/icons';
import {IBuild} from '@common/api/models/builds/IBuild';
import {DeviceDetailedState} from '@common/api/models/devices/IDevice';
import {DialogButton} from '../../../../components/molecules/DialogButton';
import {buildStopGET} from '../../../../api/ajax/builds';
import {usePermissionsForBuild} from '../../../../utils/utilHooks';
import ConditionalTooltip from '../../../../components/atoms/Texts/ConditionalTooltip';
import {toast} from 'react-toastify';
import {BUILD_SHORT_TRANSITION_TIMEOUT} from '../index';

interface PauseBuildButtonProps {
  build: IBuild;
  deviceDetailedState: DeviceDetailedState;
  fullWidthButton?: boolean;
}

let timeout: null | ReturnType<typeof setTimeout> = null;

export const usePauseBuild = (
  build: IBuild,
  deviceDetailedState: DeviceDetailedState
): [boolean, () => Promise<void>] => {
  const [pausingBuild, setPausingBuild] = useState(false);
  const [deviceDetailedStateWas, setDeviceDetailedStateWas] = useState<DeviceDetailedState>();

  useEffect(() => {
    return () => clearTimeout(timeout!);
  }, []);

  useEffect(() => {
    // If device state transitioned from MonitoringStopping, then the job has finished, whether successful or not.
    if (
      deviceDetailedStateWas === DeviceDetailedState.MonitoringStopping &&
      deviceDetailedState !== DeviceDetailedState.MonitoringStopping
    ) {
      setPausingBuild(false);
      clearTimeout(timeout!);
    }

    setDeviceDetailedStateWas(deviceDetailedState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetailedState]);

  const handlePauseBuild = async () => {
    setPausingBuild(true);
    const res = await buildStopGET(build.uuid);
    if (!res.success) {
      setPausingBuild(false);
      return;
    }

    timeout = setTimeout(() => {
      setPausingBuild(false);
      toast('Pausing build timed out', {type: 'error'});
    }, BUILD_SHORT_TRANSITION_TIMEOUT);
  };

  return [pausingBuild, handlePauseBuild];
};

export const PAUSE_DIALOG_PROPS = {
  title: 'Pause build',
  content: `Are you sure you want to pause this build?
  This will leave monitoring mode, and you can return to monitoring mode after.`,
  confirmText: 'Confirm and Pause',
};

export function PauseBuildButton({build, deviceDetailedState, fullWidthButton}: PauseBuildButtonProps) {
  const [pausingBuild, handlePauseBuild] = usePauseBuild(build, deviceDetailedState);
  const {machinePermission} = usePermissionsForBuild(build);

  return (
    <ConditionalTooltip
      tooltip="Pausing the build requires permission to access this build's machine."
      hideTooltip={!!machinePermission}
    >
      <DialogButton
        text="Pause Build"
        icon={<Pause />}
        loading={pausingBuild}
        dialog={PAUSE_DIALOG_PROPS}
        handleConfirm={handlePauseBuild}
        fullWidth={fullWidthButton}
        disabled={!machinePermission}
      />
    </ConditionalTooltip>
  );
}
