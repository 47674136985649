import * as React from 'react';
import {AppBar, Tab, Tabs, Theme} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {ActiveBuildPage, ActiveBuildProps} from '../../../pages/builds/liveBuild/ActiveBuild';

export interface BuildTabsProps {
  pages: ActiveBuildPage[];
  activeBuildProps: ActiveBuildProps & {theme: Theme};
}

export default function BuildTabs(props: BuildTabsProps) {
  const {uuid, liveStep} = useParams<{uuid: string; liveStep?: string}>();
  const history = useHistory();
  const pages = props.pages;

  const handleTabChange = (e: any, newIndex: number) => {
    history.push('/builds/uuid/' + uuid + '/live/' + pages[newIndex].id + '/');
  };

  const activePageIndex = pages.findIndex((p) => p.id === liveStep);

  return (
    <AppBar position="static" color="transparent" style={{boxShadow: 'none', marginBottom: '4px'}}>
      <Tabs
        value={activePageIndex}
        onChange={handleTabChange}
        variant="standard"
        indicatorColor="primary"
        aria-label="full width tabs example"
      >
        {pages
          .filter((p) => !p.hidden)
          .map((p) => {
            return <Tab id={'tab-' + p.id} key={'tab-' + p.id} label={p.title} />;
          })}
      </Tabs>
    </AppBar>
  );
}
