import React, {useCallback, useEffect, useRef} from 'react';
import Konva from 'konva';
import {KonvaEventObject} from 'konva/types/Node';
import {Vector2d} from 'konva/types/types';
import {FunctionComponent, useMemo, useState} from 'react';
import {Layer, Line, Stage} from 'react-konva';
import {useSelector} from 'react-redux';
import {Stage as IStage} from 'konva/types/Stage';

import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import {BoundingBox} from './BoundingBox';
import {FilteredImage} from './FilteredImage';
import {ImageOverlays} from './SingleImageViewport';
import {getCalibrationData, setStageScaleIfChanged} from './utils';
import {RootState} from '../../../../store/reducers';
import {throttle} from 'lodash';

(Konva as any).hitOnDragEnabled = true;

export interface ImageViewportProps {
  overlays: ImageOverlays[];
  isDefectViewport?: boolean;
  // Include this here because then we have access to the width without loading.
  imagePixelWidth: number;
  imagePixelHeight: number;
  width: number;
  height: number;
  mmPerPixel: number;
  buildUuid?: string;

  // @ts-ignore
  stageRef?: (ref: Stage | null) => any;
  isFitToWrapper?: boolean;
  setViewportFit: (newValue: boolean) => void;
  zoomScale: number;
  setZoomScale: (newValue: number) => void;
  positionScale: number;

  parts: IPartGETResponse[];
  currentLayer?: number;
  showBoundingBoxes?: boolean;

  onPartHover?: (part: IPartGETResponse | null) => void;
  hoveredSidebarPart?: IPartGETResponse | null;
  handleHover?: (pointerPosition: Vector2d | null) => void;
  hoveredPosition?: {x?: number; y?: number} | null;
}

const onWheel = (event: KonvaEventObject<WheelEvent>): number | undefined => {
  event.evt.preventDefault();
  let stage = event.target;
  while (stage.parent) {
    stage = stage.parent as any;
  }

  if (!(stage instanceof Konva.Stage)) {
    return;
  }

  if ('getPointerPosition' in stage) {
    const mousePos = stage.getPointerPosition();
    if (mousePos) {
      const oldScale = stage.scaleX();
      const oldMousePos = {
        x: (mousePos.x - stage.x()) / oldScale,
        y: (mousePos.y - stage.y()) / oldScale,
      };

      // Calculate zoom amount
      const scrollDelta = event.evt.deltaY;
      const zoomFactor = 1.1; // Change this to alter zoom speed
      const newScale = scrollDelta > 0 ? oldScale / zoomFactor : oldScale * zoomFactor;

      const newMousePos = {
        x: -(oldMousePos.x - mousePos.x / newScale) * newScale,
        y: -(oldMousePos.y - mousePos.y / newScale) * newScale,
      };

      // Update stage
      setStageScaleIfChanged(stage, newScale);
      stage.setAttr('x', newMousePos.x);
      stage.setAttr('y', newMousePos.y);
      stage.getStage().batchDraw();

      return newScale;
    }
  }
};

const ImageDisplay: FunctionComponent<ImageViewportProps> = (props) => {
  const [stage, setStage] = useState<Konva.Stage | null>();

  const widthMM = props.imagePixelWidth * props.mmPerPixel;
  const heightMM = props.imagePixelHeight * props.mmPerPixel;
  const build = useSelector((state: RootState) =>
    props.buildUuid ? state.buildStore.byId[props.buildUuid] : undefined
  );
  const calibrationStore = useSelector((state: RootState) => state.calibrationStore);
  const hasLoaded = props.overlays.some((overlay) => !!overlay.image?.complete);

  const {plateBoundingBox, calibrationResolution, calibrationScale} = useMemo(
    () =>
      !!build
        ? getCalibrationData(calibrationStore, build.calibrationUuid)
        : {plateBoundingBox: undefined, calibrationResolution: undefined, calibrationScale: undefined},
    [calibrationStore, build]
  );
  const plateBoundingBoxMM = plateBoundingBox?.map((point) => (calibrationScale ? point * calibrationScale : point));

  // Prevent user from dragging image offscreen
  const dragBoundFunc = (currentPosition: Vector2d) => {
    if (!stage) {
      return currentPosition;
    }
    props.setViewportFit(false);
    const zoomScale = stage.getStage().scaleX();

    // X Position
    const maxRightXPos = Math.min(currentPosition.x, props.width / 2);
    const maxLeftXPos = -zoomScale * widthMM + props.width / 2;
    // Y Position
    const maxTopYPos = Math.min(currentPosition.y, props.height / 2);
    const maxBottomYPos = -zoomScale * heightMM + props.height / 2;
    return {
      x: Math.max(maxRightXPos, maxLeftXPos),
      y: Math.max(maxTopYPos, maxBottomYPos),
    };
  };

  const focusStage = (stage: Konva.Stage) => {
    const container = stage.getStage().container();
    container.focus();
    container.tabIndex = 1;
  };

  // @ts-ignore
  const onSetStage = (ref: Stage | null) => {
    setStage(ref?.getStage());
    if (props.stageRef) {
      props.stageRef(ref);
    }
  };

  useEffect(() => {
    if (stage) focusStage(stage);
  }, [stage]);

  const lastCenter = useRef<Vector2d | null>(null);
  const lastDist = useRef<number | null>(null);

  function getDistance(p1: Vector2d, p2: Vector2d): number {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1: Vector2d, p2: Vector2d): Vector2d {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  const onTouchMove = (event: KonvaEventObject<TouchEvent>) => {
    // Based off https://konvajs.org/docs/sandbox/Multi-touch_Scale_Stage.html
    event.evt.preventDefault();
    const touch1 = event.evt.touches[0];
    const touch2 = event.evt.touches[1];

    if (!stage) return;

    focusStage(stage);

    if (touch1 && touch2) {
      stage.draggable(false);
      props.setViewportFit(false);

      // If the stage was under Konva's drag & drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      const p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      const p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      let newCenter = getCenter(p1, p2);
      if (!lastCenter.current) {
        lastCenter.current = newCenter;
        return;
      }

      let dist = getDistance(p1, p2);
      if (!lastDist.current) {
        lastDist.current = dist;
      }

      let scale = stage.scaleX() * (dist / lastDist.current);
      // Local coordinates of center point
      let pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleY(),
      };

      // Calculate new position of the stage
      let dx = newCenter.x - lastCenter.current.x;
      let dy = newCenter.y - lastCenter.current.y;

      let newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.scaleX(scale);
      stage.scaleY(scale);

      stage.position(newPos);
      stage.getStage().batchDraw();

      lastDist.current = dist;
      lastCenter.current = newCenter;
    }
  };

  const onTouchEnd = (_event: KonvaEventObject<TouchEvent>) => {
    props.setViewportFit(false);
    lastDist.current = null;
    lastCenter.current = null;
    if (stage) stage.draggable(true);
  };

  const getMMPointerPosition = (stage: IStage) => {
    // Pointer position is initially given to us relative to the top left corner of the stage.
    // Transform this into image mm-space.
    // Ideally we'd use Konva's getRelativePointerPosition, but this doesn't seem to be exposed.
    const mousePos = stage.getPointerPosition();
    if (!mousePos) {
      return null;
    }
    const transform = stage.getAbsoluteTransform().copy().invert();
    const transformedPos = transform.point(mousePos);
    // Adjust the y-coordinate so that 0 is at the bottom of the stage
    transformedPos.y = heightMM - transformedPos.y;

    return transformedPos;
  };

  const handleHover = useCallback(
    (event: KonvaEventObject<TouchEvent> | KonvaEventObject<MouseEvent> | null) => {
      if (event === null) {
        props.handleHover && props.handleHover(null);
        return;
      }
      const stage = event.target.getStage();
      const pointerPosition = !!stage ? getMMPointerPosition(stage) : null;
      pointerPosition && props.handleHover && props.handleHover(pointerPosition);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [heightMM]
  );

  const handleMouseMove = useMemo(
    () => throttle(handleHover, 50),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleHover]
  );

  return (
    <Stage
      height={props.height}
      width={props.width}
      style={{
        position: 'absolute',
        backgroundColor: '#0D0D0D',
        margin: `${props.isDefectViewport && !props.isFitToWrapper ? '15px' : '0px'}`,
        width: `${props.isFitToWrapper ? '100%' : undefined}`,
      }}
      draggable={true}
      dragBoundFunc={hasLoaded ? dragBoundFunc : undefined}
      onWheel={
        hasLoaded
          ? (evt: KonvaEventObject<WheelEvent>) => {
              if (stage) {
                focusStage(stage);
                const currentStageScale = stage.getStage().scaleX();
                const newStageScale = onWheel(evt);
                if (newStageScale) {
                  const newZoomScale = (props.zoomScale * newStageScale) / currentStageScale;
                  props.setZoomScale(newZoomScale);
                  props.setViewportFit(false);
                }
              }
            }
          : undefined
      }
      onTouchMove={hasLoaded ? onTouchMove : undefined}
      onTouchEnd={hasLoaded ? onTouchEnd : undefined}
      ref={(r) => {
        onSetStage(r);
      }}
    >
      <Layer
        onMouseMove={props.handleHover ? handleMouseMove : undefined}
        onMouseLeave={props.handleHover ? () => handleMouseMove(null) : undefined}
        onTouchMove={props.handleHover ? handleMouseMove : undefined}
      >
        {props.overlays.map(
          (o) =>
            o.image &&
            o.image.complete && (
              <FilteredImage
                id={o.id}
                key={o.id}
                filters={o.filters}
                {...o.filterParams}
                image={o.image}
                width={widthMM}
                height={heightMM}
              />
            )
        )}
        {!!props.hoveredPosition?.x && (
          <Line
            points={[
              props.hoveredPosition?.x,
              plateBoundingBoxMM?.[1] || 0,
              props.hoveredPosition?.x,
              plateBoundingBoxMM?.[3] || heightMM,
            ]}
            stroke="orange"
            strokeWidth={2 * (1 / props.positionScale)}
          />
        )}
        {!!props.hoveredPosition?.y && (
          <Line
            points={[
              plateBoundingBoxMM?.[0] || 0,
              heightMM - props.hoveredPosition?.y,
              plateBoundingBoxMM?.[2] || widthMM,
              heightMM - props.hoveredPosition?.y,
            ]}
            stroke="orange"
            strokeWidth={2 * (1 / props.positionScale)}
          />
        )}
      </Layer>
      {props.showBoundingBoxes && plateBoundingBox && calibrationResolution && (
        <BoundingBox
          parts={props.parts}
          imageWidthMM={widthMM}
          imageHeightMM={heightMM}
          plateBoundingBox={plateBoundingBox}
          calibrationResolution={calibrationResolution}
          scale={props.mmPerPixel}
          currentLayer={props.currentLayer}
          onPartHover={props.onPartHover}
          zoomScale={props.zoomScale + (stage?.getStage()?.scaleX() || 0)}
        />
      )}
      {props.hoveredSidebarPart && plateBoundingBox && calibrationResolution && (
        <BoundingBox
          parts={[props.hoveredSidebarPart]}
          imageWidthMM={widthMM}
          imageHeightMM={heightMM}
          plateBoundingBox={plateBoundingBox}
          calibrationResolution={calibrationResolution}
          scale={props.mmPerPixel}
          currentLayer={props.currentLayer}
          zoomScale={props.zoomScale + (stage?.getStage()?.scaleX() || 0)}
          isSingleHoveredPart
        />
      )}
    </Stage>
  );
};

export default ImageDisplay;
