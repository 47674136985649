import React from 'react';
import Chart from './Chart';
import {PlotParams} from 'react-plotly.js';

export interface BarChartProps {
  data: Partial<Plotly.PlotData>[];
  height?: number;
  width?: number;
  disableZoom?: boolean;
  barmode?: Plotly.Layout['barmode'];
  margin?: Partial<Plotly.Margin>;
  layout?: Partial<Plotly.Layout>;
  orientation?: Plotly.PlotData['orientation'];
  onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
  onHover?: PlotParams['onHover'];
  onUnhover?: PlotParams['onUnhover'];
}

function BarChart({disableZoom = true, orientation = 'v', onHover, onUnhover, ...props}: BarChartProps) {
  // Convert input data points into a bar chart
  const transformedData = props.data.map((data) => {
    return {...data, type: 'bar' as const, orientation};
  });
  return (
    <Chart
      data={transformedData}
      layout={{
        dragmode: false,
        autosize: true,
        // Set to undefined to make chart responsive
        height: props.height,
        width: props.width,
        margin: props.margin,
        showlegend: false,
        barmode: props.barmode || 'group',
        xaxis: {
          fixedrange: disableZoom,
          showticklabels: true,
          automargin: true,
        },
        yaxis: {fixedrange: disableZoom, automargin: true},
        hovermode: 'closest',
        ...props.layout,
      }}
      useResizeHandler
      style={{width: '100%', height: '100%'}}
      config={{displayModeBar: false, responsive: true}}
      onClick={props.onClick}
      onHover={onHover}
      onUnhover={onUnhover}
    />
  );
}

export default BarChart;
