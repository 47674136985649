import React from 'react';

import {DefectStatisticGETResponse} from '@common/api/models/builds/data/defects/IDefectStatistic';
import {
  DefectSeverity,
  getDefectSeverity,
  getPrettyDefectType,
  stringToDefectType,
} from '@common/api/models/builds/data/defects/IDefect';

import {ChipStatus} from '../../../../components/atoms/Status/Status';
import {EllipsisTextColumnTooltip} from './sharedColumns';

interface DefectStatisticRow extends DefectStatisticGETResponse {
  onDelete: () => void;
}

const defectStatisticTableSchema = [
  {
    title: 'Type',
    field: 'defectType',
    width: '132px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectStatisticRow) => {
      return getPrettyDefectType(stringToDefectType(row.defectType));
    },
  },
  {
    title: 'Severity',
    field: 'severity',
    width: '92px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectStatisticRow) => {
      const severity = getDefectSeverity(stringToDefectType(row.defectType));
      switch (severity) {
        case DefectSeverity.LOW:
          return <ChipStatus message="Low" variant="none" chipSize="small" />;
        case DefectSeverity.MEDIUM:
          return <ChipStatus message="Medium" variant="warning" chipSize="small" />;
        case DefectSeverity.HIGH:
          return <ChipStatus message="High" variant="error" chipSize="small" />;
        default:
          return <></>;
      }
    },
  },
  {
    title: 'Part',
    field: 'partName',
    width: 'auto',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectStatisticRow) => {
      return <EllipsisTextColumnTooltip>{row.partName}</EllipsisTextColumnTooltip>;
    },
  },
  {
    title: 'Layer',
    field: 'layerNum',
    width: '92px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
  },
  {
    title: 'Area (mm²)',
    field: 'defectArea',
    width: '148px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectStatisticRow) =>
      row.defectArea
        ? `${new Intl.NumberFormat('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(row.defectArea)}`
        : '-',
  },
  {
    title: 'Coverage (%)',
    field: 'defectCoverage',
    width: '132px',
    cellStyle: {padding: '10px'},
    headerStyle: {padding: '10px'},
    render: (row: DefectStatisticRow) =>
      row.defectCoverage
        ? `${new Intl.NumberFormat('en', {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          }).format(row.defectCoverage)}%`
        : '-',
  },
];

export default defectStatisticTableSchema;
