import {combineValidation, ValidateFn, generateValidationResult, validateDateTime} from '../../types';
import {IMaterial} from './IMaterial';

/**
 * Validate the input name of a material.
 * @param name {string} - Name of the material
 */
export const validateMaterialName: ValidateFn<string, string> = function (name) {
  if (name.length < 1) {
    const errorMessage = ['Please enter a material name'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate the input name of a material.
 * @param grade {string} - Grade of the material
 */
export const validateMaterialGrade: ValidateFn<string, string> = function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  grade: any
) {
  if (typeof grade !== 'string') {
    return generateValidationResult(false, ['Please enter a proper string']);
  }
  if (grade.length < 1) {
    const errorMessage = ['Please enter a material grade'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate the input date for a material's manufacturing date.
 * @param date {Date} - Manufacturing date
 */
export const validateManufacturedDate: ValidateFn<Date, string> = function (date) {
  const isValid = validateDateTime(date);
  if (!isValid) {
    const errorMessage = ['Please enter a valid manufactured date'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate the input date for a material's received date.
 * @param date {Date} - Material received date
 */
export const validateReceivedDate: ValidateFn<Date, string> = function (date) {
  const isValid = validateDateTime(date);
  if (!isValid) {
    const errorMessage = ['Please enter a valid received date'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate the input quantity for the quantity received for a material.
 * @param quantity {number} - Amount of material quantity received
 */
export const validateQuantityReceived: ValidateFn<number, string> = function (quantity) {
  if (!Number.isFinite(quantity) || quantity <= 0) {
    const errorMessage = ['Please enter a quantity received value greater than 0'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate the input quantity for the quantity remaining for a material.
 * @param quantity {number} - Amount of material quantity remaining
 */
export const validateQuantityRemaining: ValidateFn<number, string> = function (quantity) {
  if (!Number.isFinite(quantity) || quantity < 0) {
    const errorMessage = ['Please enter a quantity received value greater than 0'];
    return generateValidationResult(false, errorMessage);
  }
  return generateValidationResult(true);
};

/**
 * Validate all parameters of a material.
 * @param material {IMaterial} - Contains information about a material
 */
export const validateMaterial: ValidateFn<Partial<IMaterial>, string> = function (material) {
  return combineValidation(
    validateMaterialName(material.name),
    validateManufacturedDate(material.manufactured),
    validateReceivedDate(material.dateReceived),
    validateQuantityReceived(material.quantityReceived),
    validateQuantityRemaining(material.quantityRemaining),
    validateMaterialGrade(material.grade),
    validateMaterialGrade(material.description)
  );
};
