import React, {useState} from 'react';
import {Alert} from '@material-ui/lab';
import MaterialTable, {Column, MaterialTableProps, Options, Query, QueryResult} from 'material-table';
import {useHistory} from 'react-router-dom';
import {materialTableIcons} from '../../material-table-icons';
import {FormControlLabel, Paper} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import config from '../../../config/config';
import styled from 'styled-components';

const CoolTableContainer = (props: any) => <Paper {...props} elevation={0} />;

const tableComponents = {
  Container: CoolTableContainer,
};

export function CoolLinkedTable<RowData extends {url: string | undefined}>(
  props: {
    errorMessage?: string;
    options?: Partial<Options<RowData>>;
    filters?: Partial<RowData>;
    columns: Column<RowData>[];
    // Set to false if the table rows are not links to pages
    // (undefined is treated as true)
    linked?: boolean;
    data: RowData[] | ((query: Query<RowData>) => Promise<QueryResult<RowData>>);
  } & MaterialTableProps<RowData>
) {
  const {errorMessage, filters, columns, data, options, ...rest} = props;
  const filtersEff: {[key: string]: string} = (filters || {}) as any;

  const history = useHistory();
  const [advanced, setAdvanced] = useState(() => !!filters);

  const handleAdvancedCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdvanced(event.target.checked);
  };

  if (errorMessage && data.length === 0) {
    return <Alert severity="error">{errorMessage}</Alert>;
  } else {
    return (
      <Wrapper>
        <MaterialTable
          columns={columns.map((c) => ({
            ...c,
            defaultFilter: filtersEff[c.field as string],
          }))}
          data={data}
          icons={materialTableIcons}
          components={tableComponents}
          title={
            props.title || (
              <FormControlLabel
                control={<Checkbox checked={advanced} onChange={handleAdvancedCheck} />}
                label="Advanced Filters"
              />
            )
          }
          onRowClick={
            props.linked === undefined || props.linked
              ? (event, rowData) => {
                  if (rowData && rowData.url) {
                    history.push(rowData.url);
                  }
                }
              : undefined
          }
          options={{
            filtering: advanced,
            pageSize: 100,
            pageSizeOptions: config.pageSizeOptions,
            emptyRowsWhenPaging: false,
            ...options,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: props.isLoading ? '' : 'No records to display',
            },
          }}
          {...rest}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  .MuiTablePagination-toolbar {
    button {
      padding: 0px !important;
    }
  }
`;
