import React, {createContext, useCallback, useContext} from 'react';
import buildSteps, {BuildStep} from './draftBuildStepsConfig';
import {useParams, useHistory} from 'react-router-dom';

type HandleStepChangeFn = (step: string, replace?: boolean) => void;

type ActiveStepContextStore = {
  activeStepId: number;
  activeStep: BuildStep;
  handleStepChange: HandleStepChangeFn;
};

export const ActiveStepContext = createContext<ActiveStepContextStore | null>(null);

const ActiveStepProvider = ({children}: {children: React.ReactNode}) => {
  const history = useHistory();
  const {uuid, draftStep} = useParams<{uuid: string; draftStep?: string}>();
  const activeStepId = buildSteps.findIndex((step) => step.id === draftStep);
  const activeStep = buildSteps[activeStepId];

  const handleStepChange: HandleStepChangeFn = useCallback(
    (step, replace = false) => {
      if (replace) {
        history.replace(`/builds/uuid/${uuid}/draft/advanced/${step}/`);
      } else {
        history.push(`/builds/uuid/${uuid}/draft/advanced/${step}/`);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uuid]
  );

  const store = {activeStepId, activeStep, handleStepChange};

  return <ActiveStepContext.Provider value={store}>{children}</ActiveStepContext.Provider>;
};

export default ActiveStepProvider;

export const useActiveStep = (isSimpleWorkflow?: boolean) => {
  const activeStepContext = useContext(ActiveStepContext);
  if (isSimpleWorkflow) return {} as any;
  if (!activeStepContext) {
    throw new Error('useActiveStep must be called within a ActiveStepProvider');
  }
  return activeStepContext;
};
