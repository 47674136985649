import {BufferGeometry, InstancedMesh, Plane, Points, Scene, Vector3, WebGLRenderer, Box3, Matrix4, Mesh} from 'three';
import {Axis, BoundingBox} from '../Base3DViewport';
import {View3DViewportParams} from '../View3DViewport';
import {PartPointClouds} from '../PartPointCloud';
import {toggleSimilarityGeometry} from './similarity';
import {ExplicitPoint, sphereGeometry, ThreePoints} from '../types/pointCloudTypes';
import {PointCloudViewportParams} from '../types/params';
import {convertPointsToMesh} from '../loaders/meshLoaders';
import {convertMeshToPoints} from '../loaders/pointLoaders';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';

export const toggleClippingPlane = (
  clippingPlaneEnabled: boolean,
  renderScene: () => void,
  renderer: WebGLRenderer
) => {
  if (!renderer) return;
  renderer.localClippingEnabled = clippingPlaneEnabled;
  renderScene();
};

export const onClippingPlaneChange = (
  sceneBounds: BoundingBox | null,
  clippingPlane: Plane,
  newDirection: Axis,
  newPosition: number,
  newReverse: boolean,
  renderScene: () => void
) => {
  if (!sceneBounds) return;
  const reverseFactor = newReverse ? -1 : 1;
  clippingPlane.constant =
    (sceneBounds.min[newDirection] + sceneBounds.dimensions[newDirection] * newPosition) * reverseFactor;
  clippingPlane.normal = {
    x: new Vector3(1, 0, 0),
    y: new Vector3(0, 1, 0),
    z: new Vector3(0, 0, 1),
  }[newDirection].multiplyScalar(-reverseFactor);
  renderScene();
};

const toggleObjectVisibility = (helperName: string, visible: boolean, renderScene: () => void, scene: Scene) => {
  if (!scene) return;
  const helper = scene.getObjectByName(helperName);
  if (!helper) return;
  helper.visible = visible;
  renderScene();
};

export const toggleAxesHelper = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('axesHelper', visible, renderScene, scene);

export const toggleBuildPlatformHelper = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('gridHelper', visible, renderScene, scene);

export const toggleClippingPlaneHelper = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('planeHelper', visible, renderScene, scene);

export const toggleBoundsLines = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('boundsLines', visible, renderScene, scene);

export const toggleHelpers = (visible: boolean, renderScene: () => void, scene: Scene) =>
  toggleObjectVisibility('helpers', visible, renderScene, scene);

export const toggleUse3DPoints = (
  params: PointCloudViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void,
  geometery?: BufferGeometry
) => {
  pointClouds.forEachAnalysisType((group, analysisType) => {
    if (group.children.length === 0) return; // Check early because group.add will complain otherwise
    const newObjects = group.children.map((object) => {
      if (object.userData.isSimilarityPartPointCloud) return object;
      if (object.userData.isPartModel) return object;

      if (params.use3DPoints && object.type === 'Points')
        return convertPointsToMesh(object as Points, analysisType, params, geometery || sphereGeometry);
      if (!params.use3DPoints && object.type === 'Mesh')
        return convertMeshToPoints(object as InstancedMesh, analysisType, params);
      // If we get here, then somehow we already have the correct type of object
      return object;
    });
    group.remove.apply(group, group.children);
    group.add(...newObjects);
  });
  renderScene();
};

export const rotatePoints = (points: ThreePoints, newRotation?: number) => {
  const rotation = points.rotation;
  points.rotation.set(rotation.x, (newRotation || 0) * (Math.PI / 180), rotation.z);
};

export const rotatePointCloud = (
  params: View3DViewportParams,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  if (params.rotation === undefined || Object.keys(params.rotation).length === 0) return;

  pointClouds.forEachPointCloud((partModel) => {
    if (partModel.userData.isPartModel) {
      if (partModel.userData.partModelUuid in params.rotation!) {
        const newRotation = params.rotation![partModel.userData.partModelUuid];
        const initialRotation = partModel.userData.initialRotation || 0;
        const nextRotation = newRotation - initialRotation;

        rotatePoints(partModel as ThreePoints, nextRotation);
        if (params.centerAllParts) {
          centerPointCloud(partModel as ThreePoints, params.isSimilarity, params.throughPlane);
        } else {
          alignToBoundingBox(partModel as ThreePoints, partModel.userData.bounds);
        }
      }
    } else {
      if (partModel.userData.partUuid in params.rotation!) {
        rotatePoints(partModel as ThreePoints, params.rotation![partModel.userData.partUuid]);

        if (params.centerAllParts) {
          centerPointCloud(partModel as ThreePoints, params.isSimilarity, params.throughPlane);
        }
      }
    }
  });

  renderScene();
};

export const toggleAnalysisTypeVisibility = (
  params: View3DViewportParams,
  setParams: React.Dispatch<React.SetStateAction<View3DViewportParams>>,
  pointClouds: PartPointClouds,
  renderScene: () => void
) => {
  let minDefectAreaSize: number | undefined = undefined;
  let maxDefectAreaSize: number | undefined = undefined;

  pointClouds.forEachAnalysisType((group, analysisType) => {
    if (analysisType === AnalysisType3D.PartModel) return;

    const visible = !!params.selectedAnalysisTypes[analysisType];
    group.visible = visible;
    if (visible && params.defectAreaFilter) {
      group.children.forEach((partModel) => {
        partModel.userData.pointData.forEach((point: ExplicitPoint) => {
          if (point.defectArea) {
            if (!minDefectAreaSize || point.defectArea < minDefectAreaSize) {
              minDefectAreaSize = point.defectArea!;
            }
            if (!maxDefectAreaSize || point.defectArea > maxDefectAreaSize) {
              maxDefectAreaSize = point.defectArea!;
            }
          }
        });
      });
    }
  });

  if (params.defectAreaFilter && minDefectAreaSize !== undefined && maxDefectAreaSize !== undefined) {
    let newMin = minDefectAreaSize as number;
    let newMax = maxDefectAreaSize as number;
    if (params.defectAreaFilter.min && params.defectAreaFilter.min !== params.defectAreaSizes?.min) {
      newMin = Math.max(params.defectAreaFilter.min, newMin);
    }
    if (params.defectAreaFilter.max && params.defectAreaFilter.max !== params.defectAreaSizes?.max) {
      newMax = Math.min(params.defectAreaFilter.max, newMax);
    }

    setParams({
      ...params,
      defectAreaFilter: {min: newMin, max: newMax},
      defectAreaSizes: {min: minDefectAreaSize, max: maxDefectAreaSize},
    });
  }

  toggleSimilarityGeometry(params, pointClouds, renderScene);
  renderScene();
};

export const updateNumPointsDisplayed = (
  numPoints: number,
  pointClouds: PartPointClouds,
  numPointCloudsLoaded: number,
  renderScene: () => void
) => {
  // This assumes all point clouds have the same number of points, not true but good enough
  const pointsPerCloud = numPoints / numPointCloudsLoaded;

  pointClouds.forEachPointCloud((partModel) => {
    if (partModel.userData.isPartModel) return;

    partModel.type === 'Points'
      ? (partModel as ThreePoints).geometry.setDrawRange(0, pointsPerCloud)
      : ((partModel as InstancedMesh).count = Math.min(pointsPerCloud, partModel.userData.pointData.length));
  });

  renderScene();
};

export const getPointCloudDimensions = (points: ThreePoints) => {
  const center = new Vector3();
  const size = new Vector3();

  // Box3().setFromPoints() takes an additional parameter `precise` in an updated version of three.js
  // We need this, but our version doesn't have it, so we mimic the behaviour here.
  // Without it, centering with rotation doesn't always work.
  // https://github.com/mrdoob/three.js/blob/309b00afb6dcbc5e6c58e72f10eaa8d2e8888c83/src/math/Box3.js#L202L229
  const bbox = new Box3();

  points.updateWorldMatrix(false, false);
  const vector = new Vector3();
  const position = points.geometry.attributes.position;

  for (let i = 0, l = position.count; i < l; i++) {
    vector.fromBufferAttribute(position, i).applyMatrix4(points.matrixWorld);
    bbox.expandByPoint(vector);
  }

  bbox.getCenter(center);
  bbox.getSize(size);

  return {size, center};
};

export const centerPointCloud = (mesh: ThreePoints, isSimilarity: boolean = false, throughPlane: boolean = false) => {
  mesh.geometry.center();

  const {size, center} = getPointCloudDimensions(mesh);

  if (isSimilarity) {
    mesh.position.sub(center);
    mesh.position.y = mesh.position.y + 0.1 + size.y / 2;
    mesh.position.x = mesh.position.x + size.x / 2;
    mesh.position.z = mesh.position.z - size.z / 2;
  } else if (throughPlane) {
    mesh.position.sub(center);
  } else {
    mesh.position.sub(center);
    // Put on base of plate instead of through the plate
    mesh.position.y = mesh.position.y + size.y / 2;
  }
};

export const invertZAndY = (mesh: Mesh, initialRotation: number) => {
  const geometry = mesh.geometry;
  geometry.applyMatrix4(new Matrix4().makeRotationX(-Math.PI / 2));
  if (initialRotation) {
    geometry.applyMatrix4(new Matrix4().makeRotationY((initialRotation || 0) * (Math.PI / 180)));
  }
};

// Function to scale a mesh to a specified bounding box
export const scaleMeshToBoundingBox = (mesh: Mesh | ThreePoints, boundingBox: BoundingBox) => {
  // Compute the bounding box of the mesh
  mesh.geometry.center();

  const meshBoundingBox = new Box3().setFromObject(mesh);
  const meshCenter = new Vector3();
  meshBoundingBox.getCenter(meshCenter);

  // Calculate the dimensions of the mesh
  const meshSize = new Vector3();
  meshBoundingBox.getSize(meshSize);

  // Calculate the scaling factor (just do Y)
  const scale = boundingBox.dimensions.y / meshSize.y;

  // Apply the scaling factor to the mesh
  mesh.scale.set(scale, scale, scale);
};

// Function to center a mesh to a specified bounding box
export const alignToBoundingBox = (mesh: Mesh | ThreePoints, boundingBox: BoundingBox) => {
  // Calculate the center of the bounding box
  const boundingBoxCenter = new Vector3(
    boundingBox.min.x + boundingBox.dimensions.x / 2,
    boundingBox.min.y + boundingBox.dimensions.y / 2,
    boundingBox.min.z + boundingBox.dimensions.z / 2
  );

  // Recompute the bounding box of the scaled mesh
  const {center: meshCenter} = getPointCloudDimensions(mesh as ThreePoints);

  // Calculate the offset
  const offset = new Vector3().subVectors(boundingBoxCenter, meshCenter);

  // Apply the offset to the mesh position
  mesh.position.add(offset);
};
