import React from 'react';
import {Box, Button, CircularProgress, Typography} from '@material-ui/core';
import {useHistory, useParams} from 'react-router-dom';
import {useSimilarityReportStoreActions} from '../../../store/actions/index';
import {RootState} from '../../../store/reducers/index';
import {ArrowBack, ArrowForward, Check, CheckCircle} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import ConditionalTooltip from '../../../components/atoms/Texts/ConditionalTooltip';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import VerticalButton from '../../../components/atoms/VerticalButton';

export const CancelButton = () => {
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();

  const handleCancelClicked = () => history.push('/reports/similarity/');

  if (isSmallScreen) {
    return (
      <VerticalButton onClick={handleCancelClicked} color="secondary">
        <ArrowBack />
        <Typography variant="caption">Cancel</Typography>
      </VerticalButton>
    );
  }

  return (
    <Button variant="outlined" onClick={handleCancelClicked} color="primary" startIcon={<ArrowBack />}>
      Cancel
    </Button>
  );
};

export const ToTargetPartsPageButton = () => {
  const history = useHistory();
  const {sourcePartUuids, rotations} = useParams<{
    step?: string;
    sourcePartUuids?: string;
    rotations?: string;
  }>();
  const isSmallScreen = useSmallScreenSize();
  const sourceParts = useSelector((state: RootState) => state.similarityReportStore.draftSimilarityReport.sourceParts);
  const {setCurrentlyViewingPart} = useSimilarityReportStoreActions();

  const handleNavigation = () => {
    setCurrentlyViewingPart(undefined);
    const queryString = [sourcePartUuids, rotations].filter((query) => !!query).join('/');

    history.push(`/reports/similarity/create/targetPartSelection/${queryString}`);
  };
  if (isSmallScreen) {
    return (
      <ConditionalTooltip hideTooltip={sourceParts?.length !== 0} tooltip="Please select source part first">
        <VerticalButton onClick={handleNavigation} color="secondary">
          <ArrowForward />
          <Typography variant="caption">Select targets</Typography>
        </VerticalButton>
      </ConditionalTooltip>
    );
  }

  return (
    <ConditionalTooltip hideTooltip={sourceParts?.length !== 0} tooltip="Please select source part first">
      <Button
        variant="contained"
        onClick={handleNavigation}
        color="primary"
        style={{marginLeft: '12px'}}
        endIcon={<ArrowForward />}
        disabled={sourceParts?.length === 0}
      >
        Select target Parts
      </Button>
    </ConditionalTooltip>
  );
};

export const ToSourcePartsPageButton = () => {
  const history = useHistory();
  const {sourcePartUuids, rotations} = useParams<{
    step?: string;
    sourcePartUuids?: string;
    rotations?: string;
  }>();
  const isSmallScreen = useSmallScreenSize();
  const isCreating = useSelector((state: RootState) => state.similarityReportStore.isCreatingReport);

  const {setCurrentlyViewingPart} = useSimilarityReportStoreActions();

  const handleNavigation = () => {
    setCurrentlyViewingPart(undefined);
    const queryString = [sourcePartUuids, rotations].filter((query) => !!query).join('/');

    history.push(`/reports/similarity/create/sourcePartSelection/${queryString}`);
  };

  if (isSmallScreen) {
    return (
      <VerticalButton onClick={handleNavigation} color="secondary">
        <ArrowBack />
        <Typography variant="caption">Change sources</Typography>
      </VerticalButton>
    );
  }

  return (
    <Button
      variant="outlined"
      onClick={handleNavigation}
      disabled={isCreating}
      color="primary"
      startIcon={<ArrowBack />}
    >
      Change Source Parts
    </Button>
  );
};

export const SaveAndGenerateButton = () => {
  const isSmallScreen = useSmallScreenSize();
  const history = useHistory();
  const isCreating = useSelector((state: RootState) => state.similarityReportStore.isCreatingReport);
  const hasSelectedTargets = useSelector(
    (state: RootState) => !!state.similarityReportStore.draftSimilarityReport.targetParts.length
  );
  const isSourcePartSelected = useSelector(
    (state: RootState) => state.similarityReportStore.draftSimilarityReport.sourceParts.length !== 0
  );

  const {createSimilarityReport} = useSimilarityReportStoreActions();

  const onGenerate = async () => {
    createSimilarityReport(history);
  };

  if (isSmallScreen) {
    return (
      <ConditionalTooltip
        hideTooltip={hasSelectedTargets && isSourcePartSelected}
        tooltip={
          isSourcePartSelected ? 'Please select at least one target part' : 'Please select at least one source part'
        }
      >
        <VerticalButton onClick={onGenerate} color="secondary" disabled={!hasSelectedTargets || isCreating}>
          {isCreating ? <CircularProgress size={15} /> : <CheckCircle />}
          <Typography variant="caption">Generate Report</Typography>
        </VerticalButton>
      </ConditionalTooltip>
    );
  }

  return (
    <ConditionalTooltip
      hideTooltip={hasSelectedTargets && isSourcePartSelected}
      tooltip={isSourcePartSelected ? 'Please select at least one target part' : 'Please select a source part'}
    >
      <Button
        variant="contained"
        color="primary"
        style={{marginLeft: '12px'}}
        onClick={onGenerate}
        disabled={!hasSelectedTargets || isCreating}
        endIcon={isCreating ? <CircularProgress size={20} /> : <Check />}
      >
        Save and Generate Report
      </Button>
    </ConditionalTooltip>
  );
};

const NavigationButtons = () => {
  const {step} = useParams<{step?: string}>();

  return (
    <Box display="flex" justifyContent="flex-end" marginTop="24px">
      {step === 'sourcePartSelection' && (
        <>
          <CancelButton />
          <ToTargetPartsPageButton />
        </>
      )}
      {step === 'targetPartSelection' && (
        <>
          <ToSourcePartsPageButton />
          <SaveAndGenerateButton />
        </>
      )}
    </Box>
  );
};

export default NavigationButtons;
