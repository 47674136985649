import React from 'react';
import {TableCell, TableRow, TableBody} from '@material-ui/core';
import {IMachineParameter} from '@common/api/models/devices/machines/machineParameters/IMachineParameter';
import {CollapseTable} from '../ConfirmationStep';
import inflection from 'inflection';
import {renderDateTimeString} from '../../../../../utils/string';
import EllipsisTextWithTooltip from '../../../../../components/atoms/Texts/EllipsisTextWithTooltip';

const MachineParametersCollapse = ({machineParameters}: {machineParameters: IMachineParameter}) => {
  if (!machineParameters) return <></>;

  const {data, registered, genericNotes} = machineParameters;

  return (
    <CollapseTable>
      <colgroup>
        <col span={1} width="174px" />
        <col span={1} width="auto" />
      </colgroup>
      <TableBody>
        <TableRow>
          <TableCell>
            <b>Created On</b>
          </TableCell>
          <TableCell>{renderDateTimeString('short', registered)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <b>Notes</b>
          </TableCell>
          <TableCell>{genericNotes}</TableCell>
        </TableRow>
        {Object.entries(data).map(([key, value]) => {
          return (
            <TableRow key={`machine-param-confirmation-row-${key}`}>
              <TableCell>
                <EllipsisTextWithTooltip>
                  <b>{inflection.humanize(inflection.underscore(key))}</b>
                </EllipsisTextWithTooltip>
              </TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CollapseTable>
  );
};

export default MachineParametersCollapse;
