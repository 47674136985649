import Konva from 'konva';
import {useEffect, useReducer, useState} from 'react';
import {useFullScreenHandle} from 'react-full-screen';
import {Stage} from 'react-konva';
import {useSelector} from 'react-redux';

import {usePartStoreActions} from '../../../../store/actions';
import {RootState} from '../../../../store/reducers';
import {ImageOverlays, SingleImageViewportProps} from './SingleImageViewport';
import {setStageScaleIfChanged} from './utils';
import {useContainerWidth} from '../../../../utils/utilHooks';

interface ViewportPosition {
  x: number;
  y: number;
  scale: number;
  zoom: number;
  viewportFit: boolean;
}

export type SetPositionFnReturn = ViewportPosition;
export type SetPositionFn = (newValues: Partial<ViewportPosition>) => SetPositionFnReturn;
export type GetPositionFn = () => ViewportPosition;
export type OnPositionChangeFn = (newValues: Partial<ViewportPosition>) => void;

export const useViewportStage = (props: SingleImageViewportProps) => {
  // const [container, setContainer] = useState<HTMLDivElement | null>();
  const [viewportHeight, setViewportHeight] = useState(500);
  const [oldStageWidth, setOldStageWidth] = useState(1000);
  const [fullScreen, setFullScreen] = useState(false);
  const fullScreenHandle = useFullScreenHandle();

  const [stageHeight, setStageHeight] = useState(50);
  const {
    width: stageWidth = 100,
    containerRef: container,
    setContainerRef: setContainer,
    hasInitialized,
  } = useContainerWidth();

  const propsInitialPosition = props.getInitialPosition?.() ?? {
    x: 0,
    y: 0,
    scale: 1,
    zoom: 1,
    viewportFit: true,
  };
  const initialPosition = {
    ...propsInitialPosition,
    viewportFit: props.isDefectViewport ? false : propsInitialPosition.viewportFit,
  };

  const [viewportPosition, setViewportPosition] = useState<ViewportPosition>(initialPosition);

  const [previousOverlays, setPreviousOverlays] = useState<ImageOverlays[]>([]);
  // @ts-ignore
  const [stage, setStage] = useState<Stage | null>(null);
  const [initialized] = useState(new Date());

  const [forcedSetNewImage, forceSetNewImage] = useReducer((x) => x + 1, 0);

  /**
   * Calculate the available width and height within the browser.
   */
  const checkSize = () => {
    const viewportHeight = window.innerHeight;
    setViewportHeight(viewportHeight);
  };

  // @ts-ignore
  const onStageRef = (stage: Stage | null) => {
    if (props.stageRef) {
      props.stageRef(stage);
    }
    setStage(stage);
  };

  const updatePosition = (newPosition: Partial<ViewportPosition>) => {
    props.onPositionChange(newPosition);
    setViewportPosition((oldPosition) => ({...oldPosition, ...newPosition}));
  };

  const setZoomScaleOverride = (zoom: number) => updatePosition({zoom});

  const setIsViewportFit = (viewportFit: boolean) => updatePosition({viewportFit});

  useEffect(() => {
    if (!props.onPositionChange || !stage || (!props.imageWidth && !props.imageWidthPixels)) {
      return;
    }

    const xHandle = (e: any) => updatePosition({x: e.newVal});
    const yHandle = (e: any) => updatePosition({y: e.newVal});
    const sXHandle = (e: any) => updatePosition({scale: e.newVal});
    const sYHandle = (e: any) => updatePosition({scale: e.newVal});

    stage.getStage().on('xChange', xHandle);
    stage.getStage().on('yChange', yHandle);
    stage.getStage().on('scaleXChange', sXHandle);
    stage.getStage().on('scaleYChange', sYHandle);

    return () => {
      stage.getStage().off('xChange', xHandle);
      stage.getStage().off('yChange', yHandle);
      stage.getStage().off('scaleXChange', sXHandle);
      stage.getStage().off('scaleYChange', sYHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stage, props.imageWidthPixels, props.onPositionChange]);

  // The above useEffect calls this setPosition function for all linked viewports (it's attached via a ref in MultiViewer)
  const setPosition: SetPositionFn = (newPosition) => {
    if (!stage) {
      return {x: 0, y: 0, scale: 0, zoom: 0, viewportFit: true};
    }

    if (newPosition.x !== undefined) stage.getStage().setAttr('x', newPosition.x);
    if (newPosition.y !== undefined) stage.getStage().setAttr('y', newPosition.y);
    if (newPosition.scale !== undefined) setStageScaleIfChanged(stage.getStage(), newPosition.scale);

    if (Object.keys(newPosition).length > 0) {
      stage.getStage().batchDraw();
    }
    if (newPosition.viewportFit !== undefined && newPosition.viewportFit !== viewportPosition.viewportFit) {
      setViewportPosition((oldPosition) => ({...oldPosition, ...newPosition}));
    }

    return {
      x: stage.getStage().getAttr('x'),
      y: stage.getStage().getAttr('y'),
      scale: stage.getStage().getAttr('scaleX'),
      zoom: newPosition.zoom || viewportPosition.scale || 1,
      viewportFit: newPosition.viewportFit ?? viewportPosition.viewportFit,
    };
  };

  if (props.setPositionFnRef) {
    props.setPositionFnRef.current = setPosition;
  }

  /**
   *  Triggered when height/width is changed or when stage has been altered.
   */
  useEffect(() => {
    if (!stage) return;

    // Calculate stage height and width
    // FIXME: Work out magic numbers below here.
    let heightLimit = viewportHeight - 380;
    let _stageHeight = Math.min(heightLimit, (stageWidth / 16) * 9);
    _stageHeight = Math.max(_stageHeight, 100);

    if (props.height) {
      _stageHeight = props.height;
    }

    if (fullScreen) {
      _stageHeight = viewportHeight;
    }

    let x = stage.getStage().getAttr('x');
    let y = stage.getStage().getAttr('y');
    let scale = stage.getStage().getAttr('scaleX');
    const oldTransform = new Konva.Transform().translate(x, y).scale(scale, scale);

    const stageCenter = oldTransform
      .copy()
      .invert()
      .point({x: oldStageWidth / 2, y: stageHeight / 2});

    const scaleIncrease = 1; //_stageWidth / stageWidth;
    const newScale = scale * scaleIncrease;
    const newTransform = new Konva.Transform().translate(x, y).scale(newScale, newScale);
    const newStageCenterLocation = newTransform.copy().point(stageCenter);

    const newX = x - (newStageCenterLocation.x - stageWidth / 2);
    const newY = y - (newStageCenterLocation.y - _stageHeight / 2);

    const timeSinceInitialisation = new Date().getTime() - initialized.getTime();
    if (timeSinceInitialisation > 1000) {
      setPosition({x: newX, y: newY, scale: newScale});
    }

    if (props.onChangeDims) {
      props.onChangeDims(stageWidth, _stageHeight);
    }

    // remove margin
    if (props.isDefectViewport && !props.isFitToWrapper) {
      setStageHeight(_stageHeight - 30);
    } else {
      setStageHeight(_stageHeight);
    }
    setOldStageWidth(stageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportHeight, stageWidth, fullScreen, props.height, stage]);

  const setNewImage = () => {
    if (!props.isDefectViewport) {
      fitToView(false, forcedSetNewImage === 1 && !props.alone2d);
    }

    stage && stage.getStage().batchDraw();

    props.isDefectViewport && setZoomPosition();
    props.stopLoading && props.stopLoading();
  };

  useEffect(() => {
    if (forcedSetNewImage > 0 && hasInitialized) {
      setNewImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcedSetNewImage, hasInitialized]);

  // The below useEffect can ignore if the src images are the same
  const currentOverlaysString = props.overlays.map((o) => o.image?.currentSrc).join(',');
  const previousOverlaysString = previousOverlays.map((o) => o.image?.currentSrc).join(',');

  useEffect(() => {
    if (props.overlays.length === 0 && props.imageNotFound) {
      forceSetNewImage();
      return;
    }
    if (!stage || props.overlays.length === 0 || currentOverlaysString === previousOverlaysString) {
      return;
    }

    const allLoadingPromises: Array<Promise<void>> = [];

    for (const overlay of props.overlays) {
      if (!overlay.image) {
        continue;
      }

      if (overlay.image.complete || overlay.id === 'colourMap') {
        // OK
      } else {
        allLoadingPromises.push(
          new Promise((res, _rej) => {
            overlay.image!.addEventListener('load', () => res());
          })
        );
      }
    }

    Promise.all(allLoadingPromises).then(() => forceSetNewImage());

    if (
      props.overlays.every((overlay) => overlay.image?.complete || overlay.id === 'colourMap') ||
      props.overlays.length !== previousOverlays.length
    ) {
      setPreviousOverlays(props.overlays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stage,
    currentOverlaysString,
    previousOverlaysString,
    props.imageHeightPixels,
    // eslint-disable-next-line
    props.defectData?.boundingBox,
  ]);

  const fitToView = (
    isClickedFitToView?: boolean | undefined,
    isFirstRenderOfAdditionalViewport?: boolean | undefined
  ) => {
    const width = props.imageWidthPixels * props.mmPerPixel;
    const height = props.imageHeightPixels * props.mmPerPixel;

    const newScale = Math.min((stageWidth / width) as number, stageHeight / height);

    const newX = (stageWidth - width * newScale) / 2;
    const newY = (stageHeight - height * newScale) / 2;

    // When adding a new view, the viewport container dimensions will change and hence the scale
    // Here on first render of the new viewport, we keep the original scale but adjust the zoom level,
    // this accounts for the next render which will use a new scale combined with the new zoom we set.
    const newZoom = isFirstRenderOfAdditionalViewport
      ? (viewportPosition.zoom * viewportPosition.scale) / (viewportPosition.zoom * newScale)
      : viewportPosition.zoom * newScale;

    props.onPositionChange({...viewportPosition, zoom: newZoom});

    if ((!isNaN(newX) && !isNaN(newY) && !isNaN(newScale) && viewportPosition.viewportFit) || isClickedFitToView) {
      setPosition({x: newX, y: newY, scale: newScale, zoom: newZoom});
    }
  };

  useEffect(() => {
    // For some reason container's width changes on layer change. The width difference around 12-15px.
    const widthDiff = stageWidth && Math.abs(oldStageWidth - stageWidth);
    if (widthDiff && (widthDiff < 10 || widthDiff > 18)) {
      props.fitToScreenOnStart && viewportPosition.viewportFit && fitToView();
      checkSize();
      window.addEventListener('resize', checkSize);
      return () => {
        window.removeEventListener('resize', checkSize);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  useEffect(() => {
    if (hasInitialized && props.fitToScreenOnStart && viewportPosition.viewportFit) {
      fitToView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitialized]);

  useEffect(() => {
    checkSize();
    if (props.isDefectViewport) {
      setZoomPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentGridColumns, container]);

  const setZoomPosition = () => {
    if (props.defectData && props.defectData.boundingBox.length === 4) {
      // setting the scale to some default value before zoom in
      stage?.getStage().setAttr('scaleX', 39);
      stage?.getStage().setAttr('scaleY', 39);

      // get width
      const defectWidth = props.defectData.boundingBox[2];
      const defectHeight = props.defectData.boundingBox[3];
      const width = props.imageWidthPixels * props.mmPerPixel;
      const height = props.imageHeightPixels * props.mmPerPixel;

      let scale: number;
      if (defectWidth >= defectHeight) {
        scale = (width / defectWidth) * 10;
      } else {
        scale = (height / defectHeight) * 10;
      }

      // expand image before setting x and y
      stage?.getStage().setAttr('scaleX', scale);
      stage?.getStage().setAttr('scaleY', scale);
      updatePosition({scale});

      // calculate x and y based on new scale
      const centroidX = props.defectData.boundingBox[0] + props.defectData.boundingBox[2] / 2;
      const centroidY = props.defectData.boundingBox[1] + props.defectData.boundingBox[3] / 2;

      let temp1 = width * (centroidX / props.defectData.widthPx);
      let temp2 = height * (centroidY / props.defectData.heightPx);

      const newX = scale * temp1 - stageWidth / 2;
      const newY = scale * temp2 - stageHeight / 2;

      // set x and y
      // negative because we are dragging the image towards left side
      setPosition({x: -1 * newX, y: -1 * newY});
    }
  };

  const [mouseOn, setMouseOn] = useState(false);

  const availableAnalysisTypes = props.overlays.map((overlay) => overlay.id);
  const availableAnalysisTypesString = JSON.stringify(availableAnalysisTypes);

  // When the defect statistic viewport changed from the same stat and layer but different part.
  useEffect(() => {
    if (props.isDefectViewport && currentOverlaysString === previousOverlaysString) {
      forceSetNewImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defectData?.boundingBox]);

  useEffect(() => {
    // Required so that the zoom gets set in the defects viewport
    if (props.isDefectViewport) {
      setZoomPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAnalysisTypesString]);

  const partStoreActions = usePartStoreActions();
  const parts = useSelector((s: RootState) =>
    Object.values(s.partStore.byId).filter((part) => part.buildUuid === props.buildUuid)
  );

  useEffect(() => {
    if (props.buildUuid) {
      partStoreActions.ensureConsistent({
        buildUuid: props.buildUuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.buildUuid]);

  return {
    setContainer,
    onStageRef,
    stage,

    fullScreen,
    fullScreenHandle,
    setFullScreen,
    checkSize,

    stageHeight,
    stageWidth: props.isDefectViewport && !props.isFitToWrapper ? stageWidth - 30 : stageWidth,

    viewportPosition,
    setViewportPosition,
    setZoomScaleOverride,

    fitToView,
    setIsViewportFit,

    mouseOn,
    setMouseOn,

    previousOverlays,
    availableAnalysisTypes,

    parts,
  };
};
