import * as React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import styled from 'styled-components';

import {BatchPageProps} from '../../../pages/materials/batchPages';
import PieChart from '../../atoms/Charts/PieChart';
import {ageCompositionToMap, averageAge, maxAge} from '@common/api/models/materials/batches/Batch.utils';

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
  text-align: center;
`;

function BatchUsageCompositionWidget(props: BatchPageProps) {
  const ages = ageCompositionToMap(props.batch.ageComposition);
  const virginMaterialWeight = ages.get(0) || 0;
  const data = [virginMaterialWeight, 1 - virginMaterialWeight];
  const average = averageAge(props.batch.ageComposition);
  const max = maxAge(props.batch.ageComposition);

  const chartSize = 168;
  return (
    <Card className="rt-batch-condition-comp">
      <CardHeader title={`Condition composition of "${props.batch.name}"`} />
      <CardContent>
        <ChartWrapper>
          <PieChart labels={['Virgin', 'Used']} data={data} height={chartSize} width={chartSize} />
        </ChartWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usage</TableCell>
              <TableCell>Composition (kg)</TableCell>
              <TableCell>%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Virgin Material</TableCell>
              <TableCell>{(virginMaterialWeight * props.batch.quantity).toFixed(2)}</TableCell>
              <TableCell>{(virginMaterialWeight * 100).toFixed(1)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Used Material</TableCell>
              <TableCell>{((1 - virginMaterialWeight) * props.batch.quantity).toFixed(2)}</TableCell>
              <TableCell>{((1 - virginMaterialWeight) * 100).toFixed(1)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Average Usage</b>
              </TableCell>
              <TableCell>{Number(average).toFixed(2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Max. Usage</b>
              </TableCell>
              <TableCell>{Number(max.percentageAt).toFixed(2) + '% at ' + max.max + ' uses'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default BatchUsageCompositionWidget;
