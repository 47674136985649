import {Plane} from 'three';

export interface PointCloudViewportParams {
  pointLimit: number;
  pointSize: number;
  modelOpacity: number;
  overridePointColour: boolean;
  overrideColour: string;

  defectAreaFilter?: {min?: number; max?: number};
  defectAreaSizes?: {min?: number; max?: number};

  use3DPoints: boolean;
  isTransparent?: boolean;

  clippingPlane: Plane;
}

export const initialPointCloudParams: PointCloudViewportParams = {
  pointLimit: 5e5,
  pointSize: 0.2,
  modelOpacity: 0.5,
  overridePointColour: false,
  overrideColour: '#bfb4b4',
  defectAreaFilter: {},
  defectAreaSizes: {},

  use3DPoints: false,

  clippingPlane: new Plane(),
};
