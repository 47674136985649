import React from 'react';
import {Grid, Typography, Box} from '@material-ui/core';
import {Control, useWatch} from 'react-hook-form';
import {
  DefectNotificationType,
  IDefectNotificationConditions,
  ConditionGroup as IConditionGroup,
} from '@common/api/models/builds/data/defects/IDefectNotifications';
import AddCondition from './AddCondition';
import {FloatFormInput, SelectFormInput} from './NotificationFormInput';
import DisplayAndChangeOperator from './DisplayAndChangeOperator';
import ConditionGroup from './ConditionGroup';

function NotificationForm({
  control,
  errors,
  reset,
}: {
  control: Control<IDefectNotificationConditions>;
  errors: any;
  reset: (newForm: IDefectNotificationConditions) => void;
}) {
  const conditionGroups: Array<IConditionGroup> | undefined = useWatch({name: 'conditionGroups', control: control});

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>Notify me</Typography>
      </Grid>

      <Grid item xs={12}>
        <Box width="100%" display="flex" alignItems="center">
          <Typography style={{width: '104px'}}>when</Typography>
          <SelectFormInput
            name="notificationType"
            label="The build / A part"
            control={control}
            errors={errors}
            options={Object.values(DefectNotificationType).map((partOrBuild) => [
              partOrBuild,
              `${partOrBuild === 'part' ? 'A' : ' The'} ${partOrBuild}`,
            ])}
          />
        </Box>
        <Box width="100%" display="flex" alignItems="center" margin="12px 0px">
          <Typography>meets the following conditions</Typography>
        </Box>
        <Box width="100%" display="flex" alignItems="center">
          <Typography style={{width: '104px'}}>for</Typography>
          <FloatFormInput name="consecutiveLayers" label="Consecutive layer(s)" control={control} errors={errors} />
        </Box>
        <Box width="100%" display="flex" alignItems="center" margin="12px 0px">
          <Typography>consecutive layer(s):</Typography>
        </Box>
      </Grid>

      {conditionGroups?.map((_condition, index) => {
        return (
          <React.Fragment key={`conditionGroups[${index}]`}>
            <ConditionGroup
              path={`conditionGroups[${index}]`}
              control={control}
              errors={errors}
              reset={reset}
              rootIndex={index}
            />
            {index !== conditionGroups.length - 1 && (
              <DisplayAndChangeOperator path="" control={control} reset={reset} />
            )}
          </React.Fragment>
        );
      })}

      <AddCondition path="" control={control} reset={reset} />
    </Grid>
  );
}

export default NotificationForm;
