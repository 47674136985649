import React from 'react';
import {Button} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import {IMachine} from '@common/api/models/devices/machines/IMachine';
import {getMachineCalibFile} from '@common/api/models/devices/machines/Machine.utils';
import {downloadUrl} from '../../../../../utils/webtools';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import ConditionalTooltip from '../../../../../components/atoms/Texts/ConditionalTooltip';

export default function DownloadCalibration({machine}: {machine: IMachine}) {
  const isSmallScreen = useSmallScreenSize();
  const calibFile = getMachineCalibFile(machine);

  const handleDownload = () => {
    if (calibFile) {
      const path = `${window.location.origin}/files/${calibFile}`;
      downloadUrl(path, calibFile);
    }
  };

  const machineModelSet = !!(machine?.manufacturer && machine?.model);
  const calibFileExists = !!calibFile;

  return (
    <ConditionalTooltip
      hideTooltip={machineModelSet && calibFileExists}
      tooltip={
        !machineModelSet ? 'Cannot download calibration file, machine model not set.' : 'Calibration file unavailable'
      }
    >
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleDownload()}
        fullWidth={isSmallScreen}
        disabled={!machine || !machineModelSet || !calibFileExists}
        style={isSmallScreen ? {marginTop: '12px'} : {}}
        endIcon={<GetApp />}
      >
        Download Calibration File
      </Button>
    </ConditionalTooltip>
  );
}
