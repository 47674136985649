import React from 'react';
import {Typography} from '@material-ui/core';
import AntSwitch from '../../../atoms/Switches/AntSwitch';
import {FadingBox} from '../FadingComponents';

function HideOverlays({
  mouseOn,
  hideOverlays,
  setHideOverlays,
  left,
  top,
  disabled,
}: {
  mouseOn: boolean;
  hideOverlays: boolean;
  setHideOverlays: (hideOverlays: boolean) => void;
  left: number;
  top: number;
  disabled: boolean;
}) {
  return (
    <FadingBox
      className={(mouseOn === false && 'fade') || undefined}
      key={`crosshair-toggle-${left}-${top}`}
      position="absolute"
      left={left}
      top={top}
      display="flex"
      alignItems="center"
    >
      <Typography noWrap style={{marginRight: '8px', color: 'white'}}>
        Hide Overlays:
      </Typography>

      <AntSwitch
        size="small"
        checked={hideOverlays}
        disabled={disabled}
        onClick={() => setHideOverlays(!hideOverlays)}
      />
    </FadingBox>
  );
}

export default HideOverlays;
