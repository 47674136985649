import React, {useState, useEffect} from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  Typography,
  Fab,
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

import {validator} from '@common/api/types';
import {
  validateMaterialName,
  validateManufacturedDate,
  validateReceivedDate,
  validateQuantityReceived,
} from '@common/api/models/materials/Material.validation';
import {IMaterial} from '@common/api/models/materials/IMaterial';

import {useBatchStoreActions, useMaterialAttachmentStoreActions, useMaterialStoreActions} from '../../store/actions';
import {RootState} from '../../store/reducers';
import ViewEditFieldRow from '../../components/atoms/ViewEditFieldRow';
import {materialsByUuidPATCH} from '../../api/ajax/materials';
import {BatchesTable} from '../../components/molecules/Table/BatchesTable';
import CircularProgressWithLabel from '../../components/atoms/CircularProgressWithLabel';
import {MaterialSupplyWidget} from '../../components/molecules/Dashboard/MaterialSupplyWidget';
import BatchAddMaterialModal from '../../components/molecules/Forms/BatchAddMaterialModal';
import {AttachmentDownloadTable} from '../../components/molecules/Uploader/AttachmentDownloadTable';
import {renderDateString} from '../../utils/string';
import {BaseTour} from '../../components/molecules/BaseTour';
import {CustomHelpIcon} from './batchPages/ViewBatch';
import MaterialsMultipartUpload from './MaterialsMultipartUpload';
import {CreateBatchModal} from './CreateBatchModal';
import Header from '../../components/organisms/Header';
import LoadingPage from '../../components/organisms/LoadingPage';

const tutorialSteps = [
  {
    selector: '',
    content: "Let's start a tutorial about the Material workflow and functionalities.",
    style: {
      padding: '20px 35px 20px 35px',
    },
  },
  {
    selector: '.rt-general-info',
    content: 'The General Info section displays material details and can be updated anytime using the edit button.',
    style: {
      padding: '20px 35px 20px 35px',
    },
  },
  {
    selector: '.rt-material-supply',
    content: 'The Material supply widget depicts the quantity remaining.',
    style: {
      padding: '20px 35px 20px 35px',
    },
  },
  {
    selector: '.rt-batch-actions',
    content:
      'These buttons can be used to create a new batch from the current material, or to add an amount to an existing batch.',
    style: {
      padding: '20px 35px 20px 35px',
    },
  },
];

export function ViewMaterial() {
  const {uuid} = useParams<{uuid: string}>();

  const materialStoreActions = useMaterialStoreActions();
  const batchStoreActions = useBatchStoreActions();
  const materialAttachmentActions = useMaterialAttachmentStoreActions();

  const [addMaterialOpen, setAddMaterialOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [numFilesUploading, setNumFilesUploading] = useState(0);
  const [addBatchModalOpen, setAddBatchModalOpen] = useState(false);

  useEffect(() => {
    materialStoreActions.ensureConsistent({});
    // TODO: by material? Yeah nah.
    batchStoreActions.ensureConsistent({current: true});
    if (uuid) {
      materialAttachmentActions.ensureConsistent({materialUuid: uuid});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const material = useSelector((state: RootState) => (uuid ? state.materialStore.byId[uuid] : undefined)) as
    | IMaterial
    | undefined;
  const materialStore = useSelector((state: RootState) => state.materialStore);
  const batchStore = useSelector((state: RootState) => state.batchStore);
  const materialAttachmentStore = useSelector((state: RootState) => state.materialAttachmentStore);

  const visibleBatches = Object.values(batchStore.byId).filter((f) => f.current);
  const certificates = Object.values(materialAttachmentStore.byId).filter((ma) => ma.materialUuid === uuid);

  if (!material) {
    return <LoadingPage loadingText="Loading Material" />;
  }

  const handleNewBatchClick = () => {
    setAddBatchModalOpen(true);
  };

  const handleAddToBatchClick = () => {
    setAddMaterialOpen(true);
  };

  return (
    <React.Fragment>
      <CreateBatchModal
        isModalOpen={addBatchModalOpen}
        closeModal={() => setAddBatchModalOpen(false)}
        initialMaterialUuid={uuid}
      />
      <Header
        helmet={`${material.name} Material`}
        title={material.name}
        breadcrumbs={[{title: 'Materials', path: '/materials/'}, material.name]}
        endAdornment={
          <ButtonGroup className="rt-batch-actions">
            <Button variant={'outlined'} color={'primary'} onClick={handleAddToBatchClick}>
              Add to batch
            </Button>
            <Button variant={'contained'} color={'primary'} onClick={handleNewBatchClick}>
              New Batch
            </Button>
          </ButtonGroup>
        }
      />

      <BaseTour steps={tutorialSteps} isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />
      <div
        style={{
          position: 'fixed',
          right: '10px',
          bottom: '65px',
          display: 'inline-block',
          zIndex: 1000,
        }}
      >
        <Fab
          component="span"
          aria-label="help"
          onClick={() => {
            setIsTourOpen(true);
          }}
          style={{
            boxShadow: 'none',
            backgroundColor: 'rgb(247,249,252)',
          }}
        >
          <CustomHelpIcon fontSize="large" color="primary" />
        </Fab>
      </div>

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="rt-material-supply">
              <MaterialSupplyWidget material={material} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title={`Batches containing "${material.name}"`} />
                <CardContent>
                  <BatchesTable
                    batches={visibleBatches.filter((b) =>
                      b.materialComposition.find((m) => m.materialUuid === material.uuid)
                    )}
                    materialsByUuid={materialStore.byId}
                    options={{selection: false}}
                    exclude={['quantity']}
                    customRender={{
                      materials: (data) => {
                        return (
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <Grid container direction={'column'} justifyContent={'center'} style={{height: '100%'}}>
                                <Grid item>
                                  <div style={{height: 40}}>
                                    <CircularProgressWithLabel
                                      value={
                                        data.batch.materialComposition.find((m) => m.materialUuid === material.uuid)!
                                          .weight * 100
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={8}>
                              <Grid
                                container
                                direction={'column'}
                                justifyContent={'center'}
                                style={{height: '100%', marginLeft: '5px'}}
                              >
                                <Grid item>
                                  <Typography style={{margin: 'auto'}}>{material.name}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={7}>
          <Card className="rt-general-info">
            <CardHeader title={'General Info'} />
            <CardContent>
              <Table size={'small'}>
                <TableBody>
                  <ViewEditFieldRow
                    type="text"
                    name="Name"
                    value={material.name}
                    validateFn={(value) =>
                      validator(validateMaterialName, value, (message) => toast(message, {type: 'error'}))
                    }
                    saveValue={(name) => materialsByUuidPATCH(material.uuid, {name})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Description"
                    value={material.description}
                    saveValue={(description) => materialsByUuidPATCH(material.uuid, {description})}
                  />

                  <ViewEditFieldRow
                    type="date"
                    name="Manufactured date"
                    value={material.manufactured}
                    valueText={renderDateString('short', material.manufactured)}
                    validateFn={(value) =>
                      validator(validateManufacturedDate, value, (message) => toast(message, {type: 'error'}))
                    }
                    required
                    saveValue={(manufactured) => materialsByUuidPATCH(material.uuid, {manufactured})}
                  />

                  <ViewEditFieldRow
                    type="date"
                    name="Received date"
                    value={material.dateReceived}
                    valueText={renderDateString('short', material.dateReceived)}
                    validateFn={(value) =>
                      validator(validateReceivedDate, value, (message) => toast(message, {type: 'error'}))
                    }
                    saveValue={(dateReceived) => materialsByUuidPATCH(material.uuid, {dateReceived})}
                  />

                  <ViewEditFieldRow
                    type="number"
                    name="Quantity Received"
                    value={material.quantityReceived}
                    validateFn={(value) =>
                      validator(validateQuantityReceived, value, (message) => toast(message, {type: 'error'}))
                    }
                    units={'kg'}
                    saveValue={(quantityReceived) => materialsByUuidPATCH(material.uuid, {quantityReceived})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Type"
                    value={material.type}
                    saveValue={(type) => materialsByUuidPATCH(material.uuid, {type})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Grade"
                    value={material.grade}
                    saveValue={(grade) => materialsByUuidPATCH(material.uuid, {grade})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Supplier"
                    value={material.supplier}
                    saveValue={(supplier) => materialsByUuidPATCH(material.uuid, {supplier})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Supplier Lot#"
                    value={material.supplierLot}
                    saveValue={(supplierLot) => materialsByUuidPATCH(material.uuid, {supplierLot})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Chemical composition"
                    value={material.chemicalComposition!}
                    saveValue={(chemicalComposition) => materialsByUuidPATCH(material.uuid, {chemicalComposition})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Powder Size"
                    value={material.powderSize!}
                    saveValue={(powderSize) => materialsByUuidPATCH(material.uuid, {powderSize})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Tap Density"
                    value={material.tapDensity!}
                    units={'g/ml'}
                    saveValue={(tapDensity) => materialsByUuidPATCH(material.uuid, {tapDensity})}
                  />

                  <ViewEditFieldRow
                    type="text"
                    name="Hall Flow Characteristics"
                    value={material.hallFlowCharacteristics!}
                    units={'cc/s'}
                    saveValue={(hallFlowCharacteristics) =>
                      materialsByUuidPATCH(material.uuid, {
                        hallFlowCharacteristics,
                      })
                    }
                  />

                  <TableRow>
                    <TableCell>
                      <b>Production Certificates and Other Files</b>
                    </TableCell>
                    <TableCell colSpan={2}>
                      {certificates.length ? <AttachmentDownloadTable attachments={certificates} /> : <i>No files</i>}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <MaterialsMultipartUpload
                materialUuid={material.uuid}
                orgUuid={material.organizationUuid}
                setNumFilesUploading={setNumFilesUploading}
                numFilesUploading={numFilesUploading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <BatchAddMaterialModal
        open={addMaterialOpen}
        initialMaterialUuid={material?.uuid}
        onClose={() => setAddMaterialOpen(false)}
      />
    </React.Fragment>
  );
}
