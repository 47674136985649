import React from 'react';
import {IMetricsResponse} from '@common/api/models/metrics';
import {chartColors} from './chartColors';
import {Typography} from '@material-ui/core';
import {CenteredDiv} from './SwitchableChart';
import waitingForSomethingPNG from '../../../assets/img/waiting-for-something.png';
import LineBarChart from '../../../components/atoms/ApexCharts/LineBarChart';

function BuildDurationHistogram({
  metrics: {completedBuildDurations},
  usePercentageValues,
}: {
  metrics: IMetricsResponse;
  usePercentageValues: boolean;
}) {
  if (!completedBuildDurations.length)
    return (
      <CenteredDiv>
        <Typography variant="h4">No recent builds...</Typography>

        <img
          src={waitingForSomethingPNG}
          style={{maxHeight: '25vh', marginTop: '1rem'}}
          alt="bird chirping musically in a park"
        />
      </CenteredDiv>
    );

  const durations = completedBuildDurations.map((build) => Number((build.duration / 1000 / 60 / 60).toFixed(1)));
  const totalDuration = durations.reduce((sum, current) => sum + current, 0);
  const maxDuration = Math.ceil(Math.max(...durations) / 10) * 10;
  let minDuration = Math.floor(Math.min(...durations) / 10) * 10;

  let binSize = 10; // Hours
  let numBins = Math.min((maxDuration - minDuration) / binSize, 10);

  if (numBins < 5 && durations.length > 15) {
    binSize = 5;
    numBins = Math.min((maxDuration - minDuration) / binSize, 10);
  }

  if (numBins === 1) {
    minDuration = Math.max(0, minDuration - binSize);
  }
  numBins = Math.max(3, numBins);

  const buildDurationHistogram = Array(numBins)
    .fill(0)
    .map((_, index) => {
      const thisBin = minDuration + binSize * index;
      const isLastBin = index + 1 === numBins;
      const thisDurations = durations.filter(
        (duration) => duration >= thisBin && (isLastBin ? true : duration < thisBin + binSize)
      );
      const totalBuildTime = thisDurations.reduce((sum, current) => sum + current, 0);
      return {
        x: isLastBin ? `${thisBin}+ hours` : `${thisBin}-${thisBin + binSize} hours`,
        y: usePercentageValues ? (thisDurations.length / durations.length) * 100 : thisDurations.length,
        totalBuildTime: usePercentageValues ? (totalBuildTime / totalDuration) * 100 : totalBuildTime,
      };
    });

  return (
    <LineBarChart
      chartID="builds-duration"
      height={500}
      series={[
        {
          name: usePercentageValues ? 'Percentage of builds' : 'Number of builds',
          type: 'column',
          data: buildDurationHistogram.map((data) => data.y),
        },
        {
          name: usePercentageValues ? 'Percentage of build time' : 'Total build time',
          type: 'line',
          data: buildDurationHistogram.map((data) => data.totalBuildTime),
        },
      ]}
      options={{
        colors: [chartColors.purple, chartColors.blue],
        xaxis: {
          categories: buildDurationHistogram.map((build) => build.x),
        },
        yaxis: [
          {
            tickAmount: Math.min(10, Math.max(...buildDurationHistogram.map((data) => data.y))),
            labels: {
              formatter: (val) => (usePercentageValues ? `${Number(val).toFixed(0)}%` : Number(val).toFixed(0)),
            },
            title: {
              text: usePercentageValues ? 'Percentage of builds' : 'Number of builds',
            },
          },
          {
            opposite: true,
            labels: {
              formatter: (val) => (usePercentageValues ? `${val.toFixed(0)}%` : `${val.toFixed(0)} hours`),
            },
            title: {
              text: usePercentageValues ? 'Percentage of build time' : 'Total build time',
            },
          },
        ],
      }}
    />
  );
}

export default BuildDurationHistogram;
